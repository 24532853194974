const compareObjects = require('@digitickets/compare-objects');
const { md5Hash } = require('../../functions/hash');

/**
 * Holds tasks and executes them only when connected to the internet.
 * Register a handler function for each type of task.
 * Add tasks and the data to be given to the handler function.
 *
 * Uses compareObjects function in /app/lib/compareObjects.js
 *
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param OnlineQueueCache
 * @param $injector
 */
DigiTickets.OnlineQueue = function OnlineQueue(
    ConnectivityChecker,
    OnlineQueueCache,
    $injector
) {
    this.$injector = $injector;
    this.cache = OnlineQueueCache;
    this.connectivityChecker = ConnectivityChecker;

    /**
     * The functions to be called for each task type.
     * The task handler is passed the data given with the task as the first parameter
     *
     * @type {Object<function>}
     */
    this.handlers = {};

    /**
     * Tasks queued up to run when online.
     *
     * @type {Array}
     */
    this.queue = [];

    /**
     * Enable debugging logs?
     *
     * @type {boolean}
     */
    this.debug = false;

    /**
     * Wait this many ms before processing the queue at startup.
     * This gives other services a chance to register their handlers first.
     *
     * @type {number}
     */
    this.startUpDelay = 10000;

    /**
     * How many ms to wait between processing tasks.
     *
     * @type {number}
     */
    this.interval = 100;

    this.lastApiKey = null;

    /**
     * Are we busy running a task?
     *
     * @type {boolean}
     */
    this.busy = true;

    /**
     * Is the queue empty?
     *
     * @type {boolean}
     */
    this.isFinished = false;

    /**
     * Are we online?
     * (Updated by the online service listener)
     *
     * @type {boolean}
     */
    this.online = null;

    /**
     * Functions to be called when the queue has finished processing everything it needs to.
     *
     * @type {Array}
     */
    this.finishedListeners = [];
};

DigiTickets.OnlineQueue.prototype = {
    init: function init() {
        this.online = this.connectivityChecker.isOnline();
        this.connectivityChecker.addObserver(this.getOnlineObserver());

        // Load up our cached queue if any
        this.restoreQueue();

        // Wait before we start doing anything to begin.
        // This gives a chance for other things to add their handlers
        let self = this;
        setTimeout(function () {
            self.busy = false;
            self.start();
        }, this.startUpDelay);

        if (!this.hasTasks()) {
            this.isFinished = true;
        }
    },

    /**
     * Returns an object to pass to ConnectivityChecker as an observer
     */
    getOnlineObserver: function getOnlineObserver() {
        let self = this;
        return {
            key: 'onlinequeue',
            notifyOnline: function notifyOnline() {
                if (!self.online) {
                    self.logMsg('Now online');
                    self.online = true;
                    self.start();
                }
            },
            notifyOffline: function notifyOffline() {
                if (self.online) {
                    self.logMsg('Now offline');
                    self.online = false;
                }
            }
        };
    },

    inject: function inject(what) {
        console.warn('OnlineQueueService.inject is deprecated and should no longer be used.', what);
        return this.$injector.get(what);
    },

    /**
     * Queue
     */

    /**
     * Add a task to be processed.
     *
     * @param {string} type Which type of event is this (which handler to call)
     * @param {object} data Object of data to pass to the event handler
     * @param {boolean} [ignoreDuplicate] Ignore duplicates.
     * @param {boolean} [addToHead] Allow the task to jump to the top of the queue.
     */
    addTask: function addTask(type, data, ignoreDuplicate, addToHead) {
        this.logMsg(type, data, ignoreDuplicate);

        if (!this.hasHandler(type)) {
            throw new Error("OnlineQueue doesn't know how to handle that task (" + type + ')');
        }

        if (ignoreDuplicate === true) {
            if (this.taskExists(type, data)) {
                this.logMsg('Ignoring task because same one already in the queue');
                return false;
            }
        }

        // save the latest api key ready for use later
        if (data.hasOwnProperty('apiKey') && data.apiKey) {
            this.lastApiKey = data.apiKey;
        }

        let task = {
            type,
            data,
            attempts: 0,
            ignoreDuplicate
        };

        this.addTaskToQueue(
            task,
            addToHead === true
        );

        this.start();
    },


    addQueueElement: function addQueueElement(task) {
        let enqueuedTime = (new Date()).toISOString();
        if (!task.data.hasOwnProperty('_queue')) {
            task.data._queue = {
                queued: enqueuedTime,
                submitted: null,
                attempts: 0,
                hash: md5Hash(task.type + enqueuedTime)
            };
        }
    },

    /**
     * Add a task to the queue.
     *
     * @param {object} task The task to be added to the queue.
     * @param {boolean} [addToHead] Allow the task to jump to the top of the queue.
     */
    addTaskToQueue: function addTaskToQueue(task, addToHead) {
        this.logMsg('Item added');
        this.logMsg(task);
        let clonedTask = jQuery.extend(true, {}, task);
        switch (task.type) {
            case 'redemption':
                clonedTask.data = {
                    orderID: task.data.orderLine.orderID,
                    orderItemsID: task.data.orderLine.ID,
                    itemID: task.data.orderLine.itemID,
                    item: task.data.orderLine.name,
                    qty: task.data.orderLine.qty,
                    newAmount: task.data.newAmount
                };
                break;
        }

        this.addQueueElement(task);

        if (addToHead) {
            this.queue.unshift(task);
        } else {
            this.queue.push(task);
        }
        this.saveQueue();
    },

    /**
     * Checks if the given task is already in the queue
     *
     * @param type
     * @param data
     */
    taskExists: function taskExists(type, data) {
        return $.grep(this.queue, function (t) {
            // Check type is same and data is the same
            return t.type == type && compareObjects(t.data, data);
        }).length > 0;
    },

    clear: function clear() {
        this.queue = [];
        this.saveQueue();
    },

    hasTasks: function hasTasks() {
        return this.queue.length > 0;
    },

    getQueue: function getQueue() {
        return this.queue;
    },

    start: function start() {
        if (this.busy) {
            this.logMsg('start Busy');
            return false;
        }

        if (!this.hasTasks()) {
            this.logMsg('start Nothing to do');
            this.fireOnFinishedListeners();
            return false;
        }

        this.isFinished = false;

        if (!this.online) {
            this.logMsg('start Offline');
            return false;
        }

        this.processNextTask();
    },

    getNextTask: function getNextTask() {
        let task = this.queue.shift();
        this.saveQueue();
        return task;
    },

    processNextTask: function processNextTask() {
        this.busy = true;

        let task = this.getNextTask();
        this.logMsg('processNextTask Doing task:');
        this.logMsg(task);

        let self = this;

        let handler = this.getHandler(task.type);

        if (typeof handler !== 'function') {
            this.logMsg('No handler for action ' + task.type);
            // No handler for this task type.
            // It may have been removed since this task was added to the queue.
            this.queue.unshift(task);
            setTimeout(function () {
                self.busy = false;
                self.start();
            }, this.interval);
            return false;
        }

        if (!task.hasOwnProperty('data')) {
            task.data = {};
        }

        // If there is no api key supplied use the latest (assuming we have one)
        if (!task.data.hasOwnProperty('apiKey') && this.lastApiKey) {
            task.data.apiKey = this.lastApiKey;
        }

        // update task data
        ++task.attempts;
        this.addQueueElement(task);
        task.data._queue.submitted = (new Date()).toISOString();
        task.data._queue.attempts = task.attempts;

        // Call the event handler's function

        let taskHanderCallback = function (resultState, response) {
            if (resultState === true) {
                self.logMsg('Task complete');
            } else if (resultState === false) {
                // if authentication failed attempt to substitute the api key for the next attempt
                if (response && response.status == 401 && self.lastApiKey) {
                    task.data.apiKey = self.lastApiKey;
                }

                self.logMsg('Task failed');

                if (task.attempts >= 3) {
                    self.logMsg('Task permanently failed');
                    // todo : store to API catch all bucket
                } else {
                    // Try again later
                    setTimeout(function () {
                        self.addTaskToQueue(task, true);
                        self.start();
                    }, self.interval);
                }
            }

            self.saveQueue();

            setTimeout(function () {
                self.busy = false;
                self.start();
            }, self.interval);
        };

        // Run the handler with the data and any dependencies injected by Angular.
        this.$injector.invoke(
            handler,
            this,
            {
                data: task.data,
                callback: taskHanderCallback,
                queue: this
            }
        );
    },


    /**
     * Task Handlers
     */

    //
    // Register the given function to be called when a certain type of task is added to the queue
    // The function is given a data and callback parameter. Function must call the callback when
    // it completes so the queue can continue to be processed.
    //
    // @param type
    // @param func
    // @param replace
    //
    addHandler: function addHandler(type, func, replace) {
        if (this.hasHandler(type) && !replace) {
            return false;
        }
        if (typeof func !== 'function') {
            return false;
        }
        this.handlers[type] = func;
    },

    getHandler: function getHandler(type) {
        return this.handlers[type];
    },

    hasHandler: function hasHandler(type) {
        return this.handlers.hasOwnProperty(type);
    },

    removeHandler: function removeHandler(type) {
        let i = this.handlers.indexOf(type);
        this.handlers.splice(i, 1);
    },


    /**
     * Storage
     */
    saveQueue: function saveQueue() {
        this.logMsg('Saving queue ' + JSON.stringify(this.queue));
        this.cache.put('queue', this.queue);
        this.cache.put('lastApiKey', this.lastApiKey);
    },

    // Restore the saved queue from localstorage
    restoreQueue: function restoreQueue() {
        let savedQueue = this.cache.get('queue');
        this.lastApiKey = this.cache.get('lastApiKey');
        this.logMsg('Restoring queue ' + JSON.stringify(savedQueue));
        $.extend(this.queue, savedQueue);
    },

    // Debugging
    logMsg: function logMsg(data) {
        if (this.debug) {
            console.debug(new Date() + ' : [OnlineQueue]', data);
        }
    },

    onFinish: function onFinish(func) {
        this.finishedListeners.push(func);

        // Fire now if we're not doing anything
        if (!this.busy && !this.hasTasks()) {
            this.fireOnFinishedListeners();
        }
    },

    fireOnFinishedListeners: function fireOnFinishedListeners() {
        this.isFinished = true;
        for (let i = 0; i < this.finishedListeners.length; i++) {
            this.finishedListeners[i]();
        }
    }
};
