const apiErrorMessage = require('../Api/apiErrorMessage');
const objectHash = require('object-hash');

/**
 * Handles sending the cart off to an external source (the DT API) to perform some calculations
 * we don't do on ProPoint.
 * Initially this was just for offers but now it's also for calculating price differences when amending orders.
 *
 * @param $timeout
 * @param CartCalculationResource
 * @param {CartToReservationStructureMapper} cartToReservationStructureMapper
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {DigiTickets.OfferManager} OfferManager
 * @param {PaymentService} paymentService
 */
const ExternalCartCalculator = function (
    $timeout,
    CartCalculationResource,
    cartToReservationStructureMapper,
    ConnectivityChecker,
    OfferManager,
    paymentService
) {
    this.$timeout = $timeout;
    this.cartCalculationResource = CartCalculationResource;
    this.cartToReservationStructureMapper = cartToReservationStructureMapper;
    this.connectivityChecker = ConnectivityChecker;
    this.offerManager = OfferManager;
    this.paymentService = paymentService;

    /**
     * @type {?string}
     */
    this.lastRequestHash = null;
};

ExternalCartCalculator.prototype = {
    /**
     * @param {CartService} cart
     * @param {DigiTickets.Payment[]} [proposedPayments]
     * @param {boolean} [force]
     *
     * @returns {Promise}
     */
    recalculate(cart, proposedPayments = [], force = false) {
        let requestStructure = this.buildStructureForCartCalculationsRequest(cart, proposedPayments);

        if (!requestStructure.lines || Object.values(requestStructure.lines).length < 1) {
            // No lines in the request - no need to send.
            // Have cart recalculate itself so manual offers are updated.
            cart.calculate(false);

            return Promise.resolve({});
        }

        // Wrapped in a timeout to trigger screen update faster in Angular.
        this.$timeout(() => {
            cart.externalCalculationInProgress = true;
        });

        return this.sendRequest(requestStructure, force)
            .then((response) => {
                // Clear all existing discounts.
                cart.clearDiscounts();

                // Add the auto offers.
                this.offerManager.applyOffersToCart(cart, response.discounts);

                // Return the response so then's in the chain can run.
                return response;
            })
            .catch((error) => {
                console.warn('Cart external recalculation failed', error);

                // Re-throw the error so other catch's in the chain can run.
                throw error;
            })
            .finally(() => {
                cart.calculate(false);

                this.$timeout(() => {
                    cart.externalCalculationInProgress = false;
                });
            });
    },

    /**
     * @param {object} requestStructure
     * @param {boolean} [force] Make the request even if it's a duplicate.
     *
     * @return {Promise<any>}
     */
    sendRequest(requestStructure, force) {
        return new Promise((resolve, reject) => {
            if (!this.connectivityChecker.isOnline()) {
                reject(new Error('Offline'));
                return;
            }

            let hash = this.generateHashForRequestStructure(requestStructure);

            if (!force && hash === this.lastRequestHash) {
                reject(new Error('Ignoring duplicate hash'));
                return;
            }

            // Add the hash to the structure so the API returns it.
            requestStructure.hash = hash;

            // Remember the last hash sent so we can discard outdated responses.
            // This to prevent a race condition where an earlier request comes back after
            // the latest one, giving us old data.
            this.lastRequestHash = hash;

            this.cartCalculationResource.calculate(
                requestStructure,
                (response) => {
                    // Only use this response if the hash is the latest one we are expecting.
                    if (response.hash !== this.lastRequestHash) {
                        reject(new Error(`Response for old request received (${response.hash})`));
                    } else if (response.errors && response.errors.length > 0) {
                        reject(new Error(response.errors.shift()));
                    } else {
                        resolve(response);
                    }
                },
                (result) => {
                    reject(new Error(apiErrorMessage(result)));
                }
            );
        });
    },

    /**
     * @param {CartService} cartService
     * @param {DigiTickets.Payment[]} proposedPayments
     *
     * @return {object}
     */
    buildStructureForCartCalculationsRequest(cartService, proposedPayments) {
        let reservationStructure = this.cartToReservationStructureMapper.map(cartService, false);

        reservationStructure.manualOfferIDs = this.buildManualOfferIDsArray(
            cartService.currentCart.selectedManualOfferIds
        );

        // Add any 'proposed' payments such as experience gift vouchers.
        // The "cart calculations" endpoint uses these to adjust pricing of items and to check whether
        // the vouchers can actually be applied to the current basket
        reservationStructure.payments = this.paymentService.convertPaymentsToApiStructure(proposedPayments);

        Object.keys(reservationStructure.lines).forEach((key) => {
            // Remove strange items that shouldn't be discounted.
            // FIXME: Should this be done by the API instead?
            let line = reservationStructure.lines[key];
            if (line.itemType === 'Order Balance') {
                delete reservationStructure.lines[key];
            }
        });

        return reservationStructure;
    },

    /**
     * Convert an object from the format manual offerIDs are stored in the cart:
     * {offerID: numberOfTimesApplies, 1: 3}
     * to the format required by the API, which is an array with each offerID appearing in it the number of times
     * it can be applied:
     * [1, 1, 1]
     *
     * @param {Object<number>} selectedManualOfferIDs
     *
     * @return {number[]}
     */
    buildManualOfferIDsArray(selectedManualOfferIDs) {
        // An array of offerIDs that have been unlocked by the operator - the same ID can appear multiple times.
        let manualOfferIDs = [];

        // Convert from the object of offer IDs with a count to the array with repeated values.
        Object.keys(selectedManualOfferIDs).forEach((offerID) => {
            offerID = parseInt(offerID, 10);
            let count = selectedManualOfferIDs[offerID];
            for (let i = 0; i < count; i++) {
                manualOfferIDs.push(offerID);
            }
        });

        return manualOfferIDs;
    },

    /**
     * @param {object} requestStructure
     *
     * @return {string}
     */
    generateHashForRequestStructure(requestStructure) {
        return objectHash(requestStructure);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ExternalCartCalculator;
}
