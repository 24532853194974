const { toBool, toInt, toNullableString } = require('../functions/transform');

DigiTickets.ReturnReason = function () {
    this.description = '';
    this.ID = null;
    this.returnToStock = null;
};

DigiTickets.ReturnReason.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'reasonID'],
                transform: toInt
            },
            description: {
                field: ['description', 'reasonDescription'],
                transform: toNullableString
            },
            returnToStock: {
                field: ['returnToStock', 'defaultReturnToStock'],
                transform: toBool
            }
        };
    }
};
