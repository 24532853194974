/**
 * @param {AppConfig} AppConfig
 * @param {CurrentDevice} CurrentDevice
 * @param {UserService} UserService
 */
const ApiRequestParams = function (
    AppConfig,
    CurrentDevice,
    UserService
) {
    this.appConfig = AppConfig;
    this.currentDevice = CurrentDevice;
    this.userService = UserService;
};

ApiRequestParams.prototype = {
    /**
     * Returns the default parameters that should be added to every API request.
     * These are functions so angular-resource will call the function each time to get the actual value.
     *
     * @return {{apiKey: (function(): *), branchID: (function(): *), deviceGuid: (function(): *)}}
     */
    defaults() {
        const self = this;

        // These are returned as functions so they are invoked
        // for each request and the latest value is sent.
        // Otherwise we might be using stale data.
        let params = {
            apiKey: function apiKey() {
                return self.userService.getApiKey();
            },
            branchID: function branchID() {
                return self.userService.getBranchID();
            },
            deviceGuid: function () {
                return self.currentDevice.getDeviceGuid();
            },
            // Tell API to return dates in ISO-8601 (where implemented).
            isoDates: 1
        };
        if (self.appConfig.isBeta) {
            params.isBeta = 1;
        }
        return params;
    },

    defaultExcludeFields() {
        return [
            'categories.buttonText',
            'categories.description',
            'categories.featuredImage',
            'categories.icon',
            'categories.latLng',
            'categories.shortDescription',
            'categories.terms',
        ].join(',');
    },

    defaultMappers() {
        return {
            'mappers[0][normalise]': 'true',
            'mappers[1][toTable]': 'true'
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ApiRequestParams;
}
