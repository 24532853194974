const { toBool, toInt } = require('../functions/transform');

const FloatAdjustmentReason = function () {
    this.ID = null;
    this.reason = null;
    this.withdrawal = false;
    /**
     * Is entering a narrative required?
     *
     * @type {boolean}
     */
    this.narrative = false;
    this.requiredOnSessionClose = false;
};

FloatAdjustmentReason.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'floatAdjustmentReasonID'],
                transform: toInt
            },
            narrative: toBool,
            reason: {},
            withdrawal: toBool,
            requiredOnSessionClose: toBool
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FloatAdjustmentReason;
}
