const EntityStatus = require('../Enums/EntityStatus');
const FieldOption = require('./FieldOption');
const FieldType = require('./FieldType');
const { populate } = require('../../../functions/objects');
const { toBool, toInt, toIntArray, toString } = require('../../../functions/transform');

/**
 * A question asked to the customer when placing an order, depending on what they purchased.
 *
 * @param {{}} properties
 */
const Field = function (properties = {}) {
    /**
     * @type {Number}
     */
    this.ID = null;

    /**
     * @type {String}
     */
    this.level = null;

    /**
     * @type {string}
     */
    this.ref = '';

    /**
     * @type {string}
     */
    this.name = '';

    /**
     * If not empty this field only applies to items for the specified events.
     *
     * @type {number[]}
     */
    this.eventIDs = [];

    /**
     * If not empty this field only applies to items in the specified categories.
     *
     * @type {number[]}
     */
    this.catIDs = [];

    /**
     * If not empty this field only applies to the specified items.
     *
     * @type {number[]}
     */
    this.itemIDs = [];

    /**
     * @type {String}
     */
    this.type = null;

    /**
     * @type {boolean}
     */
    this.required = false;

    /**
     * Is this an 'ask early' field?
     * These are fields that should be asked as soon as the item is added to the cart rather than later.
     * (Think product options - what bread, what sauce etc.)
     *
     * @type {boolean}
     */
    this.askEarly = false;

    /**
     * @type {Number}
     */
    this.navOrder = null;

    /**
     * @type {FieldOption[]}
     */
    this.options = [];

    /**
     * @type {string}
     */
    this.status = EntityStatus.ACTIVE;

    /**
     * True if this has no event/cat/item restrictions.
     *
     * @type {boolean}
     */
    this.always = false;

    /**
     * True if multiple predefined options can be selected.
     *
     * @type {boolean}
     */
    this.hasMultipleOptions = false;

    /**
     * True if you answer from a predefined option (in this.options).
     *
     * @type {boolean}
     */
    this.hasOptions = false;

    populate(this, properties);
};

Field.prototype = {
    /**
     * Mapping of properties from the API data to object properties.
     *
     * @return {object}
     */
    getHydrationMap() {
        return {
            askEarly: toBool,
            catIDs: toIntArray,
            eventIDs: {
                field: ['eventIDs', 'eventCatIDs'],
                transform: toIntArray
            },
            ID: {
                field: ['ID', 'fieldID'],
                transform: toInt
            },
            itemIDs: toIntArray,
            level: toString,
            name: toString,
            navOrder: toInt,
            options: {
                field: ['options', 'fieldoptions'],
                modelCollection: FieldOption
            },
            ref: toString,
            required: {
                field: ['requiredEpos', 'required'],
                type: toBool
            },
            status: toString,
            type: toString
        };
    },

    /**
     * Initialize the model after its data has been set.
     */
    afterHydration() {
        this.hasMultipleOptions = this.type === FieldType.CHECKBOX;
        this.hasOptions = this.type === FieldType.SELECT || this.type === FieldType.CHECKBOX || this.type === FieldType.RADIO;

        this.always = this.eventIDs.length < 1 && this.itemIDs.length < 1 && this.catIDs.length < 1;
    },

    /**
     * Returns an option that is attached to this field.
     *
     * @param {number} optionID
     *
     * @return {?FieldOption}
     */
    getOptionByID(optionID) {
        return this.options.find((o) => o.ID === optionID) || null;
    },

    /**
     * Does this field relate to the given itemID?
     *
     * @param {number} itemID
     *
     * @return {boolean}
     */
    isForItem(itemID) {
        return this.itemIDs.indexOf(itemID) !== -1;
    },

    /**
     * Does this field relate to the given categoryID?
     *
     * @param {number} catID
     *
     * @return {boolean}
     */
    isForCat(catID) {
        return this.catIDs.indexOf(catID) !== -1;
    },

    /**
     * Does this field relate to the given eventID?
     *
     * @param {number} eventID
     *
     * @return {boolean}
     */
    isForEvent(eventID) {
        return this.eventIDs.indexOf(eventID) !== -1;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Field;
}
