/**
 * RFC5424 log levels.
 *
 * @see https://tools.ietf.org/html/rfc5424
 */
const LOG_SEVERITY = Object.freeze({
    EMERGENCY: 0,
    ALERT: 1,
    CRITICAL: 2,
    ERROR: 3,
    WARNING: 4,
    NOTICE: 5,
    INFO: 6,
    DEBUG: 7
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LOG_SEVERITY;
}
