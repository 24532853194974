const moment = require('moment');
const { toFloat, toInt } = require('../functions/transform');

DigiTickets.TradingSession = function () {
    /**
     * @type {Date|null}
     */
    this.endedAt = null;

    /**
     * @type {DigiTickets.Device|null}
     */
    this.device = null;

    /**
     * @type {number}
     */
    this.deviceID = null;

    /**
     * @type {number}
     */
    this.ID = null;

    /**
     * @type {Data}
     */
    this.startedAt = null;

    /**
     * @type {number}
     */
    this.tradingSessionStatusID = null;

    /**
     * @type {DigiTickets.TradingSessionEvent[]}
     */
    this.tradingSessionEvents = [];

    /**
     * @type {DigiTickets.TradingSessionFinancialSummary[]}
     */
    this.tradingSessionFinancialSummaries = [];
};

DigiTickets.TradingSession.prototype = {
    getHydrationMap() {
        return {
            device: {
                model: DigiTickets.Device
            },
            deviceID: toInt,
            endedAt: {
                transform(value) {
                    // Convert from UTC date.
                    // FIXME: PRO-916 API should output in ISO8601 so we don't need to be concerned with this.
                    return value === null ? null : moment.utc(value).toDate();
                }
            },
            ID: {
                field: ['ID', 'tradingSessionID'],
                transform: toInt
            },
            startedAt: {
                transform(value) {
                    // Convert from UTC date
                    // FIXME: PRO-916 API should output in ISO8601 so we don't need to be concerned with this.
                    return value === null ? null : moment.utc(value).toDate();
                }
            },
            tradingSessionEvents: {
                modelCollection: DigiTickets.TradingSessionEvent
            },
            tradingSessionFinancialSummaries: {
                modelCollection: DigiTickets.TradingSessionFinancialSummary
            },
            tradingSessionStatusID: toInt,
            type: toInt,
            cashBanked: toFloat
        };
    }
};
