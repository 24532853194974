const angular = require('angular');
const CustomerAccountType = require('../../libraries/DigiTickets/Enums/CustomerAccountType');
const PartialPaymentCtrl = require('./PartialPaymentCtrl');
const PaymentMethodRef = require('../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
const { cloneDeep } = require('../../functions/clone');

/**
 * @param $filter
 * @param $modal
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {DialogService} DialogService
 * @param LogResource
 * @param {PaymentService} paymentService
 * @param {PaymentMethodService} paymentMethodService
 * @param {PaymentState} paymentState
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param {UserService} UserService
 */
const PaymentCtrl = function PaymentCtrl(
    $filter,
    $modal,
    $modalInstance,
    $scope,
    $timeout,
    cartService,
    CustomerScreenDataService,
    DialogService,
    LogResource,
    paymentService,
    paymentMethodService,
    paymentState,
    PrivilegesService,
    TradingSessionManager,
    UserService
) {
    $scope.cart = cartService;
    /**
     * @type {DigiTickets.CartItem[]}
     */
    $scope.cartItemDiscounts = $scope.cart.getDiscountSummary();

    /**
     * Used in the template to show/hide the cancel button.
     *
     * @type {PaymentState}
     */
    $scope.paymentState = paymentState;

    $scope.privilegesService = PrivilegesService;

    $scope.allowClose = true;
    $scope.addFullPaymentOnClose = true;
    $scope.showLoading = false;
    $scope.setLoading = function setLoading(loading) {
        $scope.showLoading = loading;
    };

    /**
     * Payments that have been received.
     *
     * @type {DigiTickets.Payment[]}
     */
    $scope.payments = [];

    // All tabs are disabled if this is true. If any payment method is in a transaction, it will set it to true.
    $scope.txInProgress = false;
    $scope.restrictToPaymentMethodRef = null;

    $scope.getPaymentMethodTemplate = function getPaymentMethodTemplate(paymentMethod) {
        return 'partials/modals/payment/methods/' + paymentMethod.ref + '.html';
    };

    $scope.paymentMethods = paymentMethodService.getAll();

    /**
     * @param {PaymentMethod} paymentMethod
     * @return {boolean}
     */
    $scope.paymentMethodDisabled = (paymentMethod) => {
        /**
         * Disable if the current payment is not cancellable, and this isn't the one we're already on
         * or if we've already received non-gift voucher payments and this is the gift voucher payment method
         * (which can affect the order total)
         */
        /**
         * @param {DigiTickets.Payment} payment
         */
        const isNotGiftVoucherPaymentMethod = (payment) => payment.paymentMethod.ref !== PaymentMethodRef.GIFT_VOUCHER;
        return (
            paymentState.cancelable === false
                && paymentMethod.ID !== $scope.cart.selectedPaymentMethod.ID
        )
            || (
                $scope.cart.getPayments().filter(isNotGiftVoucherPaymentMethod).length > 0
                && paymentMethod.ref === PaymentMethodRef.GIFT_VOUCHER
            );
    };

    $scope.updateAvailablePaymentMethods = function updateAvailablePaymentMethods() {
        let methods = [];

        angular.forEach($scope.paymentMethods, function (paymentMethod) {
            if ($scope.restrictToPaymentMethodRef) {
                // Restricted to only one payment method.
                if (paymentMethod.ref !== $scope.restrictToPaymentMethodRef) {
                    return false;
                }
            }

            if (paymentMethod.ref === PaymentMethodRef.PAYMENT_ON_ACCOUNT) {
                // Only this method for business accounts.
                if (!$scope.cart.customer
                    || !$scope.cart.customer.account
                    || $scope.cart.customer.account.type !== CustomerAccountType.BUSINESS
                ) {
                    return false;
                }
            }

            methods.push(paymentMethod);
        });

        if ($scope.cart.isRefund()) {
            methods = methods.filter(function (method) {
                return method.supportsNegativePaymentInProPoint;
            });
        }

        $scope.availablePaymentMethods = methods;

        return methods;
    };

    $scope.selectDefaultPaymentMethod = function selectDefaultPaymentMethod() {
        if ($scope.availablePaymentMethods.length < 1) {
            $scope.selectPaymentMethod(null);
        } else if ($scope.availablePaymentMethods.length === 1 || $scope.cart.getSelectedPaymentMethod() === null) {
            $scope.selectPaymentMethod($scope.availablePaymentMethods[0]);
        }
    };

    $scope.setRestrictToPaymentMethodUsingRef = function setRestrictToPaymentMethodUsingRef(ref) {
        $scope.restrictToPaymentMethodRef = ref;
        $scope.updateAvailablePaymentMethods();
    };

    // This is an array of payment method short names for which we should hide the
    // "Received" button. Each child controller would register their desire NOT to
    // have the button.
    $scope.hidePaymentReceivedButtonForPaymentMethodRefs = [];

    $scope.hidePartialPaymentReceivedButtonForPaymentMethodRefs = [];

    /**
     * Payment methods where the user can simply click a delete button on the list of payments on
     * the left to remove the payment.
     * Generally this should be things where it's easy to hand back to the customer and remove it. Not ones where
     * a refund would need to be issued (e.g. card payments)
     *
     * @type {string[]}
     */
    $scope.deletablePaymentMethodRefs = [
        PaymentMethodRef.CASH,
        PaymentMethodRef.CHEQUE,
        PaymentMethodRef.FLEXI_VOUCHER,
        PaymentMethodRef.INVOICE,
        PaymentMethodRef.PAYMENT_ON_ACCOUNT,
        PaymentMethodRef.VOUCHER,
    ];

    $scope.clearError = function clearError() {
        $scope.error = {
            exists: false,
            message: ''
        };
    };

    $scope.setError = function setError(msg) {
        if (msg) {
            $scope.error = {
                exists: true,
                message: msg
            };
        } else {
            $scope.clearError();
        }
    };

    $scope.hasErrors = function hasErrors() {
        return $scope.error.exists;
    };

    $scope.initialize = function initialize() {
        console.log('initialize');

        paymentState.clearCancelable();
        $scope.selectPaymentMethod(null);
        $scope.clearError();

        $scope.updateAvailablePaymentMethods(false);
        // Timeout before selecting an initial payment method default method so the child controllers
        // have a chance to load (and receive the switch-to event).
        $timeout(function () {
            if ($scope.cart.transactionInProgress) {
                $scope.selectPaymentMethodByRef($scope.cart.transactionInProgress.paymentMethodRef);
                $scope.setRestrictToPaymentMethodUsingRef($scope.cart.transactionInProgress.paymentMethodRef);
            } else {
                $scope.selectDefaultPaymentMethod();
            }
        }, 100);
    };

    /**
     * @param {PaymentMethod} paymentMethod
     */
    $scope.selectPaymentMethod = function selectPaymentMethod(paymentMethod) {
        let currentMethod = $scope.cart.getSelectedPaymentMethod();

        if (currentMethod && paymentMethod && currentMethod.ref === paymentMethod.ref) {
            return false;
        } else if (currentMethod && paymentState.cancelable === false) {
            // Cannot switch payment method while transaction is in progress.
            return false;
        }

        $scope.clearError();
        $scope.clearCashbackAmount();
        $scope.cart.setSelectedPaymentMethod(paymentMethod);
        // We are switching to another payment method - broadcast "switching-to" to all methods.
        // Any payment method controller that receives a "switching-to" for a *different* method,
        // should reset itself.
        $scope.setCustomerFacingStatus('');
        $scope.$broadcast('payment-modal.payment-method-changed', (paymentMethod ? paymentMethod.ref : null));
    };

    /**
     * @param {string} paymentMethodRef
     */
    $scope.selectPaymentMethodByRef = function selectPaymentMethodByRef(paymentMethodRef) {
        for (let i = 0; i < $scope.availablePaymentMethods.length; i++) {
            if ($scope.availablePaymentMethods[i].ref === paymentMethodRef) {
                $scope.selectPaymentMethod($scope.availablePaymentMethods[i]);
                return;
            }
        }
    };

    $scope.getCurrentPaymentMethodRef = function getCurrentPaymentMethodRef() {
        return $scope.cart.selectedPaymentMethod ? $scope.cart.selectedPaymentMethod.ref : null;
    };

    $scope.preventClose = function preventClose() {
        $scope.allowClose = false;
    };

    $scope.preventAddFullPaymentOnClose = function preventAddFullPaymentOnClose() {
        $scope.addFullPaymentOnClose = false;
    };

    $scope.removePayment = function removePayment(payment, $index) {
        $scope.cart.payments.splice($index, 1);

        $scope.$broadcast('payment-modal.payment-removed');
    };

    /**
     * Only needed for non-cash / voucher payments (because we know how much cash was taken).
     * The operator presses this to indicate they have received a payment via the current method, not the full amount.
     * Display the modal to enter how much was received, then add a payment of that amount to the cart.
     */
    $scope.receivedPartial = function receivedPartial() {
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/payment/partial.html',
            controller: PartialPaymentCtrl,
            windowClass: 'in partial-payment-window',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                action: function () {
                    return 'received';
                }
            }
        });

        modalInstance.result.then(
            function (response) {
                if (response.amountTendered) {
                    // Create the payment object and add it to the cart.
                    let payment = new DigiTickets.Payment();
                    payment.setTendered(response.amountTendered);
                    payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
                    if ($scope.cashbackAmount) {
                        payment.setCashback($scope.cashbackAmount);
                    }
                    payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());

                    $scope.addPayment(payment);

                    $scope.clearCashbackAmount();

                    $scope.checkRemainingBalanceAndClose();
                }
            },
            function () {
                // User cancelled the partial payment.
            }
        );
    };

    /**
     * This shows the same modal as $scope.receivedPartial, but is intended to be used BEFORE a payment is made,
     * to enter the amount that should be used.
     * This is used for integrations such as Payment Express, where the amount can be controlled.
     *
     * @param {function} callback Gets called with the amount entered.
     */
    $scope.showMakePartialModal = function showMakePartialModal(callback) {
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/payment/partial.html',
            controller: PartialPaymentCtrl,
            windowClass: 'in partial-payment-window',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                action: function () {
                    return 'make';
                }
            }
        });

        modalInstance.result.then(
            function (response) {
                if (response.amountTendered) {
                    callback(response.amountTendered);
                }
            },
            function () {
                // User cancelled the partial payment.
            }
        );
    };

    $scope.ok = function ok() {
        $scope.cart.calculate();

        // Broadcast the fact that "ok" has been pressed, and tell the controllers which tab
        // we're currently on.
        $scope.$broadcast('payment-modal.ok-pressed', $scope.cart.selectedPaymentMethod.ref);
        if ($scope.hasErrors()) {
            return;
        }

        // Unless the child controller has not told us not to, assume the full payment has been taken.
        if ($scope.addFullPaymentOnClose && (
            (!$scope.cart.isRefund() && $scope.cart.getRemainingBalance() > 0)
            || ($scope.cart.isRefund() && $scope.cart.getRemainingBalance() < 0)
        )) {
            let payment = new DigiTickets.Payment();
            payment.setTendered($scope.cart.getRemainingBalance());
            payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
            if ($scope.cashbackAmount) {
                payment.setCashback($scope.cashbackAmount);
            }
            payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
            $scope.addPayment(payment);
            $scope.clearCashbackAmount();
        }
        $scope.addFullPaymentOnClose = true; // Reset for next time OK is clicked.

        if ($scope.allowClose) {
            $scope.checkRemainingBalanceAndClose();
        } else {
            // Reset the flag. If they click Ok again, the validation will fire again and set it
            // as necessary.
            $scope.allowClose = true;
        }
    };

    $scope.checkRemainingBalanceAndClose = function checkRemainingBalanceAndClose() {
        if ($scope.cart.isRefund()) {
            if ($scope.cart.getRemainingBalance() < 0) {
                return false;
            }
        } else if ($scope.cart.getRemainingBalance() > 0) {
            return false;
        }

        // Broadcast that the modal is about to go away and there's nothing that can be done to stop it.
        // Better clean up!
        $scope.$broadcast(
            'payment-modal.closing',
            $scope.cart.selectedPaymentMethod ? $scope.cart.selectedPaymentMethod.ref : null
        );

        $modalInstance.close({});
    };

    /**
     * @param {boolean} [force]
     */
    $scope.cancel = function cancel(force) {
        $scope.setCustomerFacingStatus('');

        if (force !== true && $scope.cart.payments.length > 0) {
            // Payments have already been taken.
            // Confirm they want to do this super bad thing.
            PrivilegesService.requirePrivilege('abandon_payments').then(
                /**
                 * @param {DigiTickets.PrivilegeCheckResult} privilegeResult
                 */
                function (privilegeResult) {
                    if (privilegeResult.granted) {
                        DialogService.confirm(
                            'PAYMENT.CONFIRM_ABANDON_PAYMENTS',
                            function (result) {
                                if (result === true) {
                                    // Add a trading session event.
                                    let event = (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.ABANDON_PAYMENTS))
                                        .setAmount($scope.cart.getTotalTendered())
                                        .setData(paymentService.getPaymentsFromCartForApi(cartService))
                                        .setManagerID(privilegeResult.grantedBy);
                                    TradingSessionManager.saveEvent(event);

                                    // Cancel again with the force option.
                                    $scope.cancel(true);
                                }
                            },
                            {
                                cancelLabel: 'PAYMENT.CONFIRM_ABANDON_PAYMENTS_CANCEL',
                                cancelBtnClass: 'btn-default',
                                okLabel: 'PAYMENT.CONFIRM_ABANDON_PAYMENTS_OK',
                                okBtnClass: 'btn-danger',
                                messageParams: {
                                    count: $scope.cart.getPaymentCount(),
                                    value: $scope.cart.getTotalTendered()
                                }
                            }
                        );
                    }
                }
            );

            return false;
        }

        // Broadcast the fact that "cancel" has been pressed, and tell the controllers which tab
        // we're currently on. Controllers can prevent the closure by setting $scope.allowClose to false.
        $scope.$broadcast(
            'payment-modal.cancel-pressed',
            $scope.cart.selectedPaymentMethod ? $scope.cart.selectedPaymentMethod.ref : null
        );

        if ($scope.allowClose) {
            // Broadcast that the modal is about to go away and there's nothing that can be done to stop it.
            // Better clean up!
            $scope.$broadcast(
                'payment-modal.closing',
                $scope.cart.selectedPaymentMethod ? $scope.cart.selectedPaymentMethod.ref : null
            );

            // No-one prevented dismissing the pop-up, so just do it.
            $modalInstance.dismiss('cancel');
        } else {
            // Reset the flag. If they click cancel again, the validation will fire again and set it
            // as necessary.
            $scope.allowClose = true;
        }
    };

    /**
     * Set that the given payment method ref should not show the completed payment button at the bottom.
     *
     * @param {String} ref
     */
    $scope.registerSuppressPaymentReceivedButton = (ref) => {
        if ($scope.hidePaymentReceivedButtonForPaymentMethodRefs.indexOf(ref) === -1) {
            $scope.hidePaymentReceivedButtonForPaymentMethodRefs.push(ref);
        }
    };

    $scope.registerShowPaymentReceivedButton = (ref) => {
        const index = $scope.hidePaymentReceivedButtonForPaymentMethodRefs.indexOf(ref);
        if (index > -1) {
            $scope.hidePaymentReceivedButtonForPaymentMethodRefs.splice(index, 1);
        }
    };

    /**
     * Set that the given payment method ref should not show the partial payment button at the bottom.
     *
     * @param {String} ref
     */
    $scope.registerSuppressPartialPaymentReceivedButton = (ref) => {
        if ($scope.hidePartialPaymentReceivedButtonForPaymentMethodRefs.indexOf(ref) === -1) {
            $scope.hidePartialPaymentReceivedButtonForPaymentMethodRefs.push(ref);
        }
    };

    $scope.showPartialPaymentReceivedButton = function showPartialPaymentReceivedButton() {
        if ($scope.cart.selectedPaymentMethod) {
            return $scope.hidePartialPaymentReceivedButtonForPaymentMethodRefs.indexOf($scope.cart.selectedPaymentMethod.ref) === -1;
        }

        return false;
    };

    $scope.showPaymentReceivedButton = function showPaymentReceivedButton() {
        if ($scope.cart.selectedPaymentMethod) {
            return $scope.hidePaymentReceivedButtonForPaymentMethodRefs.indexOf($scope.cart.selectedPaymentMethod.ref) === -1;
        }

        return false;
    };

    $scope.getPartialPaymentButtonText = function getPartialPaymentButtonText() {
        if ($scope.cart.isRefund()) {
            return $filter('lang')('PAYMENT.PARTIAL_REFUND_BTN');
        }
        let ref = $scope.cart.selectedPaymentMethod ? $scope.cart.selectedPaymentMethod.ref : null;
        switch (ref) {
            case 'payment-on-account':
                return $filter('lang')('PAYMENT.PARTIAL_PAYMENT_BTN_ACCOUNT');
            default:
                return $filter('lang')('PAYMENT.PARTIAL_PAYMENT_BTN');
        }
    };

    $scope.getPaymentButtonText = function getPaymentButtonText() {
        let paymentMethodShortName = $scope.cart.selectedPaymentMethod.shortName;
        let paymentMethodRef = $scope.cart.selectedPaymentMethod.ref;

        if ($scope.cart.isRefund()) {
            switch (paymentMethodRef) {
                case PaymentMethodRef.CASH:
                case PaymentMethodRef.VOUCHER:
                    return $filter('lang')('PAYMENT.COMPLETED_REFUND_BTN', {
                        paymentMethod: paymentMethodShortName
                    });
                case PaymentMethodRef.CARD_OFFLINE:
                case PaymentMethodRef.CHEQUE:
                    return $filter('lang')('PAYMENT.COMPLETED_REFUND_AMOUNT_BTN', {
                        paymentMethod: paymentMethodShortName,
                        amount: $scope.cart.getRemainingBalance()
                    });
                default:
                    return $filter('lang')('PAYMENT.COMPLETED_REFUND_AMOUNT_BTN', {
                        paymentMethod: '',
                        amount: $scope.cart.getRemainingBalance()
                    });
            }
        }

        switch (paymentMethodRef) {
            case PaymentMethodRef.PAYMENT_ON_ACCOUNT:
                return $filter('lang')('PAYMENT.COMPLETED_PAYMENT_CONFIRM_BTN', {
                    paymentMethod: paymentMethodShortName,
                    amount: $scope.cart.getRemainingBalance()
                });
            case PaymentMethodRef.CASH:
            case PaymentMethodRef.CARD_PAYPAL_HERE:
            case PaymentMethodRef.CARD_PAYMENT_EXPRESS:
            case PaymentMethodRef.VOUCHER:
                return $filter('lang')('PAYMENT.COMPLETED_PAYMENT_BTN', {
                    paymentMethod: paymentMethodShortName
                });
            case PaymentMethodRef.CARD_OFFLINE:
            case PaymentMethodRef.CHEQUE:
            case PaymentMethodRef.FLEXI_VOUCHER:
                return $filter('lang')('PAYMENT.COMPLETED_PAYMENT_AMOUNT_BTN', {
                    paymentMethod: paymentMethodShortName,
                    amount: $scope.cart.getRemainingBalance()
                });
            default:
                return $filter('lang')('PAYMENT.COMPLETED_PAYMENT_AMOUNT_BTN', {
                    paymentMethod: '',
                    amount: $scope.cart.getRemainingBalance()
                });
        }
    };

    $scope.allowPartialPayments = function allowPartialPayments() {
        return $scope.cart.allowPartialPayments();
    };

    $scope.setCustomerFacingStatus = function setCustomerFacingStatus(status) {
        CustomerScreenDataService.updateState((state) => {
            state.paymentStatus = status;
        });
    };

    /**
     * Add a payment to the cart.
     *
     * @param {DigiTickets.Payment} payment
     */
    $scope.addPayment = function addPayment(payment) {
        let logForRefs = [
            PaymentMethodRef.CARD_PAYMENT_EXPRESS,
            PaymentMethodRef.CARD_VERIFONE_CHIP_AND_PIN,
            PaymentMethodRef.CARD_WORLDPAY_CHIP_AND_PIN,
        ];
        if (payment.paymentMethod && payment.paymentMethod.ref && logForRefs.indexOf(payment.paymentMethod.ref) !== -1) {
            /** @type {DigiTickets.Payment} */
            let clonedPayment = cloneDeep(payment);
            clonedPayment.paymentMethod = null;
            LogResource.storeIntegratedPaymentLog(
                {
                    payment: clonedPayment
                }
            );
        }

        $scope.cart.addPayment(payment);
    };

    /**
     * Cashback.
     *
     * Nothing uses this currently. The Verifone payment method will.
     */

    /**
     * The amount of cashback requested for the payment currently being taken.
     * This should be set as the cashback amount on the generated Payment object then cleared.
     *
     * @type {float|null}
     */
    $scope.cashbackAmount = null;

    /**
     * Allow cashback to be entered for the current payment?
     *
     * @return {boolean}
     */
    $scope.allowCashback = function allowCashback() {
        if (!(UserService.company.maxCashback > 0)) {
            return false;
        }

        return $scope.cart.selectedPaymentMethod && $scope.cart.selectedPaymentMethod.supportsCashback;
    };

    $scope.showCashbackButton = function showCashbackButton() {
        if (!$scope.allowCashback()) {
            return false;
        }

        let hideForRefs = [
            'card-verifone-chip-and-pin',
            'card-worldpay-chip-and-pin',
        ];

        return hideForRefs.indexOf($scope.cart.selectedPaymentMethod.ref) === -1;
    };

    /**
     * Show a modal to enter the cashback amount.
     *
     * @param {function|null} callback
     */
    $scope.enterCashback = function enterCashback(callback) {
        let maxCashback = UserService.company.maxCashback;

        DialogService.numPad(
            {
                initialValue: $scope.cashbackAmount,
                showCashButtons: true,
                showSignButton: false
            },
            $filter('lang')('PAYMENT.CASHBACK_MESSAGE', { max: maxCashback }),
            function (value) {
                if (value !== null) {
                    value = parseFloat(value);
                    $scope.cashbackAmount = value;
                    if (typeof callback === 'function') {
                        callback(value);
                    }
                }
            },
            {
                displayAsCurrency: true,
                rawMessage: true,
                min: 0,
                max: maxCashback,
                title: 'PAYMENT.CASHBACK_TITLE'
            }
        );
    };

    $scope.clearCashbackAmount = function clearCashbackAmount() {
        $scope.cashbackAmount = null;
    };

    // Initialize
    $scope.initialize();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentCtrl;
}
