const OrderTaxSLLine = require('../../models/OrderTaxSLLine');
const OrderTaxSummary = require('../../models/OrderTaxSummary');

/**
 * @param {AgentService} AgentService
 * @param {CountryManager} CountryManager
 * @param OrderResource
 */
const TaxManager = function (
    AgentService,
    CountryManager,
    OrderResource
) {
    this.agentService = AgentService;
    this.countryManager = CountryManager;
    this.orderResource = OrderResource;
};

TaxManager.prototype = {

    /**
     * @param {number} orderID
     * @param {string} taxRegistrationCode
     *
     * @return {Promise<OrderTaxSummary>}
     */
    getOrderTaxSummary(orderID, taxRegistrationCode) {
        return new Promise((resolve, reject) => {
            this.orderResource.getTaxSummary(
                {
                    id: orderID
                },
                {},
                (response) => {
                    if (response.hasOwnProperty(taxRegistrationCode)) {
                        resolve(new OrderTaxSummary(response[taxRegistrationCode]));
                    } else {
                        reject(`Tax registration code '${taxRegistrationCode}' not present in response.`);
                    }
                },
                reject
            );
        });
    },

    /**
     * Add the tax rate to the orderLine if it exists in the tax summary.
     *
     * @param {DigiTickets.Order} order
     * @param {OrderTaxSummary} taxSummary
     *
     * @returns {DigiTickets.Order} order
     */
    addTaxDataToLine(order, taxSummary) {
        if (taxSummary && taxSummary.orderLines) {
            for (let orderLine of order.items) {
                let line = taxSummary.orderLines.find((line) => line.ordersItemsID === orderLine.iteminstances[0].ordersItemsID);
                if (line && line.hasOwnProperty('rate')) {
                    orderLine.taxRate = line.rate;
                }
            }
        }
        return order;
    },

    /**
     * @param {DigiTickets.Order} order
     *
     * @return {?string}
     */
    getGreekTaxRegistrationCode(order) {
        if (!order.company) {
            return null;
        }

        let greece = this.countryManager.findByCode('GR');
        let greekTaxRegistration = order.company.getTaxRegistrationCodeForCountry(greece);

        return greekTaxRegistration && greekTaxRegistration.registrationCode ? greekTaxRegistration.registrationCode : null;
    },

    /**
     * Create the data needed for the "SL Line" (aka "e.txt") required for Greek Tax / Casio Tax Manager integrations.
     * See the attached doc at https://digitickets.atlassian.net/browse/PRO-495
     *
     * The format is a single line starting with <%SL; with each field separated by semicolons ending in >.
     *
     * e.g. <%SL;144476123;;123321456654;;;;;222;A;20;0.00;100.00;100.00;0.00;0.00;0.00;13.00;24.00;0.00;237.00;0;>
     *
     * The fields are as follows. The number in brackets is the length of the field.
     *
     * 1    Issuer's VAT    (12) NOT SENT BY APPLICATION
     * 2    Recipient's VAT    (12)
     * 3    EAFDSS S/N    (12)  NOT SENT BY APPLICATION
     * 4    Customer's Receipt Collection Card Number    (19)
     * 5    Date and Time    (12)  NOT SENT BY APPLICATION
     * 6    Serial Number of Daily Signatures    (5)  NOT SENT BY APPLICATION
     * 7    Serial Number of Successive Signatures    (5) NOT SENT BY APPLICATION
     * 8    Ζ Number    (4)  NOT SENT BY APPLICATION
     * 9    Type of Invoice    (4)
     * 10   Validation Series    (10)
     * 11   Number of Invoice    (10)
     * 12   Net Amount Α (6%)    (18:2)
     * 13   Net Amount Β (13%)    (18:2)
     * 14   Net Amount C (24%)    (18:2)
     * 15   Net Amount D (36%)    (18:2)
     * 16   Net Amount Ε (0%)   (18:2)
     * 17   VAT Α (6%)     (18:2)
     * 18   VAT Β (13%) (18:2)
     * 19   VAT C (24%)    (18:2)
     * 20   VAT D (36%)    (18:2)
     * 21   Total Amount of Invoice    (18:2)
     * 22   Currency Code    (1)
     *
     * @param {DigiTickets.Order} order
     * @param {OrderTaxSummary} orderTaxSummary
     *
     * @return {OrderTaxSLLine}
     */
    createSLLineModel(order, orderTaxSummary) {
        const e = new OrderTaxSLLine();

        /* eslint-disable line-comment-position */

        e.setField(2, null); // Customer ("client") VAT number.
        e.setField(4, null); // Customer's Receipt Collection Card Number. Don't know what this means.

        // Type of invoice. The only 2 known values are 222 (invoice) and 173 (retail POS receipt)
        // If using 222 (which we're not) the customer's VAT number should go in field 2.
        e.setField(9, 173);

        e.setField(10, 'A'); // Validation Services. ¯\_(ツ)_/¯
        e.setField(11, order.ID); // Number of invoice

        // The 'tax types' are named A/B/C/D/E rather than by the % in case the rates change in future.
        let taxA = orderTaxSummary.getLineByTaxTypeName('Greece A');
        let taxB = orderTaxSummary.getLineByTaxTypeName('Greece B');
        let taxC = orderTaxSummary.getLineByTaxTypeName('Greece C');
        let taxD = orderTaxSummary.getLineByTaxTypeName('Greece D');
        let taxE = orderTaxSummary.getLineByTaxTypeName('Greece E');

        e.setField(12, taxA ? taxA.goodsValue : 0.00); // Net Amount Α (6%)
        e.setField(13, taxB ? taxB.goodsValue : 0.00); // Net Amount B (13%)
        e.setField(14, taxC ? taxC.goodsValue : 0.00); // Net Amount C (24%)
        e.setField(15, taxD ? taxD.goodsValue : 0.00); // Net Amount D (36%)
        e.setField(16, taxE ? taxE.goodsValue : 0.00); // Net Amount Ε (0%)
        e.setField(17, taxA ? taxA.amount : 0.00); // VAT Α (6%)
        e.setField(18, taxB ? taxB.amount : 0.00); // VAT Β (13%)
        e.setField(19, taxC ? taxC.amount : 0.00); // VAT C (24%)
        e.setField(20, taxD ? taxD.amount : 0.00); // VAT D (36%)
        e.setField(21, order.total); // Total Amount of Invoice (including tax)
        e.setField(22, 0); // Currency Code. This field is unused - zero = blank.

        /* eslint-enable line-comment-position */

        return e;
    },

    /**
     * According to Michael at EAMH System to produce an SL line for a duplicate receipt:
     * In the copy/duplicate, you can send the <%SL…> script with Invoice Type Code "502"
     * and all the amounts in the fields should be "0.00"
     *
     * @param {DigiTickets.Order} order
     *
     * @return {OrderTaxSLLine}
     */
    createSLLineModelForReprint(order) {
        const e = new OrderTaxSLLine();

        /* eslint-disable line-comment-position */

        e.setField(2, null); // Customer ("client") VAT number.
        e.setField(4, null); // Customer's Receipt Collection Card Number.
        e.setField(9, 502); // Type of invoice. 502 = reprint apparently.
        e.setField(10, 'A'); // Validation Services.
        e.setField(11, order.ID); // Number of invoice
        e.setField(12, 0.00); // Net Amount Α (6%)
        e.setField(13, 0.00); // Net Amount B (13%)
        e.setField(14, 0.00); // Net Amount C (24%)
        e.setField(15, 0.00); // Net Amount D (36%)
        e.setField(16, 0.00); // Net Amount Ε (0%)
        e.setField(17, 0.00); // VAT Α (6%)
        e.setField(18, 0.00); // VAT Β (13%)
        e.setField(19, 0.00); // VAT C (24%)
        e.setField(20, 0.00); // VAT D (36%)
        e.setField(21, 0.00); // Total Amount of Invoice (including tax)
        e.setField(22, 0); // Currency Code

        /* eslint-enable line-comment-position */

        return e;
    },

    /**
     * Returns an OrderTaxSLLine model for the order order, using the tax breakdown for the given registration code.
     *
     * @param {DigiTickets.Order} order
     * @param {string} taxRegistrationCode
     *
     * @return {Promise<OrderTaxSLLine>}
     */
    async getTaxSummaryAndCreateSLLineModel(order, taxRegistrationCode) {
        return this.getOrderTaxSummary(order.ID, taxRegistrationCode)
            .then((taxSummary) => [this.createSLLineModel(order, taxSummary), taxSummary]);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TaxManager;
}
