const moment = require('moment');
const { toDate, toInt } = require('../functions/transform');

DigiTickets.Contact = function () {
    /**
     * @type {Date|string}
     */
    this.dateOfBirth = null;

    /**
     * @type {string}
     */
    this.email = '';

    /**
     * @type {boolean}
     */
    this.emailIsValid = true;

    /**
     * @type {string}
     */
    this.firstName = '';

    /**
     * @type {number}
     */
    this.ID = null;

    /**
     * This isChild flag is toggled by the 'Adult / Child (under x)' button in the membership dataset modal.
     *
     * @type {boolean}
     */
    this.isChild = false;

    /**
     * @type {string}
     */
    this.lastName = '';

    /**
     * May be resolved when retrieving all contacts for an account.
     *
     * @type {DigiTickets.Member[]}
     */
    this.members = [];

    /**
     * Filename of contact's photo.
     *
     * @type {string|null}
     */
    this.photo = null;

    /**
     * base64 encoded data for a new photo (sent to API when creating member)
     *
     * @type {string}
     */
    this.photoData = '';

    /**
     * @type {string|null}
     */
    this.ref = null;

    /**
     * @type {string}
     */
    this.tel = '';

    /**
     * @type {string|null}
     */
    this.title = null;

    /**
     * @type {?number[]}
     */
    this.marketingPreferences = null;
};

DigiTickets.Contact.prototype = {
    getHydrationMap() {
        return {
            dateOfBirth: toDate,
            email: {},
            firstName: {},
            ID: {
                field: ['ID', 'contactID'],
                transform: toInt
            },
            lastName: {},
            photo: {},
            ref: {},
            tel: {},
            title: {}
        };
    },

    /**
     * We don't want to re-ask marketing preferences for existing accounts so set it to a non-null value.
     */
    afterHydration() {
        this.marketingPreferences = [];
    },

    /**
     * @return {string}
     */
    getPoliteAge: function getPoliteAge() {
        if (!this.dateOfBirth) {
            return 'Age unknown';
        }

        let age = moment().diff(moment(this.dateOfBirth), 'years');

        if (age >= 18) {
            return 'Over 18';
        }

        let plural = age > 1 ? 's' : '';
        return moment(this.dateOfBirth).format('DD/MM/YYYY') + ' - ' + age + ' year' + plural + ' old';
    },

    getFullName: function getFullName() {
        // TODO: Should this use the title?
        return [this.firstName, this.lastName].join(' ').trim();
    },

    get name() {
        /** @todo remove getFullName() method above and replace with calls to this */
        return [this.firstName, this.lastName].join(' ').trim();
    },

    set name(value) {
        // Do nothing.
    },

    hasPhoto: function hasPhoto() {
        return this.photo !== null || this.photoData !== '';
    },

    hasPhotoData: function hasPhotoData() {
        return this.photoData !== '';
    },

    getPhotoPath: function getPhotoPath(width, height) {
        if (!width) {
            width = 600;
        }

        if (!height) {
            height = 450;
        }

        return '/userfiles/contacts_photos/'
                + this.photo
                + '?' + this.ID
                + (width ? '&amp;w=' + width : '')
                + (height ? '&amp;h=' + height : '')
                + '&amp;zc=1';
    },

    hasData: function hasData() {
        return !!(this.firstName || this.lastName || this.email || this.tel || this.photo || this.photoData);
    },

    isEmpty: function isEmpty() {
        return !this.hasData();
    },

    /**
     * @return {string|null}
     */
    generateOriginalReference: function generateOriginalReference() {
        if (!this.ID) {
            return null;
        }

        let leadingZeros = '0000000';
        let ID = this.ID.toString();
        return 'P' + leadingZeros.substring(0, leadingZeros.length - ID.length) + ID;
    },

    /**
     * Return all the data that should be sent to the API when creating or updating this contact.
     *
     * @return {object}
     */
    toServerData: function toServerData() {
        return {
            assignedMemberRef: this.ref,
            contactID: this.ID,
            dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toYMD() : '',
            email: this.email,
            firstName: this.firstName,
            ID: this.ID,
            lastName: this.lastName,
            marketingPreferences: this.marketingPreferences,
            photo: this.photo,
            photoData: this.photoData,
            ref: this.ref,
            tel: this.tel,
            title: this.title
        };
    }
};
