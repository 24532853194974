const { toBool, toInt, toNullableInt } = require('../functions/transform');

DigiTickets.Member = function () {
    /**
     * Simple name/values
     */

    /**
     * @type {number|null}
     */
    this.ID = null;
    this.contactID = null;

    /**
     * The contact contains the member's firstName, lastName, photo, email, etc.
     *
     * @type {DigiTickets.Contact}
     */
    this.contact = null;

    /**
     * Populated during DigiTickets.Membership.getMembers()
     * Allows a member to inform if it is in an active or inactive membership.
     *
     * @type {DigiTickets.Membership}
     */
    this.membership = null;

    // Used for individual member redemptions.
    this.currentlyRedeeming = false;
    this.currentlyUnredeeming = false;
    this.visitedToday = false;
    this.visitCount = 0;

    // Used for maintenance
    this.index = 0; // used for counting up existing ones.
    this.label = 'MEMBERS_AND_ADDRESS.PRIMARY_MEMBER';
    this.dirty = false;
};

DigiTickets.Member.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'memberID'],
                transform: toInt
            },
            contact: {
                field: ['contact', 'contacts'],
                model: DigiTickets.Contact,
                /**
                 * @param {DigiTickets.Contact} value
                 * @return {?DigiTickets.Contact}
                 */
                transform(value) {
                    // Prevent empty contacts being created but check for firstName rather ID so that we don't
                    // end up removing members when we restore an orderInProgress where usually only the first
                    // member will have a contact.ID as this stage.
                    if (!value || !value.firstName) {
                        return null;
                    }

                    return value;
                }
            },
            contactID: toNullableInt,
            visitCount: toInt,
            visitedToday: toBool
        };
    },

    hasContact: function hasContact() {
        return !!this.contact;
    },

    /**
     * @param {DigiTickets.Contact|null} contact
     */
    setContact: function setContact(contact) {
        if (contact) {
            this.contact = contact;
            this.contactID = contact.ID;
        } else {
            this.contact = null;
            this.contactID = null;
        }
    },

    getID: function getID() {
        return this.ID;
    },

    hasVisitedToday: function hasVisitedToday() {
        return this.visitedToday;
    },

    getVisits: function getVisits() {
        return this.visitCount;
    },

    setVisits: function setVisits(memberRedemptionData) {
        // The object passed in contains:
        //  the new member visit count
        //  whether the member has visited today
        this.visitedToday = memberRedemptionData.hasOwnProperty('isRedeemedForDay') ? !!memberRedemptionData.isRedeemedForDay : false;
        this.visitCount = memberRedemptionData.hasOwnProperty('visitCount') ? parseInt(memberRedemptionData.visitCount) : 0;
    },

    setIndex: function setIndex(index) {
        this.index = index;
        if (index === 0) {
            this.label = 'MEMBERS_AND_ADDRESS.PRIMARY_MEMBER';
        } else {
            this.label = ['MEMBERS_AND_ADDRESS.MEMBER', 1 + index];
        }
    }
};
