const BigNumber = require('bignumber.js');
const PaymentMethod = require('../libraries/DigiTickets/PaymentMethods/PaymentMethod');
const { toBool, toDate, toFloat, toNullableInt, toString, toNullableString } = require('../functions/transform');

DigiTickets.Payment = function () {
    /**
     * The total value of the payment.
     * You should not modify this directly. Instead call setTendered/setChangeGiven which will recalculate this.
     *
     * @type {number}
     */
    this.amount = 0.00;
    this.changeGiven = 0.00;
    this.cashback = 0.00;
    this.gratuity = 0.00;
    this.ID = null;
    this.paid = true;
    this.pending = false;
    this.authCode = null;
    this.securityKey = null;
    this.status = null;

    /**
     * The complete raw response from the payment provider.
     * e.g. XML from Payment Express, or raw lines from WorldPay.
     *
     * @type {string|null}
     */
    this.statusDetail = null;

    this.takenAt = new Date();
    this.tendered = 0;

    /**
     * The reference for this transaction at the payment provider.
     *
     * @type {string|null}
     */
    this.transactionRef = null;

    /**
     * @type {string|null}
     */
    this.thirdPartyID = null;

    /**
     * A token to refer back to the card/form of payment used.
     * Used if a new token was generated by this payment.
     *
     * @type {string|null}
     */
    this.token = null;

    /**
     * Used if an existing token was used for this payment.
     *
     * @type {number|null}
     */
    this.paymentTokenID = null;

    /**
     * For vouchers, this is the face value of the voucher. But as we don't give change for vouchers,
     * the amount/tendered may be less than this.
     *
     * @type {number|null}
     */
    this.value = null;

    /**
     * @type {PaymentMethod}
     */
    this.paymentMethod = null;

    /**
     * Last 4 digits of the card number / account number..
     *
     * @type {int}
     */
    this.lastDigits = null;

    /**
     * @type {DigiTickets.PaymentChannel}
     */
    this.paymentChannel = null;

    /**
     * Remember what type of card was used, so it can be stored as the customerReference along with the last 4
     * digits of the card in a CustomerAccountPaymentToken.
     *
     * @type {string|null}
     */
    this.cardTypeRef = null;

    /**
     * Additional debugging info.
     * This will be sent to the API but is not stored with the payment. It will however be logged when the
     * payment is sent to the integrated payments log endpoint.
     *
     * @type {{}}
     */
    this.debugInfo = {};
};

DigiTickets.Payment.prototype = {
    calculateAmount() {
        const tendered = new BigNumber(this.tendered.toFixed(8));
        const changeGiven = new BigNumber(this.changeGiven.toFixed(8));

        this.amount = tendered.minus(changeGiven).toNumber();
    },

    /**
     * @param {string|null} cardTypeRef
     */
    setCardTypeRef: function (cardTypeRef) {
        this.cardTypeRef = cardTypeRef;
    },

    /**
     * Return a human readable card type from the ref.
     *
     * @return {string}
     */
    getReadableCardTypeRef: function () {
        if (this.cardTypeRef) {
            switch (this.cardTypeRef) {
                case DigiTickets.CardTypeRef.AMEX:
                    return 'Amex';
                case DigiTickets.CardTypeRef.DINERS:
                    return 'Diners';
                case DigiTickets.CardTypeRef.DISCOVER:
                    return 'Discover';
                case DigiTickets.CardTypeRef.JCB:
                    return 'JCB';
                case DigiTickets.CardTypeRef.LASER:
                    return 'Laser';
                case DigiTickets.CardTypeRef.MAESTRO:
                    return 'Maestro';
                case DigiTickets.CardTypeRef.MASTERCARD:
                    return 'Mastercard';
                case DigiTickets.CardTypeRef.VISA_CREDIT:
                case DigiTickets.CardTypeRef.VISA_DEBIT:
                case DigiTickets.CardTypeRef.VISA_ELECTRON:
                    return 'Visa';
            }
        }

        return '';
    },

    /**
     * @param {float|null} gratuity
     */
    setGratuity: function (gratuity) {
        this.gratuity = gratuity ? parseFloat(gratuity) : 0.00;
    },

    /**
     * @return {number|null}
     */
    getPaymentMethodID() {
        return this.paymentMethod ? this.paymentMethod.ID : null;
    },

    /**
     * @param {PaymentMethod} paymentMethod
     */
    setPaymentMethod: function (paymentMethod) {
        this.paymentMethod = paymentMethod;
    },

    /**
     * @param {string|null} thirdPartyID
     */
    setThirdPartyID: function (thirdPartyID) {
        this.thirdPartyID = thirdPartyID || null;
    },

    setStatus: function (status) {
        this.status = status;
    },

    setStatusDetail: function (statusDetail) {
        this.statusDetail = statusDetail;
    },

    setTransactionRef: function (transactionRef) {
        this.transactionRef = transactionRef;
    },

    setSecurityKey: function (securityKey) {
        this.securityKey = securityKey;
    },

    setAuthCode: function (authCode) {
        this.authCode = authCode;
    },

    setToken: function (token) {
        this.token = token;
    },

    setPaymentTokenID: function setPaymentTokenID(paymentTokenID) {
        this.paymentTokenID = paymentTokenID;
    },

    setTendered: function (amountTendered) {
        this.tendered = parseFloat(amountTendered);
        this.calculateAmount();
    },

    setValue: function (value) {
        this.value = value;
    },

    setChangeGiven: function (changeGiven) {
        this.changeGiven = parseFloat(changeGiven);
        this.calculateAmount();
    },

    setLastDigits: function setLastDigits(lastDigits) {
        this.lastDigits = lastDigits;
    },

    /**
     * @param {number} cashback
     */
    setCashback: function (cashback) {
        this.cashback = cashback ? parseFloat(cashback) : 0.00;
    },

    getHydrationMap() {
        return {
            authCode: toNullableString,
            cashback: toFloat,
            cardTypeRef: toString,
            changeGiven: toFloat,
            gratuity: toFloat,
            ID: {
                field: ['ID', 'paymentID'],
                transform: toNullableInt
            },
            lastDigits: {},
            paid: toBool,
            pending: toBool,
            paymentChannel: {
                field: ['paymentChannel', 'paymentchannels'],
                model: DigiTickets.PaymentChannel
            },
            paymentMethod: {
                field: ['paymentMethod', 'paymentmethods'],
                model: PaymentMethod
            },
            securityKey: toNullableString,
            status: toNullableString,
            statusDetail: toNullableString,
            takenAt: toDate,
            tendered: toFloat,
            thirdPartyID: toNullableString,
            transactionRef: toNullableString
        };
    },

    afterHydration: function afterHydration() {
        this.calculateAmount();
    }
};
