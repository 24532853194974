const FloatAdjustment = require('../../models/FloatAdjustment');

/**
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param {ErrorPresenter} errorPresenter
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 * @param {FloatAdjustmentReason[]} floatAdjustmentReasons
 * @param {{adjustmentAmountLabel: string, title: string}} templateText
 */
const AdjustFloatCtrl = function AdjustFloatCtrl(
    $filter,
    $modalInstance,
    $scope,
    errorPresenter,
    NumPadFactory,
    floatAdjustmentReasons,
    templateText
) {
    $scope.floatAdjustmentReasons = floatAdjustmentReasons;

    // This data will be used to create a FloatAdjustment when done.
    $scope.data = {
        amount: null,
        // Select the first reason by default.
        floatAdjustmentReason: floatAdjustmentReasons[0],
        narrative: null
    };

    /**
     * Error message displayed to the user.
     *
     * @type {?string}
     */
    $scope.error = null;

    $scope.numPad = NumPadFactory.create(
        {
            allowNull: false,
            initialValue: 0.00,
            showDecimalButton: true,
            showSignButton: false,
            numDecimalPlaces: 2,
            showCashButtons: true,
            doNotCaptureKeysIn: ['textarea']
        },
        (value) => {
            $scope.data.amount = value.toFixed(2);
        }
    );

    $scope.templateText = templateText;

    $scope.ok = function ok() {
        $scope.error = null;

        try {
            // Create a model from the data and return it.
            let floatAdjustment = new FloatAdjustment(
                $scope.data.floatAdjustmentReason,
                $scope.data.amount,
                $scope.data.narrative
            );

            $modalInstance.close({
                floatAdjustment
            });
        } catch (error) {
            $scope.error = errorPresenter.getText(error);
            console.log(error);
        }
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AdjustFloatCtrl;
}
