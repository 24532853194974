const { populate } = require('../../../functions/objects');

class KitchenOrderItem {
    constructor(data) {
        /**
         * Field data for this line, keyed by fieldID.
         *
         * @see {FieldGroup.getDisplayValues}
         * @type {Object<{fieldID: number, question: string, answer: string[]}>}
         */
        this.fieldData = {};

        /**
         * @type {?string}
         */
        this.itemID = null;

        /**
         * @type {?string}
         */
        this.itemName = null;

        /**
         * @type {number}
         */
        this.qty = 0;

        /**
         * @see kitchenOrderItemHash
         *
         * @type {string}
         */
        this.hash = '';

        /**
         * @type {number} Can be incremented to keep track of order history
         */
        this._versionIndex = 0;

        /**
         * @type {boolean} Whether this line is an addition to the original order
         */
        this._isNew = false;

        /**
         * @type {boolean} Whether this line has been fulfilled
         */
        this.isFulfilled = false;

        populate(this, data);
    }

    /**
     * @param {Array<{fieldID: number, question: string, answer: string[]}>} fieldData
     */
    addFieldData(fieldData) {
        for (let i = 0; i < fieldData.length; i++) {
            this.fieldData[fieldData[i].fieldID] = fieldData[i];
        }
    }

    setQty(qty) {
        this.qty = qty;
        return this;
    }

    /**
     * @return {string} A unique reference to this line
     */
    get key() {
        return this.hash + '-' + this.versionIndex + '-' + this.isFulfilled;
    }

    /**
     * @return {boolean} Whether this is a historic line which has been deleted or replaced by another
     */
    get isHistoric() {
        return this.versionIndex > 0;
    }

    /**
     * @return {boolean}
     */
    get isNew() {
        return this._isNew;
    }

    /**
     * @param {boolean} value
     */
    set isNew(value) {
        this._isNew = this.isHistoric ? false : !!value;
    }

    /**
     * @return {number} Zero-based index. 0 = an original line; 1 = most recent change; 2 = 2nd most recent change; etc
     */
    get versionIndex() {
        return this._versionIndex;
    }

    /**
     * @param {number} value
     */
    set versionIndex(value) {
        this._versionIndex = parseInt(value);
        this.isNew = this.isHistoric ? false : this._isNew;
    }
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = KitchenOrderItem;
}
