const { cloneDeep } = require('../../functions/clone');

/**
 * Handles storing/retrieving stashed carts locally and via the API.
 *
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param StashedCartResource
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
const CartStasher = function (
    CartStashHelperService,
    ConnectivityChecker,
    StashedCartResource,
    UserService,
    UserSessionManager
) {
    this.cartStashHelperService = CartStashHelperService;
    this.connectivityChecker = ConnectivityChecker;
    this.stashedCartResource = StashedCartResource;
    this.userService = UserService;
    this.userSessionManager = UserSessionManager;
};

CartStasher.prototype = {
    /**
     * @param {CartService} cart
     * @param {boolean} requiresFulfillment (a.k.a sendToKitchen)
     *
     * @return {Promise<DigiTickets.StashedCart>}
     */
    stashCart: async function stashCart(cart, requiresFulfillment) {
        const stashedCart = this.cartStashHelperService.cartToStash(cart);

        if (requiresFulfillment && !stashedCart.fulfillmentCreatedAt) {
            // Set the fulfillmentCreatedAt date of the stash, as this should ideally be the same time
            // on the printout at the EPOS and kitchen.
            stashedCart.fulfillmentCreatedAt = new Date();
        }

        return Promise.all([
            // Stash locally.
            this.userSessionManager
                .getSession(this.userService.getUserID())
                .then((userSession) => {
                    if (userSession) {
                        userSession.addStashedCart(stashedCart);
                        return this.userSessionManager.persist(userSession);
                    }
                }),
            // Stash on API.
            new Promise((resolve) => {
                this.storeRemoteStashedCart(stashedCart, requiresFulfillment, resolve);
            }),
        ])
            .then(() => stashedCart);
    },

    /**
     * FIXME: Change to merge two stashes together, instead of a cart with a stash.
     *
     * Merge the cart into an existing stashed cart.
     *
     * @param {CartService} cart
     * @param {DigiTickets.StashedCart} targetStashedCart
     * @param {boolean} requiresFulfillment
     */
    mergeWithStash: function mergeWithStash(cart, targetStashedCart, requiresFulfillment) {
        let newStashedCart = this.cartStashHelperService.cartToStash(cart);

        newStashedCart.items.forEach(
            (newItem) => {
                let foundMatch = false;

                // Loop through items to find items with the same id, type, and sessionId
                // and add to the quantity
                targetStashedCart.items.forEach(
                    (stashedItem) => {
                        if (newItem.itemId === stashedItem.itemId && newItem.itemType === stashedItem.itemType && newItem.sessionId === stashedItem.sessionId) {
                            stashedItem.quantity += newItem.quantity;

                            // If both item types are 'Membership Plan' concat the membershipDatasets arrays
                            if (newItem.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN) {
                                stashedItem.membershipDatasets = stashedItem.membershipDatasets.concat(newItem.membershipDatasets);
                            }

                            foundMatch = true;
                        }
                    }
                );

                if (!foundMatch) {
                    targetStashedCart.items.push(newItem);
                }
            }
        );

        if (!targetStashedCart.customer.hasData()) {
            // Use all new customer info if existing stash has no customer info.
            targetStashedCart.customer = newStashedCart.customer;
        } else if (!targetStashedCart.customer.account && newStashedCart.customer.account) {
            // The new customer has an account and the target does not.
            // We've already established the data is similar enough at this point.
            // Set the account from the new stash.
            targetStashedCart.customer.account = newStashedCart.customer.account;
        }

        this.userSessionManager.getSession(this.userService.ID)
            .then((userSession) => {
                if (userSession) {
                    userSession.addStashedCart(targetStashedCart);
                    return this.userSessionManager.persist(userSession);
                }
            });
        this.storeRemoteStashedCart(targetStashedCart, requiresFulfillment);

        return targetStashedCart;
    },

    /**
     * @return {Promise<DigiTickets.StashedCart[]>}
     * @param type
     */
    getLocalStashedCarts: function getLocalStashedCarts(type) {
        return this.userSessionManager.getStashedCarts()
            .then((stashedCarts) => stashedCarts.map((stashedCart) => {
                stashedCart.stashSource = 'local';
                return stashedCart;
            }));
    },

    loadStashedCarts: function loadStashedCarts() {
        return new Promise((resolve) => {
            this.getLocalStashedCarts()
                .then((localStashedCarts) => {
                    if (!this.connectivityChecker.isOnline()) {
                        // Offline. Just return the locally stashed carts.
                        this.cartStashHelperService.sortStashedCarts(localStashedCarts);
                        resolve(localStashedCarts);
                        return;
                    }

                    this.getRemoteStashedCarts(
                        this.userService.currentBranch.ID,
                        async (remoteStashedCarts) => {
                            let remoteGuids = [];
                            for (let ri = 0; ri < remoteStashedCarts.length; ri++) {
                                remoteGuids.push(remoteStashedCarts[ri].guid);
                            }

                            // Merge local stashed carts into remote stashed carts.
                            for (let li = 0; li < localStashedCarts.length; li++) {
                                let localStash = localStashedCarts[li];

                                if (localStash.stashedCartID && remoteGuids.indexOf(localStash.guid) === -1) {
                                    // If the local stash has a stashedCartID, but it is not in the remote stashes,
                                    // that means it has been deleted on another device.
                                    // Delete the local stash
                                    await this.userSessionManager.deleteStashedCartByGuid(localStash.guid);
                                } else if (remoteGuids.indexOf(localStash.guid) === -1) {
                                    // Skip showing local stashes already returned from remote stashes.
                                    remoteStashedCarts.push(localStash);
                                }
                            }

                            this.cartStashHelperService.sortStashedCarts(remoteStashedCarts);
                            resolve(remoteStashedCarts);
                        }
                    );
                });
        });
    },

    /**
     * Find a stashed cart with the given staffRef in the given array of stashed carts.
     *
     * @param {DigiTickets.StashedCart[]} stashedCarts
     * @param {string} staffRef
     *
     * @return {DigiTickets.StashedCart|null}
     */
    extractByStaffRef: function extractByStaffRef(stashedCarts, staffRef) {
        staffRef = staffRef.toLowerCase();

        for (let i = 0; i < stashedCarts.length; i++) {
            try {
                if (stashedCarts[i].staffRef
                            && stashedCarts[i].staffRef.toLowerCase() === staffRef
                ) {
                    return stashedCarts[i];
                }
            } catch (e) {
                // Just in case staffRef is not a string for some reason.
            }
        }

        return null;
    },

    /**
     * Update the version of a stashed cart in the database.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     * @param {boolean} requiresFulfillment
     * @param {Function} [callback]
     */
    storeRemoteStashedCart: function storeRemoteStashedCart(stashedCart, requiresFulfillment, callback) {
        if (!stashedCart.guid) {
            return;
        }

        let requestData = stashedCart.toServerData();
        requestData.requiresFulfillment = requiresFulfillment ? 1 : 0;

        this.stashedCartResource.save(
            requestData,
            function (response) {
                stashedCart.stashedCartID = response.stashedCartID;

                if (callback) {
                    callback(stashedCart);
                }
            }
        );
    },

    /**
     * Remove a stashed cart from the local and remote storage.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     */
    deleteStashedCart: function deleteStashedCart(stashedCart) {
        // Delete from the server if it exists there.
        this.deleteRemoteStashedCart(
            stashedCart,
            null
        );

        // Due to the nature of guids it should be safe to delete stashed carts from any user that
        // match the guid, as there should only be one.
        this.userSessionManager.deleteStashedCartByGuid(stashedCart.guid);
    },

    /**
     * Delete a stashed cart from the database.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     * @param {function} [callback]
     */
    deleteRemoteStashedCart: function deleteRemoteStashedCart(stashedCart, callback) {
        this.stashedCartResource.delete(
            {
                guid: stashedCart.guid
            },
            function (response) {
                if (callback) {
                    callback(response.success);
                }
            }
        );
    },

    getRemoteStashedCarts: function getRemoteStashedCarts(branchID, callback) {
        const self = this;
        this.stashedCartResource.query(
            {
                branchID,
                resolve: '*'
            },
            function (response) {
                let stashedCarts = [];
                for (let i = 0; i < response.length; i++) {
                    let stashedCart = self.cartStashHelperService.createStashedCartFromRemoteData(response[i]);
                    stashedCart.stashSource = 'remote';
                    stashedCarts.push(stashedCart);
                }

                callback(stashedCarts);
            }
        );
    },

    /**
     * Loads stashed carts that have been sent to the kitchen screen that have not yet been fulfilled.
     *
     * @param {Function} callback
     */
    findUnfulfilledStashedCarts: function findUnfulfilledStashedCarts(callback) {
        const self = this;
        this.stashedCartResource.queryUnfulfilled(
            function (response) {
                let stashedCarts = [];
                for (let i = 0; i < response.length; i++) {
                    let stashedCart = self.cartStashHelperService.createStashedCartFromRemoteData(response[i]);
                    stashedCarts.push(stashedCart);
                }

                callback(stashedCarts);
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartStasher;
}
