/**
 * @param {UserService} UserService
 */
const nameWithTitleDirective = function (UserService) {
    return {
        restrict: 'E',
        scope: {
            contact: '='
        },
        template: function template() {
            // We want to show "title firstName lastName", but suppress the title if the company
            // does not have them enabled.
            let titleTemplate = UserService.company.shouldCaptureTitles() ? '{{ contact.title }} ' : '';

            return titleTemplate + '{{ contact.firstName }} {{ contact.lastName }}';
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = nameWithTitleDirective;
}
