const is = require('../../Is');

/**
 * The Hydrator fills properties of models from an object containing its data (usually from the API).
 *
 * The model being hydrated must implement a getHydrationMap() method which returns a map of which data to put in
 * which property of the model. An example and possible options follow...
 *
 * getHydrationMap() {
 *     return {
 *         // The simplest case is to to set no options, which will set model.someProp to the value of
 *         // 'someProp' in the incoming data.
 *         // This will set the data as-is an not transform it in any way.
 *         someProp: {}
 *
 *         // For more complex configuration, you can set some options...
 *         // (All are optional)
 *
 *         someProp: {
 *
 *             // ** field **
 *
 *             // Specify the property name of the value in the incoming data.
 *             // If omitted, the default value is the same as the property on the model (someProp in this case).
 *             // Can be omitted if the model's property name is the same as the property name in the data.
 *             // This value is case insensitive.
 *             field: 'propNameInData',
 *
 *             // Field can also be an array, and the first key that exists in the data will be used:
 *             field: ['firstName', 'name'],
 *
 *             // Field can also be a function, which will receive all the data and should return the *value* to use
 *             // (not the key).
 *             field(data) {
 *                 return data.firstName || data.name;
 *             }
 *
 *             // ** transform **
 *
 *             // Apply some transformation function to the value before setting it on the model.
 *             // The first parameter will be the value of the the field and the second is all of the data.
 *             // This function may do some modifications then should return the value of the field.
 *             transform(value, allData) {
 *                 return value + 100;
 *             },
 *
 *             // See functions/transform.js for a lot of ready made transform functions that can be used here. e.g.
 *             transform: toInt,
 *             transform: toBool,
 *
 *             // ** model **
 *
 *             // The someProp property can be set to an instance of another model that will be created and
 *             // hydrated with the data for this field.
 *             // Note that if using this in combination with a transform function the transform is called after this.
 *             model: ChildModel,
 *
 *             // ** modelCollection **
 *
 *             // Works the same as 'model' but is used to create an array of related models.
 *             // The field data should be an array for this to work as expected.
 *             // In this example the result will be an array of ChildModels
 *             // Note: If the input value is an object the result will be an object, with keys preserved.
 *             // If the input value is an array the result will be an array but keys will not be preserved.
 *             modelCollection: ChildModel
 *
 *         },
 *
 *         // Shorthand
 *
 *         // If you just want to use a transform function only you can write it like this:
 *         someProp: value => value * 2,
 *
 *         // or if using an existing transform function:
 *         someProp: toDate,
 *
 *         // This is the equivalent of:
 *         someProp: {
 *             transform: toDate
 *         }
 *     }
 * }
 *
 * Be careful when specifying an alternate field name, because a model may be run through the hydrator multiple times.
 * See this example:
 * {
 *     ID: {
 *         field: 'thingID',
 *         transform: toInt
 *     }
 * }
 * On the first pass model.ID will correctly be set as data.thingID and cast to an integer.
 * But if the model is run through the hydrator again using the data from the model there is no longer a 'thingID'
 * property.
 * To solve this you almost always want to use an array of fields:
 * {
 *     ID: {
 *         field: ['ID' 'thingID'],
 *         transform: toInt
 *     }
 * }
 * This will work with both data from the API and already hydrated data.
 */


const Hydrator = function ($injector) {
    this.$injector = $injector;
};

Hydrator.prototype = {
    /**
     * @param {array} data
     * @param {function} modelFactory Function to return an instance of a model e.g. () => new Model;
     *
     * @return {Array}
     */
    hydrateArray(data, modelFactory) {
        let results = [];
        // This is forEach instead of a map because we want to reset array keys.
        data.forEach((datum) => results.push(this.hydrate(datum, modelFactory())));

        return results;
    },

    /**
     * @param {object} data
     * @param {object} model A single model instance.
     *
     * @return {object} Returns the hydrated model (the passed in model is also modified anyway).
     */
    hydrate(data, model) {
        if (!is.aFunction(model.getHydrationMap)) {
            throw new Error('Cannot hydrate an entity/model that does not implement getHydrationMap().');
        }

        const hydrationMap = model.getHydrationMap();
        if (!is.anObject(hydrationMap)) {
            console.error('Invalid hydration map returned.', hydrationMap);
            throw new Error('Invalid hydration map returned by getHydrationMap().');
        }

        if (!data || !is.anObject(data)) {
            // there's no data to do anything with, so quit now
            console.warn('Unable to hydrate model because data is not an object.', model, data);
            return model;
        }

        // Extract the field names from the data because we are going to make a lowercase version of them all.
        // The casting to string here isn't necessary because object keys are always strings, but just in case...
        let dataKeys = Object.keys(data).map((key) => String(key).toLowerCase());
        let dataValues = Object.values(data);

        Object.keys(hydrationMap).forEach((propertyName) => {
            // TODO: Would be good to cache the result of parsePropertyConfig.
            let propertyConfig = this.parsePropertyConfig(propertyName, hydrationMap[propertyName]);

            let propertyValue = this.getPropertyValue(propertyName, propertyConfig, data, dataKeys, dataValues);

            this.fill(model, propertyName, propertyConfig, propertyValue, data);
        });

        if (is.aFunction(model.afterHydration)) {
            this.$injector.invoke(
                model.afterHydration,
                model,
                {
                    // These are parameters/values that can be injected to an afterHydration function
                    // along with any other service in the app by using its name.
                    // It is important the the property name 'data' is used if you want the data.
                    // It is no longer positional!
                    data
                }
            );
        }

        // Mark the model as hydrated.
        // This helps transform methods to know which field they should work with if the data is already hydrated vs
        // data fresh from the API.
        model.isHydrated = true;

        return model;
    },

    /**
     * @param {string} propertyName
     * @param {*} propertyConfig
     *
     * @return {{field: string, modelCollection?: function, model?: function, transform?: function}}
     */
    parsePropertyConfig(propertyName, propertyConfig) {
        // Check if the property config is a shorthand transform function.
        if (is.aFunction(propertyConfig)) {
            propertyConfig = {
                transform: propertyConfig
            };
        }

        // Beyond this point propertyConfig is an object.

        // If the config has no field name use the propertyName as the field name.
        if (!propertyConfig.hasOwnProperty('field')) {
            propertyConfig.field = propertyName;
        }

        if (propertyConfig.model && propertyConfig.modelCollection) {
            throw new Error(`Error in propertyConfig for '${propertyName}'. Only one of model or modelCollection should be specified.`);
        }

        return propertyConfig;
    },

    /**
     * @param {string} propertyName
     * @param {object} propertyConfig
     * @param {object} data
     * @param {string[]} dataKeys
     * @param {*[]} dataValues
     *
     * @return {undefined|*}
     */
    getPropertyValue(propertyName, propertyConfig, data, dataKeys, dataValues) {
        if (is.aFunction(propertyConfig.field)) {
            // Allow functions to specify how to locate the required data.
            return propertyConfig.field(data);
        }

        if (propertyConfig.field === '*') {
            // Allow wildcard field values (assigned the entire dataset for the model).
            // This should be used in conjunction with a transform function.
            return data;
        }

        if (is.anArray(propertyConfig.field)) {
            // Allow multiple alternative field values (in order of preference).
            for (let n = 0; n < propertyConfig.field.length; n++) {
                if (data.hasOwnProperty(propertyConfig.field[n])) {
                    return data[propertyConfig.field[n]];
                }
            }
        }

        if (propertyConfig.field) {
            // Use the specified field name (case insensitive).
            let lowerField = String(propertyConfig.field).toLowerCase();
            let valueIndex = dataKeys.indexOf(lowerField);
            if (valueIndex !== -1) {
                return dataValues[valueIndex];
            }
        }

        return undefined;
    },

    /**
     * @private
     *
     * Set the property on the model to the appropriate value.
     *
     * @param model
     * @param propertyName
     * @param propertyConfig
     * @param propertyValue
     * @param data All data.
     */
    fill(model, propertyName, propertyConfig, propertyValue, data) {
        if (propertyValue === undefined) {
            // Leave undefined properties alone.
            return;
        }

        // Hydrate a single related model.
        if (propertyConfig.model) {
            if (propertyValue) {
                try {
                    propertyValue = this.hydrate(propertyValue, new propertyConfig.model());
                } catch (e) {
                    throw new Error(`Error in propertyConfig for '${propertyName}'. ${e.message}`);
                }
            } else {
                propertyValue = null;
            }
        }

        // Hydrate an array of related models.
        if (propertyConfig.modelCollection) {
            if (propertyValue && is.anObject(propertyValue) && !is.anArray(propertyValue)) {
                // If value is an object maintain the object keys.
                let hydratedModelCollection = {};
                Object.keys(propertyValue).forEach((key) => {
                    hydratedModelCollection[key] = this.hydrate(
                        propertyValue[key],
                        new propertyConfig.modelCollection()
                    );
                });
                propertyValue = hydratedModelCollection;
            } else if (propertyValue && is.anArray(propertyValue)) {
                propertyValue = this.hydrateArray(
                    propertyValue,
                    () => new propertyConfig.modelCollection()
                );
            } else {
                propertyValue = [];
            }
        }

        // Perform some transformation on the value.
        // Note that this happens after the related model(s) have been hydrated.
        if (is.aFunction(propertyConfig.transform)) {
            propertyValue = propertyConfig.transform(propertyValue, data, this);
        }

        if (propertyValue !== undefined) {
            model[propertyName] = propertyValue;
        }
    },

    /**
     * Returns a function that can be called to hydrate an array of data into the model type specified.
     * Useful for a promise chain.
     *
     * @param {function} model
     *
     * @return {function(array): array}
     */
    createModelHydrator: function createModelHydrator(model) {
        return (data) => this.hydrateArray(data, () => new model());
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Hydrator;
}
