const { cleanCartLine } = require('./cartLineCleaning');
const { cloneDeep, cloneShallow } = require('../../../functions/clone');
const { unflatten } = require('digitickets.flatten-js');

/**
 * @param {CartLineNumberFactory} cartLineNumberFactory
 */
const CartLineSplitter = function (
    cartLineNumberFactory
) {
    /**
     * @type {CartLineNumberFactory}
     */
    this.cartLineNumberFactory = cartLineNumberFactory;
};

CartLineSplitter.prototype = {
    /**
     * Get all the lines from the cart split up where necessary, ready to send to the API.
     *
     * @param {Cart} cart
     *
     * @return {DigiTickets.CartItem[]}
     */
    splitCartLinesForApi(cart) {
        let splitDiscountsResult = this.splitDiscounts(cart.itemList);

        return splitDiscountsResult.items;
    },

    /**
     * If lines have multiple different discounts, or only 1 discount but it does not apply to the
     * entire line, split those into separate lines.
     *
     * @param {DigiTickets.CartItem[]} items
     * @param {Object<string>} [fieldData]
     *
     * @return {{items: DigiTickets.CartItem[], fieldData: object}}
     */
    splitDiscounts(items, fieldData = {}) {
        let newItems = [];

        // Convert the string keys for field data to an object.
        let originalFieldData = unflatten(fieldData, null, null, '.', false);

        // Copy all field data to the new field data (this handles order level, and any future levels)
        let newFieldData = cloneDeep(originalFieldData) || {};

        // Delete the line data from the newFieldData as we'll be modifying this.
        // Order level (and any other level) field data is not modified.
        newFieldData.line = {};

        for (let i = 0; i < items.length; i++) {
            // Clone this line so we don't modify the original.
            /** @var {DigiTickets.CartItem} */
            const item = cloneShallow(items[i]);
            item.itemInstances = cloneDeep(item.itemInstances);

            /**
             * The item instances from the original line.
             * Copied to a new array here so we can remove from it without other stuff interfering and adding
             * instances back in / removing them.
             * At the end this becomes the list of instances for the original line.
             *
             * @type {ItemInstance[]}
             */
            const originalLineItemInstances = [...item.itemInstances];

            // Run calculate on the line so we're sure the discountedQuantity property is up to date.
            item.calculate(false);

            const isRefund = item.quantity < 0;

            let originalLineNum = item.lineNumber;
            let originalLineFieldData = null;
            let originalLineFieldDataInstances = null;

            if (
                originalFieldData
                && originalFieldData.hasOwnProperty('line')
                && originalFieldData.line.hasOwnProperty(originalLineNum)
            ) {
                // Found some field data for this line.
                originalLineFieldData = originalFieldData.line[originalLineNum];
                originalLineFieldDataInstances = Object.keys(originalLineFieldData);
            }

            // We only need to do anything with line if it has multiple discounts, or if there is spare qty
            // on the line that is not discounted.
            // We want to end up with a separate line for each individual discount (or lack of discount).
            if (
                // Multiple discounts
                item.discounts.length > 1
                // or not all the qty is discounted.
                || (Math.abs(item.discountedQuantity) && Math.abs(item.discountedQuantity) < Math.abs(item.quantity))
            ) {
                for (let di = 0; di < item.discounts.length; di++) {
                    /**
                     * @type {CartItemDiscount}
                     */
                    let itemDiscount = item.discounts[di];
                    if (!itemDiscount.isActive()) {
                        continue;
                    }

                    // Create a separate line for this discounted portion of the line
                    let discountedItem = item.clone();
                    if (isRefund) {
                        discountedItem.setQuantity(-1 * itemDiscount.qty, false);
                    } else {
                        discountedItem.setQuantity(itemDiscount.qty, false);

                        // Steal this number of item instances from the original line.
                        // First reset the instances on the new line as they will currently be a clone of the original
                        // line's instances.
                        discountedItem.itemInstances = [];
                        for (let newInstance = 1; newInstance <= itemDiscount.qty; newInstance++) {
                            discountedItem.itemInstances.push(
                                originalLineItemInstances.shift().setInstance(newInstance)
                            );
                        }
                    }

                    discountedItem.clearDiscounts();
                    discountedItem.setDiscounts([itemDiscount]);
                    discountedItem.calculate();

                    // The new line needs a new line number
                    let newLineNum = this.cartLineNumberFactory.next();
                    discountedItem.lineNumber = newLineNum;

                    // Remove this discounted qty from the original line
                    item.adjustQuantity(-discountedItem.getQuantity(), false);

                    // Add the newly split line
                    newItems.push(discountedItem);

                    if (!originalLineFieldData) {
                        // No field data for this line so we can go straight to the next discount.
                        continue;
                    }

                    // Now fix field data.
                    // Take discountedItem.qty of the field data for original line and change it over to the new line.
                    for (let newInstanceNum = 0; newInstanceNum < Math.abs(discountedItem.getQuantity()); newInstanceNum++) {
                        // Take the first item instance from the lineFieldData and add it to the new line
                        // with a new instance number starting from 0
                        let oldInstanceNum = originalLineFieldDataInstances.shift();

                        // Add an entry for the new line
                        if (!newFieldData.line.hasOwnProperty(newLineNum)) {
                            newFieldData.line[newLineNum] = {};
                        }

                        newFieldData.line[newLineNum][newInstanceNum] = originalLineFieldData[oldInstanceNum];

                        delete originalLineFieldData[oldInstanceNum];
                    }
                }

                // Now all the discounted parts of the line have been split off into new lines.
                // We might still have remaining qty on the original line that is not discounted (which may well
                // be the entire line, if there were no discounts on the line)

                // Use the remaining instances for the original line.
                item.itemInstances = originalLineItemInstances.map(
                    (ii, index) => ii.setInstance(index + 1)
                );

                // Remove discounts from the original line as they have now been split out
                item.clearActiveDiscounts();
                item.calculate();

                // Add the remaining non discounted portion of the line
                if (item.getQuantity() !== 0) {
                    newItems.push(item);

                    // Add the remaining field data for the original line
                    if (originalLineFieldData) {
                        // Loop through what field data is left for this line and give it new instance numbers so
                        // it starts from zero for this line instead of wherever it is left.
                        // This is done, and shift() is used above instead of pop(), to try and stay close
                        // to the same order the data was entered in. Discounted lines are inserted before
                        // the original line, so they should use the first field data / instances.
                        newFieldData.line[originalLineNum] = {};

                        let newRemainingInstanceNum = 0;
                        Object.keys(originalLineFieldData).forEach((key) => {
                            newFieldData.line[originalLineNum][newRemainingInstanceNum] = originalLineFieldData[key];
                            ++newRemainingInstanceNum;
                        });
                    }
                }
            } else {
                // Keep the line as it is.
                newItems.push(item);

                // Keep the field data for this line as it is.
                if (originalLineFieldData) {
                    newFieldData.line[originalLineNum] = originalLineFieldData;
                }
            }
        }

        newItems.forEach(cleanCartLine);

        return {
            items: newItems,
            fieldData: newFieldData
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartLineSplitter;
}
