/**
 * @param $scope
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {UserService} UserService
 */
const ErrorCtrl = function (
    $scope,
    AppConfig,
    UserService
) {
    $scope.user = UserService;
    $scope.appConfig = AppConfig;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ErrorCtrl;
}
