/**
 * Value object to represent the result of a privilege check.
 *
 * @param privilegeName
 */
DigiTickets.PrivilegeCheckResult = function (privilegeName) {
    /**
     * The userID that granted this permission, if the logged in user did not already have it.
     *
     * @type {number}
     */
    this.grantedBy = null;

    /**
     * Indicates if the privilege was granted or not.
     *
     * @type {boolean}
     */
    this.granted = false;

    /**
     * Plaintext name of the requested privilege.
     *
     * @type {string}
     */
    this.privilegeName = privilegeName || null;

    /**
     * Hashed privilege name.
     *
     * @type {string}
     */
    this.privilegeHash = null;

    /**
     * UserID that requested the privilege.
     *
     * @type {number}
     */
    this.userID = null;
};

DigiTickets.PrivilegeCheckResult.prototype = {

};
