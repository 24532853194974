DigiTickets.SocketMessageType = Object.freeze({
    FIELD_ARCHIVED: 'fieldArchived',
    FIELD_CREATED: 'fieldCreated',
    FIELD_DELETED: 'fieldDeleted',
    FIELD_UPDATED: 'fieldUpdated',
    KITCHEN_REFRESHED: 'kitchenRefreshed',
    PRODUCT_ARCHIVED: 'productArchived',
    PRODUCT_CREATED: 'productCreated',
    PRODUCT_DELETED: 'productDeleted',
    PRODUCT_UPDATED: 'productUpdated',
    ORDER_CREATED: 'orderCreated',
    STASHED_CART_CREATED: 'stashedCartCreated',
    ORDER_FULFILLED: 'orderFulfilled'
});
