const { toInt } = require('../functions/transform');

DigiTickets.Privileges = function () {
    /**
     * Array of hashed names of EPOS privileges that this user has.
     *
     * @type {string[]}
     */
    this.hasPrivileges = [];

    /**
     * @type {string|null}
     */
    this.pin = null;

    /**
     * @type {string|null}
     */
    this.rfidTagId = null;

    /**
     * Stores the last privilege that was granted to this user by a supervisor.
     *
     * @type {{hashedName: string|null, grantedBy: number|null}}
     */
    this.temporaryPrivilege = {
        hashedName: null,
        grantedBy: null
    };

    /**
     * @type {number}
     */
    this.userID = null;

    /**
     * @type {string}
     */
    this.username = null;
};

DigiTickets.Privileges.prototype = {

    getHydrationMap() {
        return {
            hasPrivileges: {
                field: ['hasPrivileges', 'eposPrivileges']
            },
            pin: {},
            rfidTagId: {},
            userID: toInt,
            username: {}
        };
    },

    /**
     * Check if this privilege set contains the specified privilege.
     *
     * @param {string} hashedPrivilegeName
     *
     * @returns {boolean}
     */
    hasPrivilege: function hasProvilege(hashedPrivilegeName) {
        for (let i = 0; i < this.hasPrivileges.length; i++) {
            if (this.hasPrivileges[i] === hashedPrivilegeName) {
                return true;
            }
        }

        return false;
    },

    /**
     * Remember the latest privilege temporarily granted by a supervisor.
     *
     * @param {string} hashedPrivilegeName
     * @param {number} grantedBy
     */
    setTemporaryPrivilege: function setTemporaryPrivilege(hashedPrivilegeName, grantedBy) {
        this.temporaryPrivilege.hashedName = hashedPrivilegeName;
        this.temporaryPrivilege.grantedBy = grantedBy;
    },

    clearTemporaryPrivilege: function clearTemporaryPrivilege() {
        this.temporaryPrivilege.hashedName = null;
        this.temporaryPrivilege.grantedBy = null;
    },

    /**
     * Check if the given hashed privilege name is the same as the last privilege
     * temporarily granted by a supervisor.
     *
     * @param {string} hashedPrivilegeName
     *
     * @return {number|boolean} Returns the user ID that granted the privilege if true. False if false.
     */
    hasTemporaryPrivilege: function hasTemporaryPrivilege(hashedPrivilegeName) {
        if (this.temporaryPrivilege.hashedName === hashedPrivilegeName) {
            return this.temporaryPrivilege.grantedBy;
        }

        return false;
    }
};
