const MembershipResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'memberships/',
        ApiRequestParams.defaults(),
        {
            update: {
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'memberships/:membershipID'
            },
            updateMultiple: {
                method: 'PUT',
                isArray: true,
                url: AppConfig.apiBaseUrl + 'memberships'
            },
            cancelMultiple: {
                method: 'DELETE',
                isArray: true,
                url: AppConfig.apiBaseUrl + 'memberships'
            },
            getRefunds: {
                method: 'GET',
                url: AppConfig.apiBaseUrl + 'membershiprefundamounts'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipResource;
}
