const CustomerScreenEvents = require('./CustomerScreen/CustomerScreenEvents');
const CustomerScreenState = require('../../models/CustomerScreenState');

const CustomerScreenDataService = function (
    $timeout
) {
    /**
     * @type {function(string, object)[]}
     */
    this.listeners = [];

    /**
     * @type {CustomerScreenState}
     */
    this.state = new CustomerScreenState();

    this.channel = null;
    if (typeof window.BroadcastChannel !== 'undefined') {
        this.channel = new BroadcastChannel('propoint');
        this.channel.onmessage = ({ data }) => {
            if (data && data.type) {
                this._fireListeners(data.type, data.data);
            }
        };
    } else {
        console.warn('BroadcastChannel not supported. Customer screen is unavailable.', window.BroadcastChannel);
    }

    /**
     * @type {Object<{question: CustomerScreenQuestion, resolve: function, reject: function, onReceived: ?function,
     *     onDisplayed: ?function}>}
     */
    this.pendingQuestions = {};

    this.addListener(
        /**
         * Add a listener for handling question responses.
         *
         * @param {string} type
         * @param {object} data
         */
        (type, data) => {
            $timeout(() => {
                let pendingQuestion = null;
                if (data.question) {
                    pendingQuestion = this.pendingQuestions[data.question.ID] || null;
                }

                switch (type) {
                    case CustomerScreenEvents.QUESTION_ANSWERED:
                        console.log('QUESTION_ANSWERED', data, pendingQuestion);
                        if (pendingQuestion) {
                            if (data.hasOwnProperty('answer') && data.answer !== null) {
                                pendingQuestion.resolve(data.answer);
                            } else {
                                pendingQuestion.reject(data.answer);
                            }
                        }
                        break;

                    case CustomerScreenEvents.QUESTION_RECEIVED:
                        console.log('QUESTION_RECEIVED', data, pendingQuestion);
                        if (pendingQuestion) {
                            pendingQuestion.question.setReceived();
                            if (pendingQuestion.onReceived === 'function') {
                                pendingQuestion.onReceived();
                            }
                        }
                        break;

                    case CustomerScreenEvents.QUESTION_DISPLAYED:
                        console.log('QUESTION_DISPLAYED', data, pendingQuestion);
                        if (pendingQuestion) {
                            pendingQuestion.question.setDisplayed();
                            if (typeof pendingQuestion.onDisplayed === 'function') {
                                pendingQuestion.onReceived();
                            }
                        }
                        break;
                }
            });
        }
    );
};

CustomerScreenDataService.prototype = {

    isReady() {
        return this.channel !== null;
    },

    /**
     * Register a callback to be fired when the data changes.
     *
     * @param {function(string, object)} listener
     */
    addListener(listener) {
        this.listeners.push(listener);
    },

    _fireListeners(type, data) {
        this.listeners.forEach((listener) => listener(type, data));
    },

    /**
     * @param {string} type
     * @param {object} data
     */
    sendMessage(type, data) {
        if (!this.channel) {
            return;
        }
        this.channel.postMessage({
            type,
            data
        });
    },

    /**
     * A helper function to update the state object and then broadcast it.
     * Use like this:
     *
     * updateState(s => {
     *     s.something = 'something else';
     * });
     *
     * @param {function(CustomerScreenState)} func
     */
    updateState(func) {
        func(this.state);
        this.sendMessage(
            CustomerScreenEvents.STATE_UPDATED,
            {
                state: this.state
            }
        );
    },

    /**
     * Shortcut for setting the 'stage' property in state as this is commonly done.
     *
     * @param {string} stage
     * @param {string} [unlessOnStage]
     */
    setStage(stage, unlessOnStage) {
        if (this.state.stage === unlessOnStage) {
            return;
        }

        this.updateState((state) => {
            state.stage = stage;
        });
    },

    /**
     * @param {CustomerScreenQuestion} question
     * @param {?function} [onReceived]
     * @param {?function} [onDisplayed]
     *
     * @return {Promise}
     */
    askQuestion(question, onReceived, onDisplayed) {
        return new Promise((resolve, reject) => {
            this.pendingQuestions[question.ID] = {
                question,
                resolve: (result) => {
                    resolve(result);
                    delete this.pendingQuestions[question.ID];
                },
                reject: (err) => {
                    reject(err);
                    delete this.pendingQuestions[question.ID];
                },
                onReceived,
                onDisplayed
            };

            this.sendMessage(CustomerScreenEvents.ASK_QUESTION, {
                question
            });

            // If after 0.5 seconds the question has not been received by the customer screen reject it.
            setTimeout(() => {
                if (!question.wasReceived) {
                    this.cancelQuestion(question.ID, 'Customer screen unavailable.');
                }
            }, 500);
        });
    },

    /**
     * Cancel a pending or displayed question on the customer screen.
     *
     * @param {string} ID
     * @param {string} [reason]
     */
    cancelQuestion(ID, reason) {
        this.sendMessage(CustomerScreenEvents.CANCEL_QUESTION, {
            ID,
            reason
        });

        if (this.pendingQuestions[ID]) {
            this.pendingQuestions[ID].reject(new Error(reason));
        }
    },

    /**
     * Called by the customer screen to confirm a question will be displayed.
     *
     * @param {CustomerScreenQuestion} question
     */
    receivedQuestion(question) {
        this.sendMessage(CustomerScreenEvents.QUESTION_RECEIVED, {
            question
        });
    },
    /**
     * Called by the customer screen when a question is being displayed.
     *
     * @param {CustomerScreenQuestion} question
     */
    displayedQuestion(question) {
        this.sendMessage(CustomerScreenEvents.QUESTION_DISPLAYED, {
            question
        });
    },

    /**
     * Called by the customer screen to send back the answer to a question.
     * There is a listener in the constructor for this message, which resolves the pending question promise.
     *
     * @param {CustomerScreenQuestion} question
     * @param answer
     */
    answerQuestion(question, answer) {
        this.sendMessage(CustomerScreenEvents.QUESTION_ANSWERED, {
            question,
            answer
        });
    }
};


/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerScreenDataService;
}
