const ChangeCalculator = require('@/libraries/DigiTickets/Payment/ChangeCalculator');
const PaymentMethodRef = require('@/libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

/**
 * This is the controller for the inner part of the payment modal for the payment method of "Flexi Voucher".
 *
 * @param $scope
 * @param $filter
 * @param {CartService} cartService
 * @param Notification
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 */
const FlexiVoucherCtrl = function FlexiVoucherCtrl(
    $scope,
    $filter,
    cartService,
    Notification,
    NumPadFactory
) {
    $scope.thisPaymentMethodRef = PaymentMethodRef.FLEXI_VOUCHER;
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    $scope.cart = cartService;

    $scope.calculator = new ChangeCalculator();
    $scope.numPad = NumPadFactory.create(
        {
            showCashButtons: true,
            showSignButton: false
        },
        function (value) {
            // When the number pad value changes update the calculator.
            $scope.calculator.setAmountTendered(value);
        }
    );
    $scope.numPadListening = false;

    $scope.clear = function clear() {
        $scope.calculator.clear();
        $scope.numPad.clear();
    };

    $scope.setAmountDue = function setAmountDue(amount) {
        $scope.calculator.setAmountDue(amount);
        $scope.numPad.setExactAmount(amount);
        $scope.numPad.options.isNegative = amount < 0;
    };

    $scope.setAmountDue($scope.cart.getRemainingBalance());

    $scope.$on('payment-modal.payment-removed', function () {
        $scope.setAmountDue($scope.cart.getRemainingBalance());
    });

    $scope.$on('payment-modal.cancel-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Was cancelled from the cash tab.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        // Changing tab, so reset the calculator.
        $scope.clear();
        $scope.setAmountDue($scope.cart.getRemainingBalance());

        $scope.numPadListening = newPaymentMethodRef === $scope.thisPaymentMethodRef;
    });

    $scope.$on('payment-modal.ok-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            let amountTendered = $scope.calculator.getAmountTendered();

            // If the cart doesn't allow partial payments (currently so if it contains memberships), then
            // we need to check that the cash paid is at least as big as the order total.
            $scope.$parent.clearError();
            if ($scope.cart.getRemainingBalance() > 0 && !$scope.cart.allowPartialPayments()) {
                // Partial payments are not allowed.
                if (amountTendered < $scope.cart.getRemainingBalance()) {
                    // They haven't paid enough, so we can't accept it.
                    $scope.$parent.setError('Partial payments are not allowed for this order');
                    return;
                }
            }

            // Add a payment for the amount we have entered,
            // instead of the PaymentCtrl creating a payment for the entire remaining balance.
            $scope.preventAddFullPaymentOnClose();

            // Flexi Vouchers refunds can be rounded up to the nearest pound. Using floor() seems counter intuitive but
            // because it is a refund we want to make the amount more -ve not +ve e.g. -14.95 -> -15.00
            if ($scope.cart.isRefund() && amountTendered < Math.floor($scope.cart.getRemainingMaxPayment())) {
                Notification.error(
                    $filter('lang')('PAYMENT.MAX_REFUND_AMOUNT_EXCEEDED_ALERT', {
                        max: Math.floor($scope.cart.getRemainingMaxPayment())
                    })
                );

                $scope.preventClose();
                return false;
            }

            if ((!$scope.cart.isRefund() && amountTendered > 0) || ($scope.cart.isRefund() && amountTendered < 0)) {
                //
                let amount = $scope.cart.getRemainingMaxPayment();
                // We've already validated that the amountTendered is valid even if it is different to the amount.
                if ($scope.cart.isRefund() && amountTendered < amount) {
                    // e.g. -14.95 -> -15.00
                    amount = amountTendered;
                }
                let change = amountTendered - amount;

                let payment = new DigiTickets.Payment();
                payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
                payment.setTendered(amountTendered);
                payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
                payment.setChangeGiven(change);
                payment.setValue(amount);
                $scope.addPayment(payment);
            }

            // Clear for next use.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FlexiVoucherCtrl;
}
