const classForErrorLevel = require('./classForErrorLevel');
const ErrorLevel = require('./ErrorLevel');
const { toInt, toString } = require('../../../functions/transform');

/**
 * A custom Error that can be thrown. In addition to the error message a language key is supplied
 * which refers to an error message defined in the .ini files which will be shown to the user.
 *
 * @param {string} message
 * @param {string} langKey
 */
function UserFacingError(message, langKey) {
    this.message = message;
    this.langKey = langKey;
    this.level = ErrorLevel.ERROR;

    // Adding the 'stack' property like this means it exists and can be accessed as this.stack
    // but when you call JSON.stringify(this) the 'stack' property does not come out.
    // https://stackoverflow.com/a/34890593/710630
    // We don't want the 'stack' property to be stashed because it can be very long and will reference the wrong lines
    // if the codebase changes in the meantime.
    Object.defineProperty(this, 'stack', { value: Error().stack, writable: true });
}

UserFacingError.prototype = Object.create(Error.prototype);
Object.assign(
    UserFacingError.prototype,
    {
        /**
         * @param {number} errorLevel
         *
         * @return {UserFacingError}
         */
        setLevel(errorLevel) {
            this.level = errorLevel;
            return this;
        },

        /**
         * @return {string}
         */
        getLangKey() {
            return this.langKey;
        },

        /**
         * @return {object}
         */
        getHydrationMap() {
            return {
                langKey: toString,
                message: toString,
                level: toInt
            };
        },

        /**
         * Return a bootstrap CSS class to represent the level of this error.
         *
         * @return {string}
         */
        getCssClass() {
            return classForErrorLevel(this.level);
        }
    }
);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserFacingError;
}
