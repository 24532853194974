/**
 * @param {OrderLine} orderLine
 * @param {Date} purchaseDate
 *
 * @return {?Date}
 */
const calculateOrderLineValidUntil = (orderLine, purchaseDate) => {
    if (orderLine.validityPeriod !== null) {
        let expiryDate = new Date(purchaseDate.toString());
        expiryDate.setDate(expiryDate.getDate() + orderLine.validityPeriod);
        expiryDate.setHours(23, 59, 59, 0);
        return expiryDate;
    }

    return null;
};

/**
 * @param {DigiTickets.Order} order
 */
const setOrderLinesValidUntil = (order) => {
    order.items.forEach((line) => {
        line.validUntil = calculateOrderLineValidUntil(line, order.date);
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        calculateOrderLineValidUntil,
        setOrderLinesValidUntil
    };
}
