DigiTickets.TradingSessionEventType = Object.freeze({
    ABANDON_PAYMENTS: 'ABANDON_PAYMENTS',
    ADMIN_NOTE: 'ADMIN_NOTE',
    ADMIN_X_READ: 'ADMIN_X_READ',
    CASH_DRAWER: 'CASH_DRAWER',
    CASH_FLOAT: 'CASH_FLOAT',
    CASH_IN: 'CASH_IN',
    CASH_OUT: 'CASH_OUT',
    CASHBACK: 'CASHBACK',
    DEVICE_OFFLINE: 'DEVICE_OFFLINE',
    DEVICE_ONLINE: 'DEVICE_ONLINE',
    DEVICE_RESUME: 'DEVICE_RESUME',
    ERROR_API: 'ERROR_API',
    GRATUITY: 'GRATUITY',
    SESSION_BANK: 'SESSION_BANK',
    SESSION_CASH: 'SESSION_CASH',
    SESSION_CLOSE: 'SESSION_CLOSE',
    SESSION_OPEN: 'SESSION_OPEN',
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_SWITCH: 'USER_SWITCH',
    VOUCHER: 'VOUCHER'
});
