const _ = require('lodash');
const CustomerAccountType = require('../libraries/DigiTickets/Enums/CustomerAccountType');
const { toInt, toString, toNullableString } = require('../functions/transform');

DigiTickets.CustomerAccount = function () {
    /**
     * @type {number|null}
     */
    this.ID = null;

    /**
     * @type {string|null}
     */
    this.email = null;
    this.organisation = null;
    this.type = CustomerAccountType.PERSONAL;
    this.ref = null;

    /**
     * @type {DigiTickets.Address[]}
     */
    this.addresses = [];

    /**
     * @type {DigiTickets.Address}
     */
    this.primaryAddress = null;

    /**
     * @type {DigiTickets.Contact[]}
     */
    this.contacts = [];

    /**
     * @type {DigiTickets.Contact}
     */
    this.primaryContact = null;

    /**
     * @type {DigiTickets.Membership[]}
     */
    this.memberships = [];

    /**
     * @type {DigiTickets.PaymentToken[]}
     */
    this.paymentTokens = [];

    this.selectedPaymentToken = null;

    /**
     * @type {DigiTickets.Membership|null}
     */
    this.firstActiveMembership = null;
};

DigiTickets.CustomerAccount.prototype = {
    /**
     * @returns {boolean}
     */
    isBusiness: function () {
        return this.type === 'business';
    },

    getHydrationMap() {
        return {
            addresses: {
                field: ['savedAddresses', 'addresses'],
                modelCollection: DigiTickets.Address
            },
            contacts: {
                modelCollection: DigiTickets.Contact
            },
            email: toNullableString,
            ID: {
                field: ['ID', 'customerAccountID'],
                transform: toInt
            },
            memberships: {
                modelCollection: DigiTickets.Membership
            },
            organisation: toNullableString,
            paymentTokens: {
                modelCollection: DigiTickets.PaymentToken
            },
            primaryAddress: {
                model: DigiTickets.Address
            },
            primaryContact: {
                model: DigiTickets.Contact
            },
            ref: toNullableString,
            type: toString
        };
    },

    afterHydration: function afterHydration(data, hydrator) {
        // Hydrate the 'contacts' property here as it is more complex than getHydrationMap should handle.
        if (data.hasOwnProperty('accountContacts')) {
            /**
             * For accounts coming from the API the accountContacts property is in the format:
             * {
             *     0: {
             *         accountContactID: 123,
             *         contact: {..},
             *         type: 'Primary'
             *     }
             * }
             *
             * Convert it to a regular array of just contact models.
             */
            let contactsData = data.accountContacts;

            let contacts = [];
            for (let i = 0; i < contactsData.length; i++) {
                let contactData = contactsData[i].hasOwnProperty('contact') ? contactsData[i].contact : contactsData[i];
                let contact = hydrator.hydrate(
                    contactData,
                    new DigiTickets.Contact()
                );

                // If the type is Primary, also set the primaryContact
                if (contactsData[i].type === 'Primary') {
                    this.primaryContact = contact;
                }

                contacts.push(contact);
            }

            this.contacts = contacts;
        } else if (data.hasOwnProperty('contacts')) {
            /**
             * If this is from a model that has already been converted to the EPOS format and stashed
             * somewhere, the 'contact' property will just be a list of contact data so we don't
             * need to do the above.
             */
            this.contacts = hydrator.hydrateArray(
                data.contacts,
                function () {
                    return new DigiTickets.Contact();
                }
            );
        }

        // Determine primary contact.
        if (!this.primaryContact && this.contacts.length > 0) {
            this.primaryContact = this.contacts[0];
        }

        this.refreshPrimaryAddress();

        // Sort contacts by name.
        this.contacts.sort(function (a, b) {
            let aFullName = a.getFullName();
            let bFullName = b.getFullName();

            if (aFullName === bFullName) {
                return 0;
            }

            return aFullName < bFullName ? -1 : 1;
        });

        // Remove any "dead" memberships. The API must return memberships that are archived/deleted, but this
        // EPOS must hide them completely.
        this.memberships = this.memberships.filter(function removeDeadMemberships(membership) {
            return !membership.isDead();
        });

        this.firstActiveMembership = this.getActiveMembership();
    },

    /**
     * Determine the primary address for this account.
     * At the moment it just uses the oldest address.
     *
     * @todo In time we will want this to be more specific
     * - ie. default billing address, or default delivery address
     */
    refreshPrimaryAddress() {
        if (this.addresses.length > 0) {
            this.addresses = _.sortBy(this.addresses, ['ID']);
            this.primaryAddress = this.addresses[0];
        } else {
            this.primaryAddress = null;
        }
    },

    /**
     * Returns the first active Membership.
     *
     * @returns {DigiTickets.Membership|null}
     */
    getActiveMembership: function getActiveMembership() {
        for (let i = 0; i < this.memberships.length; i++) {
            let membership = this.memberships[i];
            if (membership.isActive()) {
                return membership;
            }
        }

        return null;
    }
};
