const enterDirective = function () {
    return function (scope, element, attrs) {
        element.bind('keydown keydown', function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.dtEnter);
                });

                event.preventDefault();
            }
        });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = enterDirective;
}
