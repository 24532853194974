/**
 * List of all the distinct kinds of things we can print.
 */
const PrintType = Object.freeze({
    BILL: 'BILL',
    BLANK: 'BLANK',
    CARD_RECEIPT: 'CARD_RECEIPT',
    GIFT_VOUCHER: 'GIFT_VOUCHER',
    KITCHEN_ORDER: 'KITCHEN_ORDER',
    NO_SALE: 'NO_SALE',
    RECEIPT: 'RECEIPT',
    RECEIPT_DUPLICATE: 'RECEIPT_DUPLICATE',
    REPORT: 'REPORT',
    TEST: 'TEST',
    TICKETS_GROUP: 'TICKETS_GROUP',
    TICKETS_INDIVIDUAL: 'TICKETS_INDIVIDUAL'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintType;
}
