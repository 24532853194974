const DataStoreNames = require('./DataStores/DataStoreNames');

/**
 * Logic to test IndexedDB.
 * Note this is not a unit test, it is code that runs in production to check the user's browser is supported.
 *
 * @param {DataStore} dataStore
 */
const DataStoreTester = function (
    dataStore
) {
    /**
     * @return {Promise}
     */
    this.test = async () => {
        const testData = {
            name: 'testString',
            value: (new Date()).toISOString()
        };

        console.debug('Testing IndexedDB support', `Test data: ${JSON.stringify(testData)}`);

        // Check if indexedDB is available at all.
        // The check for 'window' is here so this doens't break tests running in Node.
        /* istanbul ignore if */
        if (typeof window !== 'undefined') {
            console.debug(
                'typeof window.indexedDB ',
                typeof window.indexedDB,
                '!!window.indexedDB',
                !!window.indexedDB
            );

            if (!window.indexedDB) {
                throw new Error('window.indexedDB is empty.');
            }
        }

        return dataStore
            .persist(
                DataStoreNames.TEST,
                testData
            )
            .then((persistResult) => {
                console.debug('Testing IndexedDB support', 'persist result', persistResult);

                return dataStore.find(DataStoreNames.TEST, testData.name);
            })
            .then((findResult) => {
                console.debug('Testing IndexedDB support', 'find result', findResult);

                if (findResult && findResult.value === testData.value) {
                    // Resolve the promise with 'true' as the result.
                    return true;
                }
                // Reject the promise by throwing an error.
                throw new Error(
                    `Returned value '${JSON.stringify(findResult)}' does not match test string '${testData.value}'`
                );
            });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DataStoreTester;
}
