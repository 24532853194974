const { toString } = require('../functions/transform');

DigiTickets.SoldGiftVoucherData = function () {
    this.deliveryType = null;
    /**
     * @type {DigiTickets.Contact}
     */
    this.recipientContact = new DigiTickets.Contact();
    this.recipientMessage = '';
};

DigiTickets.SoldGiftVoucherData.prototype = {
    getHydrationMap() {
        return {
            deliveryType: toString,
            recipientContact: {
                model: DigiTickets.Contact
            },
            recipientMessage: toString
        };
    },

    /**
     * @return {{deliveryType: *, recipientContact: null, recipientMessage: *}}
     */
    toServerData: function toServerData() {
        return {
            deliveryType: this.deliveryType,
            recipientContact: this.recipientContact.isEmpty() ? null : this.recipientContact.toServerData(),
            recipientMessage: this.recipientMessage
        };
    }
};
