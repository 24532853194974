const BigNumber = require('bignumber.js');
const CustomerScreenQuestion = require('../../../models/CustomerScreenQuestion');
const CustomerScreenQuestionType = require('../../../models/CustomerScreenQuestionType');
const GiftAidModalCtrl = require('../../../controllers/modal/GiftAidModalCtrl');
const PriceAdjustmentCalculator = require('../PriceAdjustmentCalculator');

/**
 * Some useful info on Gift Aid:
 * https://www.visitengland.com/sites/default/files/rules_on_gift_aid_for_charitable_attractions_updated_version_jan_15.pdf
 *
 * There are 2 scenarios:
 *
 * 1) Customer opts to make a donation of at least 10% of the ticket price. The total amount of the entry cost + the
 * extra donation is then treated as a donation for Gift Aid purposes, not just the additional 10%. For DT purposes
 * the extra 10% goes into the 'donation' field.
 *
 * 2) The entire ticket price is considered a donation but the ticket must allow the customer free (or reduced rate)
 * for a 12 month period. For DT purposes the 'donation' field remains as zero.
 *
 * @param {CurrentDevice} CurrentDevice
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {LangService} LangService
 * @param {ModalFactory} ModalFactory
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {ToastFactory} toastFactory
 * @param {UserService} UserService
 */
const GiftAidPrompter = function (
    CurrentDevice,
    CustomerScreenDataService,
    LangService,
    ModalFactory,
    NotificationService,
    toastFactory,
    UserService
) {
    this.currentDevice = CurrentDevice;
    this.customerScreenDataService = CustomerScreenDataService;
    this.langService = LangService;
    this.modalFactory = ModalFactory;
    this.notificationService = NotificationService;
    this.toastFactory = toastFactory;
    this.userSerice = UserService;
};

GiftAidPrompter.prototype = {
    /**
     * Calculation the total additional donation needed to enable Gift Aid for every item in the cart.
     * This is the amount that non-zero gift aid rates add on to items.
     *
     * @param {CartService} cart
     *
     * @return {number}
     */
    calculateAdditionalGiftAidDonationForCart(cart) {
        const priceAdjustmentCalculator = new PriceAdjustmentCalculator();
        let totalIncrease = new BigNumber(0.00);

        cart.itemList.forEach((i) => {
            if (!i.item.giftAidRate) {
                return;
            }

            // Sum up the *increase in price required for Gift Aid*.
            let { difference } = priceAdjustmentCalculator.calculateAdjustedPrice(
                i.item.giftAidRate,
                Number(i.getPrice())
            );

            let totalIncreaseForLine = new BigNumber(difference).times(i.quantity);

            totalIncrease = totalIncrease.plus(totalIncreaseForLine);
        });

        return totalIncrease.toNumber();
    },

    /**
     * Calculate the total donation for Gift Aidable items in the cart for the declaration.
     *
     * This is:
     * - The full item price if it has a zero increase gift aid rate.
     * - The full item price plus the increase for gift aid if it has a non-zero gift aid rate.
     *
     * It is different from the calculateAdditionalGiftAidDonationForCart, which is just how much the customer needs to
     * donate in addition to the item prices to enable Gift Aid. This is the total amount Gift Aid considers a
     * "donation", which includes the original price of the item.
     *
     * @param {CartService} cart
     *
     * @return {number}
     */
    calculateTotalGiftAidDonationForCart(cart) {
        const priceAdjustmentCalculator = new PriceAdjustmentCalculator();
        let totalDonation = new BigNumber(0.00);

        cart.itemList.forEach((i) => {
            if (!i.item.giftAidRate) {
                return;
            }

            // Sum up the *total item price including any increase required for Gift Aid*.
            let { newPrice } = priceAdjustmentCalculator.calculateAdjustedPrice(
                i.item.giftAidRate,
                Number(i.getPrice())
            );

            let totalIncreasedPriceForLine = new BigNumber(newPrice).times(i.quantity);

            totalDonation = totalDonation.plus(totalIncreasedPriceForLine);
        });

        return totalDonation.toNumber();
    },

    /**
     * Asks the Gift Aid question and enables / disables Gift Aid on the cart as appropriate.
     *
     * @param {CartService} cart
     * @param {DigiTickets.CartItem} cartItem
     *
     * @return {Promise<any>}
     */
    prompt(cart, cartItem) {
        return this.askQuestion(cart)
            .then((answer) => {
                if (answer === true) {
                    cart.enableGiftAid();
                } else {
                    cart.disableGiftAid();
                }
            });
    },

    /**
     * Asks the Gift Aid question either on the customer screen or to the operator.
     * Returns a promise which resolves with a boolean for the answer.
     *
     * @param {CartService} cart
     *
     * @return {Promise<boolean>}
     */
    askQuestion(cart) {
        const additionalDonation = this.calculateAdditionalGiftAidDonationForCart(cart);
        const totalDonation = this.calculateTotalGiftAidDonationForCart(cart);

        // Create a customer screen question for Gift Aid.
        const question = new CustomerScreenQuestion(
            CustomerScreenQuestionType.BOOLEAN,
            this.getDeclarationText(additionalDonation, totalDonation)
        );

        let dismissLoadingNotification = null;
        if (this.currentDevice.device.customerTouchScreenEnabled) {
            let waitingToast = this.toastFactory.infoTop(
                null,
                'Please wait for customer to answer Gift Aid question...',
                {
                    iconClass: 'toast-info show-spinner',
                    timeOut: 0,
                    extendedTimeOut: 0,
                    // Allow tapping to dismiss in case it gets stuck or is in the way.
                    tapToDismiss: true
                }
            );
            dismissLoadingNotification = () => {
                this.toastFactory.clear(waitingToast);
            };
        }

        const askOperator = () => new Promise((resolve, reject) => this.modalFactory
            .open(
                'gift-aid',
                GiftAidModalCtrl,
                'partials/modals/giftAidModal.html',
                {
                    additionalDonation: () => additionalDonation,
                    totalDonation: () => totalDonation
                }
            )
            .then(
                (result) => {
                    resolve(result.answer);
                },
                () => reject()
            ));

        if (!this.currentDevice.device.customerTouchScreenEnabled) {
            return askOperator();
        }

        return this.customerScreenDataService
            .askQuestion(question)
            .then((answer) => {
                if (typeof dismissLoadingNotification === 'function') {
                    dismissLoadingNotification();
                }

                return answer;
            })
            .catch(() => {
                if (typeof dismissLoadingNotification === 'function') {
                    dismissLoadingNotification();
                }

                // Ask the operator if asking on customer screen failed (failed means the question was not asked,
                // not that the customer answered no).
                return askOperator();
            });
    },

    getDeclarationText(additionalDonation, totalDonation) {
        const declaration = this.langService.getText(
            'GIFT_AID_MODAL.DECLARATION',
            {
                companyName: this.userSerice.getCompany().name,
                totalDonation
            }
        );

        let question;
        if (additionalDonation) {
            question = this.langService.getText('GIFT_AID_MODAL.QUESTION_CUSTOMER_WITH_DONATION', { additionalDonation });
        } else {
            question = this.langService.getText('GIFT_AID_MODAL.QUESTION_CUSTOMER');
        }

        return '<img src="/img/gift-aid-logo.png" alt="Gift Aid It" class="gift-aid-logo" />'
            + '<p>' + question + '</p>'
            + '<p class="description">' + declaration + '</p>';
    }
};

/* exported GiftAidPrompter */

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GiftAidPrompter;
}
