/**
 * This class allows the "take a photo" elements to talk to a controller. It saves every controller
 * that wants to be able to take pictures from having to have 3 or 4 methods. Instead, the controller
 * just uses this service and the photo code talks directly to this service.
 *
 * @param {WebcamService} webcamService
 */
const ContactPhotoService = function (
    webcamService
) {
    this.webcamService = webcamService;

    this.saveAllowed = false;
};

ContactPhotoService.prototype = {
    setSaveAllowed: function setSaveAllowed() {
        this.saveAllowed = true;
    },

    unsetSaveAllowed: function unsetSaveAllowed() {
        this.saveAllowed = false;
    },

    openVideoFeed: function openVideoFeed($event) {
        const self = this;

        $event.preventDefault();
        $event.stopPropagation();

        // As we can't move <video> object in DOM (stops play)
        // Use a canvas with a fixed FPS to preview the live feed
        let livePreviewContainer = $event.currentTarget.parentElement.parentElement.getElementsByClassName('contactPhotoWebcamLiveFeed');
        livePreviewContainer = livePreviewContainer[0];

        // Only set up live preview for this camera once
        if (livePreviewContainer.getAttribute('live-feed') != 1) {
            // Set 'live-feed' to off to all cameras
            let previews = document.getElementsByClassName('contactPhotoWebcamLiveFeed');
            for (let i = 0; i < previews.length; i++) {
                previews[i].setAttribute('live-feed', 0);
            }

            // Set current preview to live
            livePreviewContainer.setAttribute('live-feed', 1);
            livePreviewContainer.width = 150;
            livePreviewContainer.height = (150 / this.webcamService.ratio);

            // If can't get width & height it means camera not ready, disable live preview
            if (self.webcamService.getVideoFeed().getAttribute('width') === null || self.webcamService.getVideoFeed().getAttribute('height') === null) {
                console.log('Camera not found/ready');
                livePreviewContainer.setAttribute('live-feed', 0);
            } else {
                // If camera is ready, set up a loop to draw the preview
                var liveFeedRefresh = setInterval(function () {
                    // If feed stopped, stop this loop
                    if (livePreviewContainer.getAttribute('live-feed') != 1) {
                        clearInterval(liveFeedRefresh);
                    }

                    // Draw preview
                    livePreviewContainer.getContext('2d').drawImage(self.webcamService.getVideoFeed(), 0, 0, 150, (150 / self.webcamService.ratio));
                }, 1000 / 15); // 1000/15 = 15 frames per second
            }
        } else {
            // Camera icon was clicked again, hide the live feed
            livePreviewContainer.setAttribute('live-feed', 0);
        }
    },

    /**
     * @param {DigiTickets.Contact} contact
     * @param $event
     */
    takeContactPhoto: function takeContactPhoto(contact, $event) {
        contact.photo = null;
        contact.photoData = this.webcamService.takePicture();

        // Stop the live preview loop
        let previews = $event.currentTarget.parentElement.parentElement.getElementsByClassName('liveFeed');
        for (let i = 0; i < previews.length; i++) {
            previews[i].setAttribute('live-feed', 0);
        }
    },

    /**
     * @param {DigiTickets.Contact} contact
     */
    removeContactPhoto: function removeContactPhoto(contact) {
        contact.photoData = '';
        contact.photo = null;
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ContactPhotoService;
}
