const DataStoreNames = require('@/libraries/DigiTickets/DataStores/DataStoreNames');
const logFactory = require('@/libraries/DigiTickets/Logging/logFactory');

/**
 * DataSyncer handles pulling fresh data from the API.
 * Due to how local storage was handled in the past it can be kind of awkward:
 *
 * For older local storage 'repositories':
 * Items are stored in these repositories in an array. When they're needed it's that array that is returned.
 * Angular resource caches are used to cache the complete API response (e.g. for /tickets) in local storage.
 * When ProPoint boots up that array needs to be populated, either with the cached result from local storage or from
 * a fresh API request. We have no way to tell if a cached result exists currently, so we just tell it to load.
 * Both loading the items from this cache, and fetching fresh data from the API happen the same way - you make the API
 * request again. The difference is if the cache has not been cleared no actual API request happens and the cached
 * response is used to populate the repository.
 *
 * For newer IndexedDB 'repositories':
 * We make an API request on initial login or sync. The items are stored in IndexedDB and accessed from IndexedDB
 * when needed. We don't need to do anything when booting up ProPoint. We only need to do something when an actual
 * sync is needed. In that case an API request is made and the items in the response are stored in IndexedDB.
 *
 * Eventually we want everything to be stored in IndexedDB (or the simplified LocalStorageDataStore). This way we know
 * what data we have and it makes it easier to control when a "sync" is needed and what a "sync" does.
 *
 * @param $q
 * @param $injector
 * @param $timeout
 * @param {LocalStorageDataStore} LocalStorageDataStore
 * @param {SyncStateManager} syncStateManager
 * @param {UserService} UserService
 */
const DataSyncer = function (
    $q,
    $injector,
    $timeout,
    LocalStorageDataStore,
    syncStateManager,
    UserService
) {
    this.$q = $q;
    this.$injector = $injector;
    this.$timeout = $timeout;
    this.localStorageDataStore = LocalStorageDataStore;
    this.syncStateManager = syncStateManager;
    this.user = UserService;

    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('DataSyncer');
};

DataSyncer.prototype = {

    /**
     * @param {DigiTickets.Branch} branch
     * @param {DigiTickets.Device} device
     * @param {function} [notifyCallback] A callback to fire when the progress is updated. This is instead of using the
     *                                    'notify' listener from Angular's promise-like $q, which is a nonstandard
     *                                    implementation of promises. Not using that allows us to switch out $q for
     *                                    native promises later.
     * @param {boolean} [requiresSync] Some services should only be updated if a sync is required/requested.
     *
     * @return {Promise<any>}
     */
    loadData: function loadData(branch, device, notifyCallback, requiresSync) {
        let deferred = this.$q.defer();

        let progress = {
            categories: false,
            fields: false,
            giftVouchers: false,
            membershipPlans: false,
            offers: false,
            orderInProgress: false,
            products: false,
            privileges: false,
            returnReasons: false,
            items: false,
            sessions: false,
            templates: false,
            tickets: false
        };
        let startedAt = new Date();

        const updateProgress = () => {
            let progressKeys = Object.keys(progress);
            let totalItems = progressKeys.length;

            let unfinishedKeys = progressKeys.filter((key) => progress[key] === false);
            if (unfinishedKeys.length > 0) {
                if (typeof notifyCallback === 'function') {
                    notifyCallback({
                        total: totalItems,
                        complete: totalItems - unfinishedKeys.length,
                        percent: Math.round(((totalItems - unfinishedKeys.length) / totalItems) * 100),
                        // Pick a random item from the unfinished items to show as what's loading.
                        current: unfinishedKeys[Math.floor(Math.random() * unfinishedKeys.length)]
                    });
                }
            } else {
                // Sync is finished.
                const lastSync = {
                    branchID: this.user && this.user.currentBranch ? this.user.currentBranch.ID : null,
                    syncedAt: new Date()
                };
                console.log('Saving last sync info:', lastSync);
                this.localStorageDataStore.persist('lastSync', lastSync);

                let duration = ((new Date()).getTime() - startedAt.getTime()) / 1000;
                console.log('Data sync completed in ' + duration + ' seconds.');

                deferred.resolve({ percent: 100 });
            }
        };

        const onFailure = (failedKey, errorMessage) => {
            console.log(errorMessage);
            try {
                errorMessage = (errorMessage && typeof errorMessage === 'object')
                    ? errorMessage.toString()
                    : errorMessage;
            } catch (e) {
                // Just in case toString fails.
            }
            deferred.reject({
                failedKey,
                errorMessage
            });
        };

        /**
         * The new style repositories have a consistent interface so we can prevent duplication and use a loop.
         * Define anything that may need to be synced here.
         *
         * Each item in the array has a tableName, which should also be a key in the `progress` object above,
         * and a repositoryName which is the name of the service registered with Angular.
         *
         * @type {{repositoryName: string, tableName: string}[]}
         */
        const repositories = [
            {
                tableName: DataStoreNames.CATEGORIES,
                repositoryName: 'categoryRepository'
            },
            {
                tableName: DataStoreNames.MARKETING_PREFERENCES,
                repositoryName: 'marketingPreferenceRepository'
            },
        ];

        repositories.forEach((entry) => {
            const tableName = entry.tableName;

            // Check if this table is already synced for the current branch.
            // This check only requires the syncStateManager - we don't need to construct each repository yet.
            const syncedAt = this.syncStateManager.isSynced(tableName, branch.ID);
            if (syncedAt) {
                // Already synced. Nothing to do.
                this.log.log(`✅ ${tableName} already synced for branch ${branch.ID} at ${syncedAt.toISOString()}`);
                progress[tableName] = true;

                return;
            }

            // This table needs to be synced.
            this.log.log(`🔄 ${tableName} needs syncing.`);
            progress[tableName] = false;

            /**
             * Construct the repository so we can perform the sync.
             *
             * @type {AbstractRepository}
             */
            const repository = this.$injector.get(entry.repositoryName);

            repository.sync(branch.ID)
                .then(() => {
                    this.log.debug(`🆗 ${tableName} finished syncing.`);
                    progress[tableName] = true;
                    updateProgress();
                })
                .catch((errorMessage) => onFailure(tableName, errorMessage));
        });

        try {
            /**
             * Current device and current user only get updated if a sync was requested, not just when booting.
             *
             * IMPORTANT - If you add a new sync operation please ensure you add the corresponding language string
             * in the [EPOS_INDEX] section in the language .ini files. Keys are listed below.
             */
            if (requiresSync) {
                progress.currentDevice = false;
                this.$injector.get('deviceManager').refreshDevice().then(() => {
                    // EPOS_INDEX.CURRENTDEVICE
                    progress.currentDevice = true;
                    updateProgress();
                })
                    .catch((errorMessage) => onFailure('currentDevice', errorMessage));

                progress.currentUser = false;
                this.$injector.get('UserService').refreshCurrentUser().then(() => {
                    // EPOS_INDEX.CURRENTUSER
                    progress.currentUser = true;
                    updateProgress();
                })
                    .catch((errorMessage) => onFailure('currentUser', errorMessage));
            }

            updateProgress();

            this.$injector.get('fieldManager').loadFields().then(() => {
                // EPOS_INDEX.FIELDS
                progress.fields = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('fields', errorMessage));

            this.$injector.get('GiftVoucherManager').loadGiftVouchers().then(() => {
                // EPOS_INDEX.GIFTVOUCHERS
                progress.giftVouchers = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('giftVouchers', errorMessage));

            this.$injector.get('MembershipPlanService').loadMembershipPlans().then(() => {
                // EPOS_INDEX.MEMBERSHIPPLANS
                progress.membershipPlans = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('membershipPlans', errorMessage));

            this.$injector.get('OfferManager').loadOffers().then(() => {
                // EPOS_INDEX.OFFERS
                progress.offers = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('offers', errorMessage));

            this.$injector.get('OrderInProgressStasher').load().then(() => {
                // EPOS_INDEX.ORDERINPROGRESS
                progress.orderInProgress = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('orderInProgress', errorMessage));

            this.$injector.get('ProductService').loadProducts().then(() => {
                // EPOS_INDEX.PRODUCTS
                progress.products = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('products', errorMessage));

            this.$injector.get('PrivilegesService').loadPrivileges().then(() => {
                // EPOS_INDEX.PRIVILEGES
                progress.privileges = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('privileges', errorMessage));

            this.$injector.get('ReturnReasonService').loadReturnReasons().then(() => {
                // EPOS_INDEX.RETURNREASONS
                progress.returnReasons = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('returnReasons', errorMessage));

            this.$injector.get('SaleableItemService').load().then(() => {
                // EPOS_INDEX.ITEMS
                progress.items = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('items', errorMessage));

            this.$injector.get('SessionManager').loadSessions(this.user.currentBranch).then(() => {
                // EPOS_INDEX.SESSIONS
                progress.sessions = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('sessions', errorMessage));

            this.$injector.get('TemplateService').preloadTemplates(device.printMethod.driverRef).then(() => {
                // EPOS_INDEX.TEMPLATES
                progress.templates = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('templates', errorMessage));

            this.$injector.get('TicketService').loadTickets().then(() => {
                // EPOS_INDEX.TICKETS
                progress.tickets = true;
                updateProgress();
            }).catch((errorMessage) => onFailure('tickets', errorMessage));
        } catch (e) {
            console.log('Caught error while syncing', e);
            onFailure(null, e.toString());
        }

        return deferred.promise;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DataSyncer;
}
