const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
/**
 * This is the controller for the inner part of the payment modal for GoCardless.
 *
 * @param $scope
 * @param $filter
 * @param {CartService} cartService
 * @param Notification
 */
const GoCardlessCtrl = function GoCardlessCtrl(
    $scope,
    $filter,
    cartService,
    Notification
) {
    $scope.thisPaymentMethodRef = PaymentMethodRef.BANK_GOCARDLESS_JSFLOW_BACS;
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);
    $scope.cart = cartService;
    $scope.chosenTokenID = null;
    $scope.PaymentPatterns = DigiTickets.PaymentPatterns;
    $scope.maxMandates = 10;

    $scope.$on('pay-mthd-cancel', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Was cancelled from this tab.
            $scope.selectPaymentToken(null);
        }
    });

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        if (newPaymentMethodRef !== $scope.thisPaymentMethodRef) {
            // Changing tab, so reset the selection.
            $scope.selectPaymentToken(null);
        }
    });

    $scope.$on('payment-modal.ok-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            if (!$scope.cart.customer.account || !$scope.cart.customer.account.selectedPaymentToken) {
                Notification.error(
                    $filter('lang')('PAYMENT_GOCARDLESS.NO_MANDATE_SELECTED')
                );

                $scope.preventClose();
                return false;
            }
            let payment = new DigiTickets.Payment();
            payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
            payment.setTendered($scope.cart.getRemainingMaxPayment());
            payment.setChangeGiven(0);
            payment.setValue($scope.cart.getRemainingMaxPayment());
            payment.setPaymentTokenID($scope.chosenTokenID);
            payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
            payment.paid = false;
            $scope.addPayment(payment);
        }
    });

    $scope.selectPaymentToken = function selectPaymentToken(chosenTokenID) {
        if (!$scope.cart.customer || !$scope.cart.customer.account) {
            // No customer account selected.
            return;
        }

        if ($scope.cart.customer.account.selectedPaymentToken === chosenTokenID) {
            chosenTokenID = null;
        }
        $scope.cart.customer.account.selectedPaymentToken = chosenTokenID;
        $scope.chosenTokenID = chosenTokenID;
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GoCardlessCtrl;
}
