const RefValidator = function (
    $filter
) {
    this.$filter = $filter;

    this.referencePatterns = {
        account: {
            system: /^C[1-9A-Z]{6,7}[0-9]{1}$/i // Pattern for system-generated account references.
        },

        contact: {
            system: /^P[0-9]{7,}$/i, // Pattern for system-generated contact references.
            user: /^[A-Z0-9]{1,20}$/i // Pattern for user-entered contact references.
        },

        itemInstance: {
            system: /^I[0-9A-Z]{7}$/i // Pattern for system-generated item instance refs.
        },

        membership: {
            system: /^M[0-9]{5,}$/i, // Pattern for system-generated membership references.
            // The "~" below is so that the operator can say, "This membership replaces that membership".
            user: /^~?[A-Z0-9]{1,20}$/i // Pattern for user-entered membership references.
        },

        order: {
            system: /^D[0-9A-Z]{7,8}$/i // Pattern for system-generated booking refs.
        }
    };

    /**
     * Used in error messages when telling the user they can't use that system-generated format
     * as their ref because it would not have been the one originally assigned to the item.
     *
     * It's only necessary to enter these for types can can be entered by the user.
     */
    this.systemReferenceExamples = {
        account: 'CXXXXXXX0',
        contact: 'P0000000',
        itemInstance: 'I0000000',
        membership: 'M00000',
        order: 'DXXXXXXX'
    };

    /**
     * Used in error messages when telling the user what format the user-entered reference should be in.
     *
     * It's only necessary to enter these for types can can be entered by the user.
     */
    this.userReferenceRequirements = {
        contact: 'alphanumeric and up to 20 characters',
        membership: 'alphanumeric and up to 20 characters'
    };
};

RefValidator.prototype = {
    /**
     * @param {string} ref The reference to validate.
     * @param {string} type What the reference is for (membership/contact etc.)
     * @param {boolean} [required] Does it need to be filled?
     * @param {string|null} [allowedSystemRef] If it matches the system ref format, allow it only if it is this.
     *
     * @return {boolean|Object<string>}
     */
    validateRef: function (ref, type, required, allowedSystemRef) {
        let formattedError;

        if (!ref) {
            if (required) {
                formattedError = this.$filter('lang')(
                    'REF_VALIDATION.EMPTY_REF'
                );
                return {
                    error: 'EMPTY_REF',
                    formattedError
                };
            }
            return true;
        }

        // 1. Check the given ref matches the required user pattern for this type.
        let userPattern;
        if (this.referencePatterns[type] && this.referencePatterns[type].user) {
            userPattern = this.referencePatterns[type].user;
        }
        if (userPattern) {
            if (!this.matchesPattern(ref, userPattern)) {
                formattedError = this.$filter('lang')(
                    'REF_VALIDATION.INVALID_USER_PATTERN',
                    {
                        requirements: this.userReferenceRequirements[type]
                    }
                );
                return {
                    error: 'INVALID_USER_PATTERN',
                    formattedError,
                    type,
                    pattern: userPattern,
                    requirements: this.userReferenceRequirements[type]
                };
            }
        }

        // 2. Check it does not match the system patten for a different type.
        for (let otherType in this.referencePatterns) {
            if (this.referencePatterns.hasOwnProperty(otherType)) {
                if (type !== otherType && this.referencePatterns[otherType].system) {
                    if (this.matchesPattern(ref, this.referencePatterns[otherType].system)) {
                        formattedError = this.$filter('lang')(
                            'REF_VALIDATION.MATCHES_OTHER_SYSTEM_PATTERN',
                            {
                                example: this.systemReferenceExamples[otherType]
                            }
                        );
                        return {
                            error: 'MATCHES_OTHER_SYSTEM_PATTERN',
                            formattedError,
                            type: otherType,
                            pattern: this.referencePatterns[otherType].system,
                            example: this.systemReferenceExamples[otherType]
                        };
                    }
                }
            }
        }

        // 3. Check it does not match the system patten for this type,
        // unless it matches the original system reference (passed in).
        let systemPattern;
        if (this.referencePatterns[type] && this.referencePatterns[type].system) {
            systemPattern = this.referencePatterns[type].system;
        }
        if (systemPattern) {
            if (this.matchesPattern(ref, systemPattern)) {
                if (!allowedSystemRef || ref !== allowedSystemRef) {
                    if (allowedSystemRef) {
                        formattedError = this.$filter('lang')(
                            'REF_VALIDATION.MATCHES_SYSTEM_PATTERN',
                            {
                                example: this.systemReferenceExamples[type],
                                original: allowedSystemRef
                            }
                        );
                    } else {
                        formattedError = this.$filter('lang')(
                            'REF_VALIDATION.MATCHES_SYSTEM_PATTERN_NO_ORIG',
                            {
                                example: this.systemReferenceExamples[type]
                            }
                        );
                    }
                    return {
                        error: 'MATCHES_SYSTEM_PATTERN',
                        formattedError,
                        type,
                        pattern: systemPattern
                    };
                }
            }
        }

        // It's a syntactically valid ref.
        // It still might be a duplicate, but we can't check that on the EPOS.
        return true;
    },

    /**
     * @param {string} ref
     * @param {RegExp} pattern
     *
     * @return {boolean}
     */
    matchesPattern: function (ref, pattern) {
        return pattern.test(ref);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = RefValidator;
}
