const PrivilegesResource = function ($resource, ApiRequestParams, AppConfig, PrivilegesCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'privileges/',
        ApiRequestParams.defaults(),
        {
            query: {
                cache: PrivilegesCache,
                isArray: true,
                method: 'GET'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrivilegesResource;
}
