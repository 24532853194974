const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const TicketResource = function ($resource, ApiRequestParams, AppConfig, TicketCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'tickets/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                channelID: AppConfig.channelID
            }
        ), {
            query: {
                cache: TicketCache,
                isArray: true,
                method: 'GET',
                params: Object.assign(
                    {
                        resolve: 'events,categories',
                        excludeFields: ApiRequestParams.defaultExcludeFields()
                    },
                    ApiRequestParams.defaultMappers()
                ),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'Ticket')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TicketResource;
}
