/**
 * Print driver for use in unit tests.
 * It does nothing and just says it's done after 100ms.
 */
const DummyPrintDriver = function () {
};

DummyPrintDriver.prototype = {
    /**
     * @param {Window} frame
     * @param {PrintJobOptions} opts
     *
     * @return {promise|jQuery.promise|*|{then, catch, finally}}
     */
    print: function (frame, opts) {
        return new Promise((resolve) => {
            if (opts && typeof opts.onBeforePrintCall === 'function') {
                opts.onBeforePrintCall();
            }

            setTimeout(() => {
                resolve();
            }, 100);
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DummyPrintDriver;
}
