const suppressZeroFilter = function ($filter) {
    return function (input, format) {
        let args = [];
        for (let key in arguments) {
            if (key != 1) {
                args.push(arguments[key]);
            }
        }
        return input == 0 ? '·' : $filter(format).apply(undefined, args);
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = suppressZeroFilter;
}
