DigiTickets.GiftVoucherValidator = function () {
};

DigiTickets.GiftVoucherValidator.prototype = {
    /**
     * @param {GiftVoucher} giftVoucher
     * @param {DigiTickets.SoldGiftVoucherData} data
     * @param {number} [price]
     *
     * @return {string|bool} Returns an error message or true if valid.
     */
    validate: function validate(giftVoucher, data, price) {
        if (giftVoucher.giftVoucherType === DigiTickets.GiftVoucherType.BALANCE) {
            if (!price || price <= 0.00) {
                return 'GIFT_VOUCHERS.STARTING_BALANCE_TOO_LOW_ERROR';
            }

            // Check valid denomination is given.
            if (!giftVoucher.allowPriceEdit) {
                if (giftVoucher.getDenominationsByValue(price).length < 1) {
                    return 'GIFT_VOUCHERS.INVALID_DENOMINATION_ERROR';
                }
            }
        }

        if (data.deliveryType === DigiTickets.GiftVoucherDeliveryType.EMAIL) {
            if (!data.recipientContact || !data.recipientContact.email) {
                return 'GIFT_VOUCHERS.EMAIL_REQUIRED_ERROR';
            }
        }

        // Check name is entered if required.
        if (giftVoucher.mustCollectRecipientName() && (!data.recipientContact || !data.recipientContact.getFullName())) {
            return 'GIFT_VOUCHERS.RECIPIENT_NAME_REQUIRED_ERROR';
        }

        // Check message is entered if required.
        if (giftVoucher.mustCollectRecipientMessage() && !data.recipientMessage) {
            return 'GIFT_VOUCHERS.RECIPIENT_MESSAGE_REQUIRED_ERROR';
        }

        return true;
    }
};
