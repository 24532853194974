const GiftVoucher = require('@/lib/GiftVouchers/GiftVoucher');
const { toDate, toNullableFloat, toInt, toString, toNullableString } = require('../functions/transform');

const SoldGiftVoucher = function () {
    /** @type {?number} */
    this.currentBalance = null;

    /** @type {string} */
    this.deliveryType = '';

    /** @type {?Date} */
    this.expiresAt = null;

    /** @type {?GiftVoucher} */
    this.giftVoucher = null;

    /** @type {?number} */
    this.ID = null;

    /** @type {?string} */
    this.ref = null;

    /** @type {?number} */
    this.initialBalance = null;

    /** @type {?DigiTickets.Contact} */
    this.recipient = null;

    /** @type {?string} */
    this.recipientMessage = null;

    /** @type {?Date} */
    this.redeemedAt = null;

    /** @type {?Date} */
    this.redeemedAt = null;

    /** @type {string} */
    this.status = 'Active';
};

SoldGiftVoucher.prototype = {
    getHydrationMap() {
        return {
            currentBalance: toNullableFloat,
            deliveryType: toString,
            expiresAt: toDate,
            giftVoucher: {
                model: GiftVoucher
            },
            ID: {
                field: ['ID', 'soldGiftVoucherID'],
                transform: toInt
            },
            giftVoucherID: toInt,
            ref: toString,
            status: toString,
            initialBalance: toNullableFloat,
            recipientMessage: toNullableString,
            redeemedAt: toDate,
            recipient: {
                model: DigiTickets.Contact
            }
        };
    },

    /**
     * @return {string}
     */
    get redeemableStatus() {
        if (this.isRedeemed) {
            return DigiTickets.SoldGiftVoucherRedeemableStatus.REDEEMED;
        }
        if (this.isExpired) {
            return DigiTickets.SoldGiftVoucherRedeemableStatus.EXPIRED;
        }
        return DigiTickets.SoldGiftVoucherRedeemableStatus.REDEEMABLE;
    },

    /**
     * @returns {boolean}
     */
    get isRedeemed() {
        return this.redeemedAt !== null;
    },

    /**
     * @returns {boolean}
     */
    get isRedeemable() {
        return this.redeemableStatus === DigiTickets.SoldGiftVoucherRedeemableStatus.REDEEMABLE;
    },

    /**
     * @returns {boolean}
     */
    get isExpired() {
        return this.expiresAt !== null && this.expiresAt < new Date();
    },

    /**
     * @returns {boolean}
     */
    get isExperienceType() {
        return this.giftVoucher && this.giftVoucher.giftVoucherType === DigiTickets.GiftVoucherType.EXPERIENCE;
    },

    /**
     * @returns {boolean}
     */
    get isBalanceType() {
        return this.giftVoucher && this.giftVoucher.giftVoucherType === DigiTickets.GiftVoucherType.BALANCE;
    }
};

DigiTickets.SoldGiftVoucher = SoldGiftVoucher;

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SoldGiftVoucher;
}
