const CustomerScreenEvents = {
    STATE_UPDATED: 'STATE_UPDATED',
    CANCEL_QUESTION: 'CANCEL_QUESTION',
    ASK_QUESTION: 'ASK_QUESTION',
    QUESTION_ANSWERED: 'QUESTION_ANSWERED',
    // Customer screen is currently displaying the question.
    QUESTION_DISPLAYED: 'QUESTION_DISPLAYED',
    // Customer screen has received the question, but it is not yet shown.
    QUESTION_RECEIVED: 'QUESTION_RECEIVED'
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerScreenEvents;
}
