const angular = require('angular');
const { cloneDeep } = require('../../functions/clone');

/**
 * @param CustomerAccountsResource
 * @param {CustomerAccountSearchCache} customerAccountSearchCache
 * @param {Hydrator} hydrator
 */
const CustomerAccountService = function (
    CustomerAccountsResource,
    customerAccountSearchCache,
    hydrator
) {
    this.customerAccountsResource = CustomerAccountsResource;
    this.customerAccountSearchCache = customerAccountSearchCache;
    this.hydrator = hydrator;
};

CustomerAccountService.prototype = {
    /**
     * Returns a single customer account either from the search cache or from the API.
     *
     * @param {Number} id
     * @param {function} callback Receives either DigiTickets.CustomerAccount or null
     */
    find: function (id, callback) {
        const self = this;

        // Cache is disabled because it causes issues with stale data when returning to the account page.
        // var account = this.customerAccountSearchCache.getAccountFromCache(id);
        //       if (account) {
        //       callback(account);
        //       return;
        //       }

        this.customerAccountsResource.get(
            {
                accountID: id,
                resolve: '*'
            },
            function (response) {
                if (response) {
                    let model = self.hydrator.hydrate(response, new DigiTickets.CustomerAccount());
                    callback(model);
                } else {
                    callback(null);
                }
            },
            function () {
                callback(null);
            }
        );
    },

    /**
     * A wrapper around the CustomerAccountsResource.query method, because angular-resource
     * messes with objects that are returned, even in transformResponse.
     * This takes the raw data returned by CustomerAccountsResource and returns actual
     * CustomerAccount objects.
     *
     * @param {object} params
     * @param {function} success
     * @param {function} failure
     */
    query: function (params, success, failure) {
        const self = this;
        params.resolve = 'memberships';

        this.customerAccountsResource.query(
            params,
            function (response) {
                response = angular.fromJson(response);

                let models = self.hydrator.hydrateArray(response, function () {
                    return new DigiTickets.CustomerAccount();
                });

                success(models);
            },
            failure
        );
    },

    /**
     * A wrapper around the CustomerAccountsResource.save method. Returns a CustomerAccount model
     * on success instead of the angular-resource object.
     *
     * @param {object} params
     * @param {function} success
     * @param {function} failure
     */
    save: function (params, success, failure) {
        const self = this;
        let customerAccountParams = cloneDeep(params);
        this.customerAccountsResource.save(
            customerAccountParams,
            function (response) {
                response = angular.fromJson(response);

                let customerAccount = null;
                if (!response.error) {
                    customerAccount = self.hydrator.hydrate(response, new DigiTickets.CustomerAccount());
                }

                success(response, customerAccount);
            },
            failure
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerAccountService;
}
