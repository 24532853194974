const apiErrorMessage = require('../Api/apiErrorMessage');
const { cloneShallow } = require('../../../functions/clone');
const PaymentMethodRef = require('../PaymentMethods/PaymentMethodRef');

/**
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {Hydrator} hydrator
 * @param {PaymentResource} PaymentResource
 */
const PaymentService = function (
    AppConfig,
    hydrator,
    PaymentResource
) {
    this.appConfig = AppConfig;
    this.hydrator = hydrator;
    this.paymentResource = PaymentResource;

    /**
     * Array of payment method refs that are allowed to have zero value if they are not the only payment in payments[].
     * For example a tax on sale Experience voucher may have zero value.
     *
     * @type {string[]}
     */
    this.allowedZeroAmountPaymentMethods = [
        PaymentMethodRef.GIFT_VOUCHER,
    ];
};

PaymentService.prototype = {
    /**
     * @param {number} orderID
     * @param {DigiTickets.Payment[]} payments
     *
     * @return {Promise<DigiTickets.Payment[]>} Returns every payment for the order.
     */
    storePaymentsForOrder(orderID, payments) {
        return new Promise((resolve, reject) => {
            let paymentsForApi = this.convertPaymentsToApiStructure(payments);

            this.paymentResource.store(
                {},
                {
                    orderID,
                    orderId: orderID, // Needed until OE-156 is released
                    payments: paymentsForApi
                },
                (response) => {
                    if (response && response.success) {
                        let allPayments = this.hydrator.hydrateArray(
                            response.payments,
                            () => new DigiTickets.Payment()
                        );
                        resolve(allPayments);
                    } else {
                        reject(new Error(response.error || response.message || ''));
                    }
                },
                (result) => {
                    reject(new Error(apiErrorMessage(result)));
                }
            );
        });
    },

    /**
     * TODO: PRO-825 change this to accept a Cart instead of CartService.
     *
     * @param {CartService} cartService
     *
     * @return {*}
     */
    getPaymentsFromCartForApi(cartService) {
        let payments = cartService.payments;
        payments = this.cleanPayments(payments);

        return this.convertPaymentsToApiStructure(payments);
    },

    /**
     * Filter out payments that already have an ID because sending those again would cause a duplicate to be created.
     * Then convert each payment to the structure needed by the API.
     *
     * @param {DigiTickets.Payment[]} payments
     *
     * return {{ amount: number, paymentChannelID: number, paymentMethodID: number}[]}
     */
    convertPaymentsToApiStructure(payments) {
        return payments
            .filter((p) => !p.ID)
            .map((p) => this.convertPaymentToApiStructure(p));
    },

    /**
     * @param {DigiTickets.Payment} payment
     *
     * return {{ amount: number, paymentChannelID: number, paymentMethodID: number}}
     */
    convertPaymentToApiStructure(payment) {
        if (!(payment instanceof DigiTickets.Payment)) {
            throw new Error(
                'Argument passed to convertPaymentToApiStructure should be an instance of DigiTickets.Payment'
            );
        }
        let p = cloneShallow(payment);

        if (payment.paymentMethod.supportsChange) {
            // If any cash payments have a negative tendered amount, move that to the changeGive amount.
            if (p.tendered < 0) {
                p.changeGiven += Math.abs(p.tendered);
                p.tendered = 0;
                p.amount = -p.changeGiven;
            }
        }

        // Set the paymentChannelID and don't sent the entire paymentChannel object.
        p.paymentChannelID = this.appConfig.channelID;
        delete p.paymentChannel;

        // Set the paymentMethodID and don't send the entire paymentMethod object.
        p.paymentMethodID = payment.getPaymentMethodID();
        delete p.paymentMethod;

        // We aren't sending this to the API but it is included in Payment to hydrate historic payments.
        // For compatibility with OE-166
        delete p.pending;

        return p;
    },

    /**
     * This does the same thing CartService.removeEmptyPayments and cartService.exportPayments did previously.
     *
     * Remove any payments from the cart that have 0 tendered and 0 changeGiven or which are from a legitimate
     * zero value payment such as a tax on sale experience gift voucher.
     *
     * If this leaves us with no payments at all and there ARE some zero value payments keep just one of them.
     *
     * @param {DigiTickets.Payment[]} payments
     *
     * @return {DigiTickets.Payment[]} payments
     */
    cleanPayments(payments) {
        // Recalculate the amount property of the payment.
        payments.forEach((p) => p.calculateAmount());

        const nonZeroPayments = payments.filter(
            (p) => p.amount !== 0 || this.allowedZeroAmountPaymentMethods.includes(p.paymentMethod.ref)
        );

        // If there are some non-zero payments keep all those and ignore the rest (which will be zero value).
        if (nonZeroPayments.length > 0) {
            // Remove any duplicate payments by putting into a set.
            return [...new Set(nonZeroPayments)];
        }

        // If if there are only zero value payments keep the first one so we have at least 1 payment and lose the rest.
        if (payments.length > 0) {
            return [
                payments[0],
            ];
        }

        return [];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentService;
}
