const angular = require('angular');
const { cloneDeep } = require('../functions/clone');
const { toDate, toNullableString } = require('../functions/transform');

/**
 * Formerly called 'MembersAndAddress'.
 *
 * Represents all the data for a membership while editing it, including the list of members.
 * This may be an as-yet uncreated membership. Or info from an existing membership being modified.
 *
 * @param {DigiTickets.MembershipPlan} membershipPlan
 */
DigiTickets.MembershipDataset = function (membershipPlan) {
    // It comprises a list of members and an address.
    // The complications are that the first member can be flagged as being the customer,
    // and the address can be flagged as being the customer address.
    // We now allow them to enter the membership ref, in case they give out the card immediately.
    this.assignedMembershipRef = '';

    // These might get overridden if there's a plan and it has an allowed usage date range that starts in the future.
    this.membershipDateFrom = new Date();
    this.allowedUsageStartsInFuture = false;

    /**
     * @type {DigiTickets.Member[]}
     */
    this.members = [];

    /**
     * If editing an existing membership this will be populated (by the Membership's toMembershipDataset() method).
     *
     * @type {number|null}
     */
    this.membershipID = null;

    /**
     * @type {DigiTickets.MembershipPlan}
     */
    this.membershipPlan = null;

    /**
     * @type {DigiTickets.Address}
     */
    this.address = new DigiTickets.Address();

    /**
     * If an existing address is being used this value will be populated.
     *
     * @type {number|null}
     */
    this.addressID = null;

    this.isActive = false; // For the tabs in the pop-up.

    /**
     * @type {string|null}
     */
    this.assignedRefErrorMessage = null;

    /**
     * @type {string|null}
     */
    this.currentContactRefErrorMessage = null;

    /**
     * 0 = the membership ref / start data / address page.
     * 1 = the members page.
     *
     * @type {number}
     */
    this.activeDeck = 0;

    this.captureDatesOfBirth = false; // Is populated from membership plan in the cart item below.
    this.maximumPeople = 0;
    this.minimumAdults = 0;
    this.minimumChildren = 0;
    this.memberCutoffAgeYears = null;

    // Initialise the empty list of members, and the address.
    // If we are in the process of restoring the item, we'll automatically add
    // the membership data back in via the hydration.
    if (membershipPlan) {
        // Use a copy of the item and remove the nested items in the category.
        this.membershipPlan = angular.extend(new DigiTickets.MembershipPlan(), membershipPlan);
        this.membershipPlan.category.items = [];

        this.initialiseMembers(membershipPlan.people);
        this.captureDatesOfBirth = membershipPlan.captureDatesOfBirth;
        this.maximumPeople = membershipPlan.people;
        this.minimumChildren = membershipPlan.minimumChildren;
        this.minimumAdults = membershipPlan.minimumAdults;
        this.memberCutoffAgeYears = membershipPlan.memberCutoffAgeYears;
        if (membershipPlan.allowedUsageFromDate != null) {
            if (this.membershipDateFrom < membershipPlan.allowedUsageFromDate) {
                this.membershipDateFrom = membershipPlan.allowedUsageFromDate;
                this.allowedUsageStartsInFuture = true;
            }
        }
    }

    /**
     * The member that is currently being edited.
     *
     * @type {DigiTickets.Member}
     */
    this.currentMember = null;

    /**
     * The contact data that is currently being edited.
     * When selecting a member this becomes a clone of the existing contact if there is one,
     * or a new DigiTickets.Contact object if there is not.
     * When saving this contact will be set at the contact for this.currentMember.
     *
     * @type {DigiTickets.Contact}
     */
    this.currentContact = null;

    /**
     * This is set when selecting a member to the member's contact ID (null if a new member / new contact).
     * The 'Select A Contact' dropdown in the form is bound to this value so will change with that.
     * Upon changing, the selectedExistingContact() method will fire in the controller which will
     * switch out the currentContact property of this dataset.
     * This is so the change is not saved to the member until the save button is pressed.
     */
    this.currentContactID = null;
};

DigiTickets.MembershipDataset.prototype = {
    getHydrationMap() {
        return {
            address: {
                model: DigiTickets.Address
            },
            assignedMembershipRef: toNullableString,
            membershipDateFrom: toDate,
            membershipPlan: {
                model: DigiTickets.MembershipPlan
            },
            members: {
                modelCollection: DigiTickets.Member
            }
        };
    },

    initialiseMembers: function initialiseMembers(numMembers) {
        // Build the appropriate number of empty Member entities.
        for (let i = 0; i < numMembers; i++) {
            let member = new DigiTickets.Member();
            // We create the member but do not give them a contact yet.
            // Memberships in the database will always have the full number of members but some of them
            // may not have contacts.
            // member.contact = new DigiTickets.Contact();
            member.setIndex(i);
            this.members.push(member);
        }
    },

    /**
     * @param {DigiTickets.Member} member
     */
    selectMember: function selectMember(member) {
        if (!member) {
            this.currentMember = null;
            this.currentContact = null;
            this.currentContactID = null;
            return;
        }

        let contact;
        if (member.contact) {
            contact = cloneDeep(member.contact);
        } else {
            contact = new DigiTickets.Contact();
        }
        this.currentContact = contact;
        this.currentMember = member;
        this.currentContactID = contact.ID;
    },

    /**
     * @param {Number} index
     */
    selectMemberAtIndex: function selectMemberAtIndex(index) {
        if (this.members.hasOwnProperty(index)) {
            this.selectMember(this.members[index]);
        } else {
            this.selectMember(null);
        }
    },

    /**
     * Save the data from this.currentContact to the currently selected member.
     *
     * @param selectNext
     */
    saveCurrentMember: function saveCurrentMember(selectNext) {
        this.currentMember.setContact(this.currentContact);
        this.currentMember.dirty = true;

        if (selectNext) {
            // Select next member.
            this.selectMemberAtIndex(this.currentMember.index + 1);
        }
    },

    /**
     * The members will always exist in the database even if there is nobody set for them.
     * So to remove a member we just remove the member's contact.
     *
     * @param {DigiTickets.Member} member
     */
    removeMemberContact: function removeMemberContact(member) {
        member.setContact(null);
        this.selectMember(null);
    },

    /**
     * Returns all the non-null members from the this.members array.
     *
     * @return {DigiTickets.Member[]}
     */
    getMembers: function getMembers() {
        return this.members.filter(function (m) {
            return !!m;
        });
    },

    /**
     * Returns all the members from the this.members array that have a non-null contact.
     *
     * @return {DigiTickets.Member[]}
     */
    getMembersWithContacts: function getMembersWithContacts() {
        return this.members.filter(function (m) {
            return !!m && !!m.contact;
        });
    },

    /**
     * @return {Date}
     */
    getDateFrom: function getDateFrom() {
        if (this.membershipDateFrom) {
            return this.membershipDateFrom;
        }

        return (new Date()).setHours(0, 0, 0, 0);
    },

    /**
     * Return only the info that is needed to sent to the API
     * (exclude things to do with the form interface)
     */
    toServerData: function toServerData() {
        let data = {
            address: this.address ? this.address.toServerData() : null,
            assignedMembershipRef: this.assignedMembershipRef, // assignedMembershipRef for new memberships
            ref: this.assignedMembershipRef, // ref for new memberships
            membershipDateFrom: this.membershipDateFrom ? this.membershipDateFrom.toYMD() : null,
            membershipPlanID: this.membershipPlan ? this.membershipPlan.getId() : null,
            membershipID: this.membershipID,
            members: []
        };
        for (let i = 0; i < this.members.length; i++) {
            let member = this.members[i];
            data.members.push({
                memberID: member.ID,
                contactID: member.contactID,
                contact: member.contact ? member.contact.toServerData() : null
            });
        }

        return data;
    }
};
