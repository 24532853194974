/**
 * @param {object} $scope
 * @param $modalInstance
 * @param {DigiTickets.Address} address
 */
const AddressModalCtrl = function (
    $scope,
    $modalInstance,
    address
) {
    $scope.address = address;

    $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
    };

    $scope.ok = () => {
        $modalInstance.close(
            {
                address: $scope.address
            }
        );
    };
};

// export {AddressModalCtrl};
