const angular = require('angular');

/**
 * Create the angular-cache caches.
 *
 * The caches created are all using our own storage implementation.
 *
 * The defaults for the storage implementation are defined in DigiTickets.StorageImplementation.angularCacheDefaults.
 *
 * @param {angular} app
 * @constructor
 *
 * The following caches are cleared by the CacheManager
 * @property {AngularCache} FieldCache
 * @property {AngularCache} MembershipPlanCache
 * @property {AngularCache} OfferCache
 * @property {DigiTickets.LargeVolumeStorageImplementation} OrderCache
 * @property {AngularCache} ProductCache
 * @property {AngularCache} Privileges
 * @property {AngularCache} ReturnReasonCache
 * @property {AngularCache} TemplateCache
 * @property {AngularCache} TicketCache
 *
 * The following caches are never cleared automatically.
 * @property {AngularCache} CurrentDeviceCache - Persistent
 * @property {AngularCache} OnlineQueueCache - Persistent
 * @property {AngularCache} OrderQueueCache - Persistent
 */
DigiTickets.CacheFactory = function CacheFactory(
    app
) {
    angular.forEach([
        'currentDevice',
        'field',
        'giftVoucher',
        'membershipPlan',
        'offer',
        'onlineQueue',
        'orderQueue',
        'privileges',
        'product',
        'returnReason',
        'soldGiftVoucher',
        'template',
        'ticket',
    ], function (cache) {
        app.factory(cache.ucfirst() + 'Cache', [
            '$angularCacheFactory',
            /**
             * @param $angularCacheFactory
             * @returns {AngularCache}
             */
            function ($angularCacheFactory) {
                // @see http://www.pseudobry.com/angular-cache/configuration.html
                $angularCacheFactory(cache + 'Cache', {
                    storageImpl: new DigiTickets.StorageImplementation(),
                    storageMode: 'localStorage',
                    verifyIntegrity: false,
                    maxAge: null,
                    deleteOnExpire: 'none'
                });
                return $angularCacheFactory.get(cache + 'Cache');
            }]);
    });

    angular.forEach([
        { name: 'order', keyPrefix: 'o' },
    ], function (cache) {
        let cacheName = cache.name.ucfirst() + 'Cache';
        app.factory(cacheName, [
            function () {
                // FIXME: (PRO-1071) This looks like a bug. The signature asks for cacheName but it is provided keyPrefix.
                return new DigiTickets.LargeVolumeStorageImplementation(cache.keyPrefix);
            }]);
    });
};
