const angular = require('angular');

const byItemTypeFilter = function (items, itemType) {
    let filtered = [];
    angular.forEach(items, function (el) {
        if (el.itemType && el.itemType.indexOf(itemType) > -1) {
            filtered.push(el);
        }
    });
    return filtered;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = byItemTypeFilter;
}
