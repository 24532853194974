const Category = require('@/lib/Categories/Category');
const GiftAidRate = require('./GiftAidRate');
const moment = require('moment');
const { toBool, toNullableBool, toDate, toFloat, toNullableFloat, toInt, toNullableInt } = require('../functions/transform');

DigiTickets.MembershipPlan = function () {
    /**
     * @type {Date}
     */
    this.allowedUsageFromDate = null;
    /**
     * @type {Date}
     */
    this.allowedUsageToDate = null;

    this.allowPriceEdit = false;
    this.category = {};

    /**
     * @type {Number}
     */
    this.catID = null;

    this.giftAid = false;
    /**
     * @type {?GiftAidRate}
     */
    this.giftAidRate = null;
    this.ID = 0;
    this.itemType = DigiTickets.ItemType.MEMBERSHIP_PLAN;
    this.name = '';
    this.navOrder = 10000;
    this.offlinePrice = null;
    this.paymentPattern = null;
    this.people = 0;
    this.minimumAdults = 0;
    this.minimumChildren = 0;

    /**
     * @see {DigiTickets.MembershipDobRequirement}
     *
     * @type {number}
     */
    this.captureDatesOfBirth = DigiTickets.MembershipDobRequirement.NO;

    /**
     * If minimumAdults or minimumChildren > 0 and this is set this is the maximum age child members can be.
     * If minimumAdults or minimumChildren = 0 and this is set this is the maximum age any member can be.
     *
     * @type {number|null}
     */
    this.memberCutoffAgeYears = null;

    this.price = 0.00;
    this.shortName = '';
    this.autoRenew = false;
    this.maxRedemptions = 0;

    // Validity period and billing frequency.
    this.validityPeriodType = null;
    this.validityPeriodCount = null;
    this.billingFrequency = null;
    // Allowed values for the above
    this.allowedBillingFrequency = {
        planLength: 'Plan Length',
        monthly: 'Monthly'
    };
    this.allowedValidityPeriodType = {
        year: 'Year',
        month: 'Month'
    };
    this.totalPrice = 0.00;
};

DigiTickets.MembershipPlan.prototype = {
    /**
     * @return {number}
     */
    getId: function getId() {
        return this.ID;
    },

    /**
     * @returns {string}
     */
    getName: function getName() {
        // use shortName in epos by default if available
        return this.shortName ? this.shortName : this.name;
    },

    /**
     * @returns {number}
     */
    getPrice: function getPrice() {
        return parseFloat(this.price);
    },

    /**
     * @returns {number}
     */
    getPeople: function getPeople() {
        return parseFloat(this.people);
    },

    /**
     * @returns {String}
     */
    getValidFor: function getValidFor() {
        return this.validityPeriodCount + ' ' + this.validityPeriodType + '(s)';
    },

    /**
     * @returns {String}
     */
    getBillingInterval: function getBillingInterval() {
        return (this.billingFrequency == this.allowedBillingFrequency.planLength ? 'At start' : this.billingFrequency);
    },

    /**
     * @returns {boolean}
     */
    isSessionBased: function isSessionBased() {
        return false;
    },

    /**
     * @returns {Number}
     */
    getTotalPrice: function getTotalPrice() {
        return this.totalPrice;
    },

    /**
     * @returns {object}
     */
    getHydrationMap() {
        return {
            allowedUsageFromDate: toDate,
            allowedUsageToDate: toDate,
            allowPriceEdit: toNullableBool,
            autoRenew: toBool,
            billingFrequency: {},
            captureDatesOfBirth: toInt,
            category: {
                field: ['category', 'categories'],
                model: Category
            },
            catID: toInt,
            giftAid: toBool,
            giftAidRate: {
                model: GiftAidRate
            },
            ID: {
                field: ['ID', 'itemID'],
                transform: toInt
            },
            maxRedemptions: toInt,
            memberCutoffAgeYears: toNullableInt,
            minimumAdults: toInt,
            minimumChildren: toInt,
            name: {},
            navOrder: toInt,
            offlinePrice: toNullableFloat,
            paymentPattern: {},
            people: toInt,
            price: toFloat,
            shortName: {},
            totalPrice: toFloat,
            validityPeriodCount: toInt,
            validityPeriodType: {}
        };
    },

    getEarliestAllowedStartDate: function getEarliestAllowedStartDate() {
        // Basically this returns the earliest possible start date for the membership plan where the
        // "next" payment due is still in the future. Of course the "next" payment could be an installment
        // or a renewal.
        // If we don't understand the membership plan config (eg because someone has added a new option),
        // we just assume today is the earliest allowed date.
        // Because we need to add 1 day to the date, we actually start with "tomorrow" and do the calculation;
        // if we don't understand the config, we subtract 1 day.
        let result = moment().add(1, 'day');
        switch (this.billingFrequency) {
            case this.allowedBillingFrequency.monthly:
                // Earliest allowed date is 1 month ago.
                result = result.subtract(1, 'month');
                break;
            case this.allowedBillingFrequency.planLength:
                // Earliest allowed date is the length of the plan
                switch (this.validityPeriodType) {
                    case this.allowedValidityPeriodType.year:
                        result = result.subtract(this.validityPeriodCount, 'year');
                        break;
                    case this.allowedValidityPeriodType.month:
                        result = result.subtract(this.validityPeriodCount, 'month');
                        break;
                    default:
                        result = moment().subtract(1, 'day'); // Back to today.
                        break;
                }
                break;
            default:
                result = moment().subtract(1, 'day'); // Back to today.
                break;
        }

        // If the membership has an allowed usage date range, we have to make sure the return value is compatible with that.
        if (this.allowedUsageFromDate != null) {
            let allowedUsageStart = moment(this.allowedUsageFromDate);
            if (result < allowedUsageStart) {
                result = allowedUsageStart;
            }
        }

        return result;
    },

    getLatestAllowedStartDate: function getLatestAllowedStartDate() {
        // At time of writing, this is null, unless the plan has an allowed usage date range.
        return this.allowedUsageToDate; // If it's null, there's no limit.
    },

    /**
     * Capture dates of birth for every member?
     *
     * @return {boolean}
     */
    alwaysCaptureDob: function alwaysCaptureDob() {
        return this.captureDatesOfBirth === DigiTickets.MembershipDobRequirement.MANDATORY;
    },

    /**
     * Capture dates of birth for children only?
     *
     * @return {boolean}
     */
    captureChildDobOnly: function captureChildDobOnly() {
        return this.captureDatesOfBirth === DigiTickets.MembershipDobRequirement.CHILDREN_ONLY;
    },

    /**
     * @return {number}
     */
    getMaxAge: function getMaxAge() {
        return this.memberCutoffAgeYears;
    },

    /**
     * Determine if the memberCutoffAgeYears applies to every member or only members marked as children.
     * If minimumAdults > 0 OR minimumChildren > 0 the max age only applies to children.
     * If minimumAdults = 0 AND minimumChildren = 0 the max age applies to every member.
     *
     * @return {boolean}
     */
    maxAgeIsForChildOnly: function maxAgeIsForChildOnly() {
        return this.minimumAdults > 0 || this.minimumChildren > 0;
    },

    maxAgeIsForEverybody: function maxAgeIsForEverybody() {
        return !this.maxAgeIsForChildOnly();
    },

    canBeSoldToday: function canBeSoldToday() {
        // Plan can be sold today unless it has an allowed usage date range and the "to" date is in the past.
        return this.allowedUsageToDate == null || moment().isSameOrBefore(this.allowedUsageToDate, 'day');
    },

    membershipsCanBeRedeemedToday: function membershipsCanBeRedeemedToday() {
        // The "isBetween" comparison ignores the time component and is inclusive of the dates.
        return this.allowedUsageFromDate == null || moment().isBetween(this.allowedUsageFromDate, this.allowedUsageToDate, 'day', '[]');
    }
};
