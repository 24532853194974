/**
 * @param $q
 * @param {AgentService} AgentService
 */
const AgentPrintDriver = function (
    $q,
    AgentService
) {
    this.$q = $q;
    this.agentService = AgentService;
};

AgentPrintDriver.prototype = {
    /**
     * @param {Window} frame
     * @param {PrintJobOptions} opts
     *
     * @return {promise|jQuery.promise|*|{then, catch, finally}}
     */
    print: function (frame, opts) {
        // Get the HTML from the frame.
        let html = frame.document.documentElement.innerHTML;

        if (opts && typeof opts.onBeforePrintCall === 'function') {
            opts.onBeforePrintCall();
        }

        // Send the HTML to the agent to print.
        // Note: Images must be base64 encoded (inline as the src attribute) URLs to images will not work.
        return this.agentService.print(
            html,
            opts && opts.printRouting ? opts.printRouting.printerName : null
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AgentPrintDriver;
}
