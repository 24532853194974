const { toInt } = require('../../../functions/transform');

const FieldOption = function () {
    /**
     * @type {Number}
     */
    this.ID = null;

    /**
     * Incorrectly named ID field for backward compatibility.
     *
     * @type {number}
     */
    this.fieldOptionID = 0;

    /**
     * @type {Number}
     */
    this.fieldID = null;

    /**
     * @type {string}
     */
    this.name = '';

    /**
     * @type {Number}
     */
    this.navOrder = null;
};

FieldOption.prototype = {
    getHydrationMap() {
        return {
            fieldID: toInt,
            ID: {
                field: ['ID', 'fieldOptionID'],
                transform: toInt
            },
            // Incorrectly named ID field for backward compatibility.
            fieldOptionID: {
                field: ['ID', 'fieldOptionID'],
                transform: toInt
            },
            name: {},
            navOrder: toInt,
            status: {}
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldOption;
}
