const FieldType = require('./FieldType');
const moment = require('moment');
const { getAllFieldGroupsFromOrder } = require('./fieldFunctions');

const FieldValidator = function () {

};

FieldValidator.prototype = {
    /**
     * @param {FieldInstance} fieldInstance
     *
     * @return {boolean}
     */
    validateFieldInstance(fieldInstance) {
        fieldInstance.isValid = null;
        fieldInstance.error = null;
        fieldInstance.ageError = null;

        // Ensure required fields are filled out.
        if (fieldInstance.field.required && !fieldInstance.hasValue) {
            fieldInstance.isValid = false;
            fieldInstance.error = 'Please fill out this field.';
            return false;
        }

        if (fieldInstance.field.type === FieldType.DOB && fieldInstance.hasValue) {
            // calculateAge will set isValid and error on the instance if needed.
            // No need to check if the field is empty.
            // Validation will already have failed above if it is required but empty.
            if (this.calculateAge(fieldInstance) === false) {
                return false;
            }
        }

        fieldInstance.isValid = true;
        return true;
    },

    /**
     * @param {FieldGroup} fieldGroup
     *
     * @return {boolean}
     */
    validateFieldGroup(fieldGroup) {
        // Returns true if there are no invalid fields.
        return fieldGroup.instances.map((instance) => this.validateFieldInstance(instance))
            .filter((isValid) => !isValid)
            .length === 0;
    },

    /**
     * @param {FieldGroup[]} fieldGroups
     *
     * @return {boolean}
     */
    validateFieldGroups(fieldGroups) {
        // Returns true if there are no invalid groupd.
        return fieldGroups.map((fieldGroup) => this.validateFieldGroup(fieldGroup))
            .filter((isValid) => !isValid)
            .length === 0;
    },

    /**
     * @param {Cart} cart
     *
     * @return {boolean}
     */
    validateCartFields(cart) {
        let fieldGroups = getAllFieldGroupsFromOrder(cart);
        return this.validateFieldGroups(fieldGroups);
    },

    /**
     * Calculate the 'age' property for a date of birth field.
     * This will mark the instance is invalid if there is an error to do with the DOB but will not mark it as
     * valid if there is no error, because validation may have failed for a different reason and we don't want to
     * undo that.
     *
     * @param {FieldInstance} fieldInstance
     *
     * @return {boolean|null} Returns true if a valid date of birth was entered, false if invalid, null if empty.
     */
    calculateAge(fieldInstance) {
        fieldInstance.age = null;
        fieldInstance.ageError = null;

        if (!fieldInstance.hasValue) {
            // No value entered so don't try and convert it to a date.
            // During validation the check to ensure a required field is entered should take place before this.
            return null;
        }

        let date = moment(fieldInstance.value, 'DD-MM-YYYY');

        if (!date.isValid()) {
            fieldInstance.isValid = false;
            fieldInstance.error = 'Please enter a valid date.';
            fieldInstance.ageError = fieldInstance.error;
            return false;
        }

        let age = null;
        if (moment().diff(date, 'seconds') > 0) {
            // Date is valid and not in the future.
            age = moment().diff(date, 'years');
        }

        if (age == null || age >= 150) {
            fieldInstance.isValid = false;
            fieldInstance.error = 'Please enter a valid date of birth.';
            fieldInstance.ageError = fieldInstance.error;
            return false;
        }

        fieldInstance.age = age;
        return true;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldValidator;
}
