/**
 * @param $scope
 * @param {DigiTickets.ProductManager} ProductService
 * @param {CartService} cartService
 */
const ProductSearchCtrl = function (
    $scope,
    ProductService,
    cartService
) {
    $scope.cart = cartService;
    $scope.selected = undefined;
    $scope.products = [];

    ProductService.getProducts().then(function (products) {
        $scope.products = products;
        $scope.products.loaded = true;
    });

    /**
     * @param {DigiTickets.Product} item
     */
    $scope.handleSelection = function handleSelection(item) {
        $scope.selected = '';
        // addItem is inherited from parent controller, SellCtrl
        $scope.addItem(item, 1);
    };

    $scope.findMatchingProducts = function findMatchingProducts($viewValue) {
        let matches = [];
        for (let i = 0; i < $scope.products.length; i++) {
            if (
                $scope.products[i].name.toLowerCase().indexOf($viewValue.toLowerCase()) != -1
                        || $scope.products[i].code.toLowerCase().indexOf($viewValue.toLowerCase()) != -1
                        || $scope.products[i].barcode == $viewValue
            ) {
                matches.push($scope.products[i]);
            }
        }

        return matches;
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ProductSearchCtrl;
}
