const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const FieldResource = function ($resource, ApiRequestParams, AppConfig, FieldCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'fields/',
        ApiRequestParams.defaults(),
        {
            query: {
                cache: FieldCache,
                isArray: true,
                method: 'GET',
                params: Object.assign(
                    {
                        resolve: 'fieldoptions'
                    },
                    ApiRequestParams.defaultMappers()
                ),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'Field')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldResource;
}
