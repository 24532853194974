/**
 * Determine whether an order can be automatically redeemed, based on the company's auto-redemption profile.
 */
const AutoRedemptionStrategy = function () {
};

AutoRedemptionStrategy.prototype = {
    /**
     * @private
     * @param {DigiTickets.Order} order
     * @returns {boolean}
     */
    orderHasOnlyOneLine(order) {
        return order.items.length === 1;
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineHasQtyOfOne(orderLine) {
        return orderLine.qty === 1;
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineIsATicket(orderLine) {
        return orderLine.itemType === DigiTickets.ItemType.TICKET;
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineHasASession(orderLine) {
        return orderLine.session !== null;
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineIsNotAlreadyRedeemed(orderLine) {
        return orderLine.redeemed === 0;
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineIsWithinValidityPeriod(orderLine) {
        return !orderLine.isExpired();
    },

    /**
     * @private
     * @param {OrderLine} orderLine
     * @returns {boolean}
     */
    lineIsOpenTicket(orderLine) {
        return this.lineIsATicket(orderLine) && !this.lineHasASession(orderLine);
    },

    /**
     * @private
     * @param {Session} session
     * @param {number} minutes
     * @returns {boolean}
     */
    sessionStartsInNextNMinutes(session, minutes) {
        let now = new Date();
        let diffInMilliseconds = session.startTime - now;
        let diffInMinutes = (diffInMilliseconds / 1000) / 60;

        return diffInMinutes <= minutes;
    },

    /**
     * Find out if an order can be auto-redeemed.
     *
     * @param {DigiTickets.Order} order
     * @param {DigiTickets.AutoRedeemProfile} autoRedeemProfile
     *
     * @returns {boolean}
     */
    orderCanBeRedeemed(order, autoRedeemProfile) {
        let orderLine = order.items[0];

        return (
            this.orderHasOnlyOneLine(order)
            && this.lineHasQtyOfOne(orderLine)
            && this.lineIsATicket(orderLine)
            && this.lineIsNotAlreadyRedeemed(orderLine)
            && this.lineIsWithinValidityPeriod(orderLine)
            && (
                this.lineIsOpenTicket(orderLine)
                || (
                    this.lineHasASession(orderLine)
                    && this.sessionStartsInNextNMinutes(
                        orderLine.session,
                        autoRedeemProfile.maxMinutesBeforeEventStarts
                    )
                )
            )
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AutoRedemptionStrategy;
}
