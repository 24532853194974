/**
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param {TaxManager} TaxManager
 */
const OrderReceiptPrinter = function (
    ReceiptPrinterService,
    TaxManager
) {
    this.receiptPrinter = ReceiptPrinterService;
    this.taxManager = TaxManager;
};

OrderReceiptPrinter.prototype = {
    /**
     * @param {DigiTickets.Order} order
     * @param {OrderAdjustmentResponse} [orderAdjustmentResponse]
     * @param {OrderTaxSLLine} [slLine] slLine may be passed in if already generated.
     */
    printOrderReceipt(order, orderAdjustmentResponse, slLine) {
        // Create signature line for Greek tax.
        if (!slLine) {
            let greekTaxRegistration = this.taxManager.getGreekTaxRegistrationCode(order);
            if (greekTaxRegistration && order.receiptPrinted) {
                slLine = this.taxManager.createSLLineModelForReprint(order);
            }
        }

        return this.receiptPrinter.printOrder(
            order,
            {
                isDuplicate: !!order.receiptPrinted,
                orderAdjustmentResponse,
                slLine
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderReceiptPrinter;
}
