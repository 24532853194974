const PrivilegeNames = Object.freeze({
    ABANDON_PAYMENTS: 'abandon_payments',
    ACCESS_MANAGE: 'access_manage',
    ACCESS_REDEEM: 'access_redeem',
    ACCESS_REPORT: 'access_report',
    ACCESS_SELL: 'access_sell',
    ADD_CASH_TO_TILL: 'add_cash_to_till',
    ALLOW_UNREDEEM_MEMBERSHIPS: 'allow_unredeem_memberships',
    ALLOW_UNREDEEM_TICKETS: 'allow_unredeem_tickets',
    APPLY_DISCOUNTS: 'apply_discounts',
    APPLY_SUPERVISOR_DISCOUNTS: 'apply_supervisor_discounts',
    AUTH_ONLY_PAYMENTS: 'auth_only_payments',
    CHANGE_PRINT_SETTINGS: 'change_print_settings',
    CNP_PAYMENTS: 'cnp_payments',
    EDIT_ORDERS: 'edit_orders',
    ISSUE_REFUNDS: 'issue_refunds',
    OPEN_CASH_DRAWER: 'open_cash_drawer',
    REMOVE_CASH_FROM_TILL: 'remove_cash_from_till',
    REPORT: 'report'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrivilegeNames;
}
