const FloatAdjustmentReason = require('./FloatAdjustmentReason');
const UserFacingError = require('../libraries/DigiTickets/Errors/UserFacingError');

/**
 * @param {FloatAdjustmentReason} floatAdjustmentReason
 * @param amount
 * @param narrative
 */
const FloatAdjustment = function (floatAdjustmentReason, amount, narrative) {
    amount = parseFloat(amount);

    if (!(floatAdjustmentReason instanceof FloatAdjustmentReason)) {
        throw new Error('floatAdjustmentReason must be an instance of FloatAdjustmentReason');
    }

    if (isNaN(amount)) {
        throw new UserFacingError('Amount must be a number', 'FLOAT_ADJUSTMENT.AMOUNT_MUST_BE_NUMBER');
    }

    if (floatAdjustmentReason.narrative && (!narrative || narrative.trim() === '')) {
        throw new UserFacingError('Narrative is required', 'FLOAT_ADJUSTMENT.NARRATIVE_REQUIRED');
    }

    /**
     * @type {FloatAdjustmentReason}
     */
    this.floatAdjustmentReason = floatAdjustmentReason;

    /**
     * @type {?number}
     */
    this.amount = amount;

    /**
     * @type {?string}
     */
    this.narrative = narrative || null;
};

FloatAdjustment.prototype = {};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FloatAdjustment;
}
