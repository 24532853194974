const angular = require('angular');
const is = require('../Is');
const LZString = require('lz-string');
const { arrayCombine, arrayFlip } = require('../../functions/functions');
const { unflatten } = require('digitickets.flatten-js');

DigiTickets.LocalStorageAnalyser = function () {
    /**
     * The local storage capacity.
     *
     * @var {boolean|{Caches: number, Keys: number, Lengths: {Key: number, Stored: number, Original: number}, Limit: Number}}
     */
    this.capacity = false;

    /**
     * @var {DigiTickets.StorageImplementation}
     */
    this.storageImplementation = new DigiTickets.StorageImplementation();
};

DigiTickets.LocalStorageAnalyser.prototype = {
    /**
     * Clean the localStorage of orphaned data and keys.
     *
     * @param {boolean} withMeasure Measure data when cleaning. False when called from measure().
     * @returns {{Caches: number, Keys: number, Lengths: {Key: number, Stored: number, Original: number}}|null}
     */
    clean: function clean(withMeasure) {
        let self = this;

        // Measure the cache before we do anything if asked.
        if (withMeasure) {
            var beforeClean = this.measure();
        }

        // Get the cache in a more usable format.
        let angCache = unflatten(localStorage, '~', ['.', '.', '.', '.'], '`', true);

        // Iterate each of the angular-cache.caches.
        angular.forEach(angCache['angular-cache'].caches, function (cache, cacheKey) {
            // Get the keys for the current cache.
            let keys = angular.fromJson(self.storageImplementation.getItem('angular-cache.caches.' + cacheKey + '.keys'));

            // Check each key for each data is in the keys collection.
            angular.forEach(cache.data, function (cacheData, dataKey) {
                if (keys.indexOf(dataKey) === -1) {
                    // Remove the data from the cache as it is not indexed in the keys.
                    localStorage.removeItem('angular-cache.caches.' + cacheKey + '.data.' + dataKey);
                }
            });

            // Check each key to make sure there is an appropriate data.
            angular.forEach(keys, function (dataKey, key) {
                if (!localStorage.getItem('angular-cache.caches.' + cacheKey + '.data.' + dataKey)) {
                    // Remove the key from the collection of keys for this cache.
                    keys.splice(key, 1);
                }
            });

            // Save the keys back to the cache.
            self.storageImplementation.setItem('angular-cache.caches.' + cacheKey + '.keys', angular.toJson(keys));
        });

        // Measure the cache now that we've done all the processing if we were asked to do so.
        if (withMeasure) {
            let afterClean = this.measure();

            // Return a set of the differences.
            return {
                Caches: beforeClean.Caches - afterClean.Caches,
                Keys: beforeClean.Keys - afterClean.Keys,
                Lengths: {
                    Key: beforeClean.Lengths.Key - afterClean.Lengths.Key,
                    Stored: beforeClean.Lengths.Stored - afterClean.Lengths.Stored,
                    Original: beforeClean.Lengths.Original - afterClean.Lengths.Original
                }
            };
        }
    },

    /**
     * Dump the localStorage.
     *
     * @param {{}} cacheSizes A container to return the sizes of the various elements found during the dump.
     * @returns {object}
     */
    dump: function dump(cacheSizes) {
        /**
         * Get the localStorage as a nested structure.
         *
         * As we are using angular-cache, the first 4 levels result in ...
         *
         * angular-cache
         *   .caches
         *     .<key>
         *       .data
         *         .key1
         *         .key2
         *         ...
         *       .keys[key1, key2, ...]
         *
         * and
         *
         * DB
         *   .table
         *     .data
         *       .key
         *     .schema
         *     .index
         *       .index
         *
         * We only need to nest up to .data/.keys, so a junk alternative separator is supplied to the unflatten() function to stop further unflattening.
         */
        let self = this;
        let nestedLocalStorage = unflatten(localStorage, '~', ['.', '.', '.', '.'], '`', true);
        let caches = {};
        cacheSizes = cacheSizes || {};

        if (is.anObject(nestedLocalStorage) && nestedLocalStorage.hasOwnProperty('angular-cache')) {
            angular.extend(caches, nestedLocalStorage['angular-cache'].caches);

            // Process each cache (angular-cache.caches.<cache>)
            angular.forEach(caches, function (cache, cacheKey) {
                cacheSizes[cacheKey] = {
                    keys: {}
                };

                // Get the keys for the data - these are fully denormalised keys - angular-cache.caches.<cache>.data
                let cacheKeys = angular.fromJson(
                    self.storageImplementation.getItem('angular-cache.caches.' + cacheKey + '.keys', cacheSizes[cacheKey].keys)
                );

                // Process each key for this cache.
                angular.forEach(cacheKeys, function (key) {
                    cacheSizes[cacheKey][key] = {};

                    // Get the data for the key from the cache and de-JSON it.
                    let data = angular.fromJson(
                        self.storageImplementation.getItem('angular-cache.caches.' + cacheKey + '.data.' + key, cacheSizes[cacheKey][key])
                    );

                    if (is.anObject(data)) {
                        if (data.hasOwnProperty('key')) {
                            try {
                                delete data.key;
                            } catch (err) {
                            }
                        }
                        if (data.hasOwnProperty('accessed')) {
                            data.accessed = new Date(data.accessed);
                        }
                        if (data.hasOwnProperty('created')) {
                            data.created = new Date(data.created);
                        }
                    }
                    cache.data[key] = data;
                });
                cache.keys = cacheKeys;
            });

            if (nestedLocalStorage.hasOwnProperty('DB')) {
                caches.DB = nestedLocalStorage.DB;

                // Process each table in each DB cache.
                angular.forEach(caches.DB, function (cache) {
                    angular.forEach(cache, function (table, tableIndex) {
                        switch (tableIndex) {
                            case 'aliases':
                                cache[tableIndex] = JSON.parse(LZString.decompressFromUTF16(table));
                                break;
                            case 'indexes':
                                angular.forEach(table, function (indexPage, indexPageNumber) {
                                    cache[tableIndex][indexPageNumber] = JSON.parse(LZString.decompressFromUTF16(indexPage));
                                });
                                break;
                            default:
                                if (table.hasOwnProperty('columns')) {
                                    table.columns = JSON.parse(LZString.decompressFromUTF16(table.columns));
                                }
                                angular.forEach(table, function (row, index) {
                                    if (index !== 'columns') {
                                        table[index] = arrayCombine(table.columns, JSON.parse(LZString.decompressFromUTF16(row)));
                                    }
                                });
                        }
                    });
                });
            }

            delete nestedLocalStorage['angular-cache'];
        }

        // Do we have any non angular-cache entries? (map/data, mapLZ,data).
        if (Object.keys(nestedLocalStorage).length !== 0) {
            angular.extend(caches, nestedLocalStorage);
            angular.forEach(nestedLocalStorage, function (value, key) {
                let map = {};
                cacheSizes[key] = {
                    keys: {
                        key: 0,
                        original: 0,
                        stored: 0,
                        uncompressed: 0
                    }
                };

                if (Object.keys(value).sort().join(',') === 'data,map') {
                    cacheSizes[key].keys.key += key.length + 4;
                    cacheSizes[key].keys.stored += value.map.length;
                    cacheSizes[key].keys.uncompressed += value.map.length;
                    cacheSizes[key].keys.original += value.map.length;
                    value.map = angular.fromJson(value.map);
                }
                if (Object.keys(value).sort().join(',') === 'data,mapLZ') {
                    cacheSizes[key].keys.key += key.length + 6;
                    cacheSizes[key].keys.stored += value.mapLZ.length;
                    value.map = LZString.decompressFromUTF16(value.mapLZ);
                    cacheSizes[key].keys.uncompressed += value.map.length;
                    cacheSizes[key].keys.original += value.map.length;
                    delete value.mapLZ;
                    value.map = angular.fromJson(value.map);
                }
                map = value.map;

                angular.forEach(value.data, function (singleValue, singleKey) {
                    cacheSizes[key][singleKey] = {
                        key: key.length + singleKey.length + 6,
                        original: 0,
                        stored: singleValue.length,
                        uncompressed: 0
                    };

                    singleValue = LZString.decompressFromUTF16(singleValue);
                    cacheSizes[key][singleKey].uncompressed = singleValue.length;
                    singleValue = DigiTickets.KeyMapper.unmap({ data: angular.fromJson(singleValue), map });
                    cacheSizes[key][singleKey].original = angular.toJson(singleValue).length;
                    value.data[singleKey] = singleValue;
                });
            });
        }

        return caches;
    },

    /**
     * Measures the amount of memory used by localStorage.
     *
     * There are limits to the local storage and so we have to try and stay inside that limit.
     *
     * @returns {object}
     */
    measure: function measure() {
        let results = {
            ResourceCaches: {
                Data: 0,
                Keys: 0
            },
            DB: {
                Tables: 0,
                Rows: 0,
                IndexPages: 0,
                IndexEntries: 0,
                Caches: {}
            },
            Compression: 0, // The percentage compressed - higher is better.
            NumberOfKeys: 0, // The number of keys in the caches.
            Lengths: { // The values of the keys and the original and stored lengths.
                Keys: 0,
                CompressedData: 0,
                UncompressedData: 0
            }
        };
        let lsKey = '';
        let lsKeyParts = [];
        let lsContent = '';
        let aliases = {};

        // Clean the cache before we start any work.
        this.clean(false);

        // Get the number of keys actually stored.
        results.NumberOfKeys = Object.keys(localStorage).length;

        // Iterate all the keys and determine their type/composition/content.
        for (let key = 0; key < results.NumberOfKeys; ++key) {
            // Get the key and data, and store the current lengths.
            lsKey = localStorage.key(key);
            lsContent = localStorage[lsKey];
            results.Lengths.Keys += lsKey.length;
            results.Lengths.CompressedData += lsContent.length;

            lsKeyParts = lsKey.split('.');

            // Process the content to expand it to its highest (original) form.
            // Also accumulate any specific counts based upon the type of key.
            switch (lsKeyParts[0]) {
                case 'angular-cache':
                    lsContent = JSON.parse(lsContent);

                    if (lsContent.hasOwnProperty('LZ')) {
                        lsContent = LZString.decompressFromUTF16(lsContent.LZ);
                    }

                    if (typeof lsContent === 'string') {
                        lsContent = JSON.parse(lsContent);
                        if (typeof lsContent === 'object' && lsContent.hasOwnProperty('map')) {
                            lsContent = DigiTickets.KeyMapper.unmap(lsContent);
                        }
                    }

                    if (typeof lsContent !== 'string') {
                        lsContent = JSON.stringify(lsContent);
                    }

                    switch (lsKeyParts[3]) {
                        case 'data':
                            ++results.ResourceCaches.Data;
                            break;
                        case 'keys':
                            ++results.ResourceCaches.Keys;
                            break;
                    }
                    break;
                case 'DB':
                    lsContent = LZString.decompressFromUTF16(lsContent);
                    switch (lsKeyParts[2]) {
                        case 'aliases':
                            aliases[lsKeyParts[1]] = arrayFlip(JSON.parse(lsContent));
                            break;
                        case 'indexes':
                            ++results.DB.IndexPages;
                            angular.forEach(JSON.parse(lsContent), function (indexPage) {
                                results.DB.IndexEntries += Object.keys(indexPage).length;
                            });
                            break;
                        default:
                            switch (lsKeyParts[3]) {
                                case 'columns':
                                    ++results.DB.Tables;
                                    break;
                                default:
                                    ++results.DB.Rows;
                                    if (aliases && aliases.hasOwnProperty(lsKeyParts[1]) && aliases[lsKeyParts[1]].hasOwnProperty(lsKeyParts[2])) {
                                        let table = aliases[lsKeyParts[1]][lsKeyParts[2]];
                                        if (!results.DB.Caches.hasOwnProperty(table)) {
                                            results.DB.Caches[table] = 0;
                                        }
                                        ++results.DB.Caches[table];
                                        break;
                                    }
                            }
                    }
                    break;
            }
            results.Lengths.UncompressedData += lsContent.length;
        }

        // Calculate the compression percentage.
        results.Compression = Math.round(100 * (100 - (100 * (results.Lengths.Keys + results.Lengths.CompressedData)) / (results.Lengths.Keys + results.Lengths.UncompressedData))) / 100;

        return results;
    },

    /**
     * Test the localStorage limits.
     *
     * @return {boolean|{object}}
     */
    testCapacity: function testCapacity() {
        // Only calculate the capacity if not already calculated.
        if (!is.anObject(this.capacity)) {
            // Mark the capacity as being tested.
            this.capacity = true;

            /**
             * Measure the cache before we do anything.
             *
             * @type {{Caches: number, Keys: number, Lengths: {Key: number, Stored: number, Original: number}}}
             */
            let beforeTest = this.measure();

            /**
             * Add data to local storage in this size.
             *
             * @type {number}
             */
            let chunkSize = 1000000;

            /**
             * The dummyData.
             *
             * @type {string}
             */
            let dummyData = '';

            // Remove our test item if it exists. It shouldn't.
            localStorage.removeItem('_');

            // Keep testing until the chunkSize is zero (meaning no more chunks to test).
            while (chunkSize > 0) {
                // As the test will throw an exception eventually, run this in a try/catch.
                try {
                    // Repeat the test for the current chunkSize until there is an exception.
                    while (1) {
                        // Increase the dummyData length by chunkSize
                        dummyData += '.'.repeat(chunkSize);

                        // Store (or at least try to) the dummyData.
                        localStorage.setItem('_', dummyData);
                    }
                }
                // Failed at this chunkSize.
                catch (err) {
                    // Remove the last chunk added to the dummyData.
                    dummyData = dummyData.slice(chunkSize);

                    // Reduce the chunkSize by a factor of 10.
                    chunkSize = Math.max(0, Math.floor(chunkSize / 10));
                }
            }

            // Remove our test item as we have finished with it.
            localStorage.removeItem('_');

            /**
             * Measure the cache now that we've done all the processing.
             *
             * @type {{Caches: number, Keys: number, Lengths: {Key: number, Stored: number, Original: number}}}
             */
            let afterTest = this.measure();

            // Return a set of the differences.
            let results = {
                Caches: beforeTest.Caches - afterTest.Caches,
                Keys: beforeTest.Keys - afterTest.Keys,
                Lengths: {
                    Key: beforeTest.Lengths.Key - afterTest.Lengths.Key,
                    Stored: beforeTest.Lengths.Stored - afterTest.Lengths.Stored,
                    Original: beforeTest.Lengths.Original - afterTest.Lengths.Original
                },
                Limit: dummyData.length
            };

            // Tag the result to see if the test didn't alter the overall store.
            results.result = results.Caches === 0
                    && results.Keys === 0
                    && results.Lengths.Key === 0
                    && results.Lengths.Stored === 0
                    && results.Lengths.Original === 0
                ? 'OK'
                : 'FAILED';

            this.capacity = results;
        }

        return this.capacity;
    }
};
