/**
 * Holds the current state of paying for an order.
 * This means things like can payment be cancelled at this point, and a message to show on success.
 */
const PaymentState = function () {
    /**
     * Can the payment be cancelled?
     *
     * @type {?boolean}
     */
    this.cancelable = null;

    /**
     * Which Payment Method decided it could or could not be cancelled?
     *
     * @type {?string}
     */
    this.cancelableSetByPaymentMethodRef = null;

    /**
     * @type {?string}
     */
    this.successMessage = null;
};

PaymentState.prototype = {
    /**
     * @param {boolean} cancelable
     * @param {string} setByPaymentMethodRef
     */
    setCancelable(cancelable, setByPaymentMethodRef) {
        this.cancelable = cancelable;
        this.cancelableSetByPaymentMethodRef = setByPaymentMethodRef;
    },

    clearCancelable() {
        this.cancelable = null;
        this.cancelableSetByPaymentMethodRef = null;
    },

    /**
     * Set some text that should be displayed on the change modal, for cases when something should be
     * shown to the operator but the payment modal has already been automatically dismissed.
     *
     * @param {string} text
     */
    setSuccessMessage(text) {
        this.successMessage = text;
    },

    /**
     * Return the success message string and clear it.
     *
     * @return {?string}
     */
    pullSuccessMessage() {
        let message = this.successMessage;
        this.clearSuccessMessage();
        return message;
    },

    clearSuccessMessage: function clearSuccessMessage() {
        this.successMessage = null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentState;
}
