const PromptModalCtrl = function PromptModalCtrl(
    $filter,
    $modalInstance,
    $scope,
    message,
    Notification,
    options
) {
    if (!options) {
        options = {};
    }
    $scope.options = options;

    $scope.form = {
        value: options.hasOwnProperty('defaultValue') ? options.defaultValue : ''
    };

    $scope.cancelLabel = options.hasOwnProperty('cancelLabel') ? options.cancelLabel : 'CANCEL';
    $scope.cancelLabelParams = options.hasOwnProperty('cancelLabelParams') ? options.cancelLabelParams : {};

    $scope.okLabel = options.hasOwnProperty('okLabel') ? options.okLabel : 'OK';
    $scope.okLabelParams = options.hasOwnProperty('okLabelParams') ? options.okLabelParams : {};

    $scope.inputLabel = options.hasOwnProperty('inputLabel') ? options.inputLabel : '';
    $scope.inputLabelParams = options.hasOwnProperty('inputLabelParams') ? options.inputLabelParams : {};

    $scope.title = options.hasOwnProperty('title') ? options.title : 'DIALOGS.PROMPT_TITLE';
    $scope.titleParams = options.hasOwnProperty('titleParams') ? options.titleParams : {};

    if ($scope.options.rawMessage) {
        /**
         * Display a language file string as the message.
         */
        $scope.rawMessage = message;
    } else {
        /**
         * Display some raw HTML as the message.
         */
        $scope.message = message;
        $scope.messageParams = options.hasOwnProperty('messageParams') ? options.messageParams : {};
    }

    $scope.maxLength = options.hasOwnProperty('maxLength') ? options.maxLength : null;
    $scope.minLength = options.hasOwnProperty('minLength') ? options.minLength : null;
    $scope.regex = options.hasOwnProperty('regex') ? options.regex : 0;

    if (options.hasOwnProperty('onKeyUp')) {
        $scope.onKeyUp = function () {
            options.onKeyUp($scope);
        };
    } else {
        $scope.onKeyUp = function () {
        };
    }

    $scope.ok = function ok() {
        if ($scope.minLength && $scope.form.value.length < $scope.minLength) {
            Notification.error(
                $filter('lang')('DIALOGS.PROMPT_TOO_SHORT', { min: $scope.minLength })
            );
            return;
        }

        if ($scope.maxLength && $scope.form.value.length > $scope.maxLength) {
            Notification.error(
                $filter('lang')('DIALOGS.PROMPT_TOO_LONG', { max: $scope.maxLength })
            );
            return;
        }

        if ($scope.regex && !$scope.regex.test($scope.form.value)) {
            Notification.error(
                $filter('lang')('DIALOGS.PROMPT_INVALID_FORMAT')
            );
            return;
        }

        $modalInstance.close({
            value: $scope.form.value
        });
    };

    // Go to report page
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PromptModalCtrl;
}
