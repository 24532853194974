/**
 * FIXME: This should be moved out of the functions directory because it is DigiTickets specific.
 * 'functions' should only be for generic utilities.
 */

/**
 * @param {DigiTickets.Order} order
 *
 * @return {?OrderLine}
 */
const orderContainsGiftVouchers = (order) => order.items.find((line) => line.itemType === DigiTickets.ItemType.GIFT_VOUCHER);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        orderContainsGiftVouchers
    };
}
