DigiTickets.ItemType = Object.freeze({
    BUNDLE: 'Bundle',
    DELIVERY_METHOD: 'Delivery Method',
    GENERIC: 'Generic',
    GIFT_VOUCHER: 'Gift Voucher',
    MEMBERSHIP_PLAN: 'Membership Plan',
    ORDER_BALANCE: 'Order Balance',
    PRODUCT: 'Product',
    PRODUCT_FOOD: 'Food', // A special type used only on EPOS
    SURCHARGE: 'Surcharge',
    TICKET: 'Ticket'
});
