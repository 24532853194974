const BigNumber = require('bignumber.js');

/**
 * app.run logic common to all components.
 * This is designed to be called within a call to app.run()
 *
 * @param $rootScope
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.OnlineQueue} OnlineQueueService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 */
let runAppCommon = function runAppCommon(
    $rootScope,
    AppConfig,
    Logger,
    OnlineQueueService,
    TradingSessionManager
) {
    Logger.info('EPOS Version ' + AppConfig.eposCommit + ' ' + AppConfig.eposBranch + ' ' + AppConfig.eposVersion);

    BigNumber.set({ DECIMAL_PLACES: 2 });

    /**
     * Any additional stylesheets to go in head.
     * This is an array of URLs.
     *
     * On any page (epos/kitchen etc.) you wish to use custom stylesheets on remember to add something like this
     * in the head section:
     * <link ng-repeat="url in customStylesheets"
     *       rel="stylesheet"
     *       type="text/css"
     *       ng-href="{{ url }}" />
     * A directive to do that was tried but it does not work well inside <head>.
     *
     * @type {string[]}
     */
    $rootScope.customStylesheets = [];

    OnlineQueueService.init();

    // Hide the initial loading overlay.
    let initialLoader = document.getElementById('initial-loader');
    if (initialLoader) {
        initialLoader.parentNode.removeChild(initialLoader);
    }

    $rootScope.rootProcessingMessage = null;
    $rootScope.setRootProcessingMessage = function setRootProcessingMessage(value) {
        $rootScope.rootProcessingMessage = value || null;
    };

    // Add standard date & time formats to templates.
    // These are format strings for moment not for Angular's own date filter.
    // Use this like: {{ myDate | moment:dateWithTimeFormat }}
    $rootScope.dateFormat = 'ddd MMM Do YYYY';
    $rootScope.dateWithTimeFormat = 'ddd MMM Do YYYY h:mm A';
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = runAppCommon;
}
