/**
 * Contains the current state to be displayed on the customer facing screen.
 */
const CustomerScreenState = function () {
    /**
     * @type {?DigiTickets.Company}
     */
    this.company = null;

    /**
     * @type {?DigiTickets.Branch}
     */
    this.currentBranch = null;

    /**
     * @type {?User}
     */
    this.currentUser = null;

    /**
     * Which stage of the checkout flow are we at?
     *
     * @type {string}
     */
    this.stage = 'wait';

    this.cart = null;

    /**
     * @type {?number}
     */
    this.total = null;

    this.summary = null;

    this.paymentStatus = null;
};

CustomerScreenState.prototype = {
    /**
     * Replace the parameters given in the object.
     *
     * @param {object} data
     */
    update(data) {
        for (let key in data) {
            if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerScreenState;
}
