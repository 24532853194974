const angular = require('angular');
const moment = require('moment');
const { cloneDeep } = require('../../functions/clone');

/**
 * Formerly 'MembersAndAddressCtrl'
 *
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param action
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {ContactPhotoService} ContactPhotoService
 * @param CountryManager
 * @param currentMemberID
 * @param customerAccount
 * @param {LangService} LangService
 * @param membershipDatasets
 * @param membershipPlan
 * @param {MembershipValidator} membershipValidator
 * @param Notification
 * @param {UserService} UserService
 * @param {WebcamService} webcamService
 */
const MembershipDatasetsModalCtrl = function MembershipDatasetsModalCtrl(
    $filter,
    $modalInstance,
    $scope,
    action,
    AppConfig,
    ContactPhotoService,
    CountryManager,
    currentMemberID,
    customerAccount,
    LangService,
    membershipDatasets,
    membershipPlan,
    membershipValidator,
    Notification,
    UserService,
    webcamService
) {
    $scope.replacementMembershipRefHelpHtml = LangService.getText('MEMBERSHIPS.REPLACEMENT_MEMBERSHIP_REF_HELP');

    /**
     * Should be one of:
     * - 'add-new' When adding a new plan to the cart, or increasing the qty of a plan in the cart.
     * - 'edit-new' When the edit icon was clicked in the cart.
     * - 'edit-existing' When editing an existing membership.
     *
     * @type {string}
     */
    $scope.action = action;

    /**
     * @type {DigiTickets.Country[]}
     */
    $scope.countries = CountryManager.getCountries();

    /**
     * @type {DigiTickets.Company}
     */
    $scope.company = UserService.company;

    /**
     * Make these enums available in the template.
     */
    $scope.MembershipDobRequirement = DigiTickets.MembershipDobRequirement;
    $scope.MembershipCardAllocationType = DigiTickets.MembershipCardAllocationType;

    /**
     * @type {DigiTickets.MembershipPlan}
     */
    $scope.membershipPlan = membershipPlan;

    /**
     * @type {DigiTickets.MembershipDataset[]}
     */
    $scope.membershipDatasets = [];

    if (membershipDatasets) {
        // Use the passed in datasets if any.
        for (let d = 0; d < membershipDatasets.length; d++) {
            // Clone it so we don't modify the passed in objects
            // (the modified version are returned when clicking OK)
            let clonedDataset = cloneDeep(membershipDatasets[d]);

            if ($scope.action === 'edit-existing') {
                // When editing an existing membership the isChild flag won't be set on contacts as it does not
                // correspond to a DB field.
                // If the membership plan has an adult/child age cutoff (getMaxAge() and maxAgeIsForChildOnly())
                // set the isChild flag on the member contacts as appropriate.
                if (clonedDataset.membershipPlan.getMaxAge() && clonedDataset.membershipPlan.maxAgeIsForChildOnly()) {
                    for (let m = 0; m < clonedDataset.members.length; m++) {
                        let member = clonedDataset.members[m];
                        if (member.contact) {
                            let underAge = membershipValidator.contactIsUnderAgeOnDate(
                                member.contact,
                                clonedDataset.membershipPlan.getMaxAge(),
                                clonedDataset.getDateFrom()
                            );
                            member.contact.isChild = !!underAge;
                        }
                    }
                }
            }

            clonedDataset.maximumPeople = clonedDataset.membershipPlan.people;

            $scope.membershipDatasets.push(clonedDataset);
        }
    }

    /**
     * The customer account currently selected in the cart.
     *
     * @type {DigiTickets.CustomerAccount|null}
     */
    $scope.customerAccount = customerAccount;

    /**
     * Build a list of existing addresses that can be selected for memberships.
     *
     * @type {{id: number, value: string}}
     */
    $scope.addressOptions = [];
    $scope.canEditAddress = true;
    if ($scope.customerAccount) {
        for (let addressIndex = 0; addressIndex < $scope.customerAccount.addresses.length; addressIndex++) {
            let address = $scope.customerAccount.addresses[addressIndex];
            let value = address.getFormattedAddress();

            $scope.addressOptions.push(
                {
                    id: address.ID,
                    value
                }
            );
        }
    }

    /**
     * Retrieve an address object from the customer account by addressID.
     *
     * @param {number} addressID
     *
     * @return {DigiTickets.Address|null}
     */
    $scope.getAddressByID = function getAddressByID(addressID) {
        for (let i = 0; i < $scope.customerAccount.addresses.length; i++) {
            let address = $scope.customerAccount.addresses[i];
            if (address.ID === addressID) {
                return address;
            }
        }

        return null;
    };

    /**
     * Called when changing the Address dropdown. The passed in dataset object has an addressID property
     * that contains the new value.
     * We set the dataset.address property here instead of just binding it to ng-model on the dropdown because
     * changes to this address object should not affect the original address object in the customer account (for
     * the time being).
     *
     * Because of how the API saves addresses we're not concerned with the addressID past this point. The API
     * will find an existing address by matching its components (street, city, etc.) against an existing saved
     * address and use the same ID automatically.
     *
     * @param {DigiTickets.MembershipDataset} dataset
     */
    $scope.selectedExistingAddress = function selectedExistingAddress(dataset) {
        if (dataset.addressID) {
            let address = $scope.getAddressByID(dataset.addressID);
            dataset.address = angular.extend(new DigiTickets.Address(), address);
        } else {
            dataset.address = new DigiTickets.Address();
            dataset.address.country = $scope.company.country;
        }
    };

    /**
     * Existing contacts that can be selected for members.
     *
     * @type {DigiTickets.Contact[]}
     */
    $scope.contacts = [];

    /**
     * @type {{id: number, value: string}}
     */
    $scope.contactOptions = [];
    if ($scope.customerAccount) {
        $scope.contacts = $scope.customerAccount.contacts;
        for (let contactIndex = 0; contactIndex < $scope.customerAccount.contacts.length; contactIndex++) {
            let contact = $scope.customerAccount.contacts[contactIndex];
            let contactName = contact.getFullName();

            if (contact.title && $scope.company.shouldCaptureTitles()) {
                contactName = contact.title + ' ' + contactName;
            }

            if (contact.ref) {
                contactName += ' (' + contact.ref + ')';
            }

            $scope.contactOptions.push(
                {
                    id: contact.ID,
                    value: contactName
                }
            );
        }
    }

    /**
     * Get contacts that can be selected for the current member.
     *
     * @param membershipDataset
     */
    $scope.getAvailableContactOptions = function getAvailableContactOptions(membershipDataset) {
        if (!membershipDataset.currentMember) {
            return $scope.contactOptions;
        }

        return $scope.contactOptions.filter(
            function (option) {
                for (let i = 0; i < membershipDataset.members.length; i++) {
                    if (membershipDataset.members[i] !== membershipDataset.currentMember && membershipDataset.members[i].contactID === option.id) {
                        return false;
                    }
                }

                return true;
            }
        );
    };

    /**
     * @param {number} contactID
     *
     * @return {DigiTickets.Contact|null}
     */
    $scope.getContactByID = function getContactByID(contactID) {
        for (let i = 0; i < $scope.contacts.length; i++) {
            let contact = $scope.contacts[i];
            if (contact.ID === contactID) {
                return contact;
            }
        }

        return null;
    };

    /**
     * Called when changing the 'Select A Contact' option for a member.
     *
     * @param {DigiTickets.MembershipDataset} dataset
     */
    $scope.selectedExistingContact = function selectedExistingContact(dataset) {
        if (dataset.currentContact && dataset.currentContact.ID === dataset.currentContactID) {
            // Value has not changed.
            return;
        }

        // Set the currentContact in the dataset.
        // This is set instead of the member's contact property because we only want the changes applied
        // to the member when the operator presses the save button.
        if (dataset.currentContactID) {
            // Selected an existing new contact
            let contact = $scope.getContactByID(dataset.currentContactID);
            dataset.currentContact = contact ? angular.extend(new DigiTickets.Contact(), contact) : null;
        } else {
            // Selected 'New Contact'
            dataset.currentContact = new DigiTickets.Contact();
        }
    };

    $scope.contactPhotoService = ContactPhotoService;
    $scope.contactPhotoService.unsetSaveAllowed(); // This controller doesn't allow saving of photos individually.

    $scope.webcamService = webcamService;
    $scope.videoCaptureIsSupported = webcamService.isSupported();

    /**
     * Datepickers
     */

    $scope.datepickerFormat = 'dd/MM/yyyy';

    $scope.datepickerOptions = {
        yearFormat: 'yy'
    };

    $scope.minMembershipStartDate = $scope.membershipPlan ? $scope.membershipPlan.getEarliestAllowedStartDate() : null;
    $scope.maxMembershipStartDate = $scope.membershipPlan ? $scope.membershipPlan.getLatestAllowedStartDate() : null;

    $scope.membershipDateFrom = {
        opened: false
    };

    $scope.openMembershipDateFromPicker = function openMembershipDateFromPicker($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.membershipDateFrom.opened = true;
    };

    $scope.clearMembershipDateFrom = function clearMembershipDateFrom($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.membershipDateFrom.opened = false;
        let index = $scope.getActiveTabIndex();
        $scope.membershipDatasets[index].membershipDateFrom = '';
    };

    let activeTab = 0;
    if ($scope.action === 'add-new') {
        // Set the last tab to active.
        activeTab = $scope.membershipDatasets.length - 1;
    }

    // Make sure only 1 tab is active, and moreover, that the *correct* tab is active.
    for (let datasetIndex in $scope.membershipDatasets) {
        if ($scope.membershipDatasets.hasOwnProperty(datasetIndex)) {
            let membershipDataset = $scope.membershipDatasets[datasetIndex];

            // Set the member indexes
            for (let i = 0; i < membershipDataset.members.length; i++) {
                membershipDataset.members[i].index = i;
            }

            // This breaks if you change it to strict comparison, because object keys (datasetIndex) are always strings.
            // https://stackoverflow.com/a/3633390
            membershipDataset.isActive = (datasetIndex == activeTab);

            if ($scope.action === 'add-new' && membershipDataset.isActive) {
                // When adding a new dataset, select the primary member by default.
                // Add 1 member by default and select them.
                $scope.membershipDatasets[activeTab].selectMemberAtIndex(0);
            } else {
                // Clear all other selected members.
                membershipDataset.selectMember(null);
            }

            if (membershipDataset.hasOwnProperty('address')) {
                // Select the company's country by default for the address.
                // Unless this was an existing address that has been selected. We don't want to automatically
                // modify anything about that.
                if (!membershipDataset.address.country && !membershipDataset.address.ID && $scope.company.country) {
                    membershipDataset.address.country = $scope.company.country;
                }
            }
        }
    }

    $scope.toggleDateOfBirthPicker = function toggleDateOfBirthPicker($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.dobDatePickerState.opened = !$scope.dobDatePickerState.opened;
    };
    $scope.clearDateOfBirth = function clearDateOfBirth($event, contact) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.dobDatePickerState.opened = false;
        contact.dateOfBirth = null;
    };

    $scope.ok = function ok() {
        // Perform the 'save member' action for the current member for the current dataset if members tab is displayed.
        // Sometimes you forget to click 'save member' and go straight for the 'OK' button on modal.
        let activeDataset = $scope.getActiveDataset();
        if (activeDataset) {
            if (activeDataset.activeDeck === 1) {
                // On 'members' tab
                if (activeDataset.currentMember && activeDataset.currentContact) {
                    activeDataset.saveCurrentMember(false);
                }
            }
        }

        // Remove all empty contacts from members
        $scope.cleanMembers($scope.membershipDatasets);

        // Before we do anything, validate all the entries.
        let validationResult = membershipValidator.validateDatasets(
            $scope.getActiveTabIndex(),
            $scope.membershipDatasets
        );
        if (validationResult.errorMessage !== null) {
            if (validationResult.onTabIndex !== null) {
                $scope.setActiveTabIndex(validationResult.onTabIndex);
            }
            Notification.error(validationResult.errorMessage);
            return;
        }

        $modalInstance.close({
            membershipDatasets: $scope.membershipDatasets
        });
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * Method to return the index of the active tab.
     *
     * @returns int|null
     */
    $scope.getActiveTabIndex = function getActiveTabIndex() {
        for (let tabIndex = 0; tabIndex < $scope.membershipDatasets.length; tabIndex++) {
            if ($scope.membershipDatasets[tabIndex].isActive) {
                return tabIndex;
            }
        }

        return 0; // If none of the datasets have been set as active (e.g. when editing a cart item) use the first.
    };

    /**
     * @return {DigiTickets.MembershipDataset|null}
     */
    $scope.getActiveDataset = function getActiveDataset() {
        let activeIndex = $scope.getActiveTabIndex();
        if (activeIndex === null) {
            return null;
        }

        return $scope.membershipDatasets.hasOwnProperty(activeIndex) ? $scope.membershipDatasets[activeIndex] : null;
    };

    /**
     * Method to set the currently active tab to the one with the index that's passed in.
     *
     * @param newIndex int
     * @returns {null}
     */
    $scope.setActiveTabIndex = function setActiveTabIndex(newIndex) {
        newIndex = parseInt(newIndex);
        if (newIndex < 0 || newIndex >= $scope.membershipDatasets.length) {
            return null; // New index is out of scope, so don't do anything.
        }

        // Set all the tabs to inactive, then set the desired one to active.
        for (let tabIndex = 0; tabIndex < $scope.membershipDatasets.length; tabIndex++) {
            $scope.membershipDatasets[tabIndex].isActive = false;
        }
        $scope.membershipDatasets[newIndex].isActive = true;
    };

    $scope.setMemberDobDateRange = function setMemberDobDateRange() {
        // Default to "no min limit".
        $scope.dobDatePickerState.minDateOfBirth = null;
        let index = $scope.getActiveTabIndex();
        let maxAge = $scope.membershipDatasets[index].membershipPlan.getMaxAge();
        if (maxAge) {
            // Membership has an age limit. DOB depends on that limit and the membership start date.
            // I made the executive decision that a membership can start before the member is born, so max DOB
            // is always "today".
            let membershipStartDate = $scope.membershipDatasets[index].membershipDateFrom;
            membershipStartDate = membershipStartDate ? moment(membershipStartDate) : moment();
            $scope.dobDatePickerState.minDateOfBirth = membershipStartDate.subtract(maxAge, 'years');
        }
    };

    // Initialise the flags/settings for the member DOB calendar pop-ups.
    $scope.dobDatePickerState = {
        maxDateOfBirth: moment(),
        opened: false
    };
    // The min/max DOB depends on the membership instance, so set it for the current instance (usually #0).
    $scope.setMemberDobDateRange();

    if ($scope.action === 'add-new' || $scope.action === 'edit-new') {
        // Watch the membership data for changes. If the start date of the "current" membership changes, we need
        // to change the allowed date range on the member DOB date picker. Also, if they switch to a different
        // membership instance, we need to re-evaluate the range.
        // We don't do it when editing an existing membership as it causes a lot of unnecessary processing.
        $scope.$watch(
            'membershipDatasets',
            $scope.setMemberDobDateRange,
            true
        );
    }

    /**
     * @param {DigiTickets.Contact} contact
     */
    $scope.validateEmail = function validateEmail(contact) {
        contact.emailIsValid = !!contact.email;
    };

    /**
     * @param {DigiTickets.MembershipDataset} membershipDataset
     */
    $scope.validateMembershipRef = function validateMembershipRef(membershipDataset) {
        let result = membershipValidator.validateMembershipRef(membershipDataset);

        if (result === true) {
            membershipDataset.assignedRefErrorMessage = null;
        } else {
            membershipDataset.assignedRefErrorMessage = result.formattedError;
        }
    };

    $scope.canEnterRefForMember = function canEnterRefForMember(membershipDataset, member, contact) {
        return membershipValidator.canEnterRefForMember(
            membershipDataset,
            member,
            contact
        );
    };

    /**
     * @param {DigiTickets.MembershipDataset} membershipDataset
     * @param {DigiTickets.Member} member
     * @param {DigiTickets.Contact} contact
     */
    $scope.validateContactRef = function validateContactRef(membershipDataset, member, contact) {
        let required = membershipValidator.requireRefForMember(membershipDataset, member, contact);
        let result = membershipValidator.validateContactRef(contact, required);

        if (result === true) {
            membershipDataset.currentContactRefErrorMessage = null;
        } else {
            membershipDataset.currentContactRefErrorMessage = result.formattedError;
        }
    };

    /**
     * Converts the user-entered membership ref and member ref to upper case when they
     * navigate out of the fields.
     *
     * @param {DigiTickets.MembershipDataset} membershipDataset
     */
    $scope.formatRefs = function formatRefs(membershipDataset) {
        membershipDataset.assignedMembershipRef = membershipDataset.assignedMembershipRef.toUpperCase();
        if (membershipDataset.currentContact && membershipDataset.currentContact.ref) {
            membershipDataset.currentContact.ref = membershipDataset.currentContact.ref.toUpperCase();
        }
    };

    /**
     * Cleanup all members in the given data sets.
     * Cleanup currently entails removing contacts from members where no contact info is entered.
     *
     * @param {DigiTickets.MembershipDataset[]} membershipDatasets
     */
    $scope.cleanMembers = function cleanMembers(membershipDatasets) {
        angular.forEach(
            membershipDatasets,
            /**
             * @param {DigiTickets.MembershipDataset} membershipDataset
             */
            function (membershipDataset) {
                for (let i = 0; i < membershipDataset.members.length; i++) {
                    let member = membershipDataset.members[i];
                    if (member.contact && !member.contact.hasData()) {
                        member.setContact(null);
                    }
                }
            }
        );
    };

    $scope.clearAddress = function clearAddress() {
        angular.forEach($scope.membershipDatasets, function (membershipInstance) {
            if (membershipInstance.address && typeof membershipInstance.address.clear === 'function') {
                membershipInstance.address.clear();
            }
        });

        $('#fieldAddressSearch').val('').focus();
    };

    /**
     * @param {DigiTickets.Member} member
     */
    $scope.editMember = function editMember(member) {
        let activeDataset = $scope.getActiveDataset();
        if (activeDataset) {
            activeDataset.selectMember(member);
            $scope.validateContactRef(activeDataset, activeDataset.currentMember, activeDataset.currentContact);
        }
    };

    /**
     * @param {DigiTickets.MembershipDataset} membershipDataset
     */
    $scope.isDobRequired = function isDobRequired(membershipDataset) {
        if (membershipDataset.membershipPlan.alwaysCaptureDob()) {
            return true;
        }

        if (membershipDataset.currentContact) {
            if (membershipDataset.membershipPlan.captureChildDobOnly() && membershipDataset.currentContact.isChild) {
                return true;
            }
        }

        return false;
    };

    /**
     * @return {DigiTickets.Member|null}
     */
    $scope.getSelectedMember = function getSelectedMember() {
        return $scope.getActiveDataset().currentMember;
    };

    // If a currentMemberID is given when opening the modal, go straight to the dataset that has that member
    // in it and select them.
    // This is only used for editing existing members so it doesn't matter that members in new memberships won't
    // have IDs yet.

    $scope.selectMemberByID = function selectMemberByID(memberID) {
        for (let tabIndex = 0; tabIndex < $scope.membershipDatasets.length; tabIndex++) {
            let dataset = $scope.membershipDatasets[tabIndex];
            for (let memberIndex = 0; memberIndex < dataset.members.length; memberIndex++) {
                let member = dataset.members[memberIndex];
                if (member.ID === memberID) {
                    $scope.setActiveTabIndex(tabIndex);
                    dataset.activeDeck = 1;
                    dataset.selectMember(member);
                    return;
                }
            }
        }
    };

    if (currentMemberID) {
        $scope.selectMemberByID(currentMemberID);
    }

    $scope.$on(
        'barcodeScanned',
        /**
         * @param event
         * @param {{code: string}} response
         */
        function (event, response) {
            if (!response.code) {
                return;
            }

            let activeDataset = $scope.getActiveDataset();
            if (activeDataset) {
                switch ($scope.company.membershipCardAllocation) {
                    case DigiTickets.MembershipCardAllocationType.PLAN:
                        if (activeDataset.activeDeck === 0) {
                            // Fill the barcode in the membership's reference.
                            activeDataset.assignedMembershipRef = response.code;
                        }
                        break;

                    case DigiTickets.MembershipCardAllocationType.MEMBER:
                        if (activeDataset.activeDeck === 1 && activeDataset.currentContact) {
                            // Fill the barcode in the selected member/contact's reference.
                            activeDataset.currentContact.ref = response.code;
                        }
                        break;

                    case DigiTickets.MembershipCardAllocationType.ADULTS:
                        if (activeDataset.activeDeck === 1 && activeDataset.currentContact) {
                            if ($scope.canEnterRefForMember(activeDataset, activeDataset.currentMember, activeDataset.currentContact)) {
                                activeDataset.currentContact.ref = response.code;
                            } else {
                                Notification.error(
                                    $filter('lang')('MEMBERS_AND_ADDRESS.CANNOT_ISSUE_CARD')
                                );
                            }
                        }
                        break;
                }

                $scope.validateContactRef(activeDataset, activeDataset.currentMember, activeDataset.currentContact);
                $scope.$apply();
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipDatasetsModalCtrl;
}
