const _ = require('lodash');
const BigNumber = require('bignumber.js');
const Cart = require('./Cart');
const ChangeCalculator = require('../Payment/ChangeCalculator');
const CustomerScreenStages = require('../../../libraries/DigiTickets/CustomerScreen/CustomerScreenStages');
const FieldGroupCollection = require('../CustomFields/FieldGroupCollection');
const moment = require('moment');
const OrderToKitchenOrderMapper = require('../Kitchen/OrderToKitchenOrderMapper');
const PaymentCalculator = require('../Payment/PaymentCalculator');
const PaymentMethodRef = require('../PaymentMethods/PaymentMethodRef');
const SalesTotals = require('../SalesTotals');
const { cloneDeep } = require('../../../functions/clone');
const { createCartItemGroupsFromItems } = require('./cartItemGroups');
const { getFieldValuesFromCart, setFieldValuesOnCart } = require('../CustomFields/fieldFunctions');
const { mapCartToCartService } = require('../Mappers/mapCartToCartService');
const { md5Hash } = require('../../../functions/hash');

/**
 * !!! NOTE !!!
 * Before adding any additional properties here think about if they can be added to the new Cart model instead.
 * Even though using a Cart model is not fully implemented, the currentCart property is already in use and will
 * always be a Cart instance. It is reset to a new instance when the clear button is pressed.
 * You can access those properties as cartService.currentCart.x
 * The currentCart get stashed and restored as-is so if you add properties to Cart you don't need to worry about
 * adding them to StashedCart/StashedCartItem as well.
 *
 * @param {CartLineNumberFactory} cartLineNumberFactory
 * @param {CartLineSplitter} cartLineSplitter
 * @param {CartToOrderMapper} cartToOrderMapper
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {ExternalCartCalculator} externalCartCalculator
 * @param {FieldInstanceFactory} fieldInstanceFactory
 * @param {FieldManager} fieldManager
 * @param {DigiTickets.OfferManager} OfferManager
 * @param {PaymentMethodService} paymentMethodService
 * @param {PaymentService} paymentService
 * @param {ReservationResource} ReservationResource
 * @param {ThirdPartyRefGenerator} thirdPartyRefGenerator
 */
const CartService = function (
    cartLineNumberFactory,
    cartLineSplitter,
    cartToOrderMapper,
    CustomerScreenDataService,
    externalCartCalculator,
    fieldInstanceFactory,
    fieldManager,
    OfferManager,
    paymentMethodService,
    paymentService,
    ReservationResource,
    thirdPartyRefGenerator
) {
    this.cartLineNumberFactory = cartLineNumberFactory;
    this.cartLineSplitter = cartLineSplitter;
    this.cartToOrderMapper = cartToOrderMapper;
    this.customerScreenData = CustomerScreenDataService;
    this.externalCartCalculator = externalCartCalculator;
    this.fieldInstanceFactory = fieldInstanceFactory;
    this.fieldManager = fieldManager;
    this.offerManager = OfferManager;
    this.paymentMethodService = paymentMethodService;
    this.paymentService = paymentService;
    this.reservationResource = ReservationResource;
    this.thirdPartyRefGenerator = thirdPartyRefGenerator;

    /**
     * A hash of the current contents of the cart.
     *
     * @see this.getContentHash()
     *
     * @type {null}
     */
    this.contentHash = null;

    /**
     * @type {?User}
     */
    this.user = null;

    /**
     * If the current cart came from a stashed cart and it had been sent to the kitchen this will be set.
     *
     * TODO: Move to Cart model.
     *
     * @type {?Date}
     */
    this.fulfillmentCreatedAt = null;

    /**
     * Any kitchen fulfillments related to this cart.
     *
     * @type {?Array}
     */
    this.fulfillments = [];

    /**
     * If the current cart came from a stashed cart and it had been sent to the kitchen this will be set
     * as a KitchenOrder representation of the cart at the point at which it was un-stashed.
     *
     * This is used if the cart is re-held so EPOS can print a kitchen receipt showing what has changed.
     * The kitchen screen also prints the changes but that is handled separately.
     *
     * TODO: Move to Cart model.
     *
     * @type {?KitchenOrder}
     */
    this.restoredKitchenOrder = null;

    /**
     * @type {?FieldGroupCollection}
     */
    this.fields = null;

    /**
     * Key value pairs of FieldInstance keys and values.
     * See FieldInstance.generateKey for the format of keys.
     *
     * @type {Object}
     */
    this.fieldData = {};

    /**
     * Flag to show if calculations are currently being run on the server.
     *
     * @type {boolean}
     */
    this.externalCalculationInProgress = false;

    this.calculator = new ChangeCalculator();

    /**
     * All available payment methods.
     *
     * @type {PaymentMethod[]}
     */
    this.availablePaymentMethods = [];

    /**
     * The currently selected payment method.
     *
     * @type {?PaymentMethod}
     */
    this.selectedPaymentMethod = null;

    /**
     * @type {SalesTotals}
     */
    this.totals = new SalesTotals();

    /**
     * When the contents of the cart changes (items, customer account, etc.) this callback will be fired.
     *
     * @type {function|null}
     */
    this.onChangeFunction = null;

    /**
     * @type {CartItemGroup[]}
     */
    this.cartItemGroups = [];

    /**
     * This currentCart model contains all the data about the Cart that is currently being worked on.
     * (Almost) all data about a cart is now stored in this model instead of here in the CartService.
     *
     * @type {Cart}
     */
    this.currentCart = new Cart();

    /**
     * Used if another screen wants to switch to the Sell screen and display a specific set of items.
     * Set this property and when SellCtrl loads up it switches the currentCart for this nextCart.
     *
     * This is intended to replace the 'SellStateService' and 'SellState' model.
     *
     * @type {?Cart}
     */
    this.nextCart = null;

    // Initialize with a clean setup.
    this.clear();
};

CartService.prototype = {

    /**
     * @param {User} user
     */
    setUser: function setUser(user) {
        this.user = user;

        this.availablePaymentMethods = this.paymentMethodService.getAll();
        if (this.selectedPaymentMethod === null) {
            this.selectedPaymentMethod = this.availablePaymentMethods[0];
        }

        this.currentCart.thirdPartyRef = this.thirdPartyRefGenerator.generateCartThirdPartyRef();
        this.currentCart.thirdPartyID = this.currentCart.thirdPartyRef;
    },

    /**
     * @param {DigiTickets.CartItem} newCartItem
     * @param {number|string} [lineNumber]
     *
     * @return {?DigiTickets.CartItem}
     */
    add: function (newCartItem, lineNumber) {
        if (this.currentCart.containsOrderBalanceItem()) {
            // Cannot add any additional items when paying the balance.
            return null;
        }

        // If this exact item (including the same editable status) is already in the cart just return that line.
        // Otherwise, if there are slight differences between the lines, a new line is created.
        let existingLine = this.currentCart.getLineById(newCartItem.getId());
        if (existingLine) {
            return existingLine;
        }

        // Did not find any existing item, add new one.
        // Use the given line number if available (likely from a stashed cart - needed to keep field data
        // relating to the right line).
        if (lineNumber !== undefined && !Number.isNaN(lineNumber)) {
            lineNumber = parseInt(lineNumber, 10);
            newCartItem.lineNumber = lineNumber;
            this.cartLineNumberFactory.setMin(lineNumber);
        } else {
            newCartItem.lineNumber = this.cartLineNumberFactory.next();
        }

        this.itemList.push(newCartItem);

        this.calculate();
        this.itemsChanged();

        return this.itemList.last();
    },

    /**
     * @param {boolean} [externalIfNecessary] If applicable should the cart be sent to the API to recalculate
     *  offers or price difference for editing orders. This should be set to false if calculate is called from any
     *  function that is called after an external calculation response, to prevent an infinite loop.
     */
    calculate(externalIfNecessary = true) {
        // Update the active CartItemDiscounts on the lines.
        // See the comment on the applyManualLineOffers method for more info.
        this.offerManager.applyManualLineOffers(this);

        this.totals.reset();
        for (let i = 0; i < this.itemList.length; i++) {
            let item = this.itemList[i];
            // Run calculate on the item but tell it not to calculate the cart, to prevent an infinite loop.
            item.calculate(false);
            if (item.quantity != 0) {
                let itemType = item.item.itemType;
                let saleType = item.quantity > 0 ? this.totals.SALE_TYPES.SALE : this.totals.SALE_TYPES.RETURN;
                this.totals.addToTotals(itemType, saleType, 'lines', 1);
                this.totals.addToTotals(itemType, saleType, 'qty', item.quantity);
                this.totals.addToTotals(itemType, saleType, 'baseTotal', item.baseTotal);
                this.totals.addToTotals(itemType, saleType, 'discount', item.discountAmount);
                this.totals.addToTotals(itemType, saleType, 'lineTotal', item.lineTotal);
            }
        }

        this.calculator.setAmountDue(this.getTotalDue());
        this.calculator.setAmountTendered(this.getTotalTendered());

        if (this.currentCart.isEditingOrder && this.currentCart.originalOrder) {
            let originalOrderTotal = new BigNumber(this.currentCart.originalOrder.total);
            let cartTotal = new BigNumber(this.getTotal());
            this.currentCart.originalOrderDifference = cartTotal.minus(originalOrderTotal).toNumber();
        }

        this.calculateChange();
        this.payments = this.paymentService.cleanPayments(this.payments);

        this.updateCustomerScreenCart();
    },

    /**
     * Call when the actual contents of the cart are changed
     * (items, sessions those items are for, and/or quantities).
     */
    itemsChanged: function itemsChanged() {
        this.cartItemGroups = createCartItemGroupsFromItems(this.itemList);

        let newContentHash = this.getContentHash();
        if (newContentHash !== this.contentHash) {
            this.customerScreenData.setStage(CustomerScreenStages.SELL, CustomerScreenStages.COMPLETE);
        }

        this.contentHash = newContentHash;
        this.reloadFields();
        this._triggerOnChange();
    },

    setManualOfferIDs: function setManualOfferIDs(offerIDs) {
        this.currentCart.selectedManualOfferIds = offerIDs;
        this.refreshExternalCalculations();
    },

    /**
     * @param {DigiTickets.Offer|null} offer
     * @param {DigiTickets.CartItem} line
     */
    setManualOfferForLine: function setManualOfferForLine(offer, line) {
        line.setManualOffer(offer);
        this.refreshExternalCalculations();
    },

    refreshExternalCalculations(force) {
        if (!this.debouncedRefreshExternalCalculations) {
            this.debouncedRefreshExternalCalculations = _.debounce(
                (resolve, reject, cart) => {
                    this.externalCartCalculator.recalculate(cart, [], force)
                        .then(resolve)
                        .catch(reject);
                },
                500
            );
        }
        return new Promise((resolve, reject) => {
            this.debouncedRefreshExternalCalculations(resolve, reject, this);
        });
    },

    /**
     * Remove all discounts from all the lines.
     */
    clearDiscounts: function clearDiscounts() {
        for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].clearDiscounts();
        }
        this.calculate(false);
    },

    /**
     * Remove discounts that are really cost adjustments created by payments like tax on sale Experience vouchers.
     */
    clearPaymentDiscounts: function clearPaymentDiscounts() {
        for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].clearPaymentDiscounts();
        }
    },

    calculateChange: function () {
        if (this.isRefund()) {
            return false;
        }

        // Payments can now be split over multiple payment lines. But we still need to keep track of change given.
        // Apply the change to any payments that support change (Cash, Flexi Voucher).

        let changeDue = this.calculator.getChange();
        let cashPayments = this.getPaymentsThatSupportChange();

        for (let i = 0; i < cashPayments.length; i++) {
            let payment = cashPayments[i];

            // This will also set the changeGiven to 0 where necessary.
            let changeAppliedToPayment = Math.min(changeDue, payment.tendered);
            payment.setChangeGiven(changeAppliedToPayment);

            changeDue -= changeAppliedToPayment;
        }

        if (changeDue > 0) {
            // If the operator has taken too much money by a non-cash payment method there will be more change
            // than we have taken in cash.
            // Create a new Cash payment method to hold this excess change.

            let cashPaymentMethod = this.getCashPaymentMethod();
            if (!cashPaymentMethod) {
                // TODO: How to handle this better?
                console.log('There is excess change but no cash payment method to apply it to.');
                return false;
            }

            let cashPayment = new DigiTickets.Payment();
            cashPayment.setChangeGiven(changeDue);
            cashPayment.setPaymentMethod(cashPaymentMethod);
            this.currentCart.payments.push(cashPayment);
        }

        this._triggerOnChange();
    },

    clear: function clear(options) {
        // Ensure payments are cleared first, to avoid unnecessary calculations when removing items.
        this.clearPayments();

        let defaults = {
            deleteReservation: true
        };

        for (let key in options) {
            if (!options.hasOwnProperty(key)) {
                continue;
            }

            defaults[key] = options[key];
        }

        if (defaults.deleteReservation) {
            this.deleteReservation();
        }

        for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].adjustQuantity(-1 * this.itemList[i].quantity);
        }
        this.calculator.clear();

        /**
         * @type {DigiTickets.CartItem[]}
         */
        this.itemList = [];
        this.cartItemGroups = [];
        this.fulfillmentCreatedAt = null;
        this.setSelectedPaymentMethod(null);
        this.calculator.clear();
        this.fields = null;
        this.fieldData = {};
        this.cartLineNumberFactory.reset();
        this.restoredKitchenOrder = null;

        let newCart = new Cart();
        newCart.thirdPartyRef = this.thirdPartyRefGenerator.generateCartThirdPartyRef();
        newCart.thirdPartyID = newCart.thirdPartyRef;

        this.currentCart = newCart;

        this.calculate();
        this.itemsChanged();

        this.customerScreenData.setStage(CustomerScreenStages.WAIT, CustomerScreenStages.COMPLETE);
    },

    clearPayments: function clearPayments() {
        this.currentCart.payments = [];
        this._triggerOnChange();
    },

    updateCustomerScreenCart: function () {
        let lastItem = this.getLastItem();

        this.customerScreenData.updateState((state) => {
            state.cart = {
                lastItem: lastItem ? {
                    name: lastItem.getName(),
                    price: lastItem.getPrice(),
                    quantity: lastItem.getQuantity()
                } : null,
                itemCount: this.itemList.length,
                totalQty: this.getTotalQty(),
                total: this.getTotalDue()
            };
        });
    },

    /**
     * Returns a clone of each of the lines in the cart, so they can be added to an order (for example).
     * So changes to those lines no longer affect the cart.
     *
     * Offer calculation is disable while this runs, as the clone() method creates new CartItems attached
     * to this cart. Those new items call cart.calculate() in their constructor. This is usually running as
     * the pay button is clicked, so we don't want to total to change at that point.
     *
     * @return {Array}
     */
    exportItems: function exportItems() {
        let exportedItems = [];

        for (let i = 0; i < this.itemList.length; i++) {
            exportedItems.push(this.itemList[i].clone());
        }

        return exportedItems;
    },

    /**
     * @param {DigiTickets.CartItem} item
     *
     * @returns {object}
     */
    getExtraDataForCartItemReservation: function getExtraDataForCartItemReservation(item) {
        let extraData = {};

        let discountNarrative = item.getVariableDiscountNarrative();

        if (discountNarrative) {
            extraData.discountNarrative = discountNarrative;
        }

        return extraData;
    },

    deleteReservation: function deleteReservation() {
        if (this.hasReservation()) {
            try {
                let self = this;
                this.reservationResource.delete(
                    {
                        id: this.currentCart.reservation.token
                    },
                    function (success) {
                        self.currentCart.reservation = null;
                    },
                    function () {
                    }
                );
            } catch (e) {
            }
            this.currentCart.reservation = null;
        }
    },

    /**
     * @returns {DigiTickets.CartItem[]}
     */
    getItems: function getItems() {
        return this.itemList;
    },

    /**
     * Returns an array of all offers (discounts) that have been applied to the items in the cart.
     * If the same offer was applied to multiple lines it will only be returned once (with the appropriate
     * quantity property)
     *
     * @returns {CartItemDiscount[]}
     */
    getDiscountSummary: function getDiscountSummary() {
        let discounts = {};
        let items = this.getItems();

        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            for (let d = 0; d < item.discounts.length; d++) {
                let discount = item.discounts[d];
                if (discount && discount.isActive()) {
                    if (!discounts.hasOwnProperty(discount.offerID)) {
                        discounts[discount.offerID] = _.clone(discount);
                        // Don't use the percentage as we may be discounting different items with the same
                        // offer, so it's hard and unnecessary to calculate.
                        discounts[discount.offerID].percentage = 0;
                    } else {
                        discounts[discount.offerID].qty += discount.qty;
                        discounts[discount.offerID].amount += discount.amount;
                    }
                    // For VariableDiscounts the offer amount is always 0 and the discount amount can be
                    // different for each usage.
                    if (discount.isVariable()) {
                        discounts[discount.offerID].amount += item.getDiscountAmount(discount);
                    }
                }
            }
        }
        return Object.values(discounts);
    },

    /**
     * Return a line from the cart by its line number.
     *
     * @param {number|string} lineNumber
     *
     * @returns {DigiTickets.CartItem|null}
     */
    getLineByNumber: function getLineByNumber(lineNumber) {
        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].lineNumber == lineNumber) {
                return this.itemList[i];
            }
        }

        return null;
    },

    getLastItem: function getLastItem() {
        return this.itemList.length > 0 ? this.itemList[this.itemList.length - 1] : null;
    },

    /**
     * Returns the total number of items in the cart (sum of the qty per line)
     */
    getTotalQty: function getTotalQty() {
        let qty = 0;
        for (let i = 0; i < this.itemList.length; i++) {
            qty += this.itemList[i].getQuantity();
        }
        return qty;
    },

    hasReservation: function hasReservation() {
        return this.currentCart.reservation && this.currentCart.reservation.token;
    },

    /**
     * FIXME: This is used for conflicting purposes.
     * If there is *anything* in the cart (including zero qty lines) the Clear button should be enabled.
     * But the Pay Now button should only be enabled if there are >=1 non-zero qty lines.
     *
     * @return {boolean}
     */
    isEmpty: function isEmpty() {
        return this.itemList.length === 0 && this.totals.getOrders() == 0;
    },

    /**
     * Remove a line from the cart.
     *
     * @param {DigiTickets.CartItem} itemToRemove
     */
    remove(itemToRemove) {
        let index = this.itemList.indexOf(itemToRemove);
        if (index === -1) {
            return;
        }

        // If the item we're removing is a ticket and is session-based, we need to
        // add the availability back to the session.
        if (itemToRemove.item.itemType === DigiTickets.ItemType.TICKET && itemToRemove.session !== null) {
            // Send a flag (removeFromCartIfNewQtyZero) to prevent loops.
            itemToRemove.adjustQuantity(-1 * itemToRemove.quantity, false);
        }

        this.removeItemAtIndex(index);
    },

    /**
     * @param {number} index
     */
    removeItemAtIndex(index) {
        this.itemList.splice(index, 1);

        // When the last item is removed from the cart...
        if (this.itemList.length < 1) {
            // Reset the GiftAid question
            this.currentCart.giftAid = null;
            this.currentCart.giftAidPrices = null;
        }

        this.calculate();
        this.itemsChanged();
    },

    /**
     * @return {boolean}
     */
    isBalancePaid() {
        return !!this.currentCart.balancePaid;
    },

    /**
     * @param {DigiTickets.Payment} payment
     */
    addPayment: function (payment) {
        this.currentCart.payments.push(payment);
        this.currentCart.payments = [...new Set(this.currentCart.payments)];
        this.calculate();
        this._triggerOnChange();
    },

    /**
     * @returns {?PaymentMethod}
     */
    getCashPaymentMethod: function getCashPaymentMethod() {
        for (let i = 0; i < this.availablePaymentMethods.length; i++) {
            if (this.availablePaymentMethods[i].ref === PaymentMethodRef.CASH) {
                return this.availablePaymentMethods[i];
            }
        }

        return null;
    },

    /**
     * Returns payments that support change (Cash, Flexi Voucher) sorting by Cash first then amount.
     * This is because in payment that contains both Cash and Flexi Vouchers change should be issued as Cash.
     *
     * @returns {Array.<DigiTickets.Payment>}
     */
    getPaymentsThatSupportChange: function () {
        let cashPayments = this.currentCart.payments.filter(function (p) {
            return p.paymentMethod.supportsChange;
        });

        return cashPayments.sort(function (a, b) {
            if (
                a.paymentMethod.ref === PaymentMethodRef.CASH
                && b.paymentMethod.ref !== PaymentMethodRef.CASH
            ) {
                return -1;
            }

            if (
                a.paymentMethod.ref !== PaymentMethodRef.CASH
                && b.paymentMethod.ref === PaymentMethodRef.CASH
            ) {
                return 1;
            }

            return a.tendered < b.tendered ? 1 : -1;
        });
    },

    /**
     * Get the total cost of the cart (after discounts).
     *
     * @return {number}
     */
    getTotal() {
        return this.totals.getLineTotal();
    },

    /**
     * Get the total cost of the cart (after discounts) taking into account any payments made for the original
     * order, but not any new payments made for this cart.
     *
     * @return {number}
     */
    getTotalDue() {
        if (this.currentCart && this.currentCart.customTotal !== null) {
            return this.currentCart.customTotal;
        }

        let totalDue = new BigNumber(this.getTotal());

        if (this.currentCart && this.currentCart.originalOrder) {
            totalDue = totalDue.minus(this.currentCart.originalOrder.getTotalPaid());
        }

        return totalDue.toNumber();
    },

    getTotalTendered: function () {
        return PaymentCalculator.getTotalTendered(this.payments);
    },

    /**
     * Sum of the 'tendered' from each payment on this order and the 'amount' from any payments on the original
     * order.
     *
     * @return {number}
     */
    getTotalPaid() {
        let total = new BigNumber(PaymentCalculator.getTotalTendered(this.payments));

        if (this.currentCart.originalOrder) {
            total = total.plus(new BigNumber(this.currentCart.originalOrder.getTotalPaid()));
        }

        return total.toNumber();
    },

    /**
     * @return {DigiTickets.Payment[]}
     */
    getPayments: function getPayments() {
        return this.currentCart.payments;
    },

    getPaymentCount: function () {
        return this.currentCart.payments.length;
    },

    getRemainingBalance: function () {
        let totalDue = new BigNumber(this.getTotalDue());
        let totalTendered = new BigNumber(this.getTotalTendered());

        let remainingBalance = totalDue.minus(totalTendered).toNumber();

        if (this.isRefund()) {
            return Math.min(0, remainingBalance);
        }

        return Math.max(0, remainingBalance);
    },

    isRefund: function isRefund() {
        return this.getTotalDue() < 0;
    },

    /**
     * Returns the max amount that can be paid by a method other than Cash.
     * Return the remaining balance plus any cash already paid (as the cash can be returned as change).
     *
     * Returns the total amount payable if it is lower. This shouldn't happen, as the payment modal will submit
     * automatically once enough is received. But just in case.
     */
    getRemainingMaxPayment: function () {
        if (this.isRefund()) {
            return this.getRemainingBalance();
        }

        let remainingBalance = new BigNumber(this.getRemainingBalance());
        let totalCashTendered = new BigNumber(this.getTotalTendered());
        let balancePlusCash = remainingBalance.plus(totalCashTendered).toNumber();

        return Math.min(balancePlusCash, this.getTotalDue());
    },

    /**
     * @param {?PaymentMethod} paymentMethod
     */
    setSelectedPaymentMethod: function (paymentMethod) {
        this.selectedPaymentMethod = paymentMethod;
    },

    /**
     * @return {?PaymentMethod}
     */
    getSelectedPaymentMethod: function () {
        return this.selectedPaymentMethod;
    },

    /**
     * @param {Reservation} reservation
     */
    setReservation(reservation) {
        this.currentCart.reservation = reservation;
        this._triggerOnChange();
    },

    /**
     * If some customer info has been entered, but a country has not been, and we have a default country for
     * the company, set the customer's country to the default.
     */
    setDefaultCustomerCountry: function setDefaultCustomerCountry() {
        if (!this.customer.address.country && this.user.company.country && this.customer.hasData()) {
            this.customer.address.country = this.user.company.country;
        }
    },

    /**
     * @return {DigiTickets.Order}
     */
    toOrder() {
        const order = this.cartToOrderMapper.map(this.currentCart);

        // Add the missing things that CartToOrderMapper does not handle yet.
        order.fulfillmentCreatedAt = this.fulfillmentCreatedAt;
        order.fulfillments = this.fulfillments;
        order.total = this.getTotal();

        return order;
    },

    /**
     * Produce a hash of the following properties of the cart:
     * - Items
     * - Sessions
     * - Line Quantities
     * - Line Prices
     *
     * Add anything else that changes what is considered "what items are being purchased and at what price".
     * This is currently used for calculating offers. So add anything else that could change the outcome
     * of offer calculation.
     */
    getContentHash: function getContentHash() {
        let object = this.getContentObject();
        return md5Hash(JSON.stringify(object));
    },

    getContentObject: function getContentObject() {
        let result = {
            lines: [],
            membershipID: this.customer.membership ? this.customer.membership.ID : null,
            selectedManualOfferIds: this.currentCart.selectedManualOfferIds
        };
        for (let i = 0; i < this.itemList.length; i++) {
            let line = this.itemList[i];
            let thisLineContent = {
                itemID: line.getItemId(),
                sessionID: line.getSessionId(),
                qty: line.getQuantity(),
                price: line.getLineTotal(),
                manualOfferID: line.manualOffer ? line.manualOffer.ID : null
            };
            result.lines.push(thisLineContent);
        }

        return result;
    },

    /**
     * Populate the cart from the given StashedCart.
     * TODO: No longer needs to be async.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     *
     * @return {Promise<void>}
     */
    async populateFromStash(stashedCart) {
        this.setCurrentCart(cloneDeep(stashedCart.currentCart) || new Cart());

        // TODO: Run some revalidation on the cart. Check for past sessions and sessions with no
        // remaining capacity.

        // Restore the stashed fieldData
        this.setFieldData(stashedCart.fieldData, DigiTickets.FieldGroupingType.ALL_FIELDS);

        // If the stashed cart has a fulfillment it means it was sent to the kitchen screen.
        // Keep hold of the original kitchen order for the stashed cart before any modifications so we
        // can print a kitchen receipt showing the difference.
        this.fulfillmentCreatedAt = stashedCart.fulfillmentCreatedAt;
        this.fulfillments = stashedCart.fulfillments || [];
        if (this.fulfillmentCreatedAt) {
            this.restoredKitchenOrder = OrderToKitchenOrderMapper.map(this.toOrder());
        }

        this.clean();
        this.calculate();
    },

    /**
     * Custom fields
     */

    /**
     * Returns all the custom fields applicable to the items in the cart
     * (including order-level fields);
     *
     * @return {FieldGroupCollection}
     */
    getFields: function getFields() {
        if (!(this.fields instanceof FieldGroupCollection)) {
            this.reloadFields();
        }
        return this.fields;
    },

    /**
     * Create a new list of questions (fields) to ask and prune the answers (fieldData) to make sure everything is
     * in sync.
     */
    reloadFields: function reloadFields() {
        // Old fields...

        this.fields = this.fieldManager.createFieldGroupCollectionForCart(this);
        this.fieldData = DigiTickets.FieldHelper.cleanFieldData(this.fields, this.fieldData, DigiTickets.FieldGroupingType.ALL_FIELDS);
        this.fieldManager.setValuesOfFieldGroupCollection(this.fields, this.fieldData, DigiTickets.FieldGroupingType.ALL_FIELDS);

        // New fields...

        // Backup the values of the fields before replacing the instances.
        let originalFieldValues = getFieldValuesFromCart(this.currentCart);

        // Replace the field instances with the new ones.
        this.fieldInstanceFactory.createFieldGroupsOnCart(this.currentCart);

        // Set the values of the new instances to the backed up values.
        setFieldValuesOnCart(originalFieldValues, this.currentCart);

        this._triggerOnChange();
    },

    /**
     * Stores the entered fieldData for the custom fields.
     * Stores it in the property this.fieldData and also updates this.fields
     * so the data gets injected into the FieldInstances.
     *
     * @param {object} data
     * @param {string} fieldGroupingType One of DigiTickets.FieldGroupingType
     */
    setFieldData: function setFieldData(data, fieldGroupingType) {
        this.fieldData = Object.assign(this.fieldData, data);
        this.fieldManager.setValuesOfFieldGroupCollection(this.fields, this.fieldData, fieldGroupingType);
        this._triggerOnChange();
    },

    /**
     * Get the field data.
     *
     * @param {boolean} structuredForApi Restructure the fielddata for the API.
     * @return {Object|{}|*}
     */
    getFieldData: function getFieldData(structuredForApi) {
        // If the field data is going to the API, we need to
        // reorganise the linenumber aspect to be a zero based array.
        // This means we can loop through all the lines and extract
        // the correct line and instance set of answers without too
        // much more effort.
        if (structuredForApi) {
            let splitResult = this.cartLineSplitter.splitDiscounts(this.exportItems(), this.fieldData);

            let currentLineNumbers = splitResult.items.map(
                /**
                 * @param {DigiTickets.CartItem} cartItem
                 * @return {number}
                 */
                function (cartItem) {
                    return cartItem.lineNumber;
                }
            );

            let splitFieldData = splitResult.fieldData;
            let fieldDataWithAdjustedLineNums = [];

            if (splitFieldData !== undefined && splitFieldData.hasOwnProperty('line')) {
                // There is field data!
                for (let key in splitFieldData.line) {
                    if (splitFieldData.line.hasOwnProperty(key)) {
                        fieldDataWithAdjustedLineNums[String(currentLineNumbers.indexOf(parseInt(key)))] = cloneDeep(splitFieldData.line[key]);
                    }
                }
                splitFieldData.line = fieldDataWithAdjustedLineNums;
            }

            return splitFieldData;
        }
        return this.fieldData;
    },

    validateFieldData: function validateFieldData() {
        return this.fieldManager.validateCartFieldData(this, this.fieldData);
    },

    /**
     * Checks if the last time the custom fields modal was opened the fields displayed were the same
     * as the current set of fields calculated for the cart.
     * Returns null if it was never opened. Otherwise returns a boolean.
     *
     * @return {boolean|null}
     */
    haveAllCustomFieldsBeenPresented() {
        if (this.currentCart.lastPresentedCustomFieldKeys === null) {
            return null;
        }

        // Build the collection of current fields.
        let fields = this.fieldManager.createFieldGroupCollectionForCart(this);
        let instanceKeys = fields.getInstanceKeys();

        // See if there are any new fields not already displayed.
        let diff = _.difference(instanceKeys, this.currentCart.lastPresentedCustomFieldKeys);

        return diff.length <= 0;
    },

    /**
     * Populate the array containing a record of custom fields that have been presented.
     *
     * @param {FieldGroupCollection} fields
     */
    setLastPresentedCustomFields(fields) {
        this.currentCart.lastPresentedCustomFieldKeys = fields.getInstanceKeys();
    },

    /**
     * Returns all the MembershipDatasets from all the lines in the cart.
     *
     * @returns {DigiTickets.MembershipDataset[]}
     */
    getMembershipDatasets: function getMembershipDatasets() {
        let datasets = [];
        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].isMembershipPlan()) {
                datasets = datasets.concat(this.itemList[i].membershipDatasets);
            }
        }
        return datasets;
    },

    enableGiftAid: function () {
        this.currentCart.giftAid = true;
        this.currentCart.giftAidPrices = true;

        // Enable gift aid on items.
        this.itemList.forEach((i) => i.enableGiftAid());
    },

    disableGiftAid: function () {
        this.currentCart.giftAid = false;
        this.currentCart.giftAidPrices = false;

        // Disable gift aid on items.
        this.itemList.forEach((i) => i.disableGiftAid());
    },

    /**
     * @return {?boolean}
     */
    giftAidEnabled: function () {
        return this.currentCart.giftAid;
    },

    /**
     * Returns true if Gift Aid has been selected AND there is enough customer info for Gift Aid.
     *
     * @returns {boolean}
     */
    shouldGiftAid: function () {
        return !!(this.currentCart.giftAid && this.customer.hasGiftAidData());
    },

    /**
     * Return a relatively simple list of renewalPlanIDs and the membershipIDs that are renewing to each one.
     *
     * @return {Array}
     */
    exportRenewingMembershipData: function exportRenewingMembershipData() {
        let result = [];

        for (let i = 0; i < this.itemList.length; i++) {
            let cartItem = this.itemList[i];
            if (cartItem.renewingMembership) {
                result.push({
                    planId: cartItem.item.ID,
                    // These are arrays because the API expects them to be.
                    membershipIds: [cartItem.renewingMembership.ID],
                    renewalFromDates: [moment(cartItem.renewingMembershipFromDate).format('YYYY-MM-DD')]
                });
            }
        }

        return result;
    },

    /**
     * Check if the cart contains any items that should be displayed on the kitchen screen.
     * (Disables the send to kitchen button in the hold modal if not).
     *
     * @return {boolean}
     */
    containsKitchenItems() {
        return this.currentCart.containsKitchenItems();
    },

    containsMemberships: function containsMemberships() {
        return this.currentCart.containsMemberships();
    },

    getCancellingMemberships: function getCancellingMemberships() {
        let memberships = [];

        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].cancellingMembership && this.itemList[i].cancellingMembership.isActive()) {
                memberships.push(this.itemList[i].cancellingMembership);
            }
        }

        return memberships;
    },

    allowPartialPayments: function allowPartialPayments() {
        // At the moment, we always allow partial payments unless the cart contains any memberships.
        return !this.containsMemberships();
    },

    getCurrentPaymentPattern: function getCurrentPaymentPattern() {
        // Items being added to the cart must either match the payment period of items already
        // in the cart (if any have a payment period), or be free
        // Checking the price is greater than 0 has the side effect of not counting refunds, which is good.
        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].getLineTotal() > 0) {
                let paymentPattern = this.itemList[i].getItem().paymentPattern;
                if (paymentPattern) {
                    return paymentPattern;
                }
            }
        }

        return null;
    },

    /**
     * @param {DigiTickets.TransactionInProgress|null} transactionInProgress
     */
    setTransactionInProgress(transactionInProgress) {
        this.currentCart.transactionInProgress = transactionInProgress;
    },

    /**
     * Generate a unique ID for a payment for this order.
     *
     * Format is:
     * {this.thirdPartyID}0{paymentAttemptNumber}
     * {companyID}0{deviceID}0{time}0{paymentAttemptNumber}
     *
     * Each time this is called it will increment this.paymentsAttempted so each ID returned is unique.
     *
     * @return {string}
     */
    generatePaymentThirdPartyID: function generatePaymentThirdPartyID() {
        return this.thirdPartyRefGenerator.generatePaymentThirdPartyRef(this);
    },

    /**
     * @param {string} reference
     */
    setStaffRef: function setStaffRef(reference) {
        this.currentCart.staffRef = reference;
        this._triggerOnChange();
    },

    /**
     * Register a callback to be fired when the cart content changes.
     *
     * @param {function} func
     */
    onChange: function onChange(func) {
        this.onChangeFunction = func;
    },

    /**
     * Remove any incomplete lines from the cart (things that can't be sold in their current state).
     *
     * The main use of this is to remove lines for new memberships that don't have any data entered.
     * This can happen if the user clicks to add a membership refreshes the page without confirming or cancelling
     * the membership datasets modal. The cart will be stashed with 1 qty for the membership but no data for that
     * membership.
     * We could check the length of the membershipDatasets array when restoring the cart and prevent adding it,
     * but this clean function has been added instead because in future we want to not need to fiddle with items
     * when stashing/unstashing. Instead this clean function can be called.
     */
    clean() {
        this.itemList.forEach((item) => {
            if (item.isMembershipPlan()) {
                if (item.quantity > 0 && !item.renewingMembership && !item.cancellingMembership) {
                    // Ensure the quantity of the line matches how much data has been entered.
                    let delta = item.membershipDatasets.length - item.quantity;
                    if (delta < 0) {
                        console.warn(`Adjusting qty of membership line by ${delta} to match data length.`);
                        item.adjustQuantity(delta);
                    }
                }
            }
        });

        this.itemsChanged();
    },

    /**
     * Fire the cart contents changed callback.
     */
    _triggerOnChange: function _triggerOnChange() {
        if (typeof this.onChangeFunction === 'function') {
            this.onChangeFunction();
        }
    },

    /**
     * @param {Cart} cart
     */
    setCurrentCart(cart) {
        this.clear();
        this.currentCart = cart;
        mapCartToCartService(cart, this);

        // Make sure the CartItems' cart properties point to this CartService instance.
        // TODO: Remove this coupling between them (PRO-972)
        this.currentCart.itemList.forEach((cartLine) => {
            cartLine.setCart(this);
        });

        this.cartLineNumberFactory.setMin(
            Math.max(...cart.itemList.map((i) => i.lineNumber))
        );

        this.calculate();
        this.itemsChanged();
    },

    useNextCart() {
        this.setCurrentCart(this.nextCart);
        this.nextCart = null;
    },

    /**
     * The below setters and getters are for backward compatibility.
     * These used to be properties of CartService but are now properties of the Cart model (at this.currentCart)
     */

    /**
     * @return {Customer}
     */
    get customer() {
        return this.currentCart.customer;
    },

    /**
     * @param {Customer} customer
     */
    set customer(customer) {
        this.currentCart.customer = customer;
    },

    /**
     * @return {DigiTickets.CartItem[]}
     */
    get itemList() {
        return this.currentCart.itemList;
    },

    /**
     * @param {DigiTickets.CartItem[]} itemList
     */
    set itemList(itemList) {
        this.currentCart.itemList = itemList;
    },

    /**
     * @return {?DigiTickets.Order}
     */
    get originalOrder() {
        return this.currentCart.originalOrder;
    },

    /**
     * @param {?DigiTickets.Order} order
     */
    set originalOrder(order) {
        this.currentCart.originalOrder = order;
    },

    /**
     * @return {?Reservation}
     */
    get reservation() {
        return this.currentCart.reservation;
    },

    /**
     * @param {?Reservation} reservation
     */
    set reservation(reservation) {
        this.currentCart.reservation = reservation;
    },

    /**
     * @return {?boolean}
     */
    get giftAid() {
        return this.currentCart.giftAid;
    },

    /**
     * @param {?boolean} giftAid
     */
    set giftAid(giftAid) {
        this.currentCart.giftAid = giftAid;
    },

    /**
     * @return {?boolean}
     */
    get giftAidPrices() {
        return this.currentCart.giftAidPrices;
    },

    /**
     * @param {?boolean} giftAidPrices
     */
    set giftAidPrices(giftAidPrices) {
        this.currentCart.giftAidPrices = giftAidPrices;
    },

    /**
     * @return {boolean}
     */
    get ignoreDuplicateRef() {
        return this.currentCart.ignoreDuplicateRef;
    },

    /**
     * @param {boolean} ignoreDuplicateRef
     */
    set ignoreDuplicateRef(ignoreDuplicateRef) {
        this.currentCart.ignoreDuplicateRef = ignoreDuplicateRef;
    },

    /**
     * @return {?string}
     */
    get notes() {
        return this.currentCart.notes;
    },

    /**
     * @param {?string} notes
     */
    set notes(notes) {
        this.currentCart.notes = notes;
    },

    /**
     * @return {DigiTickets.Payment[]}
     */
    get payments() {
        return this.currentCart.payments;
    },

    /**
     * @param {DigiTickets.Payment[]} payments
     */
    set payments(payments) {
        this.currentCart.payments = payments;
    },

    /**
     * @return {number}
     */
    get paymentsAttempted() {
        return this.currentCart.paymentsAttempted;
    },

    /**
     * @param {number} paymentsAttempted
     */
    set paymentsAttempted(paymentsAttempted) {
        this.currentCart.paymentsAttempted = paymentsAttempted;
    },

    /**
     * @return {Object<number>}
     */
    get selectedManualOfferIds() {
        return this.currentCart.selectedManualOfferIds;
    },

    /**
     * @param {Object<number>} selectedManualOfferIds
     */
    set selectedManualOfferIds(selectedManualOfferIds) {
        this.currentCart.selectedManualOfferIds = selectedManualOfferIds;
    },

    /**
     * @return {?string}
     */
    get staffRef() {
        return this.currentCart.staffRef;
    },

    /**
     * @param {?string} staffRef
     */
    set staffRef(staffRef) {
        this.currentCart.staffRef = staffRef;
    },

    /**
     * @return {?string}
     */
    get stashedCartGuid() {
        return this.currentCart.stashedCartGuid;
    },

    /**
     * @param {?string} stashedCartGuid
     */
    set stashedCartGuid(stashedCartGuid) {
        this.currentCart.stashedCartGuid = stashedCartGuid;
    },

    /**
     * @return {?string}
     */
    get thirdPartyID() {
        return this.currentCart.thirdPartyID;
    },

    /**
     * @param {?string} thirdPartyID
     */
    set thirdPartyID(thirdPartyID) {
        this.currentCart.thirdPartyID = thirdPartyID;
    },

    /**
     * @return {?string}
     */
    get thirdPartyRef() {
        return this.currentCart.thirdPartyRef;
    },

    /**
     * @param {?string} thirdPartyRef
     */
    set thirdPartyRef(thirdPartyRef) {
        this.currentCart.thirdPartyRef = thirdPartyRef;
    },

    /**
     * @return {?DigiTickets.TransactionInProgress}
     */
    get transactionInProgress() {
        return this.currentCart.transactionInProgress;
    },

    /**
     * @param {?DigiTickets.TransactionInProgress} transactionInProgress
     */
    set transactionInProgress(transactionInProgress) {
        this.currentCart.transactionInProgress = transactionInProgress;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartService;
}
