const { toBool, toFloat, toInt, toString } = require('../functions/transform');

const CurrencyDenomination = function () {
    /**
     * @type {number}
     */
    this.ID = null;

    /**
     * @type {string}
     */
    this.name = '';

    /**
     *
     * @type {number}
     */
    this.value = null;

    /**
     * Is this a coin?
     *
     * @type {boolean}
     */
    this.coin = false;

    /**
     * @type {boolean}
     */
    this.active = true;
};

CurrencyDenomination.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'currencyDenominationID'],
                transform: toInt
            },
            name: toString,
            value: toFloat,
            coin: toBool,
            active: toBool
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CurrencyDenomination;
}
