class AbstractVerifoneRequestRecord {
    /**
     * @param {boolean} [posClient]
     *
     * @return {Array}
     */
    toArray(posClient = false) {
        return posClient ? [] : [];
    }
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AbstractVerifoneRequestRecord;
}
