/* global ENVIRONMENT,PROTOCOL,DOMAIN,IS_BETA */

const config = require('../../config');
const version = require('@temp/js/version');

DigiTickets.AppConfig = function () {
    const valueForEnv = (key) => {
        if (!config[key] || !config[key][ENVIRONMENT]) {
            throw new Error(`Could not find config "${[key, ENVIRONMENT].join('.')}"`);
        }
        return config[key][ENVIRONMENT];
    };

    this.channelID = config.digitickets.channelID;
    this.maxSessionsToSync = config.maxSessionsToSync;
    this.support = {
        email: config.support.email,
        telephone: config.support.telephone
    };

    this.eposVersion = new Date(version.builtAt);
    this.eposBranch = version.branch;
    this.eposCommit = version.commit;
    this.domainRoot = `${PROTOCOL}://app.${DOMAIN}`;
    this.apiBaseUrl = `${PROTOCOL}://api.${DOMAIN}/v${config.digitickets.apiVersion}/`;
    this.isDev = ENVIRONMENT !== 'Production';
    this.isBeta = IS_BETA;
    this.socketUrl = valueForEnv('socketUrl');
};
