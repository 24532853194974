const MembershipSubscriptionResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'memberships/',
        ApiRequestParams.defaults(),
        {
            updateMultiple: {
                isArray: true,
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'membershipsubscriptions'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipSubscriptionResource;
}
