const SavedAddressManager = function (hydrator, OrderInProgressStasher, SavedAddressResource) {
    /**
     * Something about the account has been modified, so let any services that might want an updated
     * copy of the account know.
     *
     * @param {DigiTickets.CustomerAccount} account
     */
    const updateOtherCopiesOfAccount = (account) => {
        if (OrderInProgressStasher.hasOrderInProgress()) {
            const selectedAccount = OrderInProgressStasher.orderInProgress.stashedCart.customer.account;
            if (selectedAccount && selectedAccount.ID === account.ID) {
                OrderInProgressStasher.orderInProgress.stashedCart.customer.setAccount(account);
            }
        }
    };

    /**
     * Persist a new address against a customer account.
     *
     * @param {DigiTickets.Address} address
     * @param {DigiTickets.CustomerAccount} account
     *
     * @return {Promise<DigiTickets.Address>}
     */
    this.addAddressToAccount = async function (address, account) {
        let creationResult;
        try {
            creationResult = await SavedAddressResource.create(
                Object.assign({}, address.toServerData(), { customerAccountID: account.ID })
            ).$promise;
        } catch (error) {
            throw new Error((error.data && error.data.error) ? error.data.error : error.message);
        }

        const savedAddress = hydrator.hydrate(creationResult[0], new DigiTickets.Address());
        account.addresses.push(savedAddress);
        account.refreshPrimaryAddress();
        updateOtherCopiesOfAccount(account);

        return savedAddress;
    };

    /**
     * Remove an address from a customer account
     *
     * @param {DigiTickets.Address} address
     * @param {DigiTickets.CustomerAccount} account
     * @throws {Error}
     */
    this.removeAddressFromAccount = async function (address, account) {
        account.addresses = account.addresses.filter((savedAddress) => (savedAddress.ID !== address.ID));
        try {
            await SavedAddressResource.delete({
                addressID: address.ID,
                customerAccountID: account.ID
            }).$promise;
        } catch (error) {
            throw new Error((error.data && error.data.error) ? error.data.error : error.message);
        }
        account.refreshPrimaryAddress();
        updateOtherCopiesOfAccount(account);
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SavedAddressManager;
}
