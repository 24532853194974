const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const GiftVoucherResource = function ($resource, ApiRequestParams, AppConfig, GiftVoucherCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'giftvouchers/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                channelID: AppConfig.channelID
            }
        ), {
            query: {
                cache: GiftVoucherCache,
                isArray: true,
                params: Object.assign(
                    {
                        resolve: 'categories,denominations',
                        excludeFields: ApiRequestParams.defaultExcludeFields()
                    },
                    ApiRequestParams.defaultMappers()
                ),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'GiftVoucher')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GiftVoucherResource;
}
