const LOG_SEVERITY = require('../Enums/LogSeverity');

DigiTickets.Logger = function () {
    const logToSentry = (log, level) => {
        /** @var {Raven} Raven */
        Raven.captureMessage(
            log.narrative,
            {
                level
            }
        );
    };

    const addToLog = (level) => (narrative, data) => {
        try {
            const log = {
                level,
                date: (new Date()).toISOString(),
                narrative,
                data: JSON.stringify(data)
            };

            if (level <= LOG_SEVERITY.ERROR) {
                console.error(narrative, data);
                logToSentry(log, 'error');
            } else if (level <= LOG_SEVERITY.WARNING) {
                console.warn(narrative, data);
                logToSentry(log, 'warning');
            } else if (level <= LOG_SEVERITY.NOTICE) {
                console.warn(narrative, data);
                logToSentry(log, 'info');
            } else {
                console.log(narrative, data);
            }
        } catch (e) {
            // Prevent logging breaking the app.
        }
    };

    this.debug = addToLog(LOG_SEVERITY.DEBUG);
    this.info = addToLog(LOG_SEVERITY.INFO);
    this.warning = addToLog(LOG_SEVERITY.WARNING);
    this.error = addToLog(LOG_SEVERITY.ERROR);
    this.alert = addToLog(LOG_SEVERITY.ALERT);
};
