/**
 * In non POS Client (VX820IP) integrations, the terminal will send a 'voucher' record
 * on the integration socket when a receipt should be printed. It's rather basic - just a bunch of lines and
 * no formatting info is given. Apparently as long as everything is printed it's good enough though!
 *
 * This could be merchant receipts, customer receipts, and receipts for the customer to sign for non-chip
 * cards or some refunds.
 */
const VerifoneVoucherRecord = function () {
    /**
     * @type {string[]}
     */
    this.lines = [];

    /**
     * @type {String}
     */
    this.rawResponse = null;
};

VerifoneVoucherRecord.prototype = {
    getHydrationMap() {
        return {
            lines: {
                field: '*',
                transform(value) {
                    let lines = [];
                    Object.keys(value).forEach((key) => {
                        if (key !== 'rawResponse') {
                            lines.push(value[key]);
                        }
                    });
                    return lines;
                }
            },
            rawResponse: {}
        };
    },

    afterHydration: function () {
        // Remove the first 2 lines because the first will be blank and the second will just be a 'V'.
        this.lines.shift();
        this.lines.shift();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneVoucherRecord;
}
