/**
 * @param $q
 * @param TemplateResource
 */
DigiTickets.TemplateManager = function (
    $q,
    TemplateResource
) {
    this.$q = $q;
    this.templateResource = TemplateResource;

    this.deferred = this.$q.defer();

    /**
     * @type {Object<string>}
     */
    this.loadedTemplates = {};

    /**
     * Array of template names to pre-load when syncing.
     *
     * @type {string[]}
     */
    this.preloadTemplateNames = [
        'orders.epos.group-ticket',
        'orders.epos.receipt',
        'orders.epos.ticket',
    ];
};

DigiTickets.TemplateManager.prototype = {

    /**
     * Calls this.loadTemplate for each of this.preloadTemplateNames.
     * This will fetch each of them from the API (or localstorage) and store in TemplateCache and this.loadedTemplates,
     * making loadTemplate() return faster when actually needed.
     *
     * @param {string} printMethodDriverRef
     */
    preloadTemplates: function preloadTemplates(printMethodDriverRef) {
        let self = this;

        let loaded = 0;
        for (let i = 0; i < this.preloadTemplateNames.length; i++) {
            let templateName = this.preloadTemplateNames[i];

            this.loadTemplate(
                templateName,
                printMethodDriverRef,
                function () {
                    ++loaded;
                    if (loaded >= self.preloadTemplateNames.length) {
                        self.deferred.resolve();
                    }
                }
            );
        }

        return this.deferred.promise;
    },

    /**
     * @param {string} templateName
     * @param {string} printMethodDriverRef
     * @param {function} [callback]
     *
     * @return {Promise<any>}
     */
    loadTemplate: function loadTemplate(templateName, printMethodDriverRef, callback) {
        return new Promise((resolve, reject) => {
            let key = templateName + ':' + printMethodDriverRef;

            if (this.loadedTemplates.hasOwnProperty(key)) {
                if (typeof callback === 'function') {
                    callback(this.loadedTemplates[key]);
                }
                resolve(this.loadedTemplates[key]);
                return;
            }

            let self = this;
            this.templateResource.query(
                {
                    printMethod: printMethodDriverRef,
                    templateName
                },
                function (response) {
                    self.loadedTemplates[key] = response;
                    if (typeof callback === 'function') {
                        callback(response);
                    }
                    resolve(response);
                },
                reject
            );
        });
    }
};
