/**
 * Different levels of error messages that we can display.
 * These are compatible with RFC5424 log levels (and the LogSeverity list).
 */
const ErrorLevel = Object.freeze({
    ERROR: 3,
    WARNING: 4,
    INFO: 6
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ErrorLevel;
}
