const CurrencyService = function (
    $locale,
    UserService
) {
    this.userService = UserService;

    const formats = $locale.NUMBER_FORMATS;
    this.defaultSymbol = ($locale ? formats.CURRENCY_SYM : '&pound;');
};

CurrencyService.prototype = {
    /**
     * @return {string}
     */
    getSymbol: function getSymbol() {
        // Use default symbol initially
        // Use the given symbol if any, fallback to default
        let symbol = this.defaultSymbol;

        try {
            // Try and use the companies currency
            let currency = this.userService.company.currency;
            if (currency.html !== undefined) {
                symbol = currency.html;
            }
            // Decode html entity
            symbol = $('<div/>').html(symbol).text();
        } catch (e) {
        }

        return symbol;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CurrencyService;
}
