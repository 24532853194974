const angular = require('angular');
const CartItemDiscount = require('../Offers/CartItemDiscount');
const Category = require('@/lib/Categories/Category');
const ItemEditableStatus = require('./ItemEditableStatus');
const ItemInstance = require('./ItemInstance');
const mapItemTypeToModel = require('@/lib/Items/mapItemTypeToModel');
const Session = require('../Sessions/Session');
const { toBool, toDate, toFloat, toInt, toNullableInt, toString, toNullableString } = require('../../../functions/transform');

const OrderLine = function () {
    /**
     * Simple name/values
     */
    this.ID = null;
    this.baseTotal = 0;
    this.bookingFee = 0;
    this.bookingFeeSubtotal = 0;

    /**
     * Raw field data from the API
     */
    this.adjustedByID = null;
    this.adjustsID = null;
    this.donation = 0;

    /**
     * Is this line expired?
     * Provided by the API, based on this line existing in the orders_items_expired table.
     * Note that this will be false for expired open tickets! (see PRO-805)
     *
     * @type {boolean}
     */
    this.expired = false;

    this.giftAid = null;
    this.itemID = null;
    this.itemType = null;
    this.lastRedeemedAt = null;
    this.lineTotal = 0;
    this.name = null;
    this.orderID = null;
    this.people = undefined;
    this.price = 0;
    this.qty = 0;

    /**
     * @type {boolean}
     */
    this.redeemable = false;

    this.redeemed = 0;
    this.returnToStock = false;
    this.subtitle = null;
    this.surchargeID = null;
    this.validityPeriod = null;

    /**
     * When this open ticket expires, if it has a validityPeriod.
     * See calculateOrderLineValidUntil function.
     *
     * @type {?Date}
     */
    this.validUntil = null;

    /**
     * Objects
     */
    this.branch = null;
    this.category = null;

    /**
     * @type {?CartItemDiscount}
     */
    this.discount = null;

    /**
     * @type {?DigiTickets.Event}
     */
    this.event = null;

    /**
     * @type {AbstractItem}
     */
    this.item = null;

    /**
     * @type {?DigiTickets.ReturnReason}
     */
    this.returnReason = null;

    /**
     * @type {?Session}
     */
    this.session = null;

    /**
     * @type {ItemInstance[]}
     */
    this.iteminstances = [];

    // Information about whether a redemption request is in progress or not.
    this.redemptionInProgress = false;
    this.unredemptionInProgress = false;
    this.currentRedeemed = null;

    // Synthetic properties.
    this.lineNumber = 0;
    this.absQty = 0;

    // Represents the option in the UI as to whether the custom fields are currently visible
    this.customFieldsVisible = false;
};

OrderLine.prototype = {
    getName: function getName() {
        if (this.item) {
            if (this.giftAid) {
                return this.item.getName() + ' (Gift Aid)';
            }
            return this.item.getName();
        }
        return this.name;
    },

    isAdjustment: function isAdjustment() {
        return !!this.adjustsID;
    },

    isAdjusted: function isAdjusted() {
        return !!this.adjustedByID;
    },

    /**
     * Find out whether redemptions are allowed on this line.
     * Only tickets can be redeemed.
     *
     * Note for backward compatibility an expired open ticket is still redeemable. It is at the discretion of
     * the operator if it can be redeemed.
     */
    isRedemptionAllowed: function isRedemptionAllowed() {
        return this.itemType === 'Ticket'
            && !(this.isAdjustment() || this.isAdjusted())
            && this.redeemable === true
            && this.expired === false;
    },

    /**
     * @return {boolean}
     */
    isExpired() {
        return this.expired || this.validUntilHasPassed();
    },

    /**
     * @return {boolean}
     */
    validUntilHasPassed() {
        return this.validUntil !== null && this.validUntil < new Date();
    },

    /**
     * Gets the redeemable class for the item (redeemed - danger, redeemable - success, wrong-day - warning)
     *
     * @return {string}
     */
    getItemStatusClass: function getItemStatusClass() {
        if (this.redeemed == this.qty) {
            return 'danger';
        }

        if (this.session) {
            if (!this.session.startTime.isSameDay(new Date())) {
                return 'warning';
            }
        }

        return 'success';
    },

    toggleCustomFieldsVisibility: function toggleCustomFieldsVisibility() {
        this.customFieldsVisible = !this.customFieldsVisible;
    },

    getRedemptionCount: function getRedemptionCount() {
        return this.redeemed;
    },

    /**
     * Can this line be edited when an existing order is being edited?
     *
     * @returns {boolean}
     */
    isEditable() {
        return this.getEditableStatus() === ItemEditableStatus.IS_EDITABLE;
    },

    /**
     * Can this line be edited? If not why not? Get the state of the line so we can display in the UI.
     *
     * @returns {string}
     */
    getEditableStatus() {
        if (this.isExpired()) {
            return ItemEditableStatus.IS_EXPIRED;
        }

        if (this.itemType !== DigiTickets.ItemType.TICKET) {
            // Only tickets can be edited currently.
            return ItemEditableStatus.IS_NOT_TICKET;
        }

        if (!this.isRedemptionAllowed() || this.redeemed >= this.qty) {
            // Already redeemed.
            return ItemEditableStatus.IS_REDEEMED;
        }

        return ItemEditableStatus.IS_EDITABLE;
    },

    getHydrationMap() {
        return {
            adjustedByID: toNullableInt,
            adjustsID: toNullableInt,
            baseTotal: toFloat,
            bookingFee: toFloat,
            bookingFeeSubtotal: toFloat,
            branch: {
                field: ['branch', 'branches'],
                model: DigiTickets.Branch
            },
            category: {
                field: ['category', 'categories'],
                model: Category
            },
            donation: toFloat,
            expired: toBool,
            event: {
                field: ['event', 'events'],
                model: DigiTickets.Event
            },
            giftAid: toBool,
            ID: {
                field: ['ID', 'ordersItemsID'],
                transform: toInt
            },
            item: {
                field: ['item', 'items'],
                /**
                 * @param {any} value
                 * @param {{}} allValues
                 * @param {DigiTickets.Hydrator} hydrator
                 *
                 * @return {?object}
                 */
                transform(value, allValues, hydrator) {
                    if (!value || !allValues.itemType) {
                        return null;
                    }
                    let modelClass = mapItemTypeToModel(allValues.itemType);
                    if (!modelClass) {
                        return null;
                    }

                    return hydrator.hydrate(value, new modelClass());
                }
            },
            itemID: toInt,
            iteminstances: {
                modelCollection: ItemInstance
            },
            itemType: toString,
            lastRedeemedAt: toDate,
            lineTotal: toFloat,
            name: toNullableString,
            orderID: toInt,
            price: toFloat,
            qty: toInt,
            redeemable: toBool,
            redeemed: toInt,
            returnReason: {
                field: ['returnReason', 'returnreasons'],
                model: DigiTickets.ReturnReason
            },
            returnToStock: toBool,
            session: {
                field: ['session', 'sessions'],
                model: Session
            },
            surchargeID: toNullableInt,
            validityPeriod: toNullableInt
        };
    },

    afterHydration: function afterHydration(data, hydrator) {
        if (data.discountAmount) {
            let discount = new CartItemDiscount();
            discount.qty = this.qty;
            discount.amount = data.discountAmount;
            discount.percentage = data.discountPercentage;
            discount.offer = data.offers ? hydrator.hydrate(data.offers[0], new DigiTickets.Offer()) : null;
            discount.offerID = discount.offer ? discount.offer.ID : null;
            this.discount = discount;
        }

        this.absQty = Math.abs(this.qty);
    },

    getEvent: function getEvent() {
        return this.event;
    },

    getEventID: function getEventID() {
        return this.event ? this.event.ID : null;
    },

    getItem: function getItem() {
        return this.item;
    },

    getItemID: function getItemID() {
        return this.item ? this.item.ID : null;
    },

    getSession: function getSession() {
        return this.session;
    },

    getSessionID: function getSessionID() {
        return this.session ? this.session.ID : null;
    },

    getQuantity: function getQuantity() {
        return this.qty;
    },

    getDonation: function getDonation() {
        return this.donation;
    },

    setRedemptionInProgress() {
        this.redemptionInProgress = true;
    },

    setUnredemptionInProgress() {
        this.unredemptionInProgress = true;
    },

    clearRedemptionInProgress() {
        this.redemptionInProgress = false;
        this.unredemptionInProgress = false;
    },

    redemptionIsInProgress: function redemptionIsInProgress() {
        return this.redemptionInProgress || this.unredemptionInProgress;
    },

    /**
     * @returns {boolean}
     */
    hasInstancePerTicket: function hasInstancePerTicket() {
        return (this.itemType === 'Ticket' && this.item.printTicketPerInstance);
    },

    summaryItemInstanceNetRedemptions: function summaryItemInstanceNetRedemptions() {
        let summary = 0;
        if (this.hasInstancePerTicket()) {
            angular.forEach(this.iteminstances, function (itemInstance) {
                summary += parseInt(itemInstance.netRedemptions);
            });
        }
        return summary;
    },

    setCurrentRedeemed: function setCurrentRedeemed(force) {
        if (this.currentRedeemed == null || !!force) {
            this.currentRedeemed = this.redeemed;
        }
    },

    getCurrentRedeemed: function getCurrentRedeemed() {
        return this.currentRedeemed;
    },

    /**
     * This is for backward compatibility because this function is called in receipt templates, stored in the app
     * repository.
     * The expiry date is now calculated externally and set as the this.validUntil property.
     *
     * @returns {?Date}
     */
    getExpiryDate: function getExpiryDate() {
        return this.validUntil;
    },

    /**
     * Get a list of Members on this order line.
     *
     * @return {DigiTickets.Member[]}
     */
    getMembers: function getMembers() {
        let orderLineMembers = [];

        this.iteminstances.forEach(function (itemInstance) {
            orderLineMembers = [].concat(orderLineMembers, itemInstance.getMembers());
        });

        return orderLineMembers;
    },

    /**
     * @return {boolean}
     */
    isMembershipPlan: function isMembershipPlan() {
        return this.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN;
    },

    /**
     * @param {number} instanceNumber
     *
     * @return {?ItemInstance}
     */
    getInstance(instanceNumber) {
        return this.iteminstances.find((i) => i.instance === instanceNumber) || null;
    },

    /**
     * Returns true if the line contains any instances that contain any non-empty field data.
     *
     * @return {boolean}
     */
    hasAnyFieldData() {
        for (let i = 0; i < this.iteminstances.length; i++) {
            if (this.iteminstances[i].fields.hasInstancesWithValue()) {
                return true;
            }
        }

        return false;
    },

    /**
     * For compatibility with CartLine.
     *
     * @return {ItemInstance[]}
     */
    getItemInstances() {
        return this.iteminstances;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderLine;
}
