DigiTickets.FieldGroupingType = Object.freeze({
    /**
     * When we store answers into the fielddata collection we may not have
     * all the answers. These 'constants' allow us to define which type
     * of answer we want to interact with and imply that the other types
     * will not be removed due to lack of available data.
     */
    ALL_FIELDS: 'all',
    ASK_EARLY_FIELDS_ONLY: 'askEarlyFieldsOnly',
    NON_ASK_EARLY_FIELDS_ONLY: 'nonAskEarlyFieldsOnly'
});
