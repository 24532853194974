const VerifoneTransactionModifiers = {
    CARDHOLDER_PRESENT: '0000',
    OFFLINE_CAPTURE: '0002',
    ONLINE: '0004', // eVo Only
    CNP_MAIL_ORDER: '0008',
    AUTHORISATION_ONLY: '0010',
    CONTINUOUS_AUTHORITY: '0020',
    ALLOW_ZERO_VALUE_PRE_AUTH: '0080', // POS Client Only
    ELECTRONIC_COMMERCE: '0200', // eVo Only
    CPC_MINUS_I_AND_L_RECORDS: '0400', // What? POS Client Only
    ALLOW_ELECTRON_CNP: '0800', // (Tele/Spread-betting) POS Client Only
    CNP_TELEPHONE_ORDER: '1000',
    CNP_ACCOUNT_ON_FILE: '2000'
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneTransactionModifiers;
}
