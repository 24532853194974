/**
 * This is a collection of functions that can be used to filter an array of FieldInstances.
 * They are primarily used when calling setValuesOfFieldGroup or setFieldValuesOnCart, but there's no reason they
 * can't be used for other filtering purposes too.
 */
const FieldInstanceFilter = Object.freeze({
    /**
     * @return {boolean}
     */
    ALL: () => true,

    /**
     * @param {FieldInstance} fi
     * @return {boolean}
     */
    ASK_EARLY_FIELDS_ONLY: (fi) => !!fi.field.askEarly,

    /**
     * @param {FieldInstance} fi
     * @return {boolean}
     */
    NON_ASK_EARLY_FIELDS_ONLY: (fi) => !fi.field.askEarly
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldInstanceFilter;
}
