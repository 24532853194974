const contactFilter = function (contact) {
    /**
     * @type {DigiTickets.Contact} contact
     */
    if (!contact || typeof contact !== 'object') {
        return '';
    }

    let parts = [
        [contact.firstName, contact.lastName].join(' ').trim(),
        contact.email,
        contact.tel,
        // contact.dateOfBirth
    ];

    parts = parts.filter(function (e) {
        return e;
    });

    return parts.join('<br/>');
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = contactFilter;
}
