const PaymentMethodRef = require('../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

const receiptPaymentsDirective = function () {
    return {
        restrict: 'E',
        scope: {
            order: '='
        },
        templateUrl: 'partials/directives/dt-receipt-payments.html',
        controller: function ($scope) {
            // Make PaymentMethodRef available in the template.
            // Note that the <dt-receipt-payments> template has an isolated scope that only contains the things that
            // are specified above to be passed in and anything that is set here. In case you were wondering why
            // setting things in ReceiptPrinter.defaultPrintTemplateData wasn't working!
            $scope.PaymentMethodRef = PaymentMethodRef;
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = receiptPaymentsDirective;
}
