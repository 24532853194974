const angular = require('angular');
const io = require('socket.io-client');

/**
 * @param {Array} [additionalModules]
 *
 * @return {angular.Module}
 */
const createAngularApp = (additionalModules = []) => {
    if (typeof global !== 'undefined') {
        // For btford.socket-io - it expects socket.io to be globally available.
        // The if statement ensure this line only runs when running with Webpack or Node.
        global.io = io;
    }

    /**
     * This is the list of AngularJS plugins/modules to use.
     *
     * Here we have a problem with some third party Angular modules.
     * Ideally this would be an array that looks like:
     * [
     *     require('angular-route'),
     *     require('angular-resource'),
     *     // etc.
     * ]
     * but some of these modules are really old and don't expose themselves that way.
     *
     * For the time being we have Grunt generate the 'angular-vendor.js' file that just sticks those all together like
     * old times and add them by name like we've always done.
     * One by one we'll replace each of these strings with a require('package-name') and remove that module from
     * angular-vendors.js
     *
     * @type {string[]}
     */
    let modules = [
        'ngRoute',
        'ngResource',
        'ngSanitize',
        'ui.bootstrap',
        'pasvaz.bindonce',
        'jmdobry.angular-cache',
        'templates-main',
        'angular-repeat-n',
        'ui-notification',
        'duScroll',
        'rt.debounce',
        'btford.socket-io',
    ];

    if (additionalModules && additionalModules.length > 0) {
        Array.prototype.push.apply(modules, additionalModules);
    }

    /**
     * Instantiate Angular.
     */
    const app = angular.module('dtProPoint', modules);

    /**
     * Application-wide default configuration for UI Bootstrap date picker
     */
    app.constant('datepickerConfig', {
        dayFormat: 'dd',
        monthFormat: 'MMMM',
        yearFormat: 'yyyy',
        dayHeaderFormat: 'EEE',
        dayTitleFormat: 'MMMM yyyy',
        monthTitleFormat: 'yyyy',
        showWeeks: false,
        startingDay: 0,
        yearRange: 20,
        minDate: null,
        maxDate: null
    });

    /**
     * Create the caches.
     * This needs to be done before the app starts running to allow all resource to have their cache available.
     */
    DigiTickets.CacheFactory(app);

    return app;
};

if (typeof module !== 'undefined' && module.exports) {
    // Running in Node or Webpack - export the functions to be used elsewhere.
    module.exports = createAngularApp;
} else {
    // Running directly in the browser (Grunt).
    // To maintain previous behaviour call the function immediately.
    window.app = createAngularApp(
        window.additionalAngularModules || []
    );

    window.baseLanguageStrings = null;
}
