const _ = require('lodash');
const PrintDriverRefs = require('../../libraries/DigiTickets/Printing/PrintDriverRefs');
const PrinterAppPrintDriver = require('../../libraries/DigiTickets/Printing/Drivers/PrinterAppPrintDriver');
const PrintType = require('../../libraries/DigiTickets/Printing/PrintType');
/**
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {AgentService} AgentService
 * @param {AvailablePrintersManager} availablePrintersManager
 * @param {CurrentDevice} CurrentDevice
 * @param DeviceResource
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {PrintRouter} PrintRouter
 */
const PrintConfigModalCtrl = function (
    $modalInstance,
    $scope,
    $timeout,
    AgentService,
    availablePrintersManager,
    CurrentDevice,
    DeviceResource,
    Logger,
    NotificationService,
    PrintRouter
) {
    /**
     * @type {DigiTickets.Device}
     */
    $scope.device = CurrentDevice.device;

    /**
     * @type {PrintRouter}
     */
    $scope.printRouter = PrintRouter;

    $scope.loading = true;
    $scope.error = null;

    /**
     * Start with the existing config.
     */
    $scope.defaultRouting = _.cloneDeep(PrintRouter.defaultRouting);
    $scope.typeRoutings = _.cloneDeep(PrintRouter.typeRoutings);

    /**
     * Make all the different types of document available to the template.
     *
     * @type {string[]}
     */
    $scope.printTypes = Object.values(PrintType);

    /**
     * List of possible print drivers for the selects.
     *
     * @type {Array}
     */
    $scope.printDriverRefs = [];
    Object.values(PrintDriverRefs)
        .filter((r) => r !== PrintDriverRefs.DUMMY)
        .forEach((r) => $scope.printDriverRefs.push({ label: r, value: r }));

    /**
     * List of possible printer names for the selects.
     *
     * @type {Array}
     */
    $scope.printerNames = [];
    (async () => {
        try {
            let printers = await availablePrintersManager.getPrinterNames();
            // $timeout used to speed up UI updating (because AngularJS takes it sweet time otherwise).
            $timeout(() => {
                printers.forEach((p) => $scope.printerNames.push({ label: p, value: p }));
            });
        } catch (e) {
            $scope.error = e.message;
        }

        $timeout(() => {
            $scope.loading = false;
        });
    })();

    /**
     * If a ref is selected that has no control over which physical printer it goes to  clear the printer name value.
     *
     * @param {PrintRouting} routing
     */
    $scope.driverRefChanged = (routing) => {
        if (!PrintRouter.canSelectPrinterForDriverRef(routing.driverRef)) {
            routing.printerName = null;
        }
    };

    /**
     * @return {Promise<*>}
     */
    $scope.persistConfig = async () => {
        // Currently the device's printConfig field only has one element ("routing"). In future there may be more.
        // To avoid losing extra properties if any are added in future we will just set the "routing" property for now
        // and keep anything else.
        let config = JSON.parse($scope.device.printConfig);
        if (!config) {
            config = {};
        }
        config.routing = PrintRouter.exportConfig();
        $scope.device.printConfig = JSON.stringify(config);

        CurrentDevice.cache();

        return DeviceResource.setPrintConfig(
            {
                deviceID: $scope.device.ID
            },
            {
                printConfig: $scope.device.printConfig
            }
        ).$promise;
    };

    $scope.ok = async () => {
        // Set the config in the PrintRouter
        PrintRouter.setDefaultRouting($scope.defaultRouting);
        PrintRouter.setTypeRoutings($scope.typeRoutings);

        // Save the config now set in the PrintRouter to the API.
        try {
            $scope.saving = true;
            await $scope.persistConfig();
            $modalInstance.close();
        } catch (e) {
            NotificationService.error(e.toString(), true);
            Logger.error(e.toString());
        } finally {
            $scope.saving = false;
        }
    };

    $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintConfigModalCtrl;
}
