const logFactory = require('../Logging/logFactory');

/**
 * @param {AgentService} AgentService
 * @param {PrinterAppPrintDriver} printerAppPrintDriver
 */
const AvailablePrintersManager = function (
    AgentService,
    printerAppPrintDriver
) {
    this.agentService = AgentService;
    this.printerAppPrintDriver = printerAppPrintDriver;

    this.log = logFactory('AvailablePrintersManager');
};

AvailablePrintersManager.prototype = {
    async getPrinterNames() {
        let printers;

        try {
            // Try loading printers from the print app.
            printers = await this.printerAppPrintDriver.getPrinterNames();
            this.log.log('Loaded printer names from Printer App', printers);
        } catch (appError) {
            this.log.log('Failed to load printer names from app', appError);
            try {
                // Try loading printers from the agent.
                printers = await this.agentService.getPrinterNames();
                this.log.log('Loaded printer names from Agent', printers);
            } catch (agentError) {
                this.log.log('Failed to load printer names from agent', agentError);
            }
        }

        if (printers) {
            return printers;
        }

        throw new Error('Unable to load printer names. Please ensure ProPoint Agent or ProPoint Printer are running.');
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AvailablePrintersManager;
}
