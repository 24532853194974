const BarcodeGenerator = require('../libraries/DigiTickets/BarcodeGenerator');

const barcodeDirective = function () {
    return {
        link: function link(scope, element, attrs) {
            const getOrientation = (orientation) => {
                const value = orientation.toLowerCase();
                return ['h', 'v'].indexOf(value) !== -1 ? value : 'h';
            };

            const code = attrs.barcode || '';
            const orientation = attrs.orientation || '';
            const opts = {
                orientation: getOrientation(orientation)
            };
            element.html(BarcodeGenerator(code, opts));
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = barcodeDirective;
}
