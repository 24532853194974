const _ = require('lodash');
const config = require('./config');
const version = require('@temp/js/version');

/**
 * Staged building of application. See Gruntfile.js for sequence.
 *
 * This file is the very first point of entry for all of the screens (epos/kitchen/customer etc.)
 */

const bootstrap = () => {
    /**
     * Force all page loads to start from the index page.
     *
     * @type {string}
     */
    window.location.hash = '#/';

    /**
     * Check if a new cache is available on page load.
     */
    window.addEventListener('load', function () {
        let appCache = window.applicationCache;
        appCache.addEventListener('updateready', function () {
            if (appCache.status === appCache.UPDATEREADY) {
                appCache.swapCache();
                $('body').append(
                    '<div class="loading-overlay" id="updating-overlay"><div><h3>ProPoint Is Updating <em>.</em> <em>.</em> <em>.</em></h3></div></div>'
                );
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
        }, false);
    }, false);

    window.DOMAIN = window.location.host;
    window.IS_BETA = false;

    if (window.location.host.indexOf('beta.epos.') === 0) {
        window.DOMAIN = window.location.host.substr('beta.epos.'.length);
        window.IS_BETA = true;
    }

    if (window.location.host.indexOf('epos') === 0) {
        window.DOMAIN = window.location.host.substr('epos.'.length);
    }

    window.PROTOCOL = window.location.protocol.slice(0, -1);

    window.ENVIRONMENT = null;
    if (window.DOMAIN.indexOf('localhost') === 0) {
        window.ENVIRONMENT = 'Development';
    } else if (config.validDomains.hasOwnProperty(window.DOMAIN)) {
        window.ENVIRONMENT = config.validDomains[window.DOMAIN];
    } else {
        throw new Error('Domain does not appear to be valid (' + window.DOMAIN + ')');
    }

    window.additionalAngularModules = [];

    // Sentry configuration (error monitoring - only for staging & production)
    if (window.ENVIRONMENT !== 'Development') {
        Raven.config(config.sentry.dsn, {
            release: version.commit, // replaced by Grunt
            environment: window.ENVIRONMENT,
            tags: {
                build: version.builtAt,
                commit: version.commit,
                branch: version.branch
            }
        }).addPlugin(Raven.Plugins.Angular).install();

        // Only add the Angular module if we're using Raven/Sentry.
        window.additionalAngularModules.push('ngRaven');
    }

    // vh units do not always reflect the actual available viewport
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // Remember the handler in a variable in case we need to programmatically remove it later on.
    const debouncedResizeEventListener = _.debounce(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 100);
    window.addEventListener('resize', debouncedResizeEventListener, { passive: true });
    window.debouncedResizeEventListener = debouncedResizeEventListener;
};

if (typeof module !== 'undefined' && module.exports) {
    // Running in Node or Webpack - export the functions to be used elsewhere.
    module.exports = bootstrap;
} else {
    // Running directly in the browser (Grunt).
    // To maintain previous behaviour call the function immediately.
    bootstrap();
}
