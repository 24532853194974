/**
 * @param {string} name
 * @param {boolean} [debugEnabled=true]
 */
const ConsoleLogger = function (name, debugEnabled = true) {
    const logName = `[${name}]`;

    this.error = (function () {
        // See https://stackoverflow.com/a/26078207/710630
        // Returning this function instead of calling console.error here makes the line number of the calling
        // code still appear in the console instead of this line in ConsoleLogger.
        return Function.prototype.bind.call(console.error, console, logName);
    }());

    this.log = (function () {
        return Function.prototype.bind.call(console.log, console, logName);
    }());

    /**
     * @param {boolean} enabled
     */
    this.setDebugEnabled = (enabled) => {
        if (enabled) {
            this.debug = (function () {
                return Function.prototype.bind.call(console.debug, console, logName);
            }());
        } else {
            this.debug = () => {};
        }
    };

    this.setDebugEnabled(debugEnabled);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ConsoleLogger;
}
