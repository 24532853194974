const ItemEditCtrl = require('../controllers/modal/ItemEditCtrl');

/**
 * @param $timeout
 * @param {DialogService} DialogService
 * @param {ModalFactory} ModalFactory
 */
const cartItemQuantityListenerDirective = function (
    $timeout,
    DialogService,
    ModalFactory
) {
    return {
        link: function link(scope, element, attrs) {
            /**
             * @return {*|promise|{then, catch, finally}|*}
             */
            function invokeReturnReasonModal() {
                return ModalFactory.open(
                    'item-edit',
                    ItemEditCtrl,
                    'partials/modals/itemEdit.html',
                    {
                        cart: () => scope.cart,
                        cartItem: () => scope.cartItem,
                        requestedTabName: () => 'ITEM_EDIT.RETURN_REASONS'
                    }
                );
            }

            //
            // Display the return reason picker (and maybe supervisor approval)
            // when first changing an item's quantity to negative.
            //
            // @param adjustBy
            //
            function handleReturn(adjustBy) {
                invokeReturnReasonModal().then(
                    function () {
                        scope.cartItem.adjustQuantity(adjustBy);
                    },
                    function () {
                        // Update the qty displayed.
                        scope.cartItem.adjustQuantity(0);
                    }
                );
            }

            /**
             * Process a change in quantity.
             *
             * @param {{cartItem: DigiTickets.CartItem, addMembershipPlan: Function(DigiTickets.CartItem cartItem)}} scope
             * @param element
             * @param {{dtCartItemQuantityListener: string}} attrs
             * @param event
             *
             * NOTE: Only use adjustQuantity() to make the changes to the quantity.
             */
            function handleQuantityChanged(scope, element, attrs, event) {
                element.disabled = true;
                event.preventDefault();
                event.stopPropagation();

                let newQuantity;
                let currentQuantity = scope.cartItem.getQuantity();

                switch (attrs.dtCartItemQuantityListener) {
                    case 'plus':
                        newQuantity = currentQuantity + 1;
                        break;
                    case 'minus':
                        newQuantity = currentQuantity - 1;
                        break;
                    case 'manual':
                        newQuantity = scope.cartItem.quantityDisplay || 0;
                        break;
                }

                switch (scope.cartItem.getItemType() + ' - ' + attrs.dtCartItemQuantityListener) {
                    // Force Order Balance quantity to always be one, regardless of any attempts to modify it.
                    case 'Order Balance - plus':
                    case 'Order Balance - minus':
                    case 'Order Balance - manual':
                        scope.cartItem.adjustQuantity(0);
                        break;

                        // Membership plans cannot be refunded, so no -ve quantities.
                        // Always ask to confirm the removal of a membership.
                    case 'Membership Plan - plus':
                        scope.displayNewMembershipDatasetModal(scope.cartItem)
                            .then(() => $timeout(() => {
                                scope.cartItem.incrementQuantity();
                            }))
                            .catch(() => {
                                console.warn('Membership data entry cancelled. Cancelling qty increase.');
                            });
                        break;
                    case 'Membership Plan - minus':
                        DialogService.confirm(
                            'MEMBERSHIPS.CONFIRM_REMOVE',
                            function (confirmed) {
                                if (confirmed === true) {
                                    scope.cartItem.decrementQuantity();
                                }
                            }
                        );
                        break;
                    case 'Membership Plan - manual':
                        // Should be disabled in the UI as only the [+] and [-] buttons should work for membership plans.
                        scope.cartItem.adjustQuantity(0);
                        break;

                        // Tickets and Products require a return reason for -ve quantities.
                    case 'Ticket - plus':
                    case 'Product - plus':
                        scope.cartItem.incrementQuantity();
                        break;
                    case 'Ticket - minus':
                    case 'Product - minus':
                        if (currentQuantity === 1) {
                            // Current quantity is 1, so adjust quantity by -2 to
                            // go from +1 to -1.
                            handleReturn(-2);
                        } else {
                            scope.cartItem.decrementQuantity();
                        }
                        break;
                    case 'Ticket - manual':
                    case 'Product - manual':
                        if (newQuantity === 0) {
                            newQuantity -= currentQuantity;
                        }
                        if (newQuantity < 0 && scope.cartItem.returnReason.reasonID === null) {
                            handleReturn(newQuantity - currentQuantity);
                        } else {
                            scope.cartItem.adjustQuantity(newQuantity - currentQuantity);
                        }
                        break;

                        // Generic item type. We just adjust quantity as necessary. No return reasons.
                    default:
                        if (newQuantity === 0 && newQuantity > currentQuantity) {
                            // Increasing but we don't want to stop at zero so go to 1.
                            newQuantity = 1;
                        } else if (newQuantity === 0 && newQuantity < currentQuantity) {
                            // Decreasing but we don't want to stop at zero so go to -1.
                            newQuantity = -1;
                        }

                        scope.cartItem.adjustQuantity(newQuantity - currentQuantity);
                }

                element.disabled = false;

                scope.$apply();
            }

            // Bind to appropriate event for the type of element.
            element.on({ INPUT: 'change', BUTTON: 'click' }[element[0].nodeName], function (event) {
                handleQuantityChanged(scope, element, attrs, event);
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = cartItemQuantityListenerDirective;
}
