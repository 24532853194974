const FieldInstanceFilter = require('../../libraries/DigiTickets/CustomFields/FieldInstanceFilter');
const { extractFieldValuesFromForm, getAllFieldGroupsFromOrder, setValuesOfFieldGroup } = require('../../libraries/DigiTickets/CustomFields/fieldFunctions');
const { cloneDeep } = require('../../functions/clone');

/**
 * @param {*} $scope
 * @param {FieldValidator} fieldValidator
 * @param {LangService} LangService
 */
const FieldsCtrl = function (
    $scope,
    fieldValidator,
    LangService
) {
    /**
     * Grab all the field groups in the cart and clone them.
     * This way we don't modify the originals while editing the form. That shouldn't happen until OK is clicked, at
     * which point the values are sent back to the calling code and that will set the values on the field instances.
     *
     * @type {FieldGroup[]}
     */
    $scope.fieldGroups = cloneDeep(getAllFieldGroupsFromOrder($scope.cartService.currentCart));

    /**
     * @type {FieldInstanceFilter.NON_ASK_EARLY_FIELDS_ONLY}
     */
    $scope.fieldInstanceFilter = FieldInstanceFilter.NON_ASK_EARLY_FIELDS_ONLY;

    /**
     * Maximum date for a date of birth input.
     *
     * @type {string}
     */
    $scope.maxDobDate = new Date().toYMD();

    /**
     * @return {Object<string, string|number|number[]>}
     */
    const getFieldValuesFromForm = () => extractFieldValuesFromForm();

    /**
     * @return {boolean}
     */
    $scope.validateFields = () => {
        // Grab the field data from the form.
        const fieldValues = getFieldValuesFromForm();

        // Set those values on the field groups so we can run validation on the objects.
        $scope.fieldGroups.forEach(
            (fieldGroup) => setValuesOfFieldGroup(fieldValues, fieldGroup)
        );

        // Validate the fields.
        return fieldValidator.validateFieldGroups($scope.fieldGroups);
    };

    $scope.$on('customer-details-modal.ok-pressed', (event, resultToReturn) => {
        if (!$scope.validateFields()) {
            console.log('Field validation failed.', $scope.fieldGroups);
            $scope.setErrorMsg(LangService.getText('SELL.FIELD_DATA_INVALID'));
            $scope.setValid(false);
        }

        resultToReturn.fieldValues = getFieldValuesFromForm();
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldsCtrl;
}
