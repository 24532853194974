/**
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {Hydrator} hydrator
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
DigiTickets.OrderInProgressStasher = function (
    CartStashHelperService,
    hydrator,
    UserService,
    UserSessionManager
) {
    this.hydrator = hydrator;
    this.cartStashHelper = CartStashHelperService;
    this.userService = UserService;
    this.userSessionManager = UserSessionManager;

    /**
     * This is the object that gets cached.
     *
     * @type {DigiTickets.OrderInProgress}
     */
    this.orderInProgress = new DigiTickets.OrderInProgress();
};

DigiTickets.OrderInProgressStasher.prototype = {
    /**
     * Load the structure from the cache.
     *
     * @return {Promise<?DigiTickets.OrderInProgress>}
     */
    async load() {
        this.reset();

        return this.userSessionManager.getSession(this.userService.ID)
            .then(
                /**
                 * @param {?UserSession} currentUserSession
                 */
                (currentUserSession) => {
                    if (currentUserSession && currentUserSession.orderInProgress) {
                        this.orderInProgress = currentUserSession.orderInProgress;
                    }
                    return this.orderInProgress;
                }
            );
    },

    /**
     * @param {?CartService} cart
     */
    setCart: function (cart) {
        this.orderInProgress.stashedCart = cart ? this.cartStashHelper.cartToStash(cart) : null;
        this.saveCache();
    },

    setState: function setState(state) {
        this.orderInProgress.state = state;
        this.saveCache();
    },

    /**
     * @param {DigiTickets.TransactionInProgress|null} transactionInProgress
     */
    setTransactionInProgress: function setTransactionInProgress(transactionInProgress) {
        this.orderInProgress.transactionInProgress = transactionInProgress;
        this.saveCache();
    },

    clearTransactionInProgress: function clearTransactionInProgress() {
        this.orderInProgress.transactionInProgress = null;
        this.saveCache();
    },

    /**
     * Reset the structure and persist to the cache.
     */
    clear: function clear() {
        this.reset();
        this.saveCache();
    },

    /**
     * Reset the structure but do not persist the change to the cache.
     */
    reset: function reset() {
        this.orderInProgress = new DigiTickets.OrderInProgress();
    },

    /**
     * @return {boolean}
     */
    hasOrderInProgress: function hasOrderInProgress() {
        return !!(this.orderInProgress && this.orderInProgress.stashedCart !== null);
    },

    saveCache: function saveCache() {
        this.userSessionManager.saveSession(
            this.userService.getUserID(),
            {
                orderInProgress: this.orderInProgress
            }
        );
    }
};
