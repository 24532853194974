const is = require('../../Is');
const FieldInstance = require('./FieldInstance');
const FieldInstanceFilter = require('./FieldInstanceFilter');
const { populate } = require('../../../functions/objects');
const { toString, toNullableInt } = require('../../../functions/transform');

/**
 * Groups a number of FieldInstances together.
 * Used to keep order / same item instance fields grouped together.
 *
 * @param {{level:string, title?:string, subtitle?:string, lineNumber?:number, itemInstance?:number}} properties
 */
const FieldGroup = function (
    properties = {}
) {
    /**
     * Heading to display when outputting these fields.
     *
     * @type {string}
     */
    this.title = properties.title || '';

    /**
     * Subheading to display when outputting these fields.
     *
     * @type {string}
     */
    this.subtitle = properties.subtitle || '';

    /**
     * Array of FieldInstances
     *
     * @type {FieldInstance[]}
     */
    this.instances = [];

    /**
     * Flag showing if at least one of the Fields in the group must be entered.
     * (Set automatically when adding to the group)
     *
     * @type {boolean}
     */
    this.hasRequiredFields = false;

    /**
     * Flag showing if at least one of the Fields in the group must be asked early.
     * (Set automatically when adding to the group)
     *
     * @type {boolean}
     */
    this.hasAskEarlyFields = false;

    /**
     * Flag showing if at least one of the Fields in the group does not require to be asked early.
     * (Set automatically when adding to the group)
     *
     * @type {boolean}
     */
    this.hasNonAskEarlyFields = false;

    // The following properties are used to pick out this group from a list of other groups by specifying what this
    // group belongs to (the order level, or the item instance number).

    /**
     * What level of Fields (order/item) this group contains.
     *
     * @type {String}
     */
    this.level = null;

    /**
     * If this group contains item level fields, which line it relates to.
     *
     * @type {?Number}
     */
    this.lineNumber = null;

    /**
     * If this group contains item level fields, which item instance on the line it relates to.
     *
     * @type {?Number}
     */
    this.itemInstance = null;

    /**
     * If this group contains item level fields, which item instance it relates to.
     *
     * @type {?Number}
     */
    this.itemInstanceID = null;

    populate(this, properties);
};

FieldGroup.prototype = {
    /**
     * @param {?number} lineNumber
     */
    setLineNumber(lineNumber) {
        this.lineNumber = lineNumber;
        this.instances.forEach((fieldInstance) => {
            fieldInstance.setLineNumber(lineNumber);
        });
    },

    /**
     * @param {?number} itemInstanceNumber
     */
    setItemInstance(itemInstanceNumber) {
        this.itemInstance = itemInstanceNumber;
        this.instances.forEach((fieldInstance) => {
            fieldInstance.setItemInstance(itemInstanceNumber);
        });
    },

    /**
     * Add a FieldInstance to the group.
     *
     * @param {FieldInstance} instance
     *
     * @return {FieldGroup}
     */
    add(instance) {
        // FIXME: This would be a good idea but it breaks lots of tests.
        // instance.setLineNumber(this.lineNumber);
        // instance.setItemInstance(this.itemInstance);

        if (instance.field.required) {
            this.hasRequiredFields = true;
        }

        if (instance.field.askEarly) {
            this.hasAskEarlyFields = true;
        } else {
            this.hasNonAskEarlyFields = true;
        }

        this.instances.push(instance);
        this.sortFields();

        return this;
    },

    /**
     * Check if at least one of the fields in the group has a value.
     *
     * @return {boolean}
     */
    hasInstancesWithValue() {
        return this.instances.filter((i) => i.hasValue).length > 0;
    },

    /**
     * @return {FieldInstance[]}
     */
    getAskEarlyInstancesWithValue() {
        return this.instances
            .filter(FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY)
            .filter((i) => i.hasValue);
    },

    /**
     * Sort the FieldInstances in this group by the navOrder of the Fields.
     */
    sortFields() {
        // Order the fields by navOrder
        this.instances.sort((a, b) => {
            if (a.field.navOrder === b.field.navOrder) {
                return 0;
            }

            return a.field.navOrder > b.field.navOrder ? 1 : -1;
        });
    },

    /**
     * Returns a summary of the value of all FieldInstances in this group.
     *
     * @return {Array<{fieldID: number, question: string, answer: string[]}>}
     */
    getDisplayValues() {
        return this.instances
            .map((instance) => {
                let field = instance.getField();
                if (!field) {
                    return null;
                }

                let value = instance.getDisplayValue();
                if (!is.anArray(value)) {
                    value = [value];
                }

                return {
                    fieldID: field.ID,
                    question: field.name,
                    answer: value
                };
            })
            .filter((value) => value !== null);
    },

    getHydrationMap() {
        return {
            instances: {
                modelCollection: FieldInstance
            },
            itemInstance: toNullableInt,
            itemInstanceID: toNullableInt,
            level: toString,
            lineNumber: toNullableInt,
            subtitle: toString,
            title: toString
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldGroup;
}
