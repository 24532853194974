// TODO: This is temporary. When we have a module system we can import {} this list from a separate .json file.
const countryList = [
    { code: 'AF', countryID: 3, name: 'Afghanistan' },
    { code: 'AL', countryID: 6, name: 'Albania' },
    { code: 'DZ', countryID: 60, name: 'Algeria' },
    { code: 'AS', countryID: 12, name: 'American Samoa' },
    { code: 'AD', countryID: 1, name: 'Andorra' },
    { code: 'AO', countryID: 9, name: 'Angola' },
    { code: 'AI', countryID: 5, name: 'Anguilla' },
    { code: 'AQ', countryID: 10, name: 'Antarctica' },
    { code: 'AG', countryID: 4, name: 'Antigua and Barbuda' },
    { code: 'AR', countryID: 11, name: 'Argentina' },
    { code: 'AM', countryID: 7, name: 'Armenia' },
    { code: 'AW', countryID: 15, name: 'Aruba' },
    { code: 'AU', countryID: 14, name: 'Australia' },
    { code: 'AT', countryID: 13, name: 'Austria' },
    { code: 'AZ', countryID: 16, name: 'Azerbaijan' },
    { code: 'BS', countryID: 30, name: 'Bahamas' },
    { code: 'BH', countryID: 23, name: 'Bahrain' },
    { code: 'BD', countryID: 19, name: 'Bangladesh' },
    { code: 'BB', countryID: 18, name: 'Barbados' },
    { code: 'BY', countryID: 34, name: 'Belarus' },
    { code: 'BE', countryID: 20, name: 'Belgium' },
    { code: 'BZ', countryID: 35, name: 'Belize' },
    { code: 'BJ', countryID: 25, name: 'Benin' },
    { code: 'BM', countryID: 26, name: 'Bermuda' },
    { code: 'BT', countryID: 31, name: 'Bhutan' },
    { code: 'BO', countryID: 28, name: 'Bolivia' },
    { code: 'BA', countryID: 17, name: 'Bosnia and Herzegovina' },
    { code: 'BW', countryID: 33, name: 'Botswana' },
    { code: 'BV', countryID: 32, name: 'Bouvet Island' },
    { code: 'BR', countryID: 29, name: 'Brazil' },
    { code: 'IO', countryID: 102, name: 'British Indian Ocean Territory' },
    { code: 'BN', countryID: 27, name: 'Brunei Darussalam' },
    { code: 'BG', countryID: 22, name: 'Bulgaria' },
    { code: 'BF', countryID: 21, name: 'Burkina Faso' },
    { code: 'BI', countryID: 24, name: 'Burundi' },
    { code: 'KH', countryID: 112, name: 'Cambodia' },
    { code: 'CM', countryID: 45, name: 'Cameroon' },
    { code: 'CA', countryID: 36, name: 'Canada' },
    { code: 'CV', countryID: 51, name: 'Cape Verde' },
    { code: 'KY', countryID: 119, name: 'Cayman Islands' },
    { code: 'CF', countryID: 39, name: 'Central African Republic' },
    { code: 'TD', countryID: 205, name: 'Chad' },
    { code: 'CL', countryID: 44, name: 'Chile' },
    { code: 'CN', countryID: 46, name: 'China' },
    { code: 'CX', countryID: 52, name: 'Christmas Island' },
    { code: 'CC', countryID: 37, name: 'Cocos (keeling) Islands' },
    { code: 'CO', countryID: 47, name: 'Colombia' },
    { code: 'KM', countryID: 114, name: 'Comoros' },
    { code: 'CG', countryID: 40, name: 'Congo' },
    { code: 'CD', countryID: 38, name: 'Congo, Democratic Republic' },
    { code: 'CK', countryID: 43, name: 'Cook Islands' },
    { code: 'CR', countryID: 48, name: 'Costa Rica' },
    { code: 'CI', countryID: 42, name: 'Cote D\'ivoire' },
    { code: 'HR', countryID: 95, name: 'Croatia' },
    { code: 'CU', countryID: 50, name: 'Cuba' },
    { code: 'CY', countryID: 53, name: 'Cyprus' },
    { code: 'CZ', countryID: 54, name: 'Czech Republic' },
    { code: 'DK', countryID: 57, name: 'Denmark' },
    { code: 'DJ', countryID: 56, name: 'Djibouti' },
    { code: 'DM', countryID: 58, name: 'Dominica' },
    { code: 'DO', countryID: 59, name: 'Dominican Republic' },
    { code: 'EC', countryID: 61, name: 'Ecuador' },
    { code: 'EG', countryID: 63, name: 'Egypt' },
    { code: 'SV', countryID: 201, name: 'El Salvador' },
    { code: 'GQ', countryID: 85, name: 'Equatorial Guinea' },
    { code: 'ER', countryID: 65, name: 'Eritrea' },
    { code: 'EE', countryID: 62, name: 'Estonia' },
    { code: 'ET', countryID: 67, name: 'Ethiopia' },
    { code: 'FK', countryID: 70, name: 'Falkland Islands (malvinas)' },
    { code: 'FO', countryID: 72, name: 'Faroe Islands' },
    { code: 'FJ', countryID: 69, name: 'Fiji' },
    { code: 'FI', countryID: 68, name: 'Finland' },
    { code: 'FR', countryID: 73, name: 'France' },
    { code: 'GF', countryID: 78, name: 'French Guiana' },
    { code: 'PF', countryID: 168, name: 'French Polynesia' },
    { code: 'TF', countryID: 206, name: 'French Southern Territories' },
    { code: 'GA', countryID: 74, name: 'Gabon' },
    { code: 'GM', countryID: 82, name: 'Gambia' },
    { code: 'GE', countryID: 77, name: 'Georgia' },
    { code: 'DE', countryID: 55, name: 'Germany' },
    { code: 'GH', countryID: 79, name: 'Ghana' },
    { code: 'GI', countryID: 80, name: 'Gibraltar' },
    { code: 'GR', countryID: 86, name: 'Greece' },
    { code: 'GL', countryID: 81, name: 'Greenland' },
    { code: 'GD', countryID: 76, name: 'Grenada' },
    { code: 'GP', countryID: 84, name: 'Guadeloupe' },
    { code: 'GU', countryID: 89, name: 'Guam' },
    { code: 'GT', countryID: 88, name: 'Guatemala' },
    { code: 'GN', countryID: 83, name: 'Guinea' },
    { code: 'GW', countryID: 90, name: 'Guinea-bissau' },
    { code: 'GY', countryID: 91, name: 'Guyana' },
    { code: 'HT', countryID: 96, name: 'Haiti' },
    { code: 'HM', countryID: 93, name: 'Heard Island and Mcdonald Islands' },
    { code: 'VA', countryID: 226, name: 'Holy See (vatican City State)' },
    { code: 'HN', countryID: 94, name: 'Honduras' },
    { code: 'HK', countryID: 92, name: 'Hong Kong' },
    { code: 'HU', countryID: 97, name: 'Hungary' },
    { code: 'IS', countryID: 105, name: 'Iceland' },
    { code: 'IN', countryID: 101, name: 'India' },
    { code: 'ID', countryID: 98, name: 'Indonesia' },
    { code: 'IR', countryID: 104, name: 'Iran, Islamic Republic Of' },
    { code: 'IQ', countryID: 103, name: 'Iraq' },
    { code: 'IE', countryID: 99, name: 'Ireland' },
    { code: 'IL', countryID: 100, name: 'Israel' },
    { code: 'IT', countryID: 106, name: 'Italy' },
    { code: 'JM', countryID: 107, name: 'Jamaica' },
    { code: 'JP', countryID: 109, name: 'Japan' },
    { code: 'JO', countryID: 108, name: 'Jordan' },
    { code: 'KZ', countryID: 120, name: 'Kazakhstan' },
    { code: 'KE', countryID: 110, name: 'Kenya' },
    { code: 'KI', countryID: 113, name: 'Kiribati' },
    { code: 'KP', countryID: 116, name: 'Korea, Democratic People\'s Republic Of' },
    { code: 'KR', countryID: 117, name: 'Korea, Republic Of' },
    { code: 'KW', countryID: 118, name: 'Kuwait' },
    { code: 'KG', countryID: 111, name: 'Kyrgyzstan' },
    { code: 'LA', countryID: 121, name: 'Lao People\'s Democratic Republic' },
    { code: 'LV', countryID: 130, name: 'Latvia' },
    { code: 'LB', countryID: 122, name: 'Lebanon' },
    { code: 'LS', countryID: 127, name: 'Lesotho' },
    { code: 'LR', countryID: 126, name: 'Liberia' },
    { code: 'LY', countryID: 131, name: 'Libyan Arab Jamahiriya' },
    { code: 'LI', countryID: 124, name: 'Liechtenstein' },
    { code: 'LT', countryID: 128, name: 'Lithuania' },
    { code: 'LU', countryID: 129, name: 'Luxembourg' },
    { code: 'MO', countryID: 141, name: 'Macao' },
    { code: 'MK', countryID: 137, name: 'Macedonia, Former Yugoslav Republic' },
    { code: 'MG', countryID: 135, name: 'Madagascar' },
    { code: 'MW', countryID: 149, name: 'Malawi' },
    { code: 'MY', countryID: 151, name: 'Malaysia' },
    { code: 'MV', countryID: 148, name: 'Maldives' },
    { code: 'ML', countryID: 138, name: 'Mali' },
    { code: 'MT', countryID: 146, name: 'Malta' },
    { code: 'MH', countryID: 136, name: 'Marshall Islands' },
    { code: 'MQ', countryID: 143, name: 'Martinique' },
    { code: 'MR', countryID: 144, name: 'Mauritania' },
    { code: 'MU', countryID: 147, name: 'Mauritius' },
    { code: 'YT', countryID: 236, name: 'Mayotte' },
    { code: 'MX', countryID: 150, name: 'Mexico' },
    { code: 'FM', countryID: 71, name: 'Micronesia, Federated States' },
    { code: 'MD', countryID: 134, name: 'Moldova, Republic Of' },
    { code: 'MC', countryID: 133, name: 'Monaco' },
    { code: 'MN', countryID: 140, name: 'Mongolia' },
    { code: 'MS', countryID: 145, name: 'Montserrat' },
    { code: 'MA', countryID: 132, name: 'Morocco' },
    { code: 'MZ', countryID: 152, name: 'Mozambique' },
    { code: 'MM', countryID: 139, name: 'Myanmar' },
    { code: 'NA', countryID: 153, name: 'Namibia' },
    { code: 'NR', countryID: 162, name: 'Nauru' },
    { code: 'NP', countryID: 161, name: 'Nepal' },
    { code: 'NL', countryID: 159, name: 'Netherlands' },
    { code: 'AN', countryID: 8, name: 'Netherlands Antilles' },
    { code: 'NC', countryID: 154, name: 'New Caledonia' },
    { code: 'NZ', countryID: 164, name: 'New Zealand' },
    { code: 'NI', countryID: 158, name: 'Nicaragua' },
    { code: 'NE', countryID: 155, name: 'Niger' },
    { code: 'NG', countryID: 157, name: 'Nigeria' },
    { code: 'NU', countryID: 163, name: 'Niue' },
    { code: 'NF', countryID: 156, name: 'Norfolk Island' },
    { code: 'MP', countryID: 142, name: 'Northern Mariana Islands' },
    { code: 'NO', countryID: 160, name: 'Norway' },
    { code: 'OM', countryID: 165, name: 'Oman' },
    { code: 'PK', countryID: 171, name: 'Pakistan' },
    { code: 'PW', countryID: 178, name: 'Palau' },
    { code: 'PS', countryID: 176, name: 'Palestinian Territory, Occupied' },
    { code: 'PA', countryID: 166, name: 'Panama' },
    { code: 'PG', countryID: 169, name: 'Papua New Guinea' },
    { code: 'PY', countryID: 179, name: 'Paraguay' },
    { code: 'PE', countryID: 167, name: 'Peru' },
    { code: 'PH', countryID: 170, name: 'Philippines' },
    { code: 'PN', countryID: 174, name: 'Pitcairn' },
    { code: 'PL', countryID: 172, name: 'Poland' },
    { code: 'PT', countryID: 177, name: 'Portugal' },
    { code: 'PR', countryID: 175, name: 'Puerto Rico' },
    { code: 'QA', countryID: 180, name: 'Qatar' },
    { code: 'RE', countryID: 181, name: 'Reunion' },
    { code: 'RO', countryID: 182, name: 'Romania' },
    { code: 'RU', countryID: 183, name: 'Russian Federation' },
    { code: 'RW', countryID: 184, name: 'Rwanda' },
    { code: 'GS', countryID: 87, name: 'S. Georgia and S. Sandwich Islands' },
    { code: 'SH', countryID: 191, name: 'Saint Helena' },
    { code: 'KN', countryID: 115, name: 'Saint Kitts and Nevis' },
    { code: 'LC', countryID: 123, name: 'Saint Lucia' },
    { code: 'PM', countryID: 173, name: 'Saint Pierre and Miquelon' },
    { code: 'VC', countryID: 227, name: 'Saint Vincent and The Grenadines' },
    { code: 'WS', countryID: 234, name: 'Samoa' },
    { code: 'SM', countryID: 196, name: 'San Marino' },
    { code: 'ST', countryID: 200, name: 'Sao Tome and Principe' },
    { code: 'SA', countryID: 185, name: 'Saudi Arabia' },
    { code: 'SN', countryID: 197, name: 'Senegal' },
    { code: 'CS', countryID: 49, name: 'Serbia and Montenegro' },
    { code: 'SC', countryID: 187, name: 'Seychelles' },
    { code: 'SL', countryID: 195, name: 'Sierra Leone' },
    { code: 'SG', countryID: 190, name: 'Singapore' },
    { code: 'SK', countryID: 194, name: 'Slovakia' },
    { code: 'SI', countryID: 192, name: 'Slovenia' },
    { code: 'SB', countryID: 186, name: 'Solomon Islands' },
    { code: 'SO', countryID: 198, name: 'Somalia' },
    { code: 'ZA', countryID: 237, name: 'South Africa' },
    { code: 'ES', countryID: 66, name: 'Spain' },
    { code: 'LK', countryID: 125, name: 'Sri Lanka' },
    { code: 'SD', countryID: 188, name: 'Sudan' },
    { code: 'SR', countryID: 199, name: 'Suriname' },
    { code: 'SJ', countryID: 193, name: 'Svalbard and Jan Mayen' },
    { code: 'SZ', countryID: 203, name: 'Swaziland' },
    { code: 'SE', countryID: 189, name: 'Sweden' },
    { code: 'CH', countryID: 41, name: 'Switzerland' },
    { code: 'SY', countryID: 202, name: 'Syrian Arab Republic' },
    { code: 'TW', countryID: 218, name: 'Taiwan, Province Of China' },
    { code: 'TJ', countryID: 209, name: 'Tajikistan' },
    { code: 'TZ', countryID: 219, name: 'Tanzania, United Republic Of' },
    { code: 'TH', countryID: 208, name: 'Thailand' },
    { code: 'TL', countryID: 211, name: 'Timor-leste' },
    { code: 'TG', countryID: 207, name: 'Togo' },
    { code: 'TK', countryID: 210, name: 'Tokelau' },
    { code: 'TO', countryID: 214, name: 'Tonga' },
    { code: 'TT', countryID: 216, name: 'Trinidad and Tobago' },
    { code: 'TN', countryID: 213, name: 'Tunisia' },
    { code: 'TR', countryID: 215, name: 'Turkey' },
    { code: 'TM', countryID: 212, name: 'Turkmenistan' },
    { code: 'TC', countryID: 204, name: 'Turks and Caicos Islands' },
    { code: 'TV', countryID: 217, name: 'Tuvalu' },
    { code: 'UG', countryID: 221, name: 'Uganda' },
    { code: 'UA', countryID: 220, name: 'Ukraine' },
    { code: 'AE', countryID: 2, name: 'United Arab Emirates' },
    { code: 'GB', countryID: 75, name: 'United Kingdom' },
    { code: 'US', countryID: 223, name: 'United States' },
    { code: 'UM', countryID: 222, name: 'United States Minor Outlying Islands' },
    { code: 'UY', countryID: 224, name: 'Uruguay' },
    { code: 'UZ', countryID: 225, name: 'Uzbekistan' },
    { code: 'VU', countryID: 232, name: 'Vanuatu' },
    { code: 'VE', countryID: 228, name: 'Venezuela' },
    { code: 'VN', countryID: 231, name: 'Viet Nam' },
    { code: 'VG', countryID: 229, name: 'Virgin Islands, British' },
    { code: 'VI', countryID: 230, name: 'Virgin Islands, U.s.' },
    { code: 'WF', countryID: 233, name: 'Wallis and Futuna' },
    { code: 'EH', countryID: 64, name: 'Western Sahara' },
    { code: 'YE', countryID: 235, name: 'Yemen' },
    { code: 'ZM', countryID: 238, name: 'Zambia' },
    { code: 'ZW', countryID: 239, name: 'Zimbabwe' },
];

/**
 * @param {Hydrator} hydrator
 */
const CountryManager = function (
    hydrator
) {
    /**
     * Kept as an array not an object so the order the countries are defined in is preserved.
     *
     * You should not access this array directly as it may not be loaded yet.
     * Instead use:
     * getCountries().then(function (countries) { // });
     *
     * @private
     *
     * @type {DigiTickets.Country[]}
     */
    this.countries = hydrator.hydrateArray(
        countryList,
        () => new DigiTickets.Country()
    );
};

CountryManager.prototype = {
    getCountries() {
        return this.countries;
    },

    /**
     * @param {string} code
     * @return {?DigiTickets.Country}
     */
    findByCode(code) {
        return this.findByData({ code });
    },

    /**
     * Return a country that matches either a countryCode or countryID property in the supplied
     * object of data. The data object is used instead of simple arguments so we can match various forms of data we
     * know about.
     *
     * Data can contain any of these properties to look up:
     * code / countryCode / country (2 char string)
     * countryID / ID (number)
     * country (object containing code/countryCode/etc. as above)
     *
     * @param {object} data
     *
     * @return {?DigiTickets.Country}
     */
    findByData(data) {
        if (!data) {
            return null;
        }

        // Parse the data object to try and find a 2 letter code.
        let code = null;
        if (data.hasOwnProperty('code') && data.code) {
            code = data.code;
        } else if (data.hasOwnProperty('countryCode') && data.countryCode) {
            code = data.countryCode;
        } else if (data.hasOwnProperty('country') && data.country && typeof data.country === 'string') {
            code = data.country;
        } else if (data.hasOwnProperty('country') && data.country && typeof data.country === 'object') {
            // It may already be a country object {country: DigiTickets.Country}.
            // Try running findByData with that in case it has the properties we are looking for.
            return this.findByData(data.country);
        }

        // Parse the data object to find a country ID.
        let id = null;
        if (data.hasOwnProperty('countryID')) {
            id = data.countryID;
        } else if (data.hasOwnProperty('ID')) {
            id = data.ID;
        }

        if (!code && !id) {
            return null;
        }

        // Find a country that matches the country or ID.
        for (let i = 0; i < this.countries.length; i++) {
            if (code && this.countries[i].code === code) {
                return this.countries[i];
            }

            if (id && this.countries[i].ID === id) {
                return this.countries[i];
            }
        }

        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CountryManager;
}
