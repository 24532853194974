/**
 * Sometimes we want to persist simple pieces of data for which IndexedDB would be overkill.
 * This provides an interface to window.localStorage but does not use the angular-cache package and all its
 * compression/decompression overhead. This should not be used for large amounts of data, it is intended for simple
 * strings or similar.
 */
const LocalStorageDataStore = function () {
    /* istanbul ignore next */
    /**
     * Define localStorage as a property to make this testable.
     *
     * @type {Storage}
     */
    this.localStorage = typeof window !== 'undefined' ? window.localStorage : undefined;
};

LocalStorageDataStore.prototype = {
    /**
     * @param {string} key
     * @param {object} data
     */
    persist(key, data) {
        this.localStorage.setItem(key, JSON.stringify(data));
    },

    /**
     * @param {string} key
     *
     * @return {?object}
     */
    find(key) {
        return JSON.parse(this.localStorage.getItem(key));
    },

    /**
     * @param {string} key
     */
    remove(key) {
        this.localStorage.removeItem(key);
    },

    /**
     * Return the keys of all items in local storage.
     *
     * @return {string[]}
     */
    getKeys() {
        return Object.keys(this.localStorage);
    },

    /**
     * Clean old items from localStorage that should no longer be there.
     * (Old angular-cache keys)
     */
    clean() {
        const shouldCleanKey = (key) => key.indexOf('angular-cache.caches.userSession') === 0
                || key.indexOf('angular-cache.caches.sessionCache') === 0
                || key.indexOf('angular-cache.caches.userCache') === 0
                || key.indexOf('angular-cache.caches.syncMetadataCache') === 0
                || key.indexOf('angular-cache.caches.cssCache') === 0;

        this.getKeys().forEach((key) => {
            if (shouldCleanKey(key)) {
                console.warn(`Deleting old data from local storage: ${key}`);
                this.remove(key);
            }
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LocalStorageDataStore;
}
