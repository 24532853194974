const { toBool, toInt, toString } = require('../../../../functions/transform');

const VerifoneConfig = function () {
    DigiTickets.AbstractPaymentMethodConfig.call(this);

    /**
     * Allow cardholder not present transactions?
     *
     * @type {boolean}
     */
    this.allowCnp = true;

    /**
     * Port for the 'integration socket' connection to the terminal.
     *
     * @type {number}
     */
    this.terminalPort = 25000;

    /**
     * Port for the 'progress socket' connection to the terminal (VX820 only).
     * Only used in POS Client mode.
     *
     * @type {number}
     */
    this.terminalProgressPort = 25001;

    /**
     * Set to true to use 'POS Client' mode (regular VX820 terminal)
     * Set to false to use 'eVo' mode (VX820IP terminal)
     *
     * @type {boolean}
     */
    this.posClient = true;

    /**
     * An optional user ID to automatically login to the PED.
     * (Both userId and userPin must be supplied to login.)
     *
     * @type {Number}
     */
    this.userId = null;

    /**
     * An optional user PIN to automatically login to the PED.
     * (Both userId and userPin must be supplied to login.)
     *
     * @type {Number}
     */
    this.userPin = null;

    /**
     * The manager PIN is needed when logging in to the terminal if the user is different
     * from the last user that logged in. This should be set along with userId and userPin to make
     * auto login work correctly.
     *
     * @type {Number}
     */
    this.managerPin = null;

    /**
     * It is recommended to turn on 'Raw Printing' in the POS Client application as this results
     * in much better looking receipts.
     * However, with the Aures ODP333 at least, this makes the printer not cut the paper.
     *
     * To solve this, when a 'continue transaction' record is received we can print a small blank
     * document so the paper gets cut.
     *
     * A bonus of this is it automatically continues the transaction too and the operator does not need
     * to press the button.
     *
     * @type {boolean}
     */
    this.improvePrinting = true;

    /**
     * Automatically send the transaction to the terminal when the Verifone payment tab is loaded?
     *
     * @type {boolean}
     */
    this.autoStart = this.posClient;
};

VerifoneConfig.prototype = Object.create(DigiTickets.AbstractPaymentMethodConfig.prototype, {});

VerifoneConfig.prototype.getHydrationMap = function () {
    return {
        improvePrinting: toBool,
        managerPin: toInt,
        posClient: toBool,
        proxyHost: toString,
        proxyPort: toInt,
        proxyRestartHour: toInt,
        secureProxy: toBool,
        socketEncoding: toString,
        terminalHost: toString,
        terminalPort: toInt,
        terminalProgressPort: toInt,
        userId: toString,
        userPin: toString
    };
};

/**
 * @return {boolean}
 */
VerifoneConfig.prototype.hasLoginCredentials = function () {
    return this.userId !== null && this.userPin !== null;
};

VerifoneConfig.prototype.constructor = VerifoneConfig;

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneConfig;
}
