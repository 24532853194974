/**
 * @param $location
 * @param $scope
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {DeviceManager} deviceManager
 * @param {NavigationService} navigationService
 * @param {UserService} UserService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param {CurrentDevice} CurrentDevice
 */
const DeviceCtrl = function (
    $location,
    $scope,
    AppConfig,
    deviceManager,
    navigationService,
    UserService,
    TradingSessionManager,
    CurrentDevice
) {
    navigationService.showNav();

    $scope.errorMessage = null;
    $scope.selectedDevice = null;
    $scope.deviceManager = deviceManager;
    $scope.devices = [];
    $scope.user = UserService;
    $scope.appConfig = AppConfig;
    $scope.processingMessage = 'DEVICE.PROCESSING_LOAD';
    $scope.tradingSessionManager = TradingSessionManager;

    $scope.selectDevice = function selectDevice(device) {
        $scope.selectedDevice = device;
    };

    /**
     * Handler for clicking the select button on the device page.
     */
    $scope.confirm = function confirm() {
        $scope.processingMessage = 'DEVICE.PROCESSING_SELECT';
        // Make an API request to assign this browser's GUID to the device.
        $scope.deviceManager.assignDevice($scope.selectedDevice).then(
            function () {
                $scope.errorMessage = null;
                if (CurrentDevice.isSet()) {
                    // Device has been assigned and updated by the deviceManager

                    let device = CurrentDevice.device;

                    // Switch to the device's default branch if the currently logged in user has access.
                    if (device.defaultBranch) {
                        console.log('Device has default branch. Attempting to switch.', device.defaultBranch.ID);
                        let branchFromCurrentUser = UserService.getBranchByID(device.defaultBranch.ID);
                        if (branchFromCurrentUser) {
                            console.log('User has access to default branch. Switching', branchFromCurrentUser);
                            UserService.setBranch(branchFromCurrentUser);
                        }
                    }

                    // Go back to index to continue to startup flow.
                    navigationService.viewEposIndex();
                }
            },
            function (response) {
                $scope.processingMessage = '';
                $scope.errorMessage = response.result;
            }
        );
    };

    $scope.hasDevices = function hasDevices() {
        return !!$scope.devices.length;
    };

    if (CurrentDevice.isSet()) {
        navigationService.viewEposIndex();
    } else {
        $scope.deviceManager.loadDevices().then(function (devices) {
            $scope.devices = devices;
            $scope.processingMessage = '';
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DeviceCtrl;
}
