/**
 * Handles things to do with redeeming gift vouchers that are already sold (SoldGiftVouchers).
 *
 * @param {Hydrator} hydrator
 * @param SoldGiftVoucherResource
 */
const SoldGiftVoucherManager = function (
    hydrator,
    SoldGiftVoucherResource
) {
    this.hydrator = hydrator;
    this.soldGiftVoucherResource = SoldGiftVoucherResource;
};

SoldGiftVoucherManager.prototype = {
    /**
     * @param {string} ref
     * @param {function} callback
     * @return {DigiTickets.SoldGiftVoucher|null}
     */
    findByRef: function findByRef(ref, callback) {
        const self = this;
        this.soldGiftVoucherResource.findByRef(
            {
                ref
            },
            function success(response) {
                if (response && response.length > 0) {
                    const soldGiftVoucher = self.hydrator.hydrate(response[0], new DigiTickets.SoldGiftVoucher());
                    callback(soldGiftVoucher);
                } else {
                    callback(null);
                }
            },
            function failure() {
                callback(null);
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SoldGiftVoucherManager;
}
