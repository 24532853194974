const AgentPrintDriver = require('./Drivers/AgentPrintDriver');
const DummyPrintDriver = require('./Drivers/DummyPrintDriver');
const printCssString = require('@temp/js/printCss.js');
const PrintDriverRefs = require('./PrintDriverRefs');
const PrinterAppPrintDriver = require('./Drivers/PrinterAppPrintDriver');
const PrintJobOptions = require('./PrintJobOptions');
const PrintRouting = require('./PrintRouting');

/**
 * Gives us the facility to print from our application without worrying about the logistics of doing so.
 *
 * @param $injector
 * @param $rootScope
 * @param $templateCache
 * @param $timeout
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.Logger} Logger
 * @param {PrintRouter} PrintRouter
 * @param {TemplateCompiler} templateCompiler
 */
function Printer(
    $injector,
    $rootScope,
    $templateCache,
    $timeout,
    CurrentDevice,
    Logger,
    PrintRouter,
    templateCompiler
) {
    /**
     * Has a document been sent for printing but its promise hasn't resolved yet?
     *
     * @type {boolean}
     */
    let isPrinting = false;

    /**
     * Reference to the #print-iframe in the document.
     *
     * @type {Window}
     */
    this.printFrame = typeof window !== 'undefined' ? window.frames['print-iframe'] : null;

    /**
     * Items that are queued up to print.
     *
     * @type {{content: string, opts: PrintJobOptions}[]}
     */
    let printQueue = [];

    const printNext = () => {
        const queuedItem = printQueue.shift();
        if (queuedItem) {
            isPrinting = true;
            printContent(
                queuedItem.content,
                queuedItem.opts
            ).then(
                // success
                () => printNext(),
                // failure
                () => printNext()
            );
        } else {
            isPrinting = false;
        }
    };

    /**
     * @param {string} content
     * @param {PrintJobOptions} opts
     */
    const addToPrintQueue = (content, opts) => {
        printQueue.push({ content, opts });
        if (!isPrinting) {
            printNext();
        }
    };

    const isPrintQueueEmpty = () => !printQueue.length;

    /**
     * @param {string} content
     * @param {PrintJobOptions} opts
     *
     * @return {*|promise|{then, catch, finally}|jQuery.promise}
     * @private
     */
    const printContent = (content, opts) => new Promise(async (resolve, reject) => {
        if (!opts) {
            opts = new PrintJobOptions('');
        }

        opts.printRouting = PrintRouter.getRoutingForType(opts.printType);

        // Get the css for the print method and driver reference.
        if (!opts.printRouting.driverRef) {
            // Fall back to the old way of using the print method on the device.
            opts.printRouting = new PrintRouting(CurrentDevice.device.printMethod.driverRef.toLowerCase());
        }

        // Determine which print driver to use.
        // This happens here instead of in the constructor because the current device
        // may not be available yet in the constructor.
        let printDriverClass;

        switch (opts.printRouting.driverRef) {
            case PrintDriverRefs.AGENT:
                printDriverClass = AgentPrintDriver;
                break;

            case PrintDriverRefs.DUMMY:
                printDriverClass = DummyPrintDriver;
                break;

            case PrintDriverRefs.PRINTER_APP:
                printDriverClass = PrinterAppPrintDriver;
                break;

            case PrintDriverRefs.STAR_2_INCH:
            case PrintDriverRefs.STAR_3_INCH:
                printDriverClass = DigiTickets.StarPrintDriver;
                break;

            case PrintDriverRefs.PRINT:
            default:
                printDriverClass = DigiTickets.PrintPrintDriver;
                break;
        }

        let printDriver = $injector.instantiate(printDriverClass);

        // printCssString is built by Grunt and contains the contents of the built print.css file
        // which comes from src/print/less/print.less
        this.printFrame.document.documentElement.innerHTML = content.replace('<!-- printCss -->', `<style type="text/css">${printCssString}</style>`);

        console.log('Printing', opts.printType, opts.printRouting.driverRef, opts.printRouting.printerName);

        printDriver.print(
            this.printFrame,
            opts
        ).then(
            () => {
                // Printed completed successfully.
                if (typeof opts.completion === 'function') {
                    opts.completion();
                }
                resolve();
            },
            () => {
                // Printing failed.
                Logger.error(
                    'Printing failed',
                    {
                        driverRef: opts.printRouting.driverRef
                    }
                );
                if (typeof opts.completion === 'function') {
                    opts.completion();
                }
                reject();
            }
        );
    });

    /**
     * @param {string} html
     * @param {PrintJobOptions} opts
     *
     * @return {Promise<any>}
     */
    const printHtmlString = (html, opts) => new Promise((resolve) => {
        if (!opts.hasOwnProperty('classes')) {
            opts.classes = [];
        }
        // Add the print method as a CSS class.
        let driverRef = CurrentDevice.device.printMethod.driverRef.toLowerCase();
        opts.classes.push('driver-' + driverRef);

        let footerPaddingEl = opts.addFooterDot !== false ? '<div id="footer-padding">.</div>' : '';

        // Because receipt templates are stored in the app repo it's annoying to change them.
        // They still only have <div id="receipt"> and are missing class="print-content"
        // This hack adds it in if it's missing.
        html = html.replace('<div id="receipt">', '<div id="receipt" class="print-content">');

        let documentHtml = `<html>
            <head>
            <!-- printCss -->
            </head>
            <body>
                <div id="printable" class="${opts.classes.join(' ')}">
                    ${html}
                    ${footerPaddingEl}
               </div>
            </body>
            </html>`;

        const builtDocument = document.implementation.createHTMLDocument('EPOS').documentElement.innerHTML = documentHtml;

        let originalCompletion = opts.completion;
        opts.completion = () => {
            // BC for a print callback being given in the 'PrintJobOptions' object.
            if (typeof originalCompletion === 'function') {
                originalCompletion();
            }
            resolve();
        };

        addToPrintQueue(builtDocument, opts);
    });

    /**
     * @param {string} templateContents A string containing the HTML content of a template.
     * @param {object} data The data used by the template for rendering.
     * @param {PrintJobOptions} opts
     *
     * @return {Promise<any>}
     */
    const printTemplate = (templateContents, data, opts) => templateCompiler
        .compile(templateContents, data)
        .then((compiledHtml) => printHtmlString(compiledHtml, opts));

    /**
     * @param {string} templatePath The path to the template relative to 'src' (typically 'print/templates/...').
     * @param {object} data The data used by the template for rendering.
     * @param opts
     * @param {PrintJobOptions} opts.
     * @return {Promise<any>}
     */
    const printLocalTemplate = (templatePath, data, opts) => {
        const templateContent = $templateCache.get(templatePath);
        return printTemplate(templateContent, data, opts);
    };

    this.printContent = printContent;
    this.printHtmlString = printHtmlString;
    this.printTemplate = printTemplate;
    this.printLocalTemplate = printLocalTemplate;
    this.isPrintQueueEmpty = isPrintQueueEmpty;
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Printer;
}
