const { toBool, toString } = require('../../../../functions/transform');

const OrderAdjustmentResponse = function () {
    /**
     * @type {?boolean}
     */
    this.success = null;

    /**
     * @type {?string}
     */
    this.message = null;

    /**
     * The adjustment order that was just created (a diff/delta from the original order to the desired state).
     *
     * @type {DigiTickets.Order}
     */
    this.adjustmentOrder = null;

    /**
     * The complete new order after adjustments have been applied.
     *
     * @type {DigiTickets.Order}
     */
    this.adjustedOrder = null;

    /**
     * This isn't returned from the API in the final spec of the adjustments endpoint, but we can
     * set it from the original order model we already had.
     *
     * @type {DigiTickets.Order}
     */
    this.originalOrder = null;
};

OrderAdjustmentResponse.prototype = {
    getHydrationMap() {
        return {
            success: toBool,
            message: toString,
            adjustmentOrder: {
                model: DigiTickets.Order
            },
            adjustedOrder: {
                model: DigiTickets.Order
            },
            originalOrder: {
                model: DigiTickets.Order
            }
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderAdjustmentResponse;
}
