/**
 * @param $compile
 * @param $rootScope
 * @param $timeout
 */
const TemplateCompiler = function (
    $compile,
    $rootScope,
    $timeout
) {
    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;

    /**
     * How long (in milliseconds) before a call to compile times out and rejects.
     *
     * @type {number}
     */
    this.compileTimeoutMs = 30000;
};

TemplateCompiler.prototype = {
    /**
     * Compile an Angular template string using the given data.
     *
     * @param {string} templateContents
     * @param {object} [data]
     *
     * @return {Promise<any>}
     */
    compile(templateContents, data) {
        return new Promise((resolve, reject) => {
            // Ensure data is an object.
            data = data || {};

            // $compile takes some time to run. Despite extensive searching there is no "it's done compiling"
            // callback to listen to. We used to just have a 100ms timeout here and that was sufficient, but
            // PRO-904 has shown that's not always the case. There is a strange delay to compilation that only
            // happens when printing tickets from the order details page.
            // https://stackoverflow.com/questions/39089533/angularjs-detect-when-compile-html-string-is-done
            // has run into the same problem where a 0ms timeout doesn't work but an arbitrary 250ms does. There's
            // no answer on that question.
            // Since there's no solution we'll make our own...

            // We are going to echo this variable in the template. Once it's in the created element's html
            // we know(?) that the compilation has finished.
            let compiledAtString = 'compiled-at-' + (new Date()).getTime();
            data.compiledAtString = compiledAtString;

            // Create a new wrapping div because $compile only works with one root element.
            // The div#compiled-template is so we can extract just what we wanted.
            let renderHtml = `<div><div id="compiled-template">${templateContents}</div>{{ compiledAtString }}</div>`;

            // Create a new Angular scope to be used for compilation.
            const scope = this.$rootScope.$new(true);
            // Add the passed in data to the new scope.
            Object.assign(scope.this, data);

            // Start compiling.
            const element = this.$compile(renderHtml)(scope);

            // Checks if (we think) the compilation has finished.
            const isCompiled = () => element
                && element[0].innerHTML.indexOf(compiledAtString) !== -1;

            // We'll give it some time to compile. Check after a short delay and recheck until
            // either we think the compilation has finished or we reach a maximum time.

            let delayBetweenChecks = 100; // ms
            let maxChecks = this.compileTimeoutMs / delayBetweenChecks;
            let checkCount = 0;

            const resolveIfCompiled = () => {
                ++checkCount;

                if (isCompiled()) {
                    let html = element[0].querySelector('#compiled-template').innerHTML;
                    resolve(html);
                    scope.$destroy();
                    return;
                }

                if (checkCount < maxChecks) {
                    setTimeout(resolveIfCompiled, delayBetweenChecks);
                } else {
                    reject(new Error(`Failed to compile template within ${maxChecks * delayBetweenChecks}ms`));
                }
            };

            setTimeout(resolveIfCompiled, delayBetweenChecks);
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TemplateCompiler;
}
