const _ = require('lodash');
const { isValidDateObject } = require('../../../js/functions/functions');

/**
 * @param $scope
 * @param {NavigationService} navigationService
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {OrderManager} OrderManagerService
 * @param {OrderSearchCache} orderSearchCache
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {RedemptionScanHandler} redemptionScanHandler
 */
const OrdersCtrl = function (
    $scope,
    navigationService,
    NotificationService,
    OrderManagerService,
    orderSearchCache,
    CurrentDevice,
    PrivilegesService,
    redemptionScanHandler
) {
    navigationService.showNav();

    // Early exit if no access permission
    PrivilegesService.requirePrivilegeOr403('access_redeem');

    $scope.navigationService = navigationService;

    $scope.pagination = {
        pageSize: 5,
        currentPage: 1,
        scrollElement: document.getElementsByTagName('html')[0]
    };

    $scope.error = false;

    $scope.startDate = {
        opened: false
    };

    $scope.endDate = {
        opened: false
    };

    $scope.minQueryLength = 3;

    $scope.orderSearchCache = orderSearchCache;
    $scope.order = {
        search: orderSearchCache.form.search,
        startDate: orderSearchCache.form.startDate,
        endDate: orderSearchCache.form.endDate,
        dateType: orderSearchCache.form.dateType,
        hasSearched: false,
        results: [],
        wasBarcodeScan: false,
        notes: orderSearchCache.form.notes
    };
    $scope.advancedSearch = !!orderSearchCache.form.advanced;

    $scope.showLoader = false;

    $scope.clear = function clear() {
        $scope.order = {
            search: '',
            startDate: null,
            endDate: null,
            dateType: 'order',
            hasSearched: false,
            results: [],
            wasBarcodeScan: false,
            notes: ''
        };
        // We should tell the search cache, too.
        orderSearchCache.clear();

        if ($(window).width() > 650) {
            $scope.focusSearchInput();
        }
    };

    $scope.isValidSearch = function isValidSearch(order) {
        return (!order.search || (order.search && order.search.length >= $scope.minQueryLength))
                    || (order.startDate)
                    || (order.endDate)
                    || (order.notes && order.notes.length >= $scope.minQueryLength);
    };

    $scope.searchKeyDown = function searchKeyDown() {
        $scope.order.wasBarcodeScan = false;
    };

    $scope.searchButtonClick = function searchButtonClick() {
        $scope.order.wasBarcodeScan = false;
        $scope.search();
    };

    // Any time search is invoked, we want to wait a very short time in case it's called
    // again. This is because when a bar code is scanned, the key presses are sent to the
    // browser, which invokes the search, then the bar code listener invokes the search.
    // The former may not have the right criteria in the form, so we want to suppress that
    // first search. We also don't want to run the same search multiple times!
    $scope.search = function search() {
        if (!$scope.isValidSearch($scope.order)) {
            NotificationService.error('MIN_QUERY_LENGTH_ERROR');
            return;
        }
        $scope.debouncedSearch();
    };

    $scope.uiState = {
        focusOnSearch: CurrentDevice.device.focusOnSearch
    };

    // On a Mobile device with a built in scanner e.g. CT-50 we can only capture the scan into an element with focus.
    // Unfortunately any element that has focus (except as a result of the initial onload) triggers the
    // virtual keyboard to appear, therefore the search field cannot permanently have focus.
    //
    // This method listens for the start of a scan and when it detects it set the focus to the search field
    // to capture the scan.
    //
    // On a CT-50 F11 is the code for the right scan button and F12 is the code for the left scan button.
    $scope.$on('keydownListener::keydown', function (event, originalEvent) {
        let searchInput = document.getElementById('search');
        let scanStartKeys = ['F11', 'F12'];
        if (scanStartKeys.includes(originalEvent.code)) {
            searchInput.focus();
            searchInput.value = '';
        }
    });

    $scope.focusSearchInput = function focusSearchInput() {
        let searchInput = document.getElementById('search');
        if (searchInput) {
            searchInput.focus();
            searchInput.select();
            searchInput.setSelectionRange(0, searchInput.value.length); // mobile safari;
        }
    };

    // This is mainly for Mobile devices, it removes the keyboard from taking up half the screen
    $scope.unfocusSearchInput = function unfocusSearchInput() {
        let searchInput = document.getElementById('search');
        if (searchInput) {
            searchInput.blur();
        }
    };

    $scope.doSearch = function doSearch() {
        $scope.error = false;
        orderSearchCache.clear();

        if (!$scope.isValidSearch($scope.order)) {
            return;
        }

        $scope.order.hasSearched = true;
        $scope.showLoader = true;
        $scope.order.results = [];

        OrderManagerService.search(
            $scope.order,
            function (results, wasOnlineSearch) {
                redemptionScanHandler.redirectFromSearchResults(
                    $scope.order.search,
                    results,
                    $scope.order.wasBarcodeScan
                );
                $scope.searchResultsLoaded(
                    $scope.order.search,
                    results,
                    wasOnlineSearch
                );
            },
            function (error) {
                $scope.order.wasBarcodeScan = false;
                $scope.error = 'An error occurred whilst searching, please check your internet connection.';
                $scope.showLoader = false;
                $scope.focusSearchInput();
            }
        );
    };

    $scope.debouncedSearch = _.debounce($scope.doSearch, 1000, {
        leading: true,
        trailing: false
    });

    /**
     * @param {DigiTickets.Order[]} results
     * @param {bool} wasOnlineSearch
     */
    const storeResultsInCache = (results, wasOnlineSearch) => {
        orderSearchCache.clear();
        orderSearchCache.form.search = $scope.order.search;
        orderSearchCache.form.notes = $scope.order.notes;
        orderSearchCache.form.startDate = $scope.order.startDate;
        orderSearchCache.form.endDate = $scope.order.endDate;
        orderSearchCache.form.dateType = $scope.order.dateType;
        orderSearchCache.form.advanced = !!$scope.advancedSearch;
        orderSearchCache.results = results;
        orderSearchCache.onlineResult = wasOnlineSearch;
    };

    $scope.searchResultsLoaded = function searchResultsLoaded(query, results, wasOnlineSearch) {
        // Remember the results in case we come back, and prefill the search criteria.
        storeResultsInCache(results, wasOnlineSearch);

        $scope.displaySearchResults(results);

        $scope.showLoader = false;

        // If screen is small enough to be a mobile device we don't want to leave the focus on the input
        // as the keyboard takes up half the screen.
        if ($(window).width() > 650) {
            $scope.focusSearchInput();
        } else {
            $scope.unfocusSearchInput();
        }
    };

    /**
     * @param {DigiTickets.Order[]} results
     */
    $scope.displaySearchResults = function displaySearchResults(results) {
        // Remove any orders that contain nothing but 'Generic' items.
        // This could be filtered by the API, but editing the OrderSearch class would require a PHD...
        $scope.order.results = results.filter(
            function (order) {
                let itemTypes = Object.keys(order.itemGroups);
                return itemTypes.length > 1 || itemTypes[0] !== DigiTickets.ItemType.GENERIC;
            }
        );
    };

    /**
     * Makes sure that the end date is never in the past in relation to the start date, as
     * it won't make any sense.
     */
    $scope.$watch('order.startDate', function (newValue) {
        if ($scope.order.endDate == null || $scope.order.endDate == '') {
            $scope.order.endDate = newValue;
        }

        if (isValidDateObject(newValue)) {
            if ($scope.order.endDate.getTime() < $scope.order.startDate.getTime()) {
                $scope.order.endDate = $scope.order.startDate;
            }
        }

        $scope.endDate.minDate = $scope.order.startDate;
    });

    $scope.openStartDatePicker = function openStartDatePicker($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.endDate.opened = false;
        $scope.startDate.opened = true;
    };

    $scope.openEndDatePicker = function openEndDatePicker($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.startDate.opened = false;
        $scope.endDate.opened = true;
    };

    // Handler to clear the start date.
    $scope.clearStartDate = function clearStartDate($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.order.startDate = null;
    };

    // Handler to clear the end date.
    $scope.clearEndDate = function clearEndDate($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.order.endDate = null;
    };

    // Handler to clear the notes.
    $scope.clearNotes = function clearNotes($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.order.notes = '';
    };

    // Toggle the display of the advanced search options when the user clicks the prompt.
    $scope.toggleAdvancedSearch = function toggleAdvancedSearch() {
        $scope.advancedSearch = !$scope.advancedSearch;
        if ($scope.advancedSearch === false) {
            // reset any 'advanced' fields
            $scope.order.startDate = null;
            $scope.order.endDate = null;
            $scope.order.notes = '';
        }
    };

    // Process the bar code if scanned.
    $scope.$on('barcodeScanned', function (event, response) {
        if (response.openModal !== false) {
            // Don't do anything on this page if a modal is open on top of it.
            return;
        }
        let barcode = response.code;
        if (barcode.length > 0) {
            $scope.order = {
                search: barcode,
                startDate: null,
                endDate: null,
                dateType: 'order',
                hasSearched: false,
                results: [],
                wasBarcodeScan: true
            };
            $scope.search();
        }
    });

    // If the results are cached, show them now.
    if (orderSearchCache.results !== null) {
        $scope.displaySearchResults(orderSearchCache.results);

        if (redemptionScanHandler.pullLastScannedMemberRef() === $scope.order.search) {
            $scope.order.wasBarcodeScan = true;
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrdersCtrl;
}
