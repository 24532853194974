const apiErrorMessage = require('./Api/apiErrorMessage');

/**
 * @param $q
 * @param $rootScope
 * @param {DigiTickets.CategoryManager} CategoryManager
 * @param {Hydrator} hydrator
 * @param ProductCache
 * @param ProductResource
 */
DigiTickets.ProductManager = function (
    $q,
    $rootScope,
    CategoryManager,
    hydrator,
    ProductCache,
    ProductResource
) {
    this.$rootScope = $rootScope;
    this.categoryManager = CategoryManager;
    this.hydrator = hydrator;
    this.productCache = ProductCache;
    this.productResource = ProductResource;

    this.categories = {};
    this.deferred = $q.defer();
    this.products = [];
};

DigiTickets.ProductManager.prototype = {

    /**
     * @param {boolean} clearCache True to empty the product cache before loading.
     *
     * @return {promise|{then, catch, finally}}  Returns a promise resolved when the products are loaded.
     */
    loadProducts: function loadProducts(clearCache) {
        if (clearCache === true) {
            this.productCache.removeAll();
        }

        let self = this;

        this.productResource.query(
            function (products) {
                self.products = self.hydrator.hydrateArray(products, function () {
                    return new DigiTickets.Product();
                });

                self.categories = self.categoryManager.extractCategoriesFromItems(self.products);

                // For user switching, the Sell controller needs to know when the products
                // are available so that it can restore any order that was in progress.
                self.$rootScope.$broadcast('ProductManager.productsReady');

                self.deferred.resolve(self.products);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return self.deferred.promise;
    },

    getProducts: function getProducts() {
        return this.deferred.promise;
    },

    getCategories: function getCategories() {
        return this.categories;
    },

    findByBarcode: function findByBarcode(barcode) {
        for (let k in this.products) {
            if (this.products[k].barcode == barcode) {
                return this.products[k];
            }
        }

        return null;
    },

    /**
     * Method to return the specific product.
     *
     * @param productId
     * @return DigiTickets.Product
     */
    getProduct: function getProduct(productId) {
        for (let productIndex = 0; productIndex < this.products.length; productIndex++) {
            if (this.products[productIndex].ID == productId) {
                return this.products[productIndex];
            }
        }
        return null;
    }

};
