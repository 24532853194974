const AbstractSearchCache = require('../AbstractSearchCache');
const { cloneShallow } = require('../../../functions/clone');

/**
 * Provides a singleton object to represent the search query and results on the "Manage" page,
 * so the previous data is visible when the user returns to the page.
 * Also used to load the account data when navigating to the account page from the account search (Manage) page.
 */
const CustomerAccountSearchCache = function () {
    AbstractSearchCache.call(this);

    this.form = {
        query: ''
    };

    this.formDefaults = cloneShallow(this.form);
};

CustomerAccountSearchCache.prototype = Object.create(AbstractSearchCache.prototype);

/**
 * @param {number|string} id
 *
 * @return {?DigiTickets.CustomerAccount}
 */
CustomerAccountSearchCache.prototype.getAccountFromCache = function (id) {
    if (!this.results || !(this.results instanceof Array)) {
        return null;
    }

    id = parseInt(id, 10);
    return this.results.find(
        /**
         * @param {DigiTickets.CustomerAccount} result
         */
        (result) => result.ID === id
    ) || null;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerAccountSearchCache;
}
