const baseLanguageStrings = require('@temp/js/en');
const { extendDeep } = require('../../functions/functions');
const { parseIni } = require('../../functions/ini');
const { ucfirst } = require('../../functions/strings');

const LangService = function (
    $q,
    $http,
    $interpolate
) {
    this.$q = $q;
    this.$http = $http;
    this.$interpolate = $interpolate;

    this.baseLanguage = 'en';
    this.currentLanguage = 'en';
    this.strings = baseLanguageStrings;

    this.branchId = null;

    this.loadLanguageFileDeferred = null;
};

LangService.prototype = {
    /**
     * @param {DigiTickets.Branch} branch
     */
    setLanguageFromBranch: function (branch) {
        if (branch && branch.language !== null) {
            this.setLanguage(
                branch.language,
                branch.languageOverrides,
                branch.ID
            );
        }
    },

    /**
     * Switch language.
     * The basis of all languages is the en.ini file stored in the /lang dir, and that is the default language.
     * Note that in our case the base 'en' is actually 'en-gb'.
     * 'en-us' would be an override on top of this.
     *
     * If a branch uses a different language, the strings in that language override the base language (en).
     * And if a branch has custom language strings, they further override that.
     *
     * @param {string} newLanguage Name of the new language file to load
     * @param {object} overrides   Any strings in this object will replace those in the loaded language file
     * @param {int} branchId
     */
    setLanguage: function (newLanguage, overrides, branchId) {
        // No language given? Nothing to do
        if (!newLanguage) {
            return;
        }

        // Default value for overrides
        if (!overrides) {
            overrides = {};
        }

        // Already the language in use?
        // The branchId check is so that we know the same overrides are being used as well
        // (easier to compare the branch ID than override objects)
        if (this.currentLanguage === newLanguage && this.branchId === branchId) {
            console.log('Language ' + newLanguage + ' for branch ' + branchId + ' already loaded.');
            return;
        }

        // Default language is easy since it's baked in to the JS
        if (newLanguage === this.baseLanguage) {
            this.strings = extendDeep(extendDeep({}, baseLanguageStrings), overrides);
            this.currentLanguage = newLanguage;
            this.branchId = branchId;
            return;
        }

        let self = this;

        // Non default language, need to load it...
        console.log('Reading language file ' + newLanguage + '.ini');
        self.loadLanguageStringsFromFile(newLanguage).then(function (newLanguageStrings) {
            // If we can't load that language, fallback to default language
            if (!newLanguageStrings) {
                console.log('Failed to load language file ' + newLanguage + '.ini');
                self.strings = extendDeep(extendDeep({}, baseLanguageStrings), overrides);
                self.currentLanguage = self.baseLanguage;
                return;
            }

            self.strings = extendDeep(extendDeep(extendDeep({}, baseLanguageStrings), newLanguageStrings), overrides);
            self.currentLanguage = newLanguage;
            self.branchId = branchId;
        });
    },

    /**
     * Reads in a language ini file, parses it, and returns an object.
     *
     * @param  {string} lang Name of the language file to load from the /lang dir.
     * @return {Promise<Object>}
     */
    loadLanguageStringsFromFile: function (lang) {
        let self = this;

        // Cancel any existing request to avoid race conditions
        if (self.loadLanguageFileDeferred !== null) {
            self.loadLanguageFileDeferred.resolve(false);
        }

        self.loadLanguageFileDeferred = this.$q.defer();

        this.$http.get(
            '/lang/' + lang + '.ini',
            { timeout: self.loadLanguageFileDeferred.promise }
        ).success(function (data) {
            let strings = parseIni(data);
            self.loadLanguageFileDeferred.resolve(strings);
            self.loadLanguageFileDeferred = null;
        });

        return self.loadLanguageFileDeferred.promise;
    },

    /**
     * Returns a string from the given object by passing in a key like "section.KEY"
     *
     * @param  {object} obj Object containing the strings
     * @param  {string} key Name of the string to return
     * @return {*}     The element from the object
     */
    getPropertyByDotNotation: function (obj, key) {
        let arr = key.split('.');
        while (arr.length && (obj = obj[arr.shift()])) {

        }
        return obj;
    },

    /**
     * Returns a string from the language file.
     * This is what the 'lang' filter is calling and is is safe to directly call
     * LangService.getText() to achieve the same result rather than injecting angular's $filter.
     *
     * @param {string} key
     * @param {object} [parameters]
     *
     * @return {string}
     */
    getText: function (key, parameters) {
        // If we can't find what to display, show this
        let fallbackText = ucfirst(key.split('.').pop().toLowerCase());
        fallbackText = fallbackText.replace(/_/g, ' ');

        // Haven't loaded any strings
        if (this.strings.length < 1) {
            console.error('Unable to load string (no strings loaded)', key);
            return fallbackText;
        }

        let text = this.getPropertyByDotNotation(this.strings, key);
        if (!text) {
            // Try removing the category from the key and use the global key if possible
            let arr = key.split('.');

            // Looking for a global anyway, fail
            if (arr.length < 2) {
                console.error('Unable to load string', key);
                return fallbackText;
            }

            // Remove first item from the array of keys
            arr.shift();
            let strippedKey = arr.join('.');
            text = this.getPropertyByDotNotation(this.strings, strippedKey);

            // Still nothing, fail
            if (!text) {
                console.error('Unable to load string', key, strippedKey);
                return fallbackText;
            }
        }

        if (typeof text !== 'string') {
            console.error('Unable to load string (unexpected type)', key);
            return fallbackText;
        }

        // Got the text now

        if (!parameters || Object.keys(parameters).length < 1) {
            // No parameters so no need to interpolate
            return text;
        }
        // Parameters were given, so run it through the interpolator
        return this.$interpolate(text)(parameters);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LangService;
}
