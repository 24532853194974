DigiTickets.Worldpay.TransactionRequestRecord = (function () {
    /**
     * @param {number} transactionType
     */
    let TransactionRequestRecord = function TransactionRequestRecord(transactionType) {
        /**
         * Transaction reference, which uniquely identifies the transaction with a user defined tag.
         * It is recommended that Transaction Reference field should contains only characters defined in 'Data Type'
         * however if Transaction Reference contains special characters then IPC will sanitize the
         * Transaction Reference Filed, excluding 'Data Type' characters. IPC will use the sanitized Transaction
         * Reference to process the transaction. IPC will send the original Transaction Reference in output response.
         * For cancel, use the transaction reference of original transaction to be cancelled.
         *
         * @type {string}
         */
        this.transactionReference = null;

        /**
         * Transaction Type indicates the type of financial transaction.
         *
         * @see {DigiTickets.Worldpay.TransactionType}
         *
         * @type {number}
         */
        this.transactionType = transactionType;

        /**
         * Transaction Amount
         *
         * Transaction amount is in major currency units
         * e.g. 3=10.23 means transactionAmount=£10.23
         *
         * Amount must be non-zero,non negative and less than 9999999.99.
         * Please note however that transactions above the terminal’s ceiling limit will be cancelled even if they are
         * less than 9999999.99. The Ceiling limit is defined by acquirer and varies by acquirer.
         * Only 2 digits are allowed after the decimal point
         *
         * @type {number}
         */
        this.transactionAmount = null;

        /**
         * @type {boolean}
         */
        this.cardholderNotPresent = false;
    };

    TransactionRequestRecord.prototype = {
        /**
         * Convert the record to an object of data with the keys being the field identifiers Worldpay expects.
         *
         * @return {Object<*>}
         */
        toData: function toData() {
            let data = {
                1: this.transactionReference,
                2: this.transactionType,
                3: this.transactionAmount
            };

            if (this.cardholderNotPresent) {
                data[12] = 1;
            }

            return data;
        }
    };

    return TransactionRequestRecord;
}());
