const _ = require('lodash');
const moment = require('moment');

/**
 * Provides validation methods for memberships and their members.
 *
 * @param {LangService} LangService
 * @param {DigiTickets.MembershipRefGenerator} MembershipRefGenerator
 * @param {RefValidator} refValidator
 * @param {UserService} UserService
 */
const MembershipValidator = function (
    LangService,
    MembershipRefGenerator,
    refValidator,
    UserService
) {
    this.langService = LangService;
    this.membershipRefGenerator = MembershipRefGenerator;
    this.refValidator = refValidator;
    this.userService = UserService;
};

MembershipValidator.prototype = {
    /**
     * Validates all the tabs (datasets) in the membership modal. If any fields are invalid,
     * or are mandatory and blank it returns an appropriate error message, otherwise it returns null.
     * It validates all memberships (ie all tabs), but starts with the currently selected tab,
     * just so it's nicer for the user.
     *
     * @param {int} activeTabIndex
     * @param {DigiTickets.MembershipDataset[]} datasets
     * @return {object}
     */
    validateDatasets: function validateDatasets(activeTabIndex, datasets) {
        let result = {
            errorMessage: null,
            onTabIndex: null
        };

        let currentDatasetValid = this.validateDataset(datasets[activeTabIndex]);
        if (currentDatasetValid !== true) {
            result.errorMessage = currentDatasetValid + ' (' + this.langService.getText('MEMBERSHIP_VALIDATION.MEMBERSHIP_CURRENT') + ')';
            return result;
        }

        for (let tabIndex = 0; tabIndex < datasets.length; tabIndex++) {
            let datasetValid = this.validateDataset(datasets[tabIndex]);
            if (datasetValid !== true) {
                result.errorMessage = datasetValid + ' (' + this.langService.getText('MEMBERSHIP_VALIDATION.MEMBERSHIP_NUM', {
                    num: (1 + tabIndex)
                }) + ')';

                // Make this tab the active one.
                result.onTabIndex = tabIndex;
                break;
            }
        }

        return result;
    },

    /**
     * Validate a single dataset.
     *
     * @param {DigiTickets.MembershipDataset} dataset
     *
     * @return {string|boolean} true if valid, a string containing an error message if not.
     */
    validateDataset: function validateDataset(dataset) {
        let membershipPlan = dataset.membershipPlan;

        // Validate the assigned ref again just to be sure. Normally fires on keyup but that can be finicky.
        let refValidationResult = this.validateMembershipRef(dataset);
        if (refValidationResult !== true) {
            return refValidationResult.formattedError;
        }

        // Validate all the member- and the address fields. Remember that the primary member
        // also has an email address, and that both the primary member and address have a
        // "use customer details" checkbox.
        // The validation has changed (for now): Only the first- and last name of the primary
        // member is mandatory; everything else is optional.

        // Check the first name and the last name. It's only mandatory for the primary member.
        if (dataset.members.length < 1) {
            return this.langService.getText('MEMBERSHIP_VALIDATION.NO_MEMBERS');
        }

        if (!dataset.members[0].contact) {
            return this.langService.getText('MEMBERSHIP_VALIDATION.PRIMARY_MEMBER_INFO_REQUIRED');
        }

        if (dataset.members[0].contactID === null) {
            if (!_.trim(dataset.members[0].contact.firstName)) {
                return this.langService.getText('MEMBERSHIP_VALIDATION.PRIMARY_MEMBER_FIRST_NAME_REQUIRED');
            }
            if (!_.trim(dataset.members[0].contact.lastName)) {
                return this.langService.getText('MEMBERSHIP_VALIDATION.PRIMARY_MEMBER_LAST_NAME_REQUIRED');
            }
            if (!dataset.members[0].contact.emailIsValid) {
                return this.langService.getText('MEMBERSHIP_VALIDATION.EMAIL_INVALID');
            }
        }

        // If the membership plan says to capture dates of birth for everyone, then go through the members, and if any fields
        // are entered, then the DOB must be entered. Of course, the primary member is a special case because we
        // know the name will be populated, and the error message will be slightly different.
        let usedContactIds = [];

        for (var memberIndex = 0; memberIndex < dataset.members.length; memberIndex++) {
            var member = dataset.members[memberIndex];

            // By this point the prior validation will ensure the members that need them have contacts.
            if (member.contact) {
                // Ensure the contact is not duplicated.
                if (member.contact.ID) {
                    if (usedContactIds.indexOf(member.contact.ID) !== -1) {
                        return this.langService.getText(
                            'MEMBERSHIP_VALIDATION.DUPLICATE_CONTACT',
                            {
                                name: member.contact.getFullName()
                            }
                        );
                    }
                    usedContactIds.push(member.contact.ID);
                }

                // Ensure all DOBs have been entered where necessary.
                let dobRequired = membershipPlan.alwaysCaptureDob() || membershipPlan.captureChildDobOnly() && member.contact.isChild;
                let hasDob = member.contact.dateOfBirth !== null;

                if (dobRequired && !hasDob) {
                    if (memberIndex === 0) {
                        // Primary member has no DOB and it is required.
                        return this.langService.getText('MEMBERSHIP_VALIDATION.PRIMARY_MEMBER_DOB_REQUIRED');
                    } else if (member.contact.hasData()) {
                        // Additional member that has been entered has no DOB and it is required.
                        return this.langService.getText(
                            'MEMBERSHIP_VALIDATION.DOB_REQUIRED',
                            {
                                num: (1 + memberIndex)
                            }
                        );
                    }
                }
            }
        }

        let membershipStartDate = dataset.getDateFrom();

        // The start date must be with the allowed usage date range on the plan, if the plan has such a range.
        if (membershipPlan.allowedUsageFromDate) {
            if (membershipStartDate < membershipPlan.allowedUsageFromDate || membershipStartDate > membershipPlan.allowedUsageToDate) {
                return this.langService.getText(
                    'MEMBERSHIP_VALIDATION.START_DATE_MUST_BE_WITHIN_ALLOWED_USAGE_DATE_RANGE',
                    {
                        from: membershipPlan.allowedUsageFromDate,
                        to: membershipPlan.allowedUsageToDate
                    }
                );
            }
        }

        // Ensure all DOBs are below the cut off age (either for every member or every member set as a child).
        let adults = 0;
        let children = 0;
        if (membershipPlan.getMaxAge()) {
            for (var memberIndex = 0; memberIndex < dataset.members.length; memberIndex++) {
                var member = dataset.members[memberIndex];
                if (member.contact && member.contact.dateOfBirth) {
                    let underAge = this.contactIsUnderAgeOnDate(
                        member.contact,
                        membershipPlan.getMaxAge(),
                        membershipStartDate
                    );

                    // If the max age applies to everybody, or this member claims to be a child, and they
                    // are over the max age, return an error.
                    if (membershipPlan.maxAgeIsForEverybody() || member.contact.isChild) {
                        if (underAge !== true) {
                            let errorStr = membershipPlan.maxAgeIsForChildOnly() ? 'DOB_TOO_OLD_CHILD' : 'DOB_TOO_OLD';
                            return this.langService.getText(
                                'MEMBERSHIP_VALIDATION.' + errorStr,
                                {
                                    name: member.contact.getFullName(),
                                    num: (1 + memberIndex),
                                    cutoff: membershipPlan.getMaxAge(),
                                    startDate: membershipStartDate
                                }
                            );
                        }
                    }

                    // While we're looping here count up the number of adults and children.
                    // (Membership Plans should always have a max age when there are minAdults/minChildren)
                    if (membershipPlan.maxAgeIsForChildOnly()) {
                        if (underAge) {
                            children++;
                        } else {
                            adults++;
                        }
                    }
                } else if (member.contact) {
                    if (membershipPlan.maxAgeIsForChildOnly()) {
                        // Count as an adult
                        adults++;
                    }
                }
            }
        }

        // Ensure the minimum number of adults and children are met.
        if (membershipPlan.minimumChildren + membershipPlan.minimumAdults > 0) {
            if (adults < membershipPlan.minimumAdults) {
                return this.langService.getText(
                    'MEMBERSHIP_VALIDATION.NOT_ENOUGH_ADULTS',
                    {
                        min: membershipPlan.minimumAdults
                    }
                );
            }

            if (children < membershipPlan.minimumChildren) {
                return this.langService.getText(
                    'MEMBERSHIP_VALIDATION.NOT_ENOUGH_CHILDREN',
                    {
                        min: membershipPlan.minimumChildren
                    }
                );
            }
        }

        // Ensure member references are entered where they should / not entered where they should not be.
        for (let i = 0; i < dataset.members.length; i++) {
            let thisMember = dataset.members[i];
            if (!thisMember.contact) {
                continue;
            }

            let requireRef = this.requireRefForMember(dataset, thisMember, thisMember.contact);
            if (requireRef === false && thisMember.contact.ref) {
                // A ref has been entered when one MUST NOT be entered.
                // If we want to enforce that refs are not entered for children uncomment this.
                // return this.langService.getText('MEMBERSHIP_VALIDATION.REF_ENTERED_FOR_CHILD', {
                //          name: thisMember.contact.getFullName()
                //      });
            } else {
                let mustEnter = (requireRef === true);
                let validationResult = this.validateContactRef(thisMember.contact, mustEnter);
                if (validationResult !== true) {
                    return validationResult.formattedError;
                }
            }
        }

        // All fields in this dataset are correctly entered.
        return true;
    },

    /**
     * Run validateDataset on all of the given cartItems that contain a membership plan.
     * Doesn't return the error message (because that isn't needed where this is used), it just
     * returns true if valid or false if invalid.
     *
     * @param {DigiTickets.CartItem[]} cartItems
     *
     * @return {boolean}
     */
    cartItemDatasetsAreValid(cartItems) {
        for (let item of cartItems) {
            if (item.isMembershipPlan()) {
                for (let membershipDataset of item.getMembershipDatasets()) {
                    let result = this.validateDataset(membershipDataset);
                    if (result !== true) {
                        return result;
                    }
                }
            }
        }
        return true;
    },

    /**
     * @param {DigiTickets.Contact} contact
     * @param {number} maxAge
     * @param {Date} onDate
     *
     * @return {boolean}
     */
    contactIsUnderAgeOnDate: function contactIsUnderAgeOnDate(contact, maxAge, onDate) {
        if (!contact.dateOfBirth) {
            return false;
        }

        // NOTE: Make sure this logic matches the equivalent logic in the App (currently in MembershipValidator::validateAgeLimits()).

        // Calculate the date on which the contact will turn [maxAge] years old.
        let dob = moment(contact.dateOfBirth);
        let cutoffBirthday = dob.clone().add(maxAge, 'year');

        // If that date is after the [onDate] they will be under [maxAge] on that day.
        return cutoffBirthday > onDate;
    },

    /**
     * Validates the membership ref, and sets the property of the controller accordingly.
     * This property determines whether or not the error message appears on screen.
     * It now also sets the error message.
     *
     * @param {DigiTickets.MembershipDataset} membershipDataset
     *
     * @return {boolean|Object<string>}
     */
    validateMembershipRef: function validateMembershipRef(membershipDataset) {
        // If we're editing an existing membership, it will have a system default ref (null otherwise)
        let allowedSystemRef = null;
        if (membershipDataset.membershipID) {
            allowedSystemRef = this.membershipRefGenerator.generate(membershipDataset.membershipID);
        }

        return this.refValidator.validateRef(
            membershipDataset.assignedMembershipRef,
            'membership',
            false,
            allowedSystemRef
        );
    },

    /**
     * Check if a ref MUST be entered for a member/contact.
     * Used to check if the ref should be required during validation.
     *
     * @param {DigiTickets.MembershipDataset} membershipDataset
     * @param {DigiTickets.Member} member
     * @param {DigiTickets.Contact} contact
     *
     * @returns {boolean|null} true = a ref MUST be entered
     *                         null = a ref MAY be entered (it's optional)
     *                         false = a ref MUST NOT be entered
     */
    requireRefForMember: function requireRefForMember(membershipDataset, member, contact) {
        if (membershipDataset.membership) {
            // Always require when editing if refs are per person.
            if (
                this.userService.getCompany().membershipCardAllocation === DigiTickets.MembershipCardAllocationType.MEMBER
                        || this.userService.getCompany().membershipCardAllocation === DigiTickets.MembershipCardAllocationType.ADULTS
            ) {
                return true;
            }
        }

        let canEnter = this.canEnterRefForMember(membershipDataset, member, contact);
        return canEnter === false ? false : null;
    },

    /**
     * Check if a ref CAN be entered for a member/contact.
     * Used to show if the ref field should be shown or hidden in the UK.
     *
     * If editing a membership a ref is always required. But this can still be used to determine if
     * the field should be shown (editable) or hidden (not editable).
     *
     * @param {DigiTickets.MembershipDataset} membershipDataset
     * @param {DigiTickets.Member} member
     * @param {DigiTickets.Contact} contact
     *
     * @return {boolean}
     */
    canEnterRefForMember: function canEnterRefForMember(membershipDataset, member, contact) {
        // Count adults and children.
        // When doing the final validation the actual DOBs are already checked by now to ensure the isChild
        // flag is appropriately set. If deciding to show/hide the ref on the template it's adequate at this
        // point to trust the user's selection.
        let adults = 0;
        let children = 0;
        for (let i = 0; i < membershipDataset.members.length; i++) {
            if (!membershipDataset.members[i].contact) {
                continue;
            }

            if (membershipDataset.members[i].index === member.index) {
                // Skip the current member because if using this while deciding to show the field
                // the member's contact won't be the same as the contact data being edited.
                continue;
            }

            if (membershipDataset.members[i].contact.isChild) {
                ++children;
            } else {
                ++adults;
            }
        }

        // Add the current contact to the count.
        if (contact.isChild) {
            ++children;
        } else {
            ++adults;
        }


        switch (this.userService.getCompany().membershipCardAllocation) {
            case DigiTickets.MembershipCardAllocationType.MEMBER:
                return true;

            case DigiTickets.MembershipCardAllocationType.ADULTS:
                // Allow for the primary member if there are only children on the plan.
                if (member.index === 0 && adults < 1) {
                    return true;
                }

                return !contact.isChild;
        }

        return false;
    },

    /**
     * @param {DigiTickets.Contact} contact
     * @param {boolean} required
     *
     * @return {boolean|Object<string>}
     */
    validateContactRef: function validateContactRef(contact, required) {
        let allowedSystemRef = contact.generateOriginalReference();

        return this.refValidator.validateRef(
            contact.ref,
            'contact',
            required,
            allowedSystemRef
        );
    },

    /**
     * Go through an array of membership datasets, counting up how many times a reference
     * (both contact and membership) has been used.
     * Return an error for each one that was used more than once.
     *
     * @param {DigiTickets.MembershipDataset[]} datasets
     *
     * @returns {string[]}
     */
    checkForDuplicateRefs: function checkForDuplicateRefs(datasets) {
        let assignedMemberRefCounts = {};
        let assignedMembershipRefCounts = {};

        // Loop through every existing membership and get the counts of each assigned ref used.
        for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
            let dataset = datasets[datasetIndex];

            let assignedRef = dataset.assignedMembershipRef;
            if (assignedRef) {
                // They have entered a ref for this membership; add the stat to our collection.
                if (!assignedMembershipRefCounts.hasOwnProperty(assignedRef)) {
                    assignedMembershipRefCounts[assignedRef] = 0;
                }
                assignedMembershipRefCounts[assignedRef]++;
            }

            for (let memberIndex = 0; memberIndex < dataset.members.length; memberIndex++) {
                let member = dataset.members[memberIndex];

                // Ignore contacts with an ID as we are only concerned with new contact refs here.
                // The same person could potentially be on multiple plans being sold at the same time,
                // and that would be a duplicate ref.
                // They may be trying to sell multiple plans to a *new* contact at the same time, using the
                // same ref. Since we don't want to create multiple contacts with the same ref it is right
                // to throw an error. They can create one membership to create the contact then sell more
                // re-selecting the now-created contact.
                if (member.contact && member.contact.ref && !member.contact.ID) {
                    // They have entered a ref for this member; add the stat to our collection.
                    if (!assignedMemberRefCounts.hasOwnProperty(member.contact.ref)) {
                        assignedMemberRefCounts[member.contact.ref] = 0;
                    }
                    assignedMemberRefCounts[member.contact.ref]++;
                }
            }
        }

        // Now go through the entered refs, any that were used more than once
        // report them as an error.
        let result = [];

        Object.keys(assignedMemberRefCounts).forEach((ref) => {
            let count = assignedMemberRefCounts[ref];
            if (count > 1) {
                result.push(
                    this.langService.getText('MEMBERSHIP_VALIDATION.DUPLICATE_MEMBER_REF', { ref })
                );
            }
        });

        Object.keys(assignedMembershipRefCounts).forEach((ref) => {
            let count = assignedMembershipRefCounts[ref];
            if (count > 1) {
                result.push(
                    this.langService.getText('MEMBERSHIP_VALIDATION.DUPLICATE_MEMBERSHIP_REF', { ref })
                );
            }
        });

        return result;
    }

};


/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipValidator;
}
