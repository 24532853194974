/**
 * Constants that can be used instead of referring to actions by ID.
 */
const VerifoneActionIds = {
    BYPASS_PIN: 1,
    CONTINUE_TRANSACTION: 2,
    CONFIRM_SIGNATURE: 3,
    REJECT_SIGNATURE: 4,
    REPRINT_RECEIPT: 5,
    VOICE_REFERRAL_AUTHORISED: 7,
    VOICE_REFERRAL_REJECTED: 8,
    CANCEL_TRANSACTION: 12,
    ALTERNATE_PAYMENT: 13,
    CONFIRM_AUTH_CODE: 20,
    REJECT_AUTH_CODE: 21,
    CHARGE_AUTH_CODE: 22,
    REVERSE_UAT_ICC_TXN: 23,
    RETRY_DEVICE: 24,
    CONTINUE_WITHOUT_DEVICE: 25,
    ABORT_DEVICE_CONNECTIVITY: 26,
    RETRY_DOWNLOAD: 27,
    CANCEL_DOWNLOAD: 28,
    CASHBACK_REQUIRED: 29,
    CASHBACK_NOT_REQUIRED: 30,
    RESTART: 31,
    ACCEPT_UNSAFE_DOWNLOAD: 32,
    REJECT_UNSAFE_DOWNLOAD: 33,
    REPLACE_ACCOUNT: 34,
    CANCEL_REPLACE_ACCOUNT: 35,
    ACCOUNT_ON_FILE_REGISTRATION_REQUIRED: 38,
    ACCOUNT_ON_FILE_REGISTRATION_NOT_REQUIRED: 39,
    RECONNECT_TO_SERVER: 40,
    ABORT_RECONNECT_TO_SERVER: 41,
    SELECT_PAYPOINT_ACCOUNT: 42,
    SELECT_PAYPOINT_OPTION: 43,
    RETRY_PAYPOINT_CONFIRMATION: 44,
    CANCEL_PAYPOINT_CONFIRMATION: 45,
    ACCEPT_LICENCE_KEY: 46,
    REJECT_LICENCE_KEY: 47,
    CANCEL_LICENCE_KEY_VERIFICATION: 48,
    CONTINUE_LICENCE_KEY_VERIFICATION: 49,
    CONFIRM_PARK_RETAIL_GIFT_TRANSACTION: 52,
    REVERSE_PARK_RETAIL_GIFT_TRANSACTION: 53,
    CANCEL_GET_CARD_DETAILS: 54,
    NO_TRANSACTION_VALUE_UPDATE_REQUIRED: 55,
    TRANSACTION_VALUE_UPDATE_REQUIRED: 56,
    MERCHANT_CURRENCY_REQUIRED: 57,
    CARDHOLDER_CURRENCY_REQUIRED: 58,
    MERCHANT_REFERENCE_ENTERED: 59,
    SUPPLY_IVR_DETAILS: 60,
    REJECT_IVR: 61,
    PRINT_SHOP_COPY_RECEIPT: 62,
    CANCEL_SHOP_COPY_RECEIPT: 63,
    SUPPLY_STAN: 64,
    ACCEPT_PART_PAYMENT: 65,
    REJECT_PART_PAYMENT: 66,
    FURTHER_PAYMENT_REQUIRED: 67,
    NO_FURTHER_PAYMENT_REQUIRED: 68
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneActionIds;
}
