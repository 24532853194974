const BigNumber = require('bignumber.js');

const ChangeCalculator = function () {
    /**
     * How much money to give to the customer.
     *
     * @private
     * @type {BigNumber}
     */
    this.amountChange = new BigNumber(0.00);

    /**
     * @private
     * @type {BigNumber}
     */
    this.amountDue = new BigNumber(0.00);

    /**
     * How much money has been received from the customer.
     *
     * @private
     * @type {BigNumber}
     */
    this.amountTendered = new BigNumber(0.00);

    /**
     * How much money is still owed by / to the customer.
     * This is simply amountDue - amountTendered.
     *
     * @private
     * @type {BigNumber}
     */
    this.remainingAmountDue = new BigNumber(0.00);
};

ChangeCalculator.prototype = {
    /**
     * Clear the amount tendered.
     */
    clear() {
        this.amountTendered = new BigNumber(0.00);
        this.calculate();
    },

    calculate() {
        this.remainingAmountDue = this.amountDue.minus(this.amountTendered);

        if (this.amountDue > 0) {
            this.amountChange = BigNumber.max(
                0,
                this.amountTendered.minus(this.amountDue)
            );
        } else {
            // For a refund of 9.99, the customer COULD give a penny and get a tenner in return.
            this.amountChange = BigNumber.max(
                0,
                this.amountTendered.plus(this.amountDue.multipliedBy(-1))
            );
        }
    },

    /**
     * @return {number}
     */
    getAmountTendered() {
        return this.amountTendered.toNumber();
    },

    /**
     * @return {number}
     */
    getChange() {
        return this.amountChange.toNumber();
    },

    /**
     * @return {number}
     */
    getRemainingAmountDue() {
        return this.remainingAmountDue.toNumber();
    },

    /**
     * @param {number} amountTendered
     */
    setAmountTendered(amountTendered) {
        this.amountTendered = new BigNumber(amountTendered);
        this.calculate();
    },

    /**
     * @return {number}
     */
    getAmountDue() {
        return this.amountDue.toNumber();
    },

    /**
     * @param {number} amountDue
     */
    setAmountDue(amountDue) {
        this.amountDue = new BigNumber(amountDue);
        this.calculate();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ChangeCalculator;
}
