/**
 * Methods for a simplified navigation bar, used on kitchen and kitchen customer screens.
 * Only provides for logging out or switching branch.
 */
/**
 * @param $scope
 * @param {CacheManager} CacheManager
 * @param {UserService} UserService
 */
const KitchenNavigationCtrl = function (
    $scope,
    CacheManager,
    UserService
) {
    $scope.userService = UserService;

    /**
     * @param {DigiTickets.Branch} newBranch
     */
    $scope.changeBranch = function changeBranch(newBranch) {
        UserService.setBranch(newBranch);
    };

    $scope.syncData = async function syncData() {
        // Clear the cache and refresh the page.
        await CacheManager.syncClear();
        window.location.reload();
    };

    $scope.logout = function logout() {
        UserService.logout();
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = KitchenNavigationCtrl;
}
