const LZString = require('lz-string');

/**
 * This class is a small wrapper to localStorage.
 *
 * It will compress data it has been given via LZString.
 *
 * All keys will be prefixed with 'DB.' to allow identification of the type of data without having to
 * too much checking.
 */
/**
 * @param {string} cacheName This should be VERY short. Ideally 1 or 2 letters as it will be duplicated against each row.
 *                           It would be nice to NOT need this element in the localStorage key, but without it, we don't
 *                           have a way to group downloaded content. If/when we get another downloaded, then we may
 *                           decide to allow the merging of the data and always download all the data. In which case,
 *                           there is no need for a separate namespace in the localStorage key.
 * @constructor
 */
DigiTickets.LargeVolumeStorageImplementation = function (
    cacheName
) {
    /**
     * The localStorage key prefix for this cache in the form of DB.[cacheName]
     *
     * @type {string}
     * @private
     */
    let _prefixedCacheName = 'DB.' + cacheName;

    /**
     * The local immutable name of the cache.
     *
     * @type {string}
     */
    this._getCacheName = function _getCacheName() {
        return cacheName;
    };

    this._buildKey = function _buildKey(key) {
        return _prefixedCacheName + '.' + key;
    };

    this._getPrefixedCacheName = function _getPrefixedCacheName() {
        return _prefixedCacheName;
    };
};

/**
 *
 * @type {{
 *      getCacheName: DigiTickets.LargeVolumeStorageImplementation.getCacheName,
 *      getItem: DigiTickets.LargeVolumeStorageImplementation.getItem,
 *      setItem: DigiTickets.LargeVolumeStorageImplementation.setItem,
 *      clear: DigiTickets.LargeVolumeStorageImplementation.clear,
 *      removeAll: DigiTickets.LargeVolumeStorageImplementation.removeAll,
 *      removeItem: DigiTickets.LargeVolumeStorageImplementation.removeItem
 *      }}
 */
DigiTickets.LargeVolumeStorageImplementation.prototype = {

    /**
     * The local immutable name of the cache.
     *
     * @type {string}
     */
    getCacheName: function getCacheName() {
        return this._getCacheName();
    },

    /**
     * Get a value from local storage and decompress it.
     *
     * @param {string} key
     * @returns {*} - Will return null or an object
     */
    getItem: function getItem(key) {
        let value = localStorage.getItem(this._buildKey(key));
        if (value !== null) {
            value = LZString.decompressFromUTF16(value);
        }

        return value;
    },

    /**
     * Put a value into local storage, but compress it first.
     *
     * @param {string} key
     * @param {string} data
     */
    setItem: function setItem(key, data) {
        return localStorage.setItem(this._buildKey(key), LZString.compressToUTF16(data));
    },

    /**
     * Remove all the data for this cache.
     */
    clear: function clear() {
        Object.keys(localStorage).forEach(function (key) {
            if (key.indexOf(this._getPrefixedCacheName()) !== -1) {
                localStorage.removeItem(key);
            }
        }.bind(this));
    },

    /**
     * Helper function to make this more compatible with the AngularCache.
     */
    removeAll: function removeAll() {
        this.clear();
    },

    /**
     * Remove the requested key from the localStorage.
     *
     * @param {string} key
     */
    removeItem: function removeItem(key) {
        localStorage.removeItem(this._buildKey(key));
    }
};
