const { toInt, toString } = require('../functions/transform');

DigiTickets.PaymentChannel = function () {
    /**
     * @type {number|null}
     */
    this.ID = null;

    /**
     * @type {string|null}
     */
    this.name = null;
};

DigiTickets.PaymentChannel.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'paymentChannelID'],
                transform: toInt
            },
            name: toString
        };
    }
};
