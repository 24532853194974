const BigNumber = require('bignumber.js');
const Customer = require('./Customer');
const FieldGroup = require('../libraries/DigiTickets/CustomFields/FieldGroup');
const FieldLevel = require('../libraries/DigiTickets/CustomFields/FieldLevel');
const OrderFulfillment = require('../libraries/DigiTickets/Fulfillments/OrderFulfillment');
const OrderLine = require('../libraries/DigiTickets/Orders/OrderLine');
const PaymentCalculator = require('../libraries/DigiTickets/Payment/PaymentCalculator');
const PaymentMethod = require('../libraries/DigiTickets/PaymentMethods/PaymentMethod');
const SalesTotals = require('../libraries/DigiTickets/SalesTotals');
const { createFieldGroupCollectionFromOrder } = require('../libraries/DigiTickets/CustomFields/fieldGroupCollectionFactory');
const { createMissingItemInstances, fixInstanceFieldDataNumbering } = require('../libraries/DigiTickets/Cart/cartLineCleaning');
const { setOrderLinesValidUntil } = require('../libraries/DigiTickets/Orders/calculateValidUntil');
const { toBool, toDate, toFloat, toInt, toNullableInt, toNullableString } = require('../functions/transform');

DigiTickets.Order = function () {
    /**
     * Simple name/values
     */
    this.bookingRef = null;
    /**
     * @type {String}
     */
    this.originalRef = null;
    this.cancelledAt = null;
    this.date = new Date();

    this.fulfilledAt = null;
    this.fulfillmentCreatedAt = null;

    this.giftAid = null;
    /**
     * @type {Number}
     */
    this.ID = null;
    this.notes = null;
    this.paid = null;
    this.paidAt = null;

    /**
     * @type {boolean}
     */
    this.receiptEmailed = false;

    /**
     * @type {boolean}
     */
    this.receiptPrinted = false;

    this.redeemed = false;
    this.redemptionDate = null;
    this.redemptionPrivileges = null;
    this.total = 0;

    /**
     * A human readable reference entered by the user for this order (e.g. "Table 123").
     * Gets shown on the kitchen screen, and printed on the receipt.
     *
     * @type {string|null}
     */
    this.staffRef = null;

    /**
     * @type {string|null}
     */
    this.stashedCartGuid = null;

    /**
     * @type {Array<DigiTickets.Payment>}
     */
    this.payments = [];

    this.remainingBalance = 0;
    this.fullyPaid = false;
    this.partiallyPaid = false;
    this.unpaid = false;

    /**
     * Objects
     */
    this.company = null;
    this.customer = null;
    this.seller = null;

    /**
     * Field data after it has been processed and organised.
     * Order/Event and Item level data is combined here.
     *
     * @type {FieldGroupCollection}
     */
    this.fields = null;

    /**
     * @type {OrderFulfillment[]}
     */
    this.fulfillments = [];

    /**
     * TODO: Temporary name because we can't remove this.fields currently because it is used in receipt templates stored
     * in app. Eventually this will just be called 'fields' to match Cart.
     *
     * @type {FieldGroup}
     */
    this.orderLevelFieldGroup = new FieldGroup({ level: FieldLevel.ORDER });

    /**
     * @type {OrderLine[]}
     */
    this.items = [];

    /**
     * @type {Object<DigiTickets.OrderItemGroup[]>}
     */
    this.itemGroups = {};

    /**
     * In afterHydration this will be populated with all the memberships contained in this order.
     *
     * @type {DigiTickets.Membership[]}
     */
    this.memberships = [];

    /**
     * Where was the order created (Online, Admin Area, Point of sale, Resellers)
     *
     * @type {?DigiTickets.PaymentChannel}
     */
    this.paymentChannel = null;

    /**
     * @type {SalesTotals}
     */
    this.totals = new SalesTotals();

    /**
     * @type {?string}
     */
    this.thirdPartyID = null;

    /**
     * @type {?string}
     */
    this.thirdPartyRef = null;

    /**
     * @type {Date|null}
     */
    this.purgeFromCustomerScreenAt = null;

    /**
     * Determines if the order's ref is visible or hidden on the kitchen-customer screen.
     *
     * @type {boolean}
     */
    this.visibleOnCustomerScreen = false;

    /**
     * All orders for Greek customers must contain a transaction number that is unique for that sales channel in that
     * year. On 1st of January the counter gets reset back to 1.
     *
     * @type {Number|null}
     */
    this.transactionNumber = null;
};

DigiTickets.Order.prototype = {
    getHydrationMap() {
        return {
            bookingRef: toNullableString,
            originalRef: toNullableString,
            cancelledAt: toDate,
            company: {
                field: ['company', 'companies'],
                model: DigiTickets.Company
            },
            customer: {
                field(data) {
                    return data.customer ? data.customer : data;
                },
                model: Customer
            },
            date: toDate,
            email: {},
            fulfillments: {
                modelCollection: OrderFulfillment
            },
            fulfilledAt: toDate,
            fulfillmentCreatedAt: toDate,
            giftAid: toBool,
            ID: {
                field: ['ID', 'orderID'],
                transform: toInt
            },
            items: {
                field: ['orderitems', 'items'],
                modelCollection: OrderLine
            },
            notes: {},
            orderLevelFieldGroup: {
                model: FieldGroup
            },
            paid: {
                field: ['paid', 'paidAt'],
                transform: toBool
            },
            paidAt: toDate,
            paymentChannel: {
                model: DigiTickets.PaymentChannel
            },
            paymentMethod: {
                // TODO This is from when there could only be one payment for an order.
                // This has been replaced by the payments array. Check if it's still used and remove it.
                field: ['paymentmethods', 'paymentMethod'],
                model: PaymentMethod
            },
            payments: {
                field(data) {
                    return data.isHydrated ? data.payments : data.paymentlines;
                },
                modelCollection: DigiTickets.Payment
            },
            postcode: {},
            redeemed: toBool,
            redemptionDate: toDate,
            redemptionPrivileges: {},
            seller: {
                field: ['seller', 'sellers'],
                model: DigiTickets.Seller
            },
            staffRef: {},
            stashedCartGuid: {},
            tel: {},
            thirdPartyID: {
                field: ['thirdPartyID', '3rdPartyID'],
                transform: toNullableString
            },
            thirdPartyRef: toNullableString,
            total: toFloat,
            transactionNumber: toNullableInt
        };
    },

    /**
     * Note this can't be changed to just 'afterHydration(data)' because it uses Angular injection.
     *
     * @param {object} data
     * @param {OrderFieldInstancesFromApiBuilder} orderFieldInstancesFromApiBuilder
     */
    afterHydration: function (data, orderFieldInstancesFromApiBuilder) {
        // If the API sends an order without the correct resolvers we might be missing item instances.
        // This seems to be the case when orders destined for the kitchen screen are loaded.
        // In this case, create the missing instances so they exist even if they don't have any data. We're about to
        // add field data to them below.
        if (data.orderitems && data.orderitems[0] && !data.orderitems[0].iteminstances) {
            this.items.forEach((line) => {
                createMissingItemInstances(line);
            });
        }

        // Create a sequential lineNumber for each line and remember which ordersItemsID has which line number.
        let lineNumbers = {};
        this.items.forEach((line, index) => {
            line.lineNumber = index + 1;
            lineNumbers[line.ID] = line.lineNumber;
            fixInstanceFieldDataNumbering(line);
        });

        // From the API data.fielddata is an array containing *all* the field data for the order - both order and
        // item level. Additionally each line in data.ordersitems has a 'fielddata' that is data only relating to that
        // line. ProPoint has always looked at the former for order level data and the latter for item level data.
        // Let's keep it working the same way.
        let fieldData = [];

        // Get the order level field data from the root level.
        if (data.hasOwnProperty('fielddata')) {
            fieldData = fieldData.concat(
                ...data.fielddata.filter((fd) => (fd.fields && fd.fields.level === 'order'))
            );
        }

        // Get the item level field data from each line.
        if (data.orderitems) {
            data.orderitems.forEach((orderLine) => {
                if (orderLine.fielddata) {
                    fieldData = fieldData.concat(...orderLine.fielddata);
                }
            });
        }

        // If we found any field data add it to the order.
        if (fieldData.length > 0) {
            // Create an array of FieldInstances containing data for the order.
            let fieldInstances = orderFieldInstancesFromApiBuilder.createFieldInstancesFromApiFieldData(
                fieldData,
                lineNumbers
            );

            // Set those FieldInstances in the appropriate places on the order:
            // order level instances in the order level FieldGroup
            // item level instances in the FieldGroup on the appropriate ItemInstance on the appropriate line.
            orderFieldInstancesFromApiBuilder.setFieldInstancesOnOrder(fieldInstances, this);
        }

        // Set the old style this.fields to a FieldGroupCollection for backward compatibility.
        // It's used in receipt templates.
        this.fields = createFieldGroupCollectionFromOrder(this);

        this.memberships = this.extractMemberships();

        this.calculate();
    },

    /**
     * @param {DigiTickets.Payment} payment
     */
    addPayment: function (payment) {
        this.payments.push(payment);
        this.calculate();
    },

    calculate: function calculate() {
        let groupID = 0;

        this.itemGroups = {};

        this.totals.reset();

        for (let i = 0; i < this.items.length; ++i) {
            let item = this.items[i];
            if (item.itemType === DigiTickets.ItemType.TICKET) {
                if (item.session === null) {
                    let openGroupName = 'Open Ticket';
                    if (!this.itemGroups.hasOwnProperty(openGroupName)) {
                        this.itemGroups[openGroupName] = new DigiTickets.OrderItemGroup(openGroupName);
                    }
                    groupID = openGroupName;
                } else {
                    // Belongs to an Event, put it in the correct one...
                    if (!this.itemGroups.hasOwnProperty(item.session.ID)) {
                        this.itemGroups[item.session.ID] = new DigiTickets.OrderItemGroup(
                            item.session.ID,
                            item.session.eventID,
                            item.session.getFullName()
                        );
                    }
                    groupID = item.session.ID;
                }
            } else {
                if (!this.itemGroups.hasOwnProperty(item.itemType)) {
                    this.itemGroups[item.itemType] = new DigiTickets.OrderItemGroup(item.itemType);
                }
                groupID = item.itemType;
            }
            this.itemGroups[groupID].items.push(item);

            let itemType = item.itemType;
            let saleType = item.qty > 0 ? this.totals.SALE_TYPES.SALE : this.totals.SALE_TYPES.RETURN;
            this.totals.addToTotals(itemType, saleType, 'lines', 1);
            this.totals.addToTotals(itemType, saleType, 'qty', item.qty);
            this.totals.addToTotals(itemType, saleType, 'baseTotal', item.baseTotal);
            this.totals.addToTotals(itemType, saleType, 'discount', item.discount ? item.discount.amount : 0);
            this.totals.addToTotals(itemType, saleType, 'lineTotal', item.lineTotal);
            if (item.hasOwnProperty('people') && item.people != undefined) {
                this.totals.addToTotals(itemType, saleType, 'peopleTotal', item.people * item.qty);
            } else if (item.hasOwnProperty('item') && item.item != null && item.item.hasOwnProperty('people') && item.item.people != undefined) {
                this.totals.addToTotals(itemType, saleType, 'peopleTotal', item.item.people * item.qty);
            }
        }

        this.setRemainingBalanceAndPaidStates();

        setOrderLinesValidUntil(this);
    },

    /**
     * Add up the amount from each paid payment on the order.
     * This uses amount not tendered because tendered for cash payments may be higher than the amount. Change will
     * have already been given so we don't care about that difference anymore, only the amount is what we need.
     *
     * @return {number}
     */
    getTotalPaid: function () {
        return PaymentCalculator.getTotalAmount(this.payments.filter((payment) => payment.paid));
    },

    /**
     * Set the remaining balance and the various 'paid' properties. This is called either when the order lines change or
     * when a payment is added to an order after an adjustment.
     */
    setRemainingBalanceAndPaidStates() {
        let totalDue = new BigNumber(this.total.toFixed(8));
        let totalPaid = new BigNumber(this.getTotalPaid().toFixed(8));

        let remainingBalance = totalDue.minus(totalPaid);
        this.remainingBalance = remainingBalance.toNumber();

        this.fullyPaid = this.remainingBalance <= 0;
        this.partiallyPaid = totalPaid > 0 && this.remainingBalance > 0;
        this.unpaid = this.remainingBalance > 0;
    },

    get fulfillmentCreatedAt() {
        // Get the most recent fulfillment
        if (this.fulfillments.length === 0) {
            return null;
        }

        const dates = this.fulfillments.map((fulfillment) => new Date(fulfillment.createdAt).getTime());
        const latestCreatedAt = Math.max(...dates);

        return new Date(latestCreatedAt);
    },

    get fulfilledAt() {
        if (this.fulfillments.length === 0) {
            return null;
        }
        const unfulfilled = this.fulfillments.filter((f) => !f.isFulfilled());
        if (unfulfilled.length > 0) {
            return null;
        }
        const dates = this.fulfillments.map((fulfillment) => new Date(fulfillment.fulfilledAt).getTime());
        const fulfilledAt = Math.max(...dates);

        return new Date(fulfilledAt);
    },

    isFulfilled: function isFulfilled() {
        return this.fulfilledAt !== null;
    },

    setCustomer: function setCustomer(customer) {
        this.customer = customer;
    },

    firstItemDetails: function firstItemDetails() {
        let firstItem = this.items[0];
        return firstItem.qty + ' x ' + firstItem.name;
    },

    /**
     * @returns {OrderLine[]}
     */
    getItems: function getItems() {
        return this.items;
    },

    /**
     * @return {boolean}
     */
    containsTickets: function containsTickets() {
        if (!this.items) {
            return false;
        }

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].itemType === 'Ticket' || (this.items[i].item && this.items[i].item.itemType === 'Ticket')) {
                return true;
            }
        }

        return false;
    },

    /**
     * Extract all the Memberships in this order.
     *
     * @returns {DigiTickets.Membership[]}
     */
    extractMemberships: function extractMemberships() {
        let memberships = [];

        for (let i = 0; i < this.items.length; i++) {
            let line = this.items[i];

            for (let j = 0; j < line.iteminstances.length; j++) {
                let instance = line.iteminstances[j];
                if (instance.membershipSubscription && instance.membershipSubscription.membership) {
                    memberships.push(instance.membershipSubscription.membership);
                }
            }
        }

        return memberships;
    },

    /**
     * Get a list of Members on this order.
     *
     * @returns {DigiTickets.Member[] | []}
     */
    getMembers: function getMembers() {
        let orderMembers = [];

        this.items.forEach(
            /** @param {OrderLine} orderLine */
            function (orderLine) {
                orderMembers = [].concat(orderMembers, orderLine.getMembers());
            }
        );

        return orderMembers;
    },

    getKitchenScreenReference: function getKitchenScreenReference() {
        if (this.staffRef) {
            return this.staffRef;
        }

        return this.bookingRef;
    },

    /**
     * Return a line from the cart by its line number.
     *
     * @param {number|string} lineNumber
     *
     * @returns {?DigiTickets.CartItem}
     */
    getLineByNumber(lineNumber) {
        return this.items.find((line) => line.lineNumber === lineNumber) || null;
    },

    /**
     * @return {FieldGroup}
     */
    getOrderLevelFieldGroup() {
        return this.orderLevelFieldGroup;
    },

    /**
     * @param {DigiTickets.Payment[]} payments
     */
    setPayments(payments) {
        this.payments = payments;
        this.setRemainingBalanceAndPaidStates();
    },

    /**
     * Getter to make Order compatible with Cart when getting lines.
     * For compatibility with Cart entity.
     *
     * @return {OrderLine[]}
     */
    getLines() {
        return this.items;
    }
};
