const angular = require('angular');
const is = require('../libraries/Is');

/**
 * Base-n encoder.
 *
 * @param {number} number
 * @param {string} alphabet
 * @returns {string}
 */
function baseNEncode(number, alphabet) {
    if (number == 0) {
        return alphabet.substr(0, 1);
    }

    let result = '';
    let base = alphabet.length;

    while (number) {
        let remainder = (number - 1) % base;
        number = Math.floor((number - 1) / base);
        result = alphabet.substr(remainder, 1) + result;
    }

    return result;
}

/**
 * Get the name of the class for an object.
 *
 * @param {object} obj
 * @returns {boolean|string}
 */
function getObjectClass(obj) {
    return (typeof obj !== 'object' || obj === null) ? false : /(\w+)\(/.exec(obj.constructor.toString())[1];
}

/**
 * Determines if a value is an integer.
 *
 * @param {int|string|float} value
 * @returns {boolean}
 */
function isInt(value) {
    return !isNaN(value) && parseInt(value) == value;
}

/**
 * Determines if an object is a date object.
 *
 * @param {object} date
 * @returns {boolean}
 */
function isValidDateObject(date) {
    return getObjectClass(date) === 'Date';
}


/**
 * Generate a version 4 GUID.
 * Source: http://stackoverflow.com/a/8809472/710630
 *
 * @returns {string}
 */
function generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

//
// Like Angular's extend, but merges two objects recursively.
// Modifies the original object
//
// @param {object} obj1
// @param dst
// @param {object} obj2
// @return {object}      obj1 with values overriden with those in obj2
//
let extendDeep = function extendDeep(dst) {
    angular.forEach(arguments, function (obj) {
        if (obj !== dst) {
            angular.forEach(obj, function (value, key) {
                if (dst[key] && is.anObject(dst[key])) {
                    extendDeep(dst[key], value);
                } else if (!is.aFunction(dst[key])) {
                    dst[key] = value;
                }
            });
        }
    });
    return dst;
};

/**
 * JavaScript implementation of PHP's array_combine function
 * http://locutus.io/php/array/array_combine/
 *
 * @param {array} keys
 * @param {array} values
 * @return {object}
 * @throws {Error}
 *
 * Example:
 * arrayCombine([0,1,2], ['kevin','van','zonneveld']) => {0: 'kevin', 1: 'van', 2: 'zonneveld'}
 */
function arrayCombine(keys, values) {
    const newArray = {};
    // input sanitation
    // Only accept arrays or array-like objects
    // Require arrays to have a count
    if (typeof keys !== 'object') {
        throw new Error('Parameter \'keys\' must be an array');
    }
    if (typeof values !== 'object') {
        throw new Error('Parameter \'values\' must be an array');
    }
    if (typeof keys.length !== 'number') {
        throw new Error('Parameter \'keys\' does not have a numeric length property');
    }
    if (typeof values.length !== 'number') {
        throw new Error('Parameter \'values\' does not have a numeric length property');
    }
    if (!keys.length) {
        throw new Error('Parameter \'keys\' appears to be empty');
    }
    // number of elements does not match
    if (keys.length !== values.length) {
        throw new Error('Parameters \'keys\' and \'values\' are not the same length');
    }
    for (let i = 0; i < keys.length; i++) {
        newArray[keys[i]] = values[i];
    }
    return newArray;
}

function arrayFlip(trans) {
    let key;
    let newArray = {};
    for (key in trans) {
        if (!trans.hasOwnProperty(key)) {
            continue;
        }
        newArray[trans[key]] = key;
    }
    return newArray;
}

/**
 * Get a model from the array of models given by matching its ID property.
 * The typehints are for membership things as that is all this is currently used for.
 *
 * @param {DigiTickets.Membership[]|MembershipSubscription[]} arr
 * @param {number} id
 * @param {string} [idProperty]
 *
 * @return {DigiTickets.Membership|MembershipSubscription|null}
 */
function getObjectFromArrayByID(arr, id, idProperty) {
    idProperty = idProperty !== undefined ? idProperty : 'ID';

    for (let i = 0; i < arr.length; i++) {
        if (arr[i][idProperty] === id) {
            return arr[i];
        }
    }

    return null;
}

/**
 * timeoutPromise() does the same thing as setTimeout but returns a promise which resolves after the delay.
 * Useful for promise chaining.
 *
 * @param {number} ms Amount of time to wait before resolving, in milliseconds.
 *
 * @return {Promise<any>}
 */
const timeoutPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Removes items from an object that have a value===null or are an empty array.
 *
 * @param {object} object
 */
const removeNullValues = (object) => {
    Object.keys(object).forEach((key) => {
        let value = object[key];
        if (value === null) {
            delete object[key];
        } else if (value instanceof Array && value.length === 0) {
            delete object[key];
        }
    });

    return object;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        arrayFlip,
        arrayCombine,
        baseNEncode,
        extendDeep,
        generateUUID,
        getObjectClass,
        getObjectFromArrayByID,
        isInt,
        isValidDateObject,
        removeNullValues,
        timeoutPromise
    };
}
