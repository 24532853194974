/**
 * Provides methods to create modal windows, to prevent lots of duplication with calls to
 * $modal.open all over the place.
 * Helps to enforce consistency with all modals (e.g. don't close when pressing escape or clicking outside)
 *
 * @param $modal
 */
const ModalFactory = function (
    $modal
) {
    this.$modal = $modal;
};

ModalFactory.prototype = {
    /**
     * Display a modal window.
     *
     * @param {string} className A CSS class to be added to the .modal element. (Will become value+'-modal')
     * @param {function} controller The controller to use.
     * @param {string} template The path to the template to render. ('partials/modals/name.html')
     * @param {object} [resolve] Additional data to be passed to the controller's constructor.
     *
     * @return {promise|{then, catch, finally}} Returns a promise-like object resolved when the 'OK' button is clicked,
     *     rejected when the 'Cancel' button is clicked. This is not a native Promise, but it is "thenable" so can be
     *     returned in a promise chain.
     */
    open: function open(
        className,
        controller,
        template,
        resolve
    ) {
        const modalInstance = this.$modal.open({
            templateUrl: template,
            controller,
            backdrop: 'static', // Prevent closing by clicking outside the modal.
            keyboard: false, // Prevent closing by pressing escape.
            windowClass: 'in ' + className + '-modal ' + className + '-window',
            resolve
        });

        return modalInstance.result;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ModalFactory;
}
