const { toDate, toInt, toString } = require('../../../functions/transform');

const Session = function () {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * @type {string}
     */
    this.name = '';

    /**
     * @type {Date}
     */
    this.startTime = null;

    /**
     * @type {Number}
     */
    this.startTimeYear = null;

    /**
     * @type {Number}
     */
    this.startTimeMonth = null;

    /**
     * @type {Number}
     */
    this.startTimeDay = null;

    /**
     * @type {String}
     */
    this.startTimeYMD = null;

    /**
     * @type {Date}
     */
    this.endTime = null;

    /**
     * @type {boolean}
     */
    this.multiDay = false;

    /**
     * @type {string}
     */
    this.notes = '';

    /**
     * @type {?number}
     */
    this.eventID = null;

    /**
     * Total capacity of the event.
     *
     * @type {number}
     */
    this.capacity = 0;

    /**
     * Remaining capacity of the event.
     *
     * @type {number}
     */
    this.available = 0;

    /**
     * @type {?boolean}
     */
    this.timeBased = null;

    /**
     * Custom price for this session.
     *
     * @type {?number}
     */
    this.sessionPrice = null;

    /**
     * @type {?string}
     */
    this.status = null;

    /**
     * Temporary property used when displaying this session on the session picker.
     * The SessionPickerCtrl calculates this.available minus number of spaces for this session in the cart
     * and sets it here.
     *
     * @type {number}
     */
    this.bookableSpaces = 0;
};

Session.prototype = {
    getHydrationMap() {
        return {
            available: {
                field: ['available', 'spacesAvailable'],
                transform: toInt
            },
            capacity: toInt,
            eventID: {
                field(value) {
                    return value.isHydrated ? value.eventID : value.eventCatID;
                },
                transform: toInt
            },
            ID: {
                field(data) {
                    // Note the API response uses the old 'eventID' key
                    return data.isHydrated ? data.ID : data.eventID;
                },
                transform: toInt
            },
            name: {},
            notes: {},
            timeBased: {
                field: ['timeBased', 'sessionType'],
                transform(value) {
                    return value === true || value === 'Time-based';
                }
            },
            status: toString,
            startTime: {
                field: '*',
                transform(value) {
                    if (value.startTime) {
                        return toDate(value.startTime);
                    }

                    if (value.date && value.time) {
                        return toDate(value.date + ' ' + value.time);
                    }

                    return null;
                }
            },
            endTime: {
                field: '*',
                transform(value) {
                    // We need to do a special check to see if the data is already hydrated, because
                    // 'endTime' exists in both the API data and model data but means different things.
                    // From the API there may be endDate (Y-m-d) and/or endTime (H:i:s)
                    // In the model there is endTime (Date object)
                    if (value.endTime && value.isHydrated) {
                        return toDate(value.endTime);
                    }

                    if (value.endDate != null && value.endTime != null) {
                        return new Date(value.endDate + ' ' + value.endTime);
                    } else if (value.endTime != null) {
                        return new Date(value.date + ' ' + value.endTime);
                    }

                    return null;
                }
            }
        };
    },

    afterHydration() {
        if (this.startTime) {
            this.startTimeYear = this.startTime.getFullYear();
            this.startTimeMonth = this.startTime.getMonth();
            this.startTimeDay = this.startTime.getDate();
            this.startTimeYMD = this.startTime.toYMD();
        }

        if (this.startTime && this.endTime) {
            this.multiDay = !this.startTime.isSameDay(this.endTime);
        }
    },

    getFullName() {
        let n = this.name + ' - ' + this.startTime.toFormattedDateString();

        if (this.timeBased) {
            if (this.multiDay) {
                n += ' @ ' + this.startTime.toFormattedTimeString() + ' - ' + this.endTime.toFormattedDateString() + ' @ ' + this.endTime.toFormattedTimeString();
            } else if (this.endTime) {
                n += ' (' + this.startTime.toFormattedTimeString() + ' - ' + this.endTime.toFormattedTimeString() + ')';
            } else {
                n += ' @ ' + this.startTime.toFormattedTimeString();
            }
        }

        return n;
    },

    /**
     * Get a Date object for midnight on the start date for this session.
     *
     * @return {?Date}
     */
    getStartDate() {
        if (this.startTime instanceof Date) {
            let n = new Date(this.startTime);
            n.setHours(0);
            n.setMinutes(0);
            n.setSeconds(0);
            n.setMilliseconds(0);
            return n;
        }

        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Session;
}
