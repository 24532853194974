const { toNullableBool, toInt, toNullableInt } = require('../functions/transform');

DigiTickets.Theme = function () {

};

DigiTickets.Theme.prototype = {

    getHydrationMap() {
        return {
            accentColour: {},
            active: toNullableBool,
            additionalColour: {},
            background1Colour: {},
            background1Enabled: toNullableBool,
            background1Image: {},
            background1Position: {},
            background1Repeat: {},
            background1Size: {},
            background2Colour: {},
            background2Enabled: toNullableBool,
            background2Image: {},
            background2Position: {},
            background2Repeat: {},
            background2Size: {},
            companyID: toNullableInt,
            cornerStyle: {},
            customBodyFont: {},
            customBodyFontFile: {},
            customBodyFontWeight: toNullableInt,
            customFontProvider: {},
            customTitleFont: {},
            customTitleFontFile: {},
            customTitleFontWeight: toNullableInt,
            externalLinkBackgroundColour: {},
            externalLinkLogo: {},
            externalLinkStyle: {},
            externalLinkText: {},
            externalLinkURL: {},
            footerBackgroundColour: {},
            footerLogoBackgroundColour: {},
            headerBackgroundColour: {},
            headerBackgroundImage: {},
            headerBackgroundImagePosition: {},
            headerBackgroundImageRepeat: {},
            headerBackgroundImageSize: {},
            headerBackgroundTransparent: toNullableBool,
            headerLinkColour: {},
            ID: {
                field: ['ID', 'themeID'],
                transform: toInt
            },
            logoContainerShape: {},
            logoOverlap: toNullableBool,
            logoPosition: {},
            logoShadow: toNullableBool,
            mainColour: {},
            name: {},
            nearMeEnabled: toNullableBool,
            topStripBackgroundColour: {},
            topStripBackgroundTransparent: toNullableBool,
            uppercaseTitles: toNullableBool,
            urls: {}
        };
    },

    /**
     * @param {string} name
     *
     * @return {string|null}
     */
    getStylesheetUrl: function (name) {
        if (this.urls && this.urls.hasOwnProperty(name)) {
            return this.urls[name];
        }

        return null;
    }

};
