/**
 * Good candidate to be a component instead of a directive.
 */
const cartCustomerInfoDirective = function () {
    return {
        scope: {
            customer: '='
        },
        restrict: 'E',
        replace: true,
        templateUrl: 'partials/directives/dt-cart-customer-info.html'
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = cartCustomerInfoDirective;
}
