/**
 * All possible statuses that could be sent from the terminal to the EPOS (via the progress socket).
 *
 * From the "Status ID Messages" section in "POS Client - Integration Guide V2.1"
 *
 * Any additional parameters are specified in the comment above the line.
 *
 * @type {Object<string[]>, string} statusId: ["Description", "Customer Facing Description", "icon-class"]
 */
const VerifoneStatuses = {
    0: ['Processing Transaction'],
    1: ['Waiting For Gratuity', 'Enter Gratuity', 'pause'],
    2: ['Gratuity Being Entered', null, 'pause'],
    3: ['Awaiting Card', 'Insert / Present Card', 'pause'], // TXN VALUE, CASHBACK VALUE, GRATUITY VALUE, TOTAL AMOUNT
    4: ['Swipe Card', 'Swipe Card', 'pause'], // TXN VALUE, CASHBACK VALUE, GRATUITY VALUE, TOTAL AMOUNT
    5: ['Card Inserted', 'Please Wait'],
    6: ['Card Removed', 'Please Wait'],
    7: ['Card Processing', 'Do Not Remove Card'],
    8: ['Change Card', 'Use Alternative Payment Method'],
    9: ['Contact Transaction Required'],
    10: ['Key In Card Details', 'Key Card Number', 'hand-up'], // TXN VALUE, CASHBACK VALUE, GRATUITY VALUE, TOTAL
    // AMOUNT
    11: ['Waiting For Cashback', 'Enter Cashback', 'pause'],
    12: ['Pin Entry', 'Enter PIN', 'pause'],
    13: ['Risk Management Complete'],
    14: ['Authorising Transaction', 'Please Wait'],
    15: ['Waiting For Result', 'Please Wait'],
    16: ['Auth Result Received', 'Please Wait'],
    17: ['Printing Merchant Receipt', 'Please Wait', 'print'],
    18: ['Signature Confirmation Required', 'Please Wait', 'pencil'],
    19: ['Continue Required', null, 'pause'],
    /**
     * The doc wrongly(?) says this should display info for a voice referral.
     * In practice it seems this is only used for continuing a CNP transaction. 24 is the status for showing a
     * voice referral.
     */
    20: ['Confirm Auth Code', null, 'question-sign'], // AUTH CODE
    21: ['Confirming Transaction', 'Please Wait'],
    22: ['Rejecting Transaction', 'Declined'],
    23: ['Final Result Received', 'Please Wait'],
    24: ['Voice Referral', 'Referral'], // VR TEL NO, CARD, EXPIRY, MID TID
    25: ['Remove Card', 'Remove Card', 'eject'],
    26: ['Auth Result Error'],
    27: ['Fallback To Swipe Disabled'],
    28: ['Downloading File', 'Loading'], // FILENAME, PROGRESS
    29: ['Updating PED', 'Loading'], // FILENAME, PROGRESS
    30: ['Invalid PED Configuration'],
    31: ['Card Data Retrieval'],
    32: ['Starting Transaction', 'Please Wait'],
    33: ['Performing Download', 'Loading'],
    34: ['Requesting Report', 'Please Wait'],
    35: ['Gratuity Selection Required', null, 'hand-up'],
    36: ['Expiry Date Required', 'Expires MM/YY', 'hand-up'],
    37: ['Start Date Required', 'Valid From MM/YY', 'hand-up'],
    38: ['Issue Number Required', 'Issue Number', 'hand-up'],
    39: ['AVS House Number Required', 'Enter House Number', 'hand-up'],
    40: ['AVS Post Code Required', 'Enter Post Code', 'hand-up'],
    41: ['CSC Required', 'Enter Card Security Code', 'hand-up'],
    42: ['Customer Present / Not Present Selection Required', 'Customer Present? Yes/No', 'hand-up'],
    43: ['Customer / Not Present Option Selection Required', 'hand-up'],
    44: ['Enter Charge Auth Code', 'Enter Charge Auth Code', 'hand-up'],
    45: ['Login Required', null, 'warning-sign'],
    46: ['Ready', 'Ready', 'ok-circle'],
    47: ['Card Not Accepted', 'Not Accepted'],
    48: ['Card Blocked'],
    49: ['Transaction Cancelled', 'Transaction Void'],
    50: ['Invalid Expiry'],
    51: ['Gratuity Invalid'],
    52: ['Invalid Card'],
    53: ['Printing Customer Receipt', null, 'print'],
    54: ['Initialising PED'],
    55: ['PED Unavailable', null, 'warning-sign'],
    56: ['Card Application Selection'],
    57: ['Retry Download'],
    58: ['Restart After Software Update'],
    59: ['Requesting DCC', 'Please Wait'],
    60: ['DCC Currency Choice'],
    61: ['Cardholder DCC Currency Choice', 'Please select currency'],
    62: ['Unsafe Download'],
    63: ['Unexpected Login'],
    64: ['Start Barclays Bonus Transaction'],
    65: ['Update Barclays Bonus Transaction'],
    66: ['Cancel Barclays Bonus Transaction'],
    67: ['Confirm Gratuity'],
    68: ['Register For Account On File Decision'],
    69: ['Awaiting Token ID'], // TXN VALUE, CASHBACK VALUE, GRATUITY VALUE, TOTAL AMOUNT
    70: ['Barclays Bonus Discount Summary'],
    71: ['Barclays Bonus Use Bonus'],
    72: ['Barclays Bonus Enter Redemption'],
    73: ['Barclays Bonus Not Available'],
    74: ['Download Complete'],
    75: ['Download Still Being Prepared'],
    76: ['Server Connection Failed'], // Triggers a model
    77: ['Resume Download'],
    78: ['PayPoint Account Extraction Failed'],
    79: ['PayPoint Amount Outside Allowed Range', 'Invalid Amount'],
    80: ['PayPoint Card Expired', 'Not Accepted'],
    81: ['PayPoint Initialised'],
    82: ['PayPoint Initialisation Failed'],
    83: ['PayPoint Initialising', 'Loading'],
    84: ['PayPoint Invalid Account'],
    85: ['PayPoint Invalid Amount'],
    86: ['PayPoint Invalid Capture Method'],
    87: ['PayPoint Invalid Card Number'],
    88: ['PayPoint Invalid Configuration'],
    89: ['PayPoint Invalid Denomination'],
    90: ['PayPoint Invalid Expiry Date'],
    91: ['PayPoint Invalid Scheme'],
    92: ['PayPoint Invalid Scheme Option'],
    93: ['PayPoint Invalid Top-up Type'],
    94: ['PayPoint Invalid Service Provider'],
    95: ['PayPoint Invalid Track2 Format'],
    96: ['PayPoint Invalid Transaction Type'],
    97: ['PayPoint Keyed Entry Not Allowed'],
    98: ['PayPoint Merchant Reference Required', 'Please Wait'],
    99: ['PayPoint No Accounts'],
    100: ['PayPoint Processing Transaction', 'Please Wait'],
    101: ['PayPoint Retry Confirmation Decision', 'Please Wait'],
    102: ['PayPoint Scheme Not Recognised', 'Invalid Scheme'],
    103: ['PayPoint Transaction Cancelled', 'Transaction Void'],
    104: ['PayPoint Transaction Type Not Allowed', 'Not Accepted'],
    105: ['PayPoint Select Scheme Option', 'Select Scheme'], // SCHEME ID
    106: ['PayPoint Download Required'],
    107: ['PayPoint Select Account'],
    108: ['Printing PayPoint Receipt', 'Printing Receipt'],
    109: ['Licence Detail Confirmation', 'Please Wait'], // MERCHANT NAME
    110: ['Licence File Required', 'Loading'],
    111: ['Pay Point Service Unavailable'],
    112: ['Park Retail Gift Account Extraction Failed'],
    113: ['Park Retail Gift Amount Outside Allowed Range'],
    114: ['Park Retail Gift Card Expired'],
    115: ['Park Retail Gift Initialisation Failed'],
    116: ['Park Retail Gift Initialising'],
    117: ['Park Retail Gift Invalid Account'],
    118: ['Park Retail Gift Invalid Amount'],
    119: ['Park Retail Gift Invalid Capture Method'],
    120: ['Park Retail Gift Invalid Card Number'],
    121: ['Park Retail Gift Invalid Configuration'],
    122: ['Park Retail Gift Invalid Expiry Date'],
    123: ['Park Retail Gift Invalid Track2 Format'],
    124: ['Park Retail Gift Invalid Transaction Type'],
    125: ['Park Retail Gift Keyed Entry Not Allowed'],
    126: ['Park Retail Gift Merchant Reference Required'],
    127: ['Park Retail Gift No Accounts'],
    128: ['Park Retail Gift Service Unavailable'],
    129: ['Park Retail Gift Processing Transaction'],
    131: ['Park Retail Gift Scheme Not Recognised'],
    132: ['Park Retail Gift Select Account'],
    133: ['Park Retail Gift Transaction Cancelled'],
    134: ['Park Retail Gift Transaction Type Not Allowed'],
    135: ['Printing Park Retail Gift Receipt'],
    136: ['PED In ESD Recovery Mode'],
    137: ['Update Transaction Value Decision'], // CAPTUREMETHOD, CARDDATA, SCHEMENAME, HASH, TXNVALUE, GRATUITYVALUE,
    // CBVALUE
    138: ['Update Barclaycard Freedom Config'],
    139: ['Processing Key Exchange'],
    140: ['Barclays Gift Initialising'],
    141: ['Global Blue Tax Free Shopping Initialising'],
    142: ['GiveX Initialising'],
    143: ['M-Voucher Initialising'],
    144: ['Performing Post Confirm Reversal'],
    145: ['Invalid Amount'],
    146: ['Merchant Reference Required'],
    147: ['Merchant Reference Length Invalid'],
    148: ['Initialising'],
    149: ['Account Extraction Failed'],
    150: ['Amount Outside Allowed Range'],
    151: ['Enter Amount'],
    152: ['Confirm Authorisation'],
    153: ['Card Expired'],
    154: ['Transax SV Initialisation Failed'],
    155: ['Transax SV Initialising'],
    156: ['Invalid Account'],
    157: ['Invalid Capture Method'],
    158: ['Invalid Card Number'],
    159: ['Invalid Configuration'],
    160: ['Invalid Expiry Date'],
    161: ['Invalid Track2 Format'],
    162: ['Invalid Transaction Type'],
    163: ['Keyed Entry Not Allowed'],
    164: ['No Accounts'],
    165: ['Card Scheme Not Recognised'],
    166: ['Transaction Type Not Allowed'],
    167: ['Transax SV Service Unavailable'],
    168: ['Invalid Issue Number'],
    169: ['Invalid Card Security Code'],
    170: ['Confirmation Failed'],
    171: ['Printing Receipt', null, 'print'],
    172: ['Waiting For Donation', null, 'pause'],
    173: ['Pin Blocked', null, 'warning-sign'],
    174: ['Pin Try Limit Exceeded', null, 'warning-sign'],
    175: ['Further Payment Required'],
    176: ['SVS Initialising'],
    177: ['SVS Initialisation Failed'],
    178: ['SVS Invalid Configuration'],
    179: ['SVS Service Unavailable'],
    180: ['Cannot Continue Transaction'],
    181: ['Part Payment'],
    182: ['Obtain STAN'],
    183: ['Invalid STAN'],
    184: ['IVR Required'],
    185: ['Prompt For Authorisation Code'],
    186: ['Invalid Auth Code'],
    187: ['Offline Txn Failed'],
    188: ['Enter Reference'],
    189: ['Print Shop Copy'],
    190: ['Must Insert Card'],
    191: ['PIN Entered'],
    192: ['Card Swiped'],
    193: ['PIN Bypassed'],
    194: ['Cancelling Transaction'],
    195: ['Card Presented'],
    196: ['Server Error'],
    197: ['On Hold Transaction'],
    198: ['Reactivation Required'],
    204: ['Optional Download Available'],
    205: ['Card Not Recognised'],
    206: ['Card Scheme Does Not Allow Transaction'],
    207: ['Choose Account'],
    208: ['ConnectionFailed_Download'],
    209: ['Submitting Offline Txns']
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneStatuses;
}
