const ItemEditableStatus = Object.freeze({
    IS_EDITABLE: 'isEditable',
    IS_EXPIRED: 'isExpired',
    IS_NOT_EDITABLE: 'isNotEditable',
    IS_NOT_TICKET: 'isNotTicket',
    IS_REDEEMED: 'isRedeemed'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ItemEditableStatus;
}
