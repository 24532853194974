const listCustomerInfoDirective = function () {
    return {
        scope: {
            customer: '='
        },
        restrict: 'AE',
        templateUrl: 'partials/directives/dt-list-customer-info.html'
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = listCustomerInfoDirective;
}
