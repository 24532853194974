//
// This object encapsulates the Payment Express response in an object, with a getter methods
// to retrieve the value, or a specific attribute, of the given element. This way, it doesn't matter
// to the outside world about the internal representation.
// At the moment we convert to a JQuery document, and use its selectors to access the elements.
//
// @param responseXML
//
DigiTickets.PaymentExpressResponse = function (responseXML) {
    this.responseObject = null;
    this.responseXML = responseXML;

    this.init(responseXML);
};

DigiTickets.PaymentExpressResponse.prototype = {

    init: function init(responseXML) {
        // We use JQuery to traverse the XML, otherwise we'd have to add plugins, etc to the EPOS.
        // I noticed, though, that because of this, it seems to "lose" 'TR' elements, presumably
        // because it thinks they're a table row not in a table. So, I modify the raw XML before
        // it's transformed. "PE" stands for "Payment Express".
        responseXML = responseXML ? responseXML.replace('<TR>', '<TR-PE>').replace('</TR>', '</TR-PE>') : '';
        this.responseObject = $(responseXML);
    },

    transformTagName: function transformTagName(tagName) {
        // See the comment in init() above.
        if (tagName.toLowerCase() == 'tr') {
            tagName += '-PE';
        }

        return tagName;
    },

    getRawResponse: function getRawResponse() {
        return this.responseXML;
    },

    /**
     * Return the value of the given element.
     *
     * @param tagName
     * @returns {*}
     */
    getContent: function getContent(tagName) {
        tagName = this.transformTagName(tagName);

        // There was a weird problem with their responses where they sometimes included
        // the same tag twice, which played havoc with our accessor, hence using .eq(0).
        // It just makes sure it picks up exactly 1 matching element.
        return this.responseObject.find(tagName).eq(0).text();
    },

    /**
     * Return the value of the given attribute in the given element.
     *
     * @param tagName
     * @param attribute
     * @returns {*}
     */
    getAttr: function getAttr(tagName, attribute) {
        tagName = this.transformTagName(tagName);

        return this.responseObject.find(tagName).eq(0).attr(attribute);
    },

    getCardReceipt: function getCardReceipt() {
        return this.getContent('Rcpt');
    },

    getErrorMessage: function getErrorMessage() {
        return this.getResponseCode() + ' ' + this.getResponseText();
    },

    getResponseCode: function getResponseCode() {
        // For initiate transactions the response code is 'ReCo'.
        // For status transactions the response code is 'RC'.
        if (this.getContent('ReCo')) {
            return this.getContent('ReCo');
        }

        return this.getContent('RC');
    },

    getResponseText: function getResponseText() {
        return this.getContent('RT');
    },

    getTxnRef: function getTxnRef() {
        return this.getContent('TxnRef');
    },

    isComplete: function isComplete() {
        return parseInt(this.getContent('Complete')) === 1;
    },

    isApproved: function isApproved() {
        return parseInt(this.getContent('AP')) === 1;
    },

    /**
     * @return {boolean}
     */
    isError: function isError() {
        let responseCode = this.getResponseCode();

        // Response code only exists if there is an error.
        return !!(responseCode && parseInt(responseCode) !== 0);
    },

    getScreenUpdate: function getScreenUpdate() {
        return {
            messageLine1: this.getContent('DL1'),
            messageLine2: this.getContent('DL2'),
            button1: {
                enabled: this.getAttr('B1', 'en'),
                label: this.getContent('B1')
            },
            button2: {
                enabled: this.getAttr('B2', 'en'),
                label: this.getContent('B2')
            }
        };
    },

    /**
     * @return {string|null}
     */
    getCardTypeRef: function getCardTypeRef() {
        let ct = this.getContent('CT');
        if (!ct) {
            return null;
        }

        // I couldn't find a list of the possible 'CT' values so these are just what was found
        // by using various card types and seeing what comes out.
        switch (ct.toLowerCase()) {
            case 'amex':
                return DigiTickets.CardTypeRef.AMEX;
            case 'diners':
                return DigiTickets.CardTypeRef.DINERS;
            case 'maestro':
                return DigiTickets.CardTypeRef.MAESTRO;
            case 'mastercard':
                return DigiTickets.CardTypeRef.MASTERCARD;
            case 'visa':
                return DigiTickets.CardTypeRef.VISA_CREDIT;
            default:
                return null;
        }
    }

};
