const OnlineResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'status/',
        ApiRequestParams.defaults(),
        {
            ping: {
                cache: false,
                method: 'GET'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OnlineResource;
}
