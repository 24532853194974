/**
 * Provides common functionality for what should be done when an order/membership/member barcode is scanned.
 *
 * @param {NavigationService} navigationService
 * @param Notification
 * @param {OrderManager} OrderManagerService
 * @param {OrderSearchCache} orderSearchCache
 */
const RedemptionScanHandler = function (
    navigationService,
    Notification,
    OrderManagerService,
    orderSearchCache
) {
    this.navigationService = navigationService;
    this.notification = Notification;
    this.orderManagerService = OrderManagerService;
    this.orderSearchCache = orderSearchCache;

    /**
     * @type {?string}
     */
    this.lastScannedMemberRef = null;
};

RedemptionScanHandler.prototype = {
    /**
     * @param {string} query
     * @param {function|null} callback A callback that is provided the search results.
     * @param {boolean} wasBarcodeScan
     */
    search: function search(query, callback, wasBarcodeScan) {
        this.orderManagerService.search(
            {
                search: query
            },
            (results, wasOnlineSearch) => {
                let wasRedirected = this.redirectFromSearchResults(query, results, wasBarcodeScan);
                if (callback) {
                    callback(query, results, wasOnlineSearch, wasRedirected);
                }
            },
            (error) => {
                this.notification.error(
                    'An error occurred whilst searching, please check your internet connection.'
                );
            }
        );
    },

    /**
     * Check and redirect if the user should be automatically taken to a view page from the results.
     *
     * @param {string} query
     * @param {DigiTickets.Order[]} results
     * @param {boolean} wasBarcodeScan
     *
     * @return {boolean}
     */
    redirectFromSearchResults: function redirectFromSearchResults(query, results, wasBarcodeScan) {
        let uppercaseQuery = query.toUpperCase();

        // Go through all the orders in the results and extract the memberships from them.
        let membershipsInOrderResults = {};
        let membershipRefsForMemberRef = [];

        for (let o = 0; o < results.length; o++) {
            let thisOrder = results[o];

            let orderMemberships = thisOrder.memberships.filter(function removeDead(membership) {
                return !membership.isDead();
            });

            for (let m = 0; m < orderMemberships.length; m++) {
                let thisMembership = orderMemberships[m];
                let membershipRef = thisMembership.ref.toUpperCase();

                membershipsInOrderResults[membershipRef] = thisMembership;

                // Check if this membership contains a contact with the same ref as the search term.
                let refMember = thisMembership.getMemberByRef(uppercaseQuery);
                if (refMember) {
                    thisMembership.highlightMemberRef = uppercaseQuery;
                    if (membershipRefsForMemberRef.indexOf(membershipRef) === -1) {
                        membershipRefsForMemberRef.push(membershipRef);
                    }
                }
            }
        }

        // If the search query was scanned in and it matches a member's ref, remember
        // that ref was scanned.
        if (wasBarcodeScan && membershipRefsForMemberRef.length > 0) {
            this.setLastScannedMemberRef(uppercaseQuery);
        }

        if (membershipRefsForMemberRef.length === 1) {
            // If the orders contained exactly one membership the searched contact/ref appears on,
            // go straight to that membership. Can't redirect if there is more than one membership found
            // because we don't know which one to go to.
            this.navigationService.viewMembershipDetails(membershipRefsForMemberRef[0], uppercaseQuery);
            return true;
        } else if (membershipsInOrderResults.hasOwnProperty(uppercaseQuery)) {
            // If the memberships contained a membership with a ref the same as the search term,
            // go straight to that membership.
            this.navigationService.viewMembershipDetails(uppercaseQuery, null);
            return true;
        } else if (results.length === 1 && results[0].bookingRef) {
            // Go straight to the order if there is only one order in the result.

            // Clear the search cache because the user won't be able to get back to the search screen otherwise!
            // (They'd keep being redirected)
            this.orderSearchCache.clear();
            this.navigationService.viewOrderDetails(results[0].bookingRef, wasBarcodeScan);
            return true;
        }

        if (membershipRefsForMemberRef.length > 0) {
            // Not redirecting straight to the membership for this member ref because there were multiple
            // results. Remember what member ref was scanned.
            this.setLastScannedMemberRef(uppercaseQuery);
        }

        return false;
    },

    /**
     * Instead of using something like 'wasBarcodeScan' in the URL to the memberships page to indicate
     * auto redempton should be triggered, that flag is set here (this is a singleton).
     * This prevents accidentally redeeming a member when refreshing or using the back button as would
     * happen if using the URL.
     *
     * @param {string} memberRef
     */
    setLastScannedMemberRef: function setLastScannedMemberRef(memberRef) {
        this.lastScannedMemberRef = memberRef;
    },

    pullLastScannedMemberRef: function pullLastScannedMemberRef() {
        let ref = this.lastScannedMemberRef;
        this.lastScannedMemberRef = null;

        return ref;
    },

    viewSearchResults: function viewSearchResults(query, results, wasOnlineSearch) {
        this.orderSearchCache.clear();
        this.orderSearchCache.form.search = query;
        this.orderSearchCache.results = results;
        this.orderSearchCache.onlineResult = wasOnlineSearch;

        this.navigationService.viewRedeem();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = RedemptionScanHandler;
}
