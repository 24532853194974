const VariableDiscount = require('./VariableDiscount');

/**
 * Can hold one or more VariableDiscount objects for an order line and provides methods to filter and store them.
 * When constructing it accepts an array of 'dumb' objects which will be casted to VariableDiscount models.
 *
 * @param {Object<VariableDiscount>} [variableDiscounts]
 */
const VariableDiscountsCollection = function (variableDiscounts) {
    /**
     * @type {Object<VariableDiscount>}
     */
    this.discounts = variableDiscounts || {};
};

VariableDiscountsCollection.prototype = {
    /**
     * @param {number} offerID
     *
     * @returns {?VariableDiscount}
     */
    getVariableDiscount(offerID) {
        return this.discounts[offerID] || null;
    },

    /**
     * @param {VariableDiscount} variableDiscount
     */
    addVariableDiscount(variableDiscount) {
        this.discounts[variableDiscount.offerID] = variableDiscount;
    },

    /**
     * Add or update a VariableDiscount by providing values rather than a VariableDiscount model.
     *
     * @param {number} offerID
     * @param {number} amount
     * @param {string} narrative
     */
    storeVariableDiscount(offerID, amount, narrative) {
        let discount = this.getVariableDiscount(offerID);

        if (!discount) {
            discount = new VariableDiscount(offerID);
        }

        discount.setAmount(amount);
        discount.setNarrative(narrative);

        this.addVariableDiscount(discount);
    },

    /**
     * Delete a VariableDiscount
     *
     * @param {number} offerID
     *
     * @returns {boolean}
     */
    deleteVariableDiscount(offerID) {
        if (this.discounts.hasOwnProperty(offerID)) {
            delete this.discounts[offerID];
            return true;
        }

        return false;
    },

    getHydrationMap() {
        return {
            discounts: {
                modelCollection: VariableDiscount
            }
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VariableDiscountsCollection;
}
