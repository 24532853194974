const apiErrorMessage = require('./Api/apiErrorMessage');
const CartItemDiscount = require('./Offers/CartItemDiscount');

/**
 * @param $filter
 * @param $q
 * @param {Hydrator} hydrator
 * @param OfferResource
 */
DigiTickets.OfferManager = function (
    $filter,
    $q,
    hydrator,
    OfferResource
) {
    this.$filter = $filter;
    this.$q = $q;
    this.hydrator = hydrator;
    this.offerResource = OfferResource;

    /**
     * A promise resolved once when all the offers are loaded (from the API or cache).
     */
    this.deferred = $q.defer();

    /**
     * All offers downloaded from the API. Keyed by offerID.
     *
     * @private
     *
     * @type {Object<DigiTickets.Offer>}
     */
    this.offers = {};

    /**
     * All offers that could be manually applied to a single line. Keyed by offerID.
     *
     * @type {Object<DigiTickets.Offer>}
     */
    this.manualLineOffers = {};

    /**
     * All offers that could be manually applied to the whole cart. Keyed by offerID.
     *
     * @type {Object<DigiTickets.Offer>}
     */
    this.manualCartOffers = {};
};

DigiTickets.OfferManager.prototype = {
    loadOffers: function loadOffers() {
        let self = this;

        this.offerResource.query(
            function (offers) {
                self.offers = {};
                self.manualLineOffers = {};
                self.manualCartOffers = {};

                for (let i = 0; i < offers.length; i++) {
                    let offer = self.hydrator.hydrate(
                        offers[i],
                        new DigiTickets.Offer()
                    );
                    self.offers[offer.ID] = offer;

                    // If the offer requires the operator to press a button to activate (equivalent to Discounts),
                    // add it to the separate collection.
                    if (offer.isManuallyAppliedToCart()) {
                        self.manualCartOffers[offer.ID] = offer;
                    } else if (offer.isManuallyAppliedToLine()) {
                        self.manualLineOffers[offer.ID] = offer;
                    }
                }

                self.deferred.resolve(self.offers);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return this.deferred.promise;
    },

    /**
     * @param {number} id
     *
     * @return {DigiTickets.Offer|null}
     */
    getOffer: function getOffer(id) {
        if (this.offers.hasOwnProperty(id)) {
            return this.offers[id];
        }

        return null;
    },

    /**
     * @return {Object<DigiTickets.Offer>}
     */
    getManualLineOffers: function getManualLineOffers() {
        return this.manualLineOffers;
    },

    /**
     * @return {Object<DigiTickets.Offer>}
     */
    getManualCartOffers: function getManualCartOffers() {
        return this.manualCartOffers;
    },

    /**
     * TODO: applyOffersToCart and applyManualLineOffers can be moved to a separate 'OfferApplicator' class.
     */

    /**
     * Apply automatic offers returned by API.
     *
     * @param {CartService} cart
     * @param {object} offerResponse Offer calculation results from server.
     */
    applyOffersToCart: function applyOffersToCart(cart, offerResponse) {
        // The offerResponse is the discounts object from the /cartcalculations API endpoint.
        for (let lineNumber in offerResponse) {
            if (offerResponse.hasOwnProperty(lineNumber)) {
                let line = cart.getLineByNumber(lineNumber);

                for (let i = 0; i < offerResponse[lineNumber].length; i++) {
                    /** @type {CartItemDiscount} */
                    let itemDiscount = this.hydrator.hydrate(
                        offerResponse[lineNumber][i],
                        new CartItemDiscount()
                    );

                    if (itemDiscount.offerID) {
                        itemDiscount.offer = this.getOffer(itemDiscount.offerID);
                    }

                    if (itemDiscount.reason === 'gift-voucher') {
                        itemDiscount.description = this.$filter('lang')('GIFT_VOUCHERS.VOUCHER');
                    }

                    line.addDiscount(itemDiscount);
                }
            }
        }
        cart._triggerOnChange();
    },

    /**
     * Apply manual offers/discounts selected by operator.
     * This loops through each item in the cart. It removes any existing CartItemDiscounts from manual offers.
     * If the line has a manual offer set it creates a CartItemDiscount for that offer, and deactivates all other
     * offers on the line.
     * If it does not have a manual offer it activates all CartItemDiscounts on the line.
     *
     * @param {CartService} cart
     */
    applyManualLineOffers: function applyManualLineOffers(cart) {
        for (let i = 0; i < cart.itemList.length; i++) {
            /** @type {DigiTickets.CartItem} */
            const cartItem = cart.itemList[i];

            const manualOffer = cartItem.getManualOffer();

            // A manual discount overrides all other discounts on the line.
            // Disable any existing discounts
            let existingDiscounts = cartItem.getDiscounts();

            // Reverse loop so the splicing doesn't affect the position
            for (let d = existingDiscounts.length - 1; d >= 0; d--) {
                const lineDiscount = existingDiscounts[d];

                if (lineDiscount.manual) {
                    // Remove any existing manual discount
                    existingDiscounts = existingDiscounts.splice(d, 1);
                } else {
                    // Remove cart level discounts unless the discount we have just added is variable.
                    // Variable discounts should apply after other discounts.
                    if (manualOffer && manualOffer.valueType !== 'variable') {
                        lineDiscount.setActive(!manualOffer);
                    }
                }
            }

            if (manualOffer) {
                const manualItemDiscount = new CartItemDiscount();
                manualItemDiscount.offer = manualOffer;
                manualItemDiscount.offerID = manualOffer.ID;

                manualItemDiscount.manual = true;
                manualItemDiscount.qty = cartItem.getQuantity();

                switch (manualOffer.valueType) {
                    case 'percent':
                        manualItemDiscount.percentage = manualOffer.value;
                        manualItemDiscount.amount = Math.preciseRound((manualOffer.value * cartItem.baseTotal / 100), 2);
                        break;
                    case 'value':
                        manualItemDiscount.amount = manualOffer.value * cartItem.quantity;
                        if (Math.abs(manualItemDiscount.amount) > Math.abs(cartItem.baseTotal)) {
                            manualItemDiscount.amount = cartItem.baseTotal;
                        }
                        manualItemDiscount.percentage = Math.preciseRound((manualItemDiscount.amount * 100) / cartItem.baseTotal, 4);
                        break;
                    case 'variable':
                        // Only apply a single instance of a variable discount per line therefore qty = 1.
                        // This may need to be revisited to spread the discount of all items in the line.
                        manualItemDiscount.qty = 1;

                        manualItemDiscount.amount = manualOffer.value;

                        manualItemDiscount.percentage = Math.preciseRound((manualItemDiscount.amount * 100) / cartItem.baseTotal, 4);
                        break;
                }

                cartItem.addDiscount(manualItemDiscount);
            }
        }
    }
};
