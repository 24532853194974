const ChangeCalculator = require('../../../libraries/DigiTickets/Payment/ChangeCalculator');
const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

/**
 * This is the controller for the inner part of the payment modal for the payment method of "Cash".
 *
 * @param $scope
 * @param $filter
 * @param {CartService} cartService
 * @param Notification
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 */
const CashCtrl = function CashCtrl(
    $scope,
    $filter,
    cartService,
    Notification,
    NumPadFactory
) {
    $scope.thisPaymentMethodRef = PaymentMethodRef.CASH;
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    $scope.cart = cartService;

    $scope.calculator = new ChangeCalculator();
    $scope.numPad = NumPadFactory.create(
        {
            showCashButtons: true,
            showSignButton: false
        },
        function (value) {
            // When the number pad value changes update the calculator.
            $scope.calculator.setAmountTendered(value);
        }
    );
    $scope.numPadListening = false;

    $scope.clear = function clear() {
        $scope.calculator.clear();
        $scope.numPad.clear();
    };

    $scope.setAmountDue = function setAmountDue(amount) {
        $scope.calculator.setAmountDue(amount);
        $scope.numPad.setExactAmount(amount);
        $scope.numPad.options.isNegative = amount < 0;
    };

    $scope.setAmountDue($scope.cart.getRemainingBalance());

    $scope.$on('payment-modal.payment-removed', function () {
        $scope.setAmountDue($scope.cart.getRemainingBalance());
    });

    $scope.$on('payment-modal.cancel-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Was cancelled from the cash tab.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        // Changing tab, so reset the calculator.
        $scope.clear();
        $scope.setAmountDue($scope.cart.getRemainingBalance());

        $scope.numPadListening = newPaymentMethodRef === $scope.thisPaymentMethodRef;
    });

    $scope.$on('payment-modal.ok-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            let amountTendered = $scope.calculator.getAmountTendered();

            // If the cart doesn't allow partial payments (currently so if it contains memberships), then
            // we need to check that the cash paid is at least as big as the order total.
            $scope.$parent.clearError();
            if ($scope.cart.getRemainingBalance() > 0 && !$scope.cart.allowPartialPayments()) {
                // Partial payments are not allowed.
                if (amountTendered < $scope.cart.getRemainingBalance()) {
                    // They haven't paid enough, so we can't accept it.
                    $scope.$parent.setError('Partial payments are not allowed for this order');
                    return;
                }
            }

            // Add a payment for the amount we have entered,
            // instead of the PaymentCtrl creating a payment for the entire remaining balance.
            $scope.preventAddFullPaymentOnClose();

            if ($scope.cart.isRefund() && amountTendered < $scope.cart.getRemainingMaxPayment()) {
                Notification.error(
                    $filter('lang')('PAYMENT.MAX_REFUND_AMOUNT_EXCEEDED_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
                );

                $scope.preventClose();
                return false;
            }

            if ((!$scope.cart.isRefund() && amountTendered > 0) || ($scope.cart.isRefund() && amountTendered < 0)) {
                let payment = new DigiTickets.Payment();
                payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
                payment.setTendered(amountTendered);
                payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
                $scope.addPayment(payment);
            }

            // Clear for next use.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CashCtrl;
}
