const Customer = require('../../models/Customer');
const CustomerFormCtrl = require('./CustomerFormCtrl');
const { cloneDeep } = require('../../functions/clone');
const { containsDeliverableItem } = require('../../libraries/DigiTickets/Cart/cartContents');

/**
 * @param $filter
 * @param $location
 * @param $modal
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {Customer} customer
 * @param {CustomerAccountService} CustomerAccountService
 * @param {string|{message: string, type: string}} errorMsg
 * @param {NotificationService} NotificationService
 * @param {UserService} UserService
 */
const CustomerDetailsCtrl = function CustomerDetailsCtrl(
    $filter,
    $location,
    $modal,
    $modalInstance,
    $scope,
    $timeout,
    cartService,
    customer,
    CustomerAccountService,
    errorMsg,
    NotificationService,
    UserService
) {
    $scope.isValid = true;

    /**
     * We need this simple setter as it can be called from child controllers (FieldsCtrl).
     * If FieldsCtrl tries to directly assign $scope.isValid it doesn't actually change the variable in this $scope,
     * but if FieldsCtrl calls setValid it does.
     *
     * @param isValid
     */
    $scope.setValid = (isValid) => {
        $scope.isValid = isValid;
    };

    $scope.minQueryLength = 3;

    $scope.search = {
        query: '',
        resultsQuery: '',
        /**
         * @type {DigiTickets.CustomerAccount[]|null}
         */
        results: null,
        loading: false
    };
    $scope.searchScrollerControl = {};

    $scope.isValidSearchQuery = function isValidSearchQuery(query) {
        return query.length >= $scope.minQueryLength;
    };

    /**
     * @param {DigiTickets.Address} address
     */
    const setDefaultDeliveryCountry = (address) => {
        if (address.isEmpty()) {
            address.country = UserService.currentBranch.country;
        }
    };

    $scope.cart = cartService;
    $scope.cartService = cartService;
    $scope.cartContainsDeliverableItem = containsDeliverableItem($scope.cart.getItems());
    // Set default delivery country
    if ($scope.cartContainsDeliverableItem) {
        setDefaultDeliveryCountry(customer.deliveryAddress);
    }

    /**
     * We get passed in a clone of the cart's current customer on load. We'll update this one and send it back
     * as the result only if OK is pressed. If the modal is closed without pressing OK the changes to this are lost.
     *
     * @type {Customer}
     */
    $scope.customer = customer;

    $scope.user = UserService;

    $scope.errorMsg = errorMsg && typeof errorMsg === 'object' ? errorMsg.text : errorMsg;
    $scope.setErrorMsg = (newErrorMsg) => {
        $scope.errorMsg = newErrorMsg;
    };
    $scope.errorMsgType = errorMsg && typeof errorMsg === 'object' ? errorMsg.type : 'danger';

    /**
     * Close the dialog, and return the changes to the opener.
     */
    $scope.ok = function () {
        $scope.setValid(true);
        $scope.setErrorMsg(null);

        let resultToReturn = {
            customer: $scope.customer
        };

        // Broadcast to child controllers that ok has been pressed and should run their validation, and add anything
        // they want to return to the result object.
        $scope.$broadcast('customer-details-modal.ok-pressed', resultToReturn);

        $timeout(() => {
            if (!$scope.isValid) {
                return;
            }

            $modalInstance.close(resultToReturn);
        });
    };

    /**
     * Just close the dialog without returning anything to the opener.
     * (No changes should be saved)
     */
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.searchCustomerAccounts = function searchCustomerAccounts() {
        if ($scope.search.loading) {
            return false;
        }

        if (!$scope.isValidSearchQuery($scope.search.query)) {
            NotificationService.error('MIN_QUERY_LENGTH_ERROR');
            return;
        }

        $scope.resultsQuery = $scope.search.query;
        $scope.search.results = [];

        $scope.search.loading = true;
        CustomerAccountService.query(
            {
                q: $scope.search.query
            },
            function (results) {
                // Sort out which contacts to show in the search results.
                for (let i = 0; i < results.length; i++) {
                    $scope.prepareAccountContactsForList(results[i]);
                }

                $scope.search.results = results;
                $scope.search.loading = false;
            },
            function () {
                $scope.search.results = {};
                $scope.search.loading = false;
            }
        );
    };

    $scope.prepareAccountContactsForList = function prepareAccountContactsForList(account) {
        let listContacts = [];
        let noNameContacts = [];

        for (let i = 0; i < account.contacts.length; i++) {
            if (account.contacts[i].ID === account.primaryContact.ID) {
                // Skip the primary contact because they are shown at the top.
                continue;
            }

            if (account.contacts[i].ref === $scope.search.query) {
                // If the contact's ref is the ref which was searched for, put that contact at the top of the list.
                listContacts.unshift(account.contacts[i]);
            } else if (!account.contacts[i].getFullName()) {
                // Put no names at the end.
                noNameContacts.push(account.contacts[i]);
            } else {
                listContacts.push(account.contacts[i]);
            }
        }

        // Add no name contacts back at the end.
        listContacts = listContacts.concat(noNameContacts);

        let length = listContacts.length;
        if (length <= 4) {
            // Show up to 3 contacts and "+x more" item.
            // If there are 4, there is no point in showing "+1  more". We can just use the space to show the 1 more.
            account.additionalContactsList = listContacts;
            account.additionalContactsOverflow = 0;
        } else {
            account.additionalContactsList = listContacts.splice(0, 3);
            account.additionalContactsOverflow = length - 3;
        }
    };

    $scope.searchCustomerKeydown = function searchCustomerKeydown($event) {
        if ($event.which === 13) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.searchCustomerAccounts();
        }
    };

    /**
     * Select a customer account from the search results.
     *
     * @param {DigiTickets.CustomerAccount} account
     */
    $scope.selectCustomerAccount = function selectCustomerAccount(account) {
        // Clear existing customer data.
        $scope.customer = new Customer();
        setDefaultDeliveryCountry($scope.customer.deliveryAddress);

        // Set account.
        // TODO: Prompt which address / contact to use?
        $scope.customer.setAccount(account);

        // Set membership (if any).
        // TODO: Prompt which membership to use?
        let membership = account.getActiveMembership();
        if (membership) {
            $scope.customer.setMembership(membership);
        }
    };

    $scope.clearCustomer = function clearCustomer() {
        $scope.customer = new Customer();
    };

    $scope.showEditCustomerForm = function showEditCustomerForm(customer) {
        if (customer.account) {
            // Redirect off to the manage account page
            $location.path('/account/' + customer.account.ID);
            $scope.cancel();
        } else {
            const clonedCustomer = cloneDeep(customer);
            return $scope.showCustomerForm(clonedCustomer, false);
        }
    };

    $scope.showNewCustomerForm = function showNewCustomerForm() {
        let customer = new Customer();
        return $scope.showCustomerForm(customer, true);
    };

    $scope.showCustomerForm = function showCustomerForm(customerObject, createAccountStartsChecked) {
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/customerForm.html',
            controller: CustomerFormCtrl,
            // static backdrop prevents modal being closed by clicking on background
            backdrop: 'static',
            resolve: {
                customer: function customer() {
                    return customerObject;
                },
                errorMsg: function getErrorMsg() {
                    return errorMsg;
                },
                createAccount: function createAccount() {
                    return createAccountStartsChecked;
                }
            },
            windowClass: 'in customer-form-window'
        });

        modalInstance.result.then(
            function (data) {
                // If 'Create an account for this customer' is ticked then a Digitickets.CustomerAccount object is
                // returned otherwise a Customer object is returned
                if (data.customerAccount) {
                    $scope.customer.setAccount(data.customerAccount);
                } else {
                    $scope.customer = data.customer;
                }
            },
            function () {
                // Cancelled
            }
        );
    };

    $scope.fieldInstanceIsNotAskEarly = function fieldInstanceIsNotAskEarly(fieldInstance) {
        return !fieldInstance.field.askEarly;
    };

    $scope.fieldInstanceIsAskEarly = function fieldInstanceIsAskEarly(fieldInstance) {
        return fieldInstance.field.askEarly;
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerDetailsCtrl;
}
