const KitchenOrderItem = require('./KitchenOrderItem');
const { cloneDeep } = require('../../../functions/clone');

/**
 * Returns the difference in items between 2 KitchenOrders.
 *
 * @param {KitchenOrder} orderA
 * @param {KitchenOrder} orderB
 *
 * @return {?{added: KitchenOrderItem[], removed: KitchenOrderItem[]}} Returns null if no difference.
 */
const kitchenOrderDiff = (orderA, orderB) => {
    /**
     * @type {{added: KitchenOrderItem[], removed: KitchenOrderItem[]}}
     */
    let result = {
        added: [],
        removed: []
    };

    const cloneItem = (kitchenLine, qty) => Object.assign(
        new KitchenOrderItem(),
        cloneDeep(kitchenLine)
    ).setQty(qty);

    /** @type {Object<KitchenOrderItem>} */
    let itemsInA = {};
    for (let i = 0; i < orderA.items.length; i++) {
        itemsInA[orderA.items[i].hash] = orderA.items[i];
    }

    /** @type {Object<KitchenOrderItem>} */
    let itemsInB = {};
    for (let i = 0; i < orderB.items.length; i++) {
        itemsInB[orderB.items[i].hash] = orderB.items[i];

        let item = orderB.items[i];

        if (itemsInA.hasOwnProperty(item.hash)) {
            let qtyDiff = itemsInA[item.hash].qty - item.qty;
            if (qtyDiff < 0) {
                // More in order B than A = some added in order B.
                result.added.push(cloneItem(item, Math.abs(qtyDiff)));
            } else if (qtyDiff > 0) {
                // More in order A than B = some removed in order B.
                result.removed.push(cloneItem(item, -Math.abs(qtyDiff)));
            }
            // Else they're the same qty - no difference.
        } else {
            // Entirely new line.
            result.added.push(cloneItem(item, item.qty));
        }
    }

    Object.keys(itemsInA).forEach(
        (hash) => {
            if (!itemsInB.hasOwnProperty(hash)) {
                // Whole line from A missing in B. It was removed.
                result.removed.push(cloneItem(itemsInA[hash], -Math.abs(itemsInA[hash].qty)));
            }
        }
    );

    if (result.added.length === 0 && result.removed.length === 0) {
        return null;
    }

    return result;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        kitchenOrderDiff
    };
}
