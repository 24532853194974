const ConsoleLogger = require('./ConsoleLogger');

const logFactory = (name, debugEnabled) => new ConsoleLogger(
    name,
    debugEnabled
);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = logFactory;
}
