DigiTickets.MembershipCardAllocationType = Object.freeze({
    /**
     * One card per membership.
     */
    PLAN: 'plan',
    /**
     * One card per member on the membership.
     */
    MEMBER: 'member',
    /**
     * One card per *adult* member on the membership (none for children).
     */
    ADULTS: 'adults'
});
