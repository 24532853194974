/**
 * @param $resource
 * @param {ApiRequestParams} ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {CurrentDevice} CurrentDevice
 */
const RedemptionResource = function ($resource, ApiRequestParams, AppConfig, CurrentDevice) {
    return $resource(
        AppConfig.apiBaseUrl + 'redemptions',
        ApiRequestParams.defaults(),
        {
            redeem: {
                method: 'POST',
                params: {
                    deviceID: function deviceID() {
                        return CurrentDevice.getDeviceId();
                    },
                    triggeredAt: function triggeredAt() {
                        return new Date().toISOString();
                    }
                }
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = RedemptionResource;
}
