const io = require('socket.io-client');

/**
 * Initializes and authenticates a WebSocket connection to the DigiTickets 'socket' server.
 * Used for kitchen and kitchen-customer screens to receive instant updates pushed from the app server via the socket.
 *
 * Note that this is nothing to do with the ProPoint Agent / Verifone / WorldPay WebSockets.
 * This is about the connection from the kitchen screen to the hosted "DigiTickets Socket Server".
 *
 * @param {$q} $q
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {socketFactory} socketFactory angular-socket-io's "socketFactory"
 */
const SocketConnector = function (
    $q,
    AppConfig,
    socketFactory
) {
    this.$q = $q;

    this.socketUrl = AppConfig.socketUrl;
    this.ioSocket = io(this.socketUrl);
    this.socket = socketFactory(
        {
            ioSocket: this.ioSocket
        }
    );
};

SocketConnector.prototype = {
    /**
     * @return {*}
     */
    getSocket: function () {
        return this.socket;
    },

    /**
     * @param {string} apiKey
     * @param {number} branchID
     *
     * @return {promise|{then, catch, finally}}
     */
    authenticate: function (apiKey, branchID) {
        let deferred = this.$q.defer();
        let promise = deferred.promise;

        this.getSocket().emit(
            'authenticate',
            {
                apiKey,
                branchID
            },
            function (response) {
                if (response.success) {
                    deferred.resolve(response);
                } else {
                    deferred.reject(response);
                }
            }
        );

        return promise;
    },

    /**
     * @param {string} type
     * @param {object} data
     */
    sendMessage: function (type, data) {
        let deferred = this.$q.defer();
        let promise = deferred.promise;

        this.getSocket().emit(
            'message',
            {
                type,
                data
            },
            function (response) {
                if (response.success) {
                    deferred.resolve(response);
                } else {
                    deferred.reject(response);
                }
            }
        );

        return promise;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SocketConnector;
}
