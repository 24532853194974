/**
 * Note this is not a singleton. An instance is created each time the session picker modal is opened
 * so it can cache the existing sessions in the cart/order.
 *
 * @param {CartService} [cart]
 * @param {DigiTickets.Order} [existingOrder]
 */
const SessionSpaceCalculator = function (
    cart,
    existingOrder
) {
    /**
     * @type {?CartService}
     */
    this.cart = cart;

    /**
     * @type {?DigiTickets.Order}
     */
    this.existingOrder = existingOrder;

    /**
     * Sometimes we want to manually adjust the number of spaces displayed for a session.
     * e.g. when we are bulk moving lines to a new session we want to display the availability of every session,
     * without deducting those lines that are being moved (normally they would be deducted because they are already
     * in the cart).
     *
     * To do this set this property to an object with a positive or negative number for each sessionID.
     *
     * @type {Object<number>}
     */
    this.sessionSpaceAdjustments = {};

    /**
     * A cache of the session spaces in the current cart.
     *
     * @type {Object<number>}
     */
    this.sessionSpacesInCart = cart ? this.getSessionSpacesInCart(cart) : {};

    /**
     * A cache of the session spaces in the current cart.
     *
     * @type {Object<number>}
     */
    this.sessionSpacesInOrder = existingOrder ? this.getSessionSpacesInOrder(existingOrder) : {};
};

SessionSpaceCalculator.prototype = {
    /**
     * @param {CartService} cart
     *
     * @return {Object<number>}
     */
    getSessionSpacesInCart(cart) {
        let sessions = {};

        cart.itemList.forEach((cartItem) => {
            if (cartItem.session) {
                if (!sessions.hasOwnProperty(cartItem.session.ID)) {
                    sessions[cartItem.session.ID] = 0;
                }

                let linePeople = cartItem.item.people ? parseInt(cartItem.item.people, 10) : 0;
                sessions[cartItem.session.ID] += (cartItem.getQuantity() * linePeople);
            }
        });

        return sessions;
    },

    /**
     * @param {DigiTickets.Order} order
     *
     * @return {Object<number>}
     */
    getSessionSpacesInOrder(order) {
        let sessions = {};

        order.items.forEach((orderLine) => {
            if (orderLine.session) {
                if (!sessions.hasOwnProperty(orderLine.session.ID)) {
                    sessions[orderLine.session.ID] = 0;
                }

                let linePeople = orderLine.item.people ? parseInt(orderLine.item.people, 10) : 0;
                sessions[orderLine.session.ID] += (orderLine.getQuantity() * linePeople);
            }
        });

        return sessions;
    },

    /**
     * Returns the number of spaces remaining on a session.
     * Starts with the session.available property.
     *
     * For new orders:
     *     If adding tickets:
     *         session.available - spaces already in cart
     *     If moving tickets to new session:
     *         session.available - spaces already in cart except on those lines being moved  (TODO)
     *
     * For existing orders being edited:
     *     If adding tickets:
     *         session.available + spaces already in order - spaces already in cart
     *     If moving tickets to a new session:
     *         session.available + spaces already in order - spaces already in cart except on those lines being moved  (TODO)
     *
     *
     * @param {Session} session
     */
    getAvailableSpacesForSession(session) {
        let available = session.available;

        if (this.sessionSpacesInOrder && this.sessionSpacesInOrder.hasOwnProperty(session.ID)) {
            available += this.sessionSpacesInOrder[session.ID];
        }

        if (this.sessionSpacesInCart && this.sessionSpacesInCart.hasOwnProperty(session.ID)) {
            available -= this.sessionSpacesInCart[session.ID];
        }

        if (this.sessionSpaceAdjustments && this.sessionSpaceAdjustments.hasOwnProperty(session.ID)) {
            available += this.sessionSpaceAdjustments[session.ID];
        }

        return available;
    },

    /**
     * @param {?Object<number>} adjustments
     */
    setSessionSpaceAdjustments(adjustments) {
        this.sessionSpaceAdjustments = adjustments || {};
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionSpaceCalculator;
}
