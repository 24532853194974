const LandingPage = function (PrivilegesService) {
    this.privilegesService = PrivilegesService;

    this.landingPageCode = null;

    // Information about the EPOS landing page(s).
    this.pageInfo = {
        isParsed: false,
        topLevel: null,
        topLevelPath: null,
        secondary: null
    };

    this.validTopLevels = [
        'manage',
        'orders',
        'report',
        'sell',
    ];
};

LandingPage.prototype = {

    //
    // Method to decompose and interpret the landing page code. It puts the various
    // components into properties of the object. It only does it if it hasn't already
    // done it.
    //
    // @param pageCode
    //
    parseLandingCode: function parseLandingCode(pageCode) {
        if (this.landingPageCode != pageCode) {
            this.landingPageCode = pageCode;
            this.pageInfo.isParsed = false;
        }
        if (!this.pageInfo.isParsed) {
            // Reset everything.
            this.pageInfo.topLevel = null;
            this.pageInfo.topLevelPath = null;
            this.pageInfo.secondary = null;
            // The code is of the form <top-level-page>[.<secondary-tab>].
            // Split on the dot to get the top-level page and optional secondary tab.
            // Actually the code could be null, in which case we assume '/sell'.
            let codeParts = this.landingPageCode == null ? [] : this.landingPageCode.split('.');
            // Make sure the top-level code is valid.
            if (codeParts.length < 1 || this.validTopLevels.indexOf(codeParts[0]) == -1) {
                // Empty or not valid. Assume sell with no secondary.
                codeParts = ['sell'];
            }
            this.pageInfo.topLevel = codeParts[0];
            this.pageInfo.topLevelPath = '/' + this.pageInfo.topLevel;
            // See if there's a secondary tab. Default to 'tickets'.
            if (codeParts.length === 2) {
                this.pageInfo.secondary = codeParts[1];
            } else {
                this.pageInfo.secondary = this.pageInfo.topLevel === 'sell' ? 'tickets' : null;
            }
            // Make a note that we've parsed the code.
            this.pageInfo.isParsed = true;
        }
    },

    /**
     * Method to return the top-level path.
     *
     * @returns {string|null} The path, eg '/sell' or '/report'.
     * @param pageCode
     * @param callback
     */
    getTopLevelScreenPath: function getTopLevelScreenPath(pageCode, callback) {
        let self = this;

        // See if we've seen this code before, and if so, whether we've parsed it.
        this.parseLandingCode(pageCode);

        // Verify we can access that top level.
        let privilegeMappings = {
            access_manage: 'manage',
            access_redeem: 'orders',
            access_report: 'report',
            access_sell: 'sell'
        };

        let access = [];
        this.privilegesService.checkMultipleCurrentUserPrivileges(
            Object.keys(privilegeMappings)
        ).then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult[]} results
             */
            function (results) {
                for (let privilegeName in results) {
                    if (results.hasOwnProperty(privilegeName)) {
                        let result = results[privilegeName];
                        if (result.granted) {
                            let page = privilegeMappings[result.privilegeName];
                            access.push(page);
                        }
                    }
                }

                if (access.indexOf(self.pageInfo.topLevel) === -1) {
                    // not access! access array should contain only valid locations, so we can force reload to first one.
                    if (access.length < 1) {
                        // no access!
                        console.log('LandingPage.getTopLevelScreenPath: user does not have access to ' + pageCode + ', no alternative pages found');
                        // Notification.error('User is unable to access any pages');
                        // this needs to logout not just redirect - debate? 403 is invalid a
                        callback('/403');
                        return;
                    }
                    // load a top level for something they do have access to!
                    console.log('LandingPage.getTopLevelScreenPath: user does not have access to ' + pageCode + ', redirecting to ' + access[0]);
                    self.parseLandingCode(access[0]);
                }

                // Return what we've determined to be the top-level path.
                callback(self.pageInfo.topLevelPath);
            }
        );
    },

    /**
     * Method to return the secondary level path (well, tab).
     *
     * @returns {string} Secondary default path (tab)
     * @param pageCode
     */
    getSecondary: function getSecondary(pageCode) {
        // See if we've seen this code before, and if so, whether we've parsed it.
        this.parseLandingCode(pageCode);
        // Return what we've determined to be the secondary path.
        return this.pageInfo.secondary;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LandingPage;
}
