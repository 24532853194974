const { toDate, toInt, toNullableInt, toString, toNullableString } = require('../functions/transform');

DigiTickets.PaymentToken = function () {
    /**
     * @type {string|null}
     */
    this.cardType = null;

    /**
     * @type {string|null}
     */
    this.cardTypeRef = null;

    /**
     * @type {number|null}
     */
    this.customerAccountID = null;

    /**
     * @type {number|null}
     */
    this.customerAccountPaymentTokenID = null;

    /**
     * @type {Date|null}
     */
    this.effectiveFrom = null;

    /**
     * @type {string|null}
     */
    this.lastDigits = null;

    /**
     * @type {string|null}
     */
    this.paymentMechanismReference = null;

    /**
     * @type {number|null}
     */
    this.paymentMethodID = null;

    /**
     * @type {string}
     */
    this.reference = '';
};

DigiTickets.PaymentToken.prototype = {
    getHydrationMap() {
        return {
            cardType: {},
            cardTypeRef: {},
            customerAccountID: toNullableInt,
            customerAccountPaymentTokenID: toInt,
            effectiveFrom: toDate,
            lastDigits: toNullableString,
            paymentMechanismReference: {},
            paymentMethodID: toNullableInt,
            reference: toString
        };
    },

    getId: function getId() {
        return this.customerAccountPaymentTokenID;
    }
};
