const MergeStashedCartModalCtrl = require('./MergeStashedCartModalCtrl');

/**
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param onlyAllowAssignStaffRef
 * @param {CartService} cartService
 * @param CartStashHelperService
 * @param CartStasher
 * @param {DialogService} DialogService
 * @param message
 * @param {ModalFactory} ModalFactory
 * @param Notification
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param UserService
 */
const HoldOrderCtrl = function HoldOrderCtrl(
    $filter,
    $modalInstance,
    $scope,
    $timeout,
    onlyAllowAssignStaffRef,
    cartService,
    CartStashHelperService,
    CartStasher,
    DialogService,
    message,
    ModalFactory,
    Notification,
    ReceiptPrinterService,
    UserService
) {
    $scope.onlyAllowAssignStaffRef = onlyAllowAssignStaffRef;
    $scope.branch = UserService.currentBranch;
    /**
     * @type {CartService}
     */
    $scope.cart = cartService;
    $scope.cartStashHelper = CartStashHelperService;
    $scope.formData = {
        staffRef: $scope.cart.currentCart.staffRef
    };
    $scope.isLoading = false;
    $scope.message = message;
    $scope.newStashedCart = CartStashHelperService.cartToStash(cartService);
    $scope.userService = UserService;

    /**
     * @type {DigiTickets.StashedCart[]}
     */
    $scope.existingStashedCarts = [];

    /**
     * Loads existing stashes to prevent duplicate staffRefs with those.
     */
    $scope.loadExistingStashedCarts = function () {
        $scope.isLoading = true;
        CartStasher.loadStashedCarts()
            .then((stashedCarts) => $timeout(() => {
                $scope.existingStashedCarts = stashedCarts;
                $scope.isLoading = false;
            }));
    };

    /**
     * @param {boolean} hold
     * @param {boolean} sendToKitchen
     * @param {DigiTickets.StashedCart} [mergeToStash]
     */
    $scope.ok = function ok(hold, sendToKitchen, mergeToStash) {
        $modalInstance.close(
            {
                hold,
                mergeToStash,
                staffRef: $scope.formData.staffRef,
                sendToKitchen
            }
        );

        $scope.formData.staffRef = '';
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * @return {boolean}
     */
    $scope.canSendToKitchen = function canSendToKitchen() {
        return !!(
            UserService.currentBranch
            && UserService.currentBranch.kitchenEnabled
            && $scope.cart.containsKitchenItems()
        );
    };

    $scope.printBill = function printBill() {
        let order = $scope.cart.toOrder();
        ReceiptPrinterService.printBill(order);
    };

    $scope.setStaffRef = function setStaffRef() {
        $scope.validateStaffRef(
            true, // Ref is required
            false, // Not stashing
            function (valid, mergeToStash) {
                if (valid) {
                    $scope.ok(false, false, mergeToStash);
                }
            }
        );
    };

    $scope.sendToKitchen = function sendToKitchen() {
        $scope.validateStaffRef(
            true, // Ref is required
            true, // Stashing
            function (valid, mergeToStash) {
                if (valid) {
                    $scope.ok(true, true, mergeToStash);
                }
            }
        );
    };

    $scope.hold = function hold() {
        $scope.validateStaffRef(
            false, // Ref is not required
            true, // Stashing
            function (valid, mergeToStash) {
                if (valid) {
                    $scope.ok(true, false, mergeToStash);
                }
            }
        );
    };

    /**
     * Check if the reference is valid and not already in use.
     *
     * @param {boolean} required
     * @param {boolean} isStashing Does the user want to stash the cart (true) or just set a reference (false)?
     * @param {function(boolean, DigiTickets.StashedCart?)} callback
     */
    $scope.validateStaffRef = function validateStaffRef(required, isStashing, callback) {
        let staffRef = $scope.formData.staffRef;

        if (!staffRef) {
            if (required) {
                // Reference is required, but nothing was given.
                Notification.error($filter('lang')('ON_HOLD_ORDERS.NO_REF_ERROR'));
                callback(false);
            } else {
                // Reference is not required, and nothing was given. That's okay.
                callback(true);
            }

            return;
        }

        if ($scope.cart.ignoreDuplicateRef) {
            // The operator has previously chosen to ignore that this cart has a duplicate ref,
            // so don't bother re-asking them
            callback(true);
            return;
        }

        // Check for duplicated staffRefs.
        let existingStashedCart = CartStasher.extractByStaffRef(
            $scope.existingStashedCarts,
            staffRef
        );

        if (!existingStashedCart) {
            // No other stash is using this reference.
            callback(true);
            return;
        }

        // Found another stashed cart with the same reference...

        if (isStashing) {
            // The user wishes to stash the cart. Give them the option to merge with the existing stash,
            // or create a new one with the same reference.
            $scope.promptForMerge(
                $scope.newStashedCart,
                existingStashedCart,
                function (merge) {
                    if (merge) {
                        // Merge this cart with an existing one
                        callback(true, existingStashedCart);
                    } else {
                        // Save the cart without any merge (whether or not it happened to have a duplicate ref)
                        callback(true);
                    }
                }
            );
        } else {
            // The user is just setting the reference, but not stashing.
            // Double check they want to use a duplicate reference.
            DialogService.confirm(
                'ON_HOLD_ORDERS.DUPLICATE_REF_ALERT',
                function (result) {
                    if (result === true) {
                        callback(true);
                    } else {
                        Notification.error($filter('lang')('ON_HOLD_ORDERS.DUPLICATE_REF_ERROR'));
                        callback(false);
                    }
                }
            );
        }
    };

    /**
     * @param {DigiTickets.StashedCart} newStashedCart
     * @param {DigiTickets.StashedCart} existingStashedCart
     * @param {function} callback
     */
    $scope.promptForMerge = function promptForMerge(newStashedCart, existingStashedCart, callback) {
        ModalFactory
            .open(
                'merge-stashed-cart',
                MergeStashedCartModalCtrl,
                'partials/modals/mergeStashedCartModal.html',
                {
                    newStashedCart: () => newStashedCart,
                    existingStashedCart: () => existingStashedCart
                }
            )
            .then(
                function (shouldMerge) {
                    callback(shouldMerge);
                },
                function () {
                    // The merge prompt has been cancelled. Do nothing and return operator to the
                    // Hold modal to give them opportunity to change their reference, or cancel the operation entirely
                }
            );
    };

    // Initialize...

    $scope.loadExistingStashedCarts();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = HoldOrderCtrl;
}
