const VerifoneConfig = require('../libraries/DigiTickets/PaymentMethods/Verifone/VerifoneConfig');
const VerifoneDriver = require('../libraries/DigiTickets/PaymentMethods/Verifone/VerifoneDriver');

app.factory(
    'VerifoneConfigService',
    /**
     * @param hydrator
     * @param CurrentDevice
     * @param {PaymentMethodService} paymentMethodService
     * @param UserService
     * @param UserService}
     */
    function (
        hydrator,
        CurrentDevice,
        paymentMethodService,
        UserService
    ) {
        let config = new VerifoneConfig();

        let paymentMethod = paymentMethodService.getByRef('card-verifone-chip-and-pin');
        if (!paymentMethod) {
            throw new Error('Company does not have Verifone payment method enabled.');
        }

        // Set config from the company's payment method config.
        if (paymentMethod.config) {
            let companyTerminalConfig = JSON.parse(paymentMethod.config);
            hydrator.hydrate(companyTerminalConfig, config);
        }

        // Set config from the device's payment method config.
        let device = CurrentDevice.device;
        if (device.paymentTerminalConfig) {
            if (device.paymentTerminalConfig.hasOwnProperty('card-verifone-chip-and-pin')) {
                hydrator.hydrate(device.paymentTerminalConfig['card-verifone-chip-and-pin'], config);
            }
        }

        // Set config from the user's payment method config.
        if (UserService.paymentTerminalConfig) {
            if (UserService.paymentTerminalConfig.hasOwnProperty('card-verifone-chip-and-pin')) {
                hydrator.hydrate(UserService.paymentTerminalConfig['card-verifone-chip-and-pin'], config);
            }
        }

        return config;
    }
);

app.service('VerifoneDriverService', VerifoneDriver);
