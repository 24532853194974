const MembershipDatasetsModalCtrl = require('../modal/MembershipDatasetsModalCtrl');
const MembershipCancellationModalCtrl = require('../modal/MembershipCancellationModalCtrl');
const MembershipInstallmentsModalCtrl = require('../modal/MembershipInstallmentsModalCtrl');
const MembershipRenewalDateChooserModalCtrl = require('../modal/MembershipRenewalDateChooserModalCtrl');

/**
 * Provides common methods used by OrderDetailsCtrl, MembershipDetailsCtrl, and ManageAccountCtrl.
 * for viewing/updating details about Memberships.
 * The aim is to reduce duplication of code that opens modals / does other things for modifying
 * things about memberships.
 *
 * @todo: Move the rest of MembershipManagementCtrl to here. The existing class is confusingly named because
 * it looks like a controller but is actually a utility class.
 */
/**
 * @param $filter
 * @param $q
 * @param $location
 * @param $modal
 * @param {CartService} cartService
 * @param DialogService
 * @param {DigiTickets.Logger} Logger
 * @param {MembershipService} membershipService
 * @param {ModalFactory} ModalFactory
 * @param Notification
 * @param {SellStateService} sellStateService
 */
DigiTickets.MembershipManagementActions = function (
    $filter,
    $q,
    $location,
    $modal,
    cartService,
    DialogService,
    Logger,
    membershipService,
    ModalFactory,
    Notification,
    sellStateService
) {
    this.$filter = $filter;
    this.$q = $q;
    this.$location = $location;
    this.$modal = $modal;

    /**
     * @type {CartService}
     */
    this.cartService = cartService;

    /**
     * @type {DialogService}
     */
    this.dialogService = DialogService;

    this.logger = Logger;

    /**
     * @type {MembershipService}
     */
    this.membershipService = membershipService;

    /**
     * @type {ModalFactory}
     */
    this.modalFactory = ModalFactory;

    this.notification = Notification;

    /**
     * @type {SellStateService}
     */
    this.sellStateService = sellStateService;
};

DigiTickets.MembershipManagementActions.prototype = {

    /**
     * Display the membership datasets modal to edit one or more datasets.
     * Returns the modified datasets in the callback (these are clones, not the same object that are passed in).
     * This does not persist the changes on the server or elsewhere. It is up to the calling code to do that.
     *
     * @param {string} action
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {DigiTickets.MembershipDataset[]} membershipDatasets
     * @param {DigiTickets.CustomerAccount} customerAccount
     * @param {function} callback
     */
    editDatasets: function editDatasets(
        action,
        membershipPlan,
        membershipDatasets,
        customerAccount,
        callback
    ) {
        // This is the user adding/editing the item, so show the pop-up.
        this.logger.debug(
            'Sell.ShowMembershipDatasetsModal',
            {
                planID: membershipPlan.ID,
                mode: (action === 'add-new' ? 'Add membership' : 'Edit memberships')
            }
        );

        this.modalFactory
            .open(
                'membership-datasets',
                MembershipDatasetsModalCtrl,
                'partials/modals/membershipDatasetsModal.html',
                {
                    action: function () {
                        return action;
                    },
                    membershipPlan: function () {
                        return membershipPlan;
                    },
                    currentMemberID: function () {
                        return null;
                    },
                    customerAccount: function () {
                        return customerAccount;
                    },
                    membershipDatasets: function () {
                        return membershipDatasets;
                    }
                }
            )
            .then(
                function success(data) {
                    callback(data.membershipDatasets);
                },
                function error() {
                    callback(null);
                }
            );
    },

    /**
     * Opens the modal to select a refund amount for each selected membership.
     *
     * The modal returns some cart items to do those refunds, each item has the cancellingMembership property set.
     * We then create a SellState and send the user to the SellCtrl.
     * The SellCtrl will call the MembershipCancellationRunner to do the actual cancellation when the user clicks
     * the pay button. (But we also tell it to 'click' the pay button as soon as it loads.)
     *
     * The SellCtrl handles the cancellation when the pay button is clicked because that is a better time for it
     * to happen when modifying a membership (cancelling and selling a new one in the same order), and it is
     * best to be consistent with when the actual cancellation happens.
     *
     * @param {DigiTickets.Membership[]} memberships
     * @param {string} returnPath
     * @param {DigiTickets.CustomerAccount|undefined} [customerAccount]
     */
    cancelMemberships: function cancelMemberships(memberships, returnPath, customerAccount) {
        if (memberships.length < 1) {
            return;
        }

        let self = this;

        // Determine the account from a membership.
        // All memberships being deleted at the same time should be from the same account,
        // otherwise you're doing something weird!
        if (customerAccount === undefined) {
            customerAccount = null;
            for (let i = 0; i < memberships.length; i++) {
                if (memberships[i].customerAccount) {
                    customerAccount = memberships[i].customerAccount;
                    break;
                }
            }
        }

        this.logger.debug(
            'Manage.ShowCancelMembershipsModal',
            {
                membershipIDs: self.membershipService.mapMembershipIds(memberships)
            }
        );

        // Open the refund type selection modal.
        let modalInstance = this.$modal.open({
            templateUrl: 'partials/modals/membershipCancellation.html',
            controller: MembershipCancellationModalCtrl,
            windowClass: 'in cancel-memberships-modal',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                memberships: function () {
                    return memberships;
                }
            }
        });

        modalInstance.result.then(
            function (response) {
                self.logger.debug(
                    'Manage.SubmitCancelMembershipsModal',
                    {
                        membershipIDs: self.membershipService.mapMembershipIds(memberships)
                    }
                );

                if (response.refundCartItems.length > 0) {
                    // Send user to sell screen to issue refunds
                    let sellState = new DigiTickets.SellState();
                    sellState.addInitialItems(response.refundCartItems);
                    sellState.payNow = true;
                    sellState.preventAdditional = true;
                    sellState.preventRemoval = true;
                    sellState.preventQtyAdjustment = true;
                    sellState.customerAccount = customerAccount;
                    sellState.returnPath = returnPath;
                    self.sellStateService.sellWithState(sellState);
                }
            },
            function () {
                // Cancelled
                self.logger.debug(
                    'Manage.DismissCancelMembershipsModal',
                    {
                        membershipIDs: self.membershipService.mapMembershipIds(memberships)
                    }
                );
            }
        );

        return modalInstance;
    },

    /**
     * Open the modal to change the subscription installment amounts for a membership.
     * The modal persists the changes via the API when it is submitted.
     *
     * @param {DigiTickets.Membership} membership
     */
    changeInstallments: function changeInstallments(membership) {
        let self = this;

        self.logger.debug(
            'Manage.ShowChangeInstallmentsModal',
            {
                membershipID: membership.getId()
            }
        );

        let modalInstance = this.$modal.open({
            templateUrl: 'partials/modals/membershipInstallments.html',
            controller: MembershipInstallmentsModalCtrl,
            windowClass: 'in membership-installments-modal',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                membership: function () {
                    return membership;
                }
            }
        });

        modalInstance.result.then(
            function (response) {
                self.logger.debug(
                    'Manage.UpdateInstallmentAmounts',
                    {
                        membershipID: membership.getId(),
                        success: response.success,
                        newInstallmentAmounts: response.newInstallmentAmounts
                    }
                );

                if (response.success) {
                    self.notification.success(
                        {
                            message: self.$filter('lang')('MEMBERSHIP_INSTALLMENTS.CHANGES_SAVED')
                        }
                    );
                } else {
                    self.notification.error(
                        {
                            message: self.$filter('lang')('MEMBERSHIP_INSTALLMENTS.CHANGES_NOT_SAVED')
                        }
                    );
                }
            },
            function () {
                // Modal was cancelled.
            }
        );

        return modalInstance;
    },

    /**
     * Set one or more memberships to no longer automatically renew.
     * Persists the changes via the API and displays a notification with the result.
     *
     * @param {DigiTickets.Membership[]} memberships
     * @param {function} callback
     */
    stopRenewingMemberships: function stopRenewingMemberships(memberships, callback) {
        if (memberships.length < 1) {
            return;
        }

        let self = this;

        self.logger.debug(
            'Manage.RequestStopRenewingMemberships',
            {
                membershipIDs: self.membershipService.mapMembershipIds(memberships)
            }
        );

        let autoRenewingMemberships = self.membershipService.extractAutoRenewingMemberships(memberships);
        let notAutoRenewingMemberships = $(memberships).not(autoRenewingMemberships).get();

        let changingRefs = this.membershipService.generateMembershipRefsString(autoRenewingMemberships);

        let confirmStr = this.$filter('lang')('MANAGE_ACCOUNT.CONFIRM_STOP_RENEWING', {
            refs: changingRefs
        });

        if (notAutoRenewingMemberships.length > 0) {
            let notChangingRefs = this.membershipService.generateMembershipRefsString(notAutoRenewingMemberships);
            confirmStr += '<br/>' + this.$filter('lang')('MANAGE_ACCOUNT.CONFIRM_STOP_RENEWING_UNCHANGED', {
                refs: notChangingRefs
            });
        }

        this.dialogService.confirm(
            confirmStr,
            function (confirmed) {
                if (confirmed === true) {
                    self.membershipService.stopRenewingMemberships(
                        autoRenewingMemberships,
                        function () {
                            let stoppedMemberships = autoRenewingMemberships.filter(
                                function (m) {
                                    return m.autoRenew === false;
                                }
                            );

                            let notStoppedMemberships = autoRenewingMemberships.filter(
                                function (m) {
                                    return m.autoRenew !== false;
                                }
                            );

                            let stoppedStr = '';
                            if (stoppedMemberships.length > 0) {
                                let stoppedRefs = self.membershipService.generateMembershipRefsString(stoppedMemberships);
                                stoppedStr = self.$filter('lang')('MANAGE_ACCOUNT.STOPPED_RENEWING_ALERT', {
                                    refs: stoppedRefs
                                });
                            }

                            let notStoppedStr = '';
                            if (notStoppedMemberships.length > 0) {
                                let notStoppedRefs = self.membershipService.generateMembershipRefsString(notStoppedMemberships);
                                notStoppedStr = self.$filter('lang')('MANAGE_ACCOUNT.NOT_STOPPED_RENEWING_ALERT', {
                                    refs: notStoppedRefs
                                });
                                self.notification.error(
                                    {
                                        message: notStoppedStr + ' ' + stoppedStr,
                                        // Display for a long time for a long message (can tap to dismiss anyway)
                                        delay: 12000
                                    }
                                );
                            } else {
                                // Just show the ones that were stopped.
                                self.notification.success(
                                    {
                                        message: stoppedStr
                                    }
                                );
                            }

                            callback(true);
                        }
                    );
                } else {
                    // Modal not confirmed.
                    callback(false);
                }
            },
            {
                rawMessage: true
            }
        );
    },

    /**
     * Show the modal to select a date to renew a membership or to clear that the user wishes to renew it.
     *
     * @param {DigiTickets.Membership} membership
     * @param {boolean} multipleAllowed Can multiple memberships be set to renew from the current
     *                                  screen (order details) or only one (membership details)?
     *                                  This only affects the message that is shown after the modal is submitted.
     */
    changeMembershipRenewingState: function changeMembershipRenewingState(membership, multipleAllowed) {
        let modalInstance = this.$modal.open({
            templateUrl: 'partials/modals/membershipRenewalDateChooserModal.html',
            controller: MembershipRenewalDateChooserModalCtrl,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'membership-renewal-date-chooser-window',
            resolve: {
                membership: function () {
                    return membership;
                }
            }
        });

        let self = this;
        modalInstance.result.then(
            function () {
                // Modal submitted.
                // Show a notification, depending on the new value of the "renewing" flag, and which screen we're on.
                if (membership.isRenewing()) {
                    if (multipleAllowed) {
                        // Order details (potentially multiple memberships).
                        self.notification.success(self.$filter('lang')('MEMBERSHIPS.RENEWAL_SELECTED_ALERT_MULTIPLE', { ref: membership.getRef() }));
                    } else {
                        // Membership details (single membership).
                        self.notification.success(self.$filter('lang')('MEMBERSHIPS.RENEWAL_SELECTED_ALERT', { ref: membership.getRef() }));
                    }
                } else {
                    self.notification.info(self.$filter('lang')('MEMBERSHIPS.RENEWAL_DESELECTED_ALERT', { ref: membership.getRef() }));
                }
            },
            function () {
                // Modal cancelled.
                // @TODO: Should we pop up a notification to confirm the current renewing state?
            }
        );
    },

    /**
     * Send the user to the Sell screen to take payments for renewing one of more memberships.
     *
     * @param {DigiTickets.Membership[]} renewingMemberships
     */
    takePaymentForRenewingMemberships: function takePaymentForRenewingMemberships(renewingMemberships) {
        // Take payment for the set of memberships we've been given.

        // Just check that it's not an empty set.
        if (renewingMemberships.length < 1) {
            return;
        }

        let sellState = new DigiTickets.SellState();
        sellState.payNow = false;

        // Because they might have renewed the membership, and therefore all the data might be different (eg
        // whether they can renew or not, expiry dates), we set a flag in the path to force the order controller
        // to ignore any cached values and ask for a new copy of the order from the server. The membership
        // controller always asks for a fresh copy, so the flag is ignored there.
        sellState.returnPath = this.$location.path();
        sellState.returnPathQuery = { r: true };

        sellState.successReturnPath = this.$location.path();
        sellState.successReturnPathQuery = { r: true, renewalSuccess: true };

        for (let i = 0; i < renewingMemberships.length; i++) {
            let renewingMembership = renewingMemberships[i];

            // Set the customer account from the first renewing membership's account.
            if (!sellState.customerAccount && renewingMembership.customerAccount !== null) {
                sellState.customerAccount = renewingMembership.customerAccount;
            }

            let cartItem = new DigiTickets.CartItem(
                this.cartService, // Cart
                renewingMembership.renewalPlan // Item
            );

            cartItem.canAdjustQuantity = false;
            cartItem.canRemove = false;

            cartItem.setRenewingMembership(renewingMembership, renewingMembership.getChosenRenewalFromDate());

            sellState.addInitialItem(cartItem);

            renewingMembership.resetRenew();
        }

        this.sellStateService.sellWithState(sellState);
    }
};
