/**
 * The SaleableItemService collects and retains all the different types of item that can be sold from the
 * sell page.
 *
 * @param $q
 * @param {DigiTickets.GiftVoucherManager} GiftVoucherManager
 * @param {DigiTickets.MembershipPlanManager} MembershipPlanService
 * @param {DigiTickets.ProductManager} ProductService
 * @param {DigiTickets.TicketManager} TicketService
 */
DigiTickets.SaleableItemService = function (
    $q,
    GiftVoucherManager,
    MembershipPlanService,
    ProductService,
    TicketService
) {
    this.giftVoucherManager = GiftVoucherManager;
    this.membershipPlanManager = MembershipPlanService;
    this.productManager = ProductService;
    this.ticketManager = TicketService;

    this.deferred = $q.defer();

    /**
     * @type {Object<DigiTickets.CategorisedItemCollection>}
     */
    this.itemTypes = {};

    this.loaded = false;

    this.giftVouchersLoaded = false;
    this.membershipPlansLoaded = false;
    this.productsLoaded = false;
    this.ticketsLoaded = false;
};

DigiTickets.SaleableItemService.prototype = {

    initialize: function initialize() {
        this.loaded = false;

        this.giftVouchersLoaded = false;
        this.membershipPlansLoaded = false;
        this.productsLoaded = false;
        this.ticketsLoaded = false;

        this.itemTypes = {};
        this.itemTypes[DigiTickets.ItemType.GIFT_VOUCHER] = new DigiTickets.CategorisedItemCollection();
        this.itemTypes[DigiTickets.ItemType.MEMBERSHIP_PLAN] = new DigiTickets.CategorisedItemCollection();
        this.itemTypes[DigiTickets.ItemType.PRODUCT] = new DigiTickets.CategorisedItemCollection();
        this.itemTypes[DigiTickets.ItemType.PRODUCT_FOOD] = new DigiTickets.CategorisedItemCollection();
        this.itemTypes[DigiTickets.ItemType.TICKET] = new DigiTickets.CategorisedItemCollection();

        this.load();
    },

    load: function load() {
        this.loadGiftVouchers();
        this.loadMembershipPlans();
        this.loadProducts();
        this.loadTickets();

        return this.deferred.promise;
    },

    updateLoaded: function updateLoaded() {
        this.loaded = this.giftVouchersLoaded
                    && this.membershipPlansLoaded
                    && this.productsLoaded
                    && this.ticketsLoaded;

        if (this.loaded) {
            this.deferred.resolve();
        }
    },

    loadGiftVouchers: function loadGiftVouchers() {
        let self = this;
        this.giftVoucherManager.getGiftVouchers().then(function () {
            self.itemTypes[DigiTickets.ItemType.GIFT_VOUCHER] = self.createItemCollection(
                self.giftVoucherManager.getCategories()
            );

            self.giftVouchersLoaded = true;
            self.updateLoaded();
        });
    },

    loadTickets: function loadTickets() {
        let self = this;

        this.ticketManager.getTickets().then(function () {
            self.itemTypes[DigiTickets.ItemType.TICKET] = self.createItemCollection(
                self.ticketManager.getCategories()
            );

            self.ticketsLoaded = true;
            self.updateLoaded();
        });
    },

    loadProducts: function loadProducts() {
        let self = this;

        let nonFoodCategoryFilter = function (category) {
            return category.hasNonFood;
        };

        let nonFoodItemFilter = function (item) {
            return !item.isFood;
        };

        let foodCategoryFilter = function (category) {
            return category.hasFood;
        };

        let foodItemFilter = function (item) {
            return !!item.isFood;
        };

        this.productManager.getProducts().then(function () {
            self.itemTypes[DigiTickets.ItemType.PRODUCT] = self.createItemCollection(
                self.productManager.getCategories(),
                nonFoodCategoryFilter,
                nonFoodItemFilter
            );

            self.itemTypes[DigiTickets.ItemType.PRODUCT_FOOD] = self.createItemCollection(
                self.productManager.getCategories(),
                foodCategoryFilter,
                foodItemFilter
            );

            self.productsLoaded = true;
            self.updateLoaded();
        });
    },

    loadMembershipPlans: function loadMembershipPlans() {
        let self = this;

        this.membershipPlanManager.getMembershipPlans().then(function () {
            self.itemTypes[DigiTickets.ItemType.MEMBERSHIP_PLAN] = self.createItemCollection(
                self.membershipPlanManager.getCategories(),
                null,
                /**
                 * @param {DigiTickets.MembershipPlan} membershipPlan
                 * @return {boolean}
                 */
                function (membershipPlan) {
                    // Don't show plans whose allowed usage "to" date is in the past.
                    return membershipPlan.canBeSoldToday();
                }
            );

            self.membershipPlansLoaded = true;
            self.updateLoaded();
        });
    },

    /**
     * @param {Category[]} categories
     * @param {function} [categoryFilter]
     * @param {function} [itemFilter]
     *
     * @return {DigiTickets.CategorisedItemCollection}
     */
    createItemCollection: function createItemCollection(categories, categoryFilter, itemFilter) {
        categories = Object.keys(categories).map(function (key) {
            return categories[key];
        });

        if (categoryFilter instanceof Function) {
            categories = categories.filter(categoryFilter);
        }

        // Apply ordering to the categories
        categories.sort(
            /**
             * Sort categories by navOrder then by name.
             *
             * @param {Category} a
             * @param {Category} b
             * @return {number}
             */
            function (a, b) {
                if (a.navOrder > b.navOrder) {
                    return 1;
                } else if (a.navOrder < b.navOrder) {
                    return -1;
                } else if (a.name !== b.name) {
                    return a.name > b.name ? 1 : -1;
                }
                return 0;
            }
        );

        let collection = new DigiTickets.CategorisedItemCollection();

        // Process our items
        for (let categoryID in categories) {
            if (!categories.hasOwnProperty(categoryID)) {
                continue;
            }
            let category = categories[categoryID];

            var items;
            if (itemFilter instanceof Function) {
                items = category.items.filter(itemFilter);
            } else {
                items = category.items;
            }

            if (items.length > 0) {
                collection.categories.push(category);
                collection.items[category.ID] = items;
            }
        }

        collection.hasCategories = collection.categories.length > 0;

        return collection;
    }
};
