const ErrorLevel = require('./ErrorLevel');

const classes = [];
classes[ErrorLevel.ERROR] = 'danger';
classes[ErrorLevel.WARNING] = 'warning';
classes[ErrorLevel.INFO] = 'info';

/**
 * Return a CSS class to represent an error level.
 *
 * @param {number} errorLevel
 *
 * @return {string}
 */
const classForErrorLevel = (errorLevel) => classes[errorLevel] || 'danger';

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = classForErrorLevel;
}
