/**
 * @param {function(number): string} currencyPresenter Must accept a monetary amount and output a formatted string
 * @returns {function(SoldGiftVoucher): string} A user-facing friendly description of the voucher that has been sold
 */
const presentSoldGiftVoucherNameFilter = (currencyPresenter) => (soldGiftVoucher) => {
    const giftVoucherName = soldGiftVoucher.giftVoucher.name;
    const amount = currencyPresenter(soldGiftVoucher.initialBalance);

    switch (soldGiftVoucher.giftVoucher.giftVoucherType) {
        case DigiTickets.GiftVoucherType.BALANCE:
            return `${giftVoucherName} (${amount})`;

        case DigiTickets.GiftVoucherType.EXPERIENCE:
        default:
            return `${giftVoucherName}`;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = presentSoldGiftVoucherNameFilter;
}
