DigiTickets.CardTypeRef = Object.freeze({
    AMEX: 'amex',
    DINERS: 'diners',
    DISCOVER: 'discover',
    JCB: 'jcb',
    LASER: 'laser',
    MAESTRO: 'maestro',
    MASTERCARD: 'mastercard',
    VISA_CREDIT: 'visa-credit',
    VISA_DEBIT: 'visa-debit',
    VISA_ELECTRON: 'visa-electron'
});
