/**
 * We need to ensure that there is only one copy of ProPoint running.
 *
 * In order to do this when we load a new instance of ProPoint we can use BroadcastChannel to simply ask if there are
 * any other instances of ProPoint running. If we get a reply we know that there are. If we do not get a reply we know
 * that this is the only instance.
 *
 * @param {LangService} LangService
 */
const AppInstanceChecker = function (LangService) {
    this.langService = LangService;
    this.challenge = 'ping';
    this.response = 'pong';
    this.isDuplicateInstance = false;
    this.channel = null;
};

AppInstanceChecker.prototype = {

    /**
     * Setup a new BroadcastChannel and send a challenge message to the channel.
     *
     * @param {object} windowObject Mock window object for tests.
     * @param {string} channelName
     * @param {boolean} [isDisabled]
     */
    init(windowObject, channelName, isDisabled) {
        if (isDisabled) {
            console.log('AppInstanceChecker is disabled');
            return false;
        }

        if (!channelName) {
            throw new Error('A BroadcastChannel name is required.');
        }

        // We use a mock window in tests.
        if (!windowObject) {
            console.debug('Using global window object.');
            // In order to test we use a global window object normally we don't pass in the mock window.
            // However that doesn't mean that window is defined. Trying to mock the window object is best avoided.
            if (typeof window !== 'undefined') {
                windowObject = window;
            }
        }

        if (windowObject && typeof windowObject.BroadcastChannel !== 'undefined') {
            this.channel = new windowObject.BroadcastChannel(channelName);
            this.channel.onmessage = (message) => {
                this.receiveMessage(message);
            };
            this.sendMessage(this.challenge);
        } else {
            console.warn(
                'BroadcastChannel not supported. Unable to ensure a single app instance.'
            );
            return false;
        }

        return true;
    },

    /**
     * Send a challenge message to the BroadcastChannel when the application loads.
     *
     * @param {string} message
     */
    sendMessage(message) {
        this.channel.postMessage(message);
    },

    /**
     * Listen for an incoming message on the BroadcastChannel and respond.
     *
     * @param {MessageEvent} message
     */
    receiveMessage(message) {
        switch (message.data) {
            case this.challenge:
                // This is a message sent by a new tab or window that has just loaded (or been reloaded).
                // However the tab or window should not respond if it knows it is a duplicate (it could be the original
                // window reloaded).
                if (!this.isDuplicateInstance) {
                    this.sendMessage(this.response);
                }
                break;
            case this.response:
                // This is a message sent by an existing tab or window responding to the challenge sent when loading.
                // If we receive a response we know that another tab or window is already open, therefore this tab or
                // window is a duplicate, it should display the error and should not respond to any future messages.
                this.isDuplicateInstance = true;
                this.showAlert();
                break;
        }
    },

    showAlert() {
        // Empty the entire body and replace with our message.
        document.body.innerHTML = '<div id="unsupported-browser-message" class="alert alert-danger" role="alert">'
            + '    <h4><i class="glyphicon glyphicon-warning-sign"></i> '
                 + this.langService.getText('APP_INSTANCE_CHECKER.WARNING') + '</h4>'
            + '    <p>' + this.langService.getText('APP_INSTANCE_CHECKER.MESSAGE') + '</p>'
            + '</div>';
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AppInstanceChecker;
}
