/**
 * @param {NavigationService} navigationService
 */
const SellStateService = function (
    navigationService
) {
    this.navigationService = navigationService;

    /**
     * @type {DigiTickets.SellState|null}
     */
    this.nextState = null;
};

SellStateService.prototype = {
    /**
     * @param {DigiTickets.SellState|null} state
     */
    setNextState: function setNextSate(state) {
        this.nextState = state;
    },

    /**
     * @return {boolean}
     */
    hasNextState: function hasNextState() {
        return this.nextState !== null;
    },

    /**
     * Set the next state and go to the sell page.
     *
     * @param {DigiTickets.SellState|null} state
     */
    sellWithState: function sellWithState(state) {
        this.setNextState(state);
        this.navigationService.viewSell();
    },

    /**
     * Return the next state and clear it.
     * Return a blank state if there is no next state.
     *
     * @return {DigiTickets.SellState|null}
     */
    pullNextState: function pullNextSate() {
        if (this.nextState !== null) {
            let state = this.nextState;
            this.setNextState(null);
            return state;
        }

        return null;
    },

    /**
     * @return {DigiTickets.SellState}
     */
    getDefaultState: function getDefaultState() {
        return new DigiTickets.SellState();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SellStateService;
}
