const angular = require('angular');

const hotKeyDirective = function () {
    return {
        link: function link(scope, element, attrs) {
            scope.$on('keydownListener::keydown', function (event, originalEvent) {
                let code = attrs.dtHotKey.toUpperCase().charCodeAt(0);
                if (originalEvent.altKey && originalEvent.keyCode == code) {
                    let angElement = angular.element(element);
                    if (typeof angElement !== 'undefined') {
                        let domElement = angElement[0];
                        if (typeof domElement !== 'undefined') {
                            domElement.click();
                        } else {
                            element.triggerHandler('click');
                        }
                    } else {
                        element.triggerHandler('click');
                    }
                }
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = hotKeyDirective;
}
