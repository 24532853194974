/**
 * @param {DigiTickets.AppConfig} AppConfig
 */
const contactPhotoDirective = function (AppConfig) {
    return {
        scope: {
            contact: '=',
            width: '@',
            height: '@'
        },
        restrict: 'E',
        template: function template() {
            return '<img ng-src="{{ getPhotoUrl() }}" alt="" title="" />';
        },
        controller: function controller($scope) {
            $scope.getPhotoUrl = function getPhotoUrl() {
                if ($scope.contact && ($scope.contact.hasPhoto() || $scope.contact.hasPhotoData())) {
                    if ($scope.contact.hasPhotoData()) {
                        return $scope.contact.photoData;
                    }
                    return AppConfig.domainRoot + $scope.contact.getPhotoPath($scope.width, $scope.height);
                }
                // Default image.
                return '/img/default-member-photo.jpg';
            };
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = contactPhotoDirective;
}
