const apiErrorMessage = require('./Api/apiErrorMessage');
const DataStoreNames = require('./DataStores/DataStoreNames');
const Session = require('./Sessions/Session');
const { basicHydrate, basicHydrateArray } = require('./Hydration/basicHydrator');

/**
 * @param $q
 * @param $interval
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {DataStore} dataStore
 * @param {Hydrator} hydrator
 * @param SessionResource
 * @param {UserService} UserService
 */
const SessionManager = function SessionManager(
    $q,
    $interval,
    AppConfig,
    dataStore,
    hydrator,
    SessionResource,
    UserService
) {
    this.$q = $q;
    this.$interval = $interval;
    this.appConfig = AppConfig;
    this.dataStore = dataStore;
    this.hydrator = hydrator;
    this.sessionResource = SessionResource;
    this.user = UserService;
    this.debug = true;
    this.deferred = $q.defer();
    this.sessionData = {};

    /**
     * @type {DigiTickets.Branch|null}
     */
    this.branch = null;

    /**
     * How frequently in ms is the API queried for updated sessions
     *
     * @type {number}
     */
    this.updateDelay = 120 * 1000;

    /**
     * @type {?Date}
     */
    this.lastUpdatedAt = null; // Store the date when last sync was performed
};

SessionManager.prototype = {

    /**
     * Trigger an update of sessions from the API.
     * Checks if the sessions for the given branch have already been loaded. If they have it performs a refresh
     * instead of pulling them all down again.
     * The sessions are loaded from the API, hydrated to Session models, and saved in the data store (IndexedDB).
     *
     * @param {DigiTickets.Branch} branch
     */
    loadSessions: function loadSessions(branch) {
        let self = this;
        this.logMsg('loadSessions', 'Loading sessions');

        this.branch = branch;

        // TODO: Switch this to use dataSyncer.isSynced()
        // Find out if sessions are already loaded.
        // We can't just check is the sessions list is empty, as an empty list is perfectly valid.
        // So we store an entry in the 'syncMetadata' table to say when sessions were last synced and for which branch.
        return this.dataStore.find(
            DataStoreNames.SYNC_METADATA,
            'sessions'
        ).then(
            function (result) {
                self.logMsg('loadSessions', 'Last sync metadata', result);
                if (result && result.branchID === branch.ID) {
                    self.logMsg('loadSessions', 'Last sync was for current branch. No need to refresh.');
                    self.lastUpdatedAt = result.lastUpdatedAt;
                    return;
                }

                self.logMsg('loadSessions', 'No last sync for this branch. Refreshing.');
                return self.refreshSessions();
            }
        ).then(
            function () {
                self.startUpdateInterval();
            }
        );
    },

    /**
     * Delete all sessions from the data store.
     *
     * @return {Promise}
     */
    clearSessions: function clearSessions() {
        return this.dataStore.clear(DataStoreNames.SESSIONS);
    },

    /**
     * Deletes all sessions from the data store, fetches all the sessions from the API, then stores them again.
     *
     * @return {Promise}
     */
    refreshSessions: function refreshSessions() {
        this.logMsg('refreshSessions', 'Refreshing all sessions.');

        // Need the .bind(this) so 'this' is what we expect it to be in the callbacks
        // https://stackoverflow.com/a/38807640
        return this.clearSessions()
            .then(this.fetchAllSessionsFromApi.bind(this))
            .then(this.storeApiResult.bind(this));
    },

    /**
     * Fetches only recently changed sessions from the API and stores them.
     * Deletes any sessions where the status is no longer 'Active'.
     *
     * @return {Promise}
     */
    updateSessions: function updateSessions() {
        this.logMsg('updateSessions', 'Updating sessions.');

        return this.fetchUpdatedSessionsFromApi()
            .then(this.storeApiResult.bind(this)).catch((e) => {});
    },

    /**
     * Make an API request to fetch every session.
     * This method is useful for promise chaining as we need to pass the parameter to fetchSessionsFromApi.
     *
     * @return {Promise<{saleableSessions: Session[], archivedAndDeletedSessionIDs: number[]}>}
     */
    fetchAllSessionsFromApi: function fetchAllSessionsFromApi() {
        return this.fetchSessionsFromApi(false);
    },

    /**
     * Make an API request to fetch only sessions updated since the last update.
     * This method is useful for promise chaining as we need to pass the parameter to fetchSessionsFromApi.
     *
     * @return {Promise<{saleableSessions: Session[], archivedAndDeletedSessionIDs: number[]}>}
     */
    fetchUpdatedSessionsFromApi: function fetchUpdatedSessionsFromApi() {
        return this.fetchSessionsFromApi(true);
    },

    /**
     * Make an API request to download sessions.
     * Returns a promise with a list of active sessions in the response and an array of non-active session IDs in
     * the response.
     *
     * @param {boolean} [updatedOnly] True to only fetch new or updated sessions since the last fetch.
     *                                False to get every session.
     *
     * @return {Promise<{saleableSessions:Session[], archivedAndDeletedSessionIDs:number[]}>}
     */
    fetchSessionsFromApi: function fetchSessionsFromApi(updatedOnly) {
        let self = this;

        return new Promise(function (resolve, reject) {
            let queryParams = {
                limit: self.appConfig.maxSessionsToSync,
                status: 'Active,Inactive'
            };

            if (updatedOnly === true) {
                // Only get sessions modified since the last update.
                queryParams.updatedSince = self.lastUpdatedAt.toISOString();

                // Include non-Active sessions when updating so we know if a session we already had has been deleted.
                queryParams.status = 'Active,Inactive,Archived,Deleted';
            }

            let requestStart = new Date();
            self.sessionResource.query(
                queryParams,
                function (responseData) {
                    // Request success.
                    let requestDuration = (new Date()).getTime() - requestStart.getTime();
                    self.logMsg(
                        'fetchSessionsFromApi',
                        'Downloaded ' + responseData.length + ' sessions in ' + requestDuration + 'ms.'
                    );

                    // This is the structure the promise will receive.
                    // Archived and Deleted sessions are separated out and just their IDs are returned so we don't waste
                    // time hydrating a Session that will never be used.
                    let apiResult = {
                        /**
                         * Sessions with status Active or Inactive
                         *
                         * @type {Session[]}
                         */
                        saleableSessions: [],
                        /**
                         * Sessions with status Archived or Deleted
                         *
                         * @type {number[]}
                         */
                        archivedAndDeletedSessionIDs: []
                    };

                    // At this point we could filter out non active sessions and then use the Hydrator's
                    // hydrateArray method, but that would iterate over the array multiple times.
                    // So we just iterate over the whole array once here and call hydrate individually while also
                    // sorting the Active from the non-Active sessions.
                    let hydrationStart = new Date();

                    for (let i = 0; i < responseData.length; i++) {
                        if (['Active', 'Inactive'].includes(responseData[i].status)) {
                            apiResult.saleableSessions.push(
                                self.hydrator.hydrate(
                                    responseData[i],
                                    new Session()
                                )
                            );
                        } else {
                            apiResult.archivedAndDeletedSessionIDs.push(
                                // Note the API response uses the old 'eventID' key - not 'sessionID'.
                                responseData[i].eventID
                            );
                        }
                    }

                    let hydrationDuration = (new Date()).getTime() - hydrationStart.getTime();
                    self.logMsg(
                        'fetchSessionsFromApi',
                        'Hydrated ' + apiResult.saleableSessions.length + ' sessions in ' + hydrationDuration + 'ms.'
                    );

                    resolve(apiResult);
                },
                function (result) {
                    // Request failed.
                    let requestDuration = (new Date()).getTime() - requestStart.getTime();
                    self.logMsg('fetchSessionsFromApi', 'Sessions request failed in ' + requestDuration + 'ms.');

                    reject(apiErrorMessage(result));
                }
            );
        });
    },

    /**
     * Store the results from the API in the database.
     *
     * @param {{saleableSessions:Session[], archivedAndDeletedSessionIDs:int[]}} apiResult
     *
     * @return {Promise}
     */
    storeApiResult: function storeApiResult(apiResult) {
        this.logMsg('storeApiResult', apiResult);
        // Promise.all is okay here because the order of these operations doens't matter.
        return Promise.all(
            [
                this.deleteSessions(apiResult.archivedAndDeletedSessionIDs),
                this.storeSessions(apiResult.saleableSessions),
                this.setLastUpdatedAt(),
            ]
        );
    },

    /**
     * Delete the specified sessions from the database.
     *
     * @param {number[]} sessionIDs
     *
     * @return {Dexie.Promise|Promise}
     */
    deleteSessions: function deleteSessions(sessionIDs) {
        let self = this;

        if (sessionIDs.length < 1) {
            self.logMsg('deleteSessions', 'Skipped deleting because 0 session IDs.');
            return Promise.resolve();
        }

        let startedAt = new Date();
        return this.dataStore.removeMany(DataStoreNames.SESSIONS, sessionIDs).then(
            function (res) {
                let duration = (new Date()).getTime() - startedAt.getTime();
                self.logMsg('deleteSessions', 'Deleted ' + sessionIDs.length + ' sessions from DB in ' + duration + 'ms.');
                return res;
            }
        );
    },

    /**
     * @param {Session[]} sessions
     *
     * @return {Dexie.Promise}
     */
    storeSessions: function storeSessions(sessions) {
        let self = this;

        let startedAt = new Date();
        return this.dataStore.persistMany(DataStoreNames.SESSIONS, sessions).then(
            function (res) {
                let duration = (new Date()).getTime() - startedAt.getTime();
                self.logMsg('storeSessions', 'Persisted ' + sessions.length + ' sessions to DB in ' + duration + 'ms.');
                return res;
            }
        );
    },

    setLastUpdatedAt: function setLastUpdatedAt() {
        this.lastUpdatedAt = new Date();
        return this.dataStore.setLastSynced(
            DataStoreNames.SESSIONS,
            this.branch.ID,
            this.lastUpdatedAt
        );
    },

    startUpdateInterval: function startUpdateInterval() {
        this.$interval(this.updateSessions.bind(this), this.updateDelay);
        this.logMsg('startUpdateInterval', 'Update interval scheduled.');
    },

    /**
     * Returns every session for the specified event as a flat array.
     *
     * @param {number} eventID
     *
     * @return {Dexie.Promise<Session[]>}
     */
    getSessionsForEvent: function getSessionsForEvent(eventID) {
        let self = this;

        let loadStartedAt = new Date();
        return this.dataStore
            .getStore(DataStoreNames.SESSIONS)
            .where({ eventID })
            // We could filter to only return sessions in the future, but this significantly slows down the loading.
            // It's not necessary as we filter out old sessions in the SessionPickerCtrl anyway.
            // .and(function (session) {
            //     return session.startTime >= now;
            // })
            .toArray()
            // .sortBy('startTime')
            .then(function (sessionsData) {
                let loadDuration = (new Date()).getTime() - loadStartedAt.getTime();
                self.logMsg('getSessionsForEvent', 'Loaded ' + sessionsData.length + ' sessions from DB in ' + loadDuration + 'ms.');

                // We have an array of objects of data with the correct keys for a Session object, but they need to be
                // converted back to Sessions. We don't need to do a full hydration again as we know the keys match
                // up. So just do a "basic hydration" for each one.
                let rehydrateStartedAt = new Date();
                let sessionModels = basicHydrateArray(sessionsData, () => new Session());

                let rehydrateDuration = (new Date()).getTime() - rehydrateStartedAt.getTime();
                self.logMsg('getSessionsForEvent', 'Rehydrated ' + sessionModels.length + ' sessions in ' + rehydrateDuration + 'ms.');

                return sessionModels;
            });
    },

    /**
     * Returns every session for the specified event in SessionCalendar object.
     *
     * @param {number} eventID
     * @param {function} [sessionFilter]
     *
     * @return {Dexie.Promise<SessionCalendar>}
     */
    getSessionCalendarForEvent: function getSessionCalendarForEvent(eventID, sessionFilter) {
        let self = this;

        return this.getSessionsForEvent(eventID)
            .then(function (sessions) {
                const startedAt = new Date();

                let sessionCalendar = new DigiTickets.SessionCalendar(sessions, sessionFilter);

                let duration = (new Date()).getTime() - startedAt.getTime();
                self.logMsg('getSessionCalendarForEvent', 'Created calendar with ' + sessions.length + ' sessions in ' + duration + 'ms.');

                return sessionCalendar;
            });
    },

    logMsg: function logMsg(caller, message) {
        if (this.debug) {
            console.debug('[SessionManager.' + caller + ']', message);
        }
    },

    /**
     * Method to return the session with the given id. It's slightly complicated
     * because the sessions are indexed by event -> date -> session.
     *
     * @param {number} sessionId Must be a number, strings will not be found.
     *
     * @return {Dexie.Promise<Session>}
     */
    getSession(sessionId) {
        return this.dataStore.find(DataStoreNames.SESSIONS, sessionId)
            .then(
                (sessionData) => {
                    if (!sessionData) {
                        // Throwing an error rejects the promise.
                        throw new Error(`Session ${sessionId} was not found.`);
                    }
                    return basicHydrate(sessionData, new Session());
                }
            );
    },

    /**
     * Modify the availability of a session stored in the DB.
     *
     * @param {number} sessionId
     * @param {number} adjustBy
     */
    adjustAvailability: function adjustAvailability(sessionId, adjustBy) {
        this.dataStore.transaction(
            this.dataStore.TRANSACTION_MODE.READWRITE,
            [
                this.dataStore.getStore('sessions'),
            ],
            () => {
                this.getSession(sessionId)
                    .then(
                        (session) => {
                            session.available += adjustBy;
                            this.storeSessions([session]);
                        }
                    );
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionManager;
}
