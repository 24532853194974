const { toDate, toInt, toNullableInt, toNullableString, toString } = require('../../../functions/transform');

const OrderFulfillment = function () {
    /**
     * @type {Number}
     */
    this.ID = null;

    /**
     * @type {?number}
     */
    this.orderID = null;

    /**
     * @type {?string}
     */
    this.stashedCartThirdPartyRef = null;

    /**
     * @type {string}
     */
    this.fulfillmentType = '';

    /**
     * @type {Date}
     */
    this.createdAt = null;

    /**
     * @type {Date}
     */
    this.fulfilledAt = null;

    /**
     * @type {{}}
     */
    this.fulfilledItems = {};
};

OrderFulfillment.prototype = {
    /**
     * @return {boolean}
     */
    isFulfilled() {
        return this.fulfilledAt !== null;
    },

    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'ordersFulfillmentsID'],
                transform: toInt
            },
            orderID: toNullableInt,
            stashedCartThirdPartyRef: {
                field: ['thirdPartyRef', 'stashedCartThirdPartyRef'],
                transform: toNullableString
            },
            fulfillmentType: toString,
            createdAt: toDate,
            fulfilledAt: toDate,
            fulfilledItems: (value) => {
                if (!value) {
                    return {};
                } else if (typeof value === 'string') {
                    return JSON.parse(value);
                }
                return value;
            }
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderFulfillment;
}
