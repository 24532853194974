const moment = require('moment');

/**
 * @param $filter
 * @param $scope
 * @param $modalInstance
 * @param {DigiTickets.Ticket[]} tickets
 * @param {Category} category
 * @param {?Session} session
 * @param {{ticket: DigiTickets.Ticket, qty: Number}[]|undefined} initialQuantities
 * @param categories
 */
const TicketQuantityModalCtrl = function (
    $filter,
    $scope,
    $modalInstance,
    tickets,
    category,
    session,
    initialQuantities,
    categories
) {
    $scope.quantities = {};
    $scope.categories = categories;
    $scope.selectedCategory = category;

    /**
     * The category of the last ticket selected in the modal. We store this so that if the operator decides to add
     * more tickets the modal opens in the last category they added a ticket from.
     *
     * @type {Category}
     */
    let lastTicketSelectedCategory = category;

    /**
     * Tickets from all categories.
     *
     * @type {DigiTickets.Ticket[]}
     */
    const allTickets = tickets;

    /**
     * Return an array of all the tickets in a given category.
     *
     * @param {Category} category
     * @returns {DigiTickets.Ticket[]}
     */
    const ticketsInCategory = (category) => allTickets.filter((ticket) => (ticket.category.ID === category.ID));

    // When the modal opens filter the tickets by supplied category.
    $scope.tickets = ticketsInCategory(category);

    if (session) {
        $scope.title = $filter('lang')('TICKET_BOOKING.QUANTITY_SELECTION_TITLE_EDIT', {
            date: moment(session.startTime).format('dddd Do MMMM'),
            time: moment(session.startTime).format('HH:mm')
        });
    } else {
        $scope.title = $filter('lang')('TICKET_BOOKING.QUANTITY_SELECTION_TITLE');
    }

    if (initialQuantities && initialQuantities.length) {
        initialQuantities.forEach(({ ticket, qty }) => {
            $scope.quantities[ticket.ID] = qty;
        });

        // Set the qty of any tickets whose qty was not already specified to 0
        tickets.forEach((ticket) => {
            if (!$scope.quantities.hasOwnProperty(ticket.ID)) {
                $scope.quantities[ticket.ID] = 0;
            }
        });
    } else {
        // Set our initial quantities for all ticket types to zero.
        tickets.forEach((ticket) => {
            $scope.quantities[ticket.ID] = 0;
        });
    }

    $scope.add = (id) => {
        ++$scope.quantities[id];
        // Store the category of the last added ticket in case the operator reopens the modal to add more tickets.
        lastTicketSelectedCategory = $scope.selectedCategory;
    };

    $scope.subtract = (id) => {
        $scope.quantities[id] = Math.max(0, $scope.quantities[id] - 1);
    };

    $scope.cancel = () => {
        $modalInstance.close(false);
    };

    $scope.totalQuantityIsZero = () => Object.values($scope.quantities)
        .reduce((total, qty) => total + qty, 0) === 0;

    /**
     * @return {{tickets: {ticket: DigiTickets.Ticket, qty: Number}[], lastTicketSelectedCategory: Category}}
     */
    $scope.ok = () => {
        const selectedTickets = allTickets.filter((ticket) => $scope.quantities[ticket.ID] > 0);
        const result = {
            tickets: selectedTickets.map((ticket) => ({
                ticket,
                qty: $scope.quantities[ticket.ID]
            })),
            lastTicketSelectedCategory
        };
        $modalInstance.close(result);
    };

    /**
     * Filter the tickets when the category is changed.
     *
     * @param {Category} category
     */
    $scope.selectCategory = (category) => {
        $scope.selectedCategory = category;
        $scope.tickets = ticketsInCategory(category);
    };
};
