/**
 * @param {DigiTickets.CartItem} cartLine
 * @returns boolean
 */
const productThatCanBeDelivered = (cartLine) => cartLine.getItem().requiresDelivery;

/**
 * @param {DigiTickets.CartItem} cartLine
 * @returns boolean
 */
const voucherRequiringDelivery = (cartLine) => cartLine.soldGiftVoucherData
        && cartLine.soldGiftVoucherData.deliveryType === DigiTickets.GiftVoucherDeliveryType.POST;

/**
 * Whether there is an item in our cart that *can* (not *must*) be delivered.
 *
 * @param {DigiTickets.CartItem[]} cartLines
 * @returns boolean
 */
const containsDeliverableItem = (cartLines) => (
    !!cartLines.find(productThatCanBeDelivered) || !!cartLines.find(voucherRequiringDelivery)
);

/**
 * Whether there is an item in our cart that *must* be delivered.
 *
 * @param {DigiTickets.CartItem[]} cartLines
 * @returns boolean
 */
const containsItemRequiringDelivery = (cartLines) => (
    !!cartLines.find(voucherRequiringDelivery)
);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        containsDeliverableItem,
        containsItemRequiringDelivery
    };
}
