const moment = require('moment');
const { toDate } = require('../functions/transform');

/**
 * Format a date using Moment.js
 *
 * @param {Date} date
 * @param {string} format
 *
 * @return {string}
 */
const momentFilter = function (date, format) {
    if (!(date instanceof Date)) {
        date = toDate(date);
    }
    if (!date) {
        return '';
    }

    return moment(date).format(format);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = momentFilter;
}
