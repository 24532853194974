const angular = require('angular');
const { cloneDeep } = require('../../../functions/clone');
const { getObjectFromArrayByID } = require('../../../functions/functions');

/**
 * @param $q
 * @param {Hydrator} hydrator
 * @param MembershipResource
 * @param MembershipSubscriptionResource
 * @param Notification
 */
const MembershipService = function (
    $q,
    hydrator,
    MembershipResource,
    MembershipSubscriptionResource,
    Notification
) {
    this.$q = $q;
    this.hydrator = hydrator;
    this.membershipResource = MembershipResource;
    this.membershipSubscriptionResource = MembershipSubscriptionResource;
    this.notification = Notification;
};

MembershipService.prototype = {
    /**
     * Retrieve a Membership from the API.
     *
     * @param {string} ref
     * @param {string} [resolve]
     * @returns {deferred.promise}
     */
    getMembershipByRef: function getMembershipbyRef(ref, resolve) {
        let deferred = this.$q.defer();

        if (resolve === undefined) {
            resolve = '*';
        }

        this.membershipResource.query(
            {
                ref,
                resolve,
                limit: 1
            },
            (results) => {
                if (results.length > 0) {
                    let membership = this.hydrator.hydrate(results[0], new DigiTickets.Membership());
                    deferred.resolve(membership);
                } else {
                    deferred.reject();
                }
            },
            () => {
                deferred.reject();
            }
        );

        return deferred.promise;
    },

    /**
     * Send the updated dataset to the API to update a membership.
     *
     * @param {DigiTickets.Membership} membership
     * @param {DigiTickets.MembershipDataset} membershipDataset
     * @param {Function} callback
     */
    updateMembership: function updateMembership(membership, membershipDataset, callback) {
        let data = membershipDataset.toServerData();

        membership.currentlyProcessing = true;
        this.membershipResource.update(
            {
                membershipID: membership.getId(),
                resolve: '*'
            },
            data,
            (response) => {
                membership.currentlyProcessing = false;
                this.hydrator.hydrate(response, membership);
                if (typeof callback === 'function') {
                    callback(membership);
                }
            },
            (response) => {
                let error = response.data.message ? response.data.message : response.data.error;
                this.notification.error('Could not save membership. ' + error);
                membership.currentlyProcessing = false;
            }
        );
    },

    /**
     * Method to generate all the data for memberships, which will get sent to the server.
     * The return object is an array. Each element in the array represents a single membership.
     * Its properties are: a membership plan id, any overridden membership reference, an
     * address object and a members list.
     *
     * @param {DigiTickets.CartItem[]} cartItems
     *
     * @returns []
     */
    getDataForServer: function getDataForServer(cartItems) {
        let membershipData = [];

        // Loop through all the cart items (order lines)...
        angular.forEach(
            cartItems,
            /**
             * @param {DigiTickets.CartItem} cartItem
             */
            function (cartItem) {
                // If this line is a plan (with possibly multiple memberships) and we are
                // not renewing existing membership(s)...
                if (cartItem.isMembershipPlan() && !cartItem.renewingMembership) {
                    // Take a copy of its membership(s) data.
                    let membershipDatasets = cloneDeep(cartItem.membershipDatasets);

                    // Loop through each membership...
                    angular.forEach(
                        membershipDatasets,
                        /**
                         * @param {DigiTickets.MembershipDataset} membershipDataset
                         */
                        function (membershipDataset) {
                            let membershipDatasetData = membershipDataset.toServerData();
                            if (cartItem.getQuantity() < 1) {
                                membershipDatasetData.isCancelling = true;
                            }
                            membershipData.push(membershipDatasetData);
                        }
                    );
                }
            }
        );

        return membershipData;
    },

    /**
     * Calculate the refund for multiple memberships.
     *
     * @param {DigiTickets.Membership[]} memberships
     * @param {Function} callback
     */
    calculateMembershipRefunds: function calculateMembershipRefunds(memberships, callback) {
        let membershipIDs = memberships.map(function (membership) {
            return membership.ID;
        });

        this.membershipResource.getRefunds(
            {
                'membershipIDs[]': membershipIDs
            },
            (response) => {
                for (let i = 0; i < memberships.length; i++) {
                    let membership = memberships[i];
                    if (response.hasOwnProperty(membership.ID)) {
                        membership.refundAmount = this.hydrator.hydrate(
                            response[membership.ID],
                            new DigiTickets.MembershipRefundAmount()
                        );
                    }
                }

                callback(memberships);
            }
        );
    },

    /**
     * @param {DigiTickets.Membership[]} memberships
     * @param {Function} callback
     */
    cancelMemberships: function cancelMemberships(memberships, callback) {
        let self = this;

        let membershipIDs = this.mapMembershipIds(memberships);

        this.membershipResource.cancelMultiple(
            {
                'membershipIDs[]': membershipIDs
            },
            function (response) {
                self.rehydrateModels(
                    memberships,
                    response,
                    'membershipID'
                );

                callback(response, memberships);
            },
            function () {
                callback(null, memberships);
            }
        );
    },

    /**
     * @param {DigiTickets.Membership[]|MembershipSubscription[]} models
     * @param {object[]} data
     * @param {string} idPropertyInData
     */
    rehydrateModels: function rehydrateModels(models, data, idPropertyInData) {
        for (let i = 0; i < data.length; i++) {
            let ID = data[i][idPropertyInData];
            let model = getObjectFromArrayByID(models, ID);
            if (model) {
                this.hydrator.hydrate(
                    data[i],
                    model
                );
            }
        }
    },

    stopRenewingMemberships: function stopRenewingMemberships(memberships, callback) {
        let self = this;

        let membershipIDs = memberships.map(function (membership) {
            return membership.ID;
        });

        this.membershipResource.updateMultiple(
            {
                membershipIDs,
                stopRenewing: 1,
                resolve: '*'
            },
            function (response) {
                if (response) {
                    self.rehydrateModels(
                        memberships,
                        response,
                        'membershipID'
                    );
                }

                callback(memberships);
            },
            function () {
                callback(memberships);
            }
        );
    },

    /**
     * @param {DigiTickets.Membership[]} memberships
     * @param {Object<string>} refundTypes An object where membership IDs are the key and a value from
     *                                             DigiTickets.MembershipRefundType is the value.
     * @param {string|null} defaultRefundType Refund type to use if the membershipID was
     *                                        not present in refundTypes.
     *
     * @return {DigiTickets.CartItem[]}
     */
    generateMembershipRefundCartItems: function generateMembershipRefundCartItems(
        memberships,
        refundTypes,
        defaultRefundType
    ) {
        let cartItems = [];

        for (let i = 0; i < memberships.length; i++) {
            let membership = memberships[i];

            let refundType = refundTypes.hasOwnProperty(membership.ID) ? refundTypes[membership.ID] : defaultRefundType;
            if (!refundType || !membership.refundAmount || !membership.refundAmount.hasOwnProperty(refundType)) {
                continue;
            }

            let refundAmount = membership.refundAmount[refundType + 'Total'];
            if (refundAmount <= 0) {
                // Sometimes we want zero refunds to be included just to visually show they're being
                // cancelled / returned. This is now required as the SellCtrl triggers the cancellation,
                // so needs a cartItem for each membership to cancel.
                refundAmount = 0.00;
            }

            /**
             * How the refund amount is split between different subscriptions.
             *
             * @type {Object<number>}
             */
            let subscriptionApportionment = membership.refundAmount[refundType];

            // The refund line uses the membership's current MembershipPlan as the item.
            let cartItem = new DigiTickets.CartItem(
                null,
                membership.currentPlan
            );
            // Note that the price is an absolute value, so the qty need to be -1.
            cartItem.setPrice(refundAmount);
            cartItem.adjustQuantity(-1);
            cartItem.setCanAdjustQuantity(false);
            cartItem.setCanRemove(false);
            cartItem.setMembershipSubscriptionApportionedAmounts(
                {
                    // Amounts for instance 1 (there's only one instance on the line)
                    1: Math.negateObjectValues(subscriptionApportionment)
                }
            );
            cartItem.setCancellingMembership(membership);
            cartItem.addMembershipDataset(membership.toMembershipDataset());
            cartItem.calculate();

            cartItems.push(cartItem);
        }

        return cartItems;
    },

    /**
     * Generate a comma separated string of all the refs for the memberships in the given array.
     *
     * @param {DigiTickets.Membership[]} memberships
     *
     * @return {string}
     */
    generateMembershipRefsString: function generateMembershipRefsString(memberships) {
        let refs = memberships.map(function (membership) {
            return membership.ref;
        });

        return refs.join(', ');
    },

    /**
     * @param {MembershipSubscription[]} subscriptions
     * @param {function} callback
     */
    saveNewSubscriptionInstallmentAmounts: function saveNewSubscriptionInstallmentAmounts(subscriptions, callback) {
        let self = this;

        let newInstallmentAmounts = {};

        for (let i = 0; i < subscriptions.length; i++) {
            newInstallmentAmounts[subscriptions[i].ID] = subscriptions[i].newInstallmentAmount;
        }

        let membershipSubscriptionIds = Object.keys(newInstallmentAmounts);

        this.membershipSubscriptionResource.updateMultiple(
            {},
            {
                membershipSubscriptionIDs: membershipSubscriptionIds,
                installmentAmounts: newInstallmentAmounts
            },
            function (response) {
                // Re-hydrate the models with the new data.
                self.rehydrateModels(
                    subscriptions,
                    response,
                    'membershipSubscriptionID'
                );

                callback(subscriptions, newInstallmentAmounts);
            },
            function () {
                callback(subscriptions, newInstallmentAmounts);
            }
        );
    },

    /**
     * @param {DigiTickets.Membership[]} memberships
     *
     * @return {number[]}
     */
    mapMembershipIds: function mapMembershipIds(memberships) {
        return memberships.map(function (membership) {
            return membership.ID;
        });
    },

    /**
     * @param {DigiTickets.Membership[]} memberships
     *
     * @return {DigiTickets.Membership[]}
     */
    extractAutoRenewingMemberships: function extractAutoRenewingMemberships(memberships) {
        return memberships.filter(
            /**
             * @param {DigiTickets.Membership} membership
             * @return {boolean}
             */
            function (membership) {
                return membership.willAutoRenew();
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipService;
}
