DigiTickets.BarcodeScanDetector = function (
    options,
    angular,
    $modalStack
) {
    this.$modalStack = $modalStack;

    this.defaults = {
        maxTimeBetweenInputs: 200, // time in milliseconds to wait between each character input from the scanner
        debug: false, // send debugging output to browser console
        suppressReturnEvent: true, // stop the 'return' character from submitting forms etc, when a scan occurs
        suppressKeyEvent: true // stop characters being displayed in input elements when a scan occurs
    };

    // Mapping of events to fire with their respective regular expressions to trigger them.
    this.patterns = {};

    this.options = angular.extend(this.defaults, options);
    this.lastInputAt = new Date();
    this.buffer = '';

    this.logMessage('Initialising BarcodeScanDetector...');
    this.logMessage(this.options);
};

DigiTickets.BarcodeScanDetector.prototype = {

    logMessage: function logMessage(message) {
        if (this.options.debug) {
            console.log(message);
        }
    },

    addPattern: function addPattern(event, regex) {
        this.patterns[event] = regex;
    },

    keyDownReceived: function keyDownReceived(event, scope) {
        let now = new Date();
        let delay = now - this.lastInputAt;

        this.logMessage('buffer: ' + this.buffer);
        this.logMessage('delay: ' + delay);

        // Ignore the "SHIFT" keydown event (before we set the last input time).
        // There is no keyup event for "SHIFT", of course, because we don't listen for it.
        if (event.which == 16) {
            return;
        }

        this.lastInputAt = now;

        if (delay < this.options.maxTimeBetweenInputs) {
            if (event.which == 13) {
                // the return key was pressed
                this.logMessage('Return key detected...');
                this.processString(this.buffer, event, scope);
                this.buffer = '';
            } else {
                // this isn't the return key, but did occur very quickly after last input
                this.buffer += String.fromCharCode(event.which);
            }
        } else {
            // there was a gap between input; clear the buffer and set it to the key that was pressed
            this.buffer = String.fromCharCode(event.which);
        }
    },

    /**
     * Check if the given string matches any of the expected pattens and fire the appropriate event if it does.
     *
     * @param string
     * @param event
     * @param scope
     */
    processString: function (string, event, scope) {
        for (let eventName in this.patterns) {
            if (this.patterns.hasOwnProperty(eventName)) {
                let pattern = this.patterns[eventName];
                let scanExp = new RegExp(pattern, 'i');
                if (string.match(scanExp)) {
                    // Suppress the default action of the input event if a barcode is detected
                    if (event && this.options.suppressReturnEvent) {
                        event.preventDefault();
                    }

                    this.logMessage('Broadcasting event: ' + eventName);

                    let openModal = this.$modalStack.getTop();
                    scope.$broadcast(eventName, {
                        code: string,
                        openModal: openModal || false,
                        time: new Date()
                    });
                }
            }
        }
    }
};
