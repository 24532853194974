/**
 * @param $scope
 * @param $modalInstance
 * @param {Number} additionalDonation
 * @param {Number} totalDonation
 * @param {UserService} UserService
 */
const GiftAidModalCtrl = function (
    $scope,
    $modalInstance,
    additionalDonation,
    totalDonation,
    UserService
) {
    /**
     * @type {?DigiTickets.Company}
     */
    $scope.company = UserService.getCompany();

    $scope.additionalDonation = additionalDonation;
    $scope.totalDonation = totalDonation;

    // Gift Aid and price increase.
    $scope.yes = () => {
        $modalInstance.close({ answer: true });
    };

    // No Gift Aid and no price increase.
    $scope.no = () => {
        $modalInstance.close({ answer: false });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GiftAidModalCtrl;
}
