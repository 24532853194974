const { ucfirst } = require('./strings');

/**
 * FIXME: This practise of adding to string prototype is against eslint's recommendations:
 * https://eslint.org/docs/rules/no-extend-native
 */

if (!String.prototype.repeat) {
    String.prototype.repeat = function repeat(num) {
        return new Array(1 + num).join(this);
    };
}

if (!String.prototype.snip) {
    String.prototype.snip = function snip(amount) {
        return this.substring(0, this.length - amount);
    };
}

if (!String.prototype.ucfirst) {
    String.prototype.ucfirst = function () {
        return ucfirst(this);
    };
}

if (!String.prototype.ucwords) {
    String.prototype.ucwords = function ucwords() {
        return (this + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    };
}

if (!String.prototype.toUpperSnakeCase) {
    String.prototype.toUpperSnakeCase = function upperSnakeCase() {
        return (this.replace(' ', '_')).toUpperCase();
    };
}

if (!String.prototype.toTitleCase) {
    /**
     * Source: https://stackoverflow.com/a/5574446/710630
     */
    String.prototype.toTitleCase = function () {
        return this.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    };
}
