/**
 * @param CustomerResource
 * @param {Hydrator} hydrator
 */
const CustomerSearchService = function CustomerSearchService(
    CustomerResource,
    hydrator
) {
    this.customerResource = CustomerResource;
    this.hydrator = hydrator;
};

CustomerSearchService.prototype = {
    /**
     * A wrapper around the CustomerAccountsResource.query method, because angular-resource
     * messes with objects that are returned, even in transformResponse.
     * This takes the raw data returned by CustomerAccountsResource and returns actual
     * CustomerAccount objects.
     *
     * @param {object} params
     * @param {function} success
     * @param {function} failure
     */
    query: function (params, success, failure) {
        const self = this;
        this.customerResource.query(
            params,
            function (response) {
                let results = [];
                for (let i = 0; i < response.length; i++) {
                    let result = response[i];

                    if (result.contact) {
                        result.contact = self.hydrator.hydrate(
                            result.contact,
                            new DigiTickets.Contact()
                        );
                    }

                    if (result.address) {
                        result.address = self.hydrator.hydrate(
                            result.address,
                            new DigiTickets.Address()
                        );
                    }

                    if (result.account) {
                        result.account = self.hydrator.hydrate(
                            result.account,
                            new DigiTickets.CustomerAccount()
                        );
                    }

                    if (result.membership) {
                        result.membership = self.hydrator.hydrate(
                            result.membership,
                            new DigiTickets.Membership()
                        );
                    }

                    results.push(result);
                }

                success(results);
            },
            failure
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerSearchService;
}
