const numPadDirective = function () {
    return {
        restrict: 'E',
        scope: {
            numPad: '=',
            listen: '='
        },
        templateUrl: 'partials/directives/dt-num-pad.html',
        link: function ($scope) {
            $scope.$on('keydownListener::keydown', function (event, originalEvent) {
                if ($scope.listen !== false) {
                    $scope.numPad.keyDownReceived(originalEvent, $scope);
                }
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = numPadDirective;
}
