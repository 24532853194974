const CartCalculationResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'offers/',
        ApiRequestParams.defaults(),
        {
            calculate: {
                url: AppConfig.apiBaseUrl + 'cartcalculations',
                method: 'POST'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartCalculationResource;
}
