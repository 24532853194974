const startFromFilter = function (input, start) {
    if (input == undefined || start == undefined) {
        return;
    }
    start = +start; // parse to int
    return input.slice(start);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = startFromFilter;
}
