DigiTickets.Worldpay.TransactionResult = Object.freeze({
    APPROVED_ONLINE: 1,
    APPROVED_OFFLINE: 2,
    APPROVED_MANUAL_REFERRAL: 3,
    DECLINED_ONLINE: 4,
    DECLINED_OFFLINE: 5,
    CANCELLED: 9,
    TRANSACTION_PERFORMED: 10,
    CAPTURE_CARD_DECLINED_ONLINE: 16,
    TRANSACTION_ABORTED: 19,
    PRE_SALES_COMPLETED: 20,
    PRE_SALES_REJECTED: 21,
    CARD_NUMBER_NOT_MATCHED: 22,
    EXPIRY_DATE_NOT_MATCHED: 23,
    INVALID_TRANSACTION_STATE: 24,
    TRANSACTION_NOT_VALID_FOR_REQUESTED_OPERATION: 25,
    INVALID_PGTR: 26,
    INVALID_MERCHANT: 27,
    INVALID_TERMINAL: 28,
    MERCHANT_STATUS_IS_NOT_VALID: 29,
    INVALID_CARD_NUMBER_: 30,
    EXPIRED_CARD: 31,
    PRE_VALID_CARD: 32,
    INVALID_ISSUE_NUMBER: 33,
    INVALID_CARD_EXPIRY_DATE: 34,
    INVALID_START_DATE: 35,
    CARD_NOT_ACCEPTED: 36,
    TRANSACTION_NOT_ALLOWED: 37,
    CASH_BACK_NOT_ALLOWED: 38,
    STATUS_BUSY: 42,
    STATUS_NOT_BUSY: 43,
    PINPAD_IS_NOT_CONNECTED: 44,
    PINPAD_IS_CONNECTED: 45,
    AVS_DETAILS_REQUIRED: 50
});
