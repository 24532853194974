/**
 * @param {Function} currencyFilter
 * @param {CurrencyService} currencyService
 * @return {Function}
 */
const currencySymbolFilter = (currencyFilter, currencyService) => (input, fractionSize) => {
    const output = currencyFilter(input, currencyService.getSymbol(), fractionSize);

    if (fractionSize === 0) {
        // Cut off the decimal because Angular < 1.3 doesn't support the fractionSize parameter
        return output.split('.').slice(0, -1).join('.');
    }

    return output;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = currencySymbolFilter;
}
