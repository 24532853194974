const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
/**
 * @param $filter
 * @param $scope
 * @param {CartService} cartService
 * @param Notification
 * @param {UserService} UserService
 * @param {DigiTickets.AppConfig} AppConfig
 */
const PayPalHereCtrl = function PayPalHereCtrl(
    $filter,
    $scope,
    AppConfig,
    cartService,
    Notification,
    UserService
) {
    $scope.thisPaymentMethodRef = PaymentMethodRef.CARD_PAYPAL_HERE;
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    $scope.cart = cartService;

    $scope.partialAmount = null;
    $scope.getAmount = function getAmount() {
        if ($scope.partialAmount !== null) {
            return $scope.partialAmount;
        }

        return $scope.cart.getRemainingBalance();
    };

    $scope.clearPartialPayment = function clearPartialPayment() {
        $scope.partialAmount = null;
    };

    $scope.makePartialPayment = function makePartialPayment() {
        $scope.showMakePartialModal(function (amount) {
            if (amount) {
                $scope.partialAmount = amount;
            } else {
                $scope.partialAmount = null;
            }
        });
    };

    $scope.lastAmountOpened = null;

    let getPayPalHereUrl = function getPayPalHereUrl() {
        /**
         * As the PayPal Here API is pretty bad when it comes to
         * specifying line totals, discounts, etc, we'll just add
         * a single amalgamated item that represents the whole order.
         */
        let invoice = {};

        let amount = $scope.getAmount();
        $scope.lastAmountOpened = amount;

        invoice.itemList = {
            item: [
                {
                    taxRate: '0',
                    name: 'DigiPoint Purchase',
                    description: 'DigiPoint Purchase',
                    unitPrice: amount,
                    taxName: 'Tax',
                    quantity: '1'
                },
            ]

        };
        invoice.paymentTerms = 'DueOnReceipt';
        invoice.currencyCode = UserService.company.currency.code;
        invoice.discountPercent = '0';
        // invoice['merchantEmail'] = 'tmesserschmidt@paypal.com';
        // invoice['payerEmail'] = 'foo@bar.com';

        console.debug('PayPal Here Amount', amount);
        console.debug('PayPal Here Invoice', invoice);

        let returnUrl = AppConfig.domainRoot + '/paypalhere/callback';
        let retUrl = encodeURIComponent(returnUrl + '?Type={Type}&InvoiceId={InvoiceId}&Tip={Tip}&Email={Email}&TxId={TxId}');
        let ppHereUrl = 'paypalhere://takePayment?returnUrl=' + retUrl;

        ppHereUrl += '&accepted=card,paypal';
        ppHereUrl += '&step=choosePayment';
        ppHereUrl = ppHereUrl + '&invoice=' + encodeURIComponent(JSON.stringify(invoice));

        return ppHereUrl;
    };

    $scope.openPayPalHere = function openPayPalHere() {
        if ($scope.getAmount() > $scope.cart.getRemainingMaxPayment()) {
            Notification.error(
                $filter('lang')('PAYMENT.MAX_AMOUNT_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
            );
            return false;
        }

        let url = getPayPalHereUrl();
        window.open(url);
    };

    $scope.$on('payment-modal.ok-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Add a payment for the amount we have entered,
            // instead of the PaymentCtrl creating a payment for the entire remaining balance.
            $scope.preventAddFullPaymentOnClose();

            if ($scope.lastAmountOpened === null) {
                Notification.error(
                    $filter('lang')('PAYMENT.PAYPAL_NOT_OPENED')
                );

                $scope.preventClose();
                return false;
            }

            let payment = new DigiTickets.Payment();
            payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
            payment.setTendered($scope.lastAmountOpened);
            payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
            $scope.addPayment(payment);

            // Clear for next use.
            $scope.partialAmount = null;
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PayPalHereCtrl;
}
