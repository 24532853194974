const FieldInstanceFilter = require('../../libraries/DigiTickets/CustomFields/FieldInstanceFilter');
const VariableDiscount = require('../../models/VariableDiscount');
const VariableDiscountModalCtrl = require('./VariableDiscountModalCtrl');
const VariableDiscountsCollection = require('../../models/VariableDiscountsCollection');
const { cleanCartLine } = require('../../libraries/DigiTickets/Cart/cartLineCleaning');
const { cloneDeep } = require('../../functions/clone');
const { extractFieldValuesFromForm } = require('../../libraries/DigiTickets/CustomFields/fieldForm');
const { setValuesOfFieldGroup } = require('../../libraries/DigiTickets/CustomFields/fieldFunctions');

/**
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {CartService} cart
 * @param {DigiTickets.CartItem} cartItem
 * @param {FieldInstanceFactory} fieldInstanceFactory
 * @param {ModalFactory} ModalFactory
 * @param {DigiTickets.OfferManager} OfferManager
 * @param PrivilegesService
 * @param requestedTabName
 * @param ReturnReasonService
 */
const ItemEditCtrl = function ItemEditCtrl(
    $modalInstance,
    $scope,
    $timeout,
    cart,
    cartItem,
    fieldInstanceFactory,
    ModalFactory,
    OfferManager,
    PrivilegesService,
    requestedTabName,
    ReturnReasonService
) {
    /**
     * The name of the tab to be displayed initially.
     *
     * @type {string} $scope.requestedTabName
     */
    $scope.requestedTabName = requestedTabName;

    /**
     * The currently displayed tab.
     *
     * @type {object|null}
     */
    $scope.activeTab = null;

    /**
     * The tab that was displayed prior to the current one.
     *
     * @type {object|null}
     */
    $scope.previousActiveTab = null;

    /**
     * @type {CartService}
     */
    $scope.cart = cart;

    /**
     * @type {DigiTickets.CartItem}
     */
    $scope.cartItem = cartItem;

    /**
     * @type {DigiTickets.OfferManager}
     */
    $scope.offerManager = OfferManager;

    /**
     * @type {DigiTickets.PrivilegesManager}
     */
    $scope.privilegesManager = PrivilegesService;

    /**
     * @type {DigiTickets.ReturnReasonManager}
     */
    $scope.returnReasonsManager = ReturnReasonService;

    // Modal
    $scope.modal = {
        name: $scope.cartItem.getName(),
        cancelDisabled: false,
        okDisabled: false,

        ok: function ok() {
            let ok = true;

            // Iterate over all the tabs and validate them if applicable
            $scope.tabset.tabs.forEach(function (tab) {
                tab.error = null;

                if (tab.controller.validate instanceof Function) {
                    if (!tab.controller.validate(tab)) {
                        // If the validate function does not return true, prevent the submission.
                        ok = false;
                        // Switch to the tab that caused the failure.
                        $scope.tabset.selectTab(tab);
                    }
                }
            });

            if (ok) {
                // Perform the onOK function of each tab
                $scope.tabset.tabs.forEach(function (tab) {
                    if (tab.controller.onOK instanceof Function) {
                        tab.controller.onOK(tab);
                    }
                });

                $modalInstance.close();
            }
        },

        cancel: function cancel() {
            $modalInstance.dismiss('cancel');
        }
    };

    // Ask Early Custom Fields
    $scope.fieldsTab = {
        /**
         * @type {FieldGroup}
         */
        fieldGroup: null,

        /**
         * @type {number}
         */
        fieldCount: 0,

        // The custom fields for this instance of the modal.
        fields: null,

        permissionResult: null,

        // Handle saving the entered data when the form OK is set.
        onOK() {
            // Grab the field data from the form.
            const fieldValues = extractFieldValuesFromForm();

            // Set those values on the original (not cloned) field group on the item instance.
            setValuesOfFieldGroup(
                fieldValues,
                cartItem.itemInstances[0].fields,
                FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY,
                true
            );

            // Call the cartLineCleaner which will copy those askEarly values to all the instanes on the line.
            cleanCartLine(cartItem);
        },

        onInit() {
            // Tell the template to only display ask early field instances.
            $scope.fieldInstanceFilter = FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY;

            // Set the field group as a clone of the field group for the first item instance on the line.
            $scope.fieldsTab.fieldGroup = cloneDeep(cartItem.itemInstances[0].fields);
            $scope.fieldsTab.fieldCount = $scope.fieldsTab.fieldGroup.instances.filter($scope.fieldInstanceFilter).length;
        }
    };

    // Discounts (Manual Line Offers)
    $scope.discountsTab = {
        offers: null,
        hasSupervisorOfferPermission: null,
        permissionResult: null,

        /**
         * @var {DigiTickets.Offer|null}
         */
        selectedManualOffer: $scope.cartItem.manualOffer,

        /**
         * Create a clone of the item's variableDiscountsCollection which is what we'll work with
         * and modify in this modal.
         * It is only set back on the cartItem if the OK button is pressed.
         *
         * @var {VariableDiscountsCollection}
         */
        variableDiscountsCollection: $scope.cartItem.variableDiscountsCollection
            ? cloneDeep($scope.cartItem.variableDiscountsCollection)
            : new VariableDiscountsCollection(),

        onInit: function onInit() {
            // Get all manually applied offers from the Offer Manager
            $scope.discountsTab.offers = $scope.offerManager.getManualLineOffers();
        },

        /**
         * OK button has been pressed on the item edit modal and all validations have passed.
         * Persist the changes.
         */
        onOK() {
            cartItem.setVariableDiscountsCollection($scope.discountsTab.variableDiscountsCollection);
            cart.setManualOfferForLine($scope.discountsTab.selectedManualOffer, cartItem);
        },

        onPermissionGranted: function onPermissionGranted() {
            // Check if we can apply supervisor offers
            let supervisorCheckPromise;
            if ($scope.discountsTab.permissionResult.grantedBy) {
                // If a supervisor granted the privilege, check if that supervisor has the
                // apply_supervisor_discounts permission.
                supervisorCheckPromise = $scope.privilegesManager.checkUserPrivilege(
                    $scope.discountsTab.permissionResult.grantedBy,
                    'apply_supervisor_discounts'
                );
            } else {
                supervisorCheckPromise = $scope.privilegesManager.checkCurrentUserPrivilege(
                    'apply_supervisor_discounts'
                );
            }

            supervisorCheckPromise.then(
                /**
                 * @param {DigiTickets.PrivilegeCheckResult} result
                 */
                function (result) {
                    if (result.granted) {
                        $scope.discountsTab.hasSupervisorOfferPermission = true;
                    }
                },
                /**
                 * @param {DigiTickets.PrivilegeCheckResult} result
                 */
                function (result) {
                    $scope.discountsTab.hasSupervisorOfferPermission = false;
                }
            );
        },

        /**
         * @param {DigiTickets.Offer} offer
         *
         * @returns {boolean}
         */
        isSelectedOffer: function isOfferSelected(offer) {
            return $scope.discountsTab.selectedManualOffer && $scope.discountsTab.selectedManualOffer.ID === offer.ID;
        },

        /**
         * Triggered when a discount button is pressed.
         *
         * @param {DigiTickets.Offer} offer
         */
        selectOffer: function selectOffer(offer) {
            const select = async function () {
                if (offer.isVariable()) {
                    // If a variable discount has been selected we need to capture additional data
                    // using the "variable discount modal".
                    // To remove a variable discount use the 'Remove discount' button in the modal.

                    // Either get the existing data from the collection or create a new VariableDiscount model.
                    let variableDiscount = $scope.discountsTab.variableDiscountsCollection.getVariableDiscount(
                        offer.ID
                    );
                    if (!variableDiscount) {
                        // No existing data for this discount. Create a new modal to pass to the modal.
                        variableDiscount = new VariableDiscount(offer.ID);
                    }

                    // Send the discount model to the VariableDiscountModal to populate.
                    try {
                        let result = await $scope.discountsTab.captureVariableOfferData(variableDiscount);

                        switch (result) {
                            case 'ok':
                                // Add the discount to the collection.
                                $scope.discountsTab.variableDiscountsCollection.addVariableDiscount(variableDiscount);

                                // Select the offer in the modal.
                                $scope.discountsTab.selectedManualOffer = offer;
                                break;
                            case 'remove':
                                // Remove the discount from the collection.
                                $scope.discountsTab.variableDiscountsCollection.deleteVariableDiscount(offer.ID);

                                // De-select the offer
                                $scope.discountsTab.selectedManualOffer = null;
                                break;
                        }
                    } catch (e) {
                        // Catching the modal being cancelled.
                    }
                } else {
                    // If it is a standard discount then press to select and press to de-select.
                    // Note if you switch from a variable discount to a regular offer the data for the variable
                    // discount is not removed, so you can switch back to the variable one later without re-entering.
                    if ($scope.discountsTab.isSelectedOffer(offer)) {
                        // De-select the offer
                        $scope.discountsTab.selectedManualOffer = null;
                        offer = null;
                    } else {
                        // Select the offer
                        $scope.discountsTab.selectedManualOffer = offer;
                    }
                }
                // Having applied the offer, we can allow OK.
                $scope.modal.okDisabled = false;

                if (offer && offer.isVariable()) {
                    $scope.$apply();
                }
            };

            if (offer.requiresSupervisorApproval && !$scope.discountsTab.hasSupervisorOfferPermission) {
                $scope.privilegesManager.requirePrivilege('apply_supervisor_discounts')
                    .then(function (result) {
                        if (result.granted) {
                            select();
                            $scope.discountsTab.hasSupervisorOfferPermission = true;
                        }
                    });
            } else {
                select();
            }
        },

        /**
         * Open the modal to capture the amount and narrative for a variable discount.
         *
         * @param {VariableDiscount} variableDiscount
         * @param {{}} [templateText] (allows for modal customisation)
         * @param {boolean} [showSignButton] (not currently implemented)
         *
         * @returns {Promise<*|promise|{then, catch, finally}>}
         */
        captureVariableOfferData: async function captureVariableOfferData(
            variableDiscount,
            templateText,
            showSignButton
        ) {
            return ModalFactory.open(
                'variable-discount',
                VariableDiscountModalCtrl,
                'partials/modals/variableDiscountModal.html',
                {
                    variableDiscount: () => variableDiscount,
                    templateText: () => templateText,
                    showSignButton: () => showSignButton
                }
            );
        }
    };

    // Return reasons
    $scope.returnReasonsTab = {
        error: null,
        returnReasons: null,
        returnReasonID: $scope.cartItem.returnReason.reasonID,
        returnToStock: $scope.cartItem.returnReason.returnToStock,
        permissionResult: null,

        onInit: function onInit() {
            // Get the return reasons from the Return Reason Manager.
            $scope.returnReasonsTab.returnReasons = [];
            $scope.returnReasonsManager.getReturnReasons().then(function (returnReasons) {
                $scope.returnReasonsTab.returnReasons = returnReasons;
            });
        },

        validate: function validate(tab) {
            if (!tab.disabled && !$scope.returnReasonsTab.returnReasonID) {
                // Prevent continuing without a return reason if the return reason tab is enabled.
                tab.error = 'ITEM_EDIT_RETURN_REASONS.REASON_REQUIRED';
                return false;
            }

            return true;
        },

        // Handle saving the entered data when the form OK is set.
        onOK: function onOK() {
            $scope.cartItem.returnReason.reasonID = $scope.returnReasonsTab.returnReasonID;

            // For items that aren't products (eg. tickets), default to returning to stock
            if ($scope.cartItem.getItemType() !== 'Product') {
                $scope.cartItem.returnReason.returnToStock = 1;
            }

            $scope.cartItem.returnReason.returnToStock = $scope.returnReasonsTab.returnToStock;
        },

        // Set the return to stock when a reason is selected
        returnReasonSelected: function returnReasonSelected(reason) {
            $scope.returnReasonsTab.returnToStock = $scope.returnReasonsTab.returnToStock !== undefined ? $scope.returnReasonsTab.returnToStock : reason.returnToStock;
        },

        // Determine if this tab should be hidden.
        hideTab: function hideTab() {
            // There is a complication with hiding the return reasons in that the
            // quantity will be a positive number when this code is called so we
            // have to rely on the requested tab being the return reasons tab when
            // that is the case.

            // Truth table
            // ===========
            // Item Type    Requested Tab  Qty - Results
            // -----------  -------------- ---   -------
            // Returnable   Return Reasons +ve   Show
            // Returnable   Return Reasons 0     Show
            // Returnable   Return Reasons -ve   Show
            // Returnable   Other          -ve   Show

            // !Returnable  Return Reasons +ve   Hide
            // !Returnable  Return Reasons 0     Hide
            // !Returnable  Return Reasons -ve   Hide
            // !Returnable  Other          +ve   Hide
            // !Returnable  Other          0     Hide
            // !Returnable  Other          -ve   Hide

            // Returnable   Other          +ve   Hide
            // Returnable   Other          0     Hide

            return ['Ticket', 'Product'].indexOf($scope.cartItem.getItemType()) === -1
                || (
                    $scope.requestedTabName !== 'ITEM_EDIT.RETURN_REASONS'
                    && $scope.cartItem.getQuantity() >= 0
                );
        }
    };

    // Tabs
    $scope.tabset = {
        // active : Indicates which tab is active initially.
        // disabled: Not really working as expected, but hopefully can be sorted.
        // heading: The label (via the lang filter) for the tab.
        // hidden: Completely suppress the tab.
        // permissionGranted: Used to control accessibility to content of tab if permission fails.
        // requiresPermission: Which permission is required for this tab.
        // src: The HTML template for the tab.
        // tab: The tab entity as defined above.
        tabs: [
            // Temporarily hide the item edit tab until a full design is available.
            // {
            //     active: $scope.requestedTabName === 'ITEM_EDIT.ITEM_DETAILS',
            //     disabled: false,
            //     heading: 'ITEM_EDIT.ITEM_DETAILS',
            //     permissionGranted: true,
            //     requiresPermission: false,
            //     src: 'partials/modals/itemEdit/itemDetails.html'
            // },
            {
                active: $scope.requestedTabName === 'ITEM_EDIT.ASK_EARLY_FIELDS',
                disabled: false,
                heading: 'ITEM_EDIT.ASK_EARLY_FIELDS',
                permissionGranted: true,
                requiresPermission: false,
                src: 'partials/modals/itemEdit/askEarlyFields.html',
                controller: $scope.fieldsTab
            },
            {
                active: $scope.requestedTabName === 'ITEM_EDIT.DISCOUNTS',
                disabled: false,
                heading: 'ITEM_EDIT.DISCOUNTS',
                permissionGranted: null,
                requiresPermission: 'apply_discounts',
                src: 'partials/modals/itemEdit/discounts.html',
                controller: $scope.discountsTab
            },
            {
                active: $scope.requestedTabName === 'ITEM_EDIT.RETURN_REASONS',
                disabled: $scope.returnReasonsTab.hideTab(),
                heading: 'ITEM_EDIT.RETURN_REASONS',
                hidden: $scope.returnReasonsTab.hideTab(),
                permissionGranted: null,
                requiresPermission: 'issue_refunds',
                src: 'partials/modals/itemEdit/returnReasons.html',
                controller: $scope.returnReasonsTab
            },
        ],

        selectTab: function selectTab(tab) {
            tab.active = true;
            $scope.tabset.onTabSelect(tab);
        },

        // Handle permissions on tab selection.
        onTabSelect: function onTabSelect(tab) {
            $scope.previousActiveTab = $scope.activeTab;
            $scope.activeTab = tab;

            if (tab.requiresPermission && tab.permissionGranted !== true) {
                tab.disabled = true;

                $scope.privilegesManager
                    .requirePrivilege(tab.requiresPermission)
                    .then(
                        /**
                         * @param {DigiTickets.PrivilegeCheckResult} result
                         */
                        function (result) {
                            tab.controller.permissionResult = result;

                            if (result.granted) {
                                // Permission granted
                                tab.disabled = false;
                                tab.permissionGranted = true;

                                if (tab.controller.onPermissionGranted instanceof Function) {
                                    tab.controller.onPermissionGranted();
                                }
                            }
                        },
                        function (result) {
                            tab.permissionGranted = false;
                            tab.controller.permissionResult = result;

                            if ($scope.previousActiveTab) {
                                // Kick back to previous tab when permission fails
                                $scope.tabset.selectTab($scope.previousActiveTab);
                            } else {
                                // Or cancel the whole dialog if this was the first tab requested
                                $scope.modal.cancel();
                            }
                        }
                    );
            } else {
                tab.disabled = false;
                tab.permissionGranted = true;
            }

            // Nothing is currently using onSelect. Uncomment this if needed
            // if (!tab.disabled && tab.permissionGranted && (tab.controller.onSelect instanceof Function)) {
            //     tab.controller.onSelect();
            // }
        }

        // Handle tab deselection.
        // Nothing is currently using onTabDeselect. Uncomment (and properly implement) this if needed
        // onTabDeselect: function onTabDeselect(tab) {
        //     if (tab.controller.onDeselect instanceof Function) {
        //         tab.onDeselect();
        //     }
        // }
    };

    /**
     * Set the active tab to the selected tab, or the first tab if non selected.
     *
     * @type {string} $scope.requestedTabName
     */
    $scope.requestedTabName = requestedTabName !== undefined ? requestedTabName : $scope.tabs[0].heading;

    // Initialise the tabs.
    $scope.tabset.tabs.forEach(function (tab) {
        if (tab.controller.onInit instanceof Function) {
            tab.controller.onInit();
        }
    });

    // Allow the tabs to be filtered by a function
    $scope.hiddenTabFilter = function hiddenTabFilter(tab) {
        return !tab.hasOwnProperty('hidden') || !tab.hidden;
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ItemEditCtrl;
}
