/**
 * Holds a number of FieldGroup objects and provides
 * methods to pick out groups based on criteria given.
 */
const FieldGroupCollection = function () {
    /**
     * @type {FieldGroup[]}
     */
    this.groups = [];
};

FieldGroupCollection.prototype = {

    /**
     * Perform some function on each FieldInstance in the collection.
     *
     * @param {Function} callback
     */
    forEachInstance: function forEachInstance(callback) {
        this.groups.forEach((group) => {
            if (group.instances) {
                group.instances.forEach(callback);
            }
        });
    },

    /**
     * Return the key of every instance in the collection.
     *
     * @return {string[]}
     */
    getInstanceKeys: function getInstanceKeys() {
        let keys = [];
        this.forEachInstance(function (instance) {
            keys.push(instance.getKey());
        });
        return keys;
    },

    /**
     * Returns key/value pairs for every instance in the collection.
     *
     * @return {Object<string|int|int[]>}
     */
    getInstanceValues() {
        let values = {};
        this.forEachInstance(
            /**
             * @param {FieldInstance} instance
             */
            (instance) => {
                values[instance.getKey()] = instance.getValue();
            }
        );
        return values;
    },

    /**
     * Add a FieldGroup to the collection.
     *
     * @param {FieldGroup} fieldGroup
     */
    addFieldGroup: function addFieldGroup(fieldGroup) {
        this.groups.push(fieldGroup);
    },

    /**
     * Add multiple FieldGroups to the collection.
     *
     * @param {FieldGroup[]} groups
     */
    addFieldGroups: function addFieldGroups(groups) {
        groups.forEach((group) => this.addFieldGroup(group));
    },

    /**
     * Reorder the groups so groups that contain required fields come first.
     */
    sortByRequired: function sortByRequired() {
        this.groups.sort(function (a, b) {
            if (a.hasRequiredFields > b.hasRequiredFields) {
                return -1;
            } else if (a.hasRequiredFields < b.hasRequiredFields) {
                return 1;
            }
            return 0;
        });
    },

    /**
     * Return all FieldGroups from the collection that match the criteria.
     *
     * @param {{hideNoValue: boolean, level?: string, itemInstance?: ?number, lineNumber: ?number}} filterOptions
     *
     * @return {FieldGroup[]}
     */
    filterFieldGroups: function filterFieldGroups(filterOptions) {
        let rtn = [];

        /**
         * Is the attribute present in the fieldoptions and is it appropriately defined.
         *
         * @param {string} attribute
         * @return {boolean}
         */
        function isset(attribute) {
            return filterOptions.hasOwnProperty(attribute) && filterOptions[attribute] !== undefined;
        }

        for (let i = 0, j = this.groups.length; i < j; ++i) {
            /**
             * @type {FieldGroup} fieldGroup
             */
            let fieldGroup = this.groups[i];

            if (isset('level') && (filterOptions.level !== fieldGroup.level)) {
                continue;
            }

            if (isset('lineNumber') && (filterOptions.lineNumber !== fieldGroup.lineNumber)) {
                continue;
            }

            if (isset('itemInstance') && (filterOptions.itemInstance !== fieldGroup.itemInstance)) {
                continue;
            }

            if (isset('hideNoValue') && filterOptions.hideNoValue && !fieldGroup.hasInstancesWithValue()) {
                continue;
            }

            if (isset('hideNoValue') && filterOptions.hideNoValue) {
                fieldGroup.instances = fieldGroup.instances.filter(function (fieldInstance) {
                    return fieldInstance.displayValue && fieldInstance.displayValue.length > 0;
                });
                if (fieldGroup.instances.length < 1) {
                    continue;
                }
            }

            rtn.push(fieldGroup);
        }

        return rtn;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldGroupCollection;
}
