const UserFacingError = require('./UserFacingError');

/**
 * Turns an Error object into a string that can be shown to the end user.
 *
 * @param {LangService} LangService
 */
const ErrorPresenter = function (
    LangService
) {
    this.langService = LangService;
};

ErrorPresenter.prototype = {
    /**
     * @param {Error|string} error
     *
     * @return {string}
     */
    getText(error) {
        if (error instanceof UserFacingError) {
            return this.langService.getText(error.getLangKey());
        }

        // Returns something like "Something has gone wrong.";
        let genericPrefix = this.langService.getText('ERROR.GENERIC');

        let message;
        if (error instanceof Error) {
            message = error.message;
        } else {
            // Assume it's a string.
            message = error;
        }

        return genericPrefix + (message ? ' ' + message : '');
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ErrorPresenter;
}
