DigiTickets.PaymentExpressConfig = function () {
    this.currencyCode = null;

    /**
     * @type {string}
     */
    this.environment = null;

    /**
     * HIT POS identifier provided by POS. For example, a POS Lane Identifier etc.
     * Alphanumeric, from 1 to 32 characters.
     *
     * @type {number}
     */
    this.deviceID = null;

    /**
     * @type {string}
     */
    this.environment = null;

    /**
     * @type {string}
     */
    this.HITuser = null;

    /**
     * @type {string}
     */
    this.HITkey = null;

    /**
     * PosName - agreed between POS Vendor and Payment Express. Alphanumeric, from 1 to 32 characters.
     *
     * @type {string}
     */
    this.posName = 'DT-EPOS';

    /**
     * @type {string}
     */
    this.stationID = null;

    /**
     * Version of POS. Supplied by POS to assist transaction recording and diagnosis.
     * Alphanumeric, from 1 to 32 characters.
     *
     * @type {string}
     */
    this.posVersion = null;

    /**
     * The developer of the POS Application. This is agreed between Payment Express and vendor.
     * Alphanumeric from 1 to 32 characters in length.
     *
     * @type {string}
     */
    this.vendorID = 'DigiTickets';
};

DigiTickets.PaymentExpressConfig.prototype = {
    setCurrencyCode: function setCurrencyCode(currencyCode) {
        let allowedCurrencies = ['AUD', 'GBP', 'NZD', 'USD'];

        if (allowedCurrencies.indexOf(currencyCode) === -1) {
            throw new Error('Unsupported Payment Express currency (' + currencyCode + ')');
        }

        this.currencyCode = currencyCode;
    },

    /**
     * @param {bool} isLive
     */
    setEnvironment: function setEnvironment(isLive) {
        this.environment = isLive ? 'sec' : 'uat';
    }
};
