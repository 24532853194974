/**
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {string} action 'add-new' or 'edit-new'
 * @param {DialogService} DialogService
 * @param {GiftVoucher} giftVoucher
 * @param {DigiTickets.GiftVoucherValidator} GiftVoucherValidator
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {number} price
 * @param {DigiTickets.SoldGiftVoucherData} soldGiftVoucherData
 */
const SoldGiftVoucherDataModalCtrl = function SoldGiftVoucherDataModalCtrl(
    $modalInstance,
    $scope,
    $timeout,
    action,
    DialogService,
    giftVoucher,
    GiftVoucherValidator,
    NotificationService,
    price,
    soldGiftVoucherData
) {
    $scope.GiftVoucherDeliveryType = DigiTickets.GiftVoucherDeliveryType;

    /**
     * Should be one of:
     * - 'add-new' When adding a new gift voucher to the cart.
     * - 'edit-new' When the edit icon was clicked in the cart.
     *
     * @type {string}
     */
    $scope.action = action;

    $scope.customBalanceEntered = false;
    $scope.giftVoucher = giftVoucher;
    $scope.price = price;
    $scope.soldGiftVoucherData = soldGiftVoucherData;

    $scope.availableDeliveryTypes = giftVoucher.availableDeliveryTypes;

    /**
     * @param {DigiTickets.GiftVoucherDenomination} denomination
     */
    $scope.selectDenomination = function selectDenomination(denomination) {
        $scope.price = denomination.denomination;
        $scope.customBalanceEntered = false;
    };

    $scope.checkIfCustomBalance = function checkIfCustomBalance() {
        if ($scope.giftVoucher.isBalanceType() && $scope.price) {
            $scope.customBalanceEntered = $scope.giftVoucher.getDenominationsByValue($scope.price).length < 1;
        } else {
            $scope.customBalanceEntered = false;
        }
    };

    $scope.enterCustomBalance = function enterCustomBalance() {
        DialogService.numPad(
            {
                showCashButtons: true,
                showSignButton: false
            },
            'GIFT_VOUCHERS.CUSTOM_BALANCE_DIALOG_MESSAGE',
            function (value) {
                // If value === null it means the modal was cancelled.
                if (value !== null) {
                    $scope.price = value;
                }
                $scope.checkIfCustomBalance();
            },
            {
                min: 0.01,
                title: 'GIFT_VOUCHERS.CUSTOM_BALANCE_DIALOG_TITLE'
            }
        );
    };

    $scope.selectDeliveryType = function selectDeliveryType(deliveryType) {
        $scope.soldGiftVoucherData.deliveryType = deliveryType;
    };

    $scope.ok = function ok() {
        let validationResult = GiftVoucherValidator.validate(
            $scope.giftVoucher,
            $scope.soldGiftVoucherData,
            $scope.price
        );

        if (validationResult !== true) {
            NotificationService.error(validationResult);
            return;
        }

        $modalInstance.close({
            price: $scope.price,
            soldGiftVoucherData: $scope.soldGiftVoucherData
        });
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.checkIfCustomBalance();

    if (action === 'add-new') {
        let autoSelectedBalance = false;
        if ($scope.giftVoucher.isBalanceType()
            && $scope.giftVoucher.denominations.length === 1
            && !$scope.giftVoucher.allowPriceEdit) {
            // If there is only one balance choice for a gift voucher select that by default.
            $scope.selectDenomination($scope.giftVoucher.denominations[0]);
            autoSelectedBalance = true;
        }

        let autoSelectedDeliveryType = false;
        if ($scope.giftVoucher.availableDeliveryTypes.length === 1) {
            // If there is only one delivery type choice for a gift voucher select that by default.
            $scope.selectDeliveryType($scope.giftVoucher.availableDeliveryTypes[0]);
            autoSelectedDeliveryType = true;
        }

        if (
            autoSelectedBalance
            && autoSelectedDeliveryType
            && $scope.soldGiftVoucherData.deliveryType === DigiTickets.GiftVoucherDeliveryType.IN_PERSON
            && $scope.giftVoucher.collectRecipientMessage === 'Not Allowed'
            && $scope.giftVoucher.collectRecipientName
        ) {
            // If all the above applies there's nothing that can be done in this modal so just close.
            // This does cause an annoying flash. Maybe it's better to not do this.
            $timeout(function () {
                $scope.ok();
            }, 1);
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SoldGiftVoucherDataModalCtrl;
}
