const angular = require('angular');
const is = require('../libraries/Is');

/**
 * @param $resource
 * @param {ApiRequestParams} ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {UserService} UserService
 */
const OrderResource = function ($resource, ApiRequestParams, AppConfig, UserService) {
    return $resource(
        AppConfig.apiBaseUrl + 'orders/:id',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                branchID: function branchID() {
                    let currentBranchID = UserService.currentBranch ? UserService.currentBranch.getId() : null;
                    let branches = [];
                    if (currentBranchID) {
                        branches.push(currentBranchID);
                    }
                    branches.push.apply(
                        branches,
                        UserService.branches.map(function (branch) {
                            return branch.getId();
                        }).filter(function (branchID) {
                            return !currentBranchID || branchID != currentBranchID;
                        })
                    );
                    return branches.join();
                }
            }
        ),
        {
            query: {
                isArray: true,
                method: 'GET',
                params: {
                    resolve: '*'
                }
            },
            queryUnfulfilled: {
                isArray: true,
                method: 'GET',
                url: AppConfig.apiBaseUrl + 'orders/unfulfilled',
                params: {
                    resolve: '*'
                }
            },
            get: {
                method: 'GET',
                params: {
                    /**
                     * Resolve everything when loading a single order for consistency
                     * with when batches of ordered are loaded.
                     */
                    resolve: '*'
                },
                transformResponse: function transformResponse(data) {
                    // As we are overriding the default behaviour of the response processing, we have to do
                    // the work of the default processing. This is, in essence, decoding the JSON string.
                    if (is.aString(data)) {
                        // This code is a cut'n'paste from angular.js as I don't see how I can call it directly.
                        let JSON_START = /^\s*(\[|\{[^\{])/;
                        let JSON_END = /[\}\]]\s*$/;
                        let PROTECTION_PREFIX = /^\)\]\}',?\n/;
                        // strip json vulnerability protection prefix
                        data = data.replace(PROTECTION_PREFIX, '');
                        if (JSON_START.test(data) && JSON_END.test(data)) data = angular.fromJson(data);
                    }
                    // As we cannot seem to find the page links, we are making blind requests to the API
                    // for the next page. When we have exhausted the data being sent, the next empty page
                    // returns an empty array. That needs to be mapped to an object for the underlying
                    // logic to work correctly without major reworking.
                    if (is.anArray(data) && data.length === 0) {
                        data = {};
                    }

                    return data;
                }
            },
            redeem: {
                method: 'PUT',
                params: {
                    resolve: 'orderitems.sessions'
                }
            },
            confirm: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            emailReceipt: {
                method: 'POST',
                url: AppConfig.apiBaseUrl + 'orders/:id/sendEmailReceipt'
            },
            getTaxSummary: {
                method: 'GET',
                url: AppConfig.apiBaseUrl + 'orders/:id/tax-summary' // Added in PRO-496
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderResource;
}
