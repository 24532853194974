class SaleValidationError extends Error {
    constructor(message) {
        super(message);
        this.clientMessages = [];

        /**
         * If this error relates to certain cart lines attach them here.
         *
         * @type {DigiTickets.CartItem[]}
         */
        this.cartLines = [];
    }

    setCartLines(lines) {
        this.cartLines = lines;
        return this;
    }

    setClientMessages(arr) {
        this.clientMessages = arr;
        return this;
    }
}

SaleValidationError.CART_EMPTY = 'CART_EMPTY';
SaleValidationError.CUSTOMER_ACCOUNT_REQUIRED = 'CUSTOMER_ACCOUNT_REQUIRED';
SaleValidationError.DELIVERY_ADDRESS_REQUIRED = 'DELIVERY_ADDRESS_REQUIRED';
SaleValidationError.DEVICE_OFFLINE = 'DEVICE_OFFLINE';
SaleValidationError.DUPLICATE_MEMBERSHIP_REFS = 'DUPLICATE_MEMBERSHIP_REFS';
SaleValidationError.FIELD_DATA_INVALID = 'FIELD_DATA_INVALID';
SaleValidationError.ADDITIONAL_CUSTOM_FIELDS_NOT_PRESENTED = 'ADDITIONAL_CUSTOM_FIELDS_NOT_PRESENTED';
SaleValidationError.GIFT_AID_CUSTOMER_INFO_REQUIRED = 'GIFT_AID_CUSTOMER_INFO_REQUIRED';
SaleValidationError.GIFT_AID_UNANSWERED = 'GIFT_AID_UNANSWERED';
SaleValidationError.MISSING_MEMBERSHIP_DATA = 'MISSING_MEMBERSHIP_DATA';
SaleValidationError.STAFF_REF_REQUIRED = 'STAFF_REF_REQUIRED';

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SaleValidationError;
}
