const AdjustFloatCtrl = require('../../../js/controllers/modal/AdjustFloatCtrl');
const PrintJobOptions = require('../../../js/libraries/DigiTickets/Printing/PrintJobOptions');
const PrintType = require('../../../js/libraries/DigiTickets/Printing/PrintType');

/**
 * @param $modal
 * @param $scope
 * @param $timeout
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {CurrentDevice} CurrentDevice
 * @param {CurrencyService} CurrencyService
 * @param {DialogService} DialogService
 * @param {LangService} LangService
 * @param {DigiTickets.Logger} Logger
 * @param {NavigationService} navigationService
 * @param Notification
 * @param {DigiTickets.OnlineQueue} OnlineQueueService
 * @param {OrderQueue} orderQueue
 * @param {Printer} Printer
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param {UserService} UserService
 */
const ReportCtrl = function (
    $modal,
    $scope,
    $timeout,
    AppConfig,
    ConnectivityChecker,
    CurrentDevice,
    CurrencyService,
    DialogService,
    LangService,
    Logger,
    navigationService,
    Notification,
    OnlineQueueService,
    orderQueue,
    Printer,
    PrivilegesService,
    ReceiptPrinterService,
    TradingSessionManager,
    UserService
) {
    navigationService.showNav();

    // Early exit if no access permission.
    PrivilegesService.requirePrivilegeOr403('access_report');

    $scope.date = new Date();
    $scope.connectivityChecker = ConnectivityChecker;

    $scope.hasQueuedOrders = orderQueue.orderList.length > 0;

    $scope.userService = UserService;

    $scope.uiState = {
        loadingMessage: 'REPORTS.LOADING',
        isPrinting: false,
        reportPrintedAt: null,
        title: ''
    };

    $scope.waitingForQueue = false;
    $scope.showLoader = false;
    $scope.currentTradingSession = null;
    $scope.summary = null;

    /**
     * Flag for granular control of button visibility to make the UI feel a bit more responsive.
     *
     * @type {boolean}
     */
    $scope.sessionClosing = false;

    // Load the current trading session from the API.
    $scope.loadSummary = function loadSummary() {
        // Wait for the online queue to finish first as it may be waiting to send some trading session events.
        $scope.showLoader = true;
        TradingSessionManager.getCurrentSession(
            CurrentDevice.device,
            function (tradingSession) {
                if (tradingSession) {
                    $scope.loadTradingSessionSummary(tradingSession);
                } else {
                    Notification.error(
                        LangService.getText('REPORTS.FAILED_LOADING')
                    );
                    $scope.showLoader = false;
                }
            }
        );
    };

    $scope.loadTradingSessionSummary = function loadTradingSessionSummary(tradingSession, callback) {
        $scope.currentTradingSession = tradingSession;
        $scope.showLoader = true;

        // Got the latest trading session. Now get the summary for it.
        TradingSessionManager.getSummary(
            tradingSession,
            function (summary) {
                if (summary) {
                    $scope.summary = summary;
                    if (typeof callback === 'function') {
                        callback(summary);
                    }
                } else {
                    $scope.summary = null;
                    Notification.error(
                        LangService.getText('REPORTS.FAILED_LOADING')
                    );
                }
                $scope.showLoader = false;
            }
        );
    };

    $scope.loadSummaryIfReady = function () {
        if ($scope.summary || $scope.showLoader) {
            // Already loaded or loading
            return;
        }

        if (!ConnectivityChecker.isOnline()) {
            return;
        }

        if (!OnlineQueueService.isFinished) {
            $scope.waitingForQueue = true;
            return;
        }

        $scope.loadSummary();
    };

    ConnectivityChecker.addObserver(
        {
            key: 'reports',
            notifyOnline: function notifyOnline() {
                $scope.loadSummaryIfReady();
            },
            notifyOffline: function notifyOffline() {

            }
        },
        true
    );

    OnlineQueueService.onFinish(function () {
        $scope.waitingForQueue = false;
        $scope.loadSummaryIfReady();
    });

    $scope.loadSummaryIfReady();

    /**
     * Print the report.
     *
     * @param {string} title
     * @param {function} [completion]
     */
    $scope.printReport = function printReport(title, completion) {
        $scope.uiState.printTitle = title;
        $scope.uiState.reportPrintedAt = new Date();
        $scope.uiState.isPrinting = true;

        let opts = new PrintJobOptions(
            PrintType.REPORT,
            () => {
                $scope.uiState.isPrinting = false;
                if (completion) {
                    completion();
                }
            }
        );

        $timeout(function () {
            Printer.printHtmlString(
                document.getElementById('report').outerHTML,
                opts
            );
        });
    };

    /**
     * Determines if the user can view the report.
     *
     * @returns {bool}
     */
    $scope.userCanViewReport = false;
    PrivilegesService.checkCurrentUserPrivilege('report').then(
        function (result) {
            $scope.userCanViewReport = result.granted;
        },
        function (result) {
            $scope.userCanViewReport = result.granted;
        }
    );

    /**
     * Method to print a trading session snapshot
     */
    $scope.xRead = function xRead() {
        Logger.info('Requested X-Read');
        $scope.printReport('Trading Session Snapshot');
    };

    /**
     * Method called when Z-Read button clicked. Loop through and capture any float adjustments that are required
     * when the session is closed.
     */
    $scope.zRead = function zRead() {
        $scope.sessionClosing = true;

        let requiredFloatAdjustmentReasons = getRequiredFloatAdjustmentReasons();

        if (requiredFloatAdjustmentReasons.length > 0) {
            (async () => {
                /* @type {FloatAdjustment[]} */
                let floatAdjustments = [];

                for (let requiredFloatAdjustmentReason of requiredFloatAdjustmentReasons) {
                    try {
                        // We are purposely awaiting in the loop because we want to ask the user questions sequentially.
                        // eslint-disable-next-line no-await-in-loop
                        let floatAdjustment = await displayAdjustFloatModal(requiredFloatAdjustmentReason);
                        floatAdjustments.push(floatAdjustment);
                    } catch (e) {
                        floatAdjustments = [];
                        $scope.sessionClosing = false;
                        break;
                    }
                }

                if (floatAdjustments.length > 0) {
                    confirmCloseSession(floatAdjustments);
                }
            })();
        } else {
            Logger.info('Requested Z-Read (no prompt)');
            confirmCloseSession();
        }
    };

    /**
     * Return a promise containing the result of each float adjustment modal in turn.
     *
     * @param {FloatAdjustmentReason} floatAdjustmentReason
     *
     * @returns {Promise<FloatAdjustment>}
     */
    const displayAdjustFloatModal = function (floatAdjustmentReason) {
        return new Promise((resolve, reject) => {
            let modalText = {
                adjustmentAmountLabel: LangService.getText('ADJUST_FLOAT.AMOUNT_REMOVED'),
                title: LangService.getText('REPORTS.Z_READ_PROMPT_FLOAT_ADJUSTMENT_TITLE')
            };

            let modal = $modal.open({
                controller: AdjustFloatCtrl,
                templateUrl: 'partials/modals/adjustFloat.html',
                resolve: {
                    floatAdjustmentReasons: function () {
                        return [floatAdjustmentReason];
                    },
                    templateText: function () {
                        return modalText;
                    }
                },
                backdrop: 'static',
                keyboard: false,
                windowClass: 'in adjust-float-window'
            });

            modal.result.then(
                (data) => resolve(data.floatAdjustment),
                () => reject()
            );

            return modal;
        });
    };

    /**
     * Returns an array of the FloatAdjustmentReasons that are required to be prompted for when the session is closed.
     *
     * @return {FloatAdjustmentReason[]}
     */
    const getRequiredFloatAdjustmentReasons = function () {
        return UserService.company.floatAdjustmentReasons.filter((reason) => reason.requiredOnSessionClose);
    };

    /**
     * TODO: Combine this with the saveFloatAdjustmentTradingSessionEvent method in NavigationCtrl.
     *
     * Save the float adjustment via the Trading Session manager.
     *
     * @param {FloatAdjustment} floatAdjustment
     */
    const persistFloatAdjustment = function (floatAdjustment) {
        let auditAmount = 0 - floatAdjustment.amount;
        let tradingSessionEvent = new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_OUT);

        // Setting the narrative means that if a client chooses to use a float adjustment
        // that requires a narrative it won't fail. If necessary we can always use an alternate modal or
        // modify the existing modal to add a narrative field.
        TradingSessionManager.saveEvent(
            tradingSessionEvent
                .setOperatorID(UserService.getUserID())
                .setAmount(auditAmount)
                .setFloatAdjustmentReasonID(floatAdjustment.floatAdjustmentReason.ID)
                .setNarrative(floatAdjustment.narrative)
        );
    };

    /**
     * Trigger the close session confirmation modal with appropriate message. If confirmation is given then send any
     * float adjustments that have just been made.
     *
     * @param {FloatAdjustment[]} [floatAdjustments]
     */
    const confirmCloseSession = function (floatAdjustments) {
        let message = LangService.getText('REPORTS.Z_READ_CONFIRM_MESSAGE');

        if (floatAdjustments) {
            message += '<br/>' + LangService.getText('REPORTS.Z_READ_CONFIRM_FLOAT_ADJUSTMENTS');
            for (let floatAdjustment of floatAdjustments) {
                message += '<br />' + floatAdjustment.floatAdjustmentReason.reason + ': ' + CurrencyService.getSymbol() + floatAdjustment.amount;
            }
        }

        DialogService.confirm(
            message,
            function (confirmed) {
                if (confirmed !== true) {
                    $scope.sessionClosing = false;
                    return;
                }

                if (floatAdjustments !== undefined) {
                    for (const floatAdjustment of floatAdjustments) {
                        persistFloatAdjustment(floatAdjustment);
                    }
                }

                // Give an indication that things are happening.
                $scope.showLoader = true;

                // This is because we can't detect when something in the queue is complete. Otherwise I would use
                // a promise. The timeout is a nasty hack in case we have multiple float adjustments.
                setTimeout(function () {
                    closeSession();
                }, 1000);
            },
            {
                rawMessage: true
            }
        );
    };

    const closeSession = function closeSession() {
        // Confirmed - close the session.
        TradingSessionManager.closeSession(
            $scope.currentTradingSession,
            function (tradingSession) {
                if (tradingSession === false) {
                    // Failed to close the session.
                    Notification.error(
                        LangService.getText('REPORTS.FAILED_CLOSING')
                    );
                    return;
                }

                // Reload the summary so values are zeroed.
                $scope.loadTradingSessionSummary(
                    tradingSession,
                    function () {
                        // Update the displayed trading session as it will now contain the ended at time.
                        $scope.currentTradingSession = tradingSession;

                        // Print the Z-Read.
                        $scope.uiState.isPrinting = true;
                        $scope.printReport('Trading Session Summary', function () {
                            $scope.uiState.printTitle = '';
                            $scope.uiState.isPrinting = false;
                            $scope.sessionClosing = false;

                            // Start the new session.
                            TradingSessionManager.openSession(
                                CurrentDevice.device,
                                function (newTradingSession) {
                                    // Set the current device's trading session.
                                    CurrentDevice.setTradingSession(newTradingSession);

                                    // Add the default float.
                                    TradingSessionManager.saveEvent(
                                        (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_FLOAT))
                                            .setOperatorID(UserService.getUserID())
                                            .setAmount(newTradingSession.device.defaultFloat)
                                    );

                                    $scope.currentTradingSession = newTradingSession;

                                    // Reload the summary so values are zeroed.
                                    $scope.loadTradingSessionSummary(newTradingSession);
                                }
                            );
                        });
                    }
                );
            }
        );
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ReportCtrl;
}
