const { toFloat, toInt } = require('../functions/transform');

DigiTickets.GiftVoucherDenomination = function () {
    this.denomination = null;
    this.ID = null;
};

DigiTickets.GiftVoucherDenomination.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'giftVoucherDenominationID'],
                transform: toInt
            },
            denomination: toFloat
        };
    }
};
