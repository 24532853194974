const { toBool, toInt, toNullableInt, toString, toNullableString } = require('../functions/transform');

DigiTickets.Branch = function () {
    /**
     * @type {DigiTickets.Country|null}
     */
    this.country = null;
    this.customerBackground = null;
    this.customerWaitingBackground = null;
    /**
     * @type {DigiTickets.EposPromoImage[]}
     */
    this.promoImages = null;
    this.ID = null;
    this.kitchenAmberMinutes = null;
    this.kitchenAutoPrint = false;
    this.kitchenEnabled = false;
    this.kitchenOrderSoundUrl = null;
    this.kitchenReadySoundUrl = null;
    this.kitchenRedMinutes = null;
    this.kitchenRefRequired = false;
    this.kitchenTitle = null;
    this.language = null;
    this.languageOverrides = null;
    this.logoUrl = null;
    this.name = '';
    this.themeID = null;
    this.printableLogoUrl = null;

    /**
     * @type {?string}
     */
    this.encodedPrintableLogo = null;

    /**
     * Print the cart/order reference on the customer's receipt?
     *
     * @type {boolean}
     */
    this.printStaffRef = false;

    this.pricingRulesActive = false;
};

DigiTickets.Branch.prototype = {
    getHydrationMap() {
        return {
            customerBackground: {
                field: ['eposCustomerBackground', 'customerBackground']
            },
            customerWaitingBackground: {
                field: ['eposCustomerWaitingBackground', 'customerWaitingBackground']
            },
            encodedPrintableLogo: toNullableString,
            promoImages: {
                field: ['epospromoimages', 'promoImages'],
                modelCollection: DigiTickets.EposPromoImage
            },
            ID: {
                field: ['branchID', 'ID'],
                transform: toInt
            },
            kitchenAmberMinutes: toInt,
            kitchenAutoPrint: toBool,
            kitchenEnabled: toBool,
            kitchenOrderSoundUrl: toNullableString,
            kitchenReadySoundUrl: toNullableString,
            kitchenRedMinutes: toInt,
            kitchenRefRequired: toBool,
            kitchenTitle: toString,
            language: {
                field: function (data) {
                    if (data.hasOwnProperty('language')) {
                        // Already hydrated
                        return data.language;
                    }
                    if (data.hasOwnProperty('languages')) {
                        return data.languages.code;
                    }
                    return null;
                }
            },
            languageOverrides: {},
            logoUrl: {},
            name: {},
            pricingRulesActive: toBool,
            printableLogoUrl: {},
            printStaffRef: toBool,
            themeID: toNullableInt
        };
    },

    /**
     * @param {CountryManager} CountryManager
     * @param {object} data
     */
    afterHydration: function afterHydration(CountryManager, data) {
        if (CountryManager) {
            this.country = CountryManager.findByData(data);
        }
    },

    getId: function getId() {
        return this.ID;
    }
};
