//
// Focus and select any text already in an input field, when it loads. Prevents loss of
// focus. Only used for inputs that exist nearly permanently and need to receive input
// of barcode scans, etc.
//
// @param $timeout
// @param $modalStack
//
const focusAlwaysDirective = function ($timeout, $modalStack) {
    return {
        scope: { trigger: '@dtFocusAlways' },
        link: function (scope, element) {
            function handleBlur() {
                $timeout(function () {
                    // only try to re-focus if a modal window isn't open
                    let modalWindow = $modalStack.getTop();
                    if (!modalWindow) {
                        element[0].focus();
                    } else {
                        modalWindow.key.result.finally(function () {
                            handleBlur();
                        });
                    }
                });
            }

            element.on('blur', handleBlur);

            element.on('focus', function () {
                $timeout(function () {
                    element[0].select();
                    element[0].setSelectionRange(0, element[0].value.length); // mobile safari
                });
            });

            element[0].focus();
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = focusAlwaysDirective;
}
