const { cloneDeep } = require('../../../functions/clone');

/**
 * Call this function when a change is made to an order to produce a new set of lines including those that are
 * historic and no longer apply. In doing so, you can see a complete history of changes to the order. Each line
 * represents a current or previous state. So where a line has its quantity changed, for instance, you will see a line
 * for both the original quantity, as well as the current quantity.
 *
 * It's used mainly in the context of food orders, where the kitchen may want to know what was originally ordered,
 * and what's changed. It also gives the UI opportunity to highlight any items that have been added to the original
 * order which might otherwise get missed.
 *
 * @param {KitchenOrderItem[]} oldLines The previous state of the order. May well be the return value from a previous call to this function.
 * @param {KitchenOrderItem[]} newLines The new state of the order. IMPORTANT - don't include any historic lines.
 *
 * @return {KitchenOrderItem[]}
 */
const linesWithHistory = (oldLines, newLines) => {
    const adjustedLines = [];

    // Removed lines
    oldLines.forEach((oldLine) => {
        const newLine = newLines.find((newLine) => oldLine.hash === newLine.hash);
        if (!newLine) {
            const copy = cloneDeep(oldLine);
            copy.versionIndex++;
            adjustedLines.push(copy);
        }
    });

    // Current lines
    newLines.forEach((newLine) => {
        const matchingLines = oldLines.filter((oldLine) => oldLine.hash === newLine.hash);
        const previousLines = matchingLines.filter((oldLine) => oldLine.isHistoric);
        const replacedLines = matchingLines.filter((oldLine) => !oldLine.isHistoric && oldLine.qty !== newLine.qty);
        if (replacedLines.length) {
            // A changed line
            const copy = cloneDeep(newLine);
            copy.isNew = true;
            adjustedLines.push(copy);

            // Increment version of replacedLine
            replacedLines.forEach((line) => {
                const copy = cloneDeep(line);
                copy.versionIndex++;
                adjustedLines.push(copy);
            });

            // Increment previous version indexes
            previousLines.forEach((line) => {
                const copy = cloneDeep(line);
                copy.versionIndex++;
                adjustedLines.push(copy);
            });
        } else if (previousLines.length) {
            // We're re-adding a previously deleted item
            const copy = cloneDeep(newLine);
            copy.isNew = true;
            adjustedLines.push(copy);

            previousLines.forEach((line) => {
                adjustedLines.push(cloneDeep(line));
            });
        } else if (matchingLines.length) {
            // Line hasn't changed on this occasion; Preserve the existing versions.
            matchingLines.forEach((line) => {
                adjustedLines.push(line);
            });
        } else {
            // A brand new line
            const copy = cloneDeep(newLine);
            copy.isNew = (oldLines.length > 0);
            adjustedLines.push(copy);
        }
    });

    return adjustedLines;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        linesWithHistory
    };
}
