const FieldType = Object.freeze({
    CHECKBOX: 'checkbox',
    DOB: 'dateofbirth',
    RADIO: 'radiobutton',
    SELECT: 'select',
    TEXTAREA: 'textarea',
    TEXTBOX: 'textbox'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldType;
}
