/**
 * Remove entries from the given object that are just empty strings, null, or empty arrays.
 * Modifies the original object.
 *
 * @param {*} obj
 *
 * @return {*} Returns the object for easier use.
 */
const removeEmptyValues = (obj) => {
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        // This is not as simple as !value because zero could be a valid value.
        if (
            value === null
            || value === undefined
            || value === ''
            || (value instanceof Array && value.length < 1)
        ) {
            delete obj[key];
        }
    });

    return obj;
};

// Set the properties given in data on the entity if those keys exist on the entity.
// This is designed to make testing easier so we can pass data to the constructor of a model rather than manually
// setting lots of properties.
//
// @param {{}} entity
// @param {{}} data
const populate = (entity, data) => {
    if (!data) {
        return;
    }

    Object.keys(data).forEach((key) => {
        if (entity.hasOwnProperty(key)) {
            entity[key] = data[key];
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        populate,
        removeEmptyValues
    };
}
