/**
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param message
 * @param Notification
 * @param numPadOptions
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 * @param options
 */
const NumPadModalCtrl = function NumPadModalCtrl(
    $filter,
    $modalInstance,
    $scope,
    message,
    Notification,
    numPadOptions,
    NumPadFactory,
    options
) {
    if (!options) {
        options = {};
    }
    $scope.options = options;

    if (!numPadOptions || typeof numPadOptions.cancel !== 'function') {
        numPadOptions.cancel = function () {
            $scope.cancel();
        };
    }

    if (!numPadOptions || typeof numPadOptions.ok !== 'function') {
        numPadOptions.ok = function () {
            $scope.ok();
        };
    }

    $scope.numPad = NumPadFactory.create(numPadOptions);

    $scope.cancelLabel = options.hasOwnProperty('cancelLabel') ? options.cancelLabel : 'CANCEL';
    $scope.cancelLabelParams = options.hasOwnProperty('cancelLabelParams') ? options.cancelLabelParams : {};

    $scope.okLabel = options.hasOwnProperty('okLabel') ? options.okLabel : 'OK';
    $scope.okLabelParams = options.hasOwnProperty('okLabelParams') ? options.okLabelParams : {};

    $scope.title = options.hasOwnProperty('title') ? options.title : 'DIALOGS.PROMPT_TITLE';
    $scope.titleParams = options.hasOwnProperty('titleParams') ? options.titleParams : {};

    if ($scope.options.rawMessage) {
        /**
         * Display a language file string as the message.
         */
        $scope.rawMessage = message;
    } else {
        /**
         * Display some raw HTML as the message.
         */
        $scope.message = message;
        $scope.messageParams = options.hasOwnProperty('messageParams') ? options.messageParams : {};
    }

    $scope.displayAsCurrency = !!options.displayAsCurrency;
    $scope.max = options.hasOwnProperty('max') ? options.max : null;
    $scope.min = options.hasOwnProperty('min') ? options.min : null;

    $scope.ok = function ok() {
        let value = $scope.numPad.getValue();

        if ($scope.min !== null && value < $scope.min) {
            let displayMin = $scope.displayAsCurrency ? $filter('currencySymbol')($scope.min) : $scope.min;
            Notification.error(
                $filter('lang')('DIALOGS.NUM_PAD_TOO_SMALL', { min: displayMin })
            );
            return;
        }

        if ($scope.max !== null && value > $scope.max) {
            let displayMax = $scope.displayAsCurrency ? $filter('currencySymbol')($scope.max) : $scope.max;
            Notification.error(
                $filter('lang')('DIALOGS.NUM_PAD_TOO_LARGE', { max: displayMax })
            );
            return;
        }

        $modalInstance.close({
            value
        });
    };

    // Go to report page
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = NumPadModalCtrl;
}
