const { toBool } = require('../../../../functions/transform');

/**
 * @param {DigiTickets.PaymentExpressConfig} config
 * @param {number} amount
 * @param {string} txnRef
 */
DigiTickets.PaymentExpressTransaction = function (config, amount, txnRef) {
    /**
     * @type {number}
     */
    this.amount = 0.00;

    /**
     * @type {null}
     */
    this.errorMessage = null;

    /**
     * @type {DigiTickets.PaymentExpressConfig}
     */
    this.config = config;

    /**
     * Has the transaction finished?
     * Note this does not mean finished successfully (paid), just that it is no longer in progress.
     *
     * @type {boolean}
     */
    this.isComplete = false;

    /**
     * Has this transaction been sent to Payment Express?
     * Set this to true manually is recovering a transaction.
     *
     * @type {boolean}
     */
    this.isStarted = false;

    /**
     * @type {boolean|null}
     */
    this.isSuccess = null;

    /**
     * @type {string}
     */
    this.processingMessage = '';

    this.terminalScreen = {};

    /**
     * @type {string}
     */
    this.status = '';

    /**
     * @type {string}
     */
    this.txnRef = txnRef;

    /**
     * Transaction Type. Valid values: Purchase, Auth, Refund or Status.
     * (We only put 'Purchase' or 'Refund' here).
     *
     * @type {string}
     */
    this.txnType = null;

    this.setAmount(amount);
    this.updateScreen();
};

DigiTickets.PaymentExpressTransaction.prototype = {
    isInProgress: function isInProgress() {
        return !this.isComplete;
    },

    /**
     * @param {number} amount
     */
    setAmount: function setAmount(amount) {
        this.amount = parseFloat(amount);
        this.txnType = this.amount >= 0.00 ? 'Purchase' : 'Refund';
    },

    /**
     * @param {DigiTickets.PaymentExpressConfig} config
     */
    setConfig: function setConfig(config) {
        this.config = config;
    },

    /**
     * @param {string} ref
     */
    setTransactionRef: function setTransactionRef(ref) {
        this.txnRef = ref;
    },

    /**
     * @param buttonNumber
     * @param buttonLabel
     *
     * @return {string}
     */
    getButtonPressRequest: function getButtonPressRequest(buttonNumber, buttonLabel) {
        return this.wrapRequestString(
            '<Name>B' + buttonNumber + '</Name>'
            + '<Station>' + this.config.stationID + '</Station>'
            + '<TxnRef>' + this.txnRef + '</TxnRef>'
            + '<TxnType>UI</TxnType>'
            + '<UiType>Bn</UiType>'
            + '<Val>' + buttonLabel + '</Val>'
        );
    },

    /**
     * @return {string}
     */
    getInitiateRequest: function getInitiateRequest() {
        return this.wrapRequestString(
            '<Amount>' + Math.abs(this.amount).toFixed(2) + '</Amount>'
            + '<Cur>' + this.config.currencyCode + '</Cur>'
            + '<DeviceId>' + this.config.deviceID + '</DeviceId>'
            + '<MRef>' + this.txnRef + '</MRef>'
            + '<PosName>' + this.config.posName + '</PosName>'
            + '<PosVersion>' + this.config.posVersion + '</PosVersion>'
            + '<Station>' + this.config.stationID + '</Station>'
            + '<TxnRef>' + this.txnRef + '</TxnRef>'
            + '<TxnType>' + this.txnType + '</TxnType>'
            + '<VendorId>' + this.config.vendorID + '</VendorId>'
        );
    },

    /**
     * @return {string}
     */
    getStatusRequest: function getStatusRequest() {
        return this.wrapRequestString(
            '<Station>' + this.config.stationID + '</Station>'
            + '<TxnRef>' + this.txnRef + '</TxnRef>'
            + '<TxnType>Status</TxnType>'
        );
    },

    /**
     * @param {string} str
     *
     * @return {string}
     */
    wrapRequestString: function wrapRequestString(str) {
        return '<Scr action="doScrHIT" user="' + this.config.HITuser + '" key="' + this.config.HITkey + '">'
            + str
            + '</Scr>';
    },

    updateScreen: function updateScreen(newState) {
        if (newState === undefined) {
            return;
        }

        // Reset everything, and then set anything that's been given to us.
        this.terminalScreen = {
            message1: '',
            message2: '',
            button1: {
                enabled: false,
                label: ''
            },
            button2: {
                enabled: false,
                label: ''
            }
        };

        if (newState.messageLine1) {
            this.terminalScreen.message1 = newState.messageLine1;
        }
        if (newState.messageLine2) {
            this.terminalScreen.message2 = newState.messageLine2;
        }

        if (newState.button1) {
            this.terminalScreen.button1.enabled = toBool(newState.button1.enabled);
            if (newState.button1.label) {
                this.terminalScreen.button1.label = newState.button1.label;
            }
        }
        if (newState.button2) {
            this.terminalScreen.button2.enabled = toBool(newState.button2.enabled);
            if (newState.button2.label) {
                this.terminalScreen.button2.label = newState.button2.label;
            }
        }
    }
};
