const { toDate, toInt } = require('../functions/transform');

DigiTickets.Redemption = function () {
    this.contact = null;
    this.ID = null;
    this.method = null;
    this.qty = 0;
    this.triggeredAt = null;
    this.type = null;
};

DigiTickets.Redemption.prototype = {
    getHydrationMap() {
        return {
            contact: {
                model: DigiTickets.Contact
            },
            ID: {
                field: ['ID', 'redemptionID'],
                transform: toInt
            },
            method: {},
            qty: toInt,
            triggeredAt: toDate
        };
    },

    afterHydration: function afterHydration() {
        this.type = this.getType();
    },

    getType: function getType() {
        if (this.qty > 0) {
            return 'REDEMPTION';
        } else if (this.qty < 0) {
            return 'UNREDEMPTION';
        }
    },

    getMemberName: function getMemberName() {
        return this.contact ? this.contact.getFullName() : '(Unknown)';
    }
};
