/**
 * This is the controller for the inner part of the payment modal for the payment method of "Payment Express".
 *
 * @param $filter
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {DigiTickets.OrderInProgressStasher} OrderInProgressStasher
 * @param {DigiTickets.PaymentExpressDriver} PaymentExpressDriver
 * @param {PaymentMethodService} paymentMethodService
 * @param {PaymentState} paymentState
 * @param {UserService} UserService
 */
const PaymentExpressCtrl = function PaymentExpressCtrl(
    $filter,
    $scope,
    $timeout,
    cartService,
    OrderInProgressStasher,
    PaymentExpressDriver,
    paymentMethodService,
    paymentState,
    UserService
) {
    /**
     * Separate logging function to keep code cleaner from a load of console.log().
     *
     * @param a
     * @param b
     */
    let log = function (a, b) {
        if (b !== undefined) {
            console.log('PaymentExpressCtrl', a, b);
        } else {
            console.log('PaymentExpressCtrl', a);
        }
    };

    $scope.cart = cartService;
    $scope.paymentExpressDriver = PaymentExpressDriver;
    $scope.paymentState = paymentState;
    $scope.userService = UserService;

    $scope.thisPaymentMethodRef = PaymentExpressDriver.paymentMethodRef;
    $scope.registerSuppressPaymentReceivedButton($scope.thisPaymentMethodRef);
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    /**
     * @type {PaymentMethod}
     */
    $scope.paymentExpressPaymentMethod = paymentMethodService.getByRef($scope.thisPaymentMethodRef);

    /**
     * Has the Payment Express driver been set up and is ready to use?
     *
     * @type {bool|null}
     */
    $scope.ready = null;

    /**
     * @type {DigiTickets.PaymentExpressTransaction|null}
     */
    $scope.currentTransaction = null;

    /**
     * @type {DigiTickets.PaymentExpressTransaction[]}
     */
    $scope.allTransactions = [];

    /**
     * A big error to display at the top of the page to show something is seriously wrong with the PE setup.
     *
     * @type {?{title:string, message:string}}
     */
    $scope.largeErrorMessage = null;

    /**
     * @return {boolean}
     */
    $scope.isTransactionInProgress = function isTransactionInProgress() {
        return !!(this.currentTransaction && !this.currentTransaction.isComplete);
    };

    $scope.tryAgain = function tryAgain() {
        $scope.clearError();
        $scope.initiateTransaction($scope.cart.getRemainingBalance());
    };

    $scope.makePartialPayment = function makePartialPayment() {
        $scope.showMakePartialModal(function (amount) {
            $scope.preventAddFullPaymentOnClose();
            $scope.initiateTransaction(amount);
        });
    };

    $scope.initiateTransaction = function initiateTransaction(amount) {
        log('initiateTransaction...', amount);

        if (!$scope.ready) {
            throw new Error('Can\'t start PE transaction because config is not valid.');
        }

        if ($scope.isTransactionInProgress()) {
            throw new Error('Transaction already in progress.');
        }

        // Create a transaction.
        let transaction = PaymentExpressDriver.createTransaction(amount, $scope.cart.generatePaymentThirdPartyID());

        $scope.sendTransaction(transaction);
    };

    /**
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     */
    $scope.sendTransaction = function sendTransaction(transaction) {
        // Set the current transaction.
        $scope.currentTransaction = transaction;

        // Add it to the list of all transactions.
        $scope.allTransactions.unshift(transaction);

        // Prevent closing the payment modal.
        paymentState.setCancelable(false, $scope.thisPaymentMethodRef);

        // Remember this transaction is in progress.
        OrderInProgressStasher.setTransactionInProgress(
            new DigiTickets.TransactionInProgress(
                $scope.thisPaymentMethodRef,
                transaction.txnRef,
                transaction.amount
            )
        );

        // Start the transaction.
        PaymentExpressDriver
            .sendTransaction(transaction)
            .then(
                /**
                 * Promise resolved.
                 *
                 * @param {{errorMessage: string, response:DigiTickets.PaymentExpressResponse}} result
                 */
                function (result) {
                    log('Transaction Resolved', result);
                    $scope.handleApprovedResponse(transaction, result.response, result.debugInfo);

                    $scope.setError(result.errorMessage);
                    paymentState.clearCancelable();
                    OrderInProgressStasher.clearTransactionInProgress();
                },
                /**
                 * Promise rejected.
                 *
                 * @param {{errorMessage:string, response:DigiTickets.PaymentExpressResponse}} result
                 */
                function (result) {
                    log('Transaction Rejected', result);

                    $scope.setError(result.errorMessage);
                    paymentState.clearCancelable();
                    OrderInProgressStasher.clearTransactionInProgress();
                },
                /**
                 * Promise notified.
                 *
                 * @param {{errorMessage:string, response:DigiTickets.PaymentExpressResponse}} result
                 */
                function (result) {
                    log('Transaction Notified', result);

                    $scope.setError(result.errorMessage);
                }
            );
    };

    /**
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     * @param {DigiTickets.PaymentExpressResponse} response
     * @param {object} [debugInfo]
     */
    $scope.handleApprovedResponse = function handleApprovedResponse(transaction, response, debugInfo) {
        // Funds received. Add Payment to cart/order.
        let payment = new DigiTickets.Payment();
        payment.setTendered(transaction.amount);
        payment.setPaymentMethod($scope.paymentExpressPaymentMethod);

        payment.setStatusDetail(response.getRawResponse());
        payment.debugInfo = debugInfo;

        payment.setThirdPartyID(response.getTxnRef());
        payment.setTransactionRef(response.getTxnRef());

        let cardNumber = response.getContent('CN');
        payment.setLastDigits(cardNumber ? cardNumber.substr(-4) : null);

        payment.setAuthCode(response.getContent('AC'));
        payment.setCardTypeRef(response.getCardTypeRef());

        $scope.addPayment(payment);
        $scope.preventAddFullPaymentOnClose();

        // Accept the modal and complete the order.
        // Timeout so user can see the status of the payment.
        $timeout(function () {
            $scope.ok();
        }, 1000);
    };

    $scope.buttonPress = function buttonPress(transaction, whichButton) {
        let buttonLabel = transaction.terminalScreen['button' + whichButton].label;

        // We ignore the response from buttonPress as we will find out what happened from the
        // status polling. Making changes here too can cause conflicts.
        PaymentExpressDriver.buttonPress(transaction, whichButton, buttonLabel);
    };

    /**
     * @param {string} error
     */
    $scope.handleInvalidConfig = function handleInvalidConfig(error) {
        $scope.ready = false;
        $scope.largeErrorMessage = {
            title: 'The Payment Express configuration is invalid. Please contact support.',
            message: error
        };
    };

    $scope.allowPartialPayments = function allowPartialPayments() {
        return $scope.$parent.allowPartialPayments();
    };

    /**
     * Initialization...
     */
    log('PE Controller Opened');

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        $scope.largeErrorMessage = null;

        if (newPaymentMethodRef === $scope.thisPaymentMethodRef) {
            log('Switched to Payment Express');

            try {
                $scope.paymentExpressDriver.init();
                $scope.ready = true;
            } catch (e) {
                $scope.handleInvalidConfig(e.toString());
            }

            // Check if there are abandoned transactions we need to resume.
            // This happens (for example) if the browser was refreshed while at the payment stage after the
            // transaction was sent to Payment Express
            if ($scope.cart.transactionInProgress && $scope.cart.transactionInProgress.paymentMethodRef === $scope.thisPaymentMethodRef) {
                log('There are transactions to recover');

                let transaction = PaymentExpressDriver.createTransaction(
                    $scope.cart.transactionInProgress.amount,
                    $scope.cart.transactionInProgress.txnRef
                );
                transaction.isStarted = true;
                transaction.processingMessage = 'Recovering transaction...';

                $scope.sendTransaction(transaction);
                $scope.preventAddFullPaymentOnClose();
            } else {
                // Tell the driver to initiate the transaction.
                log('Switched to PE method and no tx in progress - starting one.');
                $scope.initiateTransaction($scope.cart.getRemainingBalance());
            }
        } else {
            log('Switched away from Payment Express');
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentExpressCtrl;
}
