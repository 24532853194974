/**
 * Focus an input field, when it loads.
 *
 * @param $timeout
 */
const autoFocusDirective = function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            // There might be a field that is only displayed in certain circumstances, but when shown, it's
            // the first field on the screen. Therefore, we need to be able to suppress the auto-focus on
            // the field that's always shown, for the times where it's not the first field on the screen.
            if (attrs.hasOwnProperty('dtAutoFocus')) {
                if (attrs.dtAutoFocus === 'suppress') {
                    return;
                }
            }
            $timeout(
                function () {
                    element[0].focus();
                },
                250
            );
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = autoFocusDirective;
}
