const TemplateResource = function ($resource, ApiRequestParams, AppConfig, TemplateCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'templates/:templateName/:printMethod',
        ApiRequestParams.defaults(),
        {
            query: {
                cache: TemplateCache,
                isArray: false,
                method: 'GET'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TemplateResource;
}
