(function () {
    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    if (!Date.prototype.getHoursWithZeros) {
        Date.prototype.getHoursWithZeros = function getHoursWithZeros() {
            return pad(this.getHours());
        };
    }

    if (!Date.prototype.getMinutesWithZeros) {
        Date.prototype.getMinutesWithZeros = function getMinutesWithZeros() {
            return pad(this.getMinutes());
        };
    }

    if (!Date.prototype.getSecondsWithZeros) {
        Date.prototype.getSecondsWithZeros = function getSecondsWithZeros() {
            return pad(this.getSeconds());
        };
    }

    if (!Date.prototype.getMonthWithZeros) {
        Date.prototype.getMonthWithZeros = function getMonthWithZeros() {
            return pad(this.getMonth() + 1);
        };
    }

    if (!Date.prototype.getDateWithZeros) {
        Date.prototype.getDateWithZeros = function getDateWithZeros() {
            return pad(this.getDate());
        };
    }

    if (!Date.prototype.toYM) {
        Date.prototype.toYM = function toYM() {
            return String(this.getFullYear()) + '-' + this.getMonthWithZeros();
        };
    }

    if (!Date.prototype.toYMD) {
        Date.prototype.toYMD = function toYMD() {
            return String(this.getFullYear()) + '-' + this.getMonthWithZeros() + '-' + this.getDateWithZeros();
        };
    }

    if (!Date.prototype.toHMS) {
        Date.prototype.toHMS = function toHMS() {
            return this.getHoursWithZeros() + ':' + this.getMinutesWithZeros() + ':' + this.getSecondsWithZeros();
        };
    }

    if (!Date.prototype.toYMDHMS) {
        Date.prototype.toYMDHMS = function toYMDHMS() {
            return this.toYMD() + ' ' + this.toHMS();
        };
    }

    if (!Date.prototype.toDMY) {
        Date.prototype.toDMY = function toDMY() {
            return this.getDateWithZeros() + '/' + this.getMonthWithZeros() + '/' + String(this.getFullYear());
        };
    }

    if (!Date.prototype.toFormattedDateString) {
        Date.prototype.toFormattedDateString = function toFormattedDateString() {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            return this.getDate() + ' ' + months[this.getMonth()] + ' ' + this.getFullYear();
        };
    }

    if (!Date.prototype.toFormattedTimeString) {
        Date.prototype.toFormattedTimeString = function toFormattedTimeString() {
            return this.getHoursWithZeros() + ':' + this.getMinutesWithZeros();
        };
    }

    if (!Date.prototype.toISOString) {
        (function () {
            Date.prototype.toISOString = function toISOString() {
                return this.getUTCFullYear()
                    + '-' + pad(this.getUTCMonth() + 1)
                    + '-' + pad(this.getUTCDate())
                    + 'T' + pad(this.getUTCHours())
                    + ':' + pad(this.getUTCMinutes())
                    + ':' + pad(this.getUTCSeconds())
                    + '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
                    + 'Z';
            };
        }());
    }

    if (!Date.prototype.getOrdinal) {
        Date.prototype.getOrdinal = function getOrdinal() {
            let dayNo = this.getDate();
            if (dayNo == 1 || dayNo == 21 || dayNo == 31) {
                return 'st';
            } else if (dayNo == 2 || dayNo == 22) {
                return 'nd';
            } else if (dayNo == 3 || dayNo == 23) {
                return 'rd';
            }
            return 'th';
        };
    }

    if (!Date.prototype.toShortDate) {
        Date.prototype.toShortDate = function toShortDate() {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            return months[this.getMonth()] + ' ' + this.getDate() + this.getOrdinal();
        };
    }

    if (!Date.prototype.isSameDay) {
        Date.prototype.isSameDay = function isSameDay(date) {
            return this.getDate() === date.getDate() && this.getMonth() === date.getMonth() && this.getFullYear() === date.getFullYear();
        };
    }
}());
