const _ = require('lodash');
const angular = require('angular');
const CartOffersCtrl = require('../../../js/controllers/modal/CartOffersCtrl');
const ChangeCalculator = require('../../../js/libraries/DigiTickets/Payment/ChangeCalculator');
const ChangeCtrl = require('../../../js/controllers/modal/ChangeCtrl');
const CustomerDetailsCtrl = require('../../../js/controllers/modal/CustomerDetailsCtrl');
const CustomerScreenStages = require('../../../js/libraries/DigiTickets/CustomerScreen/CustomerScreenStages');
const FieldInstanceFilter = require('../../../js/libraries/DigiTickets/CustomFields/FieldInstanceFilter');
const HeldOrdersCtrl = require('../../../js/controllers/modal/HeldOrdersCtrl');
const HoldOrderCtrl = require('../../../js/controllers/modal/HoldOrderCtrl');
const ItemEditableStatus = require('../../../js/libraries/DigiTickets/Orders/ItemEditableStatus');
const ItemEditCtrl = require('../../../js/controllers/modal/ItemEditCtrl');
const moment = require('moment');
const OrderNotesCtrl = require('../../../js/controllers/modal/OrderNotesCtrl');
const OrderToKitchenOrderMapper = require('../../../js/libraries/DigiTickets/Kitchen/OrderToKitchenOrderMapper');
const PaymentCtrl = require('../../../js/controllers/modal/PaymentCtrl');
const PaymentMethodRef = require('../../../js/libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
const PriceEditorCtrl = require('../../../js/controllers/modal/PriceEditorCtrl');
const Reservation = require('../../../js/libraries/DigiTickets/Reservations/Reservation');
const SaleValidationError = require('../../../js/libraries/DigiTickets/Cart/SaleValidationError');
const SessionPickerCtrl = require('../../../js/controllers/modal/SessionPickerCtrl');
const SoldGiftVoucherDataModalCtrl = require('../../../js/controllers/modal/SoldGiftVoucherDataModalCtrl');
const TicketQuantityModalCtrl = require('./modals/TicketQuantityModalCtrl');
const { cloneDeep } = require('../../../js/functions/clone');
const { cloneShallow } = require('../../../js/functions/clone');
const { countSessionSpacesInGroup, getTicketQtysInGroup } = require('../../../js/libraries/DigiTickets/Cart/cartItemGroups');
const { kitchenOrderDiff } = require('../../../js/libraries/DigiTickets/Kitchen/kitchenOrderDiff');
const { setFieldValuesOnCart } = require('../../../js/libraries/DigiTickets/CustomFields/fieldFunctions');

/**
 * @param $filter
 * @param $location
 * @param $modal
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {CartStasher} CartStasher
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {CartToConfirmationStructureMapper} cartToConfirmationStructureMapper
 * @param {CartToOrderMapper} cartToOrderMapper
 * @param {CartToReservationStructureMapper} cartToReservationStructureMapper
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {DialogService} DialogService
 * @param {GiftAidPrompter} giftAidPrompter
 * @param {FieldInstanceFactory} fieldInstanceFactory
 * @param {Hydrator} hydrator
 * @param {LandingPage} LandingPage
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.MembershipCancellationRunner} MembershipCancellationRunnerService
 * @param {DigiTickets.MembershipManagementActions} MembershipManagementActionsService
 * @param {DigiTickets.MembershipPlanManager} MembershipPlanService
 * @param {MembershipService} membershipService
 * @param {ModalFactory} ModalFactory
 * @param {NavigationService} navigationService
 * @param Notification
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {OrderAdjustmentConfirmer} orderAdjustmentConfirmer
 * @param {DigiTickets.OrderInProgressStasher} OrderInProgressStasher
 * @param {OrderQueue} orderQueue
 * @param {OrderReceiptPrinter} orderReceiptPrinter
 * @param OrderResource
 * @param {OrderSearchCache} orderSearchCache
 * @param {OrderService} orderService
 * @param {CurrentDevice} CurrentDevice
 * @param {PaymentService} paymentService
 * @param {PaymentMethodService} paymentMethodService
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {DigiTickets.ProductManager} ProductService
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param ReservationResource
 * @param {DigiTickets.SaleableItemService} SaleableItemService
 * @param {SaleValidator} saleValidator
 * @param {SellStateService} sellStateService
 * @param {SessionManager} SessionManager
 * @param {TaxManager} TaxManager
 * @param {ToastFactory} toastFactory
 * @param {DigiTickets.TicketManager} TicketService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param {UserService} UserService
 */
const SellCtrl = function (
    $filter,
    $location,
    $modal,
    $rootScope,
    $scope,
    $timeout,
    cartService,
    CartStasher,
    CartStashHelperService,
    cartToConfirmationStructureMapper,
    cartToOrderMapper,
    cartToReservationStructureMapper,
    CustomerScreenDataService,
    DialogService,
    giftAidPrompter,
    fieldInstanceFactory,
    hydrator,
    LandingPage,
    Logger,
    MembershipCancellationRunnerService,
    MembershipManagementActionsService,
    MembershipPlanService,
    membershipService,
    ModalFactory,
    navigationService,
    Notification,
    ConnectivityChecker,
    orderAdjustmentConfirmer,
    OrderInProgressStasher,
    orderQueue,
    orderReceiptPrinter,
    OrderResource,
    orderSearchCache,
    orderService,
    CurrentDevice,
    paymentService,
    paymentMethodService,
    PrivilegesService,
    ProductService,
    ReceiptPrinterService,
    ReservationResource,
    SaleableItemService,
    saleValidator,
    sellStateService,
    SessionManager,
    TaxManager,
    toastFactory,
    TicketService,
    TradingSessionManager,
    UserService
) {
    navigationService.showNav();

    // Early exit if no access permission
    PrivilegesService.requirePrivilegeOr403('access_sell');

    $scope.user = UserService;
    $scope.itemService = SaleableItemService;
    $scope.ticketManager = TicketService;
    $scope.productManager = ProductService;

    /**
     * @type {DigiTickets.MembershipCancellationRunner}
     */
    $scope.membershipCancellationRunner = MembershipCancellationRunnerService;
    $scope.membershipPlanManager = MembershipPlanService;
    $scope.sessionManager = SessionManager;

    /**
     * FieldInstanceFilter can be used in the template in future if we want to display product options
     * on cart lines.
     * For now it's just useful if you want to do some debugging in the template but isn't actually used.
     *
     * @type {ReadonlyArray<any>}
     */
    $scope.FieldInstanceFilter = FieldInstanceFilter;

    /**
     * @type {DigiTickets.SellState}
     */
    $scope.sellState = sellStateService.getDefaultState();

    /**
     * Make the item type "constants" available to the template.
     *
     * @type {DigiTickets.ItemType}
     */
    $scope.itemType = DigiTickets.ItemType;

    $scope.state = 'cart';

    /**
     * Incorrectly named property. Don't use this, use $scope.cartService.
     *
     * @type {CartService}
     */
    $scope.cart = cartService;

    /**
     * Correctly named property. Some day we'll get rid of $scope.cart so it's not confused with a Cart model.
     *
     * @type {CartService}
     */
    $scope.cartService = cartService;

    $scope.payProcessing = false;
    $scope.paymentInProgress = false;
    $scope.editingCartItem = null;
    $scope.ItemEditableStatus = ItemEditableStatus;
    $scope.hasFood = false;
    $scope.hasMembershipPlans = false;

    $scope.eventSessionPreference = {};

    /**
     * The category of the last ticket selected in the modal. We store this so that if the operator decides to
     * add more tickets the modal opens in the last category they added a ticket from.
     *
     * @type {?Category}
     */
    $scope.lastTicketSelectedCategory = {};

    /**
     * Set by the ProductSearchCtrl child controller.
     *
     * @type {boolean}
     */
    $scope.productSearchInputIsFocused = false;
    $scope.setProductSearchInputIsFocused = function setProductSearchInputIsFocused(value) {
        $scope.productSearchInputIsFocused = value;
    };

    CustomerScreenDataService.setStage(CustomerScreenStages.WAIT);

    // TODO: This should not happen now as there is new logic to prevent getting here without a user and device.
    // Ensure that is the case then remove this check.
    if (!CurrentDevice.isSet()) {
        $location.path('/device');
    }

    $scope.canPay = function canPay() {
        return !($scope.paymentInProgress || $scope.cart.isEmpty());
    };

    /**
     * @param {string} barcode
     */
    $scope.barcodeScanHandler = function (barcode) {
        if ($scope.paymentInProgress) {
            // only allow scanning when we're not in the middle of a payment
            return false;
        }

        let searchingToast = toastFactory.spinner('SEARCHING');

        // Try finding a product with that barcode.
        let product = $scope.productManager.findByBarcode(barcode);
        if (product !== null) {
            // Got a product - add it to the cart.
            $scope.addItem(product, 1);
            toastFactory.clear(searchingToast);
            return true;
        }

        // Try finding a membership with that barcode.
        membershipService.getMembershipByRef(
            barcode
        ).then(
            function (membership) {
                // Set cart membership, and show notification
                $scope.cart.customer.setMembership(membership);
                // Recalculate cart offers
                $scope.cart.itemsChanged();
                if (membership.customerAccount !== null) {
                    $scope.cart.customer.setAccount(membership.customerAccount);
                } else if (membership.members.length > 0 && membership.members[0].contact) {
                    $scope.cart.customer.setContact(membership.members[0].contact);
                }
                toastFactory.clear(searchingToast);
            },
            function () {
                // Show not found notification
                toastFactory.clear(searchingToast);
                toastFactory.warningTop(null, "Sorry, we couldn't find this product, membership, or customer.");
            }
        );
    };
    $scope.debouncedBarcodeScanHandler = _.debounce($scope.barcodeScanHandler, 100);

    // add scanned items to the cart
    $scope.$on('barcodeScanned', function (event, response) {
        if (response.openModal !== false) {
            // Don't do anything on this page if a modal is open on top of it.
            return;
        }

        if ($scope.productSearchInputIsFocused) {
            // Prevent scanning barcodes while the search box is focused as it causes items
            // to be added twice.
            return;
        }

        $scope.debouncedBarcodeScanHandler(response.code);
    });

    // FIXME (PRO-243): If the cart service and cart model were separate we would just be setting
    // the currentCart to null. Because we can't empty the cart without that emptying being persisted to the
    // current users session (which would break the user's stashed cart when switching users), we cannot
    // empty the cart when the controller goes away.
    // $scope.$on('$destroy', function () {
    //          $scope.clearCart();
    //      });

    $scope.clearCart = function clearCart() {
        // Reset the sell state after clearing.
        $scope.sellState = new DigiTickets.SellState();

        $scope.eventSessionPreference = {};
        $scope.clearPayment();
        $scope.cart.clear();
        OrderInProgressStasher.clearTransactionInProgress();
        $scope.clearProcessing();
    };

    $scope.clearCartButtonDisabled = function () {
        if ($scope.sellState.returnPath) {
            return false;
        }

        if (cartService.isEmpty() && !cartService.customer.hasData()) {
            return true;
        }
    };

    $scope.clearCartButtonPressed = function clearCartButtonPressed() {
        $scope.redirectWhenDone(cartService.currentCart, false);
        // If this order was previously sent to the kitchen, warn the operator that clearing the basket
        // will remove the order, even though preparation of the food may be in progress.
        if ($scope.cart.restoredKitchenOrder) {
            DialogService.confirm(
                'ON_HOLD_ORDERS.CLEAR_CART_WARNING',
                (confirmed) => {
                    if (confirmed) {
                        $scope.clearCart();
                    }
                }
            );
            return;
        }

        $scope.clearCart();
    };

    $scope.clearCartButtonText = function clearCartButtonText() {
        if (cartService.currentCart.isEditingOrder) {
            return 'SELL.CANCEL';
        } else if ($scope.sellState.returnPath) {
            // If clicking the clear button will take the user back where they came from, display it
            // as a cancel button instead of clear.
            return 'SELL.CANCEL';
        }

        return 'SELL.CLEAR';
    };

    /**
     * Add an item to the cart.
     *
     * @param {AbstractItem} item
     * @param {Number} quantity
     * @param {?object} [extraInfo]
     * @param {boolean} [fromStash]
     * @param {?Number} [lineNumber]
     *
     * @return {Promise<*>}
     */
    $scope.addItem = async function addItem(item, quantity, extraInfo, fromStash, lineNumber) {
        console.debug('Add item', quantity, item);

        if ($scope.sellState.preventAdditional) {
            if (typeof $scope.sellState.preventAdditional === 'string') {
                Notification.error(
                    $filter('lang')($scope.sellState.preventAdditional)
                );
            }
            return false;
        }

        // Make sure the operator doesn't try to sell memberships that are past their allowed usage date.
        if (item.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN && !fromStash) {
            if (item.hasOwnProperty('allowedUsageToDate') && item.allowedUsageToDate !== null) {
                if (item.allowedUsageToDate < (new Date())) {
                    Notification.warning(
                        $filter('lang')('CART.CANNOT_SELL_MEMBERSHIP_BEYOND_ALLOWED_USAGE_DATES')
                    );
                    return false;
                }
            }
        }

        let cartItem = new DigiTickets.CartItem($scope.cartService, item);

        // Apply a customPrice that came from a stashed cart.
        if (extraInfo && extraInfo.hasOwnProperty('customPrice') && extraInfo.customPrice !== null) {
            cartItem.customPrice = extraInfo.customPrice;
            cartItem.setPrice(extraInfo.customPrice);
        }

        if (cartItem.item.allowPriceEdit && cartItem.customPrice === null) {
            try {
                await showPriceEditModal(cartItem, quantity);
            } catch (e) {
                console.warn('Price entry cancelled. Cancelling add to cart.', e);
                return;
            }
        }

        // Check recurring payments (or different patterns) and single payments are not being mixed.
        // By this point both Gift Aid and a custom price have already been chosen, so we know if the price
        // of the item will be zero.
        if (!cartItem.isFree()) {
            let currentPaymentPattern = $scope.cart.getCurrentPaymentPattern();
            if (currentPaymentPattern && (!item.paymentPattern || item.paymentPattern !== currentPaymentPattern)) {
                showMixedPaymentPatternError();
                return false;
            }
        }

        if (item.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN) {
            // Adding a membership plan.
            if (fromStash) {
                cartItem.setMembershipDatasets(extraInfo && extraInfo.membershipDatasets ? extraInfo.membershipDatasets : []);
                cartItem.setCancellingMembership(extraInfo && extraInfo.cancellingMembership ? extraInfo.cancellingMembership : null);
            }
        } else if (item.itemType === DigiTickets.ItemType.GIFT_VOUCHER) {
            // Adding a gift voucher.
            cartItem.canAdjustQuantity = false;

            if (extraInfo && extraInfo.soldGiftVoucherData) {
                // If restoring a stashed item soldGiftVoucherData should already be set.
                cartItem.soldGiftVoucherData = extraInfo.soldGiftVoucherData;
            } else {
                // Create a new soldGiftVoucherData for a new item.
                cartItem.soldGiftVoucherData = new DigiTickets.SoldGiftVoucherData();

                // Default to in person delivery.
                cartItem.soldGiftVoucherData.deliveryType = DigiTickets.GiftVoucherDeliveryType.IN_PERSON;

                try {
                    await $scope.showSoldGiftVoucherDataModal('add-new', cartItem);
                } catch (e) {
                    console.warn('Gift Voucher data entry cancelled. Cancelling add to cart.', e);
                    return;
                }
            }
        } else if (item.isSessionBased()) {
            // Check if a session was passed in (probably from an un-stashed cart)
            // If it was, use that.
            // If it wasn't, show the session picker modal.
            if (extraInfo && extraInfo.assocSession) {
                cartItem.session = extraInfo.assocSession;
            }
        }

        if (extraInfo && extraInfo.hasOwnProperty('editableStatus')) {
            cartItem.editableStatus = extraInfo.editableStatus;
        }

        // See if an offer was passed in.
        if (extraInfo && extraInfo.manualOffer) {
            cartItem.setManualOffer(extraInfo.manualOffer);
        }

        if (extraInfo && extraInfo.discounts) {
            cartItem.setDiscounts(extraInfo.discounts);
        }

        if (extraInfo && extraInfo.variableDiscountsCollection) {
            cartItem.setVariableDiscountsCollection(extraInfo.variableDiscountsCollection);
        }

        if (extraInfo && extraInfo.renewingMembership) {
            cartItem.setRenewingMembership(extraInfo.renewingMembership, extraInfo.renewingMembershipFromDate);
        }

        // Remember the chosen session for this event so it can be
        // pre-selected when adding more tickets for this event.
        if (cartItem.session !== null) {
            let eventID = cartItem.session.eventID;
            $scope.eventSessionPreference[eventID] = cartItem.session;
        }

        // We no longer ask the Gift Aid question here because we need to show the total donation
        // amount to the customer when they are confirming the declaration.

        if (cartService.giftAidPrices) {
            // If giftAid is already enabled on the cart enable it on the item.
            cartItem.enableGiftAid();
        } else if ($scope.cart.giftAid === false) {
            // If giftAid has been declined disable it on the item.
            cartItem.disableGiftAid();
        }

        // Memberships need an account.
        if (!fromStash && cartItem.item.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN) {
            // Ensure we have a customer account before even adding to the cart.
            try {
                await $scope.ensureHaveCustomerAccount();
            } catch (e) {
                console.warn('Selecting a customer account was cancelled. Cannot continue adding membership.');
                return;
            }
        }

        // From this point on the item is actually in the cart, so cancelling beyond here needs to
        // remove it from the cart.
        cartItem = $scope.cart.add(cartItem, fromStash, lineNumber);
        cartItem.adjustQuantity(quantity);

        // Display membership data modal for the new line (or new instance on exiting line).
        // This happens after the line is added to the cart (cart.add) because if we are adding an
        // instance of a plan that is already in the cart the modal should show tabs for the existing instances.
        // cart.add will return the existing CartItem in the cart that has those instances, and that's what
        // whose instances we want to display and add the new instance to, not a new CartItem.
        if (!fromStash && cartItem.item.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN) {
            if (cartItem.membershipDatasets.length < cartItem.quantity) {
                try {
                    await $scope.displayNewMembershipDatasetModal(cartItem);
                } catch (e) {
                    console.warn('Membership data entry cancelled. Cancelling add to cart.', e);
                    $timeout(() => {
                        // Remove the qty that was just added.
                        cartItem.adjustQuantity(-quantity);
                    });
                    return;
                }
            }
        }

        // If there are 'askEarly' fields, then go and ask for them.
        if (!fromStash) {
            let askEarlyInstances = cartItem.itemInstances[0].fields.instances.filter(
                FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY
            );
            console.log(`There are ${askEarlyInstances.length} ask early fields for this item.`);

            if (askEarlyInstances.length > 0 && cartItem.itemInstances.length === 1) {
                // For the first instance show the modal to answer the fields.
                $scope.showItemEditModal($scope.cart, cartItem, 'ITEM_EDIT.ASK_EARLY_FIELDS');
            }
            // For additional instances the values will be copied from the first instance on the line.
        }

        // If Gift Aid is enabled but not enough customer info has been added show the customer details
        // modal to allow it to be entered now.
        if (cartService.giftAidEnabled() && !$scope.cart.customer.hasGiftAidData()) {
            $scope.showCustomerDetails(
                $filter('lang')('SELL.GIFT_AID_CUSTOMER_INFO_REQUIRED')
            );
        }

        /**
         * If the UI isn't already being updated, trigger an
         * update of the bindings. This is need where cart adding
         * is triggered via barcode scan input rather than mouse
         * events taking place in the UI.
         */
        $timeout(function () {
            $scope.$apply();
        });
    };

    /**
     * Remove an item from the cart (completely, not just decrease qty) by clicking the delete icon on a line.
     *
     * @param {DigiTickets.CartItem} cartItem
     */
    $scope.removeItem = (cartItem) => {
        if (cartItem.isMembershipPlan()) {
            DialogService.confirm(
                cartItem.quantity > 1 ? 'MEMBERSHIPS.CONFIRM_REMOVE_ALL' : 'MEMBERSHIPS.CONFIRM_REMOVE',
                (confirmed) => {
                    if (confirmed === true) {
                        $scope.cart.remove(cartItem);
                    }
                }
            );
        } else {
            $scope.cart.remove(cartItem);
        }
    };

    $scope.setProcessing = function setProcessing() {
        $scope.payProcessing = true;
        $scope.state = 'processing';
    };

    $scope.clearProcessing = function clearProcessing() {
        $scope.payProcessing = false;
        $scope.state = 'cart';
    };

    /**
     * Order has been successfully completed or cancelled.
     * Send the user (back to) where they should be.
     *
     * @param {Cart} cart
     * @param {boolean} wasSuccessful
     * @param {boolean} [wasPayCancelled] If this redirect was triggered by the payment modal being cancelled
     *                                    we may not want to redirect for this in some cases.
     */
    $scope.redirectWhenDone = function redirectWhenDone(cart, wasSuccessful, wasPayCancelled) {
        let sellState = cart.sellState || $scope.sellState;

        if (wasSuccessful && sellState.successReturnPath) {
            $location
                .path(sellState.successReturnPath)
                .search(sellState.successReturnPathQuery);
        } else if (sellState.returnPath && wasPayCancelled !== true) {
            $location
                .path(sellState.returnPath)
                .search(sellState.returnPathQuery);
        }
    };

    $scope.clearPayment = function clearPayment() {
        $scope.cart.clearPayments();
        $scope.clearProcessing();
        $scope.paymentInProgress = false;
    };

    //
    // For whatever reason, the order can't currently be submitted
    // to the API server, so store it for processing later
    //
    // @param additionalData
    //
    $scope.storeOrderForLaterProcessing = function storeOrderForLaterProcessing(additionalData) {
        let reservationStructure = cartToReservationStructureMapper.map($scope.cart);
        reservationStructure.force = 1;

        let request = new DigiTickets.OrderRequest(
            cartToConfirmationStructureMapper.map($scope.cart),
            reservationStructure
        );

        // Add any additional data to be stored with the orderRequest (e.g. tempID)
        if (typeof request.order === 'object' && typeof additionalData === 'object') {
            angular.extend(request.order, additionalData);
        }

        orderQueue.addRequest(request);
    };

    /**
     * The order has been completed, finish up.
     * - Display change on customer screen.
     * - Update available spaces for sessions.
     * - Log any voucher payments in trading session.
     * - Create tax line for Greece
     * - Print receipt (if enabled)
     * - Cancel any memberships that need cancelling
     * - Show the change modal
     * - Reset the screen for the next cart
     *
     * @param {DigiTickets.Order} createdOrder The order that was just created.
     * @param {?OrderAdjustmentResponse} [orderAdjustmentResponse]
     */
    $scope.finishOrder = async function (createdOrder, orderAdjustmentResponse) {
        console.log('finishOrder createdOrder', createdOrder);

        let calculator = new ChangeCalculator();
        calculator.setAmountDue($scope.cart.getTotalDue());
        calculator.setAmountTendered($scope.cart.getTotalTendered());

        CustomerScreenDataService.updateState((state) => {
            state.summary = {
                total: calculator.getAmountDue(),
                paid: calculator.getAmountTendered(),
                change: calculator.getChange()
            };
            state.stage = CustomerScreenStages.COMPLETE;
        });

        /**
         * Update the local session availability, based on what we've just sold. The session refresher
         * task will come along in a couple of minutes anyway, and give a definitive availability, but
         * this quick adjustments means that at least the session displays a more correct availability
         * straight away.
         *
         * @type {Object<number>}
         */
        let sessionSpacesSold = {};

        createdOrder.items.forEach(function (orderLine) {
            if (orderLine.session) {
                if (!sessionSpacesSold.hasOwnProperty(orderLine.session.ID)) {
                    sessionSpacesSold[orderLine.session.ID] = 0;
                }
                let people = orderLine.people ? parseInt(orderLine.people) : 1;
                sessionSpacesSold[orderLine.session.ID] += (orderLine.qty * people);
            }
        });

        for (let sessionId in sessionSpacesSold) {
            if (sessionSpacesSold.hasOwnProperty(sessionId)) {
                $scope.sessionManager.adjustAvailability(
                    parseInt(sessionId),
                    -sessionSpacesSold[sessionId]
                );
            }
        }

        // Add payments to sales counter.
        let paymentsLog = [];
        for (let i = 0; i < createdOrder.payments.length; i++) {
            let payment = createdOrder.payments[i];
            paymentsLog.push(
                [
                    payment.paymentMethod ? payment.paymentMethod.ref : null,
                    payment.amount,
                ]
            );
            if (payment.paymentMethod && payment.paymentMethod.ref === PaymentMethodRef.VOUCHER) {
                TradingSessionManager.saveEvent(
                    (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.VOUCHER))
                        .setAmount(payment.value)
                );
            }
        }

        // Create the data to add to the receipt for Greek tax.
        let greekTaxRegistration = TaxManager.getGreekTaxRegistrationCode(createdOrder);
        let slLine;
        let taxSummary;
        try {
            if (greekTaxRegistration) {
                [slLine, taxSummary] = await TaxManager.getTaxSummaryAndCreateSLLineModel(createdOrder, greekTaxRegistration);
            }
        } catch (e) {
            console.error(e);
        }

        Logger.info(
            'Order complete',
            {
                bookingRef: createdOrder.bookingRef,
                payments: paymentsLog
            }
        );

        TaxManager.addTaxDataToLine(createdOrder, taxSummary);

        if (CurrentDevice.device.alwaysPrintReceipts()) {
            orderReceiptPrinter.printOrderReceipt(
                createdOrder,
                orderAdjustmentResponse,
                slLine
            );
        }

        if (orderAdjustmentResponse) {
            // If this was adjusting an order we will be sending the user back to the order details screen
            // after the change modal. We need to clear the cached order so the newly adjusted data displays
            // for this order instead.
            orderSearchCache.clear();
        }

        // Handle cancelling any memberships where refunds are being issued as those refunds
        // have been issued now that the order is complete.
        let cancellingMemberships = $scope.cart.getCancellingMemberships();
        if (cancellingMemberships.length > 0) {
            $scope.setProcessing();
            $scope.membershipCancellationRunner.cancel(
                cancellingMemberships,
                function (cancelledMemberships, uncancelledMemberships, messages) {
                    $scope.clearProcessing();
                    $scope.showChange(createdOrder, messages, orderAdjustmentResponse, cartService.currentCart);
                    $scope.clearCart();
                }
            );
        } else {
            $scope.showChange(createdOrder, [], orderAdjustmentResponse, cartService.currentCart);
            $scope.clearCart();
        }
    };

    /**
     * @param {DigiTickets.Order} order
     * @param {Array<{type: string, text:string}>} [additionalMessages]
     * @param {OrderAdjustmentResponse} [orderAdjustmentResponse]
     * @param {Cart} cart The cart that was just sold. This is passed in because the currentCart may have
     *      already been reset by the time we get to here.
     */
    $scope.showChange = function (order, additionalMessages, orderAdjustmentResponse, cart) {
        ModalFactory
            .open(
                'change',
                ChangeCtrl,
                'partials/modals/payment/change.html',
                {
                    cart: () => cart,
                    order: () => order,
                    additionalMessages: () => additionalMessages,
                    orderAdjustmentResponse: () => orderAdjustmentResponse
                }
            )
            .then(function () {
                $scope.redirectWhenDone(cart, true);
            });
    };

    $scope.disableGiftAid = function () {
        $scope.cart.disableGiftAid();
    };

    /**
     * Prompts for selection of a customer account if one is not already.
     * Then calls the callback
     */
    $scope.ensureHaveCustomerAccount = () => new Promise((resolve, reject) => {
        if ($scope.cart.customer.hasAccount()) {
            resolve();
            return;
        }

        // Don't have an account. Show the customer details modal so the user can select one.
        let message = $filter('lang')('SELL.ACCOUNT_SELECTION_REQUIRED');
        $scope.showCustomerDetails(message).then(() => {
            if ($scope.cart.customer.hasAccount()) {
                resolve();
            } else {
                reject();
            }
        });
    });

    /**
     * Method to put the order on hold. It uses the same "stash" mechanism as the
     * "Switch User" code (except it doesn't log the user out, of course!).
     *
     * @param {function} callback
     * @param {boolean} onlyAllowAssignStaffRef Only show the "assign reference" button, not the
     *                                          "send to kitchen" and "hold" buttons?
     * @param {string} message The name of a language string to display as an info message in the modal.
     */
    $scope.holdOrder = function holdOrder(callback, onlyAllowAssignStaffRef, message) {
        let printKitchenOrder = function (stashedCart) {
            // Convert the stash to an order to print.
            CartStashHelperService
                .stashToOrder(stashedCart)
            // Needs an arrow function to so 'this' works in the mapper
            // https://stackoverflow.com/a/46929294/710630
                .then((order) => OrderToKitchenOrderMapper.map(order))
                .then((kitchenOrder) => {
                    let diff;
                    if ($scope.cart.restoredKitchenOrder) {
                        // Ensure that we are only comparing modifications to the same order.
                        // TODO: Update to use thirdPartyRef when thirdPartyRef is added
                        if (kitchenOrder.thirdPartyID === $scope.cart.restoredKitchenOrder.thirdPartyID) {
                            kitchenOrder.fulfillmentCreatedAt = $scope.cart.restoredKitchenOrder.fulfillmentCreatedAt;
                            diff = kitchenOrderDiff($scope.cart.restoredKitchenOrder, kitchenOrder);
                        }

                        if (diff === null) {
                            return;
                        }
                    }
                    ReceiptPrinterService.printKitchenOrder(kitchenOrder, diff);
                });
        };

        let doStash = (requiresFulfillment) => {
            $timeout(async () => {
                Logger.debug('Hold order');

                let stashPromise = CartStasher.stashCart($scope.cart, requiresFulfillment);
                toastFactory.spinner('ON_HOLD_ORDERS.HOLDING', null, stashPromise);

                // Stash the cart.
                let stashedCart = await stashPromise;

                // And clear the cart.
                $scope.clearCart();

                toastFactory.successTop('ON_HOLD_ORDERS.HELD');

                // Print the kitchen receipt here if enabled for the branch.
                if (requiresFulfillment && UserService.currentBranch.kitchenAutoPrint) {
                    printKitchenOrder(stashedCart);
                }
            });
        };

        /**
         * @param {DigiTickets.StashedCart} mergeToStash
         * @param {boolean} requiresFulfillment
         */
        let doMerge = function (mergeToStash, requiresFulfillment) {
            Logger.debug('Merge held order', { toStashStaffRef: mergeToStash.staffRef });

            // Stash the cart.
            let stashedCart = CartStasher.mergeWithStash(
                $scope.cart,
                mergeToStash,
                requiresFulfillment
            );

            // And clear the cart.
            $scope.clearCart();

            Notification.success('Order has been successfully merged and saved.');

            // Print the kitchen receipt here if enabled for the branch.
            if (requiresFulfillment && UserService.currentBranch.kitchenAutoPrint) {
                printKitchenOrder(stashedCart);
            }
        };

        // Display the modal to enter a reference for the stashed cart.
        ModalFactory
            .open(
                'hold-order',
                HoldOrderCtrl,
                'partials/modals/holdOrder.html',
                {
                    onlyAllowAssignStaffRef: () => onlyAllowAssignStaffRef,
                    message: () => message
                }
            )
            .then(
                function (result) {
                    if (result.mergeToStash) {
                        $scope.cart.setStaffRef(result.staffRef);
                        doMerge(result.mergeToStash, result.sendToKitchen);
                    } else {
                        $scope.cart.setStaffRef(result.staffRef);
                        if (result.hold) {
                            doStash(result.sendToKitchen);
                        }
                    }

                    if (callback) {
                        callback(true);
                    }
                },
                function () {
                    // Modal was cancelled.
                    if (callback) {
                        callback(false);
                    }
                }
            );
    };

    /**
     * Display the modal of stashed carts that can be restored.
     */
    $scope.showRestore = function showRestore() {
        ModalFactory.open(
            'restore',
            HeldOrdersCtrl,
            'partials/modals/heldOrders.html'
        );
    };

    /**
     * @param {boolean} [displayProcessingOverlay]
     */
    $scope.refreshExternalCalculations = (displayProcessingOverlay) => {
        if (displayProcessingOverlay) {
            $rootScope.setRootProcessingMessage('SELL.PROCESSING_CALCULATING_OFFERS');
        }
        let promise = $scope.cart.refreshExternalCalculations(true);
        if (displayProcessingOverlay) {
            promise.finally(() => {
                $rootScope.setRootProcessingMessage(null);
            });
        }
        // Suppress rejections. It will reject if offline but we don't want this to prevent
        // completing a sale.
        return promise.catch((error) => {
            console.warn('Failed to refresh cart calculations', error);
        });
    };

    /**
     * @return {Promise}
     */
    const runPreCheckoutValidation = () => saleValidator
        .validate($scope.cart)
        .catch((e) => {
            console.log('Cannot proceed to payment', e);
            // Something in SaleValidator prevented proceeding to payment.
            // Allow the user to deal with the issue then start validation again as if they had clicked
            // the pay button again.

            if (e instanceof SaleValidationError) {
                switch (e.message) {
                    case SaleValidationError.DELIVERY_ADDRESS_REQUIRED:
                        // Something requires a delivery address but none was given.
                        // Show customer details modal with error message.
                        return $scope
                            .showCustomerDetails(
                                $filter('lang')('SELL.DELIVERY_ADDRESS_REQUIRED_ALERT')
                            )
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.DEVICE_OFFLINE:
                        // Device must be online for orders but we're offline.
                        // Show an alert.
                        DialogService.alert(
                            'SELL.CONNECTION_REQUIRED_ALERT'
                        );
                        throw e; // Re-throw the error so promise is not resolved.

                    case SaleValidationError.CUSTOMER_ACCOUNT_REQUIRED:
                        // Something requires a customer account but none was selected.
                        // Show customer details modal with error message.
                        return $scope
                            .showCustomerDetails(
                                $filter('lang')('SELL.ACCOUNT_SELECTION_REQUIRED')
                            )
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.FIELD_DATA_INVALID:
                        // Invalid field data was entered.
                        // Show customer detail modal with field errors.
                        return $scope.showCustomerDetails(
                            $filter('lang')('SELL.FIELD_DATA_INVALID')
                        )
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.ADDITIONAL_CUSTOM_FIELDS_NOT_PRESENTED:
                        // There are custom fields that have not been presented yet.
                        return $scope
                            .showCustomerDetails(
                                {
                                    text: $filter('lang')('SELL.CUSTOM_FIELDS_AVAILABLE_CHANGED'),
                                    type: 'info'
                                }
                            )
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.GIFT_AID_UNANSWERED:
                        // There are Gift Aid-able items but the Gift Aid yes/no question has not been asked.
                        // Ask the question.
                        // This automatically retries unlike other errors.
                        return giftAidPrompter
                            .prompt($scope.cart, e.cartLines[0])
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.GIFT_AID_CUSTOMER_INFO_REQUIRED:
                        // Gift Aid was enabled but customer info has not been given.
                        // Show customer details modal with error message.
                        return $scope
                            .showCustomerDetails(
                                $filter('lang')('SELL.GIFT_AID_CUSTOMER_INFO_REQUIRED')
                            )
                            .then(runPreCheckoutValidation);

                    case SaleValidationError.MISSING_MEMBERSHIP_DATA:
                        // Some of the membership data in the cart is missing.
                        Notification.error(
                            $filter('lang')('SELL.MISSING_MEMBERSHIP_DATA_ALERT')
                        );
                        throw e; // Re-throw the error so promise is not resolved.

                    case SaleValidationError.STAFF_REF_REQUIRED:
                        // Show staff ref entry screen.
                        return new Promise((resolve, reject) => {
                            $scope.holdOrder(
                                (result) => {
                                    if (result === true) {
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                },
                                true,
                                'ON_HOLD_ORDERS.REF_REQUIRED_FOR_KITCHEN_MSG'
                            );
                        }).then(runPreCheckoutValidation);
                }
            }
            throw e;
        });

    $scope.payButtonClick = function () {
        return runPreCheckoutValidation()
            .then(() => $scope.refreshExternalCalculations(true))
            .then(() => pay());
    };

    const pay = async function () {
        $scope.paymentInProgress = true;
        if (!cartService.currentCart.balancePaid) {
            cartService.currentCart.balancePaid = false;
        }

        CustomerScreenDataService.setStage(CustomerScreenStages.PAY);

        /**
         * After reservation is successful, display the payment dialog.
         *
         * @param {{message: string, success: boolean, reservation: object}} response
         */
        let reservationSuccessHandler = function (response) {
            $rootScope.setRootProcessingMessage(null);

            if (response.success) {
                /** @type {Reservation} reservation */
                const reservation = hydrator.hydrate(response.reservation, new Reservation());
                let availablePaymentMethods = paymentMethodService.getAll();
                let refundMethodsAreAvailable = paymentMethodService.refundMethodsAreAvailable();

                $scope.cart.setReservation(reservation);
                try {
                    let cartTotal = cartService.getTotalDue();

                    if (cartTotal === 0) {
                        // The cart total is zero so skip the payment modal and go straight to
                        // submitting the order.
                        onPaymentModalSubmit();
                        return;
                    }

                    if ((cartTotal > 0 && availablePaymentMethods.length > 0)
                                || (cartTotal < 0 && refundMethodsAreAvailable)) {
                        OrderInProgressStasher.setState('pay');

                        // Non-zero total price, and appropriate payment methods are available, so show payment dialog.
                        // Now that we have integrated payments, it's important that the
                        // user cannot dismiss the modal by pressing ESC or clicking the
                        // background, hence the backdrop and keyboard settings.
                        let modalInstance = $modal.open({
                            templateUrl: 'partials/modals/payment/index.html',
                            controller: PaymentCtrl,
                            windowClass: 'in payment-modal',
                            backdrop: 'static',
                            keyboard: false
                        });

                        modalInstance.result.then(function () {
                            onPaymentModalSubmit();
                        }, function () {
                            CustomerScreenDataService.setStage(CustomerScreenStages.SELL);

                            OrderInProgressStasher.setState('sell');
                            OrderInProgressStasher.clearTransactionInProgress();

                            // User cancelled the payment.
                            $scope.cart.deleteReservation();
                            $scope.clearPayment();

                            $scope.redirectWhenDone(cartService.currentCart, false, true);
                        });
                    } else {
                        // If no appropriate payment methods are available (eg it's a refund and no methods support refunds)
                        // show error message and return to the cart or the order
                        let errorMessage = cartTotal < 0 ? 'No payment methods supporting refunds are available' : 'No payment methods are available!';
                        Notification.error(errorMessage);
                        $scope.cart.deleteReservation();
                        $scope.clearPayment();

                        $scope.redirectWhenDone(cartService.currentCart, false);
                    }
                } catch (error) {
                    $scope.cart.deleteReservation();
                    $scope.clearPayment();
                }
            } else {
                $scope.cart.deleteReservation();
                $scope.clearPayment();
                Notification.error(response.message);
            }
        };

        /**
         * After the "Received PAYMENTTYPE Payment" button is clicked...
         */
        const onPaymentModalSubmit = function () {
            OrderInProgressStasher.setState('sell');
            OrderInProgressStasher.clearTransactionInProgress();

            let additionalData = {
                // An ID to refer to this order by locally until we know the real ID from the API.
                tempID: new Date().getTime()
            };

            cartService.currentCart.balancePaid = true;

            // If the balance of an order is being paid just update the order and do nothing else.
            if (cartService.currentCart.containsOrderBalanceItem()) {
                // We update the existing order instead of creating a new order.
                $scope.handlePayingOrderBalanceComplete();
                return;
            }

            // If we are amending an order we make a request to the /orderadjustments endpoint
            // instead of /orders.
            if (cartService.currentCart.isEditingOrder) {
                $rootScope.setRootProcessingMessage('SELL.PROCESSING_CREATING_ORDER_ADJUSTMENT');
                orderAdjustmentConfirmer
                    .confirmAdjustment(cartService)
                    .then((orderAdjustmentResponse) => {
                        console.log('orderAdjustmentResponse', orderAdjustmentResponse);
                        $scope.finishOrder(orderAdjustmentResponse.adjustmentOrder, orderAdjustmentResponse);
                    })
                    .catch((err) => {
                        console.error('Error confirming adjustment.', err);
                        // TODO: Display errors on individual lines in cart.
                        // Needs the API finished to provide errors in a line by line format.
                        toastFactory.errorTop(err.message);
                        $scope.paymentInProgress = false;
                    })
                    .finally(() => {
                        $rootScope.setRootProcessingMessage(null);
                    });
                return;
            }

            let orderConfirmationStructure = cartToConfirmationStructureMapper.map($scope.cart);
            // Only post online if we have a reservation token.
            if (orderConfirmationStructure.reservationToken) {
                $rootScope.setRootProcessingMessage('SELL.PROCESSING_CREATING_ORDER');

                // POST to the /orders endpoint
                OrderResource.confirm(
                    {},
                    orderConfirmationStructure,
                    (response) => {
                        $rootScope.setRootProcessingMessage(null);
                        if (response.success) {
                            orderQueue.logAutoRedeemAfterSale(
                                response.order.bookingRef,
                                response.lineRedemption
                            );
                            let newOrder = cartToOrderMapper.buildOrderFromApiConfirmation(
                                cartService,
                                response,
                                additionalData
                            );
                            $scope.finishOrder(newOrder);
                        } else {
                            Notification.error({
                                title: 'An unexpected error has occurred.',
                                message: 'The server generated an inconsistent result in relation to this order.<br>'
                                            + 'Please refund the customer if payment was taken.',
                                delay: 100000
                            });
                            $scope.clearCart();
                            $scope.redirectWhenDone(cartService.currentCart, false);
                        }
                    },
                    (response) => {
                        $rootScope.setRootProcessingMessage(null);
                        if (response.status == 400) {
                            let errorMessage = response && response.data ? response.data.message : '';
                            Notification.error({
                                title: 'An unexpected error has occurred.',
                                message: 'The server generated an error in relation to this order.<br>'
                                            + 'Please refund the customer if payment was taken.<br><br>'
                                            + '<b>Error: </b>' + errorMessage,
                                delay: 100000
                            });
                            $scope.clearCart();
                            $scope.redirectWhenDone(cartService.currentCart, false);
                        } else {
                            /**
                             * FIXME: We need to do better than "for whatever reason"! A lot of orders should
                             * not be retried automatically.
                             *
                             * For whatever reason, confirming the order failed.
                             * This could be an API server or connection issue, so add it
                             * to our order queue to be processed a bit later.
                             */
                            $scope.storeOrderForLaterProcessing(additionalData);
                            let newOrder = cartToOrderMapper.buildOrderFromApiConfirmation(
                                cartService,
                                null,
                                additionalData
                            );
                            $scope.finishOrder(newOrder);
                        }
                    }
                );
            } else {
                // We didn't get a reservation token, so don't try to confirm the order now,
                // just store for later processing
                $scope.storeOrderForLaterProcessing(additionalData);
                let newOrder = cartToOrderMapper.buildOrderFromApiConfirmation(
                    cartService,
                    null,
                    additionalData
                );
                $scope.finishOrder(newOrder);
            }
        };

        /**
         * If reservation fails
         *
         * @param error
         */
        let reservationErrorHandler = function reservationErrorHandler(error) {
            $rootScope.setRootProcessingMessage(null);

            if (!ConnectivityChecker.isOnline() || (error && (error.status == 0 || error.status == 503))) {
                // they are offline so let them continue with a dummy reservation..
                reservationSuccessHandler({
                    reservation: {}
                });
            } else if (error) {
                let errorMessage = 'Failed to reserve order.';
                if (error.data.message !== undefined) {
                    errorMessage = 'Cannot reserve order. ' + error.data.message;
                }

                $scope.cart.deleteReservation();
                $scope.clearPayment();
                Notification.error(errorMessage);
            }
        };

        if (!$scope.cart.hasReservation()) {
            if (cartService.currentCart.containsOrderBalanceItem()) {
                // This is paying the balance of an existing order. No reservation is required.
                reservationSuccessHandler({
                    reservation: {},
                    success: true
                });
            } else if (ConnectivityChecker.isOnline()) {
                $rootScope.setRootProcessingMessage('SELL.PROCESSING_CREATING_RESERVATION');
                ReservationResource.reserve(
                    cartToReservationStructureMapper.map($scope.cart),
                    reservationSuccessHandler,
                    reservationErrorHandler
                );
            } else {
                // they're offline, so don't try to actually send the reservation.
                reservationSuccessHandler({
                    reservation: {},
                    success: true
                });
            }
        } else {
            ReservationResource.update(
                { id: $scope.cart.reservation.token }, // Pass the ID to update the resource
                cartToReservationStructureMapper.map($scope.cart),
                reservationSuccessHandler,
                reservationErrorHandler
            );
        }
    };

    $scope.handlePayingOrderBalanceComplete = function () {
        // Order has been paid for so here we add the payments to the order.

        // Get the original order from the order balance line in the cart. There should only be one line because
        // there is code to prevent extra lines alongside an order balance.

        let balanceLine = cartService.currentCart.itemList.find(
            (line) => line.getItemType() === DigiTickets.ItemType.ORDER_BALANCE
        );
        if (!balanceLine) {
            throw new Error('Failed to find Order Balance line after paying order balance.');
        }

        /** @type {OrderBalanceItem} */
        let balanceItem = balanceLine.item;

        orderService.addPayments(
            balanceItem.getOriginalOrder(),
            paymentService.cleanPayments(cartService.payments)
        );

        let newOrder = cartToOrderMapper.buildOrderFromApiConfirmation(cartService);
        $scope.finishOrder(newOrder);
    };

    /**
     * Return all the tickets for a specific event.
     *
     * @param {int} eventID
     *
     * @return {DigiTickets.Ticket[]}
     */
    const getAllTicketsForEvent = (eventID) => {
        const categorisedTickets = Object.values(SaleableItemService.itemTypes[DigiTickets.ItemType.TICKET].items);

        const allTickets = categorisedTickets.reduce((collection, items) => collection.concat(items), []);

        return allTickets.filter((ticket) => (ticket.event && ticket.event.ID === eventID));
    };

    /**
     * Return a unique array of categories that have tickets.
     *
     * @param {DigiTickets.Ticket[]} relevantTickets
     *
     * @return {Category[]}
     */
    const getPopulatedEventCategories = (relevantTickets) => {
        let categories = [];
        let uniqueCategoryIDs = [];
        relevantTickets.forEach(function (ticket) {
            if (uniqueCategoryIDs.indexOf(ticket.category.ID) === -1) {
                categories.push(ticket.category);
                uniqueCategoryIDs.push(ticket.category.ID);
            }
        });
        return categories;
    };

    /**
     * Add some tickets to the cart by first showing the qty selection modal
     * then showing the session selection modal.
     *
     * @param {DigiTickets.Event} event
     * @param {Category} category
     * @param {{ticket: DigiTickets.Ticket, qty: Number}[]} [initialQuantities]
     */
    const startEventBooking = async (event, category, initialQuantities) => {
        const relevantTickets = getAllTicketsForEvent(event.ID);

        const categories = getPopulatedEventCategories(relevantTickets);

        const ticketQuantityData = await showTicketQuantityModal(relevantTickets, category, null, initialQuantities, categories);

        if (ticketQuantityData) {
            $scope.lastTicketSelectedCategory = ticketQuantityData.lastTicketSelectedCategory;

            let selectedTickets;
            try {
                selectedTickets = await showSessionModal(ticketQuantityData.tickets, event);
            } catch (e) {
                // Operator cancelled the session selector. Call this function to revert back to the quantity selector.
                return startEventBooking(event, category, ticketQuantityData.tickets);
            }
            selectedTickets.map(({ ticket, qty, price, session }) => {
                // As session-based pricing rules may have kicked in, create a copy of the Ticket and modify
                // the price of it (so it doesn't affect any references to the same Ticket elsewhere).
                const adjustedTicket = cloneShallow(ticket);

                // The price returned from the session picker modal including pricing rules.
                adjustedTicket.price = price;

                $scope.addItem(
                    adjustedTicket,
                    qty,
                    {
                        assocSession: session
                    }
                );
            });
        }
    };
    // Make this function available to templates - specifically to the event buttons in the item buttons panel.
    $scope.startEventBooking = startEventBooking;

    /**
     * @param {DigiTickets.Ticket[]} tickets
     * @param {Category} category
     * @param {?Session} session If we know the session in advance of quantity selection
     * @param {{ticket: DigiTickets.Ticket, qty: Number}[]} [initialQuantities]
     * @param {Category[]} categories
     *
     * @return {Promise<{tickets: {ticket: DigiTickets.Ticket, qty: Number}[], lastTicketSelectedCategory: Category}>}
     */
    const showTicketQuantityModal = async (tickets, category, session, initialQuantities, categories) => ModalFactory.open(
        'ticket-quantity',
        TicketQuantityModalCtrl,
        'epos/templates/modals/ticket-quantity-modal.html',
        {
            tickets: () => tickets,
            category: () => category,
            session: () => session,
            initialQuantities: () => initialQuantities,
            categories: () => categories
        }
    );

    /**
     * @param {{ticket: DigiTickets.Ticket, qty: Number}[]} quantities
     * @param {DigiTickets.Event} event
     * @param {Object<number>} [sessionSpaceAdjustments]
     *
     * @return {Promise<{ticket: DigiTickets.Ticket, qty: Number, price: Number, session: Session}[]>}
     */
    const showSessionModal = async (quantities, event, sessionSpaceAdjustments) => ModalFactory.open(
        'session-picker',
        SessionPickerCtrl,
        'partials/modals/sessionPicker.html',
        {
            event: () => event,
            quantities: () => quantities,
            eventSessionPreference: () => $scope.eventSessionPreference,
            sessionSpaceAdjustments: () => sessionSpaceAdjustments || {}
        }
    );

    /**
     * Add more tickets to a session already in the cart. Triggered by the + button on a session group in cart.
     *
     * @param {CartItemGroup} cartItemGroup
     * @param {{ticket: DigiTickets.Ticket, qty: Number}[]} [initialQuantities]
     */
    $scope.addMoreTicketsForSession = async (cartItemGroup, initialQuantities) => {
        const relevantTickets = getAllTicketsForEvent(cartItemGroup.session.eventID);

        const categories = getPopulatedEventCategories(relevantTickets);

        if (!$scope.lastTicketSelectedCategory) {
            $scope.lastTicketSelectedCategory = categories[0];
        }

        const ticketQuantityData = await showTicketQuantityModal(relevantTickets, $scope.lastTicketSelectedCategory, cartItemGroup.session, initialQuantities, categories);

        if (ticketQuantityData) {
            // Set the preferred session to the selected session. This gets used when the session modal is shown.
            const event = relevantTickets[0].event;
            $scope.eventSessionPreference[event.ID] = cartItemGroup.session;
            $scope.lastTicketSelectedCategory = ticketQuantityData.lastTicketSelectedCategory;
            let selectedTickets;
            try {
                selectedTickets = await showSessionModal(ticketQuantityData.tickets, event);
            } catch (e) {
                // Operator cancelled the session selector. Call this function to revert back to the quantity selector.
                return $scope.addMoreTicketsForSession(cartItemGroup, ticketQuantityData.tickets);
            }
            selectedTickets.map(({ ticket, qty, price, session }) => {
                // As session-based pricing rules may have kicked in, create a copy of the Ticket and modify
                // the price of it (so it doesn't affect any references to the same Ticket elsewhere).
                const adjustedTicket = cloneShallow(ticket);

                // The price returned from the session picker modal including pricing rules.
                adjustedTicket.price = price;

                $scope.addItem(
                    adjustedTicket,
                    qty,
                    {
                        assocSession: session
                    }
                );
            });
        }
    };

    /**
     * Change the selected session for every CartItem in the given CartItemGroup (already in the cart).
     *
     * @param {CartItemGroup} cartItemGroup
     */
    $scope.changeCartItemGroupSession = async (cartItemGroup) => {
        /**
         * Object to pass to session space calculator's sessionSpaceAdjustments containing the total
         * spaces for all the tickets for a given session.
         *
         * @type {Object<number>}
         */
        let sessionSpaceAdjustments = countSessionSpacesInGroup(cartItemGroup);

        /**
         * List of selected tickets from those in the cartItemGroup.
         *
         * @type {{ticket: DigiTickets.Ticket, qty: Number}[]|*}
         */
        let existingTickets = getTicketQtysInGroup(cartItemGroup);

        // Make it so the currently chosen session is pre-selected in the session modal.
        $scope.eventSessionPreference[cartItemGroup.event.ID] = cartItemGroup.session;

        let selectedTickets = await showSessionModal(
            existingTickets,
            cartItemGroup.event,
            // Add the spaces used by this group back to the number of available spaces.
            sessionSpaceAdjustments
        );

        // selectedTickets is an array in the same order as the existingTickets we passed in. Therefore we can
        // use that same order to get the lineNumber from existingTickets and update the relevant cart lines.
        $timeout(() => {
            selectedTickets.forEach(({ price, session }, index) => {
                let { lineNumber } = existingTickets[index];
                let cartLine = $scope.cart.getLineByNumber(lineNumber);
                cartLine.session = session;
                // Pricing rules may have applied so update the price too.
                cartLine.setPrice(price);

                // After changing the session set that new session as the preferred one for this event.
                $scope.eventSessionPreference[session.eventID] = session;
            });

            cartService.itemsChanged();
        });
    };

    /**
     * @param {CartService} cart
     * @param {DigiTickets.CartItem} cartItem
     * @param {string} [requestedTabName]
     */
    $scope.showItemEditModal = function showItemEditModal(cart, cartItem, requestedTabName) {
        ModalFactory.open(
            'item-edit',
            ItemEditCtrl,
            'partials/modals/itemEdit.html',
            {
                cart: () => cart,
                cartItem: () => cartItem,
                requestedTabName: () => requestedTabName || 'ITEM_EDIT.ITEM_DETAILS'
            }
        );
    };

    /**
     * Display the popup to add/edit gift voucher data.
     * In $scope because it's opened when clicking a gift voucher in the cart.
     *
     * @param {string} action
     * @param {DigiTickets.CartItem} cartItem
     */
    $scope.showSoldGiftVoucherDataModal = async (action, cartItem) => new Promise((resolve, reject) => {
        const modalInstance = $modal.open({
            templateUrl: 'partials/modals/soldGiftVoucherDataModal.html',
            controller: SoldGiftVoucherDataModalCtrl,
            keyboard: false,
            windowClass: 'in sold-gift-voucher-data-window',
            resolve: {
                action: function () {
                    return action;
                },
                giftVoucher: function () {
                    return cartItem.getItem();
                },
                price: function () {
                    return cartItem.getPrice();
                },
                soldGiftVoucherData: function () {
                    // Provide a clone of the model to the modal so it does not persist changes while editing.
                    return cloneDeep(cartItem.soldGiftVoucherData);
                }
            }
        });

        modalInstance.result.then(
            (response) => {
                cartItem.customPrice = response.price;
                cartItem.setPrice(response.price);
                cartItem.soldGiftVoucherData = response.soldGiftVoucherData;
                resolve();
            },
            () => reject()
        );
    });

    const showPriceEditModal = async (currentCartItem, itemQuantity) => new Promise((resolve, reject) => {
        const modalInstance = $modal.open({
            templateUrl: 'partials/modals/priceEditor.html',
            controller: PriceEditorCtrl,
            keyboard: false,
            windowClass: 'in price-edit-window',
            resolve: {
                cartItem: function cartItem() {
                    return currentCartItem;
                },
                quantity: function quantity() {
                    return itemQuantity;
                }
            }
        });

        modalInstance.result.then(
            /**
             *
             * @param {{cartItem: DigiTickets.CartItem}} data
             */
            (data) => {
                Logger.info(
                    'Price adjusted',
                    {
                        itemID: data.cartItem.getId(),
                        qty: data.cartItem.getQuantity()
                    }
                );
                resolve();
            },
            () => reject()
        );
    });

    /**
     * A membership plan is being added to the cart. Display the modal to enter info for this new membership.
     * This adds a new MembershipDataset to the line.
     *
     * @param {DigiTickets.CartItem} cartItem
     *
     * @return {Promise}
     */
    $scope.displayNewMembershipDatasetModal = async (cartItem) => $scope.ensureHaveCustomerAccount()
        .then(() => {
            // Clone the array of membershipDatasets already on this cartItem.
            // This is so if the modal is cancelled the cartItem's array is not modified.
            // (The modal itself will handle cloning the object within the array so they are not modified
            // during editing)
            let membershipDatasets = cartItem.membershipDatasets.slice(0);

            // Add one more dataset to the datasets for the new instance.
            membershipDatasets.push(
                new DigiTickets.MembershipDataset(cartItem.getItem())
            );

            return new Promise((resolve, reject) => {
                // Show the membership datasets modal to allow entering the new plan data.
                MembershipManagementActionsService.editDatasets(
                    'add-new',
                    cartItem.getItem(),
                    membershipDatasets,
                    $scope.cart.customer.account,
                    function (filledMembershipDatasets) {
                        if (filledMembershipDatasets) {
                            cartItem.setMembershipDatasets(filledMembershipDatasets);
                            resolve();
                        } else {
                            reject();
                        }
                    }
                );
            });
        });

    /**
     * Edit the datasets for a cart line.
     *
     * @param {DigiTickets.CartItem} cartItem
     * @param $event
     */
    $scope.editMembershipPlan = function editMembershipPlan(cartItem, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        MembershipManagementActionsService.editDatasets(
            'edit-new',
            cartItem.getItem(),
            cartItem.membershipDatasets,
            $scope.cart.customer.account,
            function (filledMembershipDatasets) {
                if (filledMembershipDatasets) {
                    cartItem.setMembershipDatasets(filledMembershipDatasets);
                }
            }
        );
    };

    $scope.showCartOffers = function showCartOffers() {
        let modalInstance = $modal.open({
            backdrop: 'static',
            controller: CartOffersCtrl,
            templateUrl: 'partials/modals/cartOffers.html',
            windowClass: 'in cart-offers-window'
        });

        modalInstance.result.then(
            function (data) {
                Logger.info(
                    'Selected cart offers',
                    {
                        offerIds: data.offerIds
                    }
                );

                // OK
                $scope.cart.setManualOfferIDs(data.offerIds);
            },
            function () {
                // Cancel
            }
        );
    };

    /**
     * @param {string} [errorMessage]
     *
     * @return {Promise<{customer: Customer}>}
     */
    $scope.showCustomerDetails = function (errorMessage) {
        return ModalFactory.open(
            'customer-details',
            CustomerDetailsCtrl,
            'partials/modals/customerDetails.html',
            {
                // Provide a clone of the current customer so it does not get modified until
                // OK is pressed.
                customer: () => cloneDeep($scope.cart.customer),
                errorMsg: () => errorMessage
            }
        ).then((result) => {
            // Use the returned customer.
            $scope.cart.customer = result.customer;

            // Use the returned field data, which is an object containing keys and values.
            // We need to set that on the FieldInstance objects in the cart. But we don't want to touch
            // values of 'ask early' fields because they would not have been shown.
            setFieldValuesOnCart(
                result.fieldValues,
                $scope.cart.currentCart,
                FieldInstanceFilter.NON_ASK_EARLY_FIELDS_ONLY,
                true // Clear existing values for instances and only use the new values.
            );

            // Recalculate cart offers
            $scope.cart.itemsChanged();

            return result;
        });
    };

    $scope.showOrderNotes = function showOrderNotes() {
        ModalFactory.open(
            'order-notes',
            OrderNotesCtrl,
            'partials/modals/orderNotes.html'
        );
    };

    /**
     * The currently selected tab (Tickets/Products/etc.)
     *
     * @type {string}
     */
    $scope.activeItemTab = 'tickets';

    /**
     * Show the item search box? (Currently only implemented for products)
     *
     * @type {boolean}
     */
    $scope.showItemSearch = false;

    /**
     * Method to set the active tab. The tab code is passed in. Each active state
     * is set to false, except the one for that tab, which is set to true.
     *
     * @param {string} tabName
     */
    $scope.setActiveItemTab = function setActiveItemTab(tabName) {
        if (tabName) {
            $scope.activeItemTab = tabName;
        } else {
            // Default to tickets tab.
            $scope.activeItemTab = 'tickets';
        }

        let showItemSearchFor = [
            'products',
            'foodAndDrink',
        ];
        $scope.showItemSearch = showItemSearchFor.indexOf($scope.activeItemTab) !== -1;
    };

    // Use the landing page code to determine which tab to make active by default.
    // It assumes "tickets" if the code is either not set or is invalid.
    let defaultActiveItemTab = LandingPage.getSecondary(CurrentDevice.device.eposLandingPageKey);
    $scope.setActiveItemTab(defaultActiveItemTab);

    const showMixedPaymentPatternError = () => {
        Notification.error(
            $filter('lang')('SELL.CANNOT_MIX_PAYMENT_PATTERNS')
        );
    };

    $scope.debouncedSetOrderInProgressCart = _.debounce(
        function (cart) {
            OrderInProgressStasher.setCart(cart);
        },
        100
    );

    $scope.cart.onChange(function () {
        $scope.debouncedSetOrderInProgressCart($scope.cart);
    });

    $scope.setupFromSellState = function setupFromSellState(sellState) {
        console.log('setupFromSellState', sellState);

        if (sellState.initialItems.length > 0) {
            for (let i = 0; i < sellState.initialItems.length; i++) {
                sellState.initialItems[i].cartItem.setCart($scope.cart);
                $scope.cart.add(
                    sellState.initialItems[i].cartItem
                ).adjustQuantity(
                    sellState.initialItems[i].qty
                );
            }
        }

        if (sellState.customerAccount) {
            $scope.cart.customer.setAccount(sellState.customerAccount);
        }

        if (sellState.payNow === true && !$scope.cart.isEmpty()) {
            $scope.payButtonClick();
        }
    };

    $scope.init = async function init() {
        if (cartService.nextCart) {
            cartService.useNextCart();
        } else if (sellStateService.hasNextState()) {
            // On load, if a SellState was provided by the SellStateService set up the Cart / Sell page appropriately.
            $scope.sellState = sellStateService.pullNextState();
            console.log('Setting up from Sell State', $scope.sellState);

            $scope.setupFromSellState($scope.sellState);
        } else if (OrderInProgressStasher.hasOrderInProgress()) {
            // On load, see if there was an cart/order in progress, possibly with some payments already taken.
            // If so we need to re-populate the cart with the order, and maybe pop-up the payment modal
            // and continue with the operation.
            // Clone to a new object.
            let orderInProgress = hydrator.hydrate(
                OrderInProgressStasher.orderInProgress,
                new DigiTickets.OrderInProgress()
            );
            console.log('Setting up from Order In Progress', orderInProgress);

            if (orderInProgress.stashedCart) {
                await $scope.cart.populateFromStash(
                    orderInProgress.stashedCart
                );
            }

            if (orderInProgress.transactionInProgress) {
                $scope.cart.setTransactionInProgress(
                    orderInProgress.transactionInProgress
                );
            }

            $timeout(
                function () {
                    if (orderInProgress.state === 'pay') {
                        console.log('Order In Progress was at payment stage - going to pay.');

                        // Open the payment window.
                        // This skips the call to calculate offers you get when clicking the pay button.
                        pay();
                    }
                },
                500
            );
        } else {
            // $scope.sellState was already set to the default state at the top.
            $scope.setupFromSellState($scope.sellState);
        }
    };

    $timeout($scope.init, 50);

    /**
     * Functions for formatting dates/times in the view.
     * (Moved out of CartLine model.)
     */

    $scope.formatDate = (date, format = 'ddd Do MMMM YYYY') => {
        const currentDate = (new Date()).toYMD();
        const sessionDate = date.toYMD();
        if (currentDate === sessionDate) {
            return 'Today';
        }

        return moment(date).format(format);
    };

    $scope.formatTime = (date) => {
        if (date instanceof Date) {
            return date.getHoursWithZeros() + ':' + date.getMinutesWithZeros();
        }

        return '00:00';
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SellCtrl;
}
