const angular = require('angular');

/**
 * @param $window
 * @param {DigiTickets.RfidScanDetector} RfidScanDetectorService
 */
const rfidListenerDirective = function ($window, RfidScanDetectorService) {
    return {
        link: function link(scope) {
            angular.element($window).on('keydown', function (event) {
                RfidScanDetectorService.keyDownReceived(event, scope);
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = rfidListenerDirective;
}
