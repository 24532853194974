const angular = require('angular');

const keydownListenerDirective = function ($window) {
    return {
        link: function link(scope) {
            angular.element($window).on('keydown', function (event) {
                // Namespacing with name of directive + event to avoid collisions
                scope.$broadcast('keydownListener::keydown', event);
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = keydownListenerDirective;
}
