const addressFilter = function (address) {
    if (!address || typeof address !== 'object') {
        return '';
    }

    if (address.formattedAddress) {
        return address.formattedAddress;
    }

    return address.house + ' '
        + address.street + ' '
        + address.town + ' '
        + address.postcode;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = addressFilter;
}
