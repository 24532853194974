const Category = require('@/lib/Categories/Category');
const GiftAidRate = require('./GiftAidRate');
const { toBool, toNullableBool, toFloat, toNullableFloat, toInt } = require('../functions/transform');

DigiTickets.Product = function Product() {
    this.allowPriceEdit = false;
    this.barcode = '';
    this.category = {};

    /**
     * @type {Number}
     */
    this.catID = null;

    this.code = '';
    this.giftAid = false;
    /**
     * @type {?GiftAidRate}
     */
    this.giftAidRate = null;
    this.ID = 0;
    this.isFood = false;
    this.showOnKitchenScreen = false;
    this.itemType = DigiTickets.ItemType.PRODUCT;
    this.name = '';
    this.offlinePrice = null;
    this.paymentPattern = null;
    this.price = 0.00;
    this.shortName = '';
    this.navOrder = 10000;
    this.requiresDelivery = false;
};

DigiTickets.Product.prototype = {
    /**
     * @returns {string}
     */
    getName: function getName() {
        // use shortName in epos by default if available
        return this.shortName ? this.shortName : this.name;
    },

    /**
     * @returns {Number}
     */
    getPrice: function getPrice() {
        return parseFloat(this.offlinePrice ? this.offlinePrice : this.price);
    },

    /**
     * @returns {boolean}
     */
    isSessionBased: function isSessionBased() {
        return false;
    },

    afterHydration: function afterHydration() {
        // if allowPriceEdit is null, inherit whatever is set on the category
        this.allowPriceEdit = (this.allowPriceEdit === null) ? (this.category ? this.category.allowPriceEdit : false) : this.allowPriceEdit;
    },

    /**
     * @return {object}
     */
    getHydrationMap() {
        return {
            allowPriceEdit: toNullableBool,
            barcode: {},
            category: {
                field: ['category', 'categories'],
                model: Category
            },
            catID: toInt,
            code: {},
            giftAid: toBool,
            giftAidRate: {
                model: GiftAidRate
            },
            ID: {
                field: ['ID', 'itemID'],
                transform: toInt
            },
            isFood: toBool,
            name: {},
            navOrder: toInt,
            offlinePrice: toNullableFloat,
            paymentPattern: {},
            price: toFloat,
            shortName: {},
            showOnKitchenScreen: toBool,
            requiresDelivery: toBool
        };
    }
};
