const PrintDriverRefs = Object.freeze({
    AGENT: 'agent',
    DUMMY: 'dummy',
    PRINT: 'print',
    STAR_2_INCH: 'star_brwsr_2_inch',
    STAR_3_INCH: 'star_brwsr_3_inch',
    PRINTER_APP: 'printer_app'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintDriverRefs;
}
