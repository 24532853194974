const scrollToBottomDirective = function ($timeout) {
    return {
        restrict: 'A',
        scope: {
            dtScrollToBottom: '='
        },
        link: function ($scope, element) {
            // When the collection changes scroll the element to the bottom.
            $scope.$watchCollection(
                'dtScrollToBottom',
                function () {
                    $timeout(function () {
                        $(element).scrollTop($(element)[0].scrollHeight);
                    }, 0);
                }
            );
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = scrollToBottomDirective;
}
