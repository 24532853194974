/**
 * This class prevents repetition and the controllers from having to do too much with notifications.
 * Other generic notification functionality could eventually be moved to here.
 *
 * @param $filter
 * @param Notification
 */
DigiTickets.NotificationService = function ($filter, Notification) {
    this.$filter = $filter;
    this.notification = Notification;
};

DigiTickets.NotificationService.prototype = {
    /**
     * @param {string} langString Key from the language file to display.
     * @param {boolean} [isRawMessage]
     * @param {boolean} [persist]
     */
    error: function error(langString, isRawMessage, persist) {
        let options = {
            message: isRawMessage ? langString : this.$filter('lang')(langString)
        };

        if (persist) {
            options.delay = null;
        }

        this.notification.error(options);
    }
};
