const { toInt } = require('../functions/transform');

DigiTickets.Country = function () {
    /**
     * 2 letter country code e.g. GB/US/CA etc.
     * Often also referred to as countryCode.
     *
     * @type {string}
     */
    this.code = '';

    /**
     * The ID of the country in the DigiTickets database countries table.
     *
     * @type {number}
     */
    this.ID = null;

    /**
     * Human readable country name.
     *
     * @type {string}
     */
    this.name = '';
};

DigiTickets.Country.prototype = {
    getHydrationMap() {
        return {
            code: {},
            ID: {
                field: ['ID', 'countryID'],
                transform: toInt
            },
            name: {}
        };
    }
};
