const { cloneDeep } = require('../../../functions/clone');
const { getFieldValuesFromCart } = require('../CustomFields/fieldFunctions');
const { removeEmptyValues } = require('../../../functions/objects');
const { removeNullValues } = require('../../../functions/functions');
const { unflatten } = require('digitickets.flatten-js');

/**
 * @param {CartLineSplitter} cartLineSplitter
 * @param {CartToReservationStructureMapper} cartToReservationStructureMapper
 * @param {CurrentDevice} CurrentDevice
 * @param {MembershipService} membershipService
 * @param {PaymentService} paymentService
 * @param {UserService} UserService
 */
const CartToConfirmationStructureMapper = function (
    cartLineSplitter,
    cartToReservationStructureMapper,
    CurrentDevice,
    membershipService,
    paymentService,
    UserService
) {
    /**
     * @type {CartLineSplitter}
     */
    this.cartLineSplitter = cartLineSplitter;

    /**
     * @type {CartToReservationStructureMapper}
     */
    this.cartToReservationStructureMapper = cartToReservationStructureMapper;

    /**
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;

    /**
     * @type {MembershipService}
     */
    this.membershipService = membershipService;

    /**
     * @type {PaymentService}
     */
    this.paymentService = paymentService;

    /**
     * @type {UserService}
     */
    this.userService = UserService;
};

CartToConfirmationStructureMapper.prototype = {
    /**
     * Convert a cart to the structure required for the order creation API endpoint.
     *
     * @param {CartService} cartService
     */
    map(cartService) {
        // We need to record when the EPOS (thinks it) created the order, not leave
        // it to the server to decide - might be hours later that it receives it.
        let createdDate = new Date();

        cartService.setDefaultCustomerCountry();

        // We include all the membership renewals in a separate data block ("renewingMemberships") here instead
        // of in the order lines because the order lines are sent ahead as a reservation, and we'd have to do
        // a huge amount of work to add 1 or 2 columns/attributes to the table/models.
        let structure = {
            customer: cartService.customer.account ? null : cartService.customer.toServerData(),
            customerAccountID: cartService.customer.account ? cartService.customer.account.ID : null,
            // TODO: (BAC-3029) Send date as ISO-8061, but ensure API supports this first.
            // date: createdDate.toISOString(),
            date: createdDate.toYMDHMS(),
            deviceID: this.currentDevice.isSet() ? this.currentDevice.getDeviceId() : null,
            fieldData: this.getFieldValuesFromCartForApi(cartService.currentCart),
            // TODO: find out if with robust validation in the API will we be able to remove this?
            // Force the order to pass/skip validation on the API
            force: 1,
            giftaid: cartService.shouldGiftAid() ? 1 : 0,
            memberships: this.membershipService.getDataForServer(cartService.itemList),
            notes: cartService.notes,
            paid: 1,
            paidAt: createdDate,
            payments: this.paymentService.getPaymentsFromCartForApi(cartService),
            renewingMemberships: cartService.exportRenewingMembershipData(),
            requestAutoRedeem: 1,
            reservationToken: cartService.reservation && cartService.reservation.token ? cartService.reservation.token : null,
            staffRef: cartService.currentCart.staffRef,
            // TODO: Replace stashedCartGuid with thirdPartyRef
            stashedCartGuid: cartService.currentCart.stashedCartGuid,
            thirdPartyID: cartService.currentCart.thirdPartyID,
            thirdPartyRef: cartService.currentCart.thirdPartyID,
            total: cartService.getTotal()
        };

        if (!cartService.customer.deliveryAddress.isEmpty()) {
            structure.deliveryAddress = cartService.customer.deliveryAddress.toServerData();
        }

        return removeNullValues(structure);
    },

    /**
     * Produce an object in the structure specified in OE-107
     * for sending to the API endpoint to create an order adjustment.
     *
     * @param {CartService} cartService
     *
     * @return {{orderID: number, newOrderData: *}}
     */
    mapOrderAdjustment(cartService) {
        if (!cartService.originalOrder) {
            throw new Error('There is no originalOrder in the cart.');
        }

        let confirmationStructure = this.map(cartService);
        confirmationStructure.lines = this.cartToReservationStructureMapper.mapLines(
            cartService
        );

        // Add adjustedOrderID to order data.
        confirmationStructure.adjustedOrderID = cartService.originalOrder.ID;

        // Remove payments because the order adjustment endpoint does not handle them.
        delete confirmationStructure.payments;

        return confirmationStructure;
    },

    /**
     * Return the field values from the cart in the structure expected by the API.
     *
     * @param {Cart} cart
     *
     * @return {Object<string, string|number|number[]>}
     */
    getFieldValuesFromCartForApi(cart) {
        let lines = this.cartLineSplitter.splitCartLinesForApi(cart);

        let cartWithSplitLines = cloneDeep(cart);
        cartWithSplitLines.itemList = lines;

        return this.convertFieldValuesForApi(
            getFieldValuesFromCart(cartWithSplitLines),
            lines
        );
    },

    /**
     * Return the field values from the cart in the structure expected by the API.
     *
     * When sending the values to the API we don't send: item.~1.~1.~100: value
     * we have to send: item[1][1][100]: value
     * We also need to replace the line numbers used in that array to not be the 'lineNumber' property on the lines
     * but instead be the index of the line in the array of lines.
     *
     * @param {Object<string, string|number|number[]>} fieldValues
     * @param {DigiTickets.CartItem[]} lines
     *
     * @return {Object}
     */
    convertFieldValuesForApi(fieldValues, lines) {
        removeEmptyValues(fieldValues);

        // Convert fieldValues['item.~1.~1.~100'] to fieldValues['item'][1][1][100]
        fieldValues = unflatten(fieldValues, null, null, '.', false);

        // Mapping of the 'lineNumber' property in the cart to the index of that line in the lines array sent
        // to the API. The API expects the "line numbers" for field data to actually be an index.
        let lineIndexes = {};
        Object.keys(lines).forEach((key) => {
            let line = lines[key];
            lineIndexes[line.lineNumber] = key;
        });

        let adjustedFieldValues = {};

        if (fieldValues.order) {
            // Just put the order level data directly in the result because we don't need to do anything with it.
            adjustedFieldValues.order = fieldValues.order;
        }

        if (fieldValues.line) {
            adjustedFieldValues.line = {};
            // Line level field data needs its lineNumber adjusting.
            Object.keys(fieldValues.line).forEach((oldLineNumber) => {
                // Get the new line number.
                let lineIndex = lineIndexes[oldLineNumber];
                adjustedFieldValues.line[lineIndex] = cloneDeep(fieldValues.line[oldLineNumber]);
            });
        }

        return adjustedFieldValues;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartToConfirmationStructureMapper;
}
