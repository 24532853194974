const _ = require('lodash');

/**
 * @param $modalInstance
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 * @param {DigiTickets.RfidScanDetector} RfidScanDetectorService
 * @param {UserService} UserService
 * @param {UserSwitcher} userSwitcher
 */
const SwitchUserCtrl = function (
    $modalInstance,
    $rootScope,
    $scope,
    $timeout,
    NumPadFactory,
    RfidScanDetectorService,
    UserService,
    userSwitcher
) {
    $scope.numPad = NumPadFactory.create(
        {
            allowNull: true,
            initialValue: null,
            showDecimalButton: false,
            showSignButton: false,
            numDecimalPlaces: 0,
            ok: function () {
                // The timeout is to allow the RFID handler to pickup the keydowns first if appropriate.
                $timeout(function () {
                    let pin = $scope.numPad.getValue();
                    if (pin) {
                        $scope.debouncedHandleSwitchUserAuth('pin', String(pin));
                    }
                }, 10);
            }
        }
    );

    $scope.rfidScanDetector = RfidScanDetectorService;

    $scope.pin = {
        focus: false,
        valid: true
    };
    $scope.switchUserMessage = null;

    $scope.ok = function ok() {
        // It is important that if the user okays the pop-up, that we only look for
        // a matching PIN, and not an RFID tag. The latter is handled by the listener
        // and its broadcast event.
        let pin = $scope.numPad.getValue();
        if (pin) {
            // Note: String(null) will produce a literal string "null", so the casting to a string happens here
            // instead of when getting the numPad value.
            $scope.debouncedHandleSwitchUserAuth('pin', String(pin));
        } else {
            $scope.setError('Please enter a PIN or scan an RFID tag.');
        }
    };

    // Check for a RFID tag being scanned. It attempts to switch user using that Tag.
    $scope.$on('RfidTagScanned', function (event, response) {
        // Scanning the RFID will trigger a bunch of keydown events then the enter key.
        // The NumPad is picking up those keydown events, filling its input and then trying to search by PIN.
        // To combat this there is a small timeout in the ok() handler for the numpad above to day that PIN search
        // triggering. If an RFID tag was scanned we clear the NumPad's input, making the ok() do nothing.
        $scope.numPad.clear();

        // Similarly to above, we only look for a matching RFID tag, and not a PIN.
        $scope.debouncedHandleSwitchUserAuth('rfid-tag', response.code);
    });

    $scope.logout = function logout() {
        UserService.logout();
    };

    /**
     * Debounce instance of the above function.
     *
     * @param {string} method - 'pin', 'rfid-tag', plus any future values.
     * @param {string} enteredValue The PIN, Tag Id, etc.
     */
    $scope.handleSwitchUserAuth = async function handleSwitchUserAuth(method, enteredValue) {
        if (!enteredValue) {
            $scope.setError('Please enter a PIN or scan an RFID tag.');
            return;
        }

        $rootScope.setRootProcessingMessage('SWITCH_USER.PROCESSING_SWITCH');
        userSwitcher
            .switchUser(method, enteredValue)
            .then(() => {
                // Switched.
                $modalInstance.dismiss('ok');
            })
            .catch((error) => {
                console.log('Failed to switch user', error);
                if (error instanceof Error) {
                    $timeout(() => {
                        $scope.setError(error.message);
                    });
                }
            })
            .then(() => {
                $rootScope.setRootProcessingMessage();
            });
    };

    $scope.debouncedHandleSwitchUserAuth = _.debounce($scope.handleSwitchUserAuth, 100);

    $scope.setError = function setError(errorMessage) {
        $scope.switchUserMessage = errorMessage;
        if (errorMessage === null) {
            $scope.pin.valid = true;
        } else {
            $scope.pin.valid = false;
            $scope.setFocus();
        }
    };

    /**
     * Method to set the flag that says "focus on the PIN field in the modal"
     * for a short time. The dtFocusMe directive and the attribute on the field
     * combine to set focus. We have to set it back to false just after so that
     * we can trigger it again (eg when they click Ok and the PIN is invalid).
     */
    $scope.setFocus = function setFocus() {
        $scope.pin.focus = true;
        $timeout(function () {
            $scope.pin.focus = false;
        });
    };

    $scope.setFocus();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SwitchUserCtrl;
}
