const Customer = require('../../../models/Customer');
const ErrorBag = require('../Errors/ErrorBag');
const FieldGroup = require('../CustomFields/FieldGroup');
const FieldLevel = require('../CustomFields/FieldLevel');
const Reservation = require('../Reservations/Reservation');
const { cloneShallow } = require('../../../functions/clone');
const { toBool, toNullableBool, toDate, toNullableFloat, toInt, toNullableString } = require('../../../functions/transform');

/**
 * WIP splitting up the Cart model (the data) from the CartService (the logic).
 * The properties are copied from CartService where it is something to do with the data in the cart rather than to do
 * with processing the cart. The methods are copied from CartService where it does something simple like getting or
 * setting something with no side effects.
 *
 * This should contain no dependencies so that it can be serialized/unserialized (in JSON or stored in IndexedDB).
 */
const Cart = function () {
    /**
     * A hash of the current contents of the cart.
     *
     * @see this.getContentHash()
     *
     * Not yet implemented for Cart model!
     *
     * @type {null}
     */
    this.contentHash = null;

    /**
     * @type {Customer}
     */
    this.customer = new Customer();

    /**
     * @type {?string}
     */
    this.notes = null;

    /**
     * A human readable reference entered by the user for this order (e.g. "Table 123").
     * Gets shown on the kitchen screen, and printed on the receipt.
     *
     * @type {?string}
     */
    this.staffRef = null;

    /**
     * Number of payments that have been attempted for this cart.
     * Gets incremented each time a new card transaction is attempted. The number is used to build a
     * unique thirdPartyID for payments.
     *
     * @type {number}
     */
    this.paymentsAttempted = 0;

    /**
     * A number (but non-sequential) ID for this cart/order generated on the device, long before the order is sent
     * to the server and has an order ID / bookingRef generated. This is saved with the order on the server
     * and also forms part of the thirdPartyID for payments associated with this order.
     *
     * NOTE: thirdPartyID is not unique in the database hence the creation of thirdPartyRef
     *
     * This can be used as a unique key for stashed carts / held orders.
     *
     * @type {?string}
     */
    this.thirdPartyID = null;

    /**
     * A number (but non-sequential) ID for this cart/order generated on the device, long before the order is sent
     * to the server and has an order ID / bookingRef generated.
     * This is saved with the order on the server in a unique column
     * and also forms part of the thirdPartyID for payments associated with this order.
     *
     * This can be used as a unique key for stashed carts / held orders.
     *
     * @type {?string}
     */
    this.thirdPartyRef = null;

    /**
     * If the contents of the cart came from a stashed order remember its guid here in case it gets re-stashed.
     * That way we update the existing stash on the server (and kitchen screen) instead of creating a new one.
     *
     * TODO: Replace with this.thirdPartyID
     *
     * @type {?string}
     */
    this.stashedCartGuid = null;

    /**
     * Remembers whether the operator wanted to ignore the fact this cart shared a ref with another stashed cart
     *
     * @type {boolean}
     */
    this.ignoreDuplicateRef = false;

    /**
     * If the current cart came from a stashed cart and it had been sent to the kitchen this will be set.
     *
     * @type {?Date}
     */
    this.fulfillmentCreatedAt = null;

    /**
     * If the current cart came from a stashed cart and it had been sent to the kitchen this will be set
     * as a KitchenOrder representation of the cart at the point at which it was un-stashed.
     *
     * This is used if the cart is re-held so EPOS can print a kitchen receipt showing what has changed.
     * The kitchen screen also prints the changes but that is handled separately.
     *
     * @type {?KitchenOrder}
     */
    this.restoredKitchenOrder = null;

    /**
     * Key value pairs of FieldInstance keys and values
     *
     * @type {Object}
     */
    this.fieldData = {};

    /**
     * Order level field instances.
     *
     * @type {FieldGroup}
     */
    this.fields = new FieldGroup({ level: FieldLevel.ORDER });

    /**
     * @type {DigiTickets.CartItem[]}
     */
    this.itemList = [];

    /**
     * @type {?Reservation}
     */
    this.reservation = null;

    /**
     * @type {ErrorBag}
     */
    this.errors = new ErrorBag();

    /**
     * @type {boolean}
     */
    this.balancePaid = false;

    /**
     * Payments that have been made.
     *
     * @type {DigiTickets.Payment[]}
     */
    this.payments = [];

    /**
     * Gift Aid declaration - should Gift Aid be claimed for the order?
     *
     * @type {?boolean} Null if not answered yet. Boolean if answered.
     */
    this.giftAid = null;

    /**
     * Use the increased Gift Aid prices on applicable items? (Possible without giftAid being true)
     * This choice is not implemented for the customer, but is here to provide the same functionality as
     * the DigiTickets frontend, should it be desired later.
     *
     * @type {?boolean} Null if not answered yet. Boolean if answered.
     */
    this.giftAidPrices = null;

    /**
     * Used if this order refers to a previous order (as is the case when paying the remaining balance).
     * Also used when editing an order. You can use the this.isEditingOrder flag to tell the difference.
     *
     * @type {?DigiTickets.Order}
     */
    this.originalOrder = null;

    /**
     * When editing an order this is the difference in total cost between the original order and the current
     * cart contents.
     * In general it will be 0 if nothing has changed, positive if things are added, negative if thing are removed.
     * This should be used for display purposes only and not for calculations.
     *
     * @type {number}
     */
    this.originalOrderDifference = 0.00;

    /**
     * Is this Cart modifying an existing order?
     * Note this is not true when paying the remaining balance for an order. Only if doing a full order edit.
     *
     * @type {boolean}
     */
    this.isEditingOrder = false;

    /**
     * The offer(s) that the operator has selected for the entire cart from the Discounts modal.
     * The keys are offerIDs and the values are the number of times that offer can be applied (if applicable).
     *
     * @type {Object<number>}
     */
    this.selectedManualOfferIds = {};

    /**
     * If this cart came from a transaction that had been abandoned and recovered, remember the stored
     * TransactionInProgress info. This is useful for the payment modal to select the appropriate payment
     * method and resume the transaction.
     *
     * @type {?DigiTickets.TransactionInProgress}
     */
    this.transactionInProgress = null;

    // TODO: totals

    /**
     * This was originally added for use with order editing. It would be the difference in total cost between the
     * original order and the current order total, provided by the /cartcalculations API.
     * It is no longer used for that but may be useful for future.
     *
     * @type {Number|null}
     */
    this.customTotal = null;

    /**
     * An array of field instance keys that were shown the last time the custom fields modal was opened.
     * The purpose is so we can automatically display the fields again if the available fields have changed
     * since they were last shown.
     *
     * @type {?string[]}
     */
    this.lastPresentedCustomFieldKeys = null;

    /**
     * This holds some configuration for the Sell screen - can items be removed, what type of items can be added, etc.
     *
     * The only part of this currently implemented is where to redirect the user after
     * completing the sale (used for order editing).
     *
     * The existing SellState object is being reused here because it already contains the properties we would
     * want to use.
     *
     * @type {?DigiTickets.SellState}
     */
    this.sellState = null;
};

Cart.prototype = {
    /**
     * @param {DigiTickets.CartItem} cartItem
     */
    addItem(cartItem) {
        this.itemList.push(cartItem);
    },

    /**
     * @returns {DigiTickets.CartItem[]}
     */
    getItems() {
        return this.itemList;
    },

    /**
     * Return an array containing a clone of each item in the cart so changes can be made to those items without
     * affecting the cart.
     *
     * @returns {DigiTickets.CartItem[]}
     */
    exportItems() {
        return this.itemList.map((item) => item.clone());
    },

    /**
     * Return a line from the cart by its ID.
     * This ID is generated by CartItem.getID. See there for what it is comprised of.
     *
     * @param {string} id
     *
     * @returns {?DigiTickets.CartItem}
     */
    getLineById(id) {
        return this.itemList.find((line) => line.getId() === id) || null;
    },

    /**
     * Return a line from the cart by its line number.
     *
     * @param {number|string} lineNumber
     *
     * @returns {?DigiTickets.CartItem}
     */
    getLineByNumber(lineNumber) {
        return this.itemList.find((line) => line.lineNumber === lineNumber) || null;
    },

    getLastItem() {
        return this.itemList.length > 0 ? this.itemList[this.itemList.length - 1] : null;
    },

    /**
     * Returns the total number of items in the cart (sum of the qty per line)
     */
    getTotalQty() {
        return this.itemList.reduce((totalQty, line) => totalQty + line.getQuantity(), 0);
    },

    /**
     * Check if the cart contains any items that should be displayed on the kitchen screen.
     * (Disables the send to kitchen button in the hold modal if not).
     *
     * @return {boolean}
     */
    containsKitchenItems() {
        return !!this.itemList.find((i) => i.getItem().showOnKitchenScreen && i.quantity > 0);
    },

    /**
     * @return {boolean}
     */
    containsMemberships() {
        return !!this.itemList.find((i) => i.isMembershipPlan());
    },

    /**
     * @return {boolean}
     */
    containsOrderBalanceItem() {
        return !!this.getOrderBalanceLine();
    },

    /**
     * @return {?DigiTickets.CartItem}
     */
    getOrderBalanceLine() {
        return this.itemList.find((i) => i.getItemType() === DigiTickets.ItemType.ORDER_BALANCE) || null;
    },

    /**
     * @return {?DigiTickets.Order}
     */
    getOriginalOrderHavingBalancePaid() {
        let line = this.getOrderBalanceLine();
        if (!line) {
            return null;
        }
        /** @type {OrderBalanceItem} */
        let item = line.getItem();

        return item.getOriginalOrder();
    },

    getCurrentPaymentPattern() {
        // Items being added to the cart must either match the payment period of items already
        // in the cart (if any have a payment period), or be free
        // Checking the price is greater than 0 has the side effect of not counting refunds, which is good.
        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].getLineTotal() > 0) {
                let paymentPattern = this.itemList[i].getItem().paymentPattern;
                if (paymentPattern) {
                    return paymentPattern;
                }
            }
        }

        return null;
    },

    /**
     * @returns {?number}
     */
    getOriginalOrderTotal() {
        return this.originalOrder ? this.originalOrder.total : null;
    },

    getHydrationMap() {
        return {
            balancePaid: toBool,
            contentHash: toNullableString,
            customer: {
                model: Customer
            },
            customTotal: toNullableFloat,
            errors: {
                model: ErrorBag
            },
            fieldData: {},
            fields: {
                model: FieldGroup
            },
            fulfillmentCreatedAt: toDate,
            giftAid: toNullableBool,
            giftAidPrices: toNullableBool,
            ignoreDuplicateRef: toBool,
            isEditingOrder: toBool,
            itemList: {
                modelCollection: DigiTickets.CartItem
            },
            lastPresentedCustomFieldKeys: {},
            notes: toNullableString,
            originalOrder: {
                model: DigiTickets.Order
            },
            payments: {
                modelCollection: DigiTickets.Payment
            },
            paymentsAttempted: toInt,
            reservation: {
                model: Reservation
            },
            selectedManualOfferIds: {},
            sellState: {
                model: DigiTickets.SellState
            },
            staffRef: toNullableString,
            stashedCartGuid: toNullableString,
            thirdPartyID: toNullableString,
            thirdPartyRef: toNullableString,
            transactionInProgress: {
                model: DigiTickets.TransactionInProgress
            }
        };
    },

    /**
     * @return {Cart}
     */
    toSerializable() {
        // This used to do a cloneDeep of the Cart but it has lots of properties so it takes too long on low-spec
        // hardware. Instead just do a cloneShallow of the cart so we can modify its properties below,
        // and a shallow clone of anything within the cart we are going to modify below.
        /** @type {Cart} clone */
        let clone = cloneShallow(this);

        if (clone.originalOrder) {
            clone.originalOrder = cloneShallow(clone.originalOrder);
            // Trying to store the order.fields property (which is a FieldGroupCollection instance) in IndexedDB
            // causes an error (OE-201). We can discard it because order.fields is built from a combination of
            // order.orderLevelFieldGroup and item level field groups on its instances.
            delete clone.originalOrder.fields;
        }

        // Remove circular references from memberships.
        if (clone.customer) {
            clone.customer = cloneShallow(clone.customer);
            clone.customer.membership = null;
            if (clone.customer.account) {
                clone.customer.account = cloneShallow(clone.customer.account);
                clone.customer.account.memberships = [];
                clone.customer.account.firstActiveMembership = null;
            }
        }

        // Remove circular references from cart items.
        clone.itemList = clone.itemList.map(
            /**
             * @param {DigiTickets.CartItem} i
             */
            (i) => {
                let itemClone = i.clone();
                itemClone.cart = null;

                if (itemClone.item && itemClone.item.category) {
                    itemClone.item.category.items = null;
                }

                return itemClone;
            }
        );

        return clone;
    },

    /**
     * @return {FieldGroup}
     */
    getOrderLevelFieldGroup() {
        return this.fields;
    },

    /**
     * For compatibility with Order entity.
     *
     * @return {DigiTickets.CartItem[]}
     */
    getLines() {
        return this.itemList;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Cart;
}
