const DesignTestModalCtrl = require('./DesignTestModalCtrl');

/**
 * @param $modalInstance
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param {AgentService} AgentService
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {DataStoreTester} dataStoreTester
 * @param {DigiTickets.Logger} Logger
 * @param {ModalFactory} ModalFactory
 * @param {ReceiptPrinterService} ReceiptPrinterService
 */
const DebugModalCtrl = function (
    $modalInstance,
    $rootScope,
    $scope,
    $timeout,
    AgentService,
    AppConfig,
    dataStoreTester,
    Logger,
    ModalFactory,
    ReceiptPrinterService
) {
    $scope.ok = () => {
        $modalInstance.close(true);
    };

    $scope.printTestPage = function printTestPage() {
        ReceiptPrinterService.printTestPage(
            {
                appConfig: AppConfig
            }
        );
    };

    $scope.showDesignTestModal = () => {
        ModalFactory.open(
            'design-test',
            DesignTestModalCtrl,
            'partials/modals/designTestModal.html'
        );
    };

    const updateBodyClassesForDebugPrintIframes = () => {
        document.body.classList.remove('show-print-iframes');
        document.body.classList.remove('interact-print-iframes');

        if ($rootScope.debugPrintIframes) {
            document.body.classList.add('show-print-iframes');
        }
        if ($rootScope.debugPrintIframes === 'interact') {
            document.body.classList.add('interact-print-iframes');
        }
    };

    $scope.setDebugPrintIframes = (value) => {
        $rootScope.debugPrintIframes = value;
        updateBodyClassesForDebugPrintIframes();
    };

    $scope.toggleShowAgentInfo = () => {
        $rootScope.showAgentInfo = !$rootScope.showAgentInfo;
    };

    const dtLocalStorage = new DigiTickets.LocalStorageAnalyser();

    $scope.capacity = dtLocalStorage.capacity;
    $scope.memoryUsed = dtLocalStorage.measure();

    $scope.testCapacity = function testCapacity() {
        $scope.capacity = true;
        $timeout(function () {
            $scope.capacity = dtLocalStorage.testCapacity();
        });
    };

    /**
     * Test IndexedDB is working by storing a string and retrieving it.
     */
    $scope.testIndexedDb = function testIndexedDb() {
        dataStoreTester
            .test()
            .then(() => {
                window.alert('IndexedDB is working.');
            })
            .catch((error) => {
                window.alert(error.toString());
                Logger.warning(
                    'IndexedDB not available (test button):',
                    error
                );
            });
    };

    $scope.connectToAgent = () => {
        AgentService.connectToAgent();
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DebugModalCtrl;
}
