/**
 * Given a new Cart model, populate the properties of the CartService singleton.
 * This is intended to be temporary. Eventually the CartService should just have a 'currentCart' property
 * and instead of using this we just change that property to a different Cart.
 *
 * @param {Cart} cart
 * @param {CartService} cartService
 */
const mapCartToCartService = (cart, cartService) => {
    // TODO: Not moved from CartService to Cart to not interfere with Table Service pt2 (PRO-446, PRO-523, PRO-524)
    cartService.fulfillmentCreatedAt = cart.fulfillmentCreatedAt;
    cartService.restoredKitchenOrder = cart.restoredKitchenOrder;

    // TODO: Not moved from CartService to Cart to not interfere with storing fieldData on item instances (OE-110)
    cartService.fieldData = cart.fieldData;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        mapCartToCartService
    };
}
