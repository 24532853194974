const moment = require('moment');

/**
 * @param $scope
 * @param $modalInstance
 * @param {DigiTickets.Membership} membership
 * @param Notification
 */
const MembershipRenewalDateChooserModalCtrl = function MembershipRenewalDateChooserModalCtrl(
    $scope,
    $modalInstance,
    membership,
    Notification
) {
    $scope.membership = membership;
    let dayAfterExpiry = moment(membership.currentSubscription.endDate).add(1, 'days').toDate();
    // The renewal options. Note, they are in order of precedence as regards the default option. Basically, it should
    // default to "today", unless the expiry date is in the future, in which case "today" is disabled and "expiry" is
    // the default.
    $scope.renewalTypes = [
        {
            type: DigiTickets.MembershipRenewalDateTypes.TODAY,
            isDisabled: dayAfterExpiry > new Date(),
            hasDatePicker: false,
            effectiveDate: new Date()
        },
        {
            type: DigiTickets.MembershipRenewalDateTypes.EXPIRY,
            isDisabled: false,
            hasDatePicker: false,
            effectiveDate: dayAfterExpiry
        },
        {
            type: DigiTickets.MembershipRenewalDateTypes.OTHER,
            isDisabled: false,
            hasDatePicker: true,
            effectiveDate: null
        },
        {
            type: DigiTickets.MembershipRenewalDateTypes.NONE,
            isDisabled: $scope.membership.chosenRenewalOption.type === null,
            hasDatePicker: false,
            effectiveDate: null
        },
    ];
    $scope.settingsForOther = {
        chosenDate: null,
        opened: false,
        minDate: moment($scope.membership.earliestAllowedRenewalDate).toDate()
    };
    $scope.datepickerFormat = 'dd/MM/yyyy';
    $scope.datepickerOptions = {
        yearFormat: 'yy'
    };

    $scope.selectedRenewalType = null;
    $scope.setSelected = function setSelected(which) {
        $scope.selectedRenewalType = which;
        // If they activate "Other", and the date is null, set it to the later of today and minimum allowed.
        if (which.type === DigiTickets.MembershipRenewalDateTypes.OTHER) {
            if ($scope.settingsForOther.chosenDate === null) {
                let today = new Date();
                $scope.settingsForOther.chosenDate = $scope.settingsForOther.minDate < today ? today : $scope.settingsForOther.minDate;
            }
        }
    };
    $scope.setSelectedByType = function setSelectedByType(whichType) {
        // If the type is null, we select the first enabled one.
        for (let typeIndex = 0; typeIndex < $scope.renewalTypes.length; typeIndex++) {
            let thisRenewalType = $scope.renewalTypes[typeIndex];
            if (thisRenewalType.type === whichType || (whichType === null && !thisRenewalType.isDisabled)) {
                $scope.setSelected(thisRenewalType);

                return;
            }
        }
    };

    // Initialise the state of the options.
    $scope.setSelectedByType($scope.membership.chosenRenewalOption.type);
    if ($scope.membership.chosenRenewalOption.type === DigiTickets.MembershipRenewalDateTypes.OTHER) {
        $scope.settingsForOther.chosenDate = $scope.membership.chosenRenewalOption.chosenDate;
    }

    $scope.openTodayPicker = function openTodayPicker($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.settingsForOther.opened = true;
        $scope.setOtherAsSelected();
    };
    $scope.setOtherAsSelected = function setOtherAsSelected() {
        $scope.setSelectedByType(DigiTickets.MembershipRenewalDateTypes.OTHER);
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.ok = function ok() {
        // If they chose "Other", make sure they entered a date and that it's valid.
        if ($scope.selectedRenewalType.type === DigiTickets.MembershipRenewalDateTypes.OTHER) {
            if ($scope.settingsForOther.chosenDate === null || !($scope.settingsForOther.chosenDate instanceof Date)) {
                Notification.error('You must choose a date and it must be valid!');

                return;
            }
            // It's a real date; make sure it's not too early.
            if ($scope.settingsForOther.chosenDate < $scope.settingsForOther.minDate) {
                let minAllowed = moment($scope.settingsForOther.minDate);
                Notification.error('The renewal must start on or after ' + minAllowed.format('DD MMM YYYY'));

                return;
            }
        }

        // Copy the essential values back in to the membership.
        let renewalFromDate = null;
        if ($scope.selectedRenewalType.type === DigiTickets.MembershipRenewalDateTypes.OTHER) {
            renewalFromDate = $scope.settingsForOther.chosenDate;
        } else if ($scope.selectedRenewalType.type === DigiTickets.MembershipRenewalDateTypes.EXPIRY) {
            renewalFromDate = $scope.membership.earliestAllowedRenewalDate;
        }
        $scope.membership.setRenewalOption(
            $scope.selectedRenewalType.type,
            renewalFromDate
        );

        $modalInstance.close({});
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipRenewalDateChooserModalCtrl;
}
