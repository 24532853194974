const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
const presentSoldGiftVoucherRefFilter = require('../../../../../../js/filters/presentSoldGiftVoucherRef');
const redeemSoldGiftVoucher = require('../../../../../../js/libraries/DigiTickets/GiftVouchers/SoldGiftVoucherRedemptions');

// This controller is referenced using AngularJS' data-ng-controller HTML attribute, so suppress 'unused const' warning
// noinspection JSUnusedGlobalSymbols
/**
 * This is the controller for the inner part of the payment modal for the payment method of "Gift Voucher".
 *
 * @param $scope
 * @param {CartToReservationStructureMapper} cartToReservationStructureMapper
 * @param {ExternalCartCalculator} externalCartCalculator
 * @param {ReservationResource} ReservationResource
 */
function GiftVoucherPaymentCtrl(
    $scope,
    cartToReservationStructureMapper,
    externalCartCalculator,
    ReservationResource
) {
    const thisPaymentMethodRef = PaymentMethodRef.GIFT_VOUCHER;

    /**
     * Keep track of any recently used vouchers.
     * This avoids repeat usage of same voucher prior to DT server getting updated.
     * Keyed by ID.
     *
     * @type {Object<SoldGiftVoucher>}
     */
    let voucherCache = {};

    /**
     * @type {?SoldGiftVoucher}
     */
    $scope.soldGiftVoucher = null;

    /**
     * Whether we're currently in the process of applying a voucher to the cart
     *
     * @type {boolean}
     */
    $scope.applying = false;

    /** @type {CartService} */
    const cart = $scope.cart;

    const showError = (errorMessage) => {
        if (errorMessage instanceof Error) {
            errorMessage = errorMessage.message;
        }
        $scope.applying = false;
        $scope.$parent.setError(errorMessage);
    };

    const clear = () => {
        $scope.registerSuppressPaymentReceivedButton(thisPaymentMethodRef);
        $scope.registerSuppressPartialPaymentReceivedButton(thisPaymentMethodRef);
        $scope.soldGiftVoucher = null;
        $scope.applying = false;
        $scope.clearError();
    };

    // Initialise our state
    clear();

    /**
     * @param {string} ref
     */
    $scope.searchVoucherCacheByRef = (ref) => {
        const presentedRef = presentSoldGiftVoucherRefFilter(ref);
        return Object.values(voucherCache).find(
            (soldGiftVoucher) => presentSoldGiftVoucherRefFilter(soldGiftVoucher.ref) === presentedRef
        );
    };

    /**
     * Function called when the CheckGiftVoucherDirective has run a search.
     *
     * @param {SoldGiftVoucher|null} soldGiftVoucher
     */
    $scope.onSoldGiftVoucherSelect = (soldGiftVoucher) => {
        $scope.soldGiftVoucher = soldGiftVoucher;
    };

    /**
     * Function called when the CheckGiftVoucherDirective state has changed.
     */
    $scope.onSoldGiftVoucherChange = () => {
        clear();
    };

    $scope.$on('payment-modal.payment-method-changed', () => {
        clear();
    });

    $scope.$on('payment-modal.closing', () => {
        // If the payment cycle is complete, or the user is cancelling, clear the cache
        voucherCache = {};
    });

    $scope.$on('payment-modal.cancel-pressed', () => {
        // Experience Gift Vouchers act as discounts and need to be removed when payments are abandoned and modal closed.
        $scope.cart.clearPaymentDiscounts();
        // Now re-calculate the cart total.
        $scope.cart.refreshExternalCalculations(true);
    });

    // This method is triggered by the 'Use Voucher' button.
    $scope.useVoucher = async () => {
        $scope.clearError();
        $scope.applying = true;
        if (cart.getRemainingBalance() > 0) {
            // Add a payment for the amount we have entered,
            // instead of the PaymentCtrl creating a payment for the entire remaining balance.
            $scope.preventAddFullPaymentOnClose();

            if (!$scope.soldGiftVoucher) {
                return showError('Please search for a gift voucher first');
            }

            // Load voucher from cache if available (to take into account prior applications)
            if (voucherCache[$scope.soldGiftVoucher.ID] !== undefined) {
                $scope.soldGiftVoucher = voucherCache[$scope.soldGiftVoucher.ID];
            }

            if (!$scope.soldGiftVoucher.isRedeemable) {
                return showError('This gift voucher is not redeemable');
            }
            // If partial payments aren't allowed and the voucher doesn't have enough to cover the balance, exit
            if (
                !cart.allowPartialPayments()
                && $scope.soldGiftVoucher.isBalanceType
                && $scope.soldGiftVoucher.currentBalance < cart.getRemainingBalance()
            ) {
                return showError('Partial payments are not allowed for this order');
            }

            const amountTendered = $scope.soldGiftVoucher.isBalanceType
                ? Math.min(cart.getRemainingBalance(), $scope.soldGiftVoucher.currentBalance)
                : $scope.soldGiftVoucher.currentBalance;

            let payment = new DigiTickets.Payment();
            payment.setPaymentMethod(cart.selectedPaymentMethod);
            payment.setTendered(amountTendered);
            payment.setThirdPartyID(cart.generatePaymentThirdPartyID());
            payment.transactionRef = $scope.soldGiftVoucher.ref;

            let payments = cart.payments;
            payments.push(payment);
            payments = [...new Set(payments)];

            // If this is an experience voucher, ask the API to tweak the cart contents as required to
            // adjust the price of the item being purchased to reflect the amount originally paid for the
            // voucher (so that the taxable amount is correct).
            if ($scope.soldGiftVoucher.isExperienceType) {
                try {
                    console.log('trying to refresh cart');
                    try {
                        // This gets called every time we have searched for a voucher and then click 'Use Voucher'.
                        await externalCartCalculator.recalculate(cart, payments);
                    } catch (error) {
                        $scope.cart.payments.pop();
                        $scope.$broadcast('payment-modal.payment-removed');
                        return showError(error);
                    }

                    let reservationStructure = cartToReservationStructureMapper.map(cart);

                    await ReservationResource.update(
                        { id: cart.reservation.token },
                        reservationStructure
                    ).$promise;
                } catch (e) {
                    // If there is a problem with one of the above requests we can't proceed with the
                    // payment as we don't know how much our experience voucher is 'worth', and this is
                    // required in order to set our payment amount.
                    return showError('There was a problem confirming this voucher: ' + e.message);
                }
                // cart should now have been updated
            }

            // Update the sold gift voucher
            try {
                $scope.soldGiftVoucher = redeemSoldGiftVoucher($scope.soldGiftVoucher, amountTendered);
            } catch (e) {
                return showError(e.message);
            }
            voucherCache[$scope.soldGiftVoucher.ID] = $scope.soldGiftVoucher;

            $scope.addPayment(payment);

            // Clear for next use.
            clear();

            // Let the parent controller take over and finalise the transaction
            $scope.ok();
        } else {
            return showError('Refunding to an existing voucher is not currently supported');
        }
    };
}

// export {GiftVoucherPaymentCtrl}
