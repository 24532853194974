const searchFilterDirective = function ($timeout) {
    return {
        link: function ($scope, element) {
            function init() {
                // The dt-search-filter directive can be used as an attribute on any element to support
                // "searching" (filtering) the items displayed.

                // Find an input with type=search within the element, which is where the user types their query.
                let $searchInput = $(element).find('input[type=search]');

                // Select all child elements with the .dt-search-filter-item class, which are the items to be
                // shown/hidden as the user types their query.
                let $items = $(element).find('.dt-search-filter-item');

                $searchInput.on('keyup', function () {
                    let query = $(this).val().toUpperCase();

                    if (!query) {
                        // Show all items.
                        $items.show();
                        return;
                    }

                    $items.each(function () {
                        // The text that is being searched in should be contained in an element with the
                        // dt-search-filter-text class.
                        let text = $(this).find('.dt-search-filter-text').text();

                        if (text.toUpperCase().indexOf(query) !== -1) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                    });
                });
            }

            $timeout(function () {
                init();
            }, 0);
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = searchFilterDirective;
}
