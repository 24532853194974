/**
 * Creates a string to be used as the thirdPartyRef (also thirdPartyID but that is to be removed) for a cart or for a
 * payment.
 *
 * @param {CurrentDevice} CurrentDevice
 * @param {UserService} UserService
 */
const ThirdPartyRefGenerator = function (
    CurrentDevice,
    UserService
) {
    this.currentDevice = CurrentDevice;
    this.userService = UserService;
};

ThirdPartyRefGenerator.prototype = {

    /**
     * @return {string}
     */
    generateCartThirdPartyRef() {
        return (this.userService && this.userService.company && this.userService.company.ID ? this.userService.company.ID : '')
            + '0'
            + (this.currentDevice && this.currentDevice.device && this.currentDevice.getDeviceId() ? this.currentDevice.getDeviceId() : '')
            + '0'
            + (new Date()).getTime();
    },

    /**
     * @param {Cart|{paymentsAttempted: number, thirdPartyRef: string, thirdPartyID: string}} cart
     *
     * @return {string}
     */
    generatePaymentThirdPartyRef(cart) {
        cart.paymentsAttempted += 1;

        return (cart.thirdPartyRef || cart.thirdPartyID || '')
            + '0'
            + cart.paymentsAttempted;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ThirdPartyRefGenerator;
}
