const addressAutoCompleteDirective = require('./directives/addressAutoCompleteDirective');
const addressFormDirective = require('./directives/addressFormDirective');
const autoFocusDirective = require('./directives/autoFocusDirective');
const backBtnDirective = require('./directives/backBtnDirective');
const barcodeDirective = require('./directives/barcodeDirective');
const barcodeListenerDirective = require('./directives/barcodeListenerDirective');
const cartCustomerInfoDirective = require('./directives/cartCustomerInfoDirective');
const cartItemQuantityListenerDirective = require('./directives/cartItemQuantityListenerDirective');
const clockDirective = require('./directives/clockDirective');
const contactPhotoDirective = require('./directives/contactPhotoDirective');
const datepickerParserDirective = require('./directives/datepickerParserDirective');
const enterDirective = require('./directives/enterDirective');
const fieldInstanceDirective = require('./directives/fieldInstanceDirective');
const fieldsDirective = require('./directives/fieldsDirective');
const focusAlwaysDirective = require('./directives/focusAlwaysDirective');
const focusMeDirective = require('./directives/focusMeDirective');
const hotKeyDirective = require('./directives/hotKeyDirective');
const itemPanelDirective = require('./directives/itemPanelDirective');
const keydownListenerDirective = require('./directives/keydownListenerDirective');
const listCustomerInfoDirective = require('./directives/listCustomerInfoDirective');
const memberTileDirective = require('./directives/memberTileDirective');
const nameWithTitleDirective = require('./directives/nameWithTitleDirective');
const numPadDirective = require('./directives/numPadDirective');
const pagerDirective = require('./directives/pagerDirective');
const preventEnterDirective = require('./directives/preventEnterDirective');
const receiptPaymentsDirective = require('./directives/receiptPaymentsDirective');
const redemptionAnimationDirective = require('./directives/redemptionAnimationDirective');
const rfidListenerDirective = require('./directives/rfidListenerDirective');
const scrollToBottomDirective = require('./directives/scrollToBottomDirective');
const searchFilterDirective = require('./directives/searchFilterDirective');

const ErrorCtrl = require('./controllers/ErrorCtrl');
const LoginCtrl = require('./controllers/LoginCtrl');

const addressFilter = require('./filters/address');
const byItemTypeFilter = require('./filters/byItemType');
const byPrintInstanceTicketsFilter = require('./filters/byPrintInstanceTickets');
const charactersFilter = require('./filters/characters');
const contactFilter = require('./filters/contact');
const currencySymbolFilter = require('./filters/currencySymbol');
const friendlyFilesizeFilter = require('./filters/friendlyFilesize');
const langFilter = require('./filters/lang');
const maskFilter = require('./filters/mask');
const momentFilter = require('./filters/moment');
const orderAddressFilter = require('./filters/orderAddress');
const presentSoldGiftVoucherNameFilter = require('./filters/presentSoldGiftVoucherName');
const presentSoldGiftVoucherRefFilter = require('./filters/presentSoldGiftVoucherRef');
const startFromFilter = require('./filters/startFrom');
const suppressZeroFilter = require('./filters/suppressZero');
const ticketTimeFilter = require('./filters/ticketTime');
const toArrayFilter = require('./filters/toArray');
const ucfirstFilter = require('./filters/ucfirst');

const CartCalculationResource = require('./resources/CartCalculationResource');
const CategoryResource = require('./resources/CategoryResource');
const ContactResource = require('./resources/ContactResource');
const CustomerAccountsResource = require('./resources/CustomerAccountsResource');
const CustomerResource = require('./resources/CustomerResource');
const DeviceResource = require('./resources/DeviceResource');
const FieldResource = require('./resources/FieldResource');
const GiftVoucherResource = require('./resources/GiftVoucherResource');
const LogResource = require('./resources/LogResource');
const MarketingPreferenceResource = require('./resources/MarketingPreferenceResource');
const MemberResource = require('./resources/MemberResource');
const MembershipPlanResource = require('./resources/MembershipPlanResource');
const MembershipRedemptionResource = require('./resources/MembershipRedemptionResource');
const MembershipResource = require('./resources/MembershipResource');
const MembershipSubscriptionResource = require('./resources/MembershipSubscriptionResource');
const OfferResource = require('./resources/OfferResource');
const OnlineResource = require('./resources/OnlineResource');
const OrderAdjustmentResource = require('./resources/OrderAdjustmentResource');
const OrderFulfillmentResource = require('./resources/OrderFulfillmentResource');
const OrderResource = require('./resources/OrderResource');
const PaymentExpressResource = require('./resources/PaymentMethods/PaymentExpressResource');
const PaymentResource = require('./resources/PaymentResource');
const PrivilegesResource = require('./resources/PrivilegesResource');
const ProductResource = require('./resources/ProductResource');
const RedemptionResource = require('./resources/RedemptionResource');
const ReservationResource = require('./resources/ReservationResource');
const ReturnReasonResource = require('./resources/ReturnReasonResource');
const SavedAddressResource = require('./resources/SavedAddressResource');
const SessionAvailabilityResource = require('./resources/SessionAvailabilityResource');
const SessionPriceResource = require('./resources/SessionPriceResource');
const SessionResource = require('./resources/SessionResource');
const SoldGiftVoucherResource = require('./resources/SoldGiftVoucherResource');
const StashedCartResource = require('./resources/StashedCartResource');
const TemplateResource = require('./resources/TemplateResource');
const ThemeResource = require('./resources/ThemeResource');
const TicketResource = require('./resources/TicketResource');
const TradingSessionResource = require('./resources/TradingSessionResource');
const UserResource = require('./resources/UserResource');

const is = require('./libraries/Is');
const GeneralResponseInterceptor = require('./events/GeneralResponseInterceptor');

/**
 * Register global directives.
 * Directives should be prefixed with 'dt' per AngularJS's recommended best practise:
 * https://docs.angularjs.org/guide/directive
 * "In order to avoid collisions with some future standard, it's best to prefix your own directive names. For instance,
 * if you created a <carousel> directive, it would be problematic if HTML7 introduced the same element. A two or three
 * letter prefix (e.g. btfCarousel) works well. Similarly, do not prefix your own directives with ng or they might
 * conflict with directives included in a future version of AngularJS.
 * FIXME: Rename the directives that don't follow this convention, especially the ones that begin with 'ng'.
 *
 * Some of these are better suited to being components instead of directives, but components were not introduced until
 * AngularJS 1.5
 *
 * @param {angular.Module} app
 */
const registerAngularDirectives = (app) => {
    app.directive('dtAddressAutoComplete', addressAutoCompleteDirective);
    app.directive('dtAddressForm', addressFormDirective);
    app.directive('dtAutoFocus', autoFocusDirective);
    app.directive('dtBackBtn', backBtnDirective);
    app.directive('dtBarcode', barcodeDirective);
    app.directive('dtBarcodeListener', barcodeListenerDirective);
    app.directive('dtCartCustomerInfo', cartCustomerInfoDirective);
    app.directive('dtCartItemQuantityListener', cartItemQuantityListenerDirective);
    app.directive('dtClock', clockDirective);
    app.directive('dtContactPhoto', contactPhotoDirective);
    app.directive('dtDatepickerParser', datepickerParserDirective);
    app.directive('dtEnter', enterDirective);
    app.directive('dtFieldInstance', fieldInstanceDirective);
    app.directive('dtFields', fieldsDirective);
    app.directive('dtFocusAlways', focusAlwaysDirective);
    app.directive('dtFocusMe', focusMeDirective);
    app.directive('dtHotKey', hotKeyDirective);
    app.directive('dtItemPanel', itemPanelDirective);
    app.directive('dtKeydownListener', keydownListenerDirective);
    app.directive('dtListCustomerInfo', listCustomerInfoDirective);
    app.directive('dtMemberTile', memberTileDirective);
    app.directive('dtNameWithTitle', nameWithTitleDirective);
    app.directive('dtNumPad', numPadDirective);
    app.directive('dtPager', pagerDirective);
    app.directive('dtPreventEnter', preventEnterDirective);
    app.directive('dtReceiptPayments', receiptPaymentsDirective);
    app.directive('dtRedemptionAnimation', redemptionAnimationDirective);
    app.directive('dtRfidListener', rfidListenerDirective);
    app.directive('dtScrollToBottom', scrollToBottomDirective);
    app.directive('dtSearchFilter', searchFilterDirective);
};

/**
 * Register global controllers.
 *
 * @param {angular.Module} app
 */
const registerAngularControllers = (app) => {
    app.controller('ErrorCtrl', ErrorCtrl);
    app.controller('LoginCtrl', LoginCtrl);
};

/**
 * Register global filters.
 * Note: Angular DI does not work on arrow functions, so you must use conventional 'function()'s here.
 * AngularJS supports the arrow notation from 1.5.0 onwards.
 *
 * @see https://github.com/angular/angular.js/commit/44a96a4c140873d9fd8484d870af83a0bb9acabd
 *
 * @param {angular.Module} app
 */
const registerAngularFilters = (app) => {
    app.filter('abs', () => Math.abs);
    app.filter('address', () => addressFilter);
    app.filter('asFloat', () => parseFloat);
    app.filter('byItemType', () => byItemTypeFilter);
    app.filter('byPrintInstanceTickets', () => byPrintInstanceTicketsFilter);
    app.filter('characters', () => charactersFilter);
    app.filter('contact', () => contactFilter);
    app.filter('currencySymbol', function ($filter, CurrencyService) {
        return currencySymbolFilter($filter('currency'), CurrencyService);
    });
    app.filter('friendlyFilesize', () => friendlyFilesizeFilter);
    app.filter('encodeURIComponent', () => window.encodeURIComponent);
    app.filter('lang', function (LangService) {
        return langFilter(LangService);
    });
    app.filter('mask', () => maskFilter);
    app.filter('moment', () => momentFilter);
    app.filter('orderAddress', () => orderAddressFilter);
    app.filter('soldGiftVoucherRef', () => presentSoldGiftVoucherRefFilter);
    app.filter('soldGiftVoucherName', function ($filter, CurrencyService) {
        return presentSoldGiftVoucherNameFilter(currencySymbolFilter($filter('currency'), CurrencyService));
    });
    app.filter('startFrom', () => startFromFilter);
    app.filter('suppressZero', function ($filter) {
        return suppressZeroFilter($filter);
    });
    app.filter('ticketTime', () => ticketTimeFilter);
    app.filter('toArray', () => toArrayFilter);
    app.filter('ucfirst', () => ucfirstFilter);
    // Allows us to embed HTML from DigiTickets API directly into our templates
    app.filter('unsafe', function ($sce) {
        return $sce.trustAsHtml;
    });
};

/**
 * Register angular-resource resources.
 *
 * @param {angular.Module} app
 */
const registerAngularResources = (app) => {
    app.factory('CartCalculationResource', CartCalculationResource);
    app.factory('categoryResource', CategoryResource);
    app.factory('ContactResource', ContactResource);
    app.factory('CustomerAccountsResource', CustomerAccountsResource);
    app.factory('CustomerResource', CustomerResource);
    app.factory('DeviceResource', DeviceResource);
    app.factory('FieldResource', FieldResource);
    app.factory('GiftVoucherResource', GiftVoucherResource);
    app.factory('LogResource', LogResource);
    app.factory('MarketingPreferenceResource', MarketingPreferenceResource);
    app.factory('MemberResource', MemberResource);
    app.factory('MembershipPlanResource', MembershipPlanResource);
    app.factory('MembershipRedemptionResource', MembershipRedemptionResource);
    app.factory('MembershipResource', MembershipResource);
    app.factory('MembershipSubscriptionResource', MembershipSubscriptionResource);
    app.factory('OfferResource', OfferResource);
    app.factory('OnlineResource', OnlineResource);
    app.factory('orderAdjustmentResource', OrderAdjustmentResource);
    app.factory('OrderFulfillmentResource', OrderFulfillmentResource);
    app.factory('OrderResource', OrderResource);
    app.factory('PaymentExpressResource', PaymentExpressResource);
    app.factory('PaymentResource', PaymentResource);
    app.factory('PrivilegesResource', PrivilegesResource);
    app.factory('ProductResource', ProductResource);
    app.factory('RedemptionResource', RedemptionResource);
    app.factory('ReservationResource', ReservationResource);
    app.factory('ReturnReasonResource', ReturnReasonResource);
    app.factory('SavedAddressResource', SavedAddressResource);
    app.factory('SessionAvailabilityResource', SessionAvailabilityResource);
    app.factory('SessionPriceResource', SessionPriceResource);
    app.factory('SessionResource', SessionResource);
    app.factory('SoldGiftVoucherResource', SoldGiftVoucherResource);
    app.factory('StashedCartResource', StashedCartResource);
    app.factory('TemplateResource', TemplateResource);
    app.factory('ThemeResource', ThemeResource);
    app.factory('TicketResource', TicketResource);
    app.factory('TradingSessionResource', TradingSessionResource);
    app.factory('UserResource', UserResource);
};

/**
 * @param {angular.Module} app
 */
const configureAngularApp = (app) => {
    app.config(
        function ($routeProvider, $locationProvider, $sceDelegateProvider, $rootScopeProvider, $httpProvider, $provide) {
            /* global registerRoutes */
            if (typeof registerRoutes === 'function') {
                registerRoutes($routeProvider);
            }

            $sceDelegateProvider.resourceUrlWhitelist(['self']);
            $rootScopeProvider.digestTtl(10);

            // Reset headers to avoid OPTIONS request (aka preflight)
            let formEncodedOptions = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' };
            $httpProvider.defaults.headers = {
                put: formEncodedOptions,
                post: formEncodedOptions,
                patch: formEncodedOptions,
                common: formEncodedOptions
            };

            // Allows cookies in CORS requests
            $httpProvider.defaults.withCredentials = true;

            // Override $http service's default transformRequest
            $httpProvider.defaults.transformRequest = [
                function (data) {
                    /**
                     * The workhorse; converts an object to x-www-form-urlencoded serialization.
                     *
                     * @param {Object} obj
                     * @returns {String}
                     */
                    let param = function param(obj) {
                        let query = '';
                        let name;
                        let value;
                        let fullSubName;
                        let subName;
                        let subValue;
                        let innerObj;
                        let i;

                        for (name in obj) {
                            if (obj.hasOwnProperty(name)) {
                                value = obj[name];

                                if (value instanceof Array) {
                                    for (i = 0; i < value.length; ++i) {
                                        subValue = value[i];
                                        fullSubName = name + '[' + i + ']';
                                        innerObj = {};
                                        innerObj[fullSubName] = subValue;
                                        query += param(innerObj) + '&';
                                    }
                                } else if (value instanceof Date) {
                                    // Converts all Date objects sent in AJAX request to ISO8601 format.
                                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value.toISOString()) + '&';
                                } else if (value instanceof Object) {
                                    for (subName in value) {
                                        subValue = value[subName];
                                        fullSubName = name + '[' + subName + ']';
                                        innerObj = {};
                                        innerObj[fullSubName] = subValue;
                                        query += param(innerObj) + '&';
                                    }
                                } else if (value !== undefined && value !== null) {
                                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
                                }
                            }
                        }

                        return query.length ? query.substr(0, query.length - 1) : query;
                    };

                    return is.anObject(data) && String(data) !== '[object File]' ? param(data) : data;
                }
            ];

            /**
             * @todo The responseInterceptors collection is deprecated in 1.3.0 so refactoring will be required to upgrade.
             */
            // Interceptor for all responses
            $httpProvider.responseInterceptors.push(GeneralResponseInterceptor);

            // modifies the default datepicker behaviour by adding monthChanged event
            $provide.decorator('datepickerDirective', function ($delegate) {
                let directive = $delegate[0];
                let directiveCompile = directive.compile;
                let monthTitle = '';
                directive.compile = function (tElement, tAttrs) {
                    let link = directiveCompile.apply(this, arguments);

                    let emitMonthChanged = function (thisArg, scope) {
                        let months = [
                            'January',
                            'February',
                            'March',
                            'April',
                            'May',
                            'June',
                            'July',
                            'August',
                            'September',
                            'October',
                            'November',
                            'December'
                        ];
                        let monthYearArray = thisArg.title.split(' ');
                        let month = months.indexOf(monthYearArray[0]);
                        let year = parseInt(monthYearArray[1]);
                        scope.$emit('datepicker.monthChanged', month, year, scope);
                    };

                    return function (scope, element, attrs, ctrls) {
                        link.apply(this, arguments);
                        let originalMove = scope.move;
                        scope.move = function (direction) {
                            originalMove.apply(this, arguments);
                            emitMonthChanged(this, scope);
                        };
                        let originalSelect = scope.select;
                        scope.select = function (direction) {
                            originalSelect.apply(this, arguments);
                            if (monthTitle != scope.title) {
                                emitMonthChanged(this, scope);
                                monthTitle = scope.title;
                            }
                        };
                    };
                };
                return $delegate;
            });
        }
    );
};

if (typeof module !== 'undefined' && module.exports) {
    // Running in Node or Webpack - export the functions to be used elsewhere.
    module.exports = {
        registerAngularDirectives,
        registerAngularControllers,
        registerAngularFilters,
        registerAngularResources,
        configureAngularApp
    };
} else {
    // Running directly in the browser (Grunt).
    // To maintain previous behaviour call the function immediately.
    registerAngularDirectives(window.app);
    registerAngularControllers(window.app);
    registerAngularFilters(window.app);
    registerAngularResources(window.app);
    configureAngularApp(window.app);
}
