DigiTickets.PrintPrintDriver = function (
    $q,
    $timeout
) {
    this.$q = $q;
    this.$timeout = $timeout;
};

DigiTickets.PrintPrintDriver.prototype = {
    /**
     * @param {Window} frame
     * @param {PrintJobOptions} opts
     *
     * @return {promise|jQuery.promise|*|{then, catch, finally}}
     */
    print: function (frame, opts) {
        let deferred = this.$q.defer();

        frame.focus();
        if (opts && typeof opts.onBeforePrintCall === 'function') {
            opts.onBeforePrintCall();
        }
        frame.print();
        window.focus();

        this.$timeout(function () {
            // Give it a second to finish printing.
            // This timeout is necessary to prevent calling window.print() too quickly after the last call,
            // resulting in things not printing. 500ms seems to work in dev but still is too fast for client devices.
            // 1000ms (1s) should work for clients.
            deferred.resolve();
        }, 1000);

        return deferred.promise;
    }
};
