const Customer = function () {
    /**
     * @type {?DigiTickets.CustomerAccount}
     */
    this.account = null;

    /**
     * @type {DigiTickets.Address}
     */
    this.address = new DigiTickets.Address();

    /**
     * @type {DigiTickets.Address}
     */
    this.deliveryAddress = new DigiTickets.Address();

    /**
     * @type {DigiTickets.Contact}
     */
    this.contact = new DigiTickets.Contact();

    /**
     * Selected membership (for unlocking member-only discounts).
     *
     * @type {?DigiTickets.Membership}
     */
    this.membership = null;
};

Customer.prototype = {
    /**
     * @return {DigiTickets.Address}
     */
    getAddress() {
        let address = this.account && this.account.primaryAddress ? this.account.primaryAddress : this.address;

        // Always return an address instance even if it's empty.
        return address || new DigiTickets.Address();
    },

    /**
     * @return {DigiTickets.Contact}
     */
    getContact() {
        let contact = this.account && this.account.primaryContact ? this.account.primaryContact : this.contact;

        // Always return a contact instance even if it's empty.
        return contact || new DigiTickets.Contact();
    },

    /**
     * @return {string}
     */
    getFullName() {
        const contact = this.getContactContainingName();
        return contact ? contact.getFullName() : '';
    },

    /**
     * The customer might have an account but not have a contact, or it might have a contact that has no name.
     * Meanwhile there might be a name set in this.contact.
     *
     * This returns whichever contact entity contains a name, either from the account or from the customer.
     *
     * This is useful to get the contact entity to pass to a dt-name-with-title directive.
     *
     * @return {?DigiTickets.Contact}
     */
    getContactContainingName() {
        if (this.account && this.account.primaryContact && this.account.primaryContact.getFullName()) {
            return this.account.primaryContact;
        }
        if (this.contact && this.contact.getFullName()) {
            return this.contact;
        }
        return null;
    },

    /**
     * @return {string}
     */
    getOrganisation() {
        return this.account ? this.account.organisation : '';
    },

    /**
     * @return {string}
     */
    getEmail() {
        if (this.account && this.account.primaryContact && this.account.primaryContact.email) {
            return this.account.primaryContact.email;
        }
        if (this.contact && this.contact.email) {
            return this.contact.email;
        }
        return '';
    },

    /**
     * @return {boolean}
     */
    hasEmail() {
        return !!this.getEmail();
    },

    /**
     * @return {string}
     */
    getTel() {
        if (this.account && this.account.primaryContact && this.account.primaryContact.tel) {
            return this.account.primaryContact.tel;
        }
        if (this.contact && this.contact.tel) {
            return this.contact.tel;
        }
        return '';
    },

    /**
     * @return {boolean}
     */
    hasTel() {
        return !!this.getTel();
    },

    hasAddress() {
        return !this.getAddress().isEmpty();
    },

    /**
     * @param {DigiTickets.Contact} contact
     */
    setContact(contact) {
        this.contact = contact;
    },

    /**
     * @param {DigiTickets.CustomerAccount|null} account
     */
    setAccount(account) {
        this.account = account;
    },

    /**
     * @returns {boolean}
     */
    hasAccount() {
        return !!this.account;
    },

    /**
     * @param {DigiTickets.Membership|null} membership
     */
    setMembership(membership) {
        this.membership = membership;
    },

    /**
     * Check whether we currently have any customer details at all
     *
     * @returns {boolean}
     */
    hasData() {
        return !!this.contact.firstName
            || !!this.contact.lastName
            || !!this.contact.email
            || !!this.contact.tel
            || !!this.address.house
            || !!this.address.street
            || !!this.address.town
            || !!this.address.postcode
            || !!this.account
            || !!this.membership;
    },

    /**
     * Check if we have enough data about the customer for GiftAid:
     * - First initial
     * - Last name
     * - House number
     * - Postcode
     *
     * @return {boolean}
     */
    hasGiftAidData() {
        let contact = this.getContact();
        let address = this.getAddress();

        return !!(
            contact.firstName
            && contact.lastName
            && address.house
            && address.postcode
        );
    },

    getHydrationMap() {
        return {
            address: {
                model: DigiTickets.Address
            },
            account: {
                field: ['account', 'customeraccount'],
                model: DigiTickets.CustomerAccount
            },
            contact: {
                model: DigiTickets.Contact
            },
            deliveryAddress: {
                model: DigiTickets.Address
            }
        };
    },

    /**
     * NOTE: This uses the 'function' keyword on purpose so Angular can perform DI on it.
     *
     * @param {Hydrator} hydrator
     * @param data
     */
    afterHydration: function (hydrator, data) {
        if (!this.address || this.address.isEmpty()) {
            this.address = hydrator.hydrate(
                data,
                new DigiTickets.Address()
            );
        }
        if (!this.contact || this.contact.isEmpty()) {
            this.contact = hydrator.hydrate(
                data,
                new DigiTickets.Contact()
            );
        }
        if (!this.deliveryAddress) {
            this.deliveryAddress = new DigiTickets.Address();
        }
    },

    /**
     * Return an object of data that can be sent to the API.
     * The API expects the address and contact info to be flattened into one array.
     *
     * @return {object}
     */
    toServerData() {
        const data = {};

        Object.assign(
            data,
            this.address.toServerData()
        );

        Object.assign(
            data,
            this.contact.toServerData()
        );

        // Remove ambiguous ID property. It causes issues when sent to the API because it could either be
        // an addressID or a contactID.
        /* istanbul ignore else */
        if (data.hasOwnProperty('ID')) {
            delete data.ID;
        }

        if (this.account) {
            data.type = this.account.type;
            data.organisation = this.account.organisation;
        }

        return data;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Customer;
}
