/**
 * Initializes and authenticates a WebSocket connection to the DigiTickets 'socket' server.
 * Used for kitchen and kitchen-customer screens to receive instant updates pushed from the app server via
 * the socket.
 *
 * @param {CartStasher} CartStasher
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param OrderFulfillmentResource
 * @param {OrderService} orderService
 */
const OrderFulfillmentService = function (
    CartStasher,
    CartStashHelperService,
    OrderFulfillmentResource,
    orderService
) {
    this.cartStasher = CartStasher;
    this.cartStashHelperService = CartStashHelperService;
    this.orderFulfillmentResource = OrderFulfillmentResource;
    this.orderService = orderService;
};

OrderFulfillmentService.prototype = {
    //
    // Get all orders and stashed carts from the API that require fulfillment but are
    // as-yet unfulfilled.
    //
    // @param callback
    //
    loadUnfulfilledOrders: function (callback) {
        let loadingOrders = true;
        let loadingStashedCarts = true;

        let returnOrders = [];

        let onFinish = function () {
            if (!loadingOrders && !loadingStashedCarts) {
                callback(returnOrders);
            }
        };

        // Fetch unfulfilled orders from the API.
        this.orderService.findUnfulfilledOrders(
            function (orders) {
                for (let i = 0; i < orders.length; i++) {
                    returnOrders.push(orders[i]);
                }

                loadingOrders = false;
                onFinish();
            }
        );

        // Fetch unfulfilled stashed carts from the API.
        this.cartStasher.findUnfulfilledStashedCarts(
            (stashedCarts) => {
                Promise.all(
                    // Map all the stashedCarts into an array of promises.
                    // Promise.all will resolve when all of those promises are resolved.
                    // Calling promise.then returns a promise and this is what is the result of .map
                    stashedCarts.map((stashedCart) => this.cartStashHelperService.stashToOrder(stashedCart)
                        .then((order) => {
                            returnOrders.push(order);
                        }))
                ).then(() => {
                    loadingStashedCarts = false;
                    onFinish();
                });
            }
        );
    },

    /**
     * Sort the given array of orders with the oldest (by fulfillmentCreatedAt) first.
     *
     * @param {KitchenOrder[]} orders
     */
    sortKitchenOrdersByOldest(orders) {
        orders.sort((order1, order2) => {
            if (order1.fulfillmentCreatedAt === order2.fulfillmentCreatedAt) {
                return 0;
            }

            return order1.fulfillmentCreatedAt < order2.fulfillmentCreatedAt ? -1 : 1;
        });
    },

    /**
     * Sort the given array of orders with the newest (by fulfillmentCreatedAt) first.
     *
     * @param {DigiTickets.Order[]} orders
     */
    sortOrdersByNewest(orders) {
        orders.sort((order1, order2) => {
            if (order1.fulfillmentCreatedAt === order2.fulfillmentCreatedAt) {
                return 0;
            }

            return order1.fulfillmentCreatedAt < order2.fulfillmentCreatedAt ? 1 : -1;
        });
    },

    /**
     * @param {KitchenOrder} order
     */
    markAsFulfilled(order) {
        order.fulfilledAt = new Date();

        const fulfilledItems = order.items
            .filter((item) => item.versionIndex === 0)
            .map((item) => ({
                itemID: item.itemID,
                qty: item.qty,
                hash: item.hash
            }));

        const fulfillmentIDs = order.fulfillments.map((f) => f.ID);

        // Mark the individual lines as fulfilled
        order.items.forEach((item) => {
            item.isFulfilled = true;
        });

        this.orderFulfillmentResource.update(
            {},
            {
                orderID: order.orderID ? order.orderID : null,
                stashedCartThirdPartyRef: order.thirdPartyRef,
                fulfilledAt: order.fulfilledAt,
                fulfilledItems,
                fulfillmentIDs
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderFulfillmentService;
}
