/**
 * @param $filter
 * @param $scope
 * @param {NavigationService} navigationService
 * @param {OrderManager} OrderManagerService
 * @param RedemptionResource
 * @param {SearchContext} searchContext
 */
const RedemptionCtrl = function (
    $filter,
    $scope,
    navigationService,
    OrderManagerService,
    RedemptionResource,
    searchContext
) {
    navigationService.hideNav();

    $scope.orderItem = searchContext.orderItem;
    $scope.itemInstance = searchContext.itemInstance;
    $scope.order = searchContext.currentOrder;
    $scope.date = Date.now();

    $scope.canBeRedeemed = false;
    /**
     * Error message is displayed by the dtRedemptionAnimation directive.
     *
     * @type {string}
     */
    $scope.errorMessage = '';
    $scope.failedRedemption = false;
    $scope.pendingRedirect = null;
    $scope.redemptionLoading = false;
    $scope.redemptionSuccessful = null;

    function init() {
        if ($scope.orderItem.isRedemptionAllowed() && $scope.itemInstance.netRedemptions <= 0) {
            $scope.canBeRedeemed = true;
        }
    }

    $scope.redeemInstanceOrRedirect = function redeemInstanceOrRedirect() {
        $scope.redemptionLoading = true;
        if (!$scope.canBeRedeemed || $scope.failedRedemption) {
            $scope.redemptionLoading = false;
            $scope.closeRedemption();
            return;
        }

        RedemptionResource.redeem(
            { qty: +1, ref: $scope.itemInstance.itemInstanceRef, type: 'iteminstance' },
            function success(response) {
                if (!response.success) {
                    $scope.errorMessage = response.message;
                    $scope.failedRedemption = true;
                    $scope.redemptionLoading = false;
                    $scope.redemptionSuccessful = false;
                    return;
                }

                redeemInstance(response.newRedemptionCount);
                $scope.errorMessage = '';
                $scope.failedRedemption = false;
                $scope.redemptionLoading = false;
                $scope.redemptionSuccessful = true;
            },
            function error(response) {
                $scope.errorMessage = response.data.message;
                $scope.failedRedemption = true;
                $scope.redemptionLoading = false;
                $scope.redemptionSuccessful = false;
            }
        );
    };

    $scope.closeRedemption = function closeRedemption() {
        searchContext.orderItem = null;
        searchContext.itemInstance = null;

        navigationService.viewRedeem();
    };

    $scope.getRedemptionDescription = function getRedemptionDescription() {
        if ($scope.canBeRedeemed) {
            return '';
        }

        if ($scope.itemInstance.netRedemptions >= 1) {
            return $filter('lang')('REDEMPTION.ALREADY_REDEEMED');
        }

        return $filter('lang')('REDEMPTION.NO_REDEEM');
    };

    $scope.redirectToOrder = function redirectToOrder() {
        OrderManagerService.clearSearchedBarcode();
        if ($scope.pendingRedirect !== null) {
            clearTimeout($scope.pendingRedirect);
        }

        navigationService.viewOrderDetails($scope.order.bookingRef);
    };

    $scope.isSessionSameDay = function isSessionSameDay() {
        if ($scope.orderItem.session && !$scope.orderItem.session.startTime.isSameDay(new Date())) {
            return false;
        }

        return true;
    };

    $scope.getActionClass = function getActionClass() {
        let className = getClassForAction();

        return $scope.redemptionLoading ? className + ' loading' : className;
    };

    $scope.getRedemptionHeader = function getRedemptionHeader() {
        if (!$scope.isSessionSameDay()) {
            return $filter('lang')('REDEMPTION.SURE');
        }

        return $filter('lang')('REDEMPTION.HEADER');
    };

    $scope.getRedemptionSubHeader = function getRedemptionSubHeader() {
        if (!$scope.isSessionSameDay()) {
            return $filter('lang')('REDEMPTION.DIFFERENT_DATE', {
                name: $scope.orderItem.getName(),
                date: $scope.orderItem.session.startTime.toFormattedDateString()
            });
        }

        return $filter('lang')('REDEMPTION.REDEEM_PROMPT', { name: $scope.orderItem.getName() });
    };

    $scope.getRedemptionLabel = function getRedemptionLabel() {
        if ($scope.redemptionLoading) {
            return $filter('lang')('REDEMPTION.LOADING');
        }

        if (!$scope.canBeRedeemed) {
            return $filter('lang')('REDEMPTION.OOPS');
        }

        return $filter('lang')('REDEMPTION.REDEEM');
    };

    function redeemInstance(newRedemptionCount) {
        $scope.itemInstance.setNetRedemptions(newRedemptionCount);
    }

    function getClassForAction() {
        if (!$scope.canBeRedeemed) {
            return 'irredeemable completed';
        }

        if (!$scope.isSessionSameDay()) {
            return 'pending different-day';
        }

        return 'pending';
    }

    init();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = RedemptionCtrl;
}
