const { toInt } = require('../functions/transform');

DigiTickets.Seller = function () {
    this.ID = null;
    this.usergroupID = null;
    this.username = null;
};

DigiTickets.Seller.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'userID'],
                transform: toInt
            },
            usergroupID: toInt,
            username: {}
        };
    }
};
