const VerifoneActions = require('../../Constants/VerifoneActions');
const VerifoneActionsForStatuses = require('../../Constants/VerifoneActionsForStatuses');
const VerifoneContinueTransactionRecord = require('../Request/VerifoneContinueTransactionRecord');
const VerifoneStatuses = require('../../Constants/VerifoneStatuses');
const { toInt } = require('../../../../../../functions/transform');

/**
 * This record represents messages received on the progress socket (for POS Client integrations only.)
 */
const VerifoneStatusRecord = function () {
    /**
     * This indicates the result code (will always be 100 for this record type).
     *
     * @type {Number}
     */
    this.result = null;

    /**
     * @type {Number}
     */
    this.statusId = null;

    /**
     * This is a text representation of the progress status.
     *
     * @type {String}
     */
    this.status = null;

    this.parameters = {};

    /**
     * This field will contain any information that the POS may require that is associated with that status.
     * Each parameter will be ';' delimited and will be defined as follows: <Name>=<Value>
     *
     * @type {String}
     */
    this.rawParameters = null;

    /**
     * @type {String}
     */
    this.message = null;

    /**
     * @type {String}
     */
    this.customerFacingMessage = null;

    /**
     * @type {String}
     */
    this.rawResponse = null;

    /**
     * @type {VerifoneContinueTransactionRecord[]}
     */
    this.possibleActionRecords = [];

    /**
     * Glyphicon class to go with this status.
     *
     * @type {String}
     */
    this.iconName = null;
};

VerifoneStatusRecord.prototype = {
    getHydrationMap() {
        return {
            // This indicates the result code (will always be 100),
            result: {
                field: 1,
                transform: toInt
            },

            // Status ID of the transaction. All responses listed below in separate table
            statusId: {
                field: 3,
                transform: toInt
            },

            // This is a text representation of the progress status
            status: {
                field: 4,
                transform: toInt
            },

            // This field will contain any information that the POS may require that is associated with that status.
            // Each parameter will be ';' delimited and will be defined as follows: <Name>=<Value>
            rawParameters: {
                field: 5
            },

            message: {
                field: 18
            },

            rawResponse: {}
        };
    },

    afterHydration: function () {
        // Split up the raw parameters (field 5) into key/value pairs.
        if (this.rawParameters.length > 0) {
            let params = this.rawParameters.split(';');
            for (let p = 0; p < params.length; p++) {
                let param = params[p];
                param = param.split('=');
                this.parameters[param[0]] = param[1];
            }
        }

        if (this.statusId) {
            // Lookup the user friendly text for this status from the predefined constants.
            this.statusId = parseInt(this.statusId, 10);
            if (VerifoneStatuses.hasOwnProperty(this.statusId)) {
                let status = VerifoneStatuses[this.statusId];
                this.message = status[0];
                if (status.hasOwnProperty(1)) {
                    this.customerFacingMessage = status[1];
                }
                if (status.hasOwnProperty(2)) {
                    this.iconName = status[2];
                }
            }
        }

        // Lookup what can be done with this status.
        this.possibleActionRecords = this.generatePossibleActionRecords();
    },

    /**
     * Return all the possible actions that can be performed for this status.
     * Uses the map at:
     *
     * @see VerifoneActionsForStatuses
     *
     * @return {VerifoneContinueTransactionRecord[]}
     */
    generatePossibleActionRecords() {
        let possibleActionRecords = [];

        // Can any actions be performed for this status?
        if (VerifoneActionsForStatuses.hasOwnProperty(this.statusId)) {
            let availableActions = VerifoneActionsForStatuses[this.statusId];

            // For each action that can be performed for this status:
            for (let i = 0; i < availableActions.actions.length; i++) {
                let actionId = availableActions.actions[i];
                let record = new VerifoneContinueTransactionRecord();
                record.actionId = parseInt(actionId, 10);

                // Look up a user friendly description of the action if available.
                if (VerifoneActions.hasOwnProperty(actionId)) {
                    record.description = VerifoneActions[actionId][0];
                }

                possibleActionRecords.push(record);
            }
        }

        return possibleActionRecords;
    },

    /**
     * Get the record for the action with the given ID.
     *
     * @param {number} actionId
     *
     * @return {VerifoneContinueTransactionRecord|null}
     */
    getActionRecord(actionId) {
        for (let i = 0; i < this.possibleActionRecords.length; i++) {
            if (this.possibleActionRecords[i].actionId === actionId) {
                return this.possibleActionRecords[i];
            }
        }

        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneStatusRecord;
}
