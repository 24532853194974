const LogResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'logs/',
        ApiRequestParams.defaults(),
        {
            storeIntegratedPaymentLog: {
                url: AppConfig.apiBaseUrl + 'logs/integrated-payments',
                method: 'POST'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LogResource;
}
