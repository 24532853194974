const SearchContext = function () {
    /**
     * @type {DigiTickets.Order|null}
     */
    this.currentOrder = null;

    /**
     * @type {ItemInstance|null}
     */
    this.itemInstance = null;

    /**
     * @type {?OrderLine}
     */
    this.orderItem = null;
};

SearchContext.prototype = {};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SearchContext;
}
