const OrderFulfillmentResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'orderfulfillments/',
        ApiRequestParams.defaults(),
        {
            update: {
                // Should really be PATCH, but the existing CORS options (which may be cached) do not allow for it.
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'orderfulfillments',
                isArray: true
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderFulfillmentResource;
}
