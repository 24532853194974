/**
 @typedef MembershipRedemptionResource
 @type {Object}
 @property {function(object, function, function)} query
 */

/**
 * @param $resource
 * @param ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 */
const MembershipRedemptionResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'membershipredemptions/',
        ApiRequestParams.defaults(),
        {}
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipRedemptionResource;
}
