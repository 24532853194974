/**
 * @param $scope
 * @param $timeout
 * @param $modalInstance
 * @param {CurrentDevice} CurrentDevice
 * @param {MarketingPreferenceAsker} MarketingPreferenceAsker
 * @param questions {{preference: MarketingPreference, screenQuestion: CustomerScreenQuestion, answer: ?boolean}[]}
 */
const CustomerMarketingPreferenceModalCtrl = function (
    $scope,
    $timeout,
    $modalInstance,
    CurrentDevice,
    MarketingPreferenceAsker,
    questions
) {
    /**
     * @type {boolean}
     */
    $scope.askingOnCustomerScreen = true;

    /**
     * @type {{preference: MarketingPreference, screenQuestion: CustomerScreenQuestion, answer: ?boolean}[]}
     */
    $scope.questions = questions;

    /**
     * @param {Object<boolean>}
     */
    $scope.manualAnswers = {};

    if (CurrentDevice.device.customerTouchScreenEnabled) {
        // Send questions to the customer screen.
        MarketingPreferenceAsker.askOnCustomerScreen($scope.questions)
            .then(
                /**
                 * @param {number[]} selectedMarketingPreferenceIDs
                 */
                (selectedMarketingPreferenceIDs) => $timeout(() => {
                    // Resolves when customer has answered.
                    $modalInstance.close(selectedMarketingPreferenceIDs);
                })
            )
            // Rejects if customer screen not available and user needs to manually answer.
            .catch(showManualQuestionScreen);
    } else {
        showManualQuestionScreen();
    }

    /**
     * Setup the manual question screen where the operator can complete marketing questions.
     * This is triggered when either the customer screen is unresponsive or is not touch enabled.
     */
    function showManualQuestionScreen() {
        $timeout(() => {
            $scope.askingOnCustomerScreen = false;
            // Build the answer object.
            $scope.questions.forEach((q) => {
                $scope.manualAnswers[q.preference.ID] = false;
            });
        });
    }

    $scope.ok = () => {
        // Convert the {marketingPreferenceID:boolean} object to a simple array containing
        // the IDs that were selected.
        let selectedMarketingPreferenceIDs = [];
        for (let marketingPreferenceID in $scope.manualAnswers) {
            if (
                $scope.manualAnswers.hasOwnProperty(marketingPreferenceID)
                && $scope.manualAnswers[marketingPreferenceID] === true
            ) {
                selectedMarketingPreferenceIDs.push(marketingPreferenceID);
            }
        }
        $modalInstance.close(selectedMarketingPreferenceIDs);
    };
};
