const { toInt } = require('../functions/transform');

DigiTickets.PrintMethod = function () {
    /**
     * @type {string|null}
     */
    this.driverRef = null;
    this.ID = null;
    this.name = null;
};

DigiTickets.PrintMethod.prototype = {
    getHydrationMap() {
        return {
            driverRef: {},
            ID: {
                field: ['printMethodID', 'ID'],
                transform: toInt
            },
            name: {}
        };
    }
};
