const logFactory = require('./Logging/logFactory');

/**
 * Container for the current device
 * When this is initialized it loads any existing device from the cache and is then updated later.
 *
 * @param $rootScope
 * @param {AgentService} AgentService
 * @param CurrentDeviceCache
 * @param {Hydrator} hydrator
 * @param {LocalStorageDataStore} LocalStorageDataStore
 */
const CurrentDevice = function (
    $rootScope,
    AgentService,
    CurrentDeviceCache,
    hydrator,
    LocalStorageDataStore
) {
    this.$rootScope = $rootScope;
    this.agentService = AgentService;
    this.currentDeviceCache = CurrentDeviceCache;
    this.hydrator = hydrator;
    this.localStorageDataStore = LocalStorageDataStore;

    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('CurrentDevice');

    this.localStorageKey = 'device';

    /**
     * @type {!Device}
     */
    this.device = new DigiTickets.Device();
};

CurrentDevice.prototype = {
    initialize() {
        let cachedDevice = this.loadCachedDevice();
        if (cachedDevice) {
            this.setDevice(cachedDevice, true);
        }
    },

    getDeviceGuid() {
        return this.device ? this.device.guid : null;
    },

    getDeviceId() {
        return this.device ? this.device.ID : null;
    },

    isSet() {
        return !!(this.device && this.device.ID > 0);
    },

    isFake() {
        return !!(this.device && this.device.isFake);
    },

    /**
     * Set the current device.
     *
     * @param {DigiTickets.Device} device
     * @param {boolean} [fromCache]
     */
    setDevice(device, fromCache) {
        this.device = (device.ID || device.isFake) ? device : new DigiTickets.Device();
        this.cache();
        this.saveDeviceGuidToDisk(device.guid).catch(() => {});

        if (this.isSet() || this.isFake()) {
            this.$rootScope.$broadcast('DEVICE_SET_EVENT', {
                device: this.device,
                fromCache
            });
        }
    },

    clearDevice() {
        this.setDevice(new DigiTickets.Device());
    },

    /**
     * @param {DigiTickets.TradingSession} tradingSession
     */
    setTradingSession(tradingSession) {
        if (this.isSet()) {
            this.device.currentTradingSessionID = tradingSession.ID;
            this.cache();
        }
    },

    /**
     * @return {DigiTickets.Device|null}
     * @private
     */
    loadCachedDevice() {
        let cachedDeviceFromLocalStorage = this.loadDeviceFromLocalStorage();
        if (cachedDeviceFromLocalStorage) {
            this.log.log('Loaded current device from local storage.');
            return cachedDeviceFromLocalStorage;
        }
        this.log.log('No current device found in local storage.');

        // Check if there is still a cached device in the Angular cache.
        // Without this everybody would need to do a device reset when this is released.
        // TODO: Remove the angular cache part a few weeks after PRO-1034 is released.
        let cachedDeviceFromAngularCache = this.loadDeviceFromAngularCache();
        if (cachedDeviceFromAngularCache) {
            this.log.log('Loaded current device from Angular cache.');
            return cachedDeviceFromAngularCache;
        }
        this.log.log('No current device found in Angular cache.');

        return null;
    },

    /**
     * @return {DigiTickets.Device|null}
     * @private
     */
    loadDeviceFromLocalStorage() {
        let cachedDevice = this.localStorageDataStore.find(this.localStorageKey);
        if (cachedDevice && cachedDevice.ID) {
            return this.hydrator.hydrate(cachedDevice, new DigiTickets.Device());
        }

        return null;
    },

    /**
     * @return {DigiTickets.Device|null}
     * @private
     */
    loadDeviceFromAngularCache() {
        let cachedDevice = this.currentDeviceCache.get('currentDevice');
        if (cachedDevice && cachedDevice.ID) {
            return this.hydrator.hydrate(cachedDevice, new DigiTickets.Device());
        }

        return null;
    },

    /**
     * Store the current device in the cache.
     *
     * @private
     */
    cache() {
        if (this.device && this.device.ID) {
            this.localStorageDataStore.persist(this.localStorageKey, this.device);
        } else {
            this.localStorageDataStore.remove(this.localStorageKey);
        }

        // Remove from Angular's cache - it's now stored in local storage.
        // TODO: Remove the angular cache part a few weeks after PRO-1034 is released.
        this.currentDeviceCache.remove('currentDevice');
    },

    /**
     * @param {string} deviceGuid
     *
     * @return {Promise<never>}
     */
    saveDeviceGuidToDisk(deviceGuid) {
        if (!deviceGuid) {
            return Promise.reject(new Error('Refused to save empty guid.'));
        }

        return this.agentService.writeFileToDataDir(
            'device-guid.txt',
            deviceGuid
        ).then((result) => {
            this.log.debug('Wrote guid to disk.', deviceGuid);
            return result;
        }).catch((err) => {
            this.log.error('Failed to write guid to disk.', err);
            throw err;
        });
    },

    /**
     * @return {Promise<?string>}
     */
    readDeviceGuidFromDisk() {
        return this.agentService.readFileFromDataDir(
            'device-guid.txt'
        ).then((guid) => {
            return guid;
        }).catch((err) => {
            this.log.error('Failed to read guid from disk.', err);
            throw err;
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CurrentDevice;
}
