/**
 * @param {SoldGiftVoucher} voucher
 * @param {number} amount
 * @returns {SoldGiftVoucher}
 * @throws {Error}
 */
const redeemSoldGiftVoucher = (voucher, amount) => {
    if (!voucher.isRedeemable) {
        throw new Error('This voucher is not currently redeemable');
    }
    if (voucher.isBalanceType && voucher.currentBalance < amount) {
        throw new Error('This voucher does not have enough balance remaining');
    }

    if (voucher.isBalanceType) {
        voucher.currentBalance -= amount;
    }

    // Experience type vouchers can only ever be used once, so always redeem them fully
    if (voucher.currentBalance === 0 || voucher.isExperienceType) {
        voucher.redeemedAt = new Date();
    }

    return voucher;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        redeemSoldGiftVoucher
    };
}
