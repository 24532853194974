const AboutCtrl = require('../../../js/controllers/modal/AboutCtrl');
const AdjustFloatCtrl = require('../../../js/controllers/modal/AdjustFloatCtrl');
const CheckGiftVoucherModalCtrl = require('./modals/CheckGiftVoucherModalCtrl');
const PrintConfigModalCtrl = require('../../../js/controllers/modal/PrintConfigModalCtrl');
const SwitchUserCtrl = require('../../../js/controllers/modal/SwitchUserCtrl');

/**
 * Controller for the top navigation bar.
 *
 * @param $location
 * @param $modal
 * @param $rootScope
 * @param $scope
 * @param AppConfig
 * @param CacheManager
 * @param {CartService} cartService
 * @param {DialogService} DialogService
 * @param LandingPage
 * @param {LangService} LangService
 * @param Logger
 * @param {ModalFactory} ModalFactory
 * @param {NavigationService} navigationService
 * @param Notification
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param OrderManagerService
 * @param {CurrentDevice} CurrentDevice
 * @param PrivilegesService
 * @param ReceiptPrinterService
 * @param RfidScanDetectorService
 * @param {ToastFactory} toastFactory
 * @param TradingSessionManager
 * @param UserService
 */
const NavigationCtrl = function (
    $location,
    $modal,
    $rootScope,
    $scope,
    AppConfig,
    CacheManager,
    cartService,
    DialogService,
    LandingPage,
    LangService,
    Logger,
    ModalFactory,
    navigationService,
    Notification,
    NotificationService,
    ConnectivityChecker,
    OrderManagerService,
    CurrentDevice,
    PrivilegesService,
    ReceiptPrinterService,
    RfidScanDetectorService,
    toastFactory,
    TradingSessionManager,
    UserService
) {
    $scope.userService = UserService;
    $scope.connectivityChecker = ConnectivityChecker;
    $scope.isDev = AppConfig.isDev;
    $scope.isBeta = AppConfig.isBeta;
    $scope.isOnline = false;
    $scope.synchronising = false;
    $scope.offlineOrders = 0;
    $scope.ordersSyncing = false;
    $scope.appConfig = AppConfig;
    $scope.location = $location;
    /**
     * currentDevice IS used in the template, so should in $scope.
     *
     * @type {CurrentDevice}
     */
    $scope.currentDevice = CurrentDevice;
    $scope.privilegesManager = PrivilegesService;
    $scope.cartService = cartService;
    $scope.rfidScanDetector = RfidScanDetectorService;
    $scope.tradingSessionManager = TradingSessionManager;
    $scope.uiState = {
        syncing: false,
        downloadingOrders: false,

        // Nav buttons to display, based on permissions.
        accessManage: false,
        accessRedeem: false,
        accessReport: false,
        accessSell: false,

        // If the user has already been sent to the device's landing page remember so they don't get
        // redirected again if the privileges are reloaded.
        hasRedirected: false
    };
    $scope.switchUserModal = null;
    $rootScope.navigationService = navigationService;

    /**
     * Set up a watcher on the privileges - these ensure the ui state is set once the privileges are loaded
     */
    PrivilegesService.getPrivileges().then(function () {
        let privilegeMappings = {
            access_manage: 'accessManage',
            access_redeem: 'accessRedeem',
            access_report: 'accessReport',
            access_sell: 'accessSell'
        };

        $scope.privilegesManager.checkMultipleCurrentUserPrivileges(
            Object.keys(privilegeMappings)
        ).then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult[]} results
             */
            function (results) {
                for (let privilegeName in results) {
                    if (results.hasOwnProperty(privilegeName)) {
                        let result = results[privilegeName];
                        let propertyName = privilegeMappings[result.privilegeName];
                        $scope.uiState[propertyName] = result.granted;
                    }
                }
            }
        );
    });

    /**
     * 'Download Orders' button clicked.
     */
    $scope.prepareForOffline = function prepareForOffline() {
        let downloadingToast = toastFactory.spinner('NAV.DOWNLOADING_ORDERS');
        $scope.uiState.downloadingOrders = true;

        let collectorFailure = function collectorFailure(message) {
            $scope.uiState.downloadingOrders = false;
            toastFactory.clear(downloadingToast);
            toastFactory.error('NAV.DOWNLOAD_ORDERS_FAILED', message);
        };

        /**
         * @param {int} results.nextPageNumber If nextPageNumber == 0 means no more pages.
         * @param results
         * @param {int} results.downloaded The number of orders downloaded so far
         * (or in total if nextPageNumber is 0).
         * @return {Promise.<TResult>}
         */
        let collector = function collector(results) {
            // If the next page number is zero, then we have all the pages, so we can show the final message.
            if (results.nextPageNumber === 0) {
                $scope.uiState.downloadingOrders = false;
                toastFactory.clear(downloadingToast);
                toastFactory.success('NAV.DOWNLOADED_ORDERS', results.downloaded + ' orders downloaded.');
            } else {
                toastFactory.updateText(downloadingToast, results.downloaded + ' orders downloaded so far.');
                return OrderManagerService
                    .downloadOrdersForOffline(results.nextPageNumber)
                    .then(
                        collector,
                        collectorFailure
                    );
            }
        };

        OrderManagerService.downloadOrdersForOffline(1).then(collector, collectorFailure);
    };

    $scope.connectivityChecker.addObserver({
        key: 'navbar',

        notifyOnline: function notifyOnline() {
            if (!$scope.isOnline) {
                $scope.isOnline = true;
            }

            Logger.info('Now online');
        },

        notifyOffline: function notifyOffline() {
            if ($scope.isOnline) {
                $scope.isOnline = false;
            }

            Logger.info('Now offline');
        }
    });

    /**
     * Display an 'About' modal.
     */
    $scope.about = function about() {
        ModalFactory.open(
            'about',
            AboutCtrl,
            'partials/modals/about.html'
        );
    };

    /**
     * Navigation helper to determine whether to highlight navigational elements
     * based on the current location/route.
     *
     * @param {string} location - leading hash (#) not required for internal links
     * @returns {boolean}
     */
    $scope.locationIs = function locationIs(location) {
        // An exact match on the location, or where the first segment of the location matches.
        return (location === $location.path()) || location + '/' === $location.path().substr(0, location.length + 1);
    };

    /**
     * @param {DigiTickets.Branch} newBranch
     */
    $scope.changeBranch = function changeBranch(newBranch) {
        let oldBranch = UserService.currentBranch;

        Logger.info(
            'Change branch',
            {
                oldBranchID: oldBranch ? oldBranch.ID : null,
                newBranchID: newBranch ? newBranch.ID : null
            }
        );

        UserService.setBranch(newBranch);
    };

    $scope.addToFloat = function addToFloat() {
        $scope.privilegesManager.requirePrivilege('add_cash_to_till').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    let modalText = {
                        adjustmentAmountLabel: LangService.getText('ADJUST_FLOAT.AMOUNT_TO_ADD'),
                        title: LangService.getText('ADJUST_FLOAT.ADD_CASH_TO_TILL')
                    };
                    $scope.adjustFloat(true, result.grantedBy, modalText);
                }
            }
        );
    };

    $scope.removeFromFloat = function removeFromFloat() {
        $scope.privilegesManager.requirePrivilege('remove_cash_from_till').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    // Open the cash drawer and print a receipt straight away.
                    // The receipt includes a blank line to write in the amount taken out.
                    ReceiptPrinterService.printNoSale(0, $scope.title);
                    let modalText = {
                        adjustmentAmountLabel: LangService.getText('ADJUST_FLOAT.AMOUNT_REMOVED'),
                        title: LangService.getText('ADJUST_FLOAT.REMOVE_CASH_FROM_TILL')
                    };
                    $scope.adjustFloat(false, result.grantedBy, modalText);
                }
            }
        );
    };

    $scope.adjustFloat = function adjustFloat(isAdd, authUID, modalText) {
        let modal = $modal.open({
            controller: AdjustFloatCtrl,
            templateUrl: 'partials/modals/adjustFloat.html',
            resolve: {
                floatAdjustmentReasons: function () {
                    return UserService.company.floatAdjustmentReasons.filter((r) => r.withdrawal === !isAdd);
                },
                templateText: function () {
                    return modalText;
                }
            },
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in adjust-float-window'
        });

        modal.result.then(
            (data) => {
                saveFloatAdjustmentTradingSessionEvent(
                    isAdd,
                    authUID,
                    data.floatAdjustment
                );
            },
            () => {}
        );
    };

    /**
     * Save the data returned by the adjustFloat modal.
     *
     * @param {boolean} isAdd
     * @param {number} authUID
     * @param {FloatAdjustment} floatAdjustment
     */
    const saveFloatAdjustmentTradingSessionEvent = (isAdd, authUID, floatAdjustment) => {
        let auditAmount;
        let tradingSessionEvent;

        if (isAdd) {
            auditAmount = floatAdjustment.amount;
            tradingSessionEvent = new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_IN);
        } else {
            auditAmount = 0 - floatAdjustment.amount;
            tradingSessionEvent = new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_OUT);
        }

        $scope.tradingSessionManager.saveEvent(
            tradingSessionEvent
                .setOperatorID(UserService.getUserID())
                .setManagerID(authUID)
                .setAmount(auditAmount)
                .setFloatAdjustmentReasonID(floatAdjustment.floatAdjustmentReason.ID)
                .setNarrative(floatAdjustment.narrative)
        );
        if (isAdd) {
            let receiptTitle = $scope.title;
            if (floatAdjustment.narrative && floatAdjustment.narrative.length > 0) {
                // They entered some detail, so show that as the receipt title.
                receiptTitle = floatAdjustment.floatAdjustmentReason.reason + ': ' + floatAdjustment.narrative;
            }
            // Print receipt with the amount entered.
            ReceiptPrinterService.printNoSale(auditAmount, receiptTitle);
        }
    };

    $scope.checkGiftVoucher = function checkGiftVoucher() {
        ModalFactory.open(
            'check-gift-voucher',
            CheckGiftVoucherModalCtrl,
            'partials/modals/checkGiftVoucherModal.html'
        );
    };

    $scope.showPrintConfig = function showPrintConfig() {
        PrivilegesService
            .requirePrivilege('change_print_settings')
            .then(() => {
                ModalFactory.open(
                    'print-config',
                    PrintConfigModalCtrl,
                    'partials/modals/printConfigModal.html'
                );
            });
    };

    $scope.logout = function logout(withoutConfirmation) {
        if (withoutConfirmation) {
            UserService.logout();
        } else {
            DialogService.confirm(
                'NAV.LOGOUT_CONFIRM',
                function (confirmed) {
                    if (confirmed === true) {
                        Logger.info('Logout clicked');
                        UserService.logout();
                    }
                }
            );
        }
    };

    $scope.noSale = function noSale() {
        $scope.privilegesManager.requirePrivilege('open_cash_drawer').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    ReceiptPrinterService.printNoSale(
                        null,
                        'Open cash drawer'
                    );

                    $scope.tradingSessionManager.saveEvent(
                        (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_DRAWER))
                            .setOperatorID(UserService.ID)
                            .setManagerID(result.grantedBy)
                    );
                }
            }
        );
    };

    /**
     * @param {string} trigger
     */
    $scope.syncData = async function syncData(trigger) {
        if (!$scope.connectivityChecker.isOnline()) {
            toastFactory.error('SYNC.OFFLINE_ERROR');
            Logger.debug('Navigation.syncData - offline', { trigger });
            return;
        }

        await CacheManager.syncClear();
        window.location.reload();
    };

    $scope.syncOrders = function syncOrders() {
        Logger.debug('Navigation.syncOrders');
        $scope.$emit('OrderQueue.actions.process');
    };

    /**
     * Method to initiate a "switch user" event.
     */
    $scope.switchUser = function switchUser() {
        // checking if Switch User Modal is on
        if ($scope.switchUserModal != null) {
            return;
        }

        // Throw up the dialogue box, which will listen for RFID tag scans.
        // Unfortunately, we need to pass quite a lot of the context information
        // in to the modal controller, hence all the resolves.
        $scope.switchUserModal = $modal.open({
            backdrop: 'static',
            keyboard: false,
            templateUrl: 'partials/modals/switchUser.html',
            controller: SwitchUserCtrl,
            windowClass: 'in switch-user-window'
        });

        $scope.switchUserModal.result.finally(function () {
            $scope.switchUserModal = null;
        });
    };

    $scope.$on('OrderQueue.processingComplete', function (event, count) {
        $scope.offlineOrders = count;
        $scope.ordersSyncing = false;

        Logger.debug('Navigation $on(OrderQueue.processingComplete)');
    });

    $scope.$on('OrderQueue.processingStarted', function (event, count) {
        $scope.offlineOrders = count;
        $scope.ordersSyncing = true;

        Logger.debug('Navigation $on(OrderQueue.processingStarted)');
    });

    $scope.$on('OrderQueue.requestAdd', function (event, count) {
        $scope.offlineOrders = count;

        Logger.debug('Navigation $on(OrderQueue.requestAdd)');
    });

    $scope.$on('OrderQueue.requestComplete', function (event, count) {
        $scope.offlineOrders = count;

        Logger.debug('Navigation $on(OrderQueue.requestComplete)');
    });

    $scope.$on('OrderQueue.requestFailed', function (event, count) {
        $scope.offlineOrders = count;

        Logger.debug('Navigation $on(requestFailed.start)');
    });

    $scope.$on('OrderQueue.start', function (event, count) {
        $scope.offlineOrders = count;

        Logger.debug('Navigation $on(OrderQueue.start)');
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = NavigationCtrl;
}
