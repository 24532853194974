const PayOrderBalanceModalCtrl = function (
    $filter,
    $scope,
    $modalInstance,
    $timeout,
    balance,
    CurrencyService
) {
    $scope.balance = parseFloat(balance.toFixed(2));
    $scope.formData = {
        amount: $scope.balance.toFixed(2)
    };
    $scope.currencySymbol = CurrencyService.getSymbol();

    $scope.ok = () => {
        $scope.formData.amount = parseFloat($scope.formData.amount);

        if (!$scope.formData.amount || $scope.formData.amount <= 0) {
            window.alert($filter('lang')('PAY_BALANCE_MODAL.MIN_AMOUNT_INFO'));
            return;
        }

        if ($scope.formData.amount > $scope.balance) {
            window.alert($filter('lang')('PAY_BALANCE_MODAL.MAX_AMOUNT_INFO', { max: $scope.balance }));
            return;
        }

        $modalInstance.close($scope.formData);
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PayOrderBalanceModalCtrl;
}
