/**
 * @param $modalInstance
 * @param $scope
 * @param {CartService} cartService
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {DigiTickets.StashedCart} existingStashedCart
 * @param {DigiTickets.StashedCart} newStashedCart
 * @param {UserService} UserService
 */
const MergeStashedCartModalCtrl = function (
    $modalInstance,
    $scope,
    cartService,
    CartStashHelperService,
    existingStashedCart,
    newStashedCart,
    UserService
) {
    $scope.cartStashHelper = CartStashHelperService;
    $scope.existingStashedCart = existingStashedCart;
    $scope.newStashedCart = newStashedCart;
    $scope.userService = UserService;

    $scope.canMerge = $scope.existingStashedCart.canMerge($scope.newStashedCart);

    $scope.merge = function merge() {
        $modalInstance.close(true);
    };

    $scope.dontMerge = function dontMerge() {
        // Remember that they chose not to merge, in case they go through the hold/restore cycle again
        cartService.currentCart.ignoreDuplicateRef = true;

        $modalInstance.close(false);
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MergeStashedCartModalCtrl;
}
