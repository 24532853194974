const apiErrorMessage = require('../Api/apiErrorMessage');
const User = require('../../../models/User');

/**
 * @param {CurrentDevice} CurrentDevice
 * @param {Hydrator} hydrator
 * @param {DigiTickets.Logger} Logger
 * @param {NavigationService} navigationService
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param UserResource
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
const UserSwitcher = function (
    CurrentDevice,
    hydrator,
    Logger,
    navigationService,
    PrivilegesService,
    TradingSessionManager,
    UserResource,
    UserService,
    UserSessionManager
) {
    /**
     * @private
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;

    /**
     * @private
     * @type {Hydrator}
     */
    this.hydrator = hydrator;

    /**
     * @private
     * @type {DigiTickets.Logger}
     */
    this.logger = Logger;

    /**
     * @private
     * @type {NavigationService}
     */
    this.navigationService = navigationService;

    /**
     * @private
     * @type {DigiTickets.PrivilegesManager}
     */
    this.privilegesService = PrivilegesService;

    /**
     * @private
     * @type {DigiTickets.TradingSessionManager}
     */
    this.tradingSessionManager = TradingSessionManager;

    /**
     * @private
     */
    this.userResource = UserResource;

    /**
     * @private
     * @type {UserService}
     */
    this.userService = UserService;

    /**
     * @private
     * @type {UserSessionManager}
     */
    this.userSessionManager = UserSessionManager;
};

UserSwitcher.prototype = {
    /**
     * Switch to a new user.
     *
     * @param {('pin'|'rfid-tag')} method
     * @param {string|number} enteredValue The PIN or RFID tag value.
     */
    switchUser(method, enteredValue) {
        if (method !== 'pin' && method !== 'rfid-tag') {
            return Promise.reject(new Error("Method must be one of 'pin' or 'rfid-tag'."));
        }

        return new Promise(async (resolve, reject) => {
            // Work out who the new user is. Ask the privileges model to find the user with that PIN or Tag Id.
            const newUserPrivileges = this.privilegesService.findPrivileges(method, enteredValue);

            // Check if it matched a user and that that user is not the one currently logged in.
            if (!newUserPrivileges) {
                /* istanbul ignore else */
                if (method === 'pin') {
                    reject(new Error('No user with that PIN was found.'));
                    return;
                } else if (method === 'rfid-tag') {
                    reject(new Error('No user with that RFID tag was found.'));
                    return;
                }
            }

            // Switching to same user. Nothing to do.
            if (this.userService.currentUser.ID === newUserPrivileges.userID) {
                resolve(this.userService.currentUser);
                return;
            }

            // Get the username of the user we're going to switch to.
            const newUsername = newUserPrivileges.username;

            this.logger.info(`User "${this.userService.username}" requested to switch to user "${newUsername}"`);

            // Stash the state of the current user.
            await this.userSessionManager
                .saveSession(
                    this.userService.currentUser.ID,
                    {
                        lastScreen: this.navigationService.getCurrentPath()
                    }
                )
                .then(() => this.userSessionManager.pruneOldAuthData());

            // Check if we already have a session and apiKey for the other user. If we do, skip the login screen
            // and go straight to that user.
            let newUsersSession = await this.userSessionManager.getSession(newUserPrivileges.userID);

            if (!newUsersSession) {
                reject(new Error('Did not find existing session for new user.'));
                this.redirectToLogin(newUsername);
                return;
            }

            if (!newUsersSession.apiKey) {
                reject(new Error('New user session did not contain an API key.'));
                this.redirectToLogin(newUsername);
                return;
            }

            // Check the new user's API key is still valid by making a request with it.
            this.userResource.check(
                {
                    apiKey: newUsersSession.apiKey
                },
                (response) => {
                    if (response && response.user) {
                        // Successful login.

                        let user = this.hydrator.hydrate(response.user, new User());
                        console.log('New user', user);

                        // Check the new user has access to the current branch.
                        // If they don't we will force a logout.
                        const currentBranchForNewUser = user.getBranchByID(this.userService.currentBranch.ID);
                        if (!currentBranchForNewUser) {
                            reject(new Error('New user does not have access to current branch.'));
                            this.redirectToLogin(newUsername);
                            return;
                        }

                        this.tradingSessionManager.saveEvent(
                            (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.USER_SWITCH))
                                .setOperatorID(response.user.ID)
                        );

                        // Update the current user to be the new one.
                        this.userService.changeUser(user, this.currentDevice.device);

                        this.navigationService.viewEposIndex();
                        resolve(user);
                    } else {
                        // Bad login. Redirect to the login screen, with the "Switching User" message displayed.
                        console.warn('Bad API response for new user API key.', response);
                        reject(new Error('Bad API response for new user API key.'));
                        this.redirectToLogin(newUsername);
                    }
                },
                (result) => {
                    // Bad login. Redirect to the login screen, with the "Switching User" message displayed.
                    reject(new Error('Bad API response for new user API key: ' + apiErrorMessage(result)));
                    this.redirectToLogin(newUsername);
                }
            );
        });
    },

    /**
     * Send user to the login screen, prefill the username, and prompt for password.
     *
     * @private
     * @param {string} autofillUsername
     */
    redirectToLogin(autofillUsername) {
        // Autofill that username on the login page.
        this.userSessionManager.setAutofillLoginUsername(autofillUsername);

        // Redirect to the login screen, with the "Switching User" message displayed.
        this.userService.logout();
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserSwitcher;
}
