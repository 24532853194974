const PaymentMethodRef = Object.freeze({
    BANK_GOCARDLESS_JSFLOW_BACS: 'bank-gocardless-jsflow-bacs',
    CARD_PAYMENT_EXPRESS: 'card-payment-express',
    CARD_OFFLINE: 'card-offline',
    CARD_PAYPAL_HERE: 'card-paypal-here',
    CARD_VERIFONE_CHIP_AND_PIN: 'card-verifone-chip-and-pin',
    CARD_WORLDPAY_CHIP_AND_PIN: 'card-worldpay-chip-and-pin',
    CASH: 'cash',
    CHEQUE: 'cheque',
    FLEXI_VOUCHER: 'flexi-voucher',
    GIFT_VOUCHER: 'gift-voucher',
    INVOICE: 'invoice',
    PAYMENT_ON_ACCOUNT: 'payment-on-account',
    VOUCHER: 'voucher'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentMethodRef;
}
