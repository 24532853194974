/**
 * @param $modalStack
 * @param {DigiTickets.BarcodeScanDetector} BarcodeScanDetectorService
 */
const barcodeListenerDirective = function (
    $modalStack,
    BarcodeScanDetectorService
) {
    return {
        link: function link(scope, element) {
            element.on('keydown', function (event) {
                BarcodeScanDetectorService.keyDownReceived(event, scope);
            });

            /**
             * To mock scanning a barcode:
             * $('body')[0].dispatchEvent(new CustomEvent('mockBarcodeScan', {detail: 'D5WNSVW8'}));
             */
            element.on('mockBarcodeScan', function (event) {
                let value = event.detail;
                BarcodeScanDetectorService.processString(value, event, scope);
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = barcodeListenerDirective;
}
