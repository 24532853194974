const GoogleMapsLoader = require('google-maps');

/**
 * @param $timeout
 * @param {UserService} UserService
 */
const addressAutoCompleteDirective = function ($timeout, UserService) {
    return {
        scope: {
            address: '='
        },

        link: function link($scope, element) {
            function addPlaceEventListener(autoCompleteInstance, addressElements, address, google) {
                // Place change is fired when an address from the autocomplete is selected
                google.maps.event.addListener(autoCompleteInstance, 'place_changed', function () {
                    const place = autoCompleteInstance.getPlace();
                    if (!place || !place.geometry) {
                        addressElements.addressSearchError.show();
                        addressElements.addressSearchErrorRow.show();
                        return;
                    }

                    emptyAddressValues(address);

                    addressElements.addressSearchError.hide();
                    addressElements.addressSearchErrorRow.hide();
                    displayResults(prepareAddress(place.address_components), address);
                });
            }

            /**
             * @param {object} results
             * @param {DigiTickets.Address} addressModel
             */
            function displayResults(results, addressModel) {
                $scope.$apply(function () {
                    for (let key in results) {
                        if (!results.hasOwnProperty(key) || addressModel[results[key].property] !== '') {
                            continue;
                        }

                        addressModel[results[key].property] = results[key].value;
                    }
                });
            }

            /**
             * Options to control and filter the results of the Autocomplete
             *
             * By default the Autocomplete.getPlace() will return results from multiple APIs and return things
             * like ratings, reviews, opening hours, and phone numbers which are all chargeable
             *
             * We can exclude unnecessary data and limit costs by using the 'fields' option and reinforcing this
             * with the 'types' option (which also improves search quality by excluding businesses)
             *
             * By excluding unwanted data the free $200 credit should provide 11000 searches / month vs 8000
             *
             * @see https://developers.google.com/places/web-service/autocomplete#place_types
             * @see https://developers.google.com/places/web-service/details#fields
             * @see https://cloud.google.com/maps-platform/pricing/sheet/
             *
             * @param country
             */
            function getOptions(country) {
                let options = {};

                // type: address will only search a full address e.g. 6 some street etc.
                // type: geocode is required to search by postcode and also excludes things like businesses
                options.types = ['geocode'];

                // we should only need fields from the 'Basic' category
                options.fields = [
                    'address_component',
                    'formatted_address',
                    'geometry',
                ];

                if (country) {
                    options.componentRestrictions = {
                        country
                    };
                }

                return options;
            }

            function prepareAddress(addressComponents) {
                let addressData = {
                    street_number: {
                        property: 'house',
                        value: '',
                        name: 'long_name'
                    },
                    premise: {
                        property: 'house',
                        value: '',
                        name: 'long_name'
                    },
                    route: {
                        property: 'street',
                        value: '',
                        name: 'long_name'
                    },
                    locality: {
                        property: 'town',
                        value: '',
                        name: 'long_name'
                    },
                    postal_town: {
                        property: 'town',
                        value: '',
                        name: 'long_name'
                    },
                    postal_code: {
                        property: 'postcode',
                        value: '',
                        name: 'long_name'
                    },
                    postal_code_prefix: {
                        property: 'postcode',
                        value: '',
                        name: 'long_name'
                    },
                    administrative_area_level_1: {
                        property: 'state',
                        value: '',
                        name: 'short_name'
                    },
                    administrative_area_level_2: {
                        property: 'region',
                        value: '',
                        name: 'long_name'
                    }
                };

                for (let i = 0; i < addressComponents.length; i++) {
                    let addressType = addressComponents[i].types[0];
                    if (addressData[addressType]) {
                        if (addressData[addressType].value === '') {
                            addressData[addressType].value = addressComponents[i][addressData[addressType].name];
                        }
                    }
                }

                return addressData;
            }

            function emptyAddressValues(address) {
                address.house = '';
                address.street = '';
                address.town = '';
                address.postcode = '';
                address.county = '';
            }

            $timeout(
                function () {
                    if (UserService.company.googleCloudApiKey) {
                        let $element = $(element); // jQuery
                        let addressElements = {
                            country: $element.find('.fieldCountry'),
                            house: $element.find('.fieldHouse'),
                            street: $element.find('.fieldStreet'),
                            town: $element.find('.fieldTown'),
                            postcode: $element.find('.fieldPostcode'),
                            region: $element.find('.fieldRegion'),
                            state: $element.find('.fieldState'),
                            formattedAddress: $element.find('.fieldFormattedAddress'),
                            addressSearch: $element.find('#fieldAddressSearch'),
                            addressSearchError: $element.find('#address-search-error'),
                            addressSearchErrorRow: $element.find('#address-search-error')
                        };

                        GoogleMapsLoader.VERSION = '3.34';
                        GoogleMapsLoader.LIBRARIES = ['places'];
                        GoogleMapsLoader.KEY = UserService.company.googleCloudApiKey;
                        GoogleMapsLoader.load(function (google) {
                            let countryCode = $scope.address && $scope.address.country && $scope.address.country.code ? $scope.address.country.code : null;
                            let autoComplete = new google.maps.places.Autocomplete(
                                addressElements.addressSearch[0], getOptions(countryCode)
                            );

                            addPlaceEventListener(autoComplete, addressElements, $scope.address, google);
                            $('.addressSearch').show();

                            $scope.$watch('address.country', function handleWatchChange(newCountry) {
                                if (!newCountry || countryCode === newCountry.code) {
                                    return;
                                }

                                google.maps.event.clearListeners(autoComplete, 'place_changed');
                                countryCode = newCountry.code;
                                autoComplete = new google.maps.places.Autocomplete(
                                    addressElements.addressSearch[0], getOptions(countryCode)
                                );

                                addPlaceEventListener(autoComplete, addressElements, $scope.address, google);
                            });
                        });
                    }
                },
                1
            );
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = addressAutoCompleteDirective;
}
