const FieldGroupCollection = require('./FieldGroupCollection');
const { getAllFieldGroupsFromOrder } = require('./fieldFunctions');

/**
 * Returns a FieldGroupCollection which contains all the FieldGroups from an order.
 *
 * @param {DigiTickets.Order} order
 *
 * @return {FieldGroupCollection}
 */
const createFieldGroupCollectionFromOrder = (order) => {
    let collection = new FieldGroupCollection();

    collection.addFieldGroups(getAllFieldGroupsFromOrder(order));

    return collection;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        createFieldGroupCollectionFromOrder
    };
}
