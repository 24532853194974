const UserFacingError = require('../libraries/DigiTickets/Errors/UserFacingError');
const { toFloat, toInt, toString } = require('../functions/transform');

/**
 * VariableDiscount contains the amount and narrative of a variable offer for a specific order line.
 *
 * @param {number} [offerID]
 */
const VariableDiscount = function (offerID) {
    if (offerID !== undefined) {
        offerID = parseInt(offerID);

        if (isNaN(offerID) || offerID < 1) {
            throw new Error('offerID must be positive integer');
        }
    }

    /**
     * @type {int}
     */
    this.offerID = offerID;

    /**
     * @type {number}
     */
    this.amount = 0.00;

    /**
     * @type {string}
     */
    this.narrative = '';
};

VariableDiscount.prototype = {

    /**
     * @param {number} amount
     */
    setAmount(amount) {
        amount = parseFloat(amount);

        if (isNaN(amount) || amount === 0) {
            throw new UserFacingError('Amount must be a number', 'VARIABLE_DISCOUNT.AMOUNT_MUST_BE_NUMBER');
        }

        this.amount = amount;
    },

    /**
     * @param {string} narrative
     */
    setNarrative(narrative) {
        if (!narrative || narrative.trim() === '') {
            throw new UserFacingError('Discount reason is required', 'VARIABLE_DISCOUNT.NARRATIVE_REQUIRED');
        }

        this.narrative = narrative;
    },

    /**
     * Return the amount formatted for displaying on the discount's button.
     * (Sign is flipped)
     *
     * @return {number}
     */
    getDisplayAmount() {
        return -this.amount;
    },

    getHydrationMap() {
        return {
            offerID: toInt,
            amount: toFloat,
            narrative: toString
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VariableDiscount;
}
