const { toString } = require('../../../functions/transform');

const Reservation = function () {
    /**
     * @type {string}
     */
    this.token = '';
};

Reservation.prototype = {
    getHydrationMap() {
        return {
            token: toString
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Reservation;
}
