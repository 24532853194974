const BigNumber = require('bignumber.js');

const SalesTotals = function () {
    /**
     * This object holds all the underlying data for the totals.
     * It starts as the structure of returned by makeBlankStatGroup().
     *
     * As totals are added they are added to the values, and additional keys are added to index by itemType,
     * salesType, and itemType + salesType.
     *
     * This is best explained with an example.
     * If we assume makeBlankStatGroup returned {lines: 0}
     * Then call
     * addToTotals('Ticket', 'Sale', 'lines', 1)
     * this.totals would look like:
     * {
     *     lines: 1,
     *     Ticket: {
     *         lines: 1,
     *         Sale: {
     *             lines: 1
     *         }
     *     },
     *     Sale: {
     *         lines: 1,
     *     }
     * }
     *
     * @type {{}}
     */
    this.totals = {};
    this.reset();
};

SalesTotals.prototype = {
    /**
     * saleTypes
     */
    SALE_TYPES: {
        SALE: 'Sale',
        RETURN: 'Return'
    },

    /**
     * 'whichTotal' values
     */
    TOTAL_TYPES: {
        ORDERS: 'orders',
        QTY: 'qty',
        LINES: 'lines',
        BASE_TOTAL: 'baseTotal',
        DISCOUNT: 'discount',
        LINE_TOTAL: 'lineTotal',
        PEOPLE: 'peopleTotal'
    },

    makeBlankStatGroup() {
        let stats = {};
        stats[this.TOTAL_TYPES.ORDERS] = 0;
        stats[this.TOTAL_TYPES.QTY] = 0;
        stats[this.TOTAL_TYPES.LINES] = 0;
        stats[this.TOTAL_TYPES.BASE_TOTAL] = 0;
        stats[this.TOTAL_TYPES.DISCOUNT] = 0;
        stats[this.TOTAL_TYPES.LINE_TOTAL] = 0;
        stats[this.TOTAL_TYPES.PEOPLE] = 0;

        return stats;
    },

    /**
     * @private
     *
     * Get a single total from a set of totals.
     *
     * @param {string|null} itemType
     * @param {string|null} saleType
     * @param {string} whichTotal
     *
     * @returns {number}
     */
    getTotal(itemType, saleType, whichTotal) {
        itemType = typeof itemType === 'undefined' ? null : itemType;
        saleType = typeof saleType === 'undefined' ? null : saleType;

        if (saleType) {
            this.validateSaleType(saleType);
        }

        // Only a single total is retrieved so that must exist in the totals set.
        this.validateTotalType(whichTotal);

        // Both itemType and saleType are being asked for.
        if (itemType != null && saleType != null) {
            return (this.totals.hasOwnProperty(itemType) && this.totals.hasOwnProperty(saleType)) ? this.totals[itemType][saleType][whichTotal] : 0;
        } else
        // Only the itemType total is being asked for.
        if (itemType != null && saleType == null) {
            return (this.totals.hasOwnProperty(itemType)) ? this.totals[itemType][whichTotal] : 0;
        } else
        // Only the saleType total is being asked for.
        if (itemType == null && saleType != null) {
            return (this.totals.hasOwnProperty(saleType)) ? this.totals[saleType][whichTotal] : 0;
        }
        return this.totals[whichTotal];
    },

    /**
     * Add a value to all the applicable totals.
     *
     * @param {string} itemType
     * @param {string} saleType
     * @param {string} whichTotal
     * @param {number} value
     */
    addToTotals(itemType, saleType, whichTotal, value) {
        this.validateSaleType(saleType);
        this.validateTotalType(whichTotal);

        // Create the totals[itemType] substructure.
        if (!this.totals.hasOwnProperty(itemType)) {
            this.totals[itemType] = this.makeBlankStatGroup();
        }

        // Create the totals[saleType] substructure.
        if (!this.totals.hasOwnProperty(saleType)) {
            this.totals[saleType] = this.makeBlankStatGroup();
        }

        // Create the totals[itemType][saleType] substructure.
        if (!this.totals[itemType].hasOwnProperty(saleType)) {
            this.totals[itemType][saleType] = this.makeBlankStatGroup();
        }

        this.addValueToStructure(whichTotal, value, this.totals);
        this.addValueToStructure(whichTotal, value, this.totals[itemType]);
        this.addValueToStructure(whichTotal, value, this.totals[itemType][saleType]);
        this.addValueToStructure(whichTotal, value, this.totals[saleType]);
    },

    validateSaleType(saleType) {
        if (Object.values(this.SALE_TYPES).indexOf(saleType) === -1) {
            throw new Error(`Unknown sale type (${saleType})`);
        }
    },

    validateTotalType(whichTotal) {
        // We validate this by making sure it exists in the base totals.
        if (!this.totals.hasOwnProperty(whichTotal)) {
            throw new Error(`Unknown total type (${whichTotal})`);
        }
    },

    /**
     * @private
     *
     * Actually does the addition of `value` to `structure[whichTotal]`
     *
     * @param {string} whichTotal
     * @param {object} structure
     * @param {number} value
     */
    addValueToStructure(whichTotal, value, structure) {
        structure[whichTotal] = (new BigNumber(structure[whichTotal]))
            .plus(value)
            .toNumber();

        // This seems to be a hack to increment the order count if there are any non-zero qty lines in the cart.
        // The only time the order total is queried is in the cartService.isEmpty method
        if (whichTotal == 'lines' && structure.orders == 0) {
            ++structure.orders;
        }
    },

    /**
     * Get the Order totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getOrders(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.ORDERS);
    },

    /**
     * Get the quantity totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getQty(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.QTY);
    },

    /**
     * Get the lines totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getLines(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.LINES);
    },

    /**
     * Get the base totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getBaseTotal(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.BASE_TOTAL);
    },

    /**
     * Get the discounts totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getDiscount(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.DISCOUNT);
    },

    /**
     * Get the line totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getLineTotal(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.LINE_TOTAL);
    },

    /**
     * Get the line totals.
     *
     * @param {?string} [itemType]
     * @param {?string} [saleType]
     */
    getPeopleTotal(itemType, saleType) {
        return this.getTotal(itemType, saleType, this.TOTAL_TYPES.PEOPLE);
    },

    /**
     * Reset the totals.
     */
    reset: function reset() {
        this.totals = this.makeBlankStatGroup();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SalesTotals;
}
