/**
 * This handles calling the cancel method in the MemberService and dealing with the result.
 * This class exists so this cancellation specific code can be separate from the SellCtrl which calls it.
 *
 * @param $filter
 * @param {MembershipService} membershipService
 */
DigiTickets.MembershipCancellationRunner = function (
    $filter,
    membershipService
) {
    this.$filter = $filter;
    this.membershipService = membershipService;
};

DigiTickets.MembershipCancellationRunner.prototype = {

    cancel: function cancel(memberships, callback) {
        let self = this;

        this.membershipService.cancelMemberships(
            memberships,
            function (response, memberships) {
                self.onComplete(memberships, callback);
            }
        );
    },

    onComplete: function onComplete(memberships, callback) {
        // Check all the memberships have been cancelled.
        let uncancelledMemberships = memberships.filter(
            function (membership) {
                return membership.isActive();
            }
        );
        let cancelledMemberships = memberships.filter(
            function (membership) {
                return !membership.isActive();
            }
        );

        let messages = [];

        if (uncancelledMemberships.length > 0) {
            // Alert the user that not all of them have been cancelled.
            let uncancelledRefs = this.membershipService.generateMembershipRefsString(uncancelledMemberships);

            messages.push({
                type: 'danger', // bootstrap class
                text: this.$filter('lang')('MEMBERSHIP_CANCELLATION.NOT_CANCELLED_ERROR', {
                    refs: uncancelledRefs
                })
            });
        }

        if (cancelledMemberships.length > 0) {
            // Show which memberships were cancelled.
            let cancelledRefs = this.membershipService.generateMembershipRefsString(cancelledMemberships);

            messages.push({
                type: 'success', // bootstrap class
                text: this.$filter('lang')('MEMBERSHIP_CANCELLATION.CANCELLED_ALERT', {
                    refs: cancelledRefs
                })
            });
        }

        callback(cancelledMemberships, uncancelledMemberships, messages);
    }
};
