const presentSoldGiftVoucherRefFilter = require('../../../js/filters/presentSoldGiftVoucherRef');
const SoldGiftVoucher = require('../../../js/models/SoldGiftVoucher');

const CheckGiftVoucherDirective = function () {
    return {
        scope: {
            /**
             * Pass in a function to be called when a Gift Voucher is entered.
             */
            onSelect: '=',
            /**
             * Pass in a function to be called when this directive's state changes
             */
            onChange: '=',
            /**
             * Pass in a function to be called instead of the usual look-up behaviour
             */
            cache: '=',
            /**
             * Optional string representing the default gift voucher reference. In practise, allows this
             * directive's state to be reset by an external source.
             */
            ref: '@'
        },
        restrict: 'E',
        replace: true,
        templateUrl: 'partials/directives/dt-check-gift-voucher.html',
        controller: function (
            $scope,
            SoldGiftVoucherManager
        ) {
            $scope.SoldGiftVoucherRedeemableStatus = DigiTickets.SoldGiftVoucherRedeemableStatus;

            /**
             * @type {boolean}
             */
            $scope.searching = false;

            /**
             * Will be false if a search has run and nothing was found. Null if no search has run yet.
             *
             * @type {SoldGiftVoucher|false|null}
             */
            $scope.soldGiftVoucher = null;

            $scope.$watch('ref', function (newRef, oldRef) {
                $scope.formatRef();
                if (presentSoldGiftVoucherRefFilter(newRef) !== presentSoldGiftVoucherRefFilter(oldRef)) {
                    $scope.soldGiftVoucher = null;
                    if ($scope.onChange instanceof Function) {
                        $scope.onChange();
                    }
                }
            });

            /**
             * Called when the search input changes.
             */
            $scope.formatRef = function formatRef() {
                $scope.ref = presentSoldGiftVoucherRefFilter($scope.ref);
            };

            /**
             * Runs the search on the API.
             */
            $scope.search = function search() {
                $scope.searching = true;

                if ($scope.cache instanceof Function) {
                    const result = $scope.cache($scope.ref);
                    if (result instanceof SoldGiftVoucher) {
                        $scope.setSoldGiftVoucher(result);
                        $scope.searching = false;
                        return;
                    }
                }

                SoldGiftVoucherManager.findByRef(
                    $scope.ref,
                    function (soldGiftVoucher) {
                        $scope.setSoldGiftVoucher(soldGiftVoucher || false);
                        $scope.searching = false;
                    }
                );
            };

            /**
             * @param {SoldGiftVoucher|false|null} soldGiftVoucher
             */
            $scope.setSoldGiftVoucher = function setSoldGiftVoucher(soldGiftVoucher) {
                $scope.soldGiftVoucher = soldGiftVoucher;
                console.log('setSoldGiftVoucher', soldGiftVoucher, typeof $scope.onSelect, $scope.onSelect);
                if (typeof $scope.onSelect === 'function') {
                    $scope.onSelect(soldGiftVoucher || null);
                }
            };
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CheckGiftVoucherDirective;
}
