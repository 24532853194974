const _ = require('lodash');

/**
 * @param $scope
 * @param {CustomerAccountSearchCache} customerAccountSearchCache
 * @param {CustomerAccountService} CustomerAccountService
 * @param {NavigationService} navigationService
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {ToastFactory} toastFactory
 */
const ManageCtrl = function (
    $scope,
    customerAccountSearchCache,
    CustomerAccountService,
    navigationService,
    CurrentDevice,
    PrivilegesService,
    toastFactory
) {
    navigationService.showNav();

    // Early exit if no access permission
    PrivilegesService.requirePrivilegeOr403('access_manage');

    $scope.navigationService = navigationService;

    /**
     * @type {CustomerAccountService}
     */
    $scope.customerAccountsService = CustomerAccountService;

    $scope.pagination = {
        pageSize: 5,
        currentPage: 1
    };

    /**
     * @type {CustomerAccountSearchCache}
     */
    $scope.searchCache = customerAccountSearchCache;

    $scope.minQueryLength = 3;

    $scope.formData = {
        // Load the cached query if there is one.
        query: customerAccountSearchCache.form.query
    };

    $scope.hasSearched = false;

    $scope.error = false;

    $scope.showLoader = false;

    $scope.clear = function clear() {
        customerAccountSearchCache.clear();
        $scope.formData.query = '';

        if ($(window).width() > 650) {
            $scope.focusSearchInput();
        }
    };

    $scope.isValidSearchQuery = function isValidSearchQuery(query) {
        return query.length >= $scope.minQueryLength;
    };

    // Any time search is invoked, we want to wait a very short time in case it's called
    // again. This is because when a bar code is scanned, the keypresses are sent to the
    // browser, which invokes the search, then the bar code listener invokes the search.
    // The former may not have the right criteria in the form, so we want to suppress that
    // first search. We also don't want to run the same search multiple times!
    $scope.search = function search() {
        if (!$scope.isValidSearchQuery($scope.formData.query)) {
            toastFactory.warningTop('MIN_QUERY_LENGTH_ERROR');
            return;
        }
        $scope.debouncedSearch();
    };

    $scope.uiState = {
        focusOnSearch: CurrentDevice.device.focusOnSearch
    };

    $scope.focusSearchInput = function focusSearchInput() {
        let searchInput = document.getElementById('search');
        if (searchInput) {
            searchInput.focus();
            searchInput.select();
            searchInput.setSelectionRange(0, searchInput.value.length); // mobile safari;
        }
    };

    // This is mainly for Mobile devices, it removes the keyboard from taking up half the screen
    $scope.unfocusSearchInput = function unfocusSearchInput() {
        let searchInput = document.getElementById('search');
        if (searchInput) {
            searchInput.blur();
        }
    };

    $scope.doSearch = function doSearch() {
        customerAccountSearchCache.clear();

        if (!$scope.isValidSearchQuery($scope.formData.query)) {
            return false;
        }

        $scope.error = false;
        $scope.hasSearched = true;
        $scope.showLoader = true;
        $scope.pagination.currentPage = 1;

        let searchParams = {
            q: $scope.formData.query,
            searchMemberships: 1,
            searchOrders: 1,
            resolve: '*'
        };

        CustomerAccountService.query(
            searchParams,
            function (results) {
                $scope.showLoader = false;

                storeResultsInCache(results);

                // If only 1 result, go straight into the "View" for that order or membership.
                if (results.length === 1) {
                    navigationService.viewAccountDetails(results[0].ID);
                }

                // If screen is small enough to be a mobile device we don't want to leave the focus on the input as the keyboard takes up half the screen
                if ($(window).width() > 650) {
                    $scope.focusSearchInput();
                } else {
                    $scope.unfocusSearchInput();
                }
            },
            function (error) {
                $scope.order.wasBarcodeScan = false;
                $scope.error = 'An error occurred whilst searching, please check your internet connection.';
                $scope.showLoader = false;
                $scope.focusSearchInput();
            }
        );
    };

    $scope.debouncedSearch = _.debounce($scope.doSearch, 1000, {
        leading: true,
        trailing: false
    });

    /**
     * @param {DigiTickets.CustomerAccount[]} results
     */
    const storeResultsInCache = (results) => {
        customerAccountSearchCache.clear();
        customerAccountSearchCache.form.query = $scope.formData.query;
        customerAccountSearchCache.results = results;
    };

    // Process the bar code if scanned.
    $scope.$on('barcodeScanned', function (event, response) {
        if (response.openModal !== false) {
            // Don't do anything on this page if a modal is open on top of it.
            return;
        }
        let barCode = response.code;
        if (barCode.length > 0) {
            $scope.formData.query = barCode;
            $scope.search();
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ManageCtrl;
}
