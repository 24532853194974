const TradingSessionResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'tradingsessions/:id',
        ApiRequestParams.defaults(),
        {
            // 'query' to GET /tradingsessions (with ?deviceID filter)
            // 'save' to POST /tradingsessions and start a new session
            // 'remove' to DELETE /tradingsessions/{id} and start a new session,
            // 'get' to GET /tradingsessions/{id} and show info about the session
            saveEvent: {
                url: AppConfig.apiBaseUrl + 'tradingsessionevents',
                method: 'POST'
            },

            getSummary: {
                url: AppConfig.apiBaseUrl + 'tradingsessionsummaries/:id',
                method: 'GET',
                isArray: false
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TradingSessionResource;
}
