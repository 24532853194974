/**
 @typedef PaymentResource
 @type {Object}
 @property {function(object, object, function, function)} store
 */

/**
 * @param $resource
 * @param ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 */
const PaymentResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'payments/:id',
        ApiRequestParams.defaults(),
        {
            store: {
                method: 'POST',
                url: AppConfig.apiBaseUrl + 'payments'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentResource;
}
