/**
 * Fetches the company's themes from the API and generates CSS for different screens based on the theme.
 *
 * @param $rootScope
 * @param {Hydrator} hydrator
 * @param ThemeResource
 */
const ThemeManager = function (
    $rootScope,
    hydrator,
    ThemeResource
) {
    this.$rootScope = $rootScope;
    this.hydrator = hydrator;
    this.themeResource = ThemeResource;

    /**
     * @type {DigiTickets.Theme|null}
     */
    this.defaultTheme = null;

    /**
     * @type {boolean}
     */
    this.isLoaded = false;

    /**
     * @type {DigiTickets.Theme[]}
     */
    this.themes = [];
};

ThemeManager.prototype = {

    /**
     * Load themes from the API.
     * The active theme will be the first one in the list.
     *
     * @param callback
     */
    loadThemes: function (callback) {
        let self = this;

        if (this.isLoaded) {
            callback(this.themes, this.defaultTheme);
            return;
        }

        this.themeResource.query(
            function (results) {
                self.themes = self.hydrator.hydrateArray(
                    results,
                    function () {
                        return new DigiTickets.Theme();
                    }
                );

                // The results are ordered by active first. So if the first item is not active
                // there is no default theme.
                self.defaultTheme = self.themes.length > 0 && self.themes[0].active ? self.themes[0] : null;

                if (callback) {
                    callback(self.themes, self.defaultTheme);
                }

                self.isLoaded = true;
            }
        );
    },

    /**
     * Returns the theme with the specified ID, or the default theme if not found.
     *
     * @param {number|null} themeID
     * @param {function} callback
     */
    getTheme: function (themeID, callback) {
        this.loadThemes(
            function (themes, defaultTheme) {
                if (themeID) {
                    for (let i = 0; i < themes.length; i++) {
                        if (themes[i].ID === themeID) {
                            callback(themes[i]);
                            return;
                        }
                    }
                }

                callback(defaultTheme);
            }
        );
    },

    /**
     * Load the CSS for the given theme using the given stylesheet name (frontend, kitchen-customer etc.).
     * See ThemeStyleFactory in app for more info on themes/stylesheets.
     *
     * @param {DigiTickets.Theme} theme
     * @param {string} stylesheet
     */
    enableTheme: function (theme, stylesheet) {
        let url = theme.getStylesheetUrl(stylesheet);
        if (url) {
            this.$rootScope.customStylesheets.push(url);
        }
    },

    /**
     * Remove the CSS for the given theme's stylesheet.
     *
     * Not currently used but possibly useful in future.
     * Kept in as a demo of how to remove a stylesheet; it has been tested :)
     *
     * @param {DigiTickets.Theme} theme
     * @param {string} stylesheet
     */
    disableTheme: function (theme, stylesheet) {
        let url = theme.getStylesheetUrl(stylesheet);
        if (url) {
            let index = this.$rootScope.customStylesheets.indexOf(url);
            if (index !== -1) {
                this.$rootScope.customStylesheets.splice(index, 1);
            }
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ThemeManager;
}
