const PrintRouting = require('./PrintRouting');
const PrintDriverRefs = require('./PrintDriverRefs');
const PrintType = require('./PrintType');

const PrintRouter = function (

) {
    /**
     * Where documents are sent by default.
     *
     * @type {PrintRouting}
     */
    this.defaultRouting = new PrintRouting(PrintDriverRefs.PRINT);

    /**
     * @type {Object<PrintRouting>}
     */
    this.typeRoutings = {};

    /**
     * For these refs you can select a printer name to sent it to. Otherwise we have no control over which
     * printer it goes to from the browser.
     *
     * @private
     * @type {string[]}
     */
    this.canSelectPrinterForDriverRefs = [
        PrintDriverRefs.AGENT,
        PrintDriverRefs.PRINTER_APP,
    ];
};

PrintRouter.prototype = {
    /**
     * Reset routings to the global default.
     */
    reset() {
        this.defaultRouting = new PrintRouting(PrintDriverRefs.PRINT);
        this.typeRoutings = {};
    },

    /**
     * Create a PrintRouting object from an object of keys/values (probably from a saved config file).
     *
     * @param {PrintRouting|{driverRef: string, printerName: ?string}} routingData
     *
     * @return {PrintRouting}
     */
    createPrintRoutingFromData(routingData) {
        let validDriverRefs = Object.values(PrintDriverRefs);

        // Ensure valid driver ref.
        // Throws an error for invalid refs.
        let driverRef = routingData.driverRef;
        if (driverRef !== null && validDriverRefs.indexOf(driverRef) === -1) {
            throw new Error(`Unknown print driverRef '${driverRef}'`);
        }

        let printerName = this.canSelectPrinterForDriverRef(driverRef) ? routingData.printerName : null;

        return new PrintRouting(driverRef, printerName);
    },

    /**
     * @param {PrintRouting|{driverRef: string, printerName?: ?string}} routingData
     */
    setDefaultRouting(routingData) {
        this.defaultRouting = this.createPrintRoutingFromData(routingData);
    },

    /**
     * Takes an object of routings for print types (loaded from a file), converts them to PrintRouting objects,
     * and stores them as this.routings.
     *
     * @param {Object<{driverRef: string, ?printerName:?string}>} routings
     */
    setTypeRoutings(routings) {
        let newRoutings = {};
        Object.keys(routings).forEach((type) => {
            // Skip unknown print types.
            // This does not throw an error because we may end up with old/new types in a config that we just
            // don't care about anymore/yet.
            if (!PrintType.hasOwnProperty(type)) {
                return;
            }
            let printRouting = this.createPrintRoutingFromData(routings[type]);
            // Skip types that have a null print routing because they should use the default.
            if (printRouting.driverRef) {
                newRoutings[type] = printRouting;
            }
        });
        this.typeRoutings = newRoutings;
    },

    /**
     * @param {string} printType
     *
     * @return {PrintRouting}
     */
    getRoutingForType(printType) {
        if (printType && this.typeRoutings.hasOwnProperty(printType)) {
            return this.typeRoutings[printType];
        }

        return this.defaultRouting;
    },

    /**
     * @param {DigiTickets.Device} device
     */
    loadFromDevice(device) {
        this.reset();

        console.log('loadFromDevice', device);

        if (device.printConfig) {
            let config = JSON.parse(device.printConfig);
            if (config && config.hasOwnProperty('routing')) {
                if (config.routing.hasOwnProperty('default')) {
                    this.setDefaultRouting(config.routing.default);
                }
                if (config.routing.hasOwnProperty('types')) {
                    this.setTypeRoutings(config.routing.types);
                }
            }
            console.log('Loaded print config from device.', this);
        } else if (device.printMethod) {
            this.setDefaultRouting(
                {
                    driverRef: device.printMethod.driverRef
                }
            );
            console.log('Set default print routing from device\'s paymentMethod.', this);
        }
    },

    /**
     * Returns an object for the config that can be put into a device's "printConfig" field.
     *
     * @return {{default: PrintRouting, types: {string: PrintRouting}}}
     */
    exportConfig() {
        return {
            default: this.defaultRouting,
            types: this.typeRoutings
        };
    },

    /**
     * @param {string} driverRef
     * @return {boolean}
     */
    canSelectPrinterForDriverRef(driverRef) {
        return this.canSelectPrinterForDriverRefs.indexOf(driverRef) !== -1;
    }

    /**
     * Read the routing from a JSON file on disk.
     *
     * @return {Promise<void>}
     */
    // loadFromDisk() {
    //     return this.agentService.readFile(
    //         this.configPath
    //     ).then(
    //         data => {
    //             if (data) {
    //                 console.log('Found existing print config', data);
    //                 try {
    //                     data = JSON.parse(data);
    //                     if (data.hasOwnProperty('default')) {
    //                         this.setDefaultRouting(data.default);
    //                     }
    //                     if (data.hasOwnProperty('types')) {
    //                         this.setTypeRoutings(data.types);
    //                     }
    //                 } catch (e) {
    //                     console.error('Failed to use existing print config', e);
    //                 }
    //             }
    //         },
    //         err => {
    //             // It's okay if the file doesn't exist.
    //             console.warn('No existing print config found.', err);
    //         }
    //     );
    // },

    /**
     * Write the current routing config to a file.
     *
     * @return {Promise<void>}
     */
    // persistToDisk() {
    //     const config = {
    //         default: this.defaultRouting,
    //         types: this.typeRoutings
    //     };
    //
    //     const str = JSON.stringify(config);
    //     console.log('Saving print config', str);
    //     return this.agentService.writeFile(
    //         this.configPath,
    //         str
    //     ).then(
    //         () => {
    //             console.log('Saved print config.');
    //         },
    //         err => {
    //             console.error('Failed to save print config.', err);
    //         }
    //     );
    // }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintRouter;
}
