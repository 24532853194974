/**
 * A class to provide some structure the the various 'opts' being passed between print methods.
 * The most common options can be given in the constructor.
 *
 * @param {?string} [printType]
 * @param {?Function} [completionCallback]
 */
const PrintJobOptions = function (printType, completionCallback) {
    /**
     * A callback for when the job has finished printing.
     * !!! This should no longer be used! The print methods return a promise so wait for that to resolve instead.
     *
     * @type {?Function}
     */
    this.completion = completionCallback || null;

    /**
     * Array of CSS classes to add to the printout.
     * (Not implemented in PRO-530. This is for compatibility with PRO-495).
     *
     * @type {string[]}
     */
    this.classes = [];

    /**
     * Callback for just before the job is sent to the printer.
     *
     * @type {?Function}
     */
    this.onBeforePrintCall = null;

    /**
     * Open the cash drawer when printing?
     * This only has an effect for Star printers.
     *
     * @type {boolean}
     */
    this.openCashDrawer = true;

    /**
     * One of the PrintType constants.
     *
     * @see {PrintType}
     *
     * @type {?string}
     */
    this.printType = printType;

    /**
     * @type {PrintRouting}
     */
    this.printRouting = null;

    /**
     * Should a dot be added at the bottom of the printout to fix the bottom margin not appearing?
     * Default is true.
     *
     * @type {boolean}
     */
    this.addFooterDot = true;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintJobOptions;
}
