// FIXME: Package has no exports
// const html2canvas = require('html2canvas');
const { StarWebPrintBuilder, StarWebPrintTrader } = require('digitickets.star-web-print');

DigiTickets.StarPrintDriver = function (
    $q,
    Notification,
    ReceiptPrinterService
) {
    this.$q = $q;
    this.notification = Notification;
    this.receiptPrinterService = ReceiptPrinterService;
};

DigiTickets.StarPrintDriver.prototype = {
    /**
     * @param {Window} frame
     * @param {PrintJobOptions} opts
     *
     * @return {promise|jQuery.promise|*|{then, catch, finally}}
     */
    print: function (frame, opts) {
        let deferred = this.$q.defer();
        let self = this;

        html2canvas(
            frame.document.documentElement.children[1].firstElementChild.firstElementChild,
            {
                onrendered: function onrendered(canvas) {
                    try {
                        if (canvas.getContext) {
                            let context = canvas.getContext('2d');
                            let trader = new StarWebPrintTrader({ url: '//localhost:8001/StarWebPrnt/SendMessage' });
                            let builder = new StarWebPrintBuilder();
                            let chunkSize = 96;
                            let chunk = 0;
                            let request = builder.createInitializationElement({
                                reset: true,
                                print: false
                            });

                            trader.onError = function onError() {
                                deferred.reject();
                            };

                            trader.onReceive = function onReceive() {
                                deferred.resolve();
                            };
                            trader.onTimeout = function onTimeout() {
                                deferred.reject();
                            };

                            if (opts !== undefined && opts.hasOwnProperty('openCashDrawer') && !!opts.openCashDrawer) {
                                request += builder.createPeripheralElement({
                                    channel: 1,
                                    on: 200,
                                    off: 200
                                });
                            }
                            request += builder.createCutPaperElement({
                                feed: true,
                                type: 'full'
                            });

                            do {
                                request += builder.createBitImageElement({
                                    context,
                                    x: 0,
                                    y: chunk * chunkSize,
                                    width: canvas.width,
                                    height: Math.min(chunkSize, canvas.height - (chunk * chunkSize))
                                });
                                ++chunk;
                            } while ((chunk * chunkSize) < canvas.height);

                            request += builder.createCutPaperElement({
                                feed: true,
                                type: 'full'
                            });

                            trader.sendMessage({ request });
                        }
                    } catch (e) {
                        console.error('StartPrintDriver Error', e);
                        self.notification.error(e.message);
                    }
                }
            }
        );

        return deferred.promise;
    }
};
