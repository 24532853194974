DigiTickets.OrderRequest = function (
    cartConfirmationStructure,
    cartReservationStructure
) {
    this.attempts = 0;
    this.errors = [];
    this.order = cartConfirmationStructure;
    this.reservation = cartReservationStructure;
    this.reservationToken = null;
};

DigiTickets.OrderRequest.prototype = {};
