const preventEnterDirective = function () {
    return {
        link: function (scope, element, attrs) {
            $(element).keypress(function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = preventEnterDirective;
}
