/**
 * @param {Hydrator} hydrator
 * @param {DigiTickets.OnlineQueue} OnlineQueueService
 * @param {OrderQueue} orderQueue
 * @param OrderResource
 */
const OrderService = function (
    hydrator,
    OnlineQueueService,
    orderQueue,
    OrderResource
) {
    this.hydrator = hydrator;
    this.onlineQueueService = OnlineQueueService;
    this.orderQueue = orderQueue;
    this.orderResource = OrderResource;

    // Add handlers to OnlineQueue for tasks that this service will queue up...

    OnlineQueueService.addHandler('updateOrder', function (data, callback, orderService) {
        orderService.getResource().update({ id: data.orderID }, data.newProperties, function (response) {
            // Success callback
            callback(true, response);
        }, function (response) {
            // Error callback
            callback(false, response);
        });
    });

    OnlineQueueService.addHandler('emailReceipt', function (data, callback, orderService) {
        orderService.getResource().emailReceipt({ id: data.orderID }, {}, function (response) {
            // Success callback
            callback(true, response);
        }, function (response) {
            // Error callback
            callback(false, response);
        });
    });

    OnlineQueueService.addHandler(
        'addPayments',
        /**
         * @param {{order:DigiTickets.Order, payments:DigiTickets.Payment[]}} data
         * @param {function} callback
         * @param {PaymentService} paymentService
         */
        function (data, callback, paymentService) {
            paymentService.storePaymentsForOrder(
                data.order.ID,
                data.payments
            ).then((response) => {
                callback(true, response);
            }).catch(() => {
                callback(false);
            });
        }
    );
};

OrderService.prototype = {

    getResource: function getResource() {
        return this.orderResource;
    },

    /**
     * @returns {OrderQueue}
     */
    getOrderQueue: function getOrderQueue() {
        return this.orderQueue;
    },

    //
    // Adds an updateOrder request to the OnlineQueue
    // Updates the properties of the order given with those given in newProperties
    // Updates on the server or modifies the order in the OrderQueue if offline
    //
    // @param order
    // @param newProperties
    //
    updateOrder: function updateOrder(order, newProperties) {
        // Update the object
        $.extend(order, newProperties);

        if (order.ID) {
            // If there's an order ID we know the order has been sent to the server already
            this.onlineQueueService.addTask(
                'updateOrder',
                {
                    orderID: order.ID,
                    newProperties
                }
            );
        } else if (order.tempID) {
            // No order ID so order hasn't been saved on the server.
            // Update the order in the OrderQueue instead
            this.getOrderQueue().updateQueuedOrder(order.tempID, newProperties);
        }
    },

    /**
     * Adds an email receipt request to the OnlineQueue
     *
     * @param order
     */
    emailReceipt: function emailReceipt(order) {
        if (order.ID) {
            // If there's an order ID we know the order has been sent to the server already
            this.onlineQueueService.addTask(
                'emailReceipt',
                {
                    orderID: order.ID
                }
            );
        } else {
            // Update the queued order
            this.getOrderQueue().updateQueuedOrder(order.tempID, {
                sendEmailReceipt: 1
            });
        }
    },

    /**
     * @param {DigiTickets.Order} order
     * @param {DigiTickets.Payment[]} payments
     */
    addPayments: function addPayments(order, payments) {
        // We'd only be adding payments for existing orders made via another channel, so the order ID
        // should always be set.
        if (order && order.ID) {
            // Send payments to the API for later.
            this.onlineQueueService.addTask(
                'addPayments',
                {
                    order,
                    payments
                }
            );

            // Update the order object for now.
            for (let i = 0; i < payments.length; i++) {
                order.addPayment(payments[i]);
            }
        }
    },

    findUnfulfilledOrders: function findUnfulfilledOrders(callback) {
        this.orderResource.queryUnfulfilled((response) => {
            let orders = [];
            for (let i = 0; i < response.length; i++) {
                orders.push(this.hydrator.hydrate(response[i], new DigiTickets.Order()));
            }

            callback(orders);
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderService;
}
