const angular = require('angular');

/**
 * @param $scope
 * @param $modalInstance
 * @param $timeout
 * @param cartService
 * @param PrivilegesService
 * @param {DigiTickets.OfferManager} OfferManager
 */
const CartOffersCtrl = function CartOffersCtrl(
    $scope,
    $modalInstance,
    $timeout,
    cartService,
    PrivilegesService,
    OfferManager
) {
    $scope.cart = cartService;

    $scope.hasPermission = null;
    $scope.hasSupervisorOfferPermission = null;

    $scope.offers = OfferManager.getManualCartOffers();

    // Shallow copy the existing selected offers from the cart. We'll modify this array so changes are only
    // saved if OK is pressed.
    $scope.selectedOfferIds = angular.extend({}, cartService.selectedManualOfferIds);

    $scope.ok = function ok() {
        $modalInstance.close(
            {
                offerIds: $scope.selectedOfferIds
            }
        );
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * @param {DigiTickets.Offer} offer
     */
    $scope.selectOffer = function selectOffer(offer) {
        let select = function () {
            if ($scope.isOfferSelected(offer)) {
                if (offer.applyPerTriggerLimit) {
                    // Increment count
                    ++$scope.selectedOfferIds[offer.ID];
                } else {
                    // Do nothing. Keep it selected
                }
            } else {
                $scope.selectedOfferIds[offer.ID] = 1;
            }
        };

        if (offer.requiresSupervisorApproval && !$scope.hasSupervisorOfferPermission) {
            PrivilegesService.requirePrivilege('apply_supervisor_discounts')
                .then(function (result) {
                    if (result.granted) {
                        select();
                        $scope.hasSupervisorOfferPermission = true;
                    }
                });
        } else {
            select();
        }
    };

    $scope.deselectOffer = function deselectOffer($event, offer) {
        $event.preventDefault();
        $event.stopPropagation();

        delete $scope.selectedOfferIds[offer.ID];
    };

    $scope.getSelectionCount = function getSelectionCount(offer) {
        if ($scope.isOfferSelected(offer)) {
            return $scope.selectedOfferIds[offer.ID];
        }
    };

    $scope.isOfferSelected = function isOfferSelected(offer) {
        return $scope.selectedOfferIds.hasOwnProperty(offer.ID);
    };

    // apply_discounts specifies if the user can apply discounts at all
    PrivilegesService.requirePrivilege('apply_discounts')
        .then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    $scope.hasPermission = true;

                    let supervisorCheckPromise;

                    if (result.grantedBy) {
                        // If a supervisor granted the privilege, check if that supervisor has the
                        // apply_supervisor_discounts permission.
                        supervisorCheckPromise = PrivilegesService.checkUserPrivilege(
                            result.grantedBy,
                            'apply_supervisor_discounts'
                        );
                    } else {
                        supervisorCheckPromise = PrivilegesService.checkCurrentUserPrivilege(
                            'apply_supervisor_discounts'
                        );
                    }

                    supervisorCheckPromise.then(
                        /**
                         * @param {DigiTickets.PrivilegeCheckResult} result
                         */
                        function (result) {
                            if (result.granted) {
                                $scope.hasSupervisorOfferPermission = true;
                            }
                        },
                        function (result) {
                            $scope.hasSupervisorOfferPermission = false;
                        }
                    );
                }
            }
        );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartOffersCtrl;
}
