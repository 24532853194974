/**
 * Constants that can be used instead of referring to statuses by ID.
 */
const VerifoneStatusIds = {
    PROCESSING_TRANSACTION: 0,
    WAITING_FOR_GRATUITY: 1,
    GRATUITY_BEING_ENTERED: 2,
    AWAITING_CARD: 3,
    SWIPE_CARD: 4,
    CARD_INSERTED: 5,
    CARD_REMOVED: 6,
    CARD_PROCESSING: 7,
    CHANGE_CARD: 8,
    CONTACT_TRANSACTION_REQUIRED: 9,
    KEY_IN_CARD_DETAILS: 10,
    WAITING_FOR_CASHBACK: 11,
    PIN_ENTRY: 12,
    RISK_MANAGEMENT_COMPLETE: 13,
    AUTHORISING_TRANSACTION: 14,
    WAITING_FOR_RESULT: 15,
    AUTH_RESULT_RECEIVED: 16,
    PRINTING_MERCHANT_RECEIPT: 17,
    SIGNATURE_CONFIRMATION_REQUIRED: 18,
    CONTINUE_REQUIRED: 19,
    CONFIRM_AUTH_CODE: 20,
    CONFIRMING_TRANSACTION: 21,
    REJECTING_TRANSACTION: 22,
    FINAL_RESULT_RECEIVED: 23,
    VOICE_REFERRAL: 24,
    REMOVE_CARD: 25,
    AUTH_RESULT_ERROR: 26,
    FALLBACK_TO_SWIPE_DISABLED: 27,
    DOWNLOADING_FILE: 28,
    UPDATING_PED: 29,
    INVALID_PED_CONFIGURATION: 30,
    CARD_DATA_RETRIEVAL: 31,
    STARTING_TRANSACTION: 32,
    PERFORMING_DOWNLOAD: 33,
    REQUESTING_REPORT: 34,
    GRATUITY_SELECTION_REQUIRED: 35,
    EXPIRY_DATE_REQUIRED: 36,
    START_DATE_REQUIRED: 37,
    ISSUE_NUMBER_REQUIRED: 38,
    AVS_HOUSE_NUMBER_REQUIRED: 39,
    AVS_POST_CODE_REQUIRED: 40,
    CSC_REQUIRED: 41,
    CUSTOMER_PRESENT_NOT_PRESENT_SELECTION_REQUIRED: 42,
    CUSTOMER_NOT_PRESENT_OPTION_SELECTION_REQUIRED: 43,
    ENTER_CHARGE_AUTH_CODE: 44,
    LOGIN_REQUIRED: 45,
    READY: 46,
    CARD_NOT_ACCEPTED: 47,
    CARD_BLOCKED: 48,
    TRANSACTION_CANCELLED: 49,
    INVALID_EXPIRY: 50,
    GRATUITY_INVALID: 51,
    INVALID_CARD: 52,
    PRINTING_CUSTOMER_RECEIPT: 53,
    INITIALISING_PED: 54,
    PED_UNAVAILABLE: 55,
    CARD_APPLICATION_SELECTION: 56,
    RETRY_DOWNLOAD: 57,
    RESTART_AFTER_SOFTWARE_UPDATE: 58,
    REQUESTING_DCC: 59,
    DCC_CURRENCY_CHOICE: 60,
    CARDHOLDER_DCC_CURRENCY_CHOICE: 61,
    UNSAFE_DOWNLOAD: 62,
    UNEXPECTED_LOGIN: 63,
    START_BARCLAYS_BONUS_TRANSACTION: 64,
    UPDATE_BARCLAYS_BONUS_TRANSACTION: 65,
    CANCEL_BARCLAYS_BONUS_TRANSACTION: 66,
    CONFIRM_GRATUITY: 67,
    REGISTER_FOR_ACCOUNT_ON_FILE_DECISION: 68,
    AWAITING_TOKEN_ID: 69,
    BARCLAYS_BONUS_DISCOUNT_SUMMARY: 70,
    BARCLAYS_BONUS_USE_BONUS: 71,
    BARCLAYS_BONUS_ENTER_REDEMPTION: 72,
    BARCLAYS_BONUS_NOT_AVAILABLE: 73,
    DOWNLOAD_COMPLETE: 74,
    DOWNLOAD_STILL_BEING_PREPARED: 75,
    SERVER_CONNECTION_FAILED: 76,
    RESUME_DOWNLOAD: 77,
    PAYPOINT_ACCOUNT_EXTRACTION_FAILED: 78,
    PAYPOINT_AMOUNT_OUTSIDE_ALLOWED_RANGE: 79,
    PAYPOINT_CARD_EXPIRED: 80,
    PAYPOINT_INITIALISED: 81,
    PAYPOINT_INITIALISATION_FAILED: 82,
    PAYPOINT_INITIALISING: 83,
    PAYPOINT_INVALID_ACCOUNT: 84,
    PAYPOINT_INVALID_AMOUNT: 85,
    PAYPOINT_INVALID_CAPTURE_METHOD: 86,
    PAYPOINT_INVALID_CARD_NUMBER: 87,
    PAYPOINT_INVALID_CONFIGURATION: 88,
    PAYPOINT_INVALID_DENOMINATION: 89,
    PAYPOINT_INVALID_EXPIRY_DATE: 90,
    PAYPOINT_INVALID_SCHEME: 91,
    PAYPOINT_INVALID_SCHEME_OPTION: 92,
    PAYPOINT_INVALID_TOPUP_TYPE: 93,
    PAYPOINT_INVALID_SERVICE_PROVIDER: 94,
    PAYPOINT_INVALID_TRACK2_FORMAT: 95,
    PAYPOINT_INVALID_TRANSACTION_TYPE: 96,
    PAYPOINT_KEYED_ENTRY_NOT_ALLOWED: 97,
    PAYPOINT_MERCHANT_REFERENCE_REQUIRED: 98,
    PAYPOINT_NO_ACCOUNTS: 99,
    PAYPOINT_PROCESSING_TRANSACTION: 100,
    PAYPOINT_RETRY_CONFIRMATION_DECISION: 101,
    PAYPOINT_SCHEME_NOT_RECOGNISED: 102,
    PAYPOINT_TRANSACTION_CANCELLED: 103,
    PAYPOINT_TRANSACTION_TYPE_NOT_ALLOWED: 104,
    PAYPOINT_SELECT_SCHEME_OPTION: 105,
    PAYPOINT_DOWNLOAD_REQUIRED: 106,
    PAYPOINT_SELECT_ACCOUNT: 107,
    PRINTING_PAYPOINT_RECEIPT: 108,
    LICENCE_DETAIL_CONFIRMATION: 109,
    LICENCE_FILE_REQUIRED: 110,
    PAY_POINT_SERVICE_UNAVAILABLE: 111,
    PARK_RETAIL_GIFT_ACCOUNT_EXTRACTION_FAILED: 112,
    PARK_RETAIL_GIFT_AMOUNT_OUTSIDE_ALLOWED_RANGE: 113,
    PARK_RETAIL_GIFT_CARD_EXPIRED: 114,
    PARK_RETAIL_GIFT_INITIALISATION_FAILED: 115,
    PARK_RETAIL_GIFT_INITIALISING: 116,
    PARK_RETAIL_GIFT_INVALID_ACCOUNT: 117,
    PARK_RETAIL_GIFT_INVALID_AMOUNT: 118,
    PARK_RETAIL_GIFT_INVALID_CAPTURE_METHOD: 119,
    PARK_RETAIL_GIFT_INVALID_CARD_NUMBER: 120,
    PARK_RETAIL_GIFT_INVALID_CONFIGURATION: 121,
    PARK_RETAIL_GIFT_INVALID_EXPIRY_DATE: 122,
    PARK_RETAIL_GIFT_INVALID_TRACK2_FORMAT: 123,
    PARK_RETAIL_GIFT_INVALID_TRANSACTION_TYPE: 124,
    PARK_RETAIL_GIFT_KEYED_ENTRY_NOT_ALLOWED: 125,
    PARK_RETAIL_GIFT_MERCHANT_REFERENCE_REQUIRED: 126,
    PARK_RETAIL_GIFT_NO_ACCOUNTS: 127,
    PARK_RETAIL_GIFT_SERVICE_UNAVAILABLE: 128,
    PARK_RETAIL_GIFT_PROCESSING_TRANSACTION: 129,
    PARK_RETAIL_GIFT_SCHEME_NOT_RECOGNISED: 131,
    PARK_RETAIL_GIFT_SELECT_ACCOUNT: 132,
    PARK_RETAIL_GIFT_TRANSACTION_CANCELLED: 133,
    PARK_RETAIL_GIFT_TRANSACTION_TYPE_NOT_ALLOWED: 134,
    PRINTING_PARK_RETAIL_GIFT_RECEIPT: 135,
    PED_IN_ESD_RECOVERY_MODE: 136,
    UPDATE_TRANSACTION_VALUE_DECISION: 137,
    UPDATE_BARCLAYCARD_FREEDOM_CONFIG: 138,
    PROCESSING_KEY_EXCHANGE: 139,
    BARCLAYS_GIFT_INITIALISING: 140,
    GLOBAL_BLUE_TAX_FREE_SHOPPING_INITIALISING: 141,
    GIVEX_INITIALISING: 142,
    MVOUCHER_INITIALISING: 143,
    PERFORMING_POST_CONFIRM_REVERSAL: 144,
    INVALID_AMOUNT: 145,
    MERCHANT_REFERENCE_REQUIRED: 146,
    MERCHANT_REFERENCE_LENGTH_INVALID: 147,
    INITIALISING: 148,
    ACCOUNT_EXTRACTION_FAILED: 149,
    AMOUNT_OUTSIDE_ALLOWED_RANGE: 150,
    ENTER_AMOUNT: 151,
    CONFIRM_AUTHORISATION: 152,
    CARD_EXPIRED: 153,
    TRANSAX_SV_INITIALISATION_FAILED: 154,
    TRANSAX_SV_INITIALISING: 155,
    INVALID_ACCOUNT: 156,
    INVALID_CAPTURE_METHOD: 157,
    INVALID_CARD_NUMBER: 158,
    INVALID_CONFIGURATION: 159,
    INVALID_EXPIRY_DATE: 160,
    INVALID_TRACK2_FORMAT: 161,
    INVALID_TRANSACTION_TYPE: 162,
    KEYED_ENTRY_NOT_ALLOWED: 163,
    NO_ACCOUNTS: 164,
    CARD_SCHEME_NOT_RECOGNISED: 165,
    TRANSACTION_TYPE_NOT_ALLOWED: 166,
    TRANSAX_SV_SERVICE_UNAVAILABLE: 167,
    INVALID_ISSUE_NUMBER: 168,
    INVALID_CARD_SECURITY_CODE: 169,
    CONFIRMATION_FAILED: 170,
    PRINTING_RECEIPT: 171,
    WAITING_FOR_DONATION: 172,
    PIN_BLOCKED: 173,
    PIN_TRY_LIMIT_EXCEEDED: 174,
    FURTHER_PAYMENT_REQUIRED: 175,
    SVS_INITIALISING: 176,
    SVS_INITIALISATION_FAILED: 177,
    SVS_INVALID_CONFIGURATION: 178,
    SVS_SERVICE_UNAVAILABLE: 179,
    CANNOT_CONTINUE_TRANSACTION: 180,
    PART_PAYMENT: 181,
    OBTAIN_STAN: 182,
    INVALID_STAN: 183,
    IVR_REQUIRED: 184,
    PROMPT_FOR_AUTHORISATION_CODE: 185,
    INVALID_AUTH_CODE: 186,
    OFFLINE_TXN_FAILED: 187,
    ENTER_REFERENCE: 188,
    PRINT_SHOP_COPY: 189,
    MUST_INSERT_CARD: 190,
    PIN_ENTERED: 191,
    CARD_SWIPED: 192,
    PIN_BYPASSED: 193,
    CANCELLING_TRANSACTION: 194,
    CARD_PRESENTED: 195,
    SERVER_ERROR: 196,
    ON_HOLD_TRANSACTION: 197,
    REACTIVATION_REQUIRED: 198,
    OPTIONAL_DOWNLOAD_AVAILABLE: 204,
    CARD_NOT_RECOGNISED: 205,
    CARD_SCHEME_DOES_NOT_ALLOW_TRANSACTION: 206,
    CHOOSE_ACCOUNT: 207,
    CONNECTIONFAILED_DOWNLOAD: 208,
    SUBMITTING_OFFLINE_TXN: 209
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneStatusIds;
}
