const Cart = require('../Cart/Cart');
const FieldGroupCollection = require('../CustomFields/FieldGroupCollection');
const mergeDiscountedCartLines = require('../Cart/mergeDiscountedCartLines');
const { cloneDeep } = require('../../../functions/clone');

/**
 * @param {DigiTickets.Order} order
 *
 * @return {Cart}
 */
const mapOrderToCart = (order) => {
    const cart = new Cart();

    cart.customer = cloneDeep(order.customer);
    cart.originalOrder = order;
    cart.modifiedAt = order.date;
    cart.staffRef = order.staffRef;
    cart.thirdPartyID = order.thirdPartyID;
    cart.thirdPartyRef = order.thirdPartyRef;
    cart.notes = order.notes;
    cart.giftAid = order.giftAid;
    cart.giftAidPrices = order.giftAid;

    // Payments are not copied to the cart because that would result in calculation errors and potentially duplicating
    // payments. Instead you can use access cart.originalOrder.payments

    order.items.forEach((orderLine) => {
        let cartLine = new DigiTickets.CartItem(null, orderLine.item);

        // The orders API endpoint doesn't resolve events on orderitems.item, so borrow the event for the ticket
        // from the order line itself.
        cartLine.item.event = orderLine.event;

        cartLine.lineNumber = orderLine.lineNumber;
        cartLine.session = orderLine.session;
        cartLine.quantity = orderLine.qty;
        cartLine.quantityDisplay = orderLine.qty;
        cartLine.editableStatus = orderLine.getEditableStatus();

        cartLine.itemInstances = cloneDeep(orderLine.iteminstances);

        if (orderLine.returnReason) {
            cartLine.returnReason.reasonID = orderLine.returnReason.ID;
            cartLine.returnReason.returnToStock = orderLine.returnReason.returnToStock;
        }

        cartLine.price = orderLine.price;
        cartLine.customPrice = orderLine.price;

        if (orderLine.discount) {
            cartLine.discounts.push(orderLine.discount);
        }

        cartLine.giftAid = orderLine.giftAid;
        cartLine.donation = orderLine.donation;

        cartLine.ordersItemsID = orderLine.ID;

        cart.itemList.push(cartLine);
    });

    // Old style field data.
    if (order.fields instanceof FieldGroupCollection) {
        cart.fieldData = order.fields.getInstanceValues();
    }

    // New style field data.
    // Item level field data has already been copied to the cart by cloning the itemInstances
    // Copy the order level data from the order to the cart.
    cart.fields = cloneDeep(order.getOrderLevelFieldGroup());

    // Merge together discounted lines.
    mergeDiscountedCartLines(cart);

    return cart;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = mapOrderToCart;
}
