const BigNumber = require('bignumber.js');
const PaymentMethodRef = require('@/libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

/**
 * @param {string} field
 * @return {function(DigiTickets.Payment[]): number}
 */
const getPaymentFieldTotal = (field) => (payments) => {
    let total = new BigNumber(0);

    for (let i = 0; i < payments.length; i++) {
        total = total.plus(new BigNumber(payments[i][field].toFixed(8)));
    }

    return total.toNumber();
};

/**
 * Provides methods for summarising an array of Payment objects.
 * e.g. getTotalTendered returns the sum of the 'tendered' property all Payments.
 */
const PaymentCalculator = {
    /**
     * @type {function(DigiTickets.Payment[]): number}
     */
    getTotalAmount: getPaymentFieldTotal('amount'),

    /**
     * @type {function(DigiTickets.Payment[]): number}
     */
    getTotalTendered: getPaymentFieldTotal('tendered'),

    /**
     * @type {function(DigiTickets.Payment[]): number}
     */
    getTotalCashbackAmount: getPaymentFieldTotal('cashback'),

    /**
     * @type {function(DigiTickets.Payment[]): number}
     */
    getTotalGratuityAmount: getPaymentFieldTotal('gratuity'),

    /**
     * @param {number} totalDue
     * @param {number} totalTendered
     * @return {number}
     */
    getChangeDue: (totalDue, totalTendered) => {
        totalDue = new BigNumber(totalDue);
        totalTendered = new BigNumber(totalTendered);

        let changeDue = totalTendered.minus(totalDue).toNumber();

        return Math.max(0, changeDue);
    },

    /**
     * Change can be provided as either Cash or Flexi Voucher so return the correct type.
     * If no change is due then we need to display the default change message (Cash).
     * If change is due and any of the payments is Cash then the show the default change message (Cash).
     * If change is due there are no Cash payments but there is a Flexi Voucher payment then we show the Flexi Voucher
     * chnage message.
     *
     * @param {number} change
     * @param {DigiTickets.Payment[]} payments
     *
     * @return {string}
     */
    getChangePaymentMethod: (change, payments) => {
        if (change !== 0.00) {
            if (payments.filter((p) => p.paymentMethod.ref === PaymentMethodRef.CASH).length) {
                return PaymentMethodRef.CASH;
            }
            if (payments.filter((p) => p.paymentMethod.ref === PaymentMethodRef.FLEXI_VOUCHER).length) {
                return PaymentMethodRef.FLEXI_VOUCHER;
            }
        }
        return PaymentMethodRef.CASH;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentCalculator;
}
