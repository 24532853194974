/**
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {MarketingPreferenceRepository} marketingPreferenceRepository
 */
const MarketingPreferenceAsker = function (
    CustomerScreenDataService,
    marketingPreferenceRepository
) {
    /**
     * @type {CustomerScreenDataService}
     */
    this.customerScreenDataService = CustomerScreenDataService;

    /**
     * @type {MarketingPreferenceRepository}
     */
    this.marketingPreferenceRepository = marketingPreferenceRepository;
};

MarketingPreferenceAsker.prototype = {

    /**
     * Return an array of MarketingPreferences along with their CustomerScreenQuestion representation.
     *
     * @return {Promise<[{preference: MarketingPreference, screenQuestion: CustomerScreenQuestion, answer: ?boolean}]>}
     */
    async getQuestions() {
        return this.marketingPreferenceRepository.findAllActive()
            .then((preferences) => preferences.map((p) => ({
                preference: p,
                screenQuestion: p.toCustomerScreenQuestion(),
                answer: null
            })));
    },

    /**
     * Takes the result of this.getQuestions and sends them to the customer screen.
     * Returns a promise resolved when they have all been answered or rejected if they fail to get asked.
     *
     * @param questions
     * @return {Promise<[]>}
     */
    async askOnCustomerScreen(questions) {
        return Promise
            .all(
                questions.map((q) => this.customerScreenDataService
                    .askQuestion(
                        q.screenQuestion
                    )
                    .then((answer) => {
                        q.answer = answer;
                        return q;
                    }))
            )
            .then(() => {
                // Build an array of the selected marketingPreferenceIDs
                let selectedMarketingPreferenceIDs = [];
                questions.forEach((q) => {
                    if (q.answer === true) {
                        selectedMarketingPreferenceIDs.push(q.preference.ID);
                    }
                });
                return selectedMarketingPreferenceIDs;
            });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MarketingPreferenceAsker;
}
