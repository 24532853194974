const apiErrorMessage = require('./Api/apiErrorMessage');

/**
 * @param $q
 * @param $rootScope
 * @param {DigiTickets.CategoryManager} CategoryManager
 * @param {Hydrator} hydrator
 * @param MembershipPlanResource
 */
DigiTickets.MembershipPlanManager = function (
    $q,
    $rootScope,
    CategoryManager,
    hydrator,
    MembershipPlanResource
) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.categoryManager = CategoryManager;
    this.hydrator = hydrator;
    this.membershipPlanResource = MembershipPlanResource;

    this.deferred = $q.defer();
    this.membershipPlans = [];
    this.categories = {};
};

DigiTickets.MembershipPlanManager.prototype = {

    loadMembershipPlans: function loadMembershipPlans() {
        let self = this;

        this.membershipPlanResource.query(
            function (membershipPlans) {
                self.membershipPlans = self.hydrator.hydrateArray(membershipPlans, function () {
                    return new DigiTickets.MembershipPlan();
                });

                self.categories = self.categoryManager.extractCategoriesFromItems(self.membershipPlans);

                // For user switching, the Sell controller needs to know when the membershipPlans
                // are available so that it can restore any order that was in progress.
                self.$rootScope.$broadcast('MembershipPlanManager.membershipPlansReady');

                self.deferred.resolve(self.membershipPlans);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return self.deferred.promise;
    },

    getMembershipPlans: function getMembershipPlans() {
        return this.deferred.promise;
    },

    getCategories: function getCategories() {
        return this.categories;
    },

    findByBarcode: function findByBarcode(barcode) {
        for (let k in this.membershipPlans) {
            if (this.membershipPlans[k].barcode == barcode) {
                return this.membershipPlans[k];
            }
        }

        return null;
    },

    /**
     * Method to return the specific membershipPlan.
     *
     * @param membershipPlanId
     * @return DigiTickets.MembershipPlan
     */
    getMembershipPlan: function getMembershipPlan(membershipPlanId) {
        for (let membershipPlanIndex = 0; membershipPlanIndex < this.membershipPlans.length; membershipPlanIndex++) {
            if (this.membershipPlans[membershipPlanIndex].ID == membershipPlanId) {
                return this.membershipPlans[membershipPlanIndex];
            }
        }
        return null;
    }

};
