const DataStoreNames = require('./DataStores/DataStoreNames');

/**
 * @param $angularCacheFactory
 * @param {DataStore} dataStore
 * @param {LocalStorageDataStore} LocalStorageDataStore
 * @param {SyncStateManager} syncStateManager
 */
const CacheManager = function (
    $angularCacheFactory,
    dataStore,
    LocalStorageDataStore,
    syncStateManager
) {
    this.cacheFactory = $angularCacheFactory;

    /**
     * @type {DataStore}
     */
    this.dataStore = dataStore;

    /**
     * @type {LocalStorageDataStore}
     */
    this.localStorageDataStore = LocalStorageDataStore;

    /**
     * @type {SyncStateManager}
     */
    this.syncStateManager = syncStateManager;

    /**
     * When a user changes branch or clicks Sync these caches will be cleared.
     *
     * @type {string[]}
     */
    this.syncClearList = [
        'fieldCache',
        'giftVoucherCache',
        'membershipPlanCache',
        'offerCache',
        'orderCache',
        'privilegesCache',
        'productCache',
        'returnReasonCache',
        'templateCache',
        'ticketCache',
    ];
};

CacheManager.prototype = {
    /**
     * @param {string[]} cacheNames
     */
    clearAngularCaches(cacheNames) {
        cacheNames.forEach((cacheName) => {
            let cache = this.cacheFactory.get(cacheName) || new DigiTickets.LargeVolumeStorageImplementation(cacheName.ucfirst());
            if (cache) {
                console.debug('Clearing Angular cache', cacheName);
                cache.removeAll();
            } else {
                console.error('Angular cache not found', cacheName);
            }
        });
    },

    /**
     * @returns {Dexie.Promise}
     */
    changeBranchClear() {
        // Changing branch does the same thing as a full sync.
        return this.syncClear();
    },

    /**
     * @returns {Dexie.Promise}
     */
    syncClear() {
        this.clearAngularCaches(this.syncClearList);

        this.localStorageDataStore.remove('lastSync');

        // Delete all the sync meta data about stuff stored in IndexedDB.
        this.syncStateManager.removeAll();

        // Delete most of the actual data stored in IndexedDB.
        // Make sure we do not delete userSessions as the data in this table is used for user switching.
        /** @type {string[]} */
        let idbTablesToClear = [
            DataStoreNames.MARKETING_PREFERENCES,
            DataStoreNames.SESSIONS,
            DataStoreNames.SYNC_METADATA,
            DataStoreNames.TEST,
        ];

        return Promise.all(
            idbTablesToClear.map((tableName) => {
                console.debug('Clearing IndexedDB table', tableName);
                return this.dataStore.clear(tableName);
            })
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CacheManager;
}
