const _ = require('lodash');
const { arrayCombine } = require('../../functions/functions');

/**
 * @param $q - Angular $q object
 * @param $timeout
 * @param RedemptionResource
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.OnlineQueue} OnlineQueueService
 * @param {OrderManager} OrderManagerService
 */
DigiTickets.RedemptionManager = function (
    $q,
    $timeout,
    RedemptionResource,
    Logger,
    OnlineQueueService,
    OrderManagerService
) {
    this.logger = Logger;
    this.orderManager = OrderManagerService;

    OnlineQueueService.addHandler('redemption', redemptionTaskHandler);

    /**
     * Add a redemption request to the queue
     *
     * @param {OrderLine} orderLine
     * @param {number} qty
     * @param {string} method
     */
    function addRedemptionToQueue(orderLine, qty, method) {
        Logger.debug(
            'RedemptionManager.addRedemptionToQueue',
            {
                ID: orderLine.ID,
                qty,
                method
            }
        );

        OnlineQueueService.addTask('redemption', {
            orderLine,
            qty,
            method
        });
    }

    /**
     * All redemption requests go into the queue by default. This means they can be handled
     * independently of whether we have connectivity or not.
     *
     * @param {DigiTickets.OnlineQueue} queue
     * @param {{orderLine: OrderLine, qty:number, method: string}} data
     * @param {function} callback
     */
    function redemptionTaskHandler(queue, data, callback) {
        queue.logMsg('Redemption Task Handler called');

        let result = remoteRedemption(data.orderLine.ID, data.qty, data.method);
        result.then(function (response) {
            // do some post-redemption logging (response should contain the current *live* redemption amount, so use it)
            data.orderLine.redeemed = response.newRedemptionCount;
            // inform the queue that the task succeeded
            Logger.debug(
                'Redemption submitted successfully',
                {
                    ID: data.orderLine.ID,
                    qty: data.qty,
                    method: data.method,
                    response
                }
            );

            // The redemption buttons on screen can now be re-enabled.
            try {
                data.orderLine.clearRedemptionInProgress();
            } catch (e) {
                // For some reason this occasionally fails and is possibly causing Z-Read issues
                // further down the line. Cannot see an obvious reason.
                // Add logging to gather more information.
                Logger.error(
                    'clearRedemptionInProgress failure',
                    {
                        error: e,
                        orderLine: data.orderLine,
                        response
                    }
                );
            }

            callback(true, response);
        }, function (response) {
            // inform the queue that the task failed
            Logger.error(
                'Redemption failed',
                {
                    ID: data.orderLine.ID,
                    qty: data.qty,
                    method: data.method,
                    response
                }
            );

            callback(false, response);
        });
    }

    /**
     * Try to redeem the order line via the API
     *
     * @param {string} ref
     * @param {number} qty
     * @param {string} method
     * @returns promise
     */
    function remoteRedemption(ref, qty, method) {
        let deferred = $q.defer();

        let data = {
            ref,
            type: 'OrderLine',
            qty,
            method
        };

        RedemptionResource.redeem(
            data,
            function (successResult) {
                Logger.debug('RedemptionManager.remoteRedemption - Success',
                    {
                        data,
                        response: successResult
                    });
                deferred.resolve(successResult);
            },
            function (failureResult) {
                Logger.error('RedemptionManager.remoteRedemption - Failed',
                    {
                        data,
                        response: failureResult
                    });
                deferred.reject(failureResult);
            }
        );

        return deferred.promise;
    }

    /**
     * Following a redemption, do some logging, etc
     *
     * @param {DigiTickets.Order} order
     * @param {OrderLine} orderLine
     * @param {number} previousAmount
     * @param {number} newAmount
     */
    function postRedemption(order, orderLine, previousAmount, newAmount) {
        let difference = parseInt(newAmount - previousAmount);
        let value = (orderLine.lineTotal / orderLine.qty) * difference;

        Logger.info(
            (newAmount > previousAmount ? 'Redeemed ' : 'Unredeemed ')
                + parseInt(Math.abs(difference))
                + ' x '
                + orderLine.name
                + ' from '
                + order.bookingRef
                + ' for a value of ' + parseFloat(value)
        );
    }

    const self = this;
    const doAdjustRedemptionCount = function (order, orderLine, deltaAmount, method, onlineRetrieval) {
        $timeout(() => {
            if (deltaAmount > 0) {
                orderLine.setRedemptionInProgress(true);
            } else if (deltaAmount < 0) {
                orderLine.setUnredemptionInProgress(true);
            }

            addRedemptionToQueue(orderLine, deltaAmount, method);
            if (!onlineRetrieval) {
                // Redemption is happening offline, so just mock that the redemption was a success.
                orderLine.redeemed += deltaAmount;
                orderLine.clearRedemptionInProgress();
            }

            postRedemption(order, orderLine, orderLine.getCurrentRedeemed(), deltaAmount);
            orderLine.setCurrentRedeemed(true);
            if (!onlineRetrieval) {
                let retrievedLine = arrayCombine(
                    self.orderManager.columnNamesByTable.orderitems,
                    JSON.parse(
                        self.orderManager.getCache().getItem(
                            self.orderManager.tableKeyAliases.orderitems + '.' + orderLine.ID
                        )
                    )
                );
                retrievedLine.redeemed += deltaAmount;
                self.orderManager.getCache().setItem(
                    self.orderManager.tableKeyAliases.orderitems + '.' + orderLine.ID,
                    JSON.stringify(Object.values(retrievedLine))
                );
            }
        });
    };

    /**
     * @type {Object<function>}
     */
    const debouncedDoAdjustRedemptionCounts = {};
    const doAdjustRedemptionCountDebounced = function (orderLineID, params) {
        if (!debouncedDoAdjustRedemptionCounts.hasOwnProperty(orderLineID)) {
            // Create a debounced doAdjustRedemptionCount for this order line.
            debouncedDoAdjustRedemptionCounts[orderLineID] = _.debounce(doAdjustRedemptionCount, 100);
        }
        debouncedDoAdjustRedemptionCounts[orderLineID](...params);
    };

    /**
     * Public - Update the redemption count on an order line
     *
     * @param {DigiTickets.Order} order
     * @param {OrderLine} orderLine
     * @param {number} deltaAmount
     * @param {string} method
     * @param {bool} onlineRetrieval Indicates if the order was retrieved online.
     */
    this.adjustRedemptionCount = function adjustRedemptionCount(order, orderLine, deltaAmount, method, onlineRetrieval) {
        // Set the new amount optimistically on the order line (provide instant response to the UI).
        orderLine.setCurrentRedeemed(false);
        // Create a debounce for each line of the order.
        doAdjustRedemptionCountDebounced(orderLine.ID, [order, orderLine, deltaAmount, method, onlineRetrieval]);
    };
};
