const UserFacingError = require('./UserFacingError');

const ErrorBag = function () {
    /**
     * @type {UserFacingError[]}
     */
    this.errors = [];
};

ErrorBag.prototype = {
    /**
     * @return {UserFacingError[]}
     */
    all() {
        return this.errors;
    },

    /**
     * Add an error.
     *
     * @param {UserFacingError} error
     */
    push(error) {
        this.errors.push(error);
    },

    /**
     * Remove a single error.
     *
     * @param {UserFacingError} error
     */
    remove(error) {
        let index = this.errors.indexOf(error);
        if (index !== -1) {
            this.errors.splice(index, 1);
        }
    },

    /**
     * Remove all errors.
     */
    clear() {
        this.errors = [];
    },

    /**
     * @return {boolean}
     */
    hasErrors() {
        return this.errors.length > 0;
    },

    /**
     * @return {object}
     */
    getHydrationMap() {
        return {
            errors: {
                modelCollection: UserFacingError
            }
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ErrorBag;
}
