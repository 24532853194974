const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const SessionResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'sessions',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                limit: AppConfig.maxSessionsToSync
            }
        ),
        {
            query: {
                isArray: true,
                method: 'GET',
                params: ApiRequestParams.defaultMappers(),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'Session')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionResource;
}
