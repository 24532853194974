const PaymentExpressResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        /* 'https://uat.paymentexpress.com/pxmi3/pos.aspx', */
        AppConfig.apiBaseUrl + 'PaymentExpressProxy',
        ApiRequestParams.defaults(),
        {
            // Note: All the requests are the same, but I've given them different names
            // so that the calling code is easier to understand.
            initiate: {
                method: 'POST'
            },
            buttonPress: {
                method: 'POST'
            },
            status: {
                method: 'POST'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentExpressResource;
}
