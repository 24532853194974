const User = require('../models/User');

/**
 * @param $location
 * @param $scope
 * @param $timeout
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {CacheManager} CacheManager
 * @param {DeviceManager} deviceManager
 * @param {DialogService} DialogService
 * @param {Hydrator} hydrator
 * @param {DigiTickets.Logger} Logger
 * @param {NavigationService} navigationService
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.TemplateManager} TemplateService
 * @param UserResource
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
const LoginCtrl = function (
    $location,
    $scope,
    $timeout,
    AppConfig,
    CacheManager,
    deviceManager,
    DialogService,
    hydrator,
    Logger,
    navigationService,
    CurrentDevice,
    TemplateService,
    UserResource,
    UserService,
    UserSessionManager
) {
    navigationService.showNav();

    $scope.errorMessage = '';
    $scope.processingMessage = null;

    $scope.credentials = {
        username: '',
        password: '',
        eposVersion: AppConfig.eposVersion.toISOString()
    };

    const confirmUseOfBeta = () => new Promise(
        (resolve, reject) => DialogService.confirm(
            'BETA.MESSAGE',
            (result) => (result ? resolve() : reject()),
            {
                cancelLabel: 'BETA.CANCEL_AND_LEAVE',
                okLabel: 'BETA.CONTINUE'
            }
        )
    );

    $scope.login = function login() {
        $scope.displayError(false);

        if (!$scope.credentials.username || !$scope.credentials.password) {
            $scope.displayError('Please enter your username and password.');
            return;
        }

        $scope.processingMessage = 'LOGIN.PROCESSING_LOGIN';

        UserResource.login(
            $scope.credentials,
            async function (response) {
                if (response.success && response.user) {
                    // Successfully authenticated with username and password.

                    let user = new User();
                    hydrator.hydrate(response.user, user);
                    console.log('user', user);

                    // Create a UserSession for this user.
                    // Save the user's apiKey in their session for switching users later on.
                    // Previously this saved the user's plaintext password in the session.
                    // We don't want to be doing that. So now we save the apiKey instead.
                    UserSessionManager.saveSession(
                        user.ID,
                        {
                            apiKey: user.apiKey,
                            lastLoginDatetime: new Date()
                        }
                    );

                    let currentDevice = CurrentDevice.device;

                    // Set the new current user.
                    UserService.changeUser(user, currentDevice);

                    // Get the latest version of the device.
                    $scope.processingMessage = 'LOGIN.PROCESSING_DEVICE';

                    await deviceManager.refreshDevice();

                    $scope.waitingForBetaConfirmation = true;

                    // On BETA users are presented with an information notice after login.
                    // They either need to click continue or logout.
                    if (AppConfig.isBeta) {
                        try {
                            await confirmUseOfBeta();
                        } catch (error) {
                            UserService.logout();
                        }
                    }
                    $scope.redirectIfAuthenticated();
                } else {
                    $scope.processingMessage = null;
                    $scope.displayError('There was an unexpected response when logging in. Please contact DigiTickets support.');
                    $scope.credentials.password = '';
                }
            },
            function (response) {
                if (response.status === 401) {
                    $scope.processingMessage = null;
                    $scope.displayError('Invalid login credentials, please try again.');
                    $scope.credentials.password = '';
                } else {
                    $scope.processingMessage = null;
                    $scope.displayError('There was an error communicating with the DigiTickets server.');
                    $scope.credentials.password = '';
                }
            }
        );
    };

    $scope.displayError = function displayError(message) {
        $scope.errorMessage = message || null;
    };

    $scope.redirectIfAuthenticated = function redirectIfAuthenticated() {
        if (UserService.isLoggedIn()) {
            $scope.processingMessage = 'LOADING_OVERLAY';
            Logger.info('User "' + UserService.username + '" logged in.');

            // Go back to index to continue the startup flow.
            navigationService.viewEposIndex();
        }
    };

    // Redirect users who are already logged in.
    $scope.redirectIfAuthenticated();

    // Pre fill the username field.
    let autofillUsername = UserSessionManager.pullAutofillLoginUsername();
    if (autofillUsername) {
        $scope.displayError('Please re-enter your password to continue.');
        $scope.credentials.username = autofillUsername;
        $timeout(function () {
            let passwordEl = document.getElementById('login-password');
            if (passwordEl) {
                passwordEl.focus();
            }
        }, 10);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LoginCtrl;
}
