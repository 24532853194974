let registerRoutes = function registerRoutes($routeProvider) {
    $routeProvider.when('/', { templateUrl: 'partials/epos/index.html', controller: 'EposIndexCtrl' });
    $routeProvider.when('/device', { templateUrl: 'partials/device.html', controller: 'DeviceCtrl' });
    $routeProvider.when('/login', { templateUrl: 'partials/login.html', controller: 'LoginCtrl' });
    $routeProvider.when('/manage', { templateUrl: 'partials/manage/index.html', controller: 'ManageCtrl' });
    $routeProvider.when('/account/:accountID',
        {
            templateUrl: 'partials/manage/account.html',
            controller: 'ManageAccountCtrl'
        });
    $routeProvider.when('/memberships/:membershipRef/:memberRef',
        {
            templateUrl: 'partials/memberships/membership-details.html',
            controller: 'MembershipDetailCtrl'
        });
    $routeProvider.when('/memberships/:membershipRef',
        {
            templateUrl: 'partials/memberships/membership-details.html',
            controller: 'MembershipDetailCtrl'
        });
    $routeProvider.when('/orders', { templateUrl: 'partials/orders/index.html', controller: 'OrdersCtrl' });
    $routeProvider.when('/orders/:bookingRef/:wasBarcodeScan',
        {
            templateUrl: 'partials/orders/order-details.html',
            controller: 'OrderDetailCtrl'
        });
    $routeProvider.when('/redeem/:instanceRef', {
        templateUrl: 'partials/orders/redemption.html', controller: 'RedemptionCtrl'
    });
    $routeProvider.when('/report', { templateUrl: 'partials/reports/index.html', controller: 'ReportCtrl' });
    $routeProvider.when('/sell', { templateUrl: 'partials/sell/index.html', controller: 'SellCtrl' });
    // FIXME: This probably shouldn't be here. /customer is a separate directory that has its own index.html
    // for displaying the customer screen.
    $routeProvider.when('/customer', { templateUrl: 'partials/customer/index.html', controller: 'CustomerCtrl' });
    $routeProvider.when('/403', { templateUrl: 'partials/errors/403.html', controller: 'ErrorCtrl' });
    $routeProvider.otherwise({ redirectTo: '/' });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = registerRoutes;
}
