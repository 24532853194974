const { toDate, toNullableInt, toNullableString } = require('../../../functions/transform');

/**
 * A User Session object. It's used when switching between users.
 *
 * @param {number} [userID]
 * @param {object} [sessionInfo]
 */
const UserSession = function (userID, sessionInfo) {
    /**
     * @type {?number}
     */
    this.ID = userID || null;

    /**
     * @type {?string}
     */
    this.apiKey = null;

    /**
     * @type {?Date}
     */
    this.lastLoginDatetime = null;

    /**
     * @type {?string}
     */
    this.lastScreen = null;

    /**
     * Replaces the functionality the "session stashed cart" provided.
     *
     * @type {DigiTickets.OrderInProgress|null}
     */
    this.orderInProgress = null;

    /**
     * @type {DigiTickets.StashedCart[]}
     */
    this.stashedCarts = [];

    /**
     * @type {?string}
     */
    this.username = null;

    if (sessionInfo) {
        this.updateSession(sessionInfo);
    }
};

UserSession.prototype = {
    getHydrationMap() {
        return {
            apiKey: toNullableString,
            ID: toNullableInt,
            lastLoginDatetime: toDate,
            lastScreen: {},
            orderInProgress: {
                model: DigiTickets.OrderInProgress
            },
            stashedCarts: {
                modelCollection: DigiTickets.StashedCart
            },
            username: toNullableString
        };
    },

    /**
     * Method to allow this session to be updated. The values are passed in in an object
     * and they are "overlaid" over the current values.
     * Not all keys need to be specified. You can just give apiKey for example and the others will be untouched.
     *
     * @param {object} newInfo
     */
    updateSession(newInfo) {
        let allowedKeys = [
            'apiKey',
            'lastLoginDatetime',
            'lastScreen',
            'orderInProgress',
            'username',
        ];

        allowedKeys.forEach((key) => {
            if (newInfo.hasOwnProperty(key)) {
                this[key] = newInfo[key];
            }
        });
    },

    /**
     * @param {DigiTickets.StashedCart} stashedCart
     */
    addStashedCart(stashedCart) {
        let existingStash = this.stashedCarts.find((c) => c.guid === stashedCart.guid);
        if (existingStash) {
            // Update existing.
            let existingIndex = this.stashedCarts.indexOf(existingStash);
            this.stashedCarts[existingIndex] = stashedCart;
        } else {
            // Add to the start of the list.
            this.stashedCarts.unshift(stashedCart);
        }
    },

    deleteStashedCartByGuid(guid) {
        for (let i = 0; i < this.stashedCarts.length; i++) {
            let stashedCart = this.stashedCarts[i];
            if (stashedCart.guid === guid) {
                this.stashedCarts.splice(i, 1);
                return true;
            }
        }
        return false; // Tell the calling code we couldn't find the requested cart.
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserSession;
}
