/**
 * To phase out adding things to string prototype there will be some duplication here with the stringPrototype.js.
 * Defining these are simple functions makes them easier to test and debug because you know where the function
 * comes from.
 */

/**
 * Change the first letter of a string to uppercase. The rest of the string is not modified.
 *
 * @param {string} str
 * @return {string}
 */
const ucfirst = function (str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        ucfirst
    };
}
