let GeneralResponseInterceptor = ['$rootScope', '$q', '$injector', 'Logger', function (scope, $q, $injector, Logger) {
    let dontLogHttpStatuses = [
        0, // Some client side communication error (offline?)

        // TODO: Likely a validation error but we also throw 400s for missing data which is actually a coding error
        // we want to know about.
        // 400, // Bad Request

        401, // Unauthorized
    ];

    function success(response) {
        // A successful request - let the online notifier know - we must be online
        if (response.config.url.indexOf('/status') === -1 && response.status == 200 && typeof response.data !== 'string') {
            scope.$broadcast('requestSuccess', response);
        }

        return response;
    }

    function error(response) {
        // A failed request - we are probably offline or DT API is in maintenance mode
        if ((response.config.url.indexOf('/status') === -1 && response.status == 0) || response.status == 503) {
            scope.$broadcast('requestError', response);
        }

        // Only process 401/500 errors if it has not come from the online queue - that can handle the errors natively
        if (!(!!response.config && !!response.config.data && !!response.config.data._queue)) {
            // if a 401 is received, and we're not logging in, redirect the user to the login page
            if (response.status == 401 && response.config.url.indexOf('users/auth') === -1) {
                console.warn('Request Unauthorized', response.config.url, response.status);
                scope.$broadcast('requestUnauthorized', response);
                if (location.hash != '#/login') {
                    // Redirect to the root of the APP only if not already on login
                    window.location = location.pathname;
                }
            } else {
                let errorMessage = '';
                if (response.data && response.data.hasOwnProperty('message')) {
                    errorMessage = response.data.message;
                } else if (response.data && response.data.hasOwnProperty('error')) {
                    errorMessage = response.data.error;
                }

                if (dontLogHttpStatuses.indexOf(response.status) === -1) {
                    // Record the error message and send it to Sentry.
                    Logger.error(
                        'API error'
                        + ' ' + response.status // HTTP status code
                        + ' ' + response.config.method // GET/POST/etc.
                        + ' ' + response.config.url // URL of the request
                        + (response.data ? ' ' + JSON.stringify(response.data) : ''), // Response from the server
                        {
                            response
                        }
                    );
                }

                if (response.status == 500) {
                    let Notification = $injector.get('Notification');
                    Notification.error({
                        message: 'An error occurred on the DigiTickets server. Please contact support.'
                            + (errorMessage ? '<br/><small>' + errorMessage + '</small>' : ''),
                        delay: 4000
                    });
                }
            }
        }
        return $q.reject(response);
    }

    return function (promise) {
        return promise.then(success, error);
    };
}];

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = GeneralResponseInterceptor;
}
