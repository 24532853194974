const Field = require('./Field');
const FieldOption = require('./FieldOption');
const { toBool, toInt, toNullableInt } = require('../../../functions/transform');

/**
 * An answer or a chosen option for a custom field.
 */
const FieldData = function () {
    /**
     * @type {Number}
     */
    this.ID = null;

    /**
     * @type {boolean}
     */
    this.active = true;

    /**
     * @type {Field}
     */
    this.field = null;

    /**
     * @type {FieldOption}
     */
    this.fieldOption = null;

    /**
     * Selected value, if this for a field that has options.
     *
     * @type {?number}
     */
    this.fieldOptionID = null;

    /**
     * @type {Number}
     */
    this.itemID = null;

    /**
     * 0 based instance number.
     *
     * @type {Number}
     */
    this.itemInstance = null;

    /**
     * @type {Number}
     */
    this.itemInstanceID = null;

    /**
     * 1 base line number.
     *
     * @type {Number}
     */
    this.lineNumber = null;

    /**
     * @type {Number}
     */
    this.orderID = null;

    /**
     * @type {Number}
     */
    this.ordersItemsID = null;

    /**
     * @type {String}
     */
    this.value = null;
};

FieldData.prototype = {
    /**
     * Mapping of properties from the API data to object properties.
     *
     * @return {object}
     */
    getHydrationMap() {
        return {
            active: toBool,
            field: {
                field: ['field', 'fields'],
                model: Field
            },
            fieldOption: {
                field: ['fieldOption', 'fieldoptions'],
                model: FieldOption
            },
            fieldOptionID: toNullableInt,
            ID: {
                field: ['ID', 'fieldDataID'],
                transform: toInt
            },
            itemID: toNullableInt,
            itemInstance: toNullableInt,
            itemInstanceID: toNullableInt,
            lineNumber: toNullableInt,
            orderID: toInt,
            ordersItemsID: toNullableInt,
            value: {}
        };
    },

    /**
     * Initialize the model after its data has been set.
     */
    afterHydration() {
        if (this.field && this.field.level === 'order') {
            this.itemInstance = null;
            this.itemInstanceID = null;
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldData;
}
