/**
 * This model represents the contents of the "SL Line" that is added to the bottom of receipts for
 * Greek tax purposes.
 * The name is awkward but it's the best we have (everything is in Greek...)
 *
 * The format is a single line starting with <%SL; with each field separated by semicolons ending in >.
 * e.g. <%SL;144476123;;123321456654;;;;;222;A;20;0.00;100.00;100.00;0.00;0.00;0.00;13.00;24.00;0.00;237.00;0;>
 * The toString() method produces a string in this format.
 *
 * @see {TaxManager.createSLLineModel} See the comment on method in TaxManager for description of the fields.
 */
const OrderTaxSLLine = function () {
    /**
     * @type {[]}
     * @private
     */
    this.fields = new Array(22);
};

OrderTaxSLLine.prototype = {
    setField(number, value) {
        this.fields[number - 1] = value;
    },

    getField(number) {
        return this.fields[number - 1];
    },

    toString() {
        let mappedFields = this.fields.map(
            (val, index) => {
                // Keep the decimal places for currency fields (12 through 21)
                if ((index + 1) >= 12 && (index + 1) <= 21) {
                    return val.toFixed(2);
                }
                return val;
            }
        );

        return '<%SL'
            + mappedFields.join(';')
            + '>';
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderTaxSLLine;
}
