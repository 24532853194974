const UserResource = function ($resource, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'users/auth',
        {
            // TODO: Add deviceGuid in PRO-370
        },
        {
            login: {
                cache: false,
                method: 'POST',
                params: {
                    resolve: '*',
                    encodePrintableLogos: 1
                }
            },
            check: {
                cache: false,
                method: 'GET',
                params: {
                    resolve: '*',
                    encodePrintableLogos: 1
                }
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserResource;
}
