/* istanbul ignore file */
/**
 * Namespace for DigiTickets functionality.
 *
 * All code is in the DigiTickets subdirectory, similar to PSR-0.
 */
(function () {
    let DigiTickets;
    if (typeof window === 'object' && window.DigiTickets) {
        DigiTickets = window.DigiTickets;
    } else if (typeof global === 'object' && global.DigiTickets) {
        DigiTickets = global.DigiTickets;
    } else {
        DigiTickets = {};
    }

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = DigiTickets;
    }
    if (typeof window === 'object') {
        window.DigiTickets = DigiTickets;
    }
}());
