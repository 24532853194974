const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const OfferResource = function ($resource, ApiRequestParams, AppConfig, OfferCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'offers/',
        ApiRequestParams.defaults(),
        {
            query: {
                cache: OfferCache,
                isArray: true,
                method: 'GET',
                params: ApiRequestParams.defaultMappers(),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'Offer')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OfferResource;
}
