const VerifoneTransactionModifiers = require('../../Constants/VerifoneTransactionModifiers');

const VerifoneTransactionRequestRecord = function () {
    /**
     * '01' - Purchase
     * '02' - Refund
     * '07' - Account Check
     *
     * @type {string}
     */
    this.tranactionType = '01';

    /**
     * The modifier meanings have been updated to account for the different types of CNP transactions:
     *
     * @see {VerifoneTransactionModifiers}
     *
     * @type {string}
     */
    this.modifier = VerifoneTransactionModifiers.CARDHOLDER_PRESENT;

    /**
     * @type {number}
     */
    this.transactionValue = 0.00;

    /**
     * If left blank, the terminal will prompt for cash back.
     * If populated with a value, or zero value, the terminal will not prompt.
     *
     * @type {Number}
     */
    this.cashBackValue = null;

    /**
     * Reference numbers can be supplied up to a maximum length of 50 characters.
     * When forwarded to the acquirer these are truncated to 25 characters
     * Optional
     *
     * @type {null}
     */
    this.reference = null;

    /**
     * Defines merchant parameter set to be used.
     * Optional
     *
     * @type {null}
     */
    this.accountId = null;

    /**
     * Total gratuity value to be added to the transaction.
     * POD Client: The ability to supply gratuity here has been removed and does nothing.
     *
     * @type {number}
     */
    this.gratutity = null;

    /**
     * Non Discounted Items value, showing the value of items which are not eligible for Freedom.
     * This field is conditional, as it will only be populated if there are values meeting this criterion.
     *
     * @type {Number}
     */
    this.ndiValue = null;

    /**
     * Valid values are:
     * Not Set = 0
     * Do Not Register = 1
     * Register = 2
     * Register Only = 3
     *
     * By default we are not registered for a token, so this is set to 1.
     * It will be overridden in VerifonePaymentCtrl before being sent if we do want to.
     *
     * @type {number}
     */
    this.registerForAccountOnFile = 1;

    /**
     * Exising ID/token assigned to the stored Account On File details.
     *
     * @type {string}
     */
    this.tokenId = '';

    /**
     * Field to indicate whether the charitable donation should be suppressed.
     * This functionality is designed to allow the retailer to account for scenarios whereby the transaction
     * needs to be repeated and that the cardholder should not be prompted again.
     *
     * Valid values are:
     * '0' - Not Set
     * '1' - Suppress
     *
     * @type {number}
     */
    this.suppressChariableDonation = 0;

    /**
     * Field to indicate whether the gratuity prompt should be suppressed.
     * This functionality is designed to allow the retailer to account for scenarios whereby gratuity
     * prompt may not be required for certain transactions.
     *
     * Valid values are:
     * '0' - Not Set
     * '1' - Suppress
     *
     * @type {number}
     */
    this.suppressGratuityPrompt = 1;
};

VerifoneTransactionRequestRecord.prototype = {
    toArray(posClient) {
        let record = [
            'T', // 1: Message Type: Transaction
            null, // 2: Account Number: "No longer used"
            this.tranactionType, // 3: Transaction Type
            this.modifier, // 4: Modifier
            null, // 5: PoS Routing / Bill: "Reserved, leave empty"
            null, // 6: PAN / Track 2: "Reserved, leave empty"
            null, // 7: CSC: "Reserved, leave empty"
            null, // 8: Expiry Date: "Reserved, leave empty"
            null, // 9: Issue No: "Reserved, leave empty"
            null, // 10: Start Date: "Reserved, leave empty"
            this.transactionValue, // 11: Txn Value
            this.cashBackValue, // 12: Cash Back Value (POS Client: Reserved for future use)
            null, // 13: Bank Acc No: "Reserved, leave empty"
            null, // 14: Sort Code: "Reserved, leave empty"
            null, // 15: Cheque No: "Reserved, leave empty"
            null, // 16: Cheque Type: "Reserved, leave empty"
            null, // 17: Cardholder Name: "Reserved, leave empty"
            null, // 18: Cardholder Billing Address: "Reserved, leave empty"
            null, // 19: EFTSN: "Reserved, leave empty"
            null, // 20: Auth Source: "Reserved, leave empty"
            null, // 21: Auth Code: "Reserved, leave empty" (POS Client: Only to be populated for charge only
            // transactions Modifier ‘0002’ – Offline (capture))
            null, // 22: Txn Date Time: "Reserved, leave empty"
            this.reference, // 23: Reference
            this.accountId, // 24: Account ID
            this.gratutity, // 25: Gratuity (POS Client: Reserved for future use)
            this.ndiValue, // 26: NDI Value
            this.registerForAccountOnFile, // 27: Register for Account On File
            this.tokenId, // 28: Token ID
        ];

        if (posClient) {
            // POS Client requires 30 fields. The first 28 are the same for EVO and POS Client
            record.push(this.suppressChariableDonation); // 29: Suppress Charitable Donation
            record.push(this.suppressGratuityPrompt); // 30: Suppress Gratuity Prompt
        }

        return record;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneTransactionRequestRecord;
}
