const CustomerScreenStages = Object.freeze({
    COMPLETE: 'complete',
    PAY: 'pay',
    SELL: 'sell',
    WAIT: 'wait'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerScreenStages;
}
