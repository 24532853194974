const { toBool, toFloat, toInt, toNullableInt, toIntArray } = require('../functions/transform');

DigiTickets.Offer = function () {
    this.applyPerTriggerLimit = null;
    this.code = null;
    this.ID = 0;

    /**
     * @type {Number[]}
     */
    this.itemIDs = [];

    /**
     * @type {Number[]}
     */
    this.catIDs = [];

    /**
     * @type {Number[]}
     */
    this.eventIDs = [];

    this.name = '';
    this.valueType = null;
    this.trigger = null;

    /**
     * @type {bool|null}
     */
    this.requiresSupervisorApproval = null;
};

DigiTickets.Offer.prototype = {
    getHydrationMap() {
        return {
            applyPerTriggerLimit: toNullableInt,
            catIDs: toIntArray,
            code: {},
            eventIDs: toIntArray,
            ID: {
                field: ['ID', 'offerID'],
                transform: toInt
            },
            itemIDs: toIntArray,
            name: {},
            requiresSupervisorApproval: toBool,
            target: {},
            trigger: {},
            value: toFloat,
            valueType: {}
        };
    },

    isManuallyAppliedToCart: function isManuallyAppliedToCart() {
        return this.trigger === 'manualOrder';
    },

    isManuallyAppliedToLine: function isManuallyAppliedToLine() {
        return this.trigger === 'manualLine';
    },

    isVariable: function isVariable() {
        return this.valueType === 'variable';
    },

    /**
     * Check if this offer can be applied to the given item ID.
     *
     * @param {number} itemID
     * @param {number} catID
     * @param {number} eventID
     *
     * @return {boolean}
     */
    supportsItem: function supportsItem(itemID, catID, eventID) {
        switch (this.target) {
            case 'anyItems':
                return true;
            case 'selectedItems':
                return this.itemIDs.indexOf(itemID) !== -1;
            case 'anyEvents':
                return !!eventID;
            case 'selectedEvents':
                return this.eventIDs.indexOf(eventID) !== -1;
            case 'selectedCategories':
                return this.catIDs.indexOf(catID) !== -1;
        }
    }
};
