const VerifoneLogoutRequestRecord = function () {
    /**
     * 0 - Logout
     * 1 - Print Q Report and logout
     * 2 - Exit and logout
     * 3 - Exit and logout with Q report
     * 4 - Submit offline transactions, logout and shut down
     *
     * @type {number}
     */
    this.function = 0;
};

VerifoneLogoutRequestRecord.prototype = {
    toArray() {
        return [
            'O', // 1: Message Type - Indicates Logout Record
            this.function, // 2: Function
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneLogoutRequestRecord;
}
