const config = {
    analytics: {
        trackingId: 'UA-17567200-11'
    },
    digitickets: {
        channelID: 4, // Used as paymentChannelID on orders and payments. Hardcoded to 4 (EPOS/API)
        apiVersion: 2
    },
    googleMapsApiKey: {
        Development: 'AIzaSyCjTjUmknyhO_UlMjic4Ns7uQIUBXDsfeU',
        Staging: 'AIzaSyCVeL4KJ9krXus8eexNMYERLY_g85Emxqk',
        Production: 'AIzaSyB1fTcVpVyVCN1EzXuwotG7yJVGgUXbXeY'
    },
    // More than this many sessions causes performance problems,
    // both on the server, and in this app.
    // In future, we'll want to get rid of the hard limit (except, perhaps for
    // offline mode), and instead just grab the sessions we need on the fly.
    maxSessionsToSync: 10000,
    sentry: {
        dsn: 'https://cbcfde84b1df49c78b6ea5addfd5195f@sentry.io/1194396'
    },
    socketUrl: {
        Development: 'https://socket.digitickets.test:2053',
        Staging: 'https://dtapps.co.uk:2096',
        Production: 'https://dtapps.co.uk:2053'
    },
    support: {
        email: 'support@digitickets.co.uk',
        telephone: '+44 (0)1392 826 980'
    },
    validDomains: {
        'localhost:9876': 'Development', // Karma test runner
        'digitickets.test': 'Development',
        'digitickets.mobi': 'Development',
        'digitickets.vm': 'Development',
        'dtstaging.co.uk': 'Staging',
        'digitickets.co.uk': 'Production',
        'digitickets.ie': 'Production',
        'digitickets.aws': 'Production',
        'digitickets.anthonykuske.com': 'Staging'
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = config;
}
