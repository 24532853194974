const PaymentMethodRef = require('../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

const OrderPaymentsCtrl = function OrderPaymentsCtrl(
    $scope,
    $modalInstance,
    payments
) {
    $scope.payments = payments;

    /**
     * These statuses correspond to those used in Backoffice except for cancelled. When a Payment on Account payment is
     * fulfilled by a real payment we may want to update it to paid = 0 and pending = 0. This is a temporary solution
     * as our payment statuses are a bit simplistic at the moment.
     *
     * @param {DigiTickets.Payment} payment
     * @returns {string}
     */
    $scope.displayStatus = function displayStatus(payment) {
        if (payment.paid === false && payment.pending === false) {
            if (payment.paymentMethod.ref === PaymentMethodRef.PAYMENT_ON_ACCOUNT) {
                return 'ORDER_PAYMENTS.STATUS_SETTLED';
            }
            return 'ORDER_PAYMENTS.STATUS_FAILED';
        } else if (payment.paid === true) {
            return 'ORDER_PAYMENTS.STATUS_PAID';
        }
        return 'ORDER_PAYMENTS.STATUS_PENDING';
    };

    $scope.cancel = function cancel() {
        $modalInstance.close();
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderPaymentsCtrl;
}
