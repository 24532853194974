/**
 * @param {string} driverRef
 * @param {?string} [printerName]
 */
const PrintRouting = function (driverRef, printerName) {
    /**
     * @type {!string}
     */
    this.driverRef = driverRef;

    /**
     * @type {?string}
     */
    this.printerName = printerName || null;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrintRouting;
}
