const { toInt } = require('../functions/transform');

const TaxRegistration = function () {
    this.companyID = null;
    this.countryID = null;
    this.ID = null;
    this.registrationCode = null;
};

TaxRegistration.prototype = {
    getHydrationMap() {
        return {
            companyID: toInt,
            countryID: toInt,
            ID: {
                field: ['ID', 'taxRegistrationID'],
                transform: toInt
            },
            registrationCode: {}
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TaxRegistration;
}
