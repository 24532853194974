const { toString } = require('../functions/transform');

DigiTickets.OrderInProgress = function () {
    /**
     * @type {DigiTickets.StashedCart|null}
     */
    this.stashedCart = null;

    /**
     * Can be 'sell' or 'pay'.
     *
     * @type {string}
     */
    this.state = 'sell';

    /**
     * @type {DigiTickets.TransactionInProgress}
     */
    this.transactionInProgress = null;
};

DigiTickets.OrderInProgress.prototype = {
    getHydrationMap() {
        return {
            stashedCart: {
                model: DigiTickets.StashedCart
            },
            state: toString,
            transactionInProgress: {
                model: DigiTickets.TransactionInProgress
            }
        };
    }
};
