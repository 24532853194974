const { toInt, toString } = require('../functions/transform');

DigiTickets.EposPromoImage = function () {
    this.ID = null;
    this.image = '';
    this.title = '';
};

DigiTickets.EposPromoImage.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'eposPromoImageID'],
                transform: toInt
            },
            image: toString,
            title: {}
        };
    }
};
