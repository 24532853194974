/**
 * @param $resource
 * @param {ApiRequestParams} ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 */
const DeviceResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'devices/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                deviceTime: () => (new Date()).toISOString(),
                eposVersion: AppConfig.eposVersion,
                resolve: '*'
            }
        ),
        {
            assign: {
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'devices/:deviceID'
            },
            queryUnassigned: {
                isArray: true,
                method: 'GET',
                params: {
                    // Ensure no devicGuid is sent with this request. It is usually
                    // sent with every request, from ApiRequestParams.defaults().
                    deviceGuid: '',
                    unassigned: 1
                },
                url: AppConfig.apiBaseUrl + 'devices'
            },
            refresh: {
                method: 'GET',
                url: AppConfig.apiBaseUrl + 'devices/fromCookie'
                // This contains the deviceGuid param which contains the guid from the cached device.
            },
            setPrintConfig: {
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'devices/:deviceID/printconfig'
            },
            setDeviceInfo: {
                method: 'PATCH',
                url: AppConfig.apiBaseUrl + 'devices/:deviceID/info'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DeviceResource;
}
