/**
 * @param {LangService} LangService
 *
 * @return {function(string, object): string}
 */
const langFilter = (LangService) => (key, parameters) => LangService.getText(key, parameters);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = langFilter;
}
