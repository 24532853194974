const Cart = require('./Cart/Cart');
const OrderFulfillment = require('./Fulfillments/OrderFulfillment');
const { toDate, toNullableInt, toNullableString } = require('../../functions/transform');

/**
 * A "copy" of a cart. It's used when stashing/restoring the cart when switching between users.
 * It basically holds a copy of the customer, a cut-down copy of the items, and the notes string.
 */
DigiTickets.StashedCart = function StashedCart() {
    // These first properties are actually on the stashed carts table...

    /**
     * Primary key of the stashed cart saved in the database.
     *
     * @type {Number}
     */
    this.stashedCartID = null;

    /**
     * A key generated on the device for this stash.
     * TODO: Remove and just use thirdPartyRef
     *
     * @type {String}
     */
    this.guid = null;

    /**
     * TODO: Remove and just use thirdPartyRef
     *
     * @type {String}
     */
    this.thirdPartyID = null;

    /**
     * @type {String}
     */
    this.thirdPartyRef = null;

    /**
     * @type {Number}
     */
    this.userID = null;

    /**
     * @type {Number}
     */
    this.deviceID = null;

    /**
     * @type {OrderFulfillment[]}
     */
    this.fulfillments = [];

    // End of fields for the stashed cart entity itself.
    // The following fields are data about the contents of the cart that was stashed rather than metadata
    // about the stash.

    this.fieldData = {};
    this.fulfilledAt = null;
    this.fulfillmentCreatedAt = null;

    /**
     * TODO: Rename to 'stashedAt'
     *
     * @type {Date}
     */
    this.stashDatetime = new Date();
    this.stashedAt = new Date();

    /**
     * When restoring a stash - was it loaded from the local cache or the API.
     * (Only for debugging purposes)
     *
     * @type {String}
     */
    this.stashSource = null;

    this.username = null;

    /**
     * This seems like a strange property, but a StashedCart is actually a snapshot of the CartService.
     * That CartService has a currentCart property so we need to stash that too.
     *
     * Eventually this StashedCart entity will be very minimal and just represent an entity from the StashedCarts
     * table in the DB. It will have a only a few properties:
     * thirdPartyRef
     * currentCart
     * createdAt
     *
     * @type {Cart}
     */
    this.currentCart = new Cart();
};

DigiTickets.StashedCart.prototype = {
    getHydrationMap() {
        return {
            currentCart: {
                model: Cart
            },
            deviceID: toNullableInt,
            fieldData: {},
            fulfillments: {
                field: ['fulfillments', 'orderfulfillments'],
                modelCollection: OrderFulfillment
            },
            fulfilledAt: toDate,
            fulfillmentCreatedAt: toDate,
            guid: {},
            stashedAt: {
                field: ['stashedAt', 'stashDatetime'],
                transform: toDate
            },
            stashDatetime: toDate,
            stashedCartID: toNullableInt,
            thirdPartyID: {
                field: ['thirdPartyID', '3rdPartyID'],
                transform: toNullableString
            },
            thirdPartyRef: toNullableString,
            userID: toNullableInt,
            username: toNullableString
        };
    },

    /**
     * Check if the given stashed cart could be merged into this one.
     *
     * @param {DigiTickets.StashedCart} newStashedCart
     */
    canMerge: function canMerge(newStashedCart) {
        if (!this.currentCart.customer.hasData() || !newStashedCart.currentCart.customer.hasData()) {
            // Existing or new cart has no customer.
            return true;
        }

        // Both have a customer...
        if (
            this.currentCart.customer.hasAccount()
                && newStashedCart.currentCart.customer.hasAccount()
                && this.currentCart.customer.account.ID !== newStashedCart.currentCart.customer.account.ID
        ) {
            // Different accounts.
            return false;
        }

        if (
            !!this.currentCart.customer.getFullName()
                && !!newStashedCart.currentCart.customer.getFullName()
                && this.currentCart.customer.getFullName().toLowerCase() !== newStashedCart.currentCart.customer.getFullName().toLowerCase()
        ) {
            // Different names.
            return false;
        }

        if (
            !!this.currentCart.customer.email
                && !!newStashedCart.currentCart.customer.email
                && this.currentCart.customer.email.toLowerCase() !== newStashedCart.currentCart.customer.email.toLowerCase()
        ) {
            // Different emails.
            return false;
        }

        // Can merge!
        return true;
    },

    /**
     * Return the data in this StashedCart in a format that can be sent to the API.
     * stashedCartData is a JSON encoded string because if we send a raw object we run into a problem due to sending
     * too many variables in the request to the API.
     *
     * @return {object}
     */
    toServerData() {
        return {
            // Stashed cart metadata.
            stashedCartID: this.ID,
            guid: this.guid,
            thirdPartyID: this.thirdPartyID,
            thirdPartyRef: this.thirdPartyRef,
            stashDatetime: this.stashDatetime,
            stashedAt: this.stashDatetime, // Not used yet but sent for future.

            // Contents of the stash.
            stashedCartData: JSON.stringify({
                fieldData: this.fieldData,
                fulfilledAt: this.fulfilledAt,
                fulfillmentCreatedAt: this.fulfillmentCreatedAt,
                currentCart: this.currentCart
            })
        };
    },

    /**
     * @return {Customer}
     */
    get customer() {
        return this.currentCart.customer;
    },

    /**
     * @param {Customer} customer
     */
    set customer(customer) {
        this.currentCart.customer = customer;
    },

    /**
     * @return {?string}
     */
    get staffRef() {
        return this.currentCart.staffRef;
    },

    get numberOfLines() {
        return this.currentCart ? this.currentCart.itemList.length : 0;
    },

    get totalQuantity() {
        return this.currentCart ? this.currentCart.getTotalQty() : 0;
    },

    /**
     * @return {number}
     */
    get totalValue() {
        if (this.currentCart) {
            return this.currentCart.itemList.reduce(
                (total, line) => total + line.getLineTotal(),
                0.00
            );
        }

        return 0.00;
    }
};
