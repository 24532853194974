const angular = require('angular');

app.factory(
    'RfidScanDetectorService',
    [
        function () {
            let options = {};
            this.rfidScanDetector = new DigiTickets.RfidScanDetector(options, angular);

            return this.rfidScanDetector;
        },
    ]
);
