const LogoService = function () {

};

LogoService.prototype = {
    /**
     * Returns the URL for the logo to be printed on a receipt.
     * In order of preference:
     * - Base64 encoded current branch printable logo
     * - Base64 encoded company printable logo
     * - Current branch printable logo url
     * - Company printable logo url
     * - Nothing
     *
     * Note that it does not fallback to a non-printable logo because that may result in horrible output.
     *
     * @param {UserService} userService
     * @returns {?string}
     */
    getPrintableLogoUrl(userService) {
        if (userService.currentBranch.encodedPrintableLogo) {
            return userService.currentBranch.encodedPrintableLogo;
        } else if (userService.company.encodedPrintableLogo) {
            return userService.company.encodedPrintableLogo;
        } else if (userService.currentBranch.printableLogoUrl) {
            return userService.currentBranch.printableLogoUrl;
        } else if (userService.company.printableLogoUrl) {
            return userService.company.printableLogoUrl;
        }

        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = LogoService;
}
