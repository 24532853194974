const { toFloat, toString } = require('../../functions/transform');

/**
 * An object representing an integrated transaction in progress.
 *
 * @param paymentMethodRef
 * @param txnRef
 * @param amount
 * @param state
 */
DigiTickets.TransactionInProgress = function (
    paymentMethodRef,
    txnRef,
    amount,
    state
) {
    this.paymentMethodRef = paymentMethodRef;
    this.txnRef = txnRef;
    this.amount = amount;
    this.state = state || {};
};

DigiTickets.TransactionInProgress.prototype = {
    getHydrationMap() {
        return {
            amount: toFloat,
            paymentMethodRef: toString,
            state: {},
            txnRef: {}
        };
    }
};
