const ini = require('ini');

/**
 * Parse a .ini file and return its contents as an object.
 *
 * @param {string} iniString
 */
const parseIni = function (iniString) {
    if (typeof iniString !== 'string' || iniString.length < 1) {
        return {};
    }

    iniString = iniString.trim();
    if (!iniString) {
        return {};
    }

    return ini.parse(iniString);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        parseIni
    };
}
