const angular = require('angular');
const BigNumber = require('bignumber.js');

/**
 * @param $timeout
 * @param {object} options
 * @param {function} [onChangeCallback]
 */
DigiTickets.NumPad = function (
    $timeout,
    options,
    onChangeCallback
) {
    this.$timeout = $timeout;

    this.options = angular.extend(
        {
            /**
             * The number of decimal places to render any value.
             *
             * @type {number}
             */
            numDecimalPlaces: 2,

            /**
             * If set will show an 'Exact' button which will set the amount to this value when pressed.
             *
             * @type {number|null}
             */
            exactAmount: null,

            /**
             * Input is in pennies to {dp} decimal places.
             *
             * @todo This is never used and complicates things. Can it be removed?
             *
             * @type {boolean}
             */
            fixedDP: false,

            /**
             * The initial value.
             *
             * @type {number}
             */
            initialValue: 0,

            /**
             * Show buttons to add common denominations (20/10/5) to the value.
             *
             * @type {boolean}
             */
            showCashButtons: false,

            /**
             * Show the decimal button?
             *
             * @type {boolean}
             */
            showDecimalButton: true,

            /**
             * Show or hide the sign toggle button.
             *
             * @type {boolean}
             */
            showSignButton: true,

            /**
             * Input is negative.
             * This is toggled by the sign button.
             *
             * @type {boolean}
             */
            isNegative: false,

            /**
             * If true, and no initialValue is specified, the amount will be null until something is entered.
             * Otherwise it will be 0.
             *
             * @type {boolean}
             */
            allowNull: false,

            /**
             * An array of fields in which not to capture the keystrokes if the field is the event target. At the
             * moment this is simple html input types e.g. textarea but could be extended to use css selectors
             * if necessary.
             *
             * @type {string[]}
             */
            doNotCaptureKeysIn: [],

            /**
             * Function to be called when ESC is pressed.
             */
            cancel: function cancel() {
                // Does nothing by default.
            },

            /**
             * Function to be called when Enter is pressed.
             */
            ok: function () {
                // Does nothing by default.
            }
        },
        options
    );

    this.onChangeCallback = onChangeCallback;

    /**
     * Internal value as an integer from which floating point values will be calculated.
     *
     * @type {number}
     */
    this.amount = this.options.allowNull ? null : 0;

    /**
     * What to display on the decimal button.
     *
     * @type {string}
     */
    this.decimalButtonText = this.options.fixedDP ? '0'.repeat(this.options.numDecimalPlaces) : '.';

    /**
     * For floating point entry, keeps a track of whether decimal point button has been pressed.
     *
     * @type {boolean}
     */
    this.decimalHasBeenPressed = false;

    /**
     * Keep track of what decimal position we should be entering
     * when using non-fixed DP option.
     *
     * @type {number}
     */
    this.numDecimalsEntered = 0;

    /**
     * Store the initial value in pennies, but think of pennies as the integer value for any number of DP.
     */
    if (this.options.initialValue !== null) {
        if (this.options.fixedDP) {
            this.setAmount(this.options.initialValue * Math.pow(10, this.options.numDecimalPlaces));
        } else {
            this.setValue(this.options.initialValue);
        }
    }
};

DigiTickets.NumPad.prototype = {
    /**
     * Append a value to the amount.
     *
     * @param {number} value
     */
    inputValue: function inputValue(value) {
        let amount = Math.abs(this.getAmount());
        let newValue = parseInt(value);

        if (this.options.fixedDP) {
            // pennies entered by default
            amount = (amount * 10) + newValue;
        } else {
            // pounds entered by default
            if (this.decimalHasBeenPressed) {
                // ignore any requests to enter more decimal places that in currency
                if (this.numDecimalsEntered < this.options.numDecimalPlaces) {
                    let multiplier;
                    if (newValue >= 0) {
                        multiplier = Math.pow(10, this.options.numDecimalPlaces - (this.numDecimalsEntered + 1));
                    } else {
                        multiplier = Math.pow(10, this.options.numDecimalPlaces - (this.numDecimalsEntered));
                    }
                    let totalAmountToAdd = newValue * multiplier;
                    amount += totalAmountToAdd;
                    // adjust the position of entry for the next entry
                    this.numDecimalsEntered++;
                }
            } else {
                amount = (amount * 10) + (newValue * this.getIntMultiplier());
            }
        }
        this.setAmount(amount * (this.getAmount() < 0 ? -1 : 1));
    },

    /**
     * Add a value to the amount.
     *
     * @param {number} value
     */
    addValue: function addValue(value) {
        let amount = Math.abs(this.getAmount());

        amount += (parseInt(value) * this.getIntMultiplier());

        this.setAmount(amount * (this.getAmount() < 0 ? -1 : 1));
    },

    backspace: function backspace() {
        /**
         * The expected behaviour between fixed-DP mode and floating-DP mode
         * is different:
         *  - In fixed-DP mode, the backspace should just undo our last action
         *  - In floating-DP mode, backspace should just remove the last non-zero number
         */
        if (this.options.fixedDP) {
            this.removeLastDigit();
        } else {
            this.removeLastNumber();
        }
    },

    /**
     * Strip off the last digit of the current integer amount.
     * Typically used in fixed-DP mode to undo the last entered digit
     */
    removeLastDigit: function removeLastDigit() {
        let strVal = this.getAmount().toString();
        if (strVal.length > 0) {
            strVal = strVal.substring(0, strVal.length - 1);
        }

        // reformat as an int
        if (strVal === '' || strVal === '-') {
            strVal = '0';
        }
        let intVal = parseInt(strVal);
        this.setAmount(intVal);
    },

    /**
     * Remove the last non-zero number, from the current
     * currency string. Typically used when in floating DP mode
     * when the backspace button is pressed.
     */
    removeLastNumber: function removeLastNumber() {
        // convert our current float value to a string and treat as such
        let strVal = this.getValueAsFloat().toString();
        let numFound = false;
        do {
            let lastChar = strVal.charAt(strVal.length - 1);
            if (lastChar > 0) {
                numFound = true;
            }
            strVal = strVal.substring(0, strVal.length - 1);
            // keep deleting if we hit upon a decimal point or a zero
        } while (strVal.length > 0 && numFound === false);

        this.decrementDecimalsEntered();

        // if the decimal point has been removed, reset the counters
        if (strVal.indexOf('.') === -1) {
            this.decimalHasBeenPressed = false;
            this.numDecimalsEntered = 0;
        }

        // reformat as a float
        if (strVal === '' || strVal === '-') {
            strVal = '0';
        }
        let floatVal = parseFloat(strVal);
        // convert back to an integer
        let intVal = this.getIntMultiplier() * floatVal;
        this.setAmount(intVal);
    },

    decimal: function decimal() {
        this.decimalHasBeenPressed = true;
    },

    pennies: function pennies() {
        if (!this.decimalHasBeenPressed) {
            this.decimalHasBeenPressed = true;
            this.setAmount(this.getAmount() * this.getIntMultiplier());
        }
    },

    clear: function clear() {
        this.setAmount(0.00);
        this.numDecimalsEntered = 0;
        this.decimalHasBeenPressed = false;
    },

    toggleSign: function toggleSign() {
        this.options.isNegative = !this.options.isNegative;
    },

    keyDownReceived: function keyDownReceived(event, scope) {
        let self = this;

        // The key capture is greedy. Sometimes we want to exclude it from capturing key presses in specific
        // elements such as textarea fields where it can cause problems like preventing the backspace button.
        for (let target of this.options.doNotCaptureKeysIn) {
            if (event.target.type === target) {
                return;
            }
        }

        /**
         * keyCode, which and charCode are all different depending upon browser, keyboard layout, language, and keypress or keyup/down.
         * Keypress doesn't give esc.
         * And different on the numeric keypad.
         * [0]-[9] / [0]-[9] => 48-57 / 96-105
         * [-]     / [-]     => 189   / 109
         * [.]     / [.]     => 190   / 110
         * [CR]              => 13
         * [BKSP]            => 8
         * [ESC]             => 27
         */
        let keyCode = event.which;

        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
            // number keys
            event.preventDefault();
            this.inputValue(keyCode - (keyCode >= 96 ? 96 : 48));
        } else if (keyCode === 190 || keyCode === 110) {
            // decimal key
            event.preventDefault();
            if (this.options.fixedDP) {
                this.pennies();
            } else if (this.options.numDecimalPlaces > 0) {
                this.decimal();
            }
        } else if (keyCode === 13) {
            // return key
            event.preventDefault();
            // This timeout exists because the ok method will often dismiss the modal, removing the element that
            // the keydown event happened on. This breaks something deep in Angular to do with event listeners.
            // By waiting a few ms before dismising the modal (probably what the ok function does) it avoids
            // that problem.
            this.$timeout(function () {
                self.options.ok();
            }, 100);
        } else if (keyCode === 8) {
            // backspace
            event.preventDefault();
            this.backspace();
        } else if (keyCode === 27) {
            // escape
            event.preventDefault();
            if (this.getAmount() !== 0 && this.getAmount() !== null) {
                this.clear();
            } else {
                this.$timeout(function () {
                    self.options.cancel();
                }, 100);
            }
        } else if (keyCode === 189 || keyCode === 109) {
            // Minus (189) or numpad subtract (109)
            event.preventDefault();
            if (this.options.showSignButton) {
                // Only allow changing sign by key if the sign button is shown.
                this.toggleSign();
            }
        }

        // update the UI
        scope.$apply();
    },

    /**
     * Decrease the decimals entered counter
     */
    decrementDecimalsEntered: function decrementDecimalsEntered() {
        this.numDecimalsEntered = Math.max(this.numDecimalsEntered - 1, 0);
    },

    /**
     * Get the decimal button display.
     *
     * @return {string}
     */
    getDecimalButtonText: function getDecimalButtonText() {
        return this.decimalButtonText;
    },

    /**
     * Get the current amount as an integer.
     *
     * @return {number|null}
     */
    getAmount: function getAmount() {
        if (this.amount === null) {
            return null;
        }

        if (this.options.isNegative) {
            return (new BigNumber(this.amount)).multipliedBy(-1).toNumber();
        }
        return this.amount;
    },

    /**
     * Get the current amount as a number.
     *
     * @return {number|null}
     */
    getValue: function getValue() {
        if (this.getAmount() === null) {
            return null;
        }

        return (new BigNumber(this.getAmount())).dividedBy(this.getIntMultiplier()).toNumber();
    },

    /**
     * Setter for the value.
     *
     * @param {float} value
     */
    setValue: function setValue(value) {
        this.setAmount(
            (new BigNumber(value)).multipliedBy(this.getIntMultiplier()).toNumber()
        );
    },

    /**
     * Get the current amount as a string containing the value as a float to a fixed number of decimal places.
     *
     * @return {string|null}
     */
    getValueAsFloat: function getValueAsFloat() {
        return this.getValue() === null ? 0.00 : this.formatFloatAsCurrency(this.getValue());
    },

    /**
     * Setter for amount.
     *
     * @param {number|null} amount
     */
    setAmount: function setAmount(amount) {
        if (amount === null) {
            this.amount = null;
        } else {
            amount = Math.abs(parseInt(amount));
            if (amount === 0 && this.options.allowNull) {
                this.amount = null;
            } else {
                this.amount = amount;
            }
        }

        if (this.onChangeCallback) {
            this.onChangeCallback(this.getValue());
        }
    },

    //
    // Get the multiplier required to turn an integer amount into
    // the equivalent floating point currency value.
    //
    // @return {number}
    // @param offset
    //
    getIntMultiplier: function getIntMultiplier(offset) {
        if (typeof offset === 'undefined') {
            offset = 0;
        }

        return Math.pow(10, this.options.numDecimalPlaces - offset);
    },

    /**
     * Format a float value to the correct number of decimal places
     * for a currency.
     *
     * @param {number} amount
     *
     * @return {string}
     */
    formatFloatAsCurrency: function formatFloatAsCurrency(amount) {
        return amount.toFixed(this.options.numDecimalPlaces);
    },

    /**
     * Set how much will be entered when pressing the 'Exact' button.
     *
     * @param {number|null} amount
     */
    setExactAmount: function setExactAmount(amount) {
        this.options.exactAmount = amount;
    }
};
