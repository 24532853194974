/**
 * @param {Cart|DigiTickets.Order} cartOrOrder
 *
 * @return {FieldGroup[]}
 */
const getAllFieldGroupsFromOrder = (cartOrOrder) => {
    /** @type {FieldGroup[]} */
    let fieldGroups = [
        // Add order level field group.
        cartOrOrder.getOrderLevelFieldGroup(),
    ];

    // Add item level field groups.
    cartOrOrder.getLines().forEach((line) => {
        line.getItemInstances().forEach((itemInstance) => {
            fieldGroups.push(itemInstance.fields);
        });
    });

    return fieldGroups;
};

/**
 * @param {Cart} cart
 *
 * @return {FieldInstance[]}
 */
const getAllFieldInstancesFromCart = (cart) => getAllFieldGroupsFromOrder(cart)
    .reduce((fieldInstances, fieldGroup) => fieldInstances.concat(...fieldGroup.instances), []);

/**
 * Extracts all the field data from the cart and returns a flattened object where they keys are the field
 * instance keys and the values are the user entered value for the field.
 *
 * @param {Cart} cart
 *
 * @return {Object<string, string|number|number[]>}
 */
const getFieldValuesFromCart = (cart) => {
    let values = {};

    getAllFieldInstancesFromCart(cart)
        .forEach((fieldInstance) => {
            let value = fieldInstance.getValue();
            if (value !== null) {
                values[fieldInstance.key] = fieldInstance.getValue();
            }
        });

    return values;
};

/**
 * Set the value of a single field instance from a big object of all values, keyed by the instance keys.
 * Optionally clears the value for all instances that do not have a value present in the given values.
 *
 * @param {Object<string, string>} fieldValues
 * @param {FieldInstance} fieldInstance
 * @param {boolean} [clearExisting=false]
 */
const setValueOfFieldInstance = (fieldValues, fieldInstance, clearExisting = false) => {
    if (fieldValues.hasOwnProperty(fieldInstance.key)) {
        fieldInstance.setValue(fieldValues[fieldInstance.key]);
    } else if (clearExisting) {
        fieldInstance.clearValue();
    }
};

/**
 * @param {Object<string, string>} fieldValues
 * @param {FieldGroup} fieldGroup
 * @param {function} [fieldInstanceFilter] Optional function to filter only certain instances to be modified.
 *                                         See FieldInstanceFilter for ready made examples.
 * @param {boolean} [clearExisting=false]
 */
const setValuesOfFieldGroup = (fieldValues, fieldGroup, fieldInstanceFilter, clearExisting = false) => {
    let instances = fieldGroup.instances;

    if (fieldInstanceFilter) {
        instances = instances.filter(fieldInstanceFilter);
    }

    instances.forEach(
        (fieldInstance) => setValueOfFieldInstance(fieldValues, fieldInstance, clearExisting)
    );
};

/**
 * @param {Object<string, string>} fieldValues
 * @param {Cart} cart
 * @param {function} [fieldInstanceFilter]
 * @param {boolean} [clearExisting=false]
 */
const setFieldValuesOnCart = (fieldValues, cart, fieldInstanceFilter, clearExisting = false) => {
    let fieldGroups = getAllFieldGroupsFromOrder(cart);
    fieldGroups.forEach(
        (fieldGroup) => setValuesOfFieldGroup(fieldValues, fieldGroup, fieldInstanceFilter, clearExisting)
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        getAllFieldGroupsFromOrder,
        getAllFieldInstancesFromCart,
        getFieldValuesFromCart,
        setFieldValuesOnCart,
        setValueOfFieldInstance,
        setValuesOfFieldGroup
    };
}
