const DebugModalCtrl = require('./DebugModalCtrl');

/**
 * @param $modalInstance
 * @param $scope
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {CurrentDevice} CurrentDevice
 * @param {ModalFactory} ModalFactory
 * @param OnlineQueueService
 * @param {OrderQueue} orderQueue
 * @param {UserService} UserService
 */
const AboutCtrl = function AboutCtrl(
    $modalInstance,
    $scope,
    AppConfig,
    ConnectivityChecker,
    CurrentDevice,
    ModalFactory,
    OnlineQueueService,
    orderQueue,
    UserService
) {
    $scope.appConfig = AppConfig;
    $scope.user = UserService;
    $scope.device = CurrentDevice.device;

    $scope.isOnline = function isOnline() {
        return ConnectivityChecker.isOnline();
    };

    $scope.onlineQueueLength = function onlineQueueLength() {
        return OnlineQueueService.getQueue().length;
    };

    $scope.orderQueueLength = function orderQueueLength() {
        return orderQueue.orderList.length;
    };

    $scope.ok = function ok() {
        $modalInstance.close(true);
    };

    $scope.openDebugModal = () => {
        ModalFactory.open(
            'debug',
            DebugModalCtrl,
            'partials/modals/debugModal.html'
        );
    };

    $scope.checkingConnectivity = false;
    $scope.checkConnectivity = function checkConnectivity() {
        $scope.checkingConnectivity = true;
        ConnectivityChecker.check()
            .then(
                (result) => {
                    if (result) {
                        window.alert('Online');
                    } else {
                        window.alert('Offline');
                    }
                }
            ).catch(
                () => window.alert('Connectivity test error')
            ).finally(
                () => {
                    $scope.checkingConnectivity = false;
                }
            );
    };
};


/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AboutCtrl;
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AboutCtrl;
}
