const apiErrorMessage = require('./Api/apiErrorMessage');

/**
 * @param $q
 * @param ReturnReasonResource
 * @param {Hydrator} hydrator
 */
DigiTickets.ReturnReasonManager = function (
    $q,
    ReturnReasonResource,
    hydrator
) {
    this.deferred = $q.defer();
    this.returnReasonResource = ReturnReasonResource;
    this.hydrator = hydrator;

    this.returnReasons = [];
    this.returnReasonResource = ReturnReasonResource;
};

DigiTickets.ReturnReasonManager.prototype = {
    loadReturnReasons: function loadReturnReasons() {
        let self = this;

        this.returnReasonResource.query(
            function (results) {
                self.returnReasons = self.hydrator.hydrateArray(
                    results,
                    function () {
                        return new DigiTickets.ReturnReason();
                    }
                );

                self.deferred.resolve(self.returnReasons);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return this.deferred.promise;
    },

    getReturnReasons: function getReturnReasons() {
        return this.deferred.promise;
    }
};
