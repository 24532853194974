const { toBool, toFloat, toInt, toNullableInt, toString, toNullableString } = require('../../../functions/transform');

/**
 * Represents an individual offer applied to a line in a cart (or order)
 * A line may have multiple discounts applying to a subset of the total qty of the line, and some discounts
 * might be inactive.
 */
const CartItemDiscount = function () {
    /**
     * A discount can be added to a line but be inactive. This is the case when an offer was calculated for a line
     * but the operator has manually selected a discount for that line. The offer is still displayed for information
     * purposes only.
     *
     * @type {boolean}
     */
    this.active = true;

    /**
     * The total amount of discount this discount is giving (offer amount * this.qty)
     *
     * @type {number}
     */
    this.amount = 0;

    /**
     * Description of the discount/offer.
     *
     * @type {string}
     */
    this.description = '';

    /**
     * Indicates if this discount was manually chosen by the operator.
     *
     * @type {boolean}
     */
    this.manual = false;

    /**
     * How many of the items on this line does the discount apply to?
     * This is used because we do not split up cart lines like the frontend does. Instead we keep it as
     * one line and use this to keep track of which offers/discounts apply to what portion of each line.
     * null means it should apply to all of the remaining qty on the line that does not already have a discount.
     *
     * @type {number|null}
     */
    this.qty = 0;

    /**
     * ID of the Offer used to achieve the discount.
     *
     * @type {?number}
     */
    this.offerID = null;

    /**
     * @type {DigiTickets.Offer}
     */
    this.offer = null;

    /**
     * The percentage each item is discounted by (calculated if it is a 'value' discount)
     *
     * @type {number}
     */
    this.percentage = 0;

    /**
     * The type of discount applied (value or percentage or variable)
     *
     * @type {string}
     */
    this.type = '';

    /**
     * If this discount was created by an Experience Gift Voucher there should be a voucherCode
     *
     * @type {?string}
     */
    this.voucherCode = null;

    /**
     * The /cartcalculations API response includes a reason e.g. gift-voucher
     *
     * @type {?string}
     */
    this.reason = null;
};

CartItemDiscount.prototype = {
    /**
     * @return {string}
     */
    getDescription() {
        if (this.description) {
            return this.description;
        }

        if (this.offer && this.offer.name) {
            return this.offer.name;
        }

        return '';
    },

    /**
     * @return {boolean}
     */
    isActive() {
        return this.active;
    },

    /**
     * @param {boolean} active
     */
    setActive(active) {
        this.active = !!active;
    },

    /**
     * @return {boolean}
     */
    isVariable() {
        // Check for the existence of an Experience Voucher which is a discount without an offer.
        return !!(this.offer && this.offer.isVariable());
    },

    getHydrationMap() {
        return {
            active: toBool,
            amount: toFloat,
            description: toString,
            manual: toBool,
            offer: {
                model: DigiTickets.Offer
            },
            offerID: toNullableInt,
            percentage: toFloat,
            qty: toInt,
            reason: toNullableString,
            type: toString,
            voucherCode: toNullableString
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartItemDiscount;
}
