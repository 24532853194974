const { toInt, toString } = require('../functions/transform');

const CurrencyDenomination = require('./CurrencyDenomination');

const Currency = function () {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * e.g. "Pounds Sterling"
     *
     * @type {string}
     */
    this.name = '';

    /**
     * e.g. "GBP"
     *
     * @type {string}
     */
    this.code = '';

    /**
     * HTML Symbol code e.g. "&pound;"
     *
     * @type {string}
     */
    this.html = '';

    /**
     * ISO 4217 currency code e.g. 826
     * https://en.wikipedia.org/wiki/ISO_4217
     *
     * @type {?number}
     */
    this.number = null;

    /**
     * @type {CurrencyDenomination[]}
     */
    this.currencyDenominations = [];
};

Currency.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'currencyID'],
                transform: toInt
            },
            name: toString,
            code: toString,
            html: toString,
            number: toInt,
            currencyDenominations: {
                modelCollection: CurrencyDenomination
            }
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = Currency;
}
