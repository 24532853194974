const axios = require('axios');
const Qs = require('qs'); // This has to be Qs not qs because the dist/qs.js calls it that.
const logFactory = require('../../Logging/logFactory');

const PrinterAppPrintDriver = function () {
    /**
     * @type {AxiosInstance}
     */
    this.axios = axios.create({
        baseURL: 'https://localhost.dtapps.co.uk:44301',
        timeout: 10000
    });

    this.log = logFactory('PrinterAppPrintDriver');
};

PrinterAppPrintDriver.prototype = {
    /**
     * @param {Window} frame
     * @param {PrintJobOptions} opts
     *
     * @return {Promise}
     */
    print(frame, opts) {
        // Get the HTML from the frame.
        let html = frame.document.documentElement.innerHTML;

        this.log.debug('Printing', html.substr(0, 30));

        if (opts && typeof opts.onBeforePrintCall === 'function') {
            opts.onBeforePrintCall();
        }

        // Send the HTML to the app to print.
        return this.axios.post(
            '/print',
            Qs.stringify({
                contents: html,
                printerName: opts && opts.printRouting ? opts.printRouting.printerName : null
            })
        ).then((result) => {
            this.log.debug('Print response', result);
            if (result.data && result.data.success === true) {
                return true;
            }
            throw new Error('Unsuccessful response: ' + JSON.stringify(result.data));
        });
    },

    /**
     * @return {Promise<{name: string}>}
     */
    getPrinters() {
        return this.axios.get('/printers')
            .then((result) => result.data.printers);
    },

    /**
     * @return {Promise<string>}
     */
    getPrinterNames() {
        return this.getPrinters()
            .then((printers) => printers.map((p) => p.name));
    },

    /**
     * @return {Promise<{printerAppVersion: string, printerAppDirectory: string}>}
     */
    getAppInfo() {
        return this.axios.get('/')
            .then((result) => ({
                printerAppVersion: result.data.version,
                printerAppDirectory: result.data.path,
                printerAppStartedAt: result.data.startedAt
            }));
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PrinterAppPrintDriver;
}
