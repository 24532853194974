const FieldGroup = require('./FieldGroup');
const FieldInstance = require('./FieldInstance');
const FieldLevel = require('./FieldLevel');

/**
 * @param {FieldManager} fieldManager
 */
const FieldInstanceFactory = function (
    fieldManager
) {
    /**
     * @type {FieldManager}
     */
    this.fieldManager = fieldManager;
};

FieldInstanceFactory.prototype = {
    /**
     * @param {Cart} cart
     *
     * @return {FieldGroup}
     */
    createFieldGroupsOnCart(cart) {
        // Add the order level fields to a single FieldGroup on the cart.
        cart.fields = this.createOrderLevelFieldGroupForCart(cart);

        // Add the item level fields to a FieldGroup on each ItemInstance in the cart.
        cart.itemList.forEach((line) => {
            let lineFields = this.fieldManager.getFieldsForLines(
                FieldLevel.ITEM, // Get all item level fields
                [line]
                // Note ask early fields are included.
            );

            line.itemInstances.forEach((itemInstance) => {
                itemInstance.fields = this.createItemLevelFieldGroupForInstance(
                    itemInstance,
                    line,
                    lineFields
                );
            });
        });
    },

    /**
     * Creates a FieldGroup object containing all the order level FieldInstances that should
     * exist based on items in the cart.
     *
     * @param {Cart} cart
     *
     * @return {FieldGroup}
     */
    createOrderLevelFieldGroupForCart(cart) {
        let fields = this.fieldManager.getFieldsForLines(
            FieldLevel.ORDER, // Get all order level fields.
            cart.itemList, // That either always apply or are applicable to the items in the cart.
            false // That are not 'askEarly' fields.
        );

        let fieldGroup = new FieldGroup({
            level: FieldLevel.ORDER
        });

        fields.forEach(
            /**
             * @param {Field} field
             */
            (field) => {
                let fieldInstance = new FieldInstance({ field });
                fieldGroup.add(fieldInstance);
            }
        );

        return fieldGroup;
    },

    /**
     * Create a FieldGroup containing only the askEarly fields for the item. Returns null if there are no
     * askEarly fields.
     *
     * @param {ItemInstance} itemInstance
     * @param {DigiTickets.CartItem} line
     * @return {FieldGroup|null}
     */
    createAskEarlyFieldGroupForInstance(itemInstance, line) {
        let fields = this.fieldManager.getFieldsForLines(
            FieldLevel.ITEM,
            [line],
            true
        );

        if (fields.length < 1) {
            return null;
        }

        return this.createItemLevelFieldGroupForInstance(itemInstance, line, fields);
    },

    /**
     * @private
     *
     * @param {ItemInstance} itemInstance
     * @param {DigiTickets.CartItem} line
     * @param {Field[]} fields The list of fields is passed in here to avoid repetitive field loading
     *                         because they will be the same for every instance on the line.
     */
    createItemLevelFieldGroupForInstance(itemInstance, line, fields) {
        // Create a new Field Group.
        let fieldGroup = new FieldGroup({
            level: FieldLevel.ITEM,
            title: `${line.getName()} (${itemInstance.instance})`,
            subtitle: line.getSessionFullName(),
            lineNumber: line.lineNumber,
            itemInstance: itemInstance.instanceForFieldData
        });

        fields.forEach(
            /**
             * @param {Field} field
             */
            (field) => {
                let fieldInstance = new FieldInstance({
                    field,
                    lineNumber: line.lineNumber,
                    itemInstance: itemInstance.instanceForFieldData
                });
                fieldGroup.add(fieldInstance);
            }
        );

        return fieldGroup;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldInstanceFactory;
}
