/**
 * Actions that can be sent from the EPOS to the terminal (via the the integration socket),
 * in a Continue Transaction (CONTTXN Record).
 *
 * From the "Action ID Messages" section in "POS Client - Integration Guide V2.1"
 *
 * @type {Object<string[]>} actionID: ["Description", "Possible Parameters"]
 */
const VerifoneActions = {
    // 0: ["Not Specified"], // Reserved for Internal Use
    1: ['Bypass PIN'],
    2: ['Continue Transaction'],
    3: ['Confirm Signature'],
    4: ['Reject Signature'],
    5: ['Reprint Receipt', 'REPRINTOPT'],
    // 6: ["Keyed Entry Required"], // Not Used
    7: ['Voice Referral Authorised', 'AUTHCODE'],
    8: ['Voice Referral Rejected'],
    // 9: ["Gratuity Required"], // Not Used
    // 10: ["Gratuity Not Required"], // Not Used
    // 11: ["Gratuity On PED"], // Not Used
    12: ['Cancel Transaction'],
    13: ['Alternate Payment'],
    // 14: ["Customer Present Required"], // Not Used
    // 15: ["Customer Not Present Required"], // Not Used
    // 16: ["E-Commerce Required"], // Not Used
    // 17: ["Mail Order Required"], // Not Used
    // 18: ["Telephone Order Required"], // Not Used
    // 19: ["Change Card"], // Not Used
    20: ['Confirm Auth Code'],
    21: ['Reject Auth Code'],
    22: ['Charge Auth Code', 'AUTHCODE'],
    23: ['Reverse UAT ICC Txn'],
    24: ['Retry Device'],
    25: ['Continue Without Device'],
    26: ['Abort Device Connectivity'],
    27: ['Retry Download'],
    28: ['Cancel Download'],
    29: ['Cashback Required', 'CASHBACK'],
    30: ['Cashback Not Required'],
    31: ['Restart'],
    32: ['Accept Unsafe Download'],
    33: ['Reject Unsafe Download'],
    34: ['Replace Account', 'MGRPIN'],
    35: ['Cancel Replace Account'],
    // 36: ["Confirm Gratuity"], // Not Used
    // 37: ["Change Gratuity"], // Not Used
    38: ['Account On File Registration Required'],
    39: ['Account On File Registration Not Required'],
    40: ['Attempt To Reconnect'], // Reconnect To Server
    41: ['Go Offline'], // Abort Reconnect To Server
    42: ['Select PayPoint Account', 'PAYPOINTACCID'],
    43: ['Select PayPoint Option', 'PAYPOINTOPTIONID'],
    44: ['Retry PayPoint Confirmation'],
    45: ['Cancel PayPoint Confirmation'],
    46: ['Accept Licence Key'],
    47: ['Reject Licence Key'],
    48: ['Cancel Licence Key Verification'],
    49: ['Continue Licence Key Verification'],
    52: ['Confirm Park Retail Gift Transaction'],
    53: ['Reverse Park Retail Gift Transaction'],
    54: ['Cancel Get Card Details (GDET)'],
    55: ['No Transaction Value Update Required'],
    56: ['Transaction Value Update Required', 'TXNVALUE'],
    57: ['Merchant Currency Required'],
    58: ['Cardholder Currency Required'],
    59: ['Merchant Reference Entered', 'REF'],
    60: ['Supply IVR Details'],
    61: ['Reject IVR'],
    62: ['Print Shop Copy Receipt'],
    63: ['Cancel Shop Copy Receipt'],
    64: ['Supply STAN'],
    65: ['Accept Part Payment'],
    66: ['Reject Part Payment'],
    67: ['Further Payment Required'],
    68: ['No Further Payment Required']
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneActions;
}
