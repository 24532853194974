const { arrayCombine, isValidDateObject } = require('../../functions/functions');
const is = require('../Is');

/**
 * The OrderCache holds the raw order objects parsed form the API's json. We don't automatically
 * hydrate these (as we used to) as when you've got 5000+ orders coming in, some devices
 * just can't cope with that amount of processing. Instead, we lazy-hydrate them at the point
 * at which they're required (usually an offline order search).
 *
 * @param $q
 * @param OrderResource
 * @param {DigiTickets.LargeVolumeStorageImplementation} OrderCache
 * @param {Hydrator} hydrator
 * @param {DigiTickets.Logger} Logger
 */
const OrderManager = function OrderManager(
    $q,
    OrderResource,
    OrderCache,
    hydrator,
    Logger
) {
    this.Logger = Logger;

    this.searchedBarcode = null;

    this.$q = $q;

    this.formatEndDateString = function formatEndDateString(date) {
        return date + ' 23:59:59';
    };

    // Private functions that no one needs to access except the class below..
    this.mutateDates = function mutateDates(dateType, searchParams) {
        if (dateType === 'session') {
            searchParams.eventTimeStart = searchParams.dateStart;
            searchParams.eventTimeEnd = searchParams.dateEnd;

            delete searchParams.dateStart;
            delete searchParams.dateEnd;
        }

        return searchParams;
    };

    this.getDefaultParams = function getDefaultParams() {
        return {
            limit: 50
        };
    };

    /**
     * @return {DigiTickets.LargeVolumeStorageImplementation}
     */
    this.getCache = function getCache() {
        return OrderCache;
    };

    this.getResource = function getResource() {
        return OrderResource;
    };

    this.getHydratedCollection = function getHydratedCollection(results) {
        return hydrator.hydrateArray(results, function () {
            return new DigiTickets.Order();
        });
    };

    this.getHydratedObject = function getHydratedObject(result) {
        return hydrator.hydrate(result, new DigiTickets.Order());
    };

    /**
     * @type {Object<string[]>}
     */
    this.columnNamesByTable = {};
    this.aliasKeys = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    this.tableKeyAliases = {};

    // Populate the column names by table structure and the table aliases.
    // Delete any old format of data from localStorage.
    let aliases = this.getCache().getItem('aliases');
    if (aliases) {
        this.tableKeyAliases = JSON.parse(aliases);
    }

    Object.keys(this.tableKeyAliases).forEach(
        (table) => {
            let alias = this.tableKeyAliases[table];
            this.columnNamesByTable[table] = JSON.parse(this.getCache().getItem(alias + '.columns'));
        }
    );

    if (typeof localStorage !== 'undefined') {
        Object.keys(localStorage).forEach(function (key) {
            // Drop historic data - 'OrderCache.xxxxx'
            if (key.indexOf('OrderCache.') === 0) {
                localStorage.removeItem(key);
            }
        });
    }
};

OrderManager.prototype = {

    search: function search(searchParams, callbackSuccess) {
        let self = this;

        this.searchedBarcode = searchParams.search;
        this.remoteSearch(
            searchParams,
            function (results) {
                callbackSuccess(results, true);
            },
            function () {
                // Remote search failed, try doing a local search...
                self.localSearch(
                    searchParams,
                    function (results) {
                        callbackSuccess(results, false);
                    }
                );
            }
        );
    },

    localSearch: function localSearch(searchParams, callbackSuccess) {
        /**
         * Search our local cache of orders. Currently we only support searching
         * for a full bookingRef or an itemInstanceRef, and consequently only ever zero or one results will be
         * returned.
         */
        let bookingRef = searchParams.search.toUpperCase();
        let order = this.localGet(bookingRef);
        let result = (order !== null) ? [order] : [];
        callbackSuccess(result);
    },

    remoteSearch: function remoteSearch(searchParams, callbackSuccess, callbackError) {
        let requestParams = {};

        if (searchParams.hasOwnProperty('search') && searchParams.search !== '') {
            requestParams.q = searchParams.search;
        }

        if (searchParams.hasOwnProperty('notes') && searchParams.notes !== '') {
            requestParams.notes = searchParams.notes;
            // See https://digitickets.atlassian.net/browse/PRO-1099 & https://digitickets.atlassian.net/browse/BAC-3542
            // Until we introduce a performant search for the orders.notes field we can optimise with the following
            // workaround that ensures that the least worst query is executed by the API.
            requestParams.orderBy = 'orderID';
            requestParams.cancelled = 1;
        }

        if (searchParams.hasOwnProperty('customerAccountID') && searchParams.customerAccountID) {
            requestParams.customerAccountID = searchParams.customerAccountID;
        }

        if (isValidDateObject(searchParams.startDate)) {
            requestParams.dateStart = searchParams.startDate.toYMD();
            requestParams.dateEnd = searchParams.startDate.toYMD(); // Default end date

            // Change to end date if the field is valid & specified
            if (isValidDateObject(searchParams.endDate)) {
                requestParams.dateEnd = searchParams.endDate.toYMD();
            }
            // Format the date
            requestParams.dateEnd = this.formatEndDateString(requestParams.dateEnd);

            // Change the date parameter keys based on dateType
            requestParams = this.mutateDates(searchParams.dateType, requestParams);
        }

        // If there is no criteria, then we will get the default ones so we don't list 1000's of orders
        requestParams = (Object.keys(requestParams).length === 0) ? this.getDefaultParams() : requestParams;

        this.getResource().query(requestParams, function (results) {
            if (requestParams.notes) {
                // If this is a notes search we may be including cancelled orders, so strip them out (see notes above).
                results = results.filter((result) => !result.cancelledAt);
            }
            callbackSuccess(this.getHydratedCollection(results), true);
        }.bind(this), callbackError);
    },

    /**
     * Find a single Order by either its ID or bookingRef
     *
     * @param {number|string} ref - Order ID or Booking Reference
     * @param {function} callbackSuccess
     * @param {function} [callbackError]
     */
    get: function get(ref, callbackSuccess, callbackError) {
        let self = this;

        return this.remoteGet(ref,
            function (result) {
                callbackSuccess(self.getHydratedObject(result));
            },
            function () {
                /**
                 * Remote search failed, see if we this order in the cache.
                 */
                let result = self.localGet(ref);
                if (result !== null) {
                    callbackSuccess(result);
                } else if (typeof callbackError === 'function') {
                    callbackError();
                }
            });
    },

    /**
     * Search the API for a specific order.
     *
     * @param {number|string} ref - Order ID or Booking Reference
     * @param {function} callbackSuccess
     * @param {function} callbackError
     */
    remoteGet: function remoteGet(ref, callbackSuccess, callbackError) {
        // If we cannot find the order within the search query, retrieve it from the server
        return this.getResource().get({ id: ref }, {}, callbackSuccess, callbackError);
    },

    /**
     * Search our local cache for a specific order, by bookingRef.
     *
     * @param {number|string} ref - Order ID or Booking Reference
     * @returns {null|DigiTickets.Order}
     */
    localGet: function localGet(ref) {
        let self = this;

        /**
         * Recursively populate an entity.
         *
         * @param {string} table
         * @param {string} ref
         *
         * @return {object}
         */
        let populateChildrenForEntity = function populateChildrenForEntity(table, ref) {
            // Get the row of values from the table.
            let result = JSON.parse(self.getCache().getItem(self.tableKeyAliases[table] + '.' + ref));

            // If we have one, combine the values with the keys to make an object
            if (result !== null) {
                // Combine keys and values into an object
                result = arrayCombine(self.columnNamesByTable[table], result);

                // Iterate the table's columns and see if it exists in the list of tables and where
                // the value is an integer or an array of integers, recursively populate the entity.
                self.columnNamesByTable[table].forEach(function (column) {
                    if (self.columnNamesByTable.hasOwnProperty(column)) {
                        if (is.aNumber(result[column])) {
                            result[column] = populateChildrenForEntity(column, result[column]);
                        } else if (is.anArray(result[column]) && is.aNumber(result[column][0])) {
                            result[column] = result[column].map(function (ref) {
                                return populateChildrenForEntity(column, ref);
                            });
                        }
                    }
                });
            }

            return result;
        };

        /**
         * Search our local cache of orders. Currently we only support searching
         * for a full bookingRef and itemInstanceRef
         */
        let order = populateChildrenForEntity('orders', ref.toUpperCase());

        if (!order) {
            let indexPage = 1;
            do {
                var indexes = JSON.parse(this.getCache().getItem('indexes.' + indexPage++));
                if (indexes) {
                    if (indexes.itemInstance.hasOwnProperty(ref.toUpperCase())) {
                        order = populateChildrenForEntity('orders', indexes.itemInstance[ref.toUpperCase()]);
                        if (order) {
                            break;
                        }
                    }
                }
            } while (indexes);
        }

        if (order) {
            order = this.getHydratedObject(order);
        }

        return order;
    },

    /**
     * @param {int} pageNumber
     * @return {Promise}
     */
    downloadOrdersForOffline: function downloadOrdersForOffline(pageNumber) {
        /**
         * On initialisation try to load all orders, purchased less than a year ago, which have tickets valid
         * for today (unredeemed open tickets, or session-based tickets for today).
         *
         * The results are then saved in local storage so that we can still access them if connectivity is lost
         * during the operator's session.
         *
         * The mappers below are executed in a particular sequence to optimize the amount of data transmitted.
         * The order is important for the reversing of the mappings, so please do NOT alter them without altering
         * the reverse mapping process also.
         *
         * The crappy quoted keys are just because Angular 1.3 doesn't
         * encode objects -> parameters in the standard jQuery / PHP way.
         */

        let deferred = this.$q.defer();
        let pageSize = 100;

        if (pageNumber === 0) {
            pageNumber = 1;
        }

        if (pageNumber === 1) {
            this.getCache().clear();
        }

        // Fetch every order made in the last year (up to 100) starting from the given page number.
        this.getResource().get({
            ticketsValidFor: 'today',
            dateStart: '1 year ago',
            'mappers[0][keyedBy]': 'bookingRef',
            'mappers[1][normalise]': true,
            'mappers[2][toTable]': true,
            'mappers[3][encodeEachResult]': 'toJSON',
            limit: pageSize,
            page: pageNumber,
            orderBy: 'orderID',
            orderDirection: 'DESC'
        },
        /**
         * @param {object} rawResults.data
         * @param {object} rawResults.relations
         * @param rawResults
         * @param {object} rawResults.columns
         */
        function (rawResults) {
            // Reverse mapping of this set of data should result in an entry in localStorage
            // for each row of data.
            // OrderCache.[tableAlias].data.[key] = 'json values only'
            // OrderCache.[tableAlias].columns = 'json columns only'
            // The table aliases are defined as a new table occurs in a download.
            if (rawResults.hasOwnProperty('data') && rawResults.hasOwnProperty('relations') && rawResults.hasOwnProperty('columns')) {
                try {
                    var self = this;
                    let indexes = {
                        itemInstance: {}
                    };

                    // Save the column names for each table (from the 'toTable' mapper).
                    Object.keys(rawResults.columns).forEach(function (table) {
                        if (rawResults.columns.hasOwnProperty(table)) {
                            // Replace 'data' with 'orders'.
                            let aliasedTable = table === 'data' ? 'orders' : table;

                            // Get (and maybe set) the table alias.
                            // This is needed as 30% of the stored data would end up just as keys.
                            if (!self.tableKeyAliases.hasOwnProperty(aliasedTable)) {
                                // If an alias is not already defined for this table name use the next available
                                // letter of the alphabet (self.tableKeyAliases)
                                self.tableKeyAliases[aliasedTable] = self.aliasKeys.substr(Object.keys(self.tableKeyAliases).length, 1);
                            }
                            self.getCache().setItem(self.tableKeyAliases[aliasedTable] + '.columns', rawResults.columns[table]);

                            // Hold onto the table structure to save reading it from localStorage.
                            self.columnNamesByTable[aliasedTable] = JSON.parse(rawResults.columns[table]);
                        }
                    });

                    // Save the relation data in the response (from the 'normalise' mapper).
                    // [table].[id] = 'json values'
                    Object.keys(rawResults.relations).forEach(function (tableName) {
                        if (rawResults.relations.hasOwnProperty(tableName)) {
                            Object.keys(rawResults.relations[tableName]).forEach(function (id) {
                                self.getCache().setItem(self.tableKeyAliases[tableName] + '.' + id, rawResults.relations[tableName][id]);
                            });
                        }
                    });

                    // Save the table aliases.
                    self.getCache().setItem('aliases', JSON.stringify(this.tableKeyAliases));

                    // Save the actual order data, keyed by booking ref.
                    // orders.[bookingRef] = 'json values'
                    Object.keys(rawResults.data).forEach(function (bookingRef) {
                        if (rawResults.data.hasOwnProperty(bookingRef)) {
                            self.getCache().setItem(self.tableKeyAliases.orders + '.' + bookingRef, rawResults.data[bookingRef]);
                        }

                        // Build indexes for this batch of orders.
                        // itemInstancesRef => bookingRef
                        let order = self.localGet(bookingRef);
                        order.items.forEach(function (item) {
                            item.iteminstances.forEach(function (itemInstance) {
                                if (is.aString(itemInstance.itemInstanceRef)) {
                                    indexes.itemInstance[itemInstance.itemInstanceRef] = bookingRef;
                                }
                            });
                        });
                    });

                    // Save the indexes.
                    self.getCache().setItem('indexes.' + pageNumber, JSON.stringify(indexes));
                } catch (e) {
                    self.Logger.error('There was a problem processing the order data', e.stack);
                    deferred.reject('There was a problem processing the order data.<br />Error : ' + e.toString());
                }
            }

            // We need to tell the promise if we need to get another page.
            if (rawResults.hasOwnProperty('data') && Object.keys(rawResults.data).length === pageSize) {
                deferred.resolve({
                    nextPageNumber: 1 + pageNumber,
                    downloaded: pageNumber * pageSize
                });
            } else {
                deferred.resolve({
                    nextPageNumber: 0,
                    downloaded: ((pageNumber - 1) * pageSize) + (rawResults.hasOwnProperty('data') ? Object.keys(rawResults.data).length : 0)
                });
            }
        }.bind(this),
        function () {
            deferred.reject('There was a problem connecting to the server');
        });

        return deferred.promise;
    },

    getSearchedBarcode: function getSearchedBarcode() {
        return this.searchedBarcode;
    },

    clearSearchedBarcode: function clearSearchedBarcode() {
        this.searchedBarcode = null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderManager;
}
