/**
 * @param $scope
 * @param $modalInstance
 * @param $timeout
 * @param {CartService} cartService
 */
const OrderNotesCtrl = function OrderNotesCtrl(
    $scope,
    $modalInstance,
    $timeout,
    cartService
) {
    $scope.cart = cartService;
    $scope.notes = $scope.cart.notes;
    $scope.focus = false;

    $scope.ok = function ok() {
        $scope.cart.notes = $scope.notes;
        $modalInstance.close();
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * Method to set the flag that says "focus on the notes field in the modal"
     * for a short time. The ngFocusMe directive and the attribute on the field
     * combine to set focus. We have to set it back to false just after so that
     * we can trigger it again if necessary.
     */
    $scope.setFocus = function setFocus() {
        $scope.focus = true;
        $timeout(function () {
            $scope.focus = false;
        });
    };

    $scope.setFocus();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderNotesCtrl;
}
