const SaleValidationError = require('./SaleValidationError');
const { containsItemRequiringDelivery } = require('./cartContents');

/**
 * Perform all necessary validation of a cart required before going to pay.
 * This is not necessarily validating the items in the cart, but all requirements for making a sale e.g.:
 * - Is the device online
 * - Is the necessary customer info entered
 *
 * @param $rootScope
 * @param $timeout
 * @param ConnectivityChecker
 * @param {CurrentDevice} CurrentDevice
 * @param {FieldValidator} fieldValidator
 * @param {MembershipValidator} membershipValidator
 * @param {UserService} UserService
 */
const SaleValidator = function (
    $rootScope,
    $timeout,
    ConnectivityChecker,
    CurrentDevice,
    fieldValidator,
    membershipValidator,
    UserService
) {
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.connectivityChecker = ConnectivityChecker;
    this.currentDevice = CurrentDevice;
    this.fieldValidator = fieldValidator;
    this.membershipValidator = membershipValidator;
    this.userService = UserService;
};

SaleValidator.prototype = {

    /**
     * Returns a promise which resolves if we can proceed to the payment screen, or rejects with an error
     * code otherwise.
     *
     * @param {CartService} cart
     *
     * @return {Promise<boolean|string>}
     */
    async validate(cart) {
        let validators = [
            this.checkIfCartEmpty,
            this.checkIfStaffRefRequired,
            this.checkDeliveryAddressSelectedIfGiftVouchers,
            this.checkAccountSelectedIfMemberships,
            this.checkForDuplicateMembershipRefs,
            this.checkMembershipsAreValid,
            this.checkGiftAidIsAnswered,
            this.checkGiftAidCustomerInfoIsEntered,
            this.checkCustomFieldsValid,
            this.checkCustomFieldsHaveBeenPresented,
            this.checkConnectivity,
        ];

        for (let i = 0; i < validators.length; i++) {
            // Go through all the specified validators can call them. They should either return true
            // or throw string from SaleValidationError.
            // We can await them all - if it's an async function great. If not it doesn't matter.
            // TODO: Fix no-await-in-loop
            await validators[i].bind(this)(cart);
        }

        return true;
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkIfCartEmpty(cart) {
        if (cart.isEmpty()) {
            throw new SaleValidationError(SaleValidationError.CART_EMPTY);
        }

        return true;
    },

    /**
     * @return {Promise<boolean>}
     */
    async checkConnectivity() {
        if (!this.currentDevice.device.forceOrdersOnline) {
            return true;
        }

        this.$timeout(() => {
            this.$rootScope.setRootProcessingMessage('SELL.PROCESSING_CHECK_CONNECTIVITY');
        });

        return this.connectivityChecker.check()
            .then((isOnline) => {
                if (isOnline) {
                    return true;
                }
                throw new SaleValidationError(SaleValidationError.DEVICE_OFFLINE);
            })
            .catch(() => {
                throw new SaleValidationError(SaleValidationError.DEVICE_OFFLINE);
            })
            .finally(() => {
                this.$timeout(() => {
                    this.$rootScope.setRootProcessingMessage(null);
                });
            });
    },

    /**
     * @param {CartService|Cart} cart
     * @return {boolean}
     */
    checkIfStaffRefRequired(cart) {
        // Ensure a cart/order reference is entered if there are food items in the cart
        // and the branch requires that in their kitchen config.
        if (!cart.containsKitchenItems() || !this.userService.currentBranch.kitchenRefRequired) {
            return true;
        }

        if (cart.staffRef) {
            // Cart ref is required and entered.
            return true;
        }

        // No reference entered.
        throw new SaleValidationError(SaleValidationError.STAFF_REF_REQUIRED);
    },

    /**
     * Returns true of there is an item in the cart that *must* be delivered.
     * The function is duplicated as a method so it can be easily mocked.
     *
     * @param {DigiTickets.CartItem[]} cartLines
     *
     * @returns boolean
     */
    containsItemRequiringDelivery(cartLines) {
        return containsItemRequiringDelivery(cartLines);
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkDeliveryAddressSelectedIfGiftVouchers(cart) {
        // Validate gift vouchers before continuing
        const requiresDeliveryAddress = this.containsItemRequiringDelivery(cart.getItems());
        const deliveryAddress = cart.customer.deliveryAddress;
        if (requiresDeliveryAddress && deliveryAddress.isEmpty()) {
            throw new SaleValidationError(SaleValidationError.DELIVERY_ADDRESS_REQUIRED);
        }

        return true;
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkAccountSelectedIfMemberships(cart) {
        // Ensure we still have a customer account selected if there are memberships in the cart
        // (Operator would have been required to have one selected when adding the membership to the cart,
        // but may have de-selected it since then.)
        if (cart.containsMemberships() && !cart.customer.hasAccount()) {
            throw new SaleValidationError(SaleValidationError.CUSTOMER_ACCOUNT_REQUIRED);
        }

        return true;
    },

    /**
     * Check the membership validation does not fail for any of the datasets in the cart.
     *
     * @param {CartService} cart
     * @return {boolean}
     */
    checkMembershipsAreValid(cart) {
        // Ensure that any memberships actually have valid member data attached.
        if (this.membershipValidator.cartItemDatasetsAreValid(cart.getItems()) !== true) {
            throw new SaleValidationError(SaleValidationError.MISSING_MEMBERSHIP_DATA);
        }

        return true;
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkCustomFieldsValid(cart) {
        // New style field validation. Commented out until we switch to using the cart/instance fields.
        let fieldsValid = this.fieldValidator.validateCartFields(cart.currentCart);
        if (!fieldsValid) {
            throw (new SaleValidationError(SaleValidationError.FIELD_DATA_INVALID));
        }

        return true;
    },

    /**
     * Has the operator been presented with and had the opportunity to complete each custom field?
     *
     * @param {CartService} cart
     *
     * @return {boolean}
     */
    checkCustomFieldsHaveBeenPresented(cart) {
        let haveAllCustomFieldsBeenPresented = cart.haveAllCustomFieldsBeenPresented();
        console.debug('Have all current cart fields been presented?', haveAllCustomFieldsBeenPresented);

        // TODO: Discussed in comments of PRO-777. If we add the ability to always display the custom fields
        // upon checkout this should throw the error to trigger the custom fields modal even if
        // haveAllCustomFieldsBeenPresented is null (meaning it was never opened).
        // For now if it was never opened we don't automatically show it.
        if (haveAllCustomFieldsBeenPresented === null) {
            return true;
        }

        if (haveAllCustomFieldsBeenPresented === false) {
            throw (new SaleValidationError(SaleValidationError.ADDITIONAL_CUSTOM_FIELDS_NOT_PRESENTED));
        }

        return true;
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkForDuplicateMembershipRefs(cart) {
        // Validate any membership refs in the cart. We can't do a lot, apart from make
        // sure there are no duplicates in the cart.
        let membershipRefErrors = this.membershipValidator.checkForDuplicateRefs(cart.getMembershipDatasets());
        if (membershipRefErrors.length > 0) {
            throw (new SaleValidationError(SaleValidationError.DUPLICATE_MEMBERSHIP_REFS))
                .setClientMessages(membershipRefErrors);
        }

        return true;
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkGiftAidIsAnswered(cart) {
        if (cart.giftAidEnabled() !== null) {
            // The Gift Aid question has already been answered for the Cart.
            return true;
        }

        if (cart.getCancellingMemberships().length > 0) {
            // This is a cancellation refund so we don't want to prompt for Gift Aid.
            return true;
        }

        // Check for any Gift Aid-able items in the cart.
        /** @type {DigiTickets.CartItem[]} */
        let giftAidItems = cart.getItems().filter((cartItem) => cartItem.item.giftAid);

        if (giftAidItems.length < 1) {
            return true;
        }

        // Else there are Gift Aid-able items in the cart but the Gift Aid question has not been asked.
        throw (new SaleValidationError(SaleValidationError.GIFT_AID_UNANSWERED))
            .setCartLines(giftAidItems);
    },

    /**
     * @param {CartService} cart
     * @return {boolean}
     */
    checkGiftAidCustomerInfoIsEntered(cart) {
        if (!cart.giftAid) {
            // Gift Aid not enabled so data doesn't matter.
            return true;
        }

        if (!cart.customer.hasGiftAidData()) {
            throw new SaleValidationError(SaleValidationError.GIFT_AID_CUSTOMER_INFO_REQUIRED);
        }

        return true;
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SaleValidator;
}
