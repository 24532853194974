const apiErrorMessage = require('./Api/apiErrorMessage');
const logFactory = require('./Logging/logFactory');

/**
 * @param $q
 * @param $rootScope
 * @param {AgentService} AgentService
 * @param {DigiTickets.AppConfig} AppConfig
 * @param DeviceResource
 * @param {Hydrator} hydrator
 * @param {PrinterAppPrintDriver} printerAppPrintDriver
 * @param {CurrentDevice} CurrentDevice
 * @param {UserService} UserService
 */
const DeviceManager = function (
    $q,
    $rootScope,
    AgentService,
    AppConfig,
    DeviceResource,
    hydrator,
    printerAppPrintDriver,
    CurrentDevice,
    UserService
) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.agentService = AgentService;
    this.appConfig = AppConfig;
    this.deviceResource = DeviceResource;
    this.hydrator = hydrator;
    this.currentDevice = CurrentDevice;
    this.currentUser = UserService;
    this.printerAppPrintDriver = printerAppPrintDriver;

    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('DeviceManager');

    this.devices = [];

    $rootScope.$on('requestUnauthorized', function (event, response) {
        if (response.data.hasOwnProperty('deviceIsValid') && !response.data.deviceIsValid) {
            // The API has told us the current device is no longer valid, so clear it
            this.currentDevice.clearDevice();
        }
    }.bind(this));
};

DeviceManager.prototype = {
    assignDevice: function assignDevice(device) {
        // Assign a device after it have been chosen from the list
        let self = this;
        let result = self.$q.defer();

        this.deviceResource.assign(
            {
                deviceID: device.ID
            },
            {},
            function (response) {
                let device = self.hydrator.hydrate(response.device, new DigiTickets.Device());
                self.currentDevice.setDevice(device, false);
                result.resolve(response);
            },
            function (response) {
                result.reject(response);
            }
        );

        return result.promise;
    },

    refreshDevice: function refreshDevice() {
        // Try to get a fresh copy of the device data from the API then save
        let self = this;
        let deferred = self.$q.defer();

        if (self.currentDevice.isFake()) {
            // If we're using a fake device it won't exist on the API. Just return the same device.
            // Small timeout so resolving the promise happens asynchronously after it has been returned
            // to the caller.
            setTimeout(function () {
                deferred.resolve(self.currentDevice.device);
            }, 1);
        } else if (self.currentDevice.getDeviceGuid()) {
            // Only make the request if we have a cached guid otherwise the request will fail
            self.deviceResource.refresh(
                {
                    // The deviceGuid that has been stored in the cache
                    // The API will test against this rather than the cookie
                    deviceGuid: self.currentDevice.getDeviceGuid()
                },
                {},
                function (response) {
                    // Update our current device with the new info
                    let device = self.hydrator.hydrate(response.device, new DigiTickets.Device());
                    self.currentDevice.setDevice(device, false);
                    deferred.resolve(self.currentDevice.device);
                },
                function (response) {
                    if (response.status >= 400 && response.status <= 499) {
                        // If the response was says the request for the device was 'bad', then clear it
                        self.currentDevice.clearDevice();
                    }

                    deferred.resolve(self.currentDevice.device);
                }
            );
        } else {
            // We won't have a cached guid if this is a new device or the browser cache has been cleared
            self.currentDevice.clearDevice();
            deferred.resolve(self.currentDevice.device);
        }

        return deferred.promise;
    },

    loadDevices: function loadDevices() {
        let self = this;
        let result = self.$q.defer();

        if (!this.devices.length) {
            // If no-one is logged in don't make the request to the devices endpoint
            // (it errors if there is no API key).
            if (this.currentUser.isLoggedIn()) {
                this.deviceResource.queryUnassigned(function (devices) {
                    self.devices = self.hydrator.hydrateArray(devices, () => new DigiTickets.Device());
                    result.resolve(self.devices);
                });
            } else {
                result.resolve(self.devices);
            }
        } else {
            result.resolve(self.devices);
        }

        return result.promise;
    },

    sendDeviceInfo() {
        let deviceID = this.currentDevice.getDeviceId();
        if (!deviceID) {
            throw new Error('No deviceID to send info for.');
        }

        this.getDeviceInfo()
            .then((deviceInfo) => {
                console.log('Sending device info', deviceInfo);
                this.deviceResource.setDeviceInfo(
                    {
                        deviceID
                    },
                    deviceInfo
                );
            });
    },

    /**
     * Returns device info supplied by the browser.
     *
     * @return {object}
     */
    getDefaultDeviceInfo() {
        let systemTimezoneName = null;
        try {
            systemTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
            // Just in case the above is not supported.
        }

        return {
            systemTime: (new Date()).toISOString(),
            systemTimezoneName,
            eposVersion: this.appConfig.eposCommit,
            userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : null,
            screenWidth: typeof window !== 'undefined' ? window.screen.width : null,
            screenHeight: typeof window !== 'undefined' ? window.screen.height : null,
            viewportWidth: typeof window !== 'undefined' ? window.innerWidth : null,
            viewportHeight: typeof window !== 'undefined' ? window.innerHeight : null
        };
    },

    /**
     * Returns all device info including that which is supplied by the agent.
     *
     * @return {Promise<{}>}
     */
    getDeviceInfo() {
        let agentInfo = {};
        let printerAppInfo = {};

        let promises = [];

        promises.push(
            this.agentService.getServerInfo()
                .then((response) => {
                    agentInfo = response;
                })
                .catch(() => {
                    console.debug('Couldn\'t get device info from agent.');
                    // Okay, save what we can anyway.
                    // Having this catch resolves any rejections and the promise chain continues as resolved.
                })
        );

        promises.push(
            this.printerAppPrintDriver.getAppInfo()
                .then((response) => {
                    console.log('printerAppPrintDriver response', response);
                    printerAppInfo = response;
                })
                .catch((err) => {
                    console.log('printerAppPrintDriver error', err);
                })
        );

        return Promise.all(promises)
            .then(() => Object.assign(
                {},
                this.getDefaultDeviceInfo(),
                agentInfo,
                printerAppInfo
            ));
    },

    setPaymentTerminalProxyInfo: function setPaymentTerminalProxyInfo(data) {
        // TODO: Remove
        let currentDevice = this.currentDevice.device;
        if (currentDevice && currentDevice.ID) {
            this.deviceResource.setPaymentTerminalProxyInfo(
                {
                    deviceID: this.currentDevice.getDeviceId()
                },
                {
                    info: data
                }
            );
        }
    },

    /**
     * @param {string} guid
     *
     * @return {Promise<DigiTickets.Device>}
     */
    queryDeviceByGuid(guid) {
        return new Promise((resolve, reject) => {
            this.deviceResource.query(
                {
                    guid
                },
                (response) => {
                    if (response && response.length > 0 && response[0].deviceID) {
                        let device = this.hydrator.hydrate(response[0], new DigiTickets.Device());
                        resolve(device);
                    } else {
                        reject();
                    }
                },
                (result) => reject(apiErrorMessage(result))
            );
        });
    },

    /**
     * @return {Promise<DigiTickets.Device|null>}
     */
    async loadCurrentDeviceFromGuidOnDisk() {
        let guidSavedOnDisk;
        let device;

        try {
            // Try and load a device by guid saved on disk.
            guidSavedOnDisk = await this.currentDevice.readDeviceGuidFromDisk();
        } catch (e) {
            // Failed to read guid from disk. Move on.
            return null;
        }

        if (!guidSavedOnDisk) {
            return null;
        }

        // Found something! Send it to the API and check it is still valid.
        this.log.log('Found saved guid on disk.', guidSavedOnDisk);

        try {
            device = await this.queryDeviceByGuid(guidSavedOnDisk);
        } catch (e) {
            return null;
        }

        this.log.log('Found device matching guid on disk. Setting as current device.', device);

        this.currentDevice.setDevice(device, false);

        return device;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DeviceManager;
}
