const _ = require('lodash');
const moment = require('moment');

/**
 * Holds all the sessions for a single event and provides easy methods to access subsets of them
 */
class SessionCalendar {
    /**
     * @param {Session[]} sessions
     * @param {function} [filter] Optional function to filter out event based on some criteria. It's slightly more
     *                            efficient to pass in the filter here rather than pre-filter the sessions array you
     *                            pass in as it avoids iterating over the array multiple times.
     */
    constructor(sessions = [], filter) {
        let dateSessions = {};
        let dayList = [];

        const filteredSessions = (filter instanceof Function) ? sessions.filter(filter) : sessions;

        for (const session of filteredSessions) {
            const year = session.startTimeYear;
            const month = session.startTimeMonth;
            const day = session.startTimeDay;

            dateSessions[year] = dateSessions[year] || {};
            dateSessions[year][month] = dateSessions[year][month] || {};
            dateSessions[year][month][day] = dateSessions[year][month][day] || [];
            dateSessions[year][month][day].push(session);

            if (!dayList.includes(session.startTimeYMD)) {
                dayList.push(session.startTimeYMD);
            }
        }

        /**
         * Every session for the event in an object keyed by year then month then day. The value is then
         * an array of sessions for that date.
         * Note that the months are numbered 0 to 11
         * e.g.
         * 2020
         *     8
         *         1 => [Session, Session]
         *         2 => [Session]
         *         19 => [Session]
         *     10
         *         17 => [Session]
         * 2021
         *     0
         *         1 => [Session]
         *
         * @type {Object<Object<Object<Session[]>>>}
         */
        this.dateSessions = dateSessions;

        /** @type {string[]} In 'YYYY-MM-DD' format */
        this.dayList = dayList.sort();
    }

    /**
     * Returns the first date that has available sessions.
     *
     * @return {Date|null}
     */
    getFirstDate() {
        return this.dayList[0] ? moment(this.dayList[0], 'YYYY-MM-DD').toDate() : null;
    }

    /**
     * Check if any sessions exists in the given year.
     *
     * @param {Date} date Any date in the year to be checked.
     *
     * @return {boolean}
     */
    hasSessionsForYear(date) {
        return _.get(this.dateSessions, date.getFullYear()) !== undefined;
    }

    /**
     * Check if any sessions exists in the given year and month.
     *
     * @param {Date} date Any date in the year and month to be checked.
     *
     * @return {boolean}
     */
    hasSessionsForMonth(date) {
        return _.get(this.dateSessions, [date.getFullYear(), date.getMonth()]) !== undefined;
    }

    /**
     * Check if any sessions exists in the given year, month, and day.
     *
     * @param {Date} date The date to check.
     *
     * @return {boolean}
     */
    hasSessionsForDay(date) {
        // For this one we can reduce duplication by just checking the length of this result:
        return this.getSessionsForDay(date).length > 0;
    }

    /**
     * Return all sessions on the given date.
     *
     * @param {Date} date
     *
     * @return {Session[]}
     */
    getSessionsForDay(date) {
        return _.get(this.dateSessions, [date.getFullYear(), date.getMonth(), date.getDate()], []);
    }

    /**
     * Returns every session in the given month.
     *
     * @param {Date} date
     *
     * @return {Session[]}
     */
    getSessionForMonth(date) {
        let sessions = [];
        const month = _.get(this.dateSessions, [date.getFullYear(), date.getMonth()], {});
        for (let day in month) {
            // noinspection JSUnfilteredForInLoop
            sessions = sessions.concat(month[day]);
        }

        return sessions;
    }

    /**
     * Returns every session in the given month, plus 6 days before and after that month to account for portions of
     * the next and previous months shown in a calendar.
     *
     * @param {Date} date
     */
    getSessionsForMonthWithSurrounding(date) {
        // Start at the first day of the month.
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);

        // Subtract 6 days from the start date.
        startDate.setDate(startDate.getDate() - 6);

        // Fetch up to the 6th of the next month.
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 6);

        let sessions = [];

        while (startDate <= endDate) {
            sessions = sessions.concat(this.getSessionsForDay(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        return sessions;
    }

    /**
     * Find the next available date with some sessions.
     * The given date must be a date that does have sessions or this will not return anything.
     *
     * @param {Date} fromDate
     *
     * @return {Date|null}
     */
    getNextAvailableDate(fromDate) {
        return this.determineNextDate(fromDate, 'next');
    }

    /**
     * Find the previous available date with some sessions.
     * The given date must be a date that does have sessions or this will not return anything.
     *
     * @param {Date} fromDate
     *
     * @return {Date|null}
     */
    getPreviousAvailableDate(fromDate) {
        return this.determineNextDate(fromDate, 'prev');
    }

    /**
     * Used by getNextAvailableDate and getPreviousAvailableDate.
     *
     * @private
     * @param {Date} fromDate
     * @param {('next'|'prev')} direction
     */
    determineNextDate(fromDate, direction) {
        const startIndex = this.dayList.indexOf(fromDate.toYMD());
        if (startIndex === -1) {
            return null;
        }
        const targetIndex = (direction === 'next') ? startIndex + 1 : startIndex - 1;
        const ymd = this.dayList[targetIndex];
        if (ymd === undefined) {
            return null;
        }
        return moment(ymd, 'YYYY-MM-DD').toDate();
    }
}

DigiTickets.SessionCalendar = SessionCalendar;

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionCalendar;
}
