const angular = require('angular');

app.factory(
    'BarcodeScanDetectorService',
    function (
        $modalStack
    ) {
        let options = {};
        this.barcodeScanDetector = new DigiTickets.BarcodeScanDetector(
            options,
            angular,
            $modalStack
        );
        this.barcodeScanDetector.addPattern('barcodeScanned', '^[\\w ]{4,14}$');

        return this.barcodeScanDetector;
    }
);
