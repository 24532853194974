/**
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param {DigiTickets.CartItem} cartItem
 * @param quantity
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 */
const PriceEditorCtrl = function PriceEditorCtrl(
    $filter,
    $modalInstance,
    $scope,
    cartItem,
    quantity,
    NumPadFactory
) {
    $scope.cartItem = cartItem;
    $scope.quantity = quantity;
    $scope.enteredPrice = cartItem.getPrice();
    $scope.numPad = NumPadFactory.create({
        allowNull: true,
        initialValue: null,
        ok: function () {
            $scope.ok();
        },
        cancel: function () {
            $scope.cancel();
        }
    });

    $scope.ok = function ok() {
        // only override the default price if one has been entered
        if ($scope.numPad.getAmount() !== null) {
            $scope.cartItem.customPrice = $scope.numPad.getValueAsFloat();
            $scope.cartItem.setPrice($scope.numPad.getValueAsFloat());
        }
        $modalInstance.close({
            cartItem: $scope.cartItem,
            quantity: $scope.quantity
        });
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.getNewPriceText = function getNewPriceText() {
        return $scope.numPad.getAmount() === null ? '--' : $filter('currencySymbol')($scope.numPad.getValueAsFloat());
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PriceEditorCtrl;
}
