const apiErrorMessage = require('../../Api/apiErrorMessage');
const OrderAdjustmentResponse = require('./OrderAdjustmentResponse');

/**
 * Handles sending the API request to create an order adjustment from a cart.
 *
 * @param {CartToConfirmationStructureMapper} cartToConfirmationStructureMapper
 * @param {Hydrator} hydrator
 * @param {OrderAdjustmentResource} orderAdjustmentResource
 * @param {PaymentService} paymentService
 */
const OrderAdjustmentConfirmer = function (
    cartToConfirmationStructureMapper,
    hydrator,
    orderAdjustmentResource,
    paymentService
) {
    /**
     * @private
     * @type {CartToConfirmationStructureMapper}
     */
    this.cartToConfirmationStructureMapper = cartToConfirmationStructureMapper;

    /**
     * @private
     * @type {Hydrator}
     */
    this.hydrator = hydrator;

    /**
     * @private
     * @type {OrderAdjustmentResource}
     */
    this.orderAdjustmentResource = orderAdjustmentResource;

    /**
     * @private
     * @type {PaymentService}
     */
    this.paymentService = paymentService;
};

OrderAdjustmentConfirmer.prototype = {
    /**
     * @param {CartService} cartService
     *
     * @returns {Promise.<OrderAdjustmentResponse>}
     */
    confirmAdjustment(cartService) {
        let requestData = this.cartToConfirmationStructureMapper.mapOrderAdjustment(cartService);
        let payments = this.paymentService.cleanPayments(cartService.payments);

        let newPayments = payments.filter((p) => !p.paymentID);

        return this.sendAdjustmentCreationRequest(requestData)
            .then((orderAdjustmentResponse) => {
                // Add the originalOrder to the response because it does not come back from the API.
                orderAdjustmentResponse.originalOrder = cartService.currentCart.originalOrder;

                // So that we can display the amount that was just tendered and any change due we want to add
                // the payments that were just taken to the 'adjustmentOrder' which is what we consider the order
                // that was just made.
                if (orderAdjustmentResponse.adjustmentOrder) {
                    orderAdjustmentResponse.adjustmentOrder.setPayments(newPayments);
                }

                if (newPayments.length > 0) {
                    // The adjustment order has been created. We now need to save the payments for it.
                    // Payments for adjustments are stored under the original order ID.
                    return this.paymentService.storePaymentsForOrder(
                        orderAdjustmentResponse.adjustedOrder.ID,
                        newPayments
                    ).then((allPayments) => {
                        orderAdjustmentResponse.adjustedOrder.setPayments(allPayments);
                        return orderAdjustmentResponse;
                    });
                }

                // No new payments so no need to send them to the API.

                // The API does not return payments attached to the 'adjustedOrder'.
                // At this point we know there were no new payments, so just copy the payments from the original order.
                if (orderAdjustmentResponse.originalOrder) {
                    orderAdjustmentResponse.adjustedOrder.payments = orderAdjustmentResponse.originalOrder.payments;
                }

                return orderAdjustmentResponse;
            });
    },

    /**
     * @param {*} requestData
     *
     * @returns {Promise.<OrderAdjustmentResponse>}
     */
    sendAdjustmentCreationRequest(requestData) {
        return new Promise((resolve, reject) => {
            this.orderAdjustmentResource.store(
                {
                    orderID: requestData.adjustedOrderID
                },
                requestData,
                (response) => {
                    if (response && response.success) {
                        let responseObj = this.buildResponseModelFromResponse(response);
                        resolve(responseObj);
                    } else {
                        reject(new Error(response.error || response.message || ''));
                    }
                },
                (result) => {
                    reject(new Error(apiErrorMessage(result)));
                }
            );
        });
    },

    /**
     * @param {object} responseData
     *
     * @return {OrderAdjustmentResponse}
     */
    buildResponseModelFromResponse(responseData) {
        let response = new OrderAdjustmentResponse();
        this.hydrator.hydrate(responseData, response);

        return response;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderAdjustmentConfirmer;
}
