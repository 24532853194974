const moment = require('moment');
const { toNullableBool, toFloat, toInt, toString } = require('../../../../../../functions/transform');

DigiTickets.Worldpay.TransactionResponseRecord = (function () {
    let TransactionResponseRecord = function TransactionResponseRecord() {
        /**
         * POS Entry Mode, card payment entry method.
         * Please note that additional values may be added with new releases of IPC
         *
         * Values:
         * 5 Chip transaction.
         * 2 Stripe transaction.
         * 1 Keyed transaction.
         * 81 Card holder not present.
         * 0 X & Z report and transaction type Cancel
         * 21 Cash transaction.
         * 7 Contactless chip transaction
         * 91 Contactless Stripe transaction
         * 92 Contactless On-Device transaction
         *
         * @type {number}
         */
        this.posEntryMode = null;

        /**
         * Transaction Type.
         * Transaction Type indicates the type of financial transaction,
         * represented by the first two digits of ISO 8583:1987 Processing Code.
         *
         * Values:
         * 0 Sale (Goods and Services) (Includes Sale with Cashback)
         * 3 Cancel
         * 20 Returns
         *
         * @type {number}
         */
        this.transactionType = null;

        /**
         * @see {DigiTickets.Worldpay.TransactionResult}
         *
         * @type {number}
         */
        this.transactionResult = null;

        /**
         * Authorisation Code, holds the value generated by the issuer for an approved transaction.
         * Present only in case of a successful transaction.
         *
         * @type {string}
         */
        this.authCode = null;

        /**
         * EMV Card Data Element Application Primary Account Number (PAN) holds the valid cardholder account number.
         * Masked PAN consists of first 6 digits and last 4 digits: e.g. 492949 XXXXXX 0002
         *
         * @type {string}
         */
        this.pan = null;

        /**
         * EMV Card Data Element Application Label holds the mnemonic associated with the
         * AID according to the ISO/IEC 7816-5.
         *
         * What??
         *
         * @type {string}
         */
        this.label = null;

        /**
         * EMV Card Data Element Application Effective Date which holds date from which the application may be used.
         * The format is DDMMYYYY.
         *
         * @type {string}
         */
        this.effectiveDate = null;

        /**
         * Field 8:
         * Transaction Date contains local date that the transaction was authorised.
         * The format is DDMMYYYY.
         *
         * Field 9:
         * Transaction Time contains local time that the transaction was authorised.
         * The format is HHMMSS.
         *
         * These 2 are combined for this Date field.
         *
         * @type {Date|null}
         */
        this.transactionDate = null;

        /**
         * EMV Card Data Element Cardholder Name indicates cardholder name according to ISO 7813.
         *
         * @type {null}
         */
        this.cardholderName = null;

        /**
         * EMV Card Data Element Cardholder Name Extended indicates the whole cardholder name when greater than
         * characters using the same coding convention as in ISO 7813.
         *
         * @type {null}
         */
        this.cardholderNameExtended = null;

        /**
         * @type {string}
         */
        this.merchantIdentifier = null;

        /**
         * @type {string}
         */
        this.terminalIdentifier = null;

        /**
         * 1 Signature verified
         * 2 Pin verified/ CVM performed on Consumer device in contactless transaction
         * 7 Cardholder not present
         * 8 No CVM
         * 9 Unknown CVM
         *
         * @type {number}
         */
        this.cardVerificationMethod = null;

        /**
         * Start Date, present only in case of a swiped UK Maestro/Solo card transaction.
         *
         * @type {number}
         */
        this.startDate = null;

        /**
         * Total Number of Sale Counts
         *
         * @type {number}
         */
        this.totalSales = null;

        /**
         * Total Number of Refund Counts
         *
         * @type {number}
         */
        this.totalRefunds = null;

        //
        // Total Sale Amount.
        // This amount is returned when X and Z report transaction is performed.
        // Amount is returned in major currency denomination
        //
        // @type {null}
        //
        this.totalSaleAmount = null;

        /**
         * Total Refund Amount.
         * This amount is returned when X and Z report transaction is performed.
         * Amount is returned in major currency denomination
         *
         * @type {null}
         */
        this.totalRefundAmount = null;

        this.eftSequenceNumber = null;

        this.merchantAddress = null;

        this.merchantName = null;

        this.batchNumber = null;

        this.referralTelephoneNumber1 = null;

        this.referralTelephoneNumber2 = null;

        this.paymentGatewayTransactionReference = null;

        /**
         * EMV Card Data Element Application Identifier (AID), which identifies the
         * application as described in ISO/IEC 7816-5.
         *
         * @type {null}
         */
        this.aid = null;

        /**
         * PAN Sequence number or Issue Number. PAN Sequence number in case of an ICC transaction a
         * nd Issue number in case of a Swiped UK Maestro/Solo card transaction.
         *
         * @type {null}
         */
        this.issueNumber = null;

        /**
         * Transaction Status Information (TSI), present only in case of an ICC transaction.
         * Used for debug purpose only.
         *
         * @type {null}
         */
        this.transactionStatus = null;

        /**
         * Terminal Verification Results (TVR), present only in case of an ICC transaction.
         * Used for debug purpose only.
         *
         * @type {null}
         */
        this.terminalVerificationResults = null;

        this.retentionReminder = null;

        this.customerDeclaration = null;

        /**
         * Additional Response Data, the CVV response.
         *
         * @type {null}
         */
        this.additionalResponseData = null;

        this.receiptNumber = null;

        /**
         * Card Expiry Date
         * IPC returns the encrypted expiry date in case of IPP350, iWL250 and Miura PEDs.
         * Any valid MMYY date can be sent to IPC for cancel and pre sales completion transaction type.
         *
         * @type {null}
         */
        this.cardExpiryDate = null;

        /**
         * Total Amount. Amount is returned in configured major/minor currency denomination.
         * Total amount includes Sale Amount, Cash Back Amount (if any), Gratuity Amount (if any)
         * and Pennies Donation Amount (if any)
         * The default format of amount will be in minor currency.
         *
         * @type {null}
         */
        this.totalAmount = null;

        /**
         * Cash Back Amount. Present if cash back amount is entered when requested Cash Back Amount is returned
         * in configured major/minor currency denomination.
         * The default format of amount will be in minor currency.
         *
         * @type {number|null}
         */
        this.cashbackAmount = null;

        /**
         * Gratuity Amount
         * Gratuity Amount is returned in configured major/minor currency denomination.
         * The default format of amount will be in minor currency.
         *
         * @type {null}
         */
        this.gratuityAmount = null;

        /**
         * This field indicates Card type, If card is fuel card it returns 1 else 0.
         *
         * @type {boolean}
         */
        this.fuelCard = false;

        /**
         * Card Issuer Code, this is the 3 digit WPH card issuer code.
         * Should be used to identify the type of the card.
         *
         * @type {null}
         */
        this.cardIssuerCode = null;

        /**
         * Token Reference: This will be 20 characters long and alphanumeric field.
         *
         * @type {null}
         */
        this.tokenReference = null;

        /**
         * Credit/Debit card identification with online/offline indicator will be returned only in Check Card response.
         * Only four values are possible:
         * D|Online
         * C|Online
         * D|Offline
         * C|Offline
         *
         * @type {string}
         */
        this.cardIdentification = null;

        /**
         * Acquirer Name will be returned in this field only in get territory response.
         *
         * @type {string}
         */
        this.acquirerName = null;

        /**
         * Converted currency name for DCC transactions like HKD – HK Dollar.
         *
         * @type {string}
         */
        this.convertedCurrency = null;

        /**
         * Amount converted into accepted currency for DCC transactions.
         *
         * @type {number}
         */
        this.convertedAmount = null;

        /**
         * Currency conversion rate.
         *
         * @type {null}
         */
        this.conversionRate = null;

        /**
         * Pennies Donation Amount: This field will be present only in the response of Pennies Donation transaction.
         * Pennies Donation Amount is returned in major currency.
         *
         * @type {null}
         */
        this.penniesDonationAmount = null;

        /**
         * Total number of offline stored transactions.
         *
         * @type {null}
         */
        this.offlineTransactions = null;

        /**
         * Serial number of connected pinpad.
         *
         * @type {null}
         */
        this.pedSerial = null;

        this.availableOfflineSpendingAmount = null;

        this.flexCashAvailableBalance = null;

        /**
         * Retrieval Reference Number. It is a copy of receipt number.
         *
         * @type {null}
         */
        this.retrievalReferenceNumber = null;

        /**
         * Transaction reference. This field will be present in the response of a transaction if field 1 is present
         * in transaction request.
         *
         * @type {null}
         */
        this.transactionReference = null;

        /**
         * @type {string}
         */
        this.rawResponse = null;
    };

    TransactionResponseRecord.prototype = {
        getHydrationMap() {
            return {
                posEntryMode: {
                    field: 1,
                    transform: toInt
                },
                transactionType: {
                    field: 2,
                    transform: toInt
                },
                transactionResult: {
                    field: 3,
                    transform: toInt
                },
                authCode: {
                    field: 4,
                    transform: toString
                },
                pan: {
                    field: 5,
                    transform: toString
                },
                label: {
                    field: 6,
                    transform: toString
                },
                effectiveDate: {
                    field: 7
                },
                cardholderName: {
                    field: 10
                },
                cardholderNameExtended: {
                    field: 11
                },
                merchantIdentifier: {
                    field: 12
                },
                terminalIdentifier: {
                    field: 13
                },
                cardVerificationMethod: {
                    field: 14
                },
                startDate: {
                    field: 15
                },
                totalSales: {
                    field: 16
                },
                totalRefunds: {
                    field: 17
                },
                totalSaleAmount: {
                    field: 18
                },
                totalRefundAmount: {
                    field: 19
                },
                eftSequenceNumber: {
                    field: 21
                },
                merchantAddress: {
                    field: 22
                },
                merchantName: {
                    field: 23
                },
                batchNumber: {
                    field: 25
                },
                referralTelephoneNumber1: {
                    field: 26
                },
                referralTelephoneNumber2: {
                    field: 27
                },
                paymentGatewayTransactionReference: {
                    field: 28
                },
                aid: {
                    field: 29
                },
                issueNumber: {
                    field: 30
                },
                transactionStatus: {
                    field: 31
                },
                terminalVerificationResults: {
                    field: 32
                },
                retentionReminder: {
                    field: 33
                },
                customerDeclaration: {
                    field: 34
                },
                additionalResponseData: {
                    field: 35
                },
                receiptNumber: {
                    field: 36
                },
                cardExpiryDate: {
                    field: 37
                },
                totalAmount: {
                    field: 38,
                    transform: toFloat
                },
                cashbackAmount: {
                    field: 39,
                    transform: toFloat
                },
                gratuityAmount: {
                    field: 40,
                    transform: toFloat
                },
                fuelCard: {
                    field: 41,
                    transform: toNullableBool
                },
                cardIssuerCode: {
                    field: 60,
                    transform: toInt
                },
                tokenReference: {
                    field: 61
                },
                cardIdentification: {
                    field: 64
                },
                acquirerName: {
                    field: 65
                },
                convertedCurrency: {
                    field: 70
                },
                convertedAmount: {
                    field: 71
                },
                conversionRate: {
                    field: 72
                },
                penniesDonationAmount: {
                    field: 74
                },
                offlineTransactions: {
                    field: 75
                },
                pedSerial: {
                    field: 76
                },
                availableOfflineSpendingAmount: {
                    field: 77
                },
                flexCashAvailableBalance: {
                    field: 78
                },
                retrievalReferenceNumber: {
                    field: 80
                },
                transactionReference: {
                    field: 98
                },
                rawResponse: {}
            };
        },

        afterHydration: function afterHydration(data) {
            // Hydrate transactionDate here so it can be a datetime object with the combined date and time fields.
            if (data[8] && data[9]) {
                let d = moment(data[8] + ' ' + data[9], 'DDMMYYYY HHmmss');
                this.transactionDate = d.toDate();
            }
        },

        /**
         * Create a Payment object from this record.
         *
         * @param {boolean} isRefund
         *
         * @returns {DigiTickets.Payment}
         */
        toPayment: function toPayment(isRefund) {
            let payment = new DigiTickets.Payment();

            // Unlike Verifone, the totalAmount here includes cashback and gratuity. We don't want those in the
            // total so deduct those.
            // IPC should have been configured to return amounts in major currency units (pounds) instead of minor
            // unit (pence).
            let amount = this.totalAmount;
            amount -= this.cashbackAmount;
            amount -= this.gratuityAmount;

            // There seems to be no way to tell if this was a refund from the record itself.
            // So the isRefund parameter is passed in and the value is negated if true.
            if (isRefund) {
                payment.setTendered(-amount);
            } else {
                payment.setTendered(amount);
            }

            payment.setAuthCode(this.authCode);
            payment.setCardTypeRef(this.getCardTypeRef());
            payment.setCashback(this.cashbackAmount);
            payment.setGratuity(this.gratuityAmount);
            payment.setLastDigits(this.pan.substr(-4));
            payment.setStatusDetail(this.rawResponse);
            payment.setThirdPartyID(this.transactionReference);
            payment.setToken(this.tokenReference); // Account on File token.

            return payment;
        },

        /**
         * Map Worldpay card issuer codes to DigiTickets card refs.
         *
         * @return {string|null}
         */
        getCardTypeRef: function getCardTypeRef() {
            let cardIssuerRefs = {
                1: DigiTickets.CardTypeRef.VISA_DEBIT,
                2: DigiTickets.CardTypeRef.VISA_ELECTRON,
                3: DigiTickets.CardTypeRef.VISA_CREDIT,
                4: DigiTickets.CardTypeRef.VISA_CREDIT,
                5: DigiTickets.CardTypeRef.MASTERCARD,
                6: DigiTickets.CardTypeRef.MAESTRO,
                8: DigiTickets.CardTypeRef.JCB,
                9: DigiTickets.CardTypeRef.MAESTRO,
                10: DigiTickets.CardTypeRef.VISA_CREDIT,
                12: DigiTickets.CardTypeRef.AMEX,
                13: DigiTickets.CardTypeRef.DINERS,
                14: DigiTickets.CardTypeRef.LASER,
                16: DigiTickets.CardTypeRef.MASTERCARD,
                20: DigiTickets.CardTypeRef.DISCOVER,
                23: DigiTickets.CardTypeRef.MASTERCARD
            };
            if (this.cardIssuerCode && cardIssuerRefs.hasOwnProperty(this.cardIssuerCode)) {
                return cardIssuerRefs[this.cardIssuerCode];
            }

            return null;
        }
    };

    return TransactionResponseRecord;
}());
