const { toBool, toNullableString } = require('../functions/transform');

/**
 * SellState represents some options that can be applied to the Sell controller,
 * such as limiting what item types are shown, preventing additional items being added, paying instantly,
 * and where the user is taken when they have completed payment.
 *
 * The default values are the normal Sell behaviour - nothing hidden, no redirect when finished.
 */
DigiTickets.SellState = function () {
    /**
     * Only allow these item types to be added to the cart.
     * null = no restriction.
     * An empty array = no types are allowed.
     *
     * @see DigiTickets.ItemType for possible types.
     *
     * @type {string[]|null}
     */
    this.restrictItemTypes = null;

    /**
     * @type {DigiTickets.CustomerAccount|null}
     */
    this.customerAccount = null;

    /**
     * These items will be added to the cart when the sell page is displayed.
     *
     * @type {DigiTickets.CartItem[][]}
     */
    this.initialItems = [];

    /**
     * Automatically show the pay screen?
     * (Only works if items have been added to the cart already.)
     *
     * @type {boolean}
     */
    this.payNow = false;

    /**
     * Prevent any additional items being added to the cart?
     * If this is a string it will be displayed an error with the given string from the language file when the
     * user tries to add items to the cart.
     * You can set this to true to prevent adding items without specifying a message.
     *
     * @type {boolean|string}
     */
    this.preventAdditional = false;

    /**
     * Prevent items being removed from the cart?
     * To enable, set this to a string from the language file that will be shown to the user saying why
     * they cannot remove any items.
     * To prevent removal of individual cart items instead of everything in the cart,
     * call setCanRemove(false) on a CartItem.
     *
     * @type {boolean|string}
     */
    this.preventRemoval = false;

    /**
     * Prevent the qty of any items in the cart being changed?
     * To prevent adjusting the quantity of only individual cart items instead of everything in the cart,
     * call setCanAdjustQuantity(false) on a CartItem.
     *
     * @type {boolean}
     */
    this.preventQtyAdjustment = false;

    /**
     * Direct the user to this path when they cancel the sale,
     * or when they successfully complete the order too if successReturnPath has not been set.
     *
     * @type {string|null}
     */
    this.returnPath = null;
    this.returnPathQuery = {};

    /**
     * Direct the user to this path when they either successfully complete the order.
     * If null this.returnPath will be used instead.
     *
     * @type {string|null}
     */
    this.successReturnPath = null;

    /**
     * Query string parameters to add to the end of the url when redirected after successfully completing an order.
     *
     * @type {{}}
     */
    this.successReturnPathQuery = {};
};

DigiTickets.SellState.prototype = {
    /**
     * @param {DigiTickets.CartItem} cartItem
     * @param {Number} [qty=1]
     */
    addInitialItem: function addInitialItem(cartItem, qty) {
        this.initialItems.push({
            cartItem,
            qty: (qty || 1)
        });
    },

    /**
     * @param {DigiTickets.CartItem[]} cartItems
     */
    addInitialItems: function addInitialItem(cartItems) {
        for (let i = 0; i < cartItems.length; i++) {
            this.addInitialItem(
                cartItems[i],
                cartItems[i].getQuantity()
            );
            // When the items are added to the cart the qty above is set using cartItem.adjustQty
            // so we need the cartItem's qty reset to 0 before that happens.
            cartItems[i].setQuantity(0);
        }
    },

    /**
     * @return {object}
     */
    getHydrationMap() {
        return {
            customerAccount: {
                model: DigiTickets.CustomerAccount
            },
            initialItems: {
                modelCollection: DigiTickets.CartItem
            },
            payNow: toBool,
            preventAdditional: toBool,
            preventQtyAdjustment: toBool,
            preventRemoval: toBool,
            restrictItemTypes: {},
            returnPath: toNullableString,
            returnPathQuery: {},
            successReturnPath: toNullableString,
            successReturnPathQuery: {}
        };
    }
};
