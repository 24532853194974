const apiErrorMessage = require('./Api/apiErrorMessage');

/**
 * @param $q
 * @param $rootScope
 * @param {DigiTickets.CategoryManager} CategoryManager
 * @param {Hydrator} hydrator
 * @param TicketResource
 */
DigiTickets.TicketManager = function (
    $q,
    $rootScope,
    CategoryManager,
    hydrator,
    TicketResource
) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.categoryManager = CategoryManager;
    this.hydrator = hydrator;
    this.ticketResource = TicketResource;

    this.categories = {};
    this.deferred = $q.defer();
    this.tickets = [];
};

DigiTickets.TicketManager.prototype = {

    loadTickets: function loadTickets() {
        let self = this;

        this.ticketResource.query(
            function (tickets) {
                self.tickets = self.hydrator.hydrateArray(tickets, function () {
                    return new DigiTickets.Ticket();
                });

                self.categories = self.categoryManager.extractCategoriesFromItems(self.tickets);

                // For user switching, the Sell controller needs to know when the tickets
                // are available so that it can restore any order that was in progress.
                self.$rootScope.$broadcast('TicketManager.ticketsReady');

                self.deferred.resolve(self.tickets);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return self.deferred.promise;
    },

    getTickets: function getTickets() {
        return this.deferred.promise;
    },

    getCategories: function getCategories() {
        return this.categories;
    },

    /**
     * Method to return the specific ticket.
     *
     * @param ticketId
     * @return DigiTickets.Ticket
     */
    getTicket: function getTicket(ticketId) {
        for (let ticketIndex = 0; ticketIndex < this.tickets.length; ticketIndex++) {
            if (this.tickets[ticketIndex].ID == ticketId) {
                return this.tickets[ticketIndex];
            }
        }
        return null;
    }

};
