const BigNumber = require('bignumber.js');
const CustomerScreenStages = require('@/libraries/DigiTickets/CustomerScreen/CustomerScreenStages');
const PaymentCalculator = require('@/libraries/DigiTickets/Payment/PaymentCalculator');
const PaymentMethodRef = require('@/libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
const { cloneDeep } = require('@/functions/clone');
const { orderContainsGiftVouchers } = require('@/functions/orders');

/**
 * This is a small controller that just controls what shows
 * in the 'change due' pop-up (modal) that shows when a customer has paid.
 *
 * @param $injector
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param additionalMessages
 * @param {CurrentDevice} CurrentDevice
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {DialogService} DialogService
 * @param {Hydrator} hydrator
 * @param {DigiTickets.Order} order
 * @param {?OrderAdjustmentResponse} orderAdjustmentResponse
 * @param {OrderReceiptPrinter} orderReceiptPrinter
 * @param {OrderService} orderService
 * @param {PaymentState} paymentState
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param SoldGiftVoucherResource
 */
const ChangeCtrl = function ChangeCtrl(
    $injector,
    $modalInstance,
    $scope,
    $timeout,
    additionalMessages,
    CurrentDevice,
    CustomerScreenDataService,
    DialogService,
    hydrator,
    order,
    orderAdjustmentResponse,
    orderReceiptPrinter,
    orderService,
    paymentState,
    ReceiptPrinterService,
    SoldGiftVoucherResource
) {
    /**
     * @type {DigiTickets.Order}
     */
    $scope.order = order;

    let amountDue = order.total;
    let amountDueForChange = order.total;
    let amountTendered = PaymentCalculator.getTotalTendered(order.payments);

    if (orderAdjustmentResponse) {
        // If we have edited an order the amountDue we have to take into account the original order if there is an
        // outstanding balance due unpaid payments such as Payment on Account.
        let originalTotal = new BigNumber(orderAdjustmentResponse.originalOrder.total);
        let originalRemainingBalance = new BigNumber(orderAdjustmentResponse.originalOrder.remainingBalance);
        let adjustedTotal = new BigNumber(orderAdjustmentResponse.adjustedOrder.total);

        amountDueForChange = originalTotal.minus(originalRemainingBalance).minus(adjustedTotal).negated().toNumber();
    }

    let amountChange = PaymentCalculator.getChangeDue(amountDueForChange, amountTendered);

    $scope.amountDue = amountDue;
    $scope.amountTendered = amountTendered;
    $scope.amountChange = amountChange;
    $scope.amountCashback = PaymentCalculator.getTotalCashbackAmount(order.payments);
    $scope.amountGratuity = PaymentCalculator.getTotalGratuityAmount(order.payments);
    $scope.changePaymentMethod = PaymentCalculator.getChangePaymentMethod(amountChange, order.payments);

    $scope.device = CurrentDevice.device;
    $scope.additionalMessages = additionalMessages;

    $scope.hasVerifonePayments = false;
    $scope.verifoneDriver = null;
    for (let i = 0; i < order.payments.length; i++) {
        if (order.payments[i].paymentMethod && order.payments[i].paymentMethod.ref === PaymentMethodRef.CARD_VERIFONE_CHIP_AND_PIN) {
            $scope.hasVerifonePayments = true;
            // Inject VerifoneDriver only when required so companies who don't use it don't deal with it.
            $scope.verifoneDriver = $injector.get('VerifoneDriverService');
            break;
        }
    }

    const successMessage = paymentState.pullSuccessMessage();
    if (successMessage) {
        if (!$scope.additionalMessages) {
            $scope.additionalMessages = [];
        }
        $scope.additionalMessages.push({
            type: 'success',
            text: successMessage
        });
    }

    $scope.uiState = {
        isPrinting: false
    };

    /** @type {DigiTickets.SoldGiftVoucher[]} */
    $scope.soldGiftVouchers = [];

    if (orderContainsGiftVouchers(order)) {
        // Fetch the SoldGiftVouchers from this order from the API.
        SoldGiftVoucherResource.query({ orderID: order.ID },
            (response) => {
                $scope.soldGiftVouchers = hydrator.hydrateArray(
                    response,
                    () => new DigiTickets.SoldGiftVoucher()
                );
                // See if we should try to print sold gift vouchers automatically.
                if (CurrentDevice.device.alwaysPrintReceipts()) {
                    $scope.printSoldGiftVouchers();
                }
            });
    }

    $scope.printSoldGiftVouchers = () => {
        ReceiptPrinterService.printGiftVouchers($scope.soldGiftVouchers);
    };

    $scope.emailReceipt = function emailReceipt($event) {
        if (!$($event.target).hasClass('btn-success')) {
            // Receipt hasn't already been sent.
            $scope.sendEmailReceipt();
        } else {
            DialogService.confirm(
                'CHANGE_MODAL.CONFIRM_RESEND_RECEIPT',
                function (confirmed) {
                    if (confirmed === true) {
                        // Receipt has already been sent, but operator says send it again.
                        $scope.sendEmailReceipt();
                    }
                }
            );
        }
    };

    /**
     * Send request to API to send email receipt to the customer.
     */
    $scope.sendEmailReceipt = function sendEmailReceipt() {
        $scope.ensureHaveCustomerEmail(function () {
            orderService.emailReceipt($scope.order);

            // Small timeout so the user sees the button change and they know something happened
            setTimeout(function () {
                $('.change-modal-footer .email-receipt-btn')
                    .removeClass('btn-primary')
                    .addClass('btn-success')
                    .html('<span class="glyphicon glyphicon-ok"></span> Email Receipt');
            }, 200);
        });
    };

    /**
     * Prompts for the customer's email address if we don't already have it then calls the callback.
     *
     * @param callback
     */
    $scope.ensureHaveCustomerEmail = function ensureHaveCustomerEmail(callback) {
        if ($scope.order.customer.hasEmail()) {
            // Already have the email address
            callback();
            return;
        }

        // Don't have the email
        // Show the customer details modal so the user can enter it
        //
        // TODO: showCustomerDetails is defined in SellCtrl. This function was moved from there.
        // Move showCustomerDetails to somewhere generic to avoid this unexpected inheritance.
        // Maybe create a simple "email capture" dialog for this purpose as all we want here is the email address.
        // FIXME: This doesn't work! showCustomerDetails is not defined anywhere accessible in this scope.
        let errorMsg = "Please enter the customer's email address to send a receipt.";
        $scope.showCustomerDetails(errorMsg).then((result) => {
            if (result === null) {
                return;
            }

            // Clone the customer data because if it stays as a reference
            // it might change before the task is run
            let clonedResult = cloneDeep(result);

            $scope.order.customer = clonedResult.customer;

            // Update the order with the new customer info
            // (Even if there's not an email added, the data may have changed)
            orderService.updateOrder($scope.order, {
                customer: clonedResult.customer,
                customerAccountID: clonedResult.customer.account ? clonedResult.customer.account.ID : null
            });

            if ($scope.order.customer.hasEmail()) {
                callback();
            }
        });
    };

    $scope.printReceipt = function printReceipt() {
        $scope.uiState.isPrinting = true;

        orderReceiptPrinter.printOrderReceipt($scope.order, orderAdjustmentResponse)
            .finally(() => {
                $scope.uiState.isPrinting = false;
            });
    };

    /**
     * Can we print tickets for this order?
     * This controls whether or not we show the 'Print Individual Tickets' and 'Print Group Tickets' buttons.
     *
     * @returns {boolean}
     */
    $scope.canPrintTickets = () => ReceiptPrinterService.canPrintTicketsForOrder($scope.order);

    $scope.printIndividualTickets = function printIndividualTickets() {
        $scope.uiState.isPrinting = true;

        ReceiptPrinterService.printIndividualTickets(
            $scope.order,
            function () {
                $scope.uiState.isPrinting = false;
            },
            CurrentDevice.device.printTickets // Does the device have a souvenir (Boca) printer attached?
        );
    };

    $scope.printGroupTickets = function printGroupTickets() {
        $scope.uiState.isPrinting = true;

        ReceiptPrinterService.printGroupTickets(
            $scope.order,
            function () {
                $scope.uiState.isPrinting = false;
            },
            CurrentDevice.device.printTickets // Does the device have a souvenir (Boca) printer attached?
        );
    };

    $scope.ok = function ok() {
        CustomerScreenDataService.setStage(CustomerScreenStages.WAIT);

        // Close the ChangeCtrl.
        $modalInstance.close();
    };

    // Auto print tickets if enabled.
    $timeout(() => {
        if ($scope.canPrintTickets() && CurrentDevice.device.autoPrintTicketsOnSale) {
            console.log('[ChangeCtrl]', 'Auto printing ticket(s)...');
            $scope.printIndividualTickets();
        }
    }, 10);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ChangeCtrl;
}
