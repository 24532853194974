const logFactory = require('./Logging/logFactory');

/**
 * This class represents a single TCP socket connection.
 * The actual TCP connection is happening between the ProPoint Agent and the whatever host is specified.
 * The AgentService connects a WebSocket to that agent and forwards information
 * the agent sends back about this socket to this class.
 *
 * @param {string} name
 * @param {string} host
 * @param {number} port
 * @param {AgentService} agentService
 */
const TcpSocketConnection = function (
    name,
    host,
    port,
    agentService
) {
    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('TcpSocket ' + name);

    /**
     * Is the connection established?
     * true = connected
     * false = not connected
     *
     * @type {boolean}
     */
    this.connected = false;

    /**
     * @type {Promise|null}
     */
    this.connectingPromise = null;

    /**
     * @type {AgentService}
     * @private
     */
    this.agentService = agentService;

    /**
     * A name to identify this socket in debugging logs.
     *
     * @type {string}
     */
    this.name = name;

    /**
     * IP / hostname of the IP socket to connect to.
     *
     * @type {string}
     */
    this.host = host;

    /**
     * Port of the IP socket to connect to.
     *
     * @type {number}
     */
    this.port = port;

    /**
     * Callback when the WebSocket begins connecting to this TCP socket.
     *
     * @type {?Function}
     */
    this.onConnecting = null;

    /**
     * Callback when this TCP socket is connected.
     *
     * @type {?Function}
     */
    this.onConnected = null;

    /**
     * Callback when this TCP socket connection is closed.
     *
     * @type {?Function}
     */
    this.onClose = null;

    /**
     * Callback when an error message is received from the TCP socket.
     *
     * @type {?Function}
     */
    this.onError = null;

    /**
     * Callback when data is received from the TCP socket.
     *
     * @type {?Function}
     */
    this.onData = null;
};

TcpSocketConnection.prototype = {
    /**
     * Connect to the TCP socket (via the ProPoint Agent).
     *
     * @return {Promise}
     */
    connect() {
        if (this.connected) {
            this.log.debug('Already connected.');
            return Promise.resolve();
        }

        if (this.connectingPromise) {
            this.log.debug('Connection already in progress.');
            return this.connectingPromise;
        }

        this.connectingPromise = this.agentService._connectTcpSocket(this);

        this.connectingPromise.finally(() => {
            this.connectingPromise = null;
        });

        return this.connectingPromise;
    },

    /**
     * Send some data over the TCP socket (via the WebSocket).
     *
     * @param {string} dataString
     * @param {function} [callback]
     */
    sendData: function sendData(dataString, callback) {
        this.connect().then(() => {
            this.agentService._sendData(
                this,
                dataString,
                callback
            );
        });
    },

    /**
     * The socket controller tells us data was received for this socket.
     * This should only be called by the AgentService.
     *
     * @param {object} data
     */
    _didReceiveData: function _didReceiveData(data) {
        if (typeof this.onData === 'function' && data.data) {
            this.onData(data.data, this);
        }
    },

    /**
     * The socket controller tells us an error happened this socket.
     * This should only be called by the AgentService.
     *
     * @param {object} data
     */
    _didError: function _didError(data) {
        if (typeof this.onError === 'function' && data.error) {
            this.onError(data.error, this);
        }
    },

    /**
     * The socket controller tells us the TCP socket was disconnected.
     * This should only be called by the AgentService.
     */
    _didClose: function _didClose() {
        this.log.debug('Disconnected');
        this.connected = false;

        if (typeof this.onClose === 'function') {
            this.onClose(this);
        }
    },

    /**
     * The socket controller tells us the TCP socket is connecting.
     * This should only be called by the AgentService.
     */
    _isConnecting: function _isConnecting() {
        this.connected = false;

        if (typeof this.onConnecting === 'function') {
            this.onConnecting(this);
        }
    },

    /**
     * The socket controller tells us the TCP socket is connected.
     * This should only be called by the AgentService.
     */
    _didConnect: function _didConnect() {
        this.log.debug('Connected');
        this.connected = true;

        if (typeof this.onConnected === 'function') {
            this.onConnected(this);
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = TcpSocketConnection;
}
