/**
 * @param $resource
 * @param ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 * @param SoldGiftVoucherCache
 */
const SoldGiftVoucherResource = function ($resource, ApiRequestParams, AppConfig, SoldGiftVoucherCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'soldgiftvouchers/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                resolve: () => 'giftvoucher,recipient'
            }
        ),
        {
            query: {
                cache: SoldGiftVoucherCache,
                isArray: true
            },
            findByRef: {
                method: 'GET',
                isArray: true
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SoldGiftVoucherResource;
}
