const { removeNullValues } = require('../../../functions/functions');

/**
 * @param {CartLineSplitter} cartLineSplitter
 * @param {CurrentDevice} CurrentDevice
 * @param {UserService} UserService
 */
const CartToReservationStructureMapper = function (
    cartLineSplitter,
    CurrentDevice,
    UserService
) {
    /**
     * @type {CartLineSplitter}
     */
    this.cartLineSplitter = cartLineSplitter;

    /**
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;

    /**
     * @type {UserService}
     */
    this.userService = UserService;
};

CartToReservationStructureMapper.prototype = {
    /**
     * Convert a cart to the structure required for the reservations API endpoint.
     *
     * @param {CartService} cart
     * @param {boolean} [splitDiscounts=false]
     */
    map(cart, splitDiscounts = true) {
        let reservationDate = new Date();

        return {
            customerAccountID: cart.customer.account ? cart.customer.account.ID : null,
            // FIXME: We should send dates in ISO-8601. Check the API supports this.
            date: reservationDate.toYMDHMS(),
            deviceID: this.currentDevice.isSet() ? this.currentDevice.getDeviceId() : null,
            existingOrderID: cart.originalOrder ? cart.originalOrder.ID : null,
            lines: this.mapLines(cart, splitDiscounts),
            // This membershipID is here because this 'reservation structure' is also sent to the API to calculate
            // offers, not just to make a reservation. So it is needed to unlock member-only discounts.
            membershipID: cart.customer.membership ? cart.customer.membership.ID : null
        };
    },

    /**
     * @param {CartService} cart
     * @param {boolean} [splitDiscounts=true]
     *
     * @return {object}
     */
    mapLines(cart, splitDiscounts = true) {
        let items = cart.exportItems();

        if (splitDiscounts) {
            let splitResult = this.cartLineSplitter.splitDiscounts(items, cart.fieldData);
            items = splitResult.items;
        }

        return this.convertCartItemsToReservationLines(items, !splitDiscounts);
    },

    /**
     * Returns an object containing all they cart items, keyed by the item's lineNumber.
     *
     * @param {DigiTickets.CartItem[]} items
     * @param {boolean} [useLineNumber=false] Use the line numbers of the cart items as the keys in the returned object?
     *
     * @return {Object<Object>}
     */
    convertCartItemsToReservationLines(items, useLineNumber = false) {
        let lines = {};
        for (let i = 0; i < items.length; i++) {
            let key = useLineNumber ? items[i].lineNumber : i;
            lines[key] = this.convertCartItemToReservationLine(items[i]);
        }

        return lines;
    },

    /**
     * @param {DigiTickets.CartItem} item
     *
     * @return {object}
     */
    convertCartItemToReservationLine(item) {
        let discount = item.getFirstActiveDiscount();

        let line = {
            baseTotal: item.getBaseTotal(),
            branchID: this.userService.getBranchID(),
            discountAmount: item.discountAmount,
            discountedBaseTotal: item.getDiscountedBaseTotal(),
            discountPercentage: item.discountPercentage,
            donation: item.getDonation(),
            extraData: this.getExtraDataForCartItemReservation(item),
            giftaid: item.giftAid ? 1 : 0,
            itemID: item.item.ID,
            itemType: item.getItemType(),
            lineNumber: item.lineNumber,
            lineTotal: item.getLineTotal(),
            membershipSubscriptionApportionedAmounts: item.membershipSubscriptionApportionedAmounts,
            offerID: discount ? discount.offerID : null,
            // TODO: We should send ordersItemsID but only on lines that have not been changed at all, otherwise the
            // API will delete and recreate them.
            // ordersItemsID: item.ordersItemsID,
            price: item.getPrice(),
            qty: item.getQuantity(),
            returnReasonID: item.returnReason.reasonID,
            returnToStock: item.returnReason.returnToStock,
            sessionID: item.session ? item.session.ID : null,
            signature: item.getId(),
            soldGiftVoucherData: item.soldGiftVoucherData ? item.soldGiftVoucherData.toServerData() : null
        };

        removeNullValues(line);

        return line;
    },

    /**
     * @param {DigiTickets.CartItem} item
     *
     * @returns {object}
     */
    getExtraDataForCartItemReservation(item) {
        let extraData = {};

        let discountNarrative = item.getVariableDiscountNarrative();

        if (discountNarrative) {
            extraData.discountNarrative = discountNarrative;
        }

        return extraData;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartToReservationStructureMapper;
}
