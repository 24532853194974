/**
 * @param {CountryManager} CountryManager
 */
const addressFormDirective = function (CountryManager) {
    return {
        scope: {
            address: '='
        },
        restrict: 'E',
        replace: true,
        templateUrl: 'partials/directives/dt-address-form.html',
        link: function link($scope, element, attrs, ctrl, transclude) {
            $scope.countries = CountryManager.getCountries();
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = addressFormDirective;
}
