const WebcamService = function () {
    // Video & canvas DOM objects
    this.video = null;
    this.canvas = null;

    // Target width & height of photo image
    this.width = 600;
    this.height = 450;
    this.ratio = this.width / this.ratio;

    // Video is streaming?
    this.streaming = false;

    // Whether video capture is supported (ie. probably not iOS)
    this.supported = false;

    // Initialise as soon as the service is used
    this.initializeCamera();
};

WebcamService.prototype = {

    isSupported: function isSupported() {
        return this.supported;
    },

    takePicture: function takePicture() {
        if (this.video === null) {
            // Camera not initialised yet
            this.initializeCamera();
        }

        if (this.streaming && this.width && this.height) {
            let context = this.canvas.getContext('2d');
            context.drawImage(this.video, 0, 0, this.width, this.height);

            return this.canvas.toDataURL('image/jpeg', 0.65);
        }

        return '';
    },

    getVideoFeed: function getVideoFeed() {
        if (this.video === null) {
            // Camera not initialised yet
            this.initializeCamera();
        }

        return this.video;
    },

    initializeCamera: function initializeCamera() {
        if (this.video !== null) {
            return;
        }
        console.log('Initialising camera');

        let self = this;

        this.video = document.createElement('video');
        this.canvas = document.createElement('canvas');

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            this.supported = true;
        }

        // iOS doesn't currently support getUserMedia, so check for availability first
        if (this.isSupported()) {
            let constraints = {
                video: true,
                audio: false
            };

            let successCallback = function (stream) {
                self.video.srcObject = stream;
                self.video.play();
            };

            let errorCallback = function (error) {
                console.log(error);
            };

            navigator.mediaDevices.getUserMedia(constraints)
                .then(successCallback)
                .catch(errorCallback);
        }

        this.video.addEventListener(
            'canplay',
            function () {
                if (!self.streaming) {
                    // Try to calculate the height with the correct ratios
                    self.height = self.video.videoHeight / (self.video.videoWidth / self.width);

                    if (isNaN(self.height)) {
                        // However some browsers have a bug, in that case we assume 4:3 ratio
                        self.height = self.width / (4 / 3);
                    }

                    self.ratio = self.width / self.height;

                    self.video.setAttribute('width', self.width);
                    self.video.setAttribute('height', self.height);
                    self.canvas.setAttribute('width', self.width);
                    self.canvas.setAttribute('height', self.height);

                    self.streaming = true;
                }
            },
            false
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = WebcamService;
}
