/**
 * Provides the ability to modify the installmentAmount for subscriptions on a membership.
 *
 * @param $filter
 * @param $scope
 * @param $modalInstance
 * @param {CurrencyService} CurrencyService
 * @param {DigiTickets.Membership} membership
 * @param {MembershipService} membershipService
 * @param Notification
 */
const MembershipInstallmentsModalCtrl = function MembershipInstallmentsModalCtrl(
    $filter,
    $scope,
    $modalInstance,
    CurrencyService,
    membership,
    membershipService,
    Notification
) {
    /**
     * @type {string}
     */
    $scope.currencySymbol = CurrencyService.getSymbol();

    /**
     * @type {DigiTickets.Membership}
     */
    $scope.membership = membership;

    /**
     * @type {boolean}
     */
    $scope.saving = false;

    /**
     * @type {MembershipSubscription[]}
     */
    $scope.subscriptions = membership.subscriptions;

    // Set the initial new amounts.
    for (let i = 0; i < $scope.subscriptions.length; i++) {
        $scope.subscriptions[i].newInstallmentAmount = $scope.subscriptions[i].installmentAmount;
    }

    /**
     * Round the amount entered when the user is done typing to prevent things like 0.001
     *
     * @param $event
     * @param subscription
     */
    $scope.onBlur = function onBlur($event, subscription) {
        subscription.newInstallmentAmount = Math.preciseRound(subscription.newInstallmentAmount, 2);
    };

    $scope.ok = function ok() {
        // Validate entered amounts.
        for (let i = 0; i < $scope.subscriptions.length > 0; i++) {
            let newAmount = $scope.subscriptions[i].newInstallmentAmount;
            newAmount = Math.preciseRound(newAmount, 2);
            if (!(parseFloat(newAmount) > 0)) {
                Notification.error(
                    {
                        message: $filter('lang')('MEMBERSHIP_INSTALLMENTS.INVALID_AMOUNT_ERROR')
                    }
                );
                return false;
            }
        }

        $scope.saving = true;
        membershipService.saveNewSubscriptionInstallmentAmounts(
            $scope.subscriptions,
            function (subscriptions, newInstallmentAmounts) {
                $scope.saving = false;
                let success = true;

                // Ensure all the new installmentAmounts are saved.
                for (let i = 0; i < subscriptions.length; i++) {
                    if (subscriptions[i].installmentAmount !== subscriptions[i].newInstallmentAmount) {
                        success = false;
                        break;
                    }
                }

                $modalInstance.close({
                    subscriptions: $scope.subscriptions,
                    success,
                    newInstallmentAmounts
                });
            }
        );
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipInstallmentsModalCtrl;
}
