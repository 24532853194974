/**
 * @param {object} $scope
 * @param $modalInstance
 */
const CheckGiftVoucherModalCtrl = function (
    $scope,
    $modalInstance
) {
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

// export {CheckGiftVoucherModalCtrl};
