const ThemeResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'themes/:id',
        ApiRequestParams.defaults(),
        {}
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ThemeResource;
}
