Math.preciseRound = function preciseRound(value, decimals) {
    return parseFloat((Math.round((value * Math.pow(10, decimals)) + ((value >= 0 ? 1 : -1) * 0.001)) / Math.pow(10, decimals)).toFixed(decimals));
};

Math.sumObject = function sumObject(obj) {
    let total = 0;
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            total += parseFloat(obj[key]);
        }
    }
    return total;
};

Math.negateObjectValues = function negateObjectValues(obj) {
    let newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = -parseFloat(obj[key]);
        }
    }
    return newObj;
};

Math.randomBetween = function randomBetween(min, max) {
    return Math.floor(min + (Math.random() * (1 + max - min)));
};
