/**
 * @param $location
 * @param $rootScope
 * @param {UserService} UserService
 */
const NavigationService = function (
    $location,
    $rootScope,
    UserService
) {
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.userService = UserService;

    this.navVisible = false;
};

NavigationService.prototype = {
    /**
     * @return {string}
     */
    getCurrentPath() {
        return this.$location.path();
    },

    showNav: function showNav() {
        this.navVisible = true;
    },

    hideNav: function hideNav() {
        this.navVisible = false;
    },

    /**
     * Go to the 403 (no permission) page.
     */
    view403: function view403() {
        this.showNav();
        this.$location.path('/403');
    },

    viewEposIndex: function viewEposIndex() {
        this.hideNav();
        this.$location.path('/');
    },

    /**
     * If the customer account refs are back-filled in the DB, ref could be used in the URL instead of ID.
     *
     * @param {number} accountId
     */
    viewAccountDetails: function viewAccountDetails(accountId) {
        this.showNav();
        this.$location.path('/account/' + accountId);
    },

    /**
     * Go to login screen.
     */
    viewLogin: function viewLogin() {
        this.showNav();
        this.$location.path('/login');
    },

    /**
     * Go to device selection.
     */
    viewDevice: function viewDevice() {
        this.showNav();
        this.$location.path('/device');
    },

    /**
     * @param {string} membershipRef
     * @param {string|null} memberRef
     */
    viewMembershipDetails: function viewMembershipDetails(membershipRef, memberRef) {
        this.showNav();
        if (memberRef) {
            this.$location.path('/memberships/' + membershipRef + '/' + memberRef);
        } else {
            this.$location.path('/memberships/' + membershipRef);
        }
    },

    /**
     * Go to the redemption / order search screen.
     */
    viewRedeem: function viewRedeem() {
        this.showNav();
        this.$location.path('/orders');
    },

    /**
     * @param {string} itemInstanceRef
     */
    viewInstanceRedeem: function viewInstanceRedeeem(itemInstanceRef) {
        this.hideNav();
        this.$location.path('redeem/' + itemInstanceRef);
    },

    /**
     * @param {string} bookingRef
     * @param {boolean|null} [wasBarcodeScan]
     */
    viewOrderDetails: function viewOrderDetails(bookingRef, wasBarcodeScan) {
        wasBarcodeScan = !!wasBarcodeScan;
        this.showNav();
        this.$location.path('/orders/' + bookingRef + '/' + wasBarcodeScan);
    },

    /**
     * Go to sell screen.
     */
    viewSell: function viewSell() {
        this.showNav();
        this.$location.path('/sell');
    },

    /**
     * @param {string} requestedPath
     * @return {boolean}
     */
    isNavigationAllowed: function isNavigationAllowed(requestedPath) {
        if (this.$rootScope.appReady) {
            return true;
        }

        let allowedPaths = [
            '',
            '/',
        ];

        if (this.userService.isLoggedIn()) {
            allowedPaths.push('/device');
        } else {
            allowedPaths.push('/login');
        }

        if (allowedPaths.indexOf(requestedPath) === -1) {
            console.log('App is not ready. Preventing navigation to', requestedPath);
            return false;
        }
        console.log('App is not ready. But allowing navigation to allowed path', requestedPath);
        return true;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = NavigationService;
}
