/**
 * This is the data structure returned by the 'order tax summary' API endpoint.
 * It contains a 'line' for each 'tax type' showing the value of items and amount of tax at that rate.
 *
 * @param {object} [data]
 */
const OrderTaxSummary = function (data) {
    /**
     * @type {?string}
     */
    this.taxRegistration = null;

    /**
     * Total amount of tax.
     *
     * @type {number}
     */
    this.total = 0.00;

    /**
     * An array of lines grouped by the taxType.
     *
     * @type {Array<{goodsValue:number, rate:number, amount:number, taxTypeName:string}>}
     */
    this.lines = [];

    /**
     * A non grouped array of lines. Each orderLine should have a corresponding line in this array.
     *
     * @type {Array<{ordersItemsID:number, goodsValue:number, rate:number, amount:number, taxTypeName:string}>}
     */
    this.orderLines = [];

    if (data) {
        for (let key in data) {
            if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
};

OrderTaxSummary.prototype = {
    /**
     * @param {string} name
     *
     * @return {{goodsValue:number, rate:number, amount:number, taxTypeName:string}|undefined}
     */
    getLineByTaxTypeName(name) {
        return this.lines.find((l) => l.taxTypeName === name);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderTaxSummary;
}
