DigiTickets.MembershipRefundAmount = function () {
    /**
     * Membership subscription IDs and respective amounts to refund if all subscriptions are refunded.
     *
     * @type {Object<number>}
     */
    this.all = {};

    /**
     * @type {float}
     */
    this.allTotal = 0.00;

    /**
     * @type {boolean}
     */
    this.calculated = false;

    /**
     * Membership subscription IDs and respective amounts to refund if no subscriptions are refunded.
     * It's silly to calculate for 'none', but it's done for consistency with other refund types.
     *
     * @type {Object<number>}
     */
    this.none = {};

    /**
     * @type {float}
     */
    this.noneTotal = 0.00;

    /**
     * Membership subscription IDs and respective amounts to refund if some subscriptions are refunded.
     *
     * @type {Object<number>}
     */
    this.unused = {};

    /**
     * @type {float}
     */
    this.unusedTotal = 0.00;
};

DigiTickets.MembershipRefundAmount.prototype = {
    getHydrationMap() {
        return {
            all: {},
            none: {},
            unused: {}
        };
    },

    afterHydration: function afterHydration() {
        this.calculated = true;

        this.allTotal = Math.preciseRound(Math.sumObject(this.all), 2);
        this.noneTotal = Math.preciseRound(Math.sumObject(this.none), 2);
        this.unusedTotal = Math.preciseRound(Math.sumObject(this.unused), 2);
    }
};
