const CustomerAccountType = require('../../libraries/DigiTickets/Enums/CustomerAccountType');
const CustomerMarketingPreferenceModalCtrl = require('../../../epos/js/controllers/modals/CustomerMarketingPreferenceModalCtrl');

/**
 * @param $scope
 * @param $modalInstance
 * @param $timeout
 * @param $filter
 * @param CountryManager
 * @param {CustomerAccountService} CustomerAccountService
 * @param {Customer} customer
 * @param {UserService} UserService
 * @param errorMsg
 * @param createAccount
 * @param {MarketingPreferenceAsker} MarketingPreferenceAsker
 * @param {ModalFactory} ModalFactory
 */
const CustomerFormCtrl = function CustomerFormCtrl(
    $scope,
    $modalInstance,
    $timeout,
    $filter,
    CountryManager,
    CustomerAccountService,
    customer,
    UserService,
    errorMsg,
    createAccount,
    MarketingPreferenceAsker,
    ModalFactory
) {
    $scope.focus = false;

    /**
     * @type {?DigiTickets.Company}
     */
    $scope.company = UserService.company;

    /**
     * @type {Customer}
     */
    $scope.customer = customer;

    $scope.createAccountStartsChecked = createAccount;

    // The form only registers itself with the $scope of the controller after the controller has initially run.
    // See http://stackoverflow.com/a/22436630/710630 and http://stackoverflow.com/a/24746842/710630
    $scope.customerForm = {
        createAccount: $scope.createAccountStartsChecked,

        // These 2 properties belong on an account model. We keep them here because we only want to
        // create an account if 'createAccount' is selected. They are copied to an account model when OK is pressed.
        type: CustomerAccountType.PERSONAL,
        organisation: null
    };

    if (errorMsg) {
        $scope.error = {
            exists: true,
            message: errorMsg
        };
    } else {
        $scope.error = {
            exists: false,
            message: ''
        };
    }

    $scope.initialize = function initialize() {
        // Set the customer's country to the company's country by default.
        if (!$scope.customer.address.country && $scope.company.country) {
            $scope.customer.address.country = $scope.company.country;
        }

        $(document).off('keydown', '.customer-details-modal-body');
        // Add listener to submit modal when pressing enter
        $(document).on('keydown', '.customer-details-modal-body', function (e) {
            if (e.which == 13) {
                if ($('textarea:focus').length < 1) {
                    $(this).closest('.modal').find('.ok-button').click();
                }
            }
        });
    };

    $scope.submitting = false;

    /**
     * Close the dialog, and return the changes to the opener.
     *
     * @returns {boolean}
     */
    $scope.ok = function ok() {
        if ($scope.customerForm.createAccount) {
            $scope.customer.account = new DigiTickets.CustomerAccount();
            $scope.customer.account.type = $scope.customerForm.type;
            if ($scope.customerForm.type === CustomerAccountType.BUSINESS) {
                $scope.customer.account.organisation = $scope.customerForm.organisation;
            }
        } else {
            $scope.customer.account = null;
        }

        if ($scope.validate() !== true) {
            return false;
        }

        if ($scope.submitting) {
            return false;
        }

        $scope.askMarketingPreferences()
            .finally(() => $timeout($scope.submitAndClose));
    };

    $scope.askMarketingPreferences = function () {
        return new Promise(async (resolve, reject) => {
            // Collect marketing preferences if an email was entered.
            // This is needed even for non-accounts.
            if (!$scope.customer.contact.email) {
                resolve();
                return;
            }

            if ($scope.customer.contact.marketingPreferences !== null) {
                // Already asked.
                resolve();
                return;
            }

            let marketingQuestions = await MarketingPreferenceAsker.getQuestions();
            if (marketingQuestions.length < 1) {
                resolve();
                return;
            }

            // Ask the marketing questions by opening the CustomerMarketingPreferencesModal.
            // This handles either asking on the customer screen or falling back to the operator answering.
            ModalFactory.open(
                'customer-marketing-preferences',
                CustomerMarketingPreferenceModalCtrl,
                'epos/templates/modals/customer-marketing-preference-modal.html',
                {
                    questions: () => marketingQuestions
                }
            ).then(
                /**
                 * @param {number[]} marketingPreferenceIDs
                 */
                (marketingPreferenceIDs) => {
                    $scope.customer.contact.marketingPreferences = marketingPreferenceIDs;
                    resolve();
                }
            )
                .catch(reject);
        });
    };

    $scope.submitAndClose = function () {
        if ($scope.customerForm.createAccount) {
            $scope.submitting = true;
            CustomerAccountService.save(
                $scope.customer.toServerData(),
                function (response, customerAccount) {
                    if (customerAccount) {
                        $modalInstance.close({
                            customerAccount
                        });
                    } else if (response.error) {
                        $scope.error = {
                            exists: true,
                            message: response.error
                        };
                    }
                    $scope.submitting = false;
                },
                function (response) {
                    $scope.error = {
                        exists: true,
                        message: response.data.error
                    };
                    $scope.submitting = false;
                }
            );
        } else {
            $modalInstance.close({
                customer: $scope.customer
            });
        }
    };

    /**
     * Just close the dialog without returning anything to the opener.
     * (No changes should be saved)
     */
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * Method to set the flag that says "focus on the first field in the modal"
     * for a short time. The ngFocusMe directive and the attribute on the field
     * combine to set focus. We have to set it back to false just after so that
     * we can trigger it again if necessary.
     */
    $scope.setFocus = function setFocus() {
        $scope.focus = true;
        $timeout(function () {
            $scope.focus = false;
        });
    };

    $scope.setFocus();

    $scope.validate = function validate() {
        let valid = true;
        let errors = [];

        // Validate email address if entered
        if (!$scope.customerForm.form.email.$valid) {
            errors.push($filter('lang')('CUSTOMER_DETAILS.EMAIL_INVALID'));
            valid = false;
        }

        // If creating an account, require one piece of contact info
        if ($scope.customerForm.createAccount) {
            // If the company requires titles, and the title is not entered, then generate an error.
            if ($scope.company.shouldCaptureTitles() && !$scope.customer.contact.title) {
                errors.push($filter('lang')('CUSTOMER_DETAILS.REQUIRED_FOR_ACCOUNT', { field: 'CUSTOMER_DETAILS.TITLE' }));
                valid = false;
            }

            // Always first name & last name (in time, OR business name)
            if (!$scope.customer.contact.firstName) {
                errors.push($filter('lang')('CUSTOMER_DETAILS.REQUIRED_FOR_ACCOUNT', { field: 'CUSTOMER_DETAILS.FIRST_NAME' }));
                valid = false;
            }

            if (!$scope.customer.contact.lastName) {
                errors.push($filter('lang')('CUSTOMER_DETAILS.REQUIRED_FOR_ACCOUNT', { field: 'CUSTOMER_DETAILS.LAST_NAME' }));
                valid = false;
            }

            /**
             * At least one of:
             * tel
             * email
             * address (house, street, town)
             */

            if (!$scope.customer.contact.email && !$scope.customer.contact.tel && !($scope.customer.address.house && $scope.customer.address.postcode)) {
                errors.push($filter('lang')('CUSTOMER_DETAILS.REQUIRED_CONTACT_FOR_ACCOUNT'));
                valid = false;
            }

            if ($scope.customer.account && $scope.customer.account.type === 'business' && !$scope.customer.account.organisation) {
                errors.push($filter('lang')('CUSTOMER_DETAILS.ORG_REQUIRED_FOR_BUSINESS'));
                valid = false;
            }
        }

        if (!valid) {
            $scope.error = {
                exists: true,
                message: errors.join(' ')
            };
            return false;
        }

        return true;
    };

    $scope.clearAddress = function clearAddress() {
        $scope.customer.address.clear();
        $('#fieldAddressSearch').val('').focus();
    };

    // Initialize
    $scope.initialize();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerFormCtrl;
}
