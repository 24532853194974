const friendlyFilesizeFilter = function (bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return '?';
    }

    let units = [
        'bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB',
    ];

    let unit = 0;

    while (bytes >= 1024) {
        bytes /= 1024;
        unit++;
    }

    return bytes.toFixed(+precision) + ' ' + units[unit];
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = friendlyFilesizeFilter;
}
