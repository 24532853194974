const BigNumber = require('bignumber.js');
const CartItemDiscount = require('../libraries/DigiTickets/Offers/CartItemDiscount');
const ErrorBag = require('../libraries/DigiTickets/Errors/ErrorBag');
const FieldInstanceFilter = require('../libraries/DigiTickets/CustomFields/FieldInstanceFilter');
const ItemEditableStatus = require('../libraries/DigiTickets/Orders/ItemEditableStatus');
const ItemInstance = require('../libraries/DigiTickets/Orders/ItemInstance');
const mapItemTypeToModel = require('@/lib/Items/mapItemTypeToModel');
const moment = require('moment');
const objectHash = require('object-hash');
const PriceAdjustmentCalculator = require('../libraries/DigiTickets/PriceAdjustmentCalculator');
const Session = require('../libraries/DigiTickets/Sessions/Session');
const VariableDiscountsCollection = require('./VariableDiscountsCollection');
const { cleanCartLine } = require('../libraries/DigiTickets/Cart/cartLineCleaning');
const { cloneShallow } = require('../functions/clone');
const { toBool, toDate, toFloat, toNullableFloat, toInt, toNullableInt, toNullableString } = require('../functions/transform');

/**
 * @param {CartService} cart
 * @param {AbstractItem} item
 */
DigiTickets.CartItem = function (cart, item) {
    /**
     * Be careful - this is a CartService not a Cart!
     * TODO: Remove this coupling between CartItems and the Cart (PRO-972)
     *
     * @type {?CartService}
     */
    this.cart = null;

    /**
     * @type {AbstractItem}
     */
    this.item = item;

    /**
     * @type {?Session}
     */
    this.session = null;

    /**
     * @type {number}
     */
    this.quantity = 0;

    /**
     * @type {ItemInstance[]}
     */
    this.itemInstances = [];

    /**
     * How much of the quantity on this line is discounted?
     *
     * @type {number}
     */
    this.discountedQuantity = 0;

    this.quantityDisplay = this.quantity;

    /**
     * FIXME: Why doesn't this just use a DigiTickets.ReturnReason model?
     *
     * @type {{returnToStock: boolean, reasonID: number}|null}
     */
    this.returnReason = {
        reasonID: null,
        returnToStock: this.item && this.item.itemType !== DigiTickets.ItemType.PRODUCT ? true : null
    };

    /**
     * An optional string to display below the item's name on the cart.
     *
     * @type {?string}
     */
    this.subtitle = null;

    /**
     * An offer that has manually been selected by the operator (previously "discounts")
     * This overrides all other offers for the line.
     *
     * @type {DigiTickets.Offer|null}}
     */
    this.manualOffer = null;

    /**
     * All of the offers/discounts affecting this line.
     *
     * @type {CartItemDiscount[]}
     */
    this.discounts = [];

    /**
     * The price of a single item on this line.
     *
     * @type {number}
     */
    this.price = this.item ? this.item.getPrice() : 0.00;

    /**
     * If the operator entered a custom price for this item remember it here.
     *
     * @type {?Number}
     */
    this.customPrice = null;

    /**
     * Line total before discounts.
     * (this.price * this.quantity)
     *
     * @type {number}
     */
    this.baseTotal = 0;

    /**
     * The total amount of discount for the line (calculated for percentage discounts).
     *
     * @type {number}
     */
    this.discountAmount = 0.00;

    /**
     * The total percentage of discount for the line (calculated for amount discounts).
     *
     * @type {number}
     */
    this.discountPercentage = 0.00;

    /**
     * Line base total after discount is removed.
     * (this.baseTotal - this.discountAmount)
     *
     * @type {number}
     */
    this.discountedBaseTotal = 0;

    /**
     * Line total after discounts and fees.
     * We don't do any booking fees on ProPoint so this will always be the same as this.discountedBaseTotal
     *
     * @type {number}
     */
    this.lineTotal = 0;

    /**
     * Member / address data for membership(s) on this line.
     *
     * @type {DigiTickets.MembershipDataset[]}
     */
    this.membershipDatasets = [];

    /**
     * Gift Aid price in use?
     *
     * @type {boolean}
     */
    this.giftAid = false;

    /**
     * @type {number}
     */
    this.donation = 0.00;

    /**
     * Allow the user to adjust the qty of this line in the cart?
     *
     * @type {boolean}
     */
    this.canAdjustQuantity = true;

    /**
     * Allow the user to remove this line from the cart?
     *
     * @type {boolean}
     */
    this.canRemove = true;

    /**
     * TODO: This is based on old field data. Remove when we stop using that.
     *
     * If this item has 'ask early' custom fields, then we can't aggregate it.
     * But rather than detecting that as part of the 'getID()' method, do it early
     * as the value won't change in this session.
     *
     * @type {?string}
     */
    this.askEarlyID = null;

    /**
     * The line number given to this cart item by the cart.
     *
     * Used to link custom field data to the line, in conjunction with
     * item instances.
     *
     * @type {number}
     */
    this.lineNumber = 0;

    /**
     * Used when refunding a membership.
     * Determines how much of the value of this line is being apportioned to which membership subscriptions.
     * It should be an object in the format:
     * {
     *     instanceNumber: {
     *         membershipSubscriptionId: 123.00,
     *         membershipSubscriptionId: 456.00
     *     }
     * }
     *
     * @type {null|Object<Object<number>>}
     */
    this.membershipSubscriptionApportionedAmounts = null;

    /**
     * This will be populated if this line is a refund for a membership that will be cancelled.
     * The membership will be cancelled by the SellCtrl when the user clicks the pay button.
     *
     * @type {DigiTickets.Membership|null}
     */
    this.cancellingMembership = null;

    /**
     * This will be populated if this line is renewing a membership.
     *
     * @type {DigiTickets.Membership|null}
     */
    this.renewingMembership = null;

    /**
     * @type {Date|null}
     */
    this.renewingMembershipFromDate = null;

    /**
     * @type {DigiTickets.SoldGiftVoucherData|null}
     */
    this.soldGiftVoucherData = null;

    /**
     * Array of discount reasons that aren't really discounts they are adjustments made by specific payment types
     * for example Experience Vouchers with tax on sale
     *
     * @type {string[]}
     */
    this.paymentDiscountReasons = ['gift-voucher'];

    /**
     * If this cart line was built from an existing OrderLine, remember the ID of that orderLine.
     *
     * @type {?Number}
     */
    this.ordersItemsID = null;

    /**
     * Collection of VariableDiscount objects containing amounts and narratives of variable discount offers.
     *
     * @type {?VariableDiscountsCollection}
     */
    this.variableDiscountsCollection = null;

    /**
     * What is the edit state of a line?
     * We can only edit Tickets that have not been redeemed and which have not expired.
     *
     * @type {string}
     */
    this.editableStatus = ItemEditableStatus.IS_EDITABLE;

    /**
     * @type {ErrorBag}
     */
    this.errors = new ErrorBag();

    if (cart) {
        this.setCart(cart);
    }

    this.calculate();
};

DigiTickets.CartItem.prototype = {
    /**
     * @param {Number} adjustQuantityBy
     * @param {Boolean} [removeFromCartIfNewQtyZero=true] Should this item remove itself from cart if all qty removed?
     *
     * @returns {DigiTickets.CartItem}
     */
    adjustQuantity: function adjustQuantity(adjustQuantityBy, removeFromCartIfNewQtyZero = true) {
        let previousQty = this.quantity;

        let newQty = this.quantity + parseInt(adjustQuantityBy, 10);
        if (newQty === 0 && adjustQuantityBy > 0) {
            newQty++; // make the quantity 1 not zero if increasing from minus
            adjustQuantityBy++; // adjust the adjustment by the same ++
        }
        this.setQuantity(newQty);

        // When adjusting the qty for new membership plans (not refunds!), remove extra datasets.
        if (this.quantity >= 0 && adjustQuantityBy < 0 && this.isMembershipPlan()) {
            // We need to remove the appropriate number of memberships from this cart item.
            while (this.membershipDatasets.length > 0 && this.membershipDatasets.length > this.quantity) {
                this.membershipDatasets.pop();
            }
        }
        this.quantityDisplay = this.quantity;

        if (this.cart) {
            // This method can be called from multiple sources including CartService.remove().
            // When called from there we send this removeFromCartIfNewQtyZero as false to prevent an infinite loop
            // of adjusting quantity then removing.
            if (previousQty !== 0 && this.quantity === 0 && removeFromCartIfNewQtyZero !== false) {
                this.cart.remove(this);
            }
            this.cart.itemsChanged();
        }

        return this;
    },

    /**
     * @param {number} instanceNumber
     *
     * @return {?ItemInstance}
     */
    getInstance(instanceNumber) {
        return this.itemInstances.find((i) => i.instance === instanceNumber) || null;
    },

    /**
     * @param {number} instanceNumber
     *
     * @return {?ItemInstance} Returns the removed instance.
     */
    removeInstance(instanceNumber) {
        let instance = this.getInstance(instanceNumber);
        if (instance) {
            let index = this.itemInstances.indexOf(instance);
            if (index !== -1) {
                this.itemInstances.splice(index, 1);
                return instance;
            }
        }

        return null;
    },

    setCanAdjustQuantity: function setCanAdjustQuantity(value) {
        this.canAdjustQuantity = value;
    },

    setCanRemove: function setCanRemove(value) {
        this.canRemove = value;
    },

    validateQuantityChange: function validateQuantityChange() {
        if (!/^-?[1-9]\d{0,2}$/.test(this.quantityDisplay)) {
            return false;
        }
    },

    /**
     * @param {boolean} [calculateCart] Should this calculation trigger a calculation of the cart? Default is true.
     *
     * @return {CartItem}
     */
    calculate: function calculate(calculateCart) {
        cleanCartLine(this);

        let baseTotal = (new BigNumber(this.price)).multipliedBy(this.quantity);
        this.baseTotal = baseTotal.toNumber();

        // Calculate the total amount of discount provided by all discounts on this line.
        let discountAmount = new BigNumber(0);
        let discountedQuantity = new BigNumber(0);

        for (let i = 0; i < this.discounts.length; i++) {
            let discount = this.discounts[i];

            let amount = discount.amount;

            if (discount.isActive()) {
                if (discount.isVariable()) {
                    amount = this.getDiscountAmount(discount);
                    discountedQuantity = new BigNumber(1);
                } else {
                    discountedQuantity = discountedQuantity.plus(discount.qty);
                }
                discountAmount = discountAmount.plus(amount);
            }
        }

        // Set total amount of discount for the whole line.
        this.discountAmount = discountAmount.toNumber();

        this.discountedQuantity = discountedQuantity.toNumber();

        // Calculate total discount percentage for the whole line.
        // This applies to Experience vouchers tax on sale but not tax on use.
        let discountPercentage = new BigNumber(0.00);
        if (discountAmount.isGreaterThan(0)) {
            discountPercentage = discountAmount.multipliedBy(100).dividedBy(baseTotal);
        }
        this.discountPercentage = discountPercentage.toNumber();

        let discountedBaseTotal = baseTotal.minus(discountAmount);
        this.discountedBaseTotal = discountedBaseTotal.toNumber();

        this.lineTotal = this.discountedBaseTotal;

        if (calculateCart !== false && this.cart) {
            this.cart.calculate();
        }

        return this;
    },

    /**
     * @param {CartItemDiscount} itemDiscount
     */
    addDiscount: function addDiscount(itemDiscount) {
        this.discounts.push(itemDiscount);
        this.discounts = [...new Set(this.discounts)];
        this.sortDiscounts();
    },

    /**
     * @param {CartItemDiscount[]} itemDiscounts
     */
    setDiscounts: function setDiscounts(itemDiscounts) {
        this.discounts = itemDiscounts;
        this.sortDiscounts();
    },

    sortDiscounts: function sortDiscounts() {
        this.discounts.sort(
            function (a, b) {
                // Variable discounts should be listed last as they apply after other discounts.
                if (a.isVariable() !== b.isVariable()) {
                    return (a.isVariable()) ? 1 : -1;
                }

                if (a.manual !== b.manual) {
                    return (a.manual) ? -1 : 1;
                }

                if (a.amount > b.amount) {
                    return -1;
                } else if (a.amount < b.amount) {
                    return 1;
                }

                return 0;
            }
        );
    },

    /**
     * @returns {CartItemDiscount[]}
     */
    getDiscounts: function getDiscounts() {
        return this.discounts;
    },

    /**
     * @return {?CartItemDiscount|null}
     */
    getFirstActiveDiscount() {
        return this.discounts.filter((d) => !!d.active)[0] || null;
    },

    /**
     * @param {CartItemDiscount} discount
     *
     * @returns {number}
     */
    getDiscountAmount: function getDiscountAmount(discount) {
        // Check for the existence of an Experience Voucher which is a discount without an offer.
        if (discount.offer && discount.isVariable() && this.variableDiscountsCollection) {
            let variableDiscount = this.variableDiscountsCollection.getVariableDiscount(discount.offerID);

            if (variableDiscount) {
                return variableDiscount.amount;
            }
        }

        return discount.amount;
    },

    /**
     * Return the narrative for a VariableDiscount
     *
     * @returns {string|null}
     */
    getVariableDiscountNarrative: function getVariableDiscountNarrative() {
        let discounts = this.getDiscounts();

        for (let discount of discounts) {
            if (discount.isVariable() && discount.offerID && this.variableDiscountsCollection) {
                let variableDiscount = this.variableDiscountsCollection.getVariableDiscount(discount.offerID);
                if (variableDiscount) {
                    return variableDiscount.narrative;
                }
            }
        }
        return null;
    },

    /**
     * Clear discounts
     */
    clearDiscounts: function clearDiscounts() {
        this.discounts = [];
    },

    /**
     * Clear all active discounts but leave inactive ones.
     */
    clearActiveDiscounts() {
        this.discounts = this.discounts.filter((d) => !d.active);
    },

    /**
     * Clear discounts that are really cost adjustments for example those created by tax on sale Experience Gift
     * vouchers. These need to be specifically removed when the payment modal is cancelled and abandoned.
     */
    clearPaymentDiscounts: function clearPaymentDiscounts() {
        this.discounts = this.discounts.filter((discount) => !this.paymentDiscountReasons.includes(discount.reason));
    },

    /**
     * Set the offer that was chosen by the operator.
     *
     * @param {DigiTickets.Offer|null} offer
     */
    setManualOffer: function setManualOffer(offer) {
        this.manualOffer = offer;
        this.calculate();
    },

    /**
     * @returns {DigiTickets.Offer|null}
     */
    getManualOffer: function getManualOffer() {
        return this.manualOffer;
    },

    decrementQuantity: function decrementQuantity() {
        this.adjustQuantity(-1);
        return this;
    },

    getAvailable: function getAvailable() {
        if (this.session == null) {
            return Infinity;
        }

        return this.session.available;
    },

    /**
     * Create a unique ID for the line.
     * This is used to determine which lines can be merged together by increasing the qty of one rather than adding
     * a new line.
     *
     * @return {string}
     */
    getId: function getId() {
        let components = {
            itemID: this.item.ID,
            price: this.price,
            giftAid: this.giftAid,
            isRefund: this.quantity < 0,
            isPriceAdjusted: this.price !== this.item.getPrice(),
            manualOfferID: this.manualOffer ? this.manualOffer.ID : null,
            sessionID: this.session ? this.session.ID : null,
            // Value of old style ask early field data
            askEarlyID: this.askEarlyID || null,
            // Value of new style ask early field data. Until the user can change the new field data the value
            // will always be null or the same for every line with the same fields so it won't affect anything,
            askEarlyFieldHash: this.getAskEarlyFieldHash(),
            renewingMembershipID: this.renewingMembership ? this.renewingMembership.ID : null,
            cancellingMembershipID: this.cancellingMembership ? this.cancellingMembership.ID : null,
            editableStatus: this.editableStatus
        };

        if (this.getItemType() === DigiTickets.ItemType.GIFT_VOUCHER) {
            // Make this ID unique so nothing gets merged with it.
            components.lineNumber = this.lineNumber;
        }

        return JSON.stringify(components);
    },

    getItem: function getItem() {
        if (this.item !== null) {
            return this.item;
        }
    },

    getItemId: function getItemId() {
        if (this.item !== null) {
            return this.item.ID;
        }
    },

    getItemType: function getItemType() {
        if (this.item !== null) {
            return this.item.itemType;
        }
        return null;
    },

    isGiftVoucher: function isGiftVoucher() {
        return this.getItemType() === DigiTickets.ItemType.GIFT_VOUCHER;
    },

    isMembershipPlan: function isMembershipPlan() {
        return this.getItemType() === DigiTickets.ItemType.MEMBERSHIP_PLAN;
    },

    getCategoryId: function getCategoryId() {
        return this.item && this.item.category ? this.item.category.ID : null;
    },

    /**
     * @return {?DigiTickets.Event}
     */
    getEvent() {
        return this.item && this.item.event ? this.item.event : null;
    },

    /**
     * @return {number|null}
     */
    getEventId() {
        return this.item && this.item.event ? this.item.event.ID : null;
    },

    /**
     * @return {string|null}
     */
    getEventName() {
        return this.item && this.item.event ? this.item.event.name : null;
    },

    /**
     * @return {Session|null}
     */
    getSession() {
        return this.session || null;
    },

    /**
     * @return {number|null}
     */
    getSessionId() {
        return this.session ? this.session.ID : null;
    },

    /**
     * @return {string|null}
     */
    getSessionFullName() {
        return this.session ? this.session.getFullName() : null;
    },

    getName: function getName() {
        if (this.giftAid) {
            return this.item.getName() + ' (Gift Aid)';
        }
        return this.item.getName();
    },

    getSubtitle: function getSubtitle() {
        return this.subtitle;
    },

    setSubtitle: function setSubtitle(subtitle) {
        this.subtitle = subtitle;
    },

    getPrice: function getPrice() {
        return this.price;
    },

    isFree: function isFree() {
        return parseFloat(this.getPrice()) === 0.00;
    },

    getDonation: function getDonation() {
        return this.donation;
    },

    getQuantity: function getQuantity() {
        return this.quantity;
    },

    getBaseTotal: function getBaseTotal() {
        return this.baseTotal;
    },

    getDiscountedBaseTotal: function getDiscountedBaseTotal() {
        return this.discountedBaseTotal;
    },

    getLineTotal: function getLineTotal() {
        return this.lineTotal;
    },

    incrementQuantity: function incrementQuantity() {
        this.adjustQuantity(1);
        return this;
    },

    setPrice: function setPrice(price) {
        this.price = price;
        this.calculate();
        return this;
    },

    /**
     * @private
     *
     * @param {number} newQuantity
     * @param {boolean} updateCart
     *
     * @return {DigiTickets.CartItem}
     */
    setQuantity: function setQuantity(newQuantity, updateCart) {
        this.quantity = newQuantity;
        this.quantityDisplay = newQuantity;

        // Use the clean function to create/remove item instances as necessary and fix field data numbering.
        cleanCartLine(this);

        if (this.quantity > 0) {
            this.returnReason.reasonID = null;
            this.returnReason.returnToStock = null;
        }
        this.calculate();
        if (updateCart !== false && this.cart) {
            this.cart.itemsChanged();
        }

        return this;
    },

    /**
     * Enable Gift Aid for this item. Adjust the price as appropriate.
     */
    enableGiftAid: function enableGiftAid() {
        if (!this.item.giftAid || !this.item.giftAidRate) {
            // Gift Aid not available on this item.
            return false;
        }

        if (this.giftAid) {
            // Gift Aid already enabled.
            return false;
        }

        let originalPrice;
        if (this.customPrice !== null) {
            originalPrice = this.customPrice;
        } else {
            originalPrice = this.item.getPrice();
        }

        const priceAdjustmentCalculator = new PriceAdjustmentCalculator();
        let { newPrice } = priceAdjustmentCalculator.calculateAdjustedPrice(this.item.giftAidRate, Number(originalPrice));

        this.giftAid = true;
        this.setPrice(newPrice);
        this.donation = (new BigNumber(newPrice)).minus(originalPrice).toNumber();

        return true;
    },

    disableGiftAid: function () {
        if (this.giftAid) {
            // We can get back to the original price by subtracting the donation for the current price.
            let originalPrice = (new BigNumber(this.price)).minus(this.donation).toNumber();
            this.setPrice(originalPrice);
            this.donation = 0.00;
            this.giftAid = false;

            return true;
        }

        return false;
    },

    /**
     * Return a shallow copy of this CartItem
     *
     * @return {DigiTickets.CartItem}
     */
    clone: function clone() {
        return cloneShallow(this);
    },

    /**
     * @return {?string}
     */
    getAskEarlyFieldHash() {
        if (!this.itemInstances[0]) {
            return null;
        }

        let askEarlyInstances = this.itemInstances[0].fields.instances
            .filter(FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY);

        if (askEarlyInstances.length < 1) {
            return null;
        }

        let askEarlyValues = askEarlyInstances
            .map((fieldInstance) => ({ ID: fieldInstance.field.ID, value: fieldInstance.getValue() }));

        return objectHash(askEarlyValues);
    },

    generateAskEarlyID: function generateAskEarlyID() {
        // FIXME: This won't work with Cart model because it has no fieldManager.
        if (!this.askEarlyID && this.cart && this.cart.fieldManager) {
            this.askEarlyID = (this.cart.fieldManager.getFieldsForLines('item', [this], true).length > 0 ? 'AE' + (new Date()).valueOf() : null);
        }
    },

    /**
     * @param {CartService} cart
     */
    setCart: function setCart(cart) {
        this.cart = cart;
        this.generateAskEarlyID();
        this.calculate();
        this.cart.itemsChanged();
    },

    /**
     * Set the amount being refunded to membership subscriptions by this line.
     *
     * These amounts are calculated by the 'membership refund amounts' API endpoint
     * (use MembershipService.calculateMembershipRefunds())
     *
     * It should be an object where the keys are membership subscription IDs, and the values
     * are the amount (in currency) that should be apportioned to that membership subscription from the payment(s)
     * for this order.
     * This is only currently used for refunding memberships, so the amounts should all be negative.
     *
     * @param {Object<Object<number>>} amounts
     */
    setMembershipSubscriptionApportionedAmounts: function setMembershipSubscriptionApportionedAmounts(amounts) {
        this.membershipSubscriptionApportionedAmounts = amounts;
    },

    /**
     * If this line is a refund for a membership, that membership should not have been cancelled yet.
     * Setting the membership here will cause the SellCtrl to trigger to actual cancellation of this membership
     * once the refund has been completed.
     *
     * @param {DigiTickets.Membership|null} membership
     */
    setCancellingMembership: function setCancellingMembership(membership) {
        this.cancellingMembership = membership;
        if (membership) {
            this.setSubtitle('Refund for Membership ' + membership.getRef());
        } else {
            this.setSubtitle(null);
        }
    },

    /**
     * @param {DigiTickets.MembershipDataset} membershipDataset
     */
    addMembershipDataset: function addMembershipDataset(membershipDataset) {
        this.membershipDatasets.push(membershipDataset);
        if (this.cart) {
            this.cart._triggerOnChange();
        }
    },

    /**
     * @return {DigiTickets.MembershipDataset[]}
     */
    getMembershipDatasets: function getMembershipDatasets() {
        return this.membershipDatasets;
    },

    /**
     * @param {DigiTickets.MembershipDataset[]} membershipDatasets
     */
    setMembershipDatasets: function setMembershipDatasets(membershipDatasets) {
        this.membershipDatasets = membershipDatasets;
        if (this.cart) {
            this.cart._triggerOnChange();
        }
    },

    /**
     * @param {DigiTickets.Membership|null} membership
     * @param {Date} fromDate
     */
    setRenewingMembership: function setRenewingMembership(membership, fromDate) {
        this.renewingMembership = membership;
        this.renewingMembershipFromDate = fromDate;

        if (membership) {
            let subtitle = 'Renewing Membership ' + membership.getRef();
            if (fromDate) {
                subtitle += ' from ' + moment(fromDate).format('YYYY-MM-DD');
            }

            this.setSubtitle(subtitle);
        } else {
            this.setSubtitle(null);
        }
    },

    /**
     * @param {DigiTickets.SoldGiftVoucherData|null} data
     */
    setSoldGiftVoucherData: function setSoldGiftVoucherData(data) {
        this.soldGiftVoucherData = data;
    },

    /**
     * @param {VariableDiscountsCollection} variableDiscountsCollection
     */
    setVariableDiscountsCollection: function setVariableDiscountsCollection(variableDiscountsCollection) {
        this.variableDiscountsCollection = variableDiscountsCollection;
    },

    getHydrationMap() {
        return {
            askEarlyID: toNullableString,
            baseTotal: toFloat,
            canAdjustQuantity: toBool,
            cancellingMembership: {
                model: DigiTickets.Membership
            },
            canRemove: toBool,
            customPrice: toNullableFloat,
            discountAmount: toFloat,
            discountedBaseTotal: toFloat,
            discountedQuantity: toInt,
            discountPercentage: toFloat,
            discounts: {
                modelCollection: CartItemDiscount
            },
            donation: toFloat,
            errors: ErrorBag,
            giftAid: toBool,
            item: {
                /**
                 * @param {any} value
                 * @param {{}} allValues
                 * @param {DigiTickets.Hydrator} hydrator
                 *
                 * @return {?object}
                 */
                transform(value, allValues, hydrator) {
                    if (!value || !value.itemType) {
                        return null;
                    }
                    let modelClass = mapItemTypeToModel(value.itemType);
                    if (!modelClass) {
                        return null;
                    }

                    return hydrator.hydrate(value, new modelClass());
                }
            },
            itemInstances: {
                modelCollection: ItemInstance
            },
            lineNumber: toInt,
            lineTotal: toFloat,
            manualOffer: {
                model: DigiTickets.Offer
            },
            membershipDatasets: {
                modelCollection: DigiTickets.MembershipDataset
            },
            membershipSubscriptionApportionedAmounts: {},
            ordersItemsID: toNullableInt,
            price: toFloat,
            quantity: toInt,
            renewingMembership: {
                model: DigiTickets.Membership
            },
            renewingMembershipFromDate: toDate,
            returnReason: {},
            session: {
                model: Session
            },
            soldGiftVoucherData: {
                model: DigiTickets.SoldGiftVoucherData
            },
            subtitle: toNullableString,
            variableDiscountsCollection: {
                model: VariableDiscountsCollection
            }
        };
    },

    afterHydration: function () {
        // quantityDisplay isn't imported because it's an implementation detail not really a piece of data about
        // the cart. It should almost always be the same as this.quantity.
        this.quantityDisplay = this.quantity;
    },

    /**
     * @return {boolean}
     */
    isEditable() {
        return this.editableStatus === ItemEditableStatus.IS_EDITABLE;
    },

    /**
     * For compatibility with OrderLine.
     *
     * @return {ItemInstance[]}
     */
    getItemInstances() {
        return this.itemInstances;
    }
};
