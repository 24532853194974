/**
 * A slimmer replacement to angular-bootstrap's pager directed.
 * Created so we can customise the template and functionality and reduce our dependency on that outdated package.
 */
const pagerDirective = function () {
    return {
        restrict: 'E',
        scope: {
            currentPage: '=',
            totalItems: '=',
            pageSize: '=',
            /**
             * An HTMLElement.
             * If specified this will be scrolled to the top when the page changes.
             */
            scrollElement: '='
        },
        replace: true,
        controller: function ($scope) {
            $scope.totalPages = () => ($scope.totalItems ? Math.ceil($scope.totalItems / $scope.pageSize) : 1);
            $scope.isOnFirstPage = () => $scope.currentPage <= 1;
            $scope.isOnLastPage = () => $scope.currentPage >= $scope.totalPages();

            $scope.prevPage = () => {
                if (!$scope.isOnFirstPage()) {
                    $scope.setPage($scope.currentPage - 1);
                }
            };

            $scope.nextPage = () => {
                if (!$scope.isOnLastPage()) {
                    $scope.setPage($scope.currentPage + 1);
                }
            };

            $scope.scrollTimeout = null;
            $scope.setPage = (page) => {
                $scope.currentPage = page;

                /** @type {HTMLElement} scrollEl */
                let scrollEl = $scope.scrollElement;
                if (scrollEl) {
                    clearTimeout($scope.scrollTimeout);
                    $scope.scrollTimeout = setTimeout(() => {
                        scrollEl.scrollTop = 0;
                    }, 100);
                }
            };
        },
        template: function () {
            return '<div class="dt-pager">'
                + '<a class="prev" ng-class="{disabled: isOnFirstPage()}"  ng-click="prevPage()"><i class="far fa-angle-left"></i> {{ "PAGER.PREV" | lang }}</a>'
                + '<div>{{ "PAGER.CURRENT_PAGE" | lang }} {{ currentPage }} / {{ totalPages() }}</div>'
                + '<a class="next" ng-class="{disabled: isOnLastPage()}" ng-click="nextPage()">{{ "PAGER.NEXT" | lang }} <i class="far fa-angle-right"></i> </a>'
                + '</div>';
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = pagerDirective;
}
