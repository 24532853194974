const { toBool, toNullableBool, toDate, toFloat, toInt, toNullableInt, toNullableString, fromJson } = require('../functions/transform');

const Device = function () {
    /**
     * @type {number|null}
     */
    this.ID = null;

    this.name = '';
    this.guid = null;
    this.guidAssigned = '';
    this.defaultBranch = null;
    this.defaultFloat = 0;

    /**
     * @type {number[]}
     */
    this.disabledPaymentMethodIDs = [];

    /**
     * The minimum amount of cash that should be in a till.
     * Not currently used.
     *
     * @type {float}
     */
    this.cashLowLimit = 0;

    /**
     * The maximum amount of cash that should be in a till.
     * Not currently used.
     *
     * @type {float}
     */
    this.cashHighLimit = 0;

    /**
     * The maximum number of cheques or vouchers that should be in the till.
     * Not currently used.
     *
     * @type {number}
     */
    this.paperLimit = 0;

    this.eposLandingPageKey = null;
    this.extendedTradingSessionSummaries = true;

    /**
     * @type {object|null}
     */
    this.paymentTerminalConfig = null;

    /**
     * @type {string|null}
     */
    this.paymentTerminalRef = null;

    this.terminalHasPrinter = true;

    /**
     * @type {boolean}
     */
    this.forceOrdersOnline = false;

    this.currentTradingSessionID = null;
    this.focusOnSearch = false;
    this.eposPrintReceipts = 'always';

    /**
     * Device has a souvenir ticket printer?
     * This affects which template is used when printing tickets.
     *
     * @type {boolean}
     */
    this.printTickets = false;

    /**
     * @type {boolean}
     */
    this.autoPrintTicketsOnRedemption = false;

    /**
     * @type {boolean}
     */
    this.autoPrintTicketsOnSale = false;

    /**
     * @type {DigiTickets.PrintMethod}
     */
    this.printMethod = new DigiTickets.PrintMethod();

    /**
     * JSON encoded printer configuration.
     * This should always be a string not an object. It gets passed to the PrintRouter which will decode it.
     *
     * @type {?string}
     */
    this.printConfig = null;

    /**
     *
     * @type {object|null}
     */
    this.printConfig = null;

    /**
     * Is this a fake device for internal use in the epos code (instead of a real device from the API)?
     *
     * @type {boolean}
     */
    this.isFake = false;

    /**
     * Is there a touch sensitive customer facing screen enabled on this device?
     *
     * @type {boolean}
     */
    this.customerTouchScreenEnabled = false;
};

Device.prototype = {
    getHydrationMap() {
        return {
            autoPrintTicketsOnRedemption: toBool,
            autoPrintTicketsOnSale: toBool,
            cashHighLimit: toFloat,
            cashLowLimit: toFloat,
            currentTradingSessionID: toNullableInt,
            defaultBranch: {
                model: DigiTickets.Branch
            },
            defaultFloat: toFloat,
            disabledPaymentMethodIDs: {},
            eposLandingPageKey: {},
            eposPrintReceipts: toNullableString,
            extendedTradingSessionSummaries: toBool,
            focusOnSearch: toNullableBool,
            forceOrdersOnline: toBool,
            guid: {},
            guidAssigned: {
                field: ['guidChangedAt', 'guidAssigned'],
                transform: toDate
            },
            ID: {
                field: ['deviceID', 'ID'],
                transform: toInt
            },
            name: {},
            paperLimit: toInt,
            paymentTerminalConfig: fromJson,
            paymentTerminalRef: toNullableString,
            printConfig: toNullableString,
            printMethod: {
                model: DigiTickets.PrintMethod
            },
            printTickets: toBool,
            terminalHasPrinter: toBool,
            customerTouchScreenEnabled: toBool
        };
    },

    alwaysPrintReceipts: function () {
        return this.eposPrintReceipts === 'always';
    }
};

DigiTickets.Device = Device;

/* istanbul ignore next */
if (typeof module !== 'undefined') {
    module.exports = Device;
}
