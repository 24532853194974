const ChangeCalculator = require('../../../libraries/DigiTickets/Payment/ChangeCalculator');
const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');

/**
 * This is the controller for the inner part of the payment modal for the payment method of "Voucher".
 *
 * @param $scope
 * @param {CartService} cartService
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 */
const VoucherCtrl = function VoucherCtrl(
    $scope,
    cartService,
    NumPadFactory
) {
    $scope.thisPaymentMethodRef = PaymentMethodRef.VOUCHER;
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    $scope.cart = cartService;

    $scope.calculator = new ChangeCalculator();
    $scope.numPad = NumPadFactory.create(
        {
            showCashButtons: true,
            showSignButton: false
        },
        function (value) {
            // When the number pad value changes update the calculator.
            $scope.calculator.setAmountTendered(value);
        }
    );
    $scope.numPadListening = false;

    $scope.clear = function clear() {
        $scope.calculator.clear();
        $scope.numPad.clear();
    };

    $scope.setAmountDue = function setAmountDue(amount) {
        $scope.calculator.setAmountDue(amount);
        $scope.numPad.setExactAmount(amount);
        $scope.numPad.options.isNegative = amount < 0;
    };

    $scope.setAmountDue($scope.cart.getRemainingBalance());

    $scope.$on('payment-modal.payment-removed', function () {
        $scope.setAmountDue($scope.cart.getRemainingBalance());
    });

    $scope.$on('payment-modal.cancel-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Was cancelled from the cash tab.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        // Changing tab, so reset the calculator.
        $scope.clear();
        $scope.setAmountDue($scope.cart.getRemainingBalance());

        $scope.numPadListening = newPaymentMethodRef === $scope.thisPaymentMethodRef;
    });

    $scope.$on('payment-modal.ok-pressed', function (event, paymentMethodRef) {
        // If this is a cash payment, we need to check that the tendered
        // amount is higher than, or the same as, the amount required.
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Add a payment for the amount we have entered,
            // instead of the PaymentCtrl creating a payment for the entire remaining balance.
            $scope.preventAddFullPaymentOnClose();

            if ($scope.calculator.getAmountTendered() > 0) {
                // For vouchers, we don't want to give any change, and don't want to create an overpayment.
                // So limit the voucher value to the max payment amount.
                let amount = Math.min(
                    $scope.calculator.getAmountTendered(),
                    $scope.cart.getRemainingMaxPayment()
                );

                let payment = new DigiTickets.Payment();
                payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
                payment.setTendered(amount);
                payment.setThirdPartyID($scope.cart.generatePaymentThirdPartyID());
                payment.setChangeGiven(0);
                payment.setValue($scope.calculator.getAmountTendered());
                $scope.addPayment(payment);
            }

            // Clear for next use.
            $scope.clear();
            $scope.setAmountDue($scope.cart.getRemainingBalance());
        }
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VoucherCtrl;
}
