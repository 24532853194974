const CartLineNumberFactory = function () {
    /**
     * The last line number used. The next number will be 1 more than this.
     *
     * @type {number}
     */
    this.lineNumber = 0;
};

CartLineNumberFactory.prototype = {
    next() {
        return ++this.lineNumber;
    },

    reset() {
        this.lineNumber = 0;
    },

    set(value) {
        this.lineNumber = value;
    },

    /**
     * Set the last line number to the given value, if the given value is greater than
     * the last line number.
     *
     * @param {number} value
     */
    setMin(value) {
        if (value > this.lineNumber) {
            this.set(value);
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartLineNumberFactory;
}
