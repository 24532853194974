/**
 * Convert a string to a string of HTML for representing a Code39 barcode.
 *
 * @param {string} barcode The string to be encoded.
 * @param {{orientation?:'w'|'h'}} opts
 */
const BarcodeGenerator = (barcode, opts) => {
    const defaults = {
        orientation: 'h'
    };

    const options = Object.assign({}, defaults, opts);

    const classes = {
        b: 'narrow black',
        B: 'wide black',
        w: 'narrow white',
        W: 'wide white'
    };

    const barPatterns = {
        '*': 'bWbwBwBwb',
        '-': 'bWbwbwBwB',
        $: 'bWbWbWbwb',
        '%': 'bwbWbWbWb',
        ' ': 'bWBwbwBwb',
        '.': 'BWbwbwBwb',
        '/': 'bWbWbwbWb',
        '+': 'bWbwbWbWb',
        0: 'bwbWBwBwb',
        1: 'BwbWbwbwB',
        2: 'bwBWbwbwB',
        3: 'BwBWbwbwb',
        4: 'bwbWBwbwB',
        5: 'BwbWBwbwb',
        6: 'bwBWBwbwb',
        7: 'bwbWbwBwB',
        8: 'BwbWbwBwb',
        9: 'bwBWbwBwb',
        A: 'BwbwbWbwB',
        B: 'bwBwbWbwB',
        C: 'BwBwbWbwb',
        D: 'bwbwBWbwB',
        E: 'BwbwBWbwb',
        F: 'bwBwBWbwb',
        G: 'bwbwbWBwB',
        H: 'BwbwbWBwb',
        I: 'bwBwbWBwb',
        J: 'bwbwBWBwb',
        K: 'BwbwbwbWB',
        L: 'bwBwbwbWB',
        M: 'BwBwbwbWb',
        N: 'bwbwBwbWB',
        O: 'BwbwBwbWb',
        P: 'bwBwBwbWb',
        Q: 'bwbwbwBWB',
        R: 'BwbwbwBWb',
        S: 'bwBwbwBWb',
        T: 'bwbwBwBWb',
        U: 'BWbwbwbwB',
        V: 'bWBwbwbwB',
        W: 'BWBwbwbwb',
        X: 'bWbwBwbwB',
        Y: 'BWbwBwbwb',
        Z: 'bWBwBwbwb'
    };

    const renderBar = (style) => {
        const cssClasses = classes[style];
        if (cssClasses === undefined) {
            throw new Error(`Unknown style [${style}]`);
        }

        return `<bar class="bar${barcode.length} ${cssClasses}"></bar>`;
    };

    const renderBarGroup = (char) => {
        const barPattern = barPatterns[char];
        if (barPattern === undefined) {
            throw new Error(`Illegal code 39 character [${char}]`);
        }

        return barPattern.split('').map(renderBar).join('');
    };

    const star = renderBarGroup('*');
    const whitespace = renderBar('w');

    return `<barcode class="${options.orientation}">`
        + star
        + whitespace
        + barcode.split('').map((char) => renderBarGroup(char) + whitespace).join('')
        + star
        + '</barcode>';
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = BarcodeGenerator;
}
