const VerifoneLoginRequestRecord = function () {
    /**
     * @type {Number}
     */
    this.userId = null;

    /**
     * @type {Number}
     */
    this.userPin = null;

    /**
     * @type {string}
     */
    this.menuOptions = '*';
};

VerifoneLoginRequestRecord.prototype = {
    toArray() {
        return [
            'L2', // 1: Message Type - Indicates an L2 format Login Record
            this.userId, // 2: User ID
            this.userPin, // 3: User PIN
            this.menuOptions, // 4: Menu Options
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneLoginRequestRecord;
}
