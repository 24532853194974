const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

/**
 * @param $resource
 * @param {ApiRequestParams} ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 */
const MarketingPreferenceResource = function (
    $resource,
    ApiRequestParams,
    AppConfig
) {
    return $resource(
        AppConfig.apiBaseUrl + 'marketingpreferences',
        ApiRequestParams.defaults(),
        {
            query: {
                isArray: true,
                method: 'GET',
                params: ApiRequestParams.defaultMappers(),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'ReturnReason')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MarketingPreferenceResource;
}
