/**
 * Note this cannot have DeviceManager as a dependency as it would cause a circular dependency.
 *
 * @param {Hydrator} hydrator
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.OnlineQueue} OnlineQueueService
 * @param TradingSessionResource
 * @param {UserService} UserService
 */
DigiTickets.TradingSessionManager = function (
    hydrator,
    CurrentDevice,
    Logger,
    OnlineQueueService,
    TradingSessionResource,
    UserService
) {
    /**
     * @type {Hydrator}
     */
    this.hydrator = hydrator;

    /**
     * @type {DigiTickets.Logger}
     */
    this.logger = Logger;

    /**
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;

    /**
     * @type {DigiTickets.OnlineQueue}
     */
    this.onlineQueue = OnlineQueueService;

    this.tradingSessionResource = TradingSessionResource;

    /**
     * @type {UserService}
     */
    this.userService = UserService;

    /**
     * @type {DigiTickets.TradingSession|null}
     */
    this.currentTradingSession = null;

    this.onlineQueue.addHandler(
        'saveTradingSessionEntry',
        /**
         * @param {DigiTickets.TradingSessionEvent} data
         * @param callback
         */
        function (data, callback) {
            // TODO: Having device api keys would be much nicer here.
            if (!data.apiKey) {
                // If there's no API key on the event use the current logged in user's.
                if (UserService) {
                    data.apiKey = UserService.getApiKey();
                }
            }
            if (!data.apiKey) {
                // If there's still no API key don't sent the request because we know it will fail.
                Logger.warn('TradingSessionEvent has no apiKey. Not sending to API.', data);
                callback(false);
                return;
            }

            let deviceGuid = CurrentDevice.getDeviceGuid();

            TradingSessionResource.saveEvent(
                {
                    deviceGuid
                },
                data,
                function (response) {
                    callback(true, response);
                }, function (response) {
                    callback(false, response);
                }
            );
        }
    );
};

DigiTickets.TradingSessionManager.prototype = {
    /**
     * Add an event to the trading session event log.
     *
     * @param {DigiTickets.TradingSessionEvent} tradingSessionEvent
     */
    saveEvent: function saveEvent(tradingSessionEvent) {
        tradingSessionEvent.apiKey = tradingSessionEvent.apiKey || this.userService.getApiKey();

        if (!tradingSessionEvent.operatorID) {
            if (!this.userService.getUserID()) {
                return;
            }
            tradingSessionEvent.setOperatorID(this.userService.getUserID());
        }
        this.onlineQueue.addTask('saveTradingSessionEntry', tradingSessionEvent, false, false);
    },

    /**
     * Get the currently active trading session for the device from the API.
     * Callback receives either the TradingSession model,
     * false if there is no open session for the device,
     * or null if there was an error.
     *
     * @param {DigiTickets.Device} device
     * @param {function(DigiTickets.TradingSession|null|boolean)} callback
     */
    getCurrentSession: function getCurrentSession(device, callback) {
        let self = this;
        this.tradingSessionResource.query(
            {
                deviceID: device.ID,
                trading: 1,
                resolve: 'device'
            },
            function (response) {
                if (response.length > 0) {
                    let tradingSession = self.hydrator.hydrate(
                        response[0],
                        new DigiTickets.TradingSession()
                    );
                    callback(tradingSession);
                } else {
                    callback(false);
                }
            },
            function () {
                callback(null);
            }
        );
    },

    /**
     * Check the API for an existing open trading session.
     * If one was found send a resume event.
     * If one was not found open one.
     *
     * @param {DigiTickets.Device} device
     * @param {function(DigiTickets.TradingSession)} callback
     */
    resumeOrOpenSession: function resumeOrOpenSession(device, callback) {
        let self = this;

        this.getCurrentSession(
            device,
            function (currentTradingSession) {
                if (currentTradingSession && currentTradingSession.ID) {
                    // Found an existing trading session - send a resume event.
                    self.logger.info('Resuming trading session', currentTradingSession.ID);

                    // Disabled as it just clogs up the event log. Does this actually get used?
                    // self.saveEvent(
                    //      new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.DEVICE_RESUME)
                    //  );

                    callback(currentTradingSession);
                } else if (currentTradingSession === false) {
                    // No existing session found - start a new one.
                    self.logger.info('No existing trading session');

                    self.openSession(
                        device,
                        function (newTradingSession) {
                            self.logger.info('Started trading session', newTradingSession.ID);

                            if (!newTradingSession) {
                                throw new Error('Failed to start a trading session.');
                            }

                            // Add default float.
                            self.saveEvent(
                                (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.CASH_FLOAT))
                                    .setOperatorID(self.userService.getUserID())
                                    .setAmount(device.defaultFloat)
                            );

                            callback(newTradingSession);
                        }
                    );
                }
                // else - Result of getCurrentSession was null. Possibly offline?
            }
        );
    },

    /**
     * Close then open a trading session.
     * If no open trading session was found, just open a new one.
     *
     * @param {DigiTickets.Device} device
     * @param {function(DigiTickets.TradingSession)} callback
     */
    startNewSession: function startNewSession(device, callback) {
        let self = this;

        // First check for an existing open session.
        this.getCurrentSession(
            device,
            function (tradingSession) {
                if (tradingSession) {
                    // Found an existing trading session.
                    // Close it.
                    self.closeSession(
                        tradingSession,
                        function (closed) {
                            if (closed) {
                                self.openSession(device, callback);
                            } else {
                                throw new Error('Failed to end trading session ' + tradingSession.ID + '.');
                            }
                        }
                    );
                } else {
                    self.openSession(device, callback);
                }
            }
        );
    },

    /**
     * Open a trading session.
     * May return null if there is an error, e.g. if there is already an open session for the device
     *
     * @param {DigiTickets.Device} device
     * @param {function(DigiTickets.TradingSession|null)} callback
     */
    openSession: function openSession(
        device,
        callback
    ) {
        let self = this;

        if (!device || !device.ID) {
            throw new Error('A device must be selected before starting a trading session.');
        }

        // Ask API to start a new trading session.
        // If one already exists it will return a 409 status and the existing session.
        this.tradingSessionResource.save(
            {
                operatorID: this.userService.getUserID(),
                deviceID: device.ID,
                resolve: 'device'
            },
            function (response) {
                let tradingSession = self.hydrator.hydrate(
                    response,
                    new DigiTickets.TradingSession()
                );

                callback(tradingSession);
            },
            function () {
                callback(null);
            }
        );
    },

    /**
     * Close a trading session.
     * May return false if there is an error, e.g. if the session is already closed.
     *
     * @param {DigiTickets.TradingSession} tradingSession
     * @param {function(DigiTickets.TradingSession|boolean)} callback
     */
    closeSession: function closeSession(tradingSession, callback) {
        let self = this;

        this.tradingSessionResource.remove(
            {
                id: tradingSession.ID,
                resolve: 'device'
            },
            function (response) {
                // Response contains the updated trading session object.
                tradingSession = self.hydrator.hydrate(
                    response,
                    new DigiTickets.TradingSession()
                );

                callback(tradingSession);
            },
            function () {
                callback(false);
            }
        );
    },

    /**
     * Get a trading session summary  from the API.
     *
     * @param {DigiTickets.TradingSession} tradingSession
     * @param {function(object)} callback
     */
    getSummary: function getSummary(tradingSession, callback) {
        this.tradingSessionResource.getSummary(
            {
                id: tradingSession.ID
            },
            function (response) {
                callback(response);
            },
            function (response) {
                callback(null);
            }
        );
    }
};
