const config = require('../../js/config');

const DeviceCtrl = require('./controllers/DeviceCtrl');
const EposIndexCtrl = require('./controllers/EposIndexCtrl');
const ManageAccountCtrl = require('./controllers/ManageAccountCtrl');
const ManageCtrl = require('./controllers/ManageCtrl');
const MembershipDetailCtrl = require('./controllers/MembershipDetailCtrl');
const NavigationCtrl = require('./controllers/NavigationCtrl');
const OrdersCtrl = require('./controllers/OrdersCtrl');
const OrderDetailCtrl = require('./controllers/OrderDetailCtrl');
const RedemptionCtrl = require('./controllers/RedemptionCtrl');
const ReportCtrl = require('./controllers/ReportCtrl');
const SellCtrl = require('./controllers/SellCtrl');

const CashCtrl = require('../../js/controllers/modal/PaymentMethods/CashCtrl');
const FieldsCtrl = require('../../js/controllers/modal/FieldsCtrl');
const FlexiVoucherCtrl = require('../../js/controllers/modal/PaymentMethods/FlexiVoucherCtrl');
const GiftVoucherPaymentCtrl = require('./controllers/modals/payment/methods/GiftVoucherPaymentCtrl');
const GoCardlessCtrl = require('../../js/controllers/modal/PaymentMethods/GoCardlessCtrl');
const PaymentExpressCtrl = require('../../js/controllers/modal/PaymentMethods/PaymentExpressCtrl');
const PayPalHereCtrl = require('../../js/controllers/modal/PaymentMethods/PayPalHereCtrl');
const ProductSearchCtrl = require('./controllers/ProductSearchCtrl');
const VerifonePaymentCtrl = require('../../js/controllers/modal/PaymentMethods/VerifonePaymentCtrl');
const VoucherCtrl = require('../../js/controllers/modal/PaymentMethods/VoucherCtrl');
const WorldpayPaymentCtrl = require('../../js/controllers/modal/PaymentMethods/WorldpayPaymentCtrl');

const AgentInfoDirective = require('./directives/AgentInfoDirective');
const CheckGiftVoucherDirective = require('./directives/CheckGiftVoucherDirective');

const SaleValidator = require('../../js/libraries/DigiTickets/Cart/SaleValidator');

const runAppCommon = require('../../js/run-common');
const AppInstanceChecker = require('../../js/libraries/DigiTickets/AppInstanceChecker');
const UserEvents = require('../../js/libraries/DigiTickets/Enums/UserEvents');
const CustomerScreenStages = require('../../js/libraries/DigiTickets/CustomerScreen/CustomerScreenStages');

/**
 * Register epos only controllers.
 */
app.controller('DeviceCtrl', DeviceCtrl);
app.controller('EposIndexCtrl', EposIndexCtrl);
app.controller('ManageAccountCtrl', ManageAccountCtrl);
app.controller('ManageCtrl', ManageCtrl);
app.controller('MembershipDetailCtrl', MembershipDetailCtrl);
app.controller('NavigationCtrl', NavigationCtrl);
app.controller('OrdersCtrl', OrdersCtrl);
app.controller('OrderDetailCtrl', OrderDetailCtrl);
app.controller('RedemptionCtrl', RedemptionCtrl);
app.controller('ReportCtrl', ReportCtrl);
app.controller('SellCtrl', SellCtrl);

// Register controllers that will be used in an ng-controller directive in a template.
app.controller('CashCtrl', CashCtrl);
app.controller('FieldsCtrl', FieldsCtrl);
app.controller('FlexiVoucherCtrl', FlexiVoucherCtrl);
app.controller('GiftVoucherPaymentCtrl', GiftVoucherPaymentCtrl);
app.controller('GoCardlessCtrl', GoCardlessCtrl);
app.controller('PaymentExpressCtrl', PaymentExpressCtrl);
app.controller('PayPalHereCtrl', PayPalHereCtrl);
app.controller('ProductSearchCtrl', ProductSearchCtrl);
app.controller('VerifonePaymentCtrl', VerifonePaymentCtrl);
app.controller('VoucherCtrl', VoucherCtrl);
app.controller('WorldpayPaymentCtrl', WorldpayPaymentCtrl);

/**
 * Register EPOS-only directives.
 *
 * The name used here will become the name you use in HTML to use the directive, but converted to kebab-case.
 * e.g. dtCheckGiftVoucher is used as <dt-check-gift-voucher>
 * These should be prefixed with 'dt' to prevent collisions with any directives in third party packages, or any future
 * HTML elements with the same name.
 */
app.directive('dtAgentInfo', AgentInfoDirective);
app.directive('dtCheckGiftVoucher', CheckGiftVoucherDirective);

/**
 * Register EPOS-only services.
 */
app.service('saleValidator', SaleValidator);

/**
 * Staged building of epos. See Gruntfile.js for sequence.
 */
app.run(
    /**
     * @param $rootScope
     * @param $injector
     * @param $location
     * @param {AgentService} AgentService
     * @param {DigiTickets.AppConfig} AppConfig
     * @param {DeviceManager} deviceManager
     * @param {NavigationService} navigationService
     * @param TradingSessionManager
     * @param {UserService} UserService
     * @param LangService
     * @param {DigiTickets.Logger} Logger
     * @param CustomerScreenDataService
     * @param {CartService} cartService
     * @param {CurrentDevice} CurrentDevice
     * @param {PaymentMethodService} paymentMethodService
     * @param {PrintRouter} PrintRouter
     */
    function (
        $rootScope,
        $injector,
        $location,
        AgentService,
        AppConfig,
        deviceManager,
        navigationService,
        TradingSessionManager,
        UserService,
        LangService,
        Logger,
        CustomerScreenDataService,
        cartService,
        CurrentDevice,
        paymentMethodService,
        PrintRouter
    ) {
        $injector.invoke(runAppCommon);

        Logger.info('EPOS Version ' + AppConfig.eposVersion.toISOString());

        /**
         * Ensure that only one instance of the EPOS screen is running.
         */
        let appInstanceChecker = $injector.instantiate(AppInstanceChecker);
        appInstanceChecker.init(window, 'epos');

        // Attempt to connect to ProPoint agent now.
        AgentService.connectToAgent()
            .catch((err) => {
                // Agent not available. This is probably fine. If something needs it it will complain louder.
                console.warn('ProPoint agent not available.', err);
            });

        /**
         * We log user logins in the trading session. But the device may not be set when the user logs in,
         * because the device selection happens after login.
         * In that case, the recently logged in user is kept here until a device is selected and the login
         * can be logged.
         *
         * FIXME: This should definitely be a user model not the service, once the model exists.
         *
         * @type {UserService|null}
         */
        let loggedInUserPendingLog = null;
        /**
         * @param {User} user
         */
        let logNewUserLogin = function (user) {
            TradingSessionManager.saveEvent(
                (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.USER_LOGIN))
                    .setApiKey(user.apiKey)
                    .setOperatorID(user.ID)
            );
            loggedInUserPendingLog = null;
        };

        // Callback for when the user logs in.
        // Device may not be selected yet.
        $rootScope.$on(UserEvents.USER_LOGGED_IN,
            /**
             * @param event
             * @param {{user: User, fromCache: boolean}} data
             */
            function (event, data) {
                let user = data.user;

                // Inform Sentry of the current user
                Raven.setUserContext({
                    id: user.ID,
                    username: user.username,
                    company: user.company.name
                });

                cartService.setUser(user);
                CustomerScreenDataService.updateState(
                    /**
                     * @param {CustomerScreenState} state
                     */
                    (state) => {
                        state.company = user.company;
                        state.currentBranch = UserService.currentBranch;
                        state.currentUser = UserService.currentUser;
                    }
                );

                /**
                 * Some services are required for the OnlineQueueService and whilst the normal injection should work,
                 * some are not presented early enough.
                 */
                $injector.get('RedemptionManagerService');

                LangService.setLanguageFromBranch(UserService.currentBranch);

                // Initialize the OrderQueue and OrderService.
                $injector.get('orderQueue');
                $injector.get('orderService');

                // If the company has the Verifone payment method, and there is a userId and userPin, login to
                // the terminal now.
                let verifonePaymentMethod = paymentMethodService.getByRef('card-verifone-chip-and-pin');
                if (verifonePaymentMethod) {
                    // It is important that this is not injected before the user is logged in or the config
                    // will not have all the data it needs.

                    /**
                     * @type {VerifoneDriver}
                     */
                    let verifoneDriver = $injector.get('VerifoneDriverService');

                    if (verifoneDriver.config.hasLoginCredentials()) {
                        verifoneDriver.login();
                    }
                }

                if (!data.fromCache) {
                    // This was a new login, not just loading the user from the cache.
                    // We need to log a USER_LOGIN trading session event, but the device might not have been selected.
                    if (CurrentDevice.isSet()) {
                        // Device is already selected, log now.
                        logNewUserLogin(data.user);
                    } else {
                        // Store user in a variable to be logged once the device is set.
                        loggedInUserPendingLog = data.user;
                    }
                }
            });

        $rootScope.$on(UserEvents.BRANCH_CHANGED, function (event, data) {
            CustomerScreenDataService.updateState((state) => {
                state.currentBranch = UserService.currentBranch;
            });
            LangService.setLanguageFromBranch(UserService.currentBranch);
        });

        // Callback for when the user logs out.
        $rootScope.$on(UserEvents.USER_LOGGED_OUT,
            /**
             * @param event
             * @param {{user: User}} data
             */
            function (event, data) {
                let user = data.user;

                // Inform Sentry that they've logged out (by sending empty)
                Raven.setUserContext();

                Logger.info('User "' + user.username + '" logged out.');

                TradingSessionManager.saveEvent(
                    (new DigiTickets.TradingSessionEvent(DigiTickets.TradingSessionEventType.USER_LOGOUT))
                        .setApiKey(user.apiKey)
                        .setOperatorID(user.ID)
                );

                // If the company has the Verifone payment method, log out of the terminal.
                let verifonePaymentMethod = paymentMethodService.getByRef('card-verifone-chip-and-pin');
                if (verifonePaymentMethod) {
                    /**
                     * @type {VerifoneDriver}
                     */
                    let verifoneDriver = $injector.get('VerifoneDriverService');

                    // Cancel any transaction in progress.
                    verifoneDriver.cancelTransaction();

                    if (verifoneDriver.state.loggedIn !== false) {
                        verifoneDriver.logout();
                    }
                }
            });

        $rootScope.$on('DEVICE_SET_EVENT', function (event, data) {
            if (loggedInUserPendingLog) {
                logNewUserLogin(loggedInUserPendingLog);
            }

            // Inform Sentry of the newly chosen device.
            Raven.setExtraContext({
                deviceID: data.device.ID,
                deviceName: data.device.name
            });

            // When a device has been selected set the print configuration for that device.
            PrintRouter.loadFromDevice(data.device);

            // Update the device info.
            deviceManager.sendDeviceInfo();
        });

        $rootScope.$on('$routeChangeStart', function (event, next, current) {
            // Track route changes with Google Analytics
            gtag('config', config.analytics.trackingId, { page_path: next.originalPath });
        });

        /**
         * This checks if the same route is being loaded constantly (ie. redirect loop),
         * if is it the same every time for 3 times then it will prevent the next change.
         *
         * Otherwise it will update the previous set with the new location and start again.
         *
         * @type {{next: string, current: string, count: number}}
         */
        let previousSet = { next: '', current: '', count: 0 };
        $rootScope.$on('$locationChangeStart',
            /**
             * @param {event} ev
             * @param {string} next
             * @param {string} current
             */
            function (ev, next, current) {
                let requestedPath = $location.path();
                if (requestedPath && !navigationService.isNavigationAllowed(requestedPath)) {
                    ev.preventDefault();
                    return;
                }

                Logger.debug(
                    'Switched page from ' + current + ' to ' + next
                );
                if ((previousSet.next == next) && (previousSet.current == current)) {
                    if (previousSet.count == 3) {
                        ev.preventDefault();
                        previousSet.count = 0;
                    }
                    previousSet.count++;
                }

                previousSet.next = next;
                previousSet.current = current;
            });

        // Initialize the user (load from cache).
        UserService.initialize();

        // Initialize the current device (load from cache).
        CurrentDevice.initialize();

        CustomerScreenDataService.setStage(CustomerScreenStages.WAIT);

        // Catch any unauthorized API requests and trigger a logout.
        // It means the API key is no longer valid. The user probably logged in elsewhere.
        $rootScope.$on('requestUnauthorized', function () {
            if (UserService.isLoggedIn()) {
                UserService.logout();
            }
        });

        let initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
            initialLoader.parentNode.removeChild(initialLoader);
        }
    }
);
