const logFactory = require('../../Logging/logFactory');
const PrintJobOptions = require('../../Printing/PrintJobOptions');
const PrintType = require('../../Printing/PrintType');
const VerifoneActionIds = require('./Constants/VerifoneActionIds');
const VerifoneActionsForStatuses = require('./Constants/VerifoneActionsForStatuses');
const VerifoneContinueTransactionRecord = require('./Records/Request/VerifoneContinueTransactionRecord');
const VerifoneLoginRequestRecord = require('./Records/Request/VerifoneLoginRequestRecord');
const VerifoneLoginResponseRecord = require('./Records/Response/VerifoneLoginResponseRecord');
const VerifoneLogoutRequestRecord = require('./Records/Request/VerifoneLogoutRequestRecord');
const VerifoneLogoutResponseRecord = require('./Records/Response/VerifoneLogoutResponseRecord');
const VerifoneReportRequestRecord = require('./Records/Request/VerifoneReportRequestRecord');
const VerifoneSocketNames = require('./Constants/VerifoneSocketNames');
const VerifoneStatusIds = require('./Constants/VerifoneStatusIds');
const VerifoneStatusRecord = require('./Records/Response/VerifoneStatusRecord');
const VerifoneSubmitOfflineTransactionsRecord = require('./Records/Request/VerifoneSubmitOfflineTransactionsRecord');
const VerifoneTransactionRequestRecord = require('./Records/Request/VerifoneTransactionRequestRecord');
const VerifoneTransactionResponseRecord = require('./Records/Response/VerifoneTransactionResponseRecord');
const VerifoneVoucherRecord = require('./Records/Response/VerifoneVoucherRecord');
const VerifoneWinStateRequestRecord = require('./Records/Request/VerifoneWinStateRequestRecord');

/**
 * @param $interval
 * @param $timeout
 * @param {AgentService} AgentService
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {DeviceManager} deviceManager
 * @param {DialogService} DialogService
 * @param {Hydrator} hydrator
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param {ToastFactory} toastFactory
 * @param {UserService} UserService
 * @param {VerifoneConfig} VerifoneConfigService
 */
const VerifoneDriver = function (
    $interval,
    $timeout,
    AgentService,
    ConnectivityChecker,
    deviceManager,
    DialogService,
    hydrator,
    ReceiptPrinterService,
    toastFactory,
    UserService,
    VerifoneConfigService
) {
    this.$timeout = $timeout;
    this.agentService = AgentService;
    this.config = VerifoneConfigService;
    this.connectivityChecker = ConnectivityChecker;
    this.deviceManager = deviceManager;
    this.dialogService = DialogService;
    this.hydrator = hydrator;
    this.receiptPrinterService = ReceiptPrinterService;
    this.toastFactory = toastFactory;
    this.user = UserService;

    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('Verifone', true);

    /**
     * Callbacks to be fired when a message is received from the terminal.
     *
     * @type {Function[]}
     */
    this.recordReceivedListeners = [];

    /**
     * The socket connection to the terminal for
     * - sending commands (VX820 + VX820IP)
     * - receiving results (VX820 + VX820IP)
     * - receiving progress messages (VX820IP only)
     *
     * The integration socket regularly disconnects itself (by design), so we must ensure it is connected
     * before sending any message to it.
     *
     * @type {TcpSocketConnection}
     */
    this.integrationSocket = null;

    /**
     * When using the POS Client (VX820) a separate socket connection is needed to receive status updates
     * ("Card inserted", "Waiting for PIN", etc.) Note that the POS Client must be in
     * 'screenless' mode for the progress socket to be enabled.
     *
     * When not using the POS Client (VX820IP) these messages are received on the integration socket and
     * the progress socket is not required or available.
     *
     * @type {?TcpSocketConnection}
     */
    this.progressSocket = null;

    /**
     * Keep track of the state of the terminal.
     */
    this.state = {
        /**
         * Can a cancel command be sent in the current state?
         *
         * @type {boolean|null}
         */
        canCancel: null,

        /**
         * To prevent sending records to the terminal too quickly a 1 second timeout happens after
         * each record is sent. During this time buttons should be disabled so the operator cannot
         * press it again.
         *
         * Note that this does not actually prevent records being sent by the driver and
         * is only used to update the UI.
         *
         * @type {boolean}
         */
        canSendRecord: true,

        /**
         * Is the WebSocket to the proxy server connected?
         * (null = currently connecting)
         *
         * @type {boolean|null}
         */
        webSocketConnected: false,

        /**
         * Has the device been 'ready' at least once since the EPOS was loaded?
         *
         * @type {boolean}
         */
        initialReady: false,

        /**
         * Is the device in the 'ready' state currently?
         * (null = we don't know)
         *
         * @type {boolean|null}
         */
        isReady: null,

        /**
         * Last record received from the integration socket.
         */
        lastIntegrationRecordReceived: null,

        /**
         * Last record received from the progress socket.
         *
         * @type {VerifoneStatusRecord|null}
         */
        lastProgressRecordReceived: null,

        /**
         * Remember the last record we sent to the terminal.
         * (Always on the integration socket because you don't send things to the progress socket.)
         *
         * @type {*}
         */
        lastRecordSent: null,

        /**
         * Is a user logged in to the payment terminal?
         * (null = we don't know)
         *
         * @type {boolean|null}
         */
        loggedIn: null
    };

    if (this.config.validate()) {
        this.init();
    } else {
        this.toastFactory.errorTop('', 'The Verifone payment terminal config is invalid.');
    }

    /**
     * While the terminal is logging in or downloading updates a persistent notification will be displayed.
     * Keep a reference to the method to dismiss those notifications here so they can be dismissed later.
     */
    this.dismissLoggingInNotificationFunc = null;
    this.dismissUpdatingNotificationFunc = null;

    // When we go back online, tell the terminal to submit its offline transactions.
    this.connectivityChecker.addObserver({
        key: 'VerifoneDriver',
        notifyOnline: () => {
            if (this.state.isReady) {
                // Temporarily disabled until we figure out why this breaks (PRO-780)
                // this.submitOfflineTransactions();
            }
        },
        notifyOffline: () => {
        }
    });

    // Submit offline transactions every 5 mins.
    // This is commented out as it can cause errors to appear if it runs while a transaction is in progress.
    // TODO: Determine if this this needed or if the online observer is sufficient.
    // $interval(() => {
    //    if (this.state.isReady && this.connectivityChecker.online) {
    //        this.submitOfflineTransactions()
    //    }
    // }, 300000);
};

VerifoneDriver.prototype = {
    /**
     * Setup the socket connections.
     */
    init() {
        let connectPromises = [];

        // The integration socket will disconnect after a period of inactivity or after an error.
        this.integrationSocket = this.createTcpSocket(
            VerifoneSocketNames.INTEGRATION,
            this.config.terminalHost,
            this.config.terminalPort
        );
        let integrationSocketPromise = this.integrationSocket.connect();
        integrationSocketPromise.catch((error) => {
            this.toastFactory.error(
                'PAYMENT.TERMINAL_ERROR',
                'There was a problem connecting to the Verifone integration socket.<br/>'
                + error.toString()
            );
        });
        connectPromises.push(integrationSocketPromise);

        if (this.config.posClient) {
            // The progress socket stays connected indefinitely.
            this.progressSocket = this.createTcpSocket(
                VerifoneSocketNames.PROGRESS,
                this.config.terminalHost,
                this.config.terminalProgressPort
            );
            let progressSocketPromise = this.progressSocket.connect();
            progressSocketPromise.catch((error) => {
                this.toastFactory.error(
                    'PAYMENT.TERMINAL_ERROR',
                    'There was a problem connecting to the Verifone progress socket.<br/>'
                    + error.toString()
                );
            });
            connectPromises.push(progressSocketPromise);
        }

        Promise.all(connectPromises)
            .then(() => {
                this.toastFactory.success(
                    '',
                    'Connected to Verifone payment terminal.'
                );
            })
            .catch(() => {
                this.dismissLoggingInNotification();
            });
    },

    /**
     * Create a new TCP socket object via the WebSocket connection.
     * Setup listeners to handle data and errors.
     *
     * @param {string} name
     * @param {string} host
     * @param {number} port
     *
     * @return {TcpSocketConnection}
     */
    createTcpSocket(name, host, port) {
        let tcpSocket = this.agentService.createTcpSocket(
            name,
            host,
            port
        );

        tcpSocket.onError = (errorString) => {
            this.toastFactory.error(
                'PAYMENT.TERMINAL_ERROR',
                'There was a problem communicating with the Verifone payment terminal.<br/>' + errorString
            );
            this.dismissLoggingInNotification();
        };

        // Add listener for data coming from the TCP socket.
        tcpSocket.onData = (dataString, socket) => {
            let record = this.createRecordFromSocketData(dataString, socket);

            if (record) {
                this.handleReceivedRecord(record, socket);
            }
        };

        return tcpSocket;
    },

    /**
     * Receives the raw string data from a socket and creates a record object from it.
     *
     * @param {string} dataString
     * @param {TcpSocketConnection} fromSocket
     *
     * @return {object|null}
     */
    createRecordFromSocketData(dataString, fromSocket) {
        // Fix a bug with reprinting receipts from a VX820IP
        // If you reprint receipts it replies with:
        // V,***** DUPLICATE *****
        // ** CARDHOLDER COPY **, ...
        // You would expect the new line to be a comma like the rest of the new lines in the voucher but it isn't.
        // Bug is confirmed with Verifone but they will not fix it as it would break other people's workarounds.
        dataString = dataString.replace('V,***** DUPLICATE *****\n', 'V,***** DUPLICATE *****,');

        let lines = dataString.split('\n');

        for (let i = 0; i < lines.length; i++) {
            // Remove special characters in string, including newline at the end and
            // device control characters such as ACK
            // See: https://www.w3schools.com/charsets/ref_html_ascii.asp
            let str = lines[i].replace(/[\000-\031]/g, '');

            if (str.length < 1) {
                continue;
            }

            // A small hack. In the getHydrationMap() of the response record models it's easier to
            // understand if the field number is the same number from the Verifone docs.
            // The docs start from field 1, but str.split() will start at 0.
            // So insert an extra comma at the start of the string before splitting so the actual
            // data starts at index 1.
            let recordData = (',' + str).split(',');
            recordData.rawResponse = str;

            let record;

            if (fromSocket === this.progressSocket) {
                // Message received on progress socket.
                // This will only ever be a StatusRecord.
                record = new VerifoneStatusRecord();
                this.hydrator.hydrate(recordData, record);
                return record;
            }

            // Messages received on integration socket...

            if (recordData[1] === 'V') {
                // Voucher record received
                // Note for duplicate receipts there is a bug. See above for more info.
                record = new VerifoneVoucherRecord();
                this.hydrator.hydrate(recordData, record);
                return record;
            }

            if (!this.config.posClient && parseInt(recordData[1], 10) === 100) {
                // Instead of using a progress socket, the VX820IP sends records
                // down the integration socket with a status code of 100.
                // TODO: Different object for eVo records? Because it won't have a statusId.
                record = new VerifoneStatusRecord();
                this.hydrator.hydrate(recordData, record);
                return record;
            }

            // Try to determine what type it is.
            record = new (this.getExpectedResponseType())();
            this.hydrator.hydrate(recordData, record);
            return record;
        }

        return null;
    },

    /**
     * Do something with a record received from the terminal.
     * Then pass it on to any listeners.
     *
     * @param {object} record
     * @param {TcpSocketConnection} socket
     */
    handleReceivedRecord(record, socket) {
        this.log.debug('Record Received (' + socket.name + ')', record);

        this.setLastReceivedRecord(record, socket);

        if (
            (record.result && record.result === -85)
            || (record.statusId && record.statusId === VerifoneStatusIds.LOGIN_REQUIRED)
        ) {
            // Don't auto login anymore.
            // If we are told the PED has been logged out, log back in.
            /*this.$timeout(() => {
                this.login();
            }, 1000);*/

        } else if (record instanceof VerifoneVoucherRecord) {
            // Print it.
            this.printVoucherRecord(record);
            return;
        } else if (record instanceof VerifoneLoginResponseRecord) {
            if (record.result === 0) {
                // Successful login.
                this.onLogin();
            } else if (record.result === -84) {
                // User already logged in. This is fine!
                // TODO: Attempt to log them out then login again.
                this.onLogin();
            }
        } else if (record instanceof VerifoneLogoutResponseRecord) {
            if (record.result === 0) {
                // Successful logout.
                this.onLogout();
            } else if (record.result === -84) {
                // User not logged in. This is fine - we wanted to logout anyway.
                this.onLogout();
            }
        } else if (record instanceof VerifoneStatusRecord) {
            // For some records we already know what to do, so send a response automatically.
            this.autoRespondToStatusRecord(record);

            // Set the ready status.
            if (record.statusId === VerifoneStatusIds.READY) {
                this.setReady(true);
            } else {
                this.setReady(false);
            }

            // Do some special things with certain statuses.
            switch (record.statusId) {
                case VerifoneStatusIds.DOWNLOADING_FILE:
                case VerifoneStatusIds.PERFORMING_DOWNLOAD:
                    this.maximisePosClient();
                    this.showUpdatingNotification();
                    break;

                case VerifoneStatusIds.DOWNLOAD_COMPLETE:
                    this.dismissUpdatingNotification();
                    break;

                case VerifoneStatusIds.SERVER_CONNECTION_FAILED:
                    // Show modal to go offline or try to reconnect.
                    this.showOfflineConfirmationModal();
                    return;

                case VerifoneStatusIds.CONTINUE_REQUIRED:
                    if (this.config.improvePrinting) {
                        // Print a small blank page to cut the paper.
                        this.printBlankPage(() => {
                            this.$timeout(() => {
                                let continueRecord = new VerifoneContinueTransactionRecord();
                                continueRecord.actionId = VerifoneActionIds.CONTINUE_TRANSACTION;
                                this.sendRecord(continueRecord);
                            }, 500);
                        });
                    }
                    break;

                case VerifoneStatusIds.SIGNATURE_CONFIRMATION_REQUIRED:
                    if (this.config.improvePrinting) {
                        // Print a small blank page to cut the paper.
                        // But don't automatically continue the transaction like for CONTINUE_REQUIRED.
                        this.printBlankPage();
                    }
                    break;
            }
        }

        if (record.hasOwnProperty('result')) {
            if (record.result < 0) {
                // Generic error handler.
                this.displayErrorRecord(record);
                this.dismissLoggingInNotification();
                this.dismissUpdatingNotification();
            } else if (!this.config.posClient) {
                this.setReady(true);
            }
        }

        let uppercaseMessage = record.message ? record.message.toUpperCase() : '';
        switch (uppercaseMessage) {
            case 'RECEIPT REPRINTED':
                if (this.config.posClient && this.config.improvePrinting) {
                    this.printBlankPage();
                }
                break;
            case 'TRANSACTION CANCELLED':
                if (!this.config.posClient) {
                    this.setReady(true);
                }
                break;
        }

        this.state.canCancel = this.canCancel();

        // Tell other places that may be interested in this record (the payment controller).
        this.notifyResponseListeners(record, socket);
    },

    /**
     * Set the last record that was received from the terminal on that socket.
     *
     * @param {object} record
     * @param {TcpSocketConnection} socket
     */
    setLastReceivedRecord(record, socket) {
        // Set the last received record on this socket.
        if (socket && socket.name === VerifoneSocketNames.INTEGRATION) {
            this.state.lastIntegrationRecordReceived = record;
        } else if (socket && socket.name === VerifoneSocketNames.PROGRESS) {
            this.state.lastProgressRecordReceived = record;
        }
    },

    /**
     * Decide if the given record should be ignored when it is received.
     *
     * @param {object} record
     *
     * @return {boolean}
     */
    shouldIgnoreRecord(record) {
        let upperMessage = record.message ? record.message.toUpperCase() : '';
        let ignoreMessages = [
            'WINDOW STATE CHANGED',
            'SERVICE NOT ALLOWED',
            // 'USER NOT LOGGED IN'
        ];

        return ignoreMessages.indexOf(upperMessage) !== -1;
    },

    /**
     * Display an error notification for the given record.
     *
     * @param {object} record
     */
    displayErrorRecord(record) {
        // Ignore these errors:
        let ignoreErrors = [
            -84, // "User already logged in" when trying to log in, or "User not logged in" when trying to log out.
            -99, // Transaction cancelled - not really an error.
        ];

        if (ignoreErrors.indexOf(record.result) === -1) {
            // This is an error we can't handle on the EPOS.
            // Maximise the POS Client app.
            let message = 'There was a error with the Verifone payment terminal:<br/>' + record.message;
            if (this.config.posClient) {
                message += '<br/><small>Please check the terminal application for any further information.</small>';
            }
            this.toastFactory.errorTop('', message);

            this.maximisePosClient();
        }
    },

    /**
     * Set if the terminal is ready to receive a new transaction.
     *
     * @param {boolean} ready
     */
    setReady(ready) {
        this.state.isReady = ready;

        if (this.state.isReady) {
            this.dismissLoggingInNotification();
            this.dismissUpdatingNotification();
        }

        // Show a ready notification once per session.
        if (this.state.isReady && !this.state.initialReady) {
            this.toastFactory.success('', 'Payment terminal is ready.');
            this.state.initialReady = true;
        }
    },

    /**
     * For some status records we want to automatically send an action response.
     *
     * @param {VerifoneStatusRecord} statusRecord
     *
     * @return {boolean} If an automatic response was sent.
     */
    autoRespondToStatusRecord(statusRecord) {
        let autoResponseActionId = this.getAutoResponseActionForStatus(statusRecord.statusId);
        if (!autoResponseActionId) {
            return false;
        }

        let actionRecord = statusRecord.getActionRecord(autoResponseActionId);
        if (actionRecord) {
            this.log.debug('Auto-responding to', statusRecord, actionRecord);
            this.sendRecord(actionRecord);
            return true;
        }

        return false;
    },

    /**
     * Return an action that should be sent back automatically for the given status.
     *
     * @param {number} statusId
     *
     * @return {number|null}
     */
    getAutoResponseActionForStatus(statusId) {
        switch (statusId) {
            case VerifoneStatusIds.UNEXPECTED_LOGIN:
                // Automatically switch account when logging in as a different user.
                return VerifoneActionIds.REPLACE_ACCOUNT;

            case VerifoneStatusIds.REGISTER_FOR_ACCOUNT_ON_FILE_DECISION:
                // Skip registering for account on file if prompted.
                // The flag to suppress the register for account prompt is set on the transaction request
                // record. But just in case!
                return VerifoneActionIds.ACCOUNT_ON_FILE_REGISTRATION_NOT_REQUIRED;

            case VerifoneStatusIds.DOWNLOAD_STILL_BEING_PREPARED:
            case VerifoneStatusIds.RETRY_DOWNLOAD:
            case VerifoneStatusIds.RESUME_DOWNLOAD:
                // If there are errors updating the terminal, cancel the download.
                // This will cause an error message to flash up saying to check the Sential app, which is better
                // than attempting to fix the terminal's problems from the EPOS.
                return VerifoneActionIds.CANCEL_DOWNLOAD;

            case VerifoneStatusIds.UNSAFE_DOWNLOAD:
                return VerifoneActionIds.REJECT_UNSAFE_DOWNLOAD;

            case VerifoneStatusIds.WAITING_FOR_CASHBACK:
                // If cashback is not enabled on the company, auto reply to skip cashback.
                if (!this.user.company.maxCashback) {
                    return VerifoneActionIds.CASHBACK_NOT_REQUIRED;
                }
                break;
        }

        return null;
    },

    /**
     * Send a record to the terminal.
     *
     * @param {AbstractVerifoneRequestRecord} record
     */
    sendRecord(record) {
        this.preProcessRecord(record);

        this.state.lastRecordSent = record;

        this.log.debug('Sending Record', record);

        let dataString = record.toArray(this.config.posClient).join(',');

        if (this.config.posClient) {
            dataString += '\r\n';
        }

        if (this.progressSocket !== null) {
            // Ensure the progress socket is connected first.
            // The integration socket will connect itself before sending data.
            this.progressSocket.connect().then(() => {
                this.integrationSocket.sendData(dataString);
            });
        } else {
            this.integrationSocket.sendData(dataString);
        }

        this.state.canSendRecord = false;
        this.$timeout(() => {
            this.state.canSendRecord = true;
        }, 1000);
    },

    /**
     * Tell the POS Client application to make itself visible.
     */
    maximisePosClient() {
        if (this.config.posClient) {
            this.sendRecord(new VerifoneWinStateRequestRecord(2));
        }
    },

    /**
     * Perform any necessary transformations on a record before sending.
     *
     * @param {object} record
     */
    preProcessRecord(record) {
        if (record instanceof VerifoneContinueTransactionRecord) {
            switch (record.actionId) {
                case VerifoneActionIds.REPLACE_ACCOUNT:
                    // Replace Account needs to include the manager PIN.
                    record.parameters.MGRPIN = this.config.managerPin;
                    break;
            }
        }
    },

    /**
     * Returns the class that should be used for the next record received on the integration socket,
     * based on the last record that was sent.
     *
     * We can't automatically differentiate between types of responses (it's just a string of text. It sure
     * would have been nice if they could have said what kind of record it was!).
     * Get what we think the response type should be based on what was last sent.
     * Otherwise use a generic response model.
     *
     * e.g. If the last record sent was a VerifoneLoginRequestRecord the next response should be a LoginResponseRecord.
     */
    getExpectedResponseType() {
        if (this.state.lastRecordSent) {
            if (this.state.lastRecordSent instanceof VerifoneTransactionRequestRecord) {
                return VerifoneTransactionResponseRecord;
            }
            if (this.state.lastRecordSent instanceof VerifoneLoginRequestRecord) {
                return VerifoneLoginResponseRecord;
            }
            if (this.state.lastRecordSent instanceof VerifoneLogoutRequestRecord) {
                return VerifoneLogoutResponseRecord;
            }
        }

        return VerifoneTransactionResponseRecord;
    },

    /**
     * Send a cancel transaction request to the terminal, but only if we know it's appropriate at this time.
     *
     * @return {boolean}
     */
    cancelTransaction() {
        // canCancel() can be true false or null. If false it should not be attempted.
        // If true we know it can be cancelled.
        // If null it may be cancellable. Try it for POS Client. Don't tell the operator to do it for eVo.
        if (this.canCancel() === true) {
            let record;
            if (this.config.posClient) {
                record = new VerifoneContinueTransactionRecord();
                record.actionId = VerifoneActionIds.CANCEL_TRANSACTION;
                this.sendRecord(record);
            } else {
                // VX820IP doesn't support cancel records. The best we can do is tell the operator to press
                // the cancel button.
                this.toastFactory.warningTop('Please press the Cancel button on the payment terminal.');
            }
            return true;
        }
        this.log.error('Cannot cancel transaction in current state.');
        return false;
    },

    /**
     * Check if the 'cancel' command can be sent in the current state.
     *
     * @return {boolean|null} If a transaction is in progress the modal should not be closed or the payment
     *                        method changed: false.
     *                        If it is okay to abandon the transaction (no cancel command sent): null.
     *                        Can cancel and the cancel command should be sent: true.
     */
    canCancel() {
        if (this.state.lastProgressRecordReceived && this.state.lastProgressRecordReceived.statusId) {
            let actionsForStatus = VerifoneActionsForStatuses[this.state.lastProgressRecordReceived.statusId];

            if (actionsForStatus && actionsForStatus.hasOwnProperty('actions')) {
                // Check if the 'Cancel Transaction' action is available for the last status received.
                return actionsForStatus.actions.indexOf(VerifoneActionIds.CANCEL_TRANSACTION) !== -1;
            }
            // Check if is in a state that can be abandoned.
            // If it is return null (can be left without sending the cancel command).
            // if it is not return false. We know it cannot be cancelled because the cancel action is not
            // available.
            let abandonableStatuses = [
                VerifoneStatusIds.READY,
                VerifoneStatusIds.DOWNLOADING_FILE,
                VerifoneStatusIds.UPDATING_PED,
                VerifoneStatusIds.PERFORMING_DOWNLOAD,
                VerifoneStatusIds.LOGIN_REQUIRED,
                VerifoneStatusIds.TRANSACTION_CANCELLED,
                VerifoneStatusIds.INITIALISING_PED,
                VerifoneStatusIds.PED_UNAVAILABLE,
                VerifoneStatusIds.RETRY_DOWNLOAD,
                VerifoneStatusIds.RESTART_AFTER_SOFTWARE_UPDATE,
                VerifoneStatusIds.DOWNLOAD_COMPLETE,
            ];

            return abandonableStatuses.indexOf(this.state.lastProgressRecordReceived.statusId) !== -1 ? null : false;
        } else if (this.state.lastIntegrationRecordReceived) {
            // The VX820IP terminals do not send status/progress records with a statusId. Determine if it can
            // be cancelled based on the last integration message received.
            let shouldCancelMessages = [
                'Waiting for card',
            ];
            if (shouldCancelMessages.indexOf(this.state.lastIntegrationRecordReceived.message) !== -1) {
                return true;
            }
            let shouldNotCancelMessages = [
                'Authorising',
                'Cardholder print complete',
                'Checking signature',
                'Confirming',
                'Merchant print complete',
            ];
            if (shouldNotCancelMessages.indexOf(this.state.lastIntegrationRecordReceived.message) !== -1) {
                return false;
            }
        }

        return null;
    },

    /**
     * Send a login record to the terminal using the ID/PIN specified, or the ID/PIN from the config
     * if none are given.
     *
     * @param {number} [userId]
     * @param {number} [userPin]
     */
    login(userId, userPin) {
        userId = userId !== undefined ? userId : this.config.userId;
        userPin = userPin !== undefined ? userPin : this.config.userPin;

        if (!userId || !userPin) {
            return;
        }

        let loginRecord = new VerifoneLoginRequestRecord();
        loginRecord.userId = userId;
        loginRecord.userPin = userPin;

        if (!this.config.posClient) {
            // Lock down menu options.
            loginRecord.menuOptions = '2{*}';
        }

        this.sendRecord(loginRecord);

        this.showLoggingInNotification();
    },

    /**
     * Send a logout request record.
     */
    logout() {
        this.sendRecord(new VerifoneLogoutRequestRecord());
    },

    /**
     * Tell the terminal to submit any offline transactions.
     */
    submitOfflineTransactions() {
        this.sendRecord(new VerifoneSubmitOfflineTransactionsRecord());
    },

    /**
     * Tell the terminal to reprint the last merchant receipt.
     */
    reprintMerchantReceipt() {
        this.sendRecord(new VerifoneReportRequestRecord(102));
    },

    /**
     * Tell the terminal to reprint the last cardholder receipt.
     */
    reprintCardholderReceipt() {
        this.sendRecord(new VerifoneReportRequestRecord(101));
    },

    /**
     * Print a small blank page.
     * (Used to improve printing from POS Client in RAW mode)
     *
     * @param {function} [callback]
     */
    printBlankPage(callback) {
        let opts = new PrintJobOptions(PrintType.CARD_RECEIPT, null, callback);
        this.receiptPrinterService.printBlankPage(opts);
    },

    /**
     * Register a new listener that will be fired when a record is received from the terminal.
     *
     * @param {Function} listener
     */
    onRecordReceived(listener) {
        this.recordReceivedListeners.push(listener);
    },

    /**
     * Un-register all record received listeners.
     */
    clearRecordReceivedListeners() {
        this.recordReceivedListeners = [];
    },

    /**
     * Show the "Logging in to payment terminal" notification.
     */
    showLoggingInNotification() {
        if (typeof this.dismissLoggingInNotificationFunc !== 'function') {
            let loadingToast = this.toastFactory.spinner('', 'Logging in to payment terminal...');
            this.dismissLoggingInNotificationFunc = () => {
                this.toastFactory.clear(loadingToast);
            };
        }
    },

    /**
     * Hide the "Logging in to payment terminal" notification.
     */
    dismissLoggingInNotification() {
        if (typeof this.dismissLoggingInNotificationFunc === 'function') {
            this.dismissLoggingInNotificationFunc();
            this.dismissLoggingInNotificationFunc = null;
        }
    },

    /**
     * Show the "Payment terminal updating" notification.
     */
    showUpdatingNotification() {
        if (typeof this.dismissUpdatingNotificationFunc !== 'function') {
            let loadingToast = this.toastFactory.spinner('PLEASE_WAIT', 'Payment terminal is updating.');
            this.dismissUpdatingNotificationFunc = () => {
                this.toastFactory.clear(loadingToast);
            };
        }
    },

    /**
     * Hide the "Payment terminal updating" notification.
     */
    dismissUpdatingNotification() {
        if (typeof this.dismissUpdatingNotificationFunc === 'function') {
            this.dismissUpdatingNotificationFunc();
            this.dismissUpdatingNotificationFunc = null;
        }
    },

    /**
     * Notify all the registered response listeners.
     *
     * @param {object} record
     * @param {TcpSocketConnection} socket
     *
     * @private
     */
    notifyResponseListeners(record, socket) {
        for (let r = 0; r < this.recordReceivedListeners.length; r++) {
            this.recordReceivedListeners[r](record, socket);
        }
    },

    /**
     * @private
     */
    onLogin() {
        this.dismissLoggingInNotification();

        this.state.loggedIn = true;

        if (!this.config.posClient) {
            this.setReady(true);
        }

        // Temporarily disabled until we figure out why this breaks (PRO-780)
        // this.submitOfflineTransactions();
    },

    /**
     * @private
     */
    onLogout() {
        if (this.state.loggedIn) {
            this.toastFactory.success('', 'Successfully logged out of payment terminal.');
        }
        this.state.loggedIn = false;
    },

    /**
     * @param {VerifoneVoucherRecord} record
     * @private
     */
    printVoucherRecord(record) {
        this.receiptPrinterService.printVerifoneVoucher(record);
    },

    /**
     * Display a modal to confirm if the payment terminal should switch to offline model.
     *
     * @private
     */
    showOfflineConfirmationModal() {
        this.dismissLoggingInNotification();

        this.dialogService.confirm(
            'PAYMENT_VERIFONE.OFFLINE_MODAL_BODY',
            (result) => {
                let continueRecord = new VerifoneContinueTransactionRecord();
                if (result) {
                    // Attempt to reconnect
                    continueRecord.actionId = VerifoneActionIds.RECONNECT_TO_SERVER;
                } else {
                    // Go offline
                    continueRecord.actionId = VerifoneActionIds.ABORT_RECONNECT_TO_SERVER;
                }
                this.sendRecord(continueRecord);
                this.showLoggingInNotification();
            },
            {
                cancelLabel: 'PAYMENT_VERIFONE.GO_OFFLINE_BTN',
                okLabel: 'PAYMENT_VERIFONE.ATTEMPT_RECONNECT_BTN'
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneDriver;
}
