DigiTickets.MembershipRefGenerator = function () {
    this.prefix = 'M';
};

DigiTickets.MembershipRefGenerator.prototype = {
    /**
     * @param {int|null} membershipId
     * @return {string|null}
     */
    generate: function generate(membershipId) {
        if (!membershipId) {
            return null;
        }

        let leadingZeros = '00000';
        let id = membershipId.toString();

        return this.prefix
                + leadingZeros.substring(0, leadingZeros.length - id.length) + id;
    }
};
