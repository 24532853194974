const redemptionAnimationDirective = function ($filter) {
    return {
        /**
         * @param $scope Scope is inherited from RedemptionCtrl - the only place this directive is used.
         * @param element
         */
        link: function ($scope, element) {
            if (!$scope.canBeRedeemed) {
                return false;
            }

            function redirectInTwoSeconds($scope) {
                return setTimeout(function () {
                    $scope.pendingRedirect = null;
                    return $scope.closeRedemption();
                }, 2000);
            }

            function beginRedemptionWatcher($scope, element) {
                let redemptionSuccessful = $scope.redemptionSuccessful;

                $scope.$watch('redemptionSuccessful', function (redemptionSuccessful) {
                    if (redemptionSuccessful === null) {
                        return false;
                    }

                    if (redemptionSuccessful === false) {
                        showFailedRedemption(element, $scope.errorMessage);

                        return false;
                    }

                    showSuccessfulRedemption(element, $scope);
                    $scope.pendingRedirect = redirectInTwoSeconds($scope);
                });
            }

            function showFailedRedemption(element, message) {
                $('#redemption-text-container').fadeOut('fast', function () {
                    $('#redemption-footer-container').removeClass('redeemable');
                    $(element).removeClass().addClass('irredeemable').addClass('completed');

                    $('.redemption-action-label').text($filter('lang')('REDEMPTION.OOPS'));
                    $('.redemption-action-message').text(message || $filter('lang')('REDEMPTION.ERROR')).fadeIn('fast');
                });
            }

            function showSuccessfulRedemption(element, $scope) {
                $('#redemption-text-container').fadeOut('fast', function () {
                    $(element).removeClass().addClass('completed').addClass('success');

                    $('.redemption-action-label').text($filter('lang')('REDEMPTION.SUCCESS'));
                    $('.redemption-action-message').html(
                        $filter('lang')('REDEMPTION.REDEEMED', { name: $scope.orderItem.getName() })
                    ).fadeIn('fast');
                });
            }

            beginRedemptionWatcher($scope, element);
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = redemptionAnimationDirective;
}
