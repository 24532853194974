const apiErrorMessage = require('../../../js/libraries/DigiTickets/Api/apiErrorMessage');
const MembershipDatasetsModalCtrl = require('../../../js/controllers/modal/MembershipDatasetsModalCtrl');
const MembershipManagementCtrl = require('../../../js/controllers/MembershipManagementCtrl');
const moment = require('moment');
const PrivilegeNames = require('@/libraries/DigiTickets/Privileges/PrivilegeNames');

/**
 * @param $location
 * @param $modal
 * @param $routeParams
 * @param $scope
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {CartService} cartService
 * @param {CustomerAccountService} CustomerAccountService
 * @param {Hydrator} hydrator
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.MembershipManagementActions} MembershipManagementActionsService
 * @param {MembershipRedemptionResource} MembershipRedemptionResource
 * @param MembershipResource
 * @param {MembershipService} membershipService
 * @param {NavigationService} navigationService
 * @param Notification
 * @param OrderManagerService
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param ReceiptPrinterService
 * @param RedemptionManagerService
 * @param RedemptionResource
 * @param {RedemptionScanHandler} redemptionScanHandler
 * @param {ToastFactory} toastFactory
 * @param {UserService} UserService
 */
const MembershipDetailCtrl = function (
    $location,
    $modal,
    $routeParams,
    $scope,
    AppConfig,
    cartService,
    CustomerAccountService,
    hydrator,
    Logger,
    MembershipManagementActionsService,
    MembershipRedemptionResource,
    MembershipResource,
    membershipService,
    navigationService,
    Notification,
    OrderManagerService,
    PrivilegesService,
    ReceiptPrinterService,
    RedemptionManagerService,
    RedemptionResource,
    redemptionScanHandler,
    toastFactory,
    UserService
) {
    navigationService.showNav();

    $scope.autoRedeemProfile = UserService.company.getAutoRedeemProfile();
    $scope.membershipActions = MembershipManagementActionsService;
    $scope.membershipResource = MembershipResource;
    $scope.navigationService = navigationService;

    $scope.currentMembership = null;
    $scope.currentMembershipSubscription = null;
    $scope.currentTab = 'members';
    $scope.selectedMember = null;
    $scope.initialSelectedMemberRef = $routeParams.memberRef ? $routeParams.memberRef : null;
    $scope.showLoader = true;
    $scope.showNotFound = false;

    $scope.lastScannedMemberRef = redemptionScanHandler.pullLastScannedMemberRef();

    /**
     * @type {boolean}
     */
    $scope.fromBarcodeScan = !!($scope.lastScannedMemberRef
        && $scope.initialSelectedMemberRef
        && $scope.lastScannedMemberRef === $scope.initialSelectedMemberRef);

    $scope.redemptionPagination = {
        pageSize: 10,
        currentPage: 1,
        scrollElement: document.getElementsByTagName('html')[0]
    };

    /**
     * @type {Array|null}
     */
    $scope.redemptions = null;

    /**
     * @type {boolean}
     */
    $scope.loadingRedemptions = false;

    const loadRedemptionHistory = function () {
        if ($scope.loadingRedemptions || $scope.redemptions !== null) {
            return;
        }

        $scope.loadingRedemptions = true;
        MembershipRedemptionResource.query(
            {
                membershipID: $scope.currentMembership.ID
            },
            (response) => {
                $scope.redemptions = response;
                $scope.loadingRedemptions = false;
            },
            (result) => {
                toastFactory.errorTop('', apiErrorMessage(result));
                $scope.loadingRedemptions = false;
            }
        );
    };


    /**
     * Page Navigation
     */

    $scope.selectTab = function selectTab(tab) {
        $scope.redemptionPagination.currentPage = 1;
        $scope.currentTab = tab;
        switch (tab) {
            case 'linked-members':
                $scope.loadLinkedMembers();
                break;
            case 'visits':
                loadRedemptionHistory();
                break;
        }
    };

    /**
     * Membership Data
     */

    /**
     * @param {String} membershipRef
     * @param {Boolean} showLoading
     *
     * @return {Promise}
     */
    $scope.loadMembership = function loadMembership(membershipRef, showLoading) {
        if (showLoading !== false) {
            $scope.showLoader = true;
            $scope.showNotFound = false;
        }

        return membershipService.getMembershipByRef(membershipRef).then(
            function (membership) {
                $scope.showLoader = false;
                $scope.membershipLoaded(membership);
            },
            function () {
                $scope.showLoader = false;
                $scope.showNotFound = true;
            }
        );
    };

    $scope.membershipLoaded = function membershipLoaded(membership) {
        $scope.currentMembership = membership;
        $scope.currentMembershipSubscription = membership.getCurrentSubscription();
        $scope.paymentSummary = membership.getPaymentSummary();
        $scope.timeRemainingBeforeExpiry = moment(membership.maxSubscriptionExpiryDate).fromNow();
        $scope.warnAboutExpiry = (function () {
            let now = moment();
            let then = moment(membership.maxSubscriptionExpiryDate);
            let daysLeft = then.diff(now, 'days');
            return daysLeft <= 30;
        }());

        // Determine the selected member.
        let member;
        if ($scope.selectedMember) {
            // Replace the selectedMember when reloading as the selectedMember object will no longer be the same
            // as the member object from the membership.
            member = $scope.getMemberById($scope.selectedMember.ID);
            if (member) {
                $scope.selectMember(member, false);
            }
        } else if ($scope.initialSelectedMemberRef) {
            member = $scope.currentMembership.getMemberByRef($scope.initialSelectedMemberRef);
            if (member) {
                $scope.selectMember(member, $scope.fromBarcodeScan);
            }
        } else {
            $scope.selectMember(null, false);
        }
    };

    /**
     * @param {number} id
     *
     * @return {DigiTickets.Member|null}
     */
    $scope.getMemberById = function getMemberById(id) {
        for (let i = 0; i < $scope.currentMembership.members.length; i++) {
            let member = $scope.currentMembership.members[i];
            if (member.ID === id) {
                return member;
            }
        }

        return null;
    };

    $scope.reloadMembership = function reloadMembership() {
        if (!$scope.currentMembership) {
            return;
        }
        $scope.loadMembership($scope.currentMembership.ref, false);
    };

    /**
     * Return the URL to this details page for the current membership.
     *
     * @return {?string}
     */
    $scope.getCurrentMembershipUrl = function getCurrentMembershipUrl() {
        if (!$scope.currentMembership) {
            return null;
        }

        return '/memberships/' + $scope.currentMembership.getRef();
    };

    /**
     * @type {MembershipManagementCtrl}
     */
    const membershipManagementCtrl = new MembershipManagementCtrl(
        $location,
        $modal,
        cartService,
        Logger,
        MembershipResource,
        Notification,
        RedemptionResource
    );

    /**
     * @param {DigiTickets.Membership} membership
     */
    $scope.cancelMembership = function cancelMembership(membership) {
        MembershipManagementActionsService.cancelMemberships(
            [membership],
            $scope.getCurrentMembershipUrl()
        );
    };

    $scope.stopRenewingInProgress = false;

    /**
     * @param {DigiTickets.Membership} membership
     */
    $scope.stopRenewingMembership = function stopRenewingMembership(membership) {
        $scope.stopRenewingInProgress = true;
        MembershipManagementActionsService.stopRenewingMemberships(
            [membership],
            function () {
                $scope.stopRenewingInProgress = false;
            }
        );
    };

    /**
     * Admit all members (redeem the whole membership).
     *
     * @param {DigiTickets.Membership} membership
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     */
    $scope.logVisit = function logVisit(
        membership,
        membershipSubscription,
        orderItem,
        membershipPlan
    ) {
        return membershipManagementCtrl.logVisit(
            membership,
            membershipSubscription,
            orderItem,
            membershipPlan,
            function () {
                $scope.reloadMembership();
            }
        );
    };

    /**
     * Un-admit all members (un-redeem the whole membership).
     *
     * @param membership
     * @param membershipSubscription
     * @param orderItem
     * @param membershipPlan
     * @return {*}
     */
    $scope.unlogVisit = function unlogVisit(membership, membershipSubscription, orderItem, membershipPlan) {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_MEMBERSHIPS)
            .then(() => {
                return membershipManagementCtrl.unlogVisit(membership,
                    membershipSubscription,
                    orderItem,
                    membershipPlan,
                    function () {
                        $scope.reloadMembership();
                    });
            });
    };

    $scope.postHandleSingleMemberVisit = function postHandleSingleMemberVisit(membership) {
        if (membership.members.length === 1) {
            toastFactory.success('MEMBERSHIP_DETAILS.VISIT_LOGGED');
            navigationService.viewRedeem();
        }
    };

    $scope.afterMemberVisitChange = function afterMemberVisitChange(membership) {
        // @TODO: Ideally, we would make a call to the redemptions endpoint to get the new state of the visit
        // @TODO: history, but that endpoint doesn't exist! For now, re-load the whole membership.
        $scope
            .loadMembership($routeParams.membershipRef, false)
            .finally($scope.postHandleSingleMemberVisit.bind($scope, membership));
    };

    /**
     * Redeem or un-redeem a single member.
     *
     * @param {DigiTickets.Member} member
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {DigiTickets.Membership} membership
     */
    $scope.toggleMemberVisit = function toggleMemberVisit(
        member,
        membershipSubscription,
        orderItem,
        membershipPlan,
        membership
    ) {
        if (member.hasVisitedToday()) {
            $scope.unlogMemberVisit(member, membershipSubscription, orderItem, membershipPlan, membership);
        } else {
            $scope.logMemberVisit(member, membershipSubscription, orderItem, membershipPlan, membership);
        }
    };

    /**
     * Redeem a single member.
     *
     * @param {DigiTickets.Member} member
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {DigiTickets.Membership} membership
     */
    $scope.logMemberVisit = function logMemberVisit(
        member,
        membershipSubscription,
        orderItem,
        membershipPlan,
        membership
    ) {
        if (!member.hasContact()
            || member.currentlyRedeeming
            || member.currentlyUnredeeming
            || member.hasVisitedToday()
            || !membership.canBeRedeemedRightNow()
            || !membershipPlan.membershipsCanBeRedeemedToday()
        ) {
            return;
        }

        membershipManagementCtrl.updateMemberVisits(
            member,
            membershipSubscription,
            orderItem,
            membershipPlan,
            1,
            $scope.afterMemberVisitChange.bind($scope, membership)
        );
    };

    /**
     * Un-redeem a single member.
     *
     * @param {DigiTickets.Member} member
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {DigiTickets.Membership} membership
     */
    $scope.unlogMemberVisit = function unlogMemberVisit(
        member,
        membershipSubscription,
        orderItem,
        membershipPlan,
        membership
    ) {
        if (!member.hasContact()
            || member.currentlyRedeeming
            || member.currentlyUnredeeming
            || !member.hasVisitedToday()
            || !membership.canBeRedeemedRightNow()
            || !membershipPlan.membershipsCanBeRedeemedToday()
        ) {
            return;
        }

        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_MEMBERSHIPS)
            .then(() => {
                membershipManagementCtrl.updateMemberVisits(
                    member,
                    membershipSubscription,
                    orderItem,
                    membershipPlan,
                    -1,
                    $scope.afterMemberVisitChange.bind($scope, membership)
                );
            });
    };

    /**
     * @param member
     */
    $scope.editMember = function editMember(member) {
        $scope.amendMembers($scope.currentMembership, member.ID);
    };

    /**
     * @param {DigiTickets.Membership} membership
     * @param {number|null} currentMemberID Member to select by default in the modal.
     */
    $scope.amendMembers = function amendMembers(membership, currentMemberID) {
        let membershipDataset = membership.toMembershipDataset();

        // Show the MembershipDatasetsModal with only this dataset, and with only
        // the members tab enabled.

        // This is the user adding/editing the item, so show the pop-up.
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/membershipDatasetsModal.html',
            controller: MembershipDatasetsModalCtrl,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in membership-datasets-window',
            resolve: {
                action: function action() {
                    return 'edit-existing';
                },
                membershipPlan: function membershipPlan() {
                    return membership.currentPlan;
                },
                currentMemberID: function () {
                    return currentMemberID;
                },
                customerAccount: function customerAccount() {
                    return membership.customerAccount;
                },
                increaseQuantityBy: function increaseQuantityBy() {
                    return 0;
                },
                membershipDatasets: function membershipDatasets() {
                    return [membershipDataset];
                }
            }
        });

        modalInstance.result.then(
            function success(data) {
                let dataset = data.membershipDatasets[0];

                membershipService.updateMembership(
                    membership,
                    dataset,
                    function (updatedMembership) {
                        $scope.membershipLoaded(updatedMembership);
                    }
                );
            },
            function error() {
            }
        );
    };

    /**
     * Highlight a member as selected.
     * Auto redeem that member if applicable.
     *
     * @param {DigiTickets.Member|null} member
     * @param {boolean} autoRedeem
     */
    $scope.selectMember = function selectMember(member, autoRedeem) {
        $scope.selectedMember = member;

        if (autoRedeem && member && $scope.autoRedeemProfile && $scope.autoRedeemProfile.redeemMembersWhenScanned) {
            // Redeem the member.
            $scope.logMemberVisit(
                member,
                $scope.currentMembershipSubscription,
                $scope.currentMembershipSubscription.itemInstance.orderLine,
                $scope.currentMembershipSubscription.itemInstance.orderLine.item,
                $scope.currentMembership
            );
        }
    };

    /**
     * @type {DigiTickets.Member[]}
     */
    $scope.linkedMembers = null;
    $scope.loadingLinkedMembers = false;

    $scope.loadLinkedMembers = function loadLinkedMembers() {
        if ($scope.linkedMembers === null && $scope.currentMembership.customerAccount) {
            $scope.loadingLinkedMembers = true;

            // Load the account with all of its memberships attached.
            CustomerAccountService.find(
                $scope.currentMembership.customerAccount.ID,
                /**
                 * @param {DigiTickets.CustomerAccount} account
                 */
                function (account) {
                    if (account) {
                        let linkedMembers = [];
                        let linkedMemberRefs = [];

                        // Go through each membership and pick out the linked members to show.
                        for (let i = 0; i < account.memberships.length; i++) {
                            let linkedMembership = account.memberships[i];
                            if (linkedMembership.ID === $scope.currentMembership.ID) {
                                // Exclude the current membership.
                                continue;
                            }

                            if (!linkedMembership.isActive() || !linkedMembership.currentSubscription) {
                                continue;
                            }


                            for (let j = 0; j < linkedMembership.members.length; j++) {
                                let linkedMember = linkedMembership.members[j];
                                // Add the membership to the member for redeeming.
                                linkedMember.membership = linkedMembership;

                                if (linkedMember.contact) {
                                    // Only show each person once even if they are on multiple memberships.
                                    if (linkedMemberRefs.indexOf(linkedMember.contact.ref) !== -1) {
                                        continue;
                                    }

                                    // Hide contacts that are already present in the loaded membership.
                                    if ($scope.currentMembership.getMemberByRef(linkedMember.contact.ref)) {
                                        continue;
                                    }

                                    linkedMembers.push(linkedMember);
                                    linkedMemberRefs.push(linkedMember.ref);
                                }
                            }
                        }

                        $scope.linkedMembers = linkedMembers;
                    } else {
                        $scope.linkedMembers = [];
                    }
                    $scope.loadingLinkedMembers = false;
                }
            );
        }
    };

    // Handle scanning a barcode.
    $scope.$on('barcodeScanned', function (event, response) {
        if (response.openModal !== false) {
            // Don't do anything on this page if a modal is open on top of it.
            return;
        }
        let barcode = response.code.toUpperCase();
        if (barcode.length > 0) {
            // A barcode was scanned.
            let member = $scope.currentMembership.getMemberByRef(barcode);
            if (member) {
                // First check if this barcode belongs to one of the members on the screen. If it does
                // just highlight them.
                $scope.selectMember(member, true);
            } else if ($scope.currentMembership && barcode === $scope.currentMembership.ref.toUpperCase()) {
                // Clear the selected member.
                $scope.selectMember(null, false);
            } else {
                // Otherwise run a search for this barcode.
                // Take them to the results on the redeem tab if there was no redirect.
                let searchingToast = toastFactory.spinner('SEARCHING');
                redemptionScanHandler.search(
                    response.code,
                    function (query, results, wasOnlineSearch, wasRedirected) {
                        toastFactory.clear(searchingToast);
                        if (!wasRedirected) {
                            // Go to redeem / order search page with these results.
                            redemptionScanHandler.viewSearchResults(
                                query,
                                results,
                                wasOnlineSearch
                            );
                        }
                    },
                    true
                );
            }
        }
        try {
            $scope.$apply();
        } catch (e) {

        }
    });

    // Load the passed in membership ref
    $scope.loadMembership($routeParams.membershipRef, true);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipDetailCtrl;
}
