const angular = require('angular');
const is = require('../Is');
const LZString = require('lz-string');

/**
 * The data coming in from the API includes a LOT of redundancies.
 *
 * This angular-cache Storage Implementation will shrink/expand the data as it is set/get.
 *
 * V1 : Mapped Keys - When the value being stored is, in essence, a result set from mySQL, the column names are part of every row.
 *                    This is massively wasteful.
 *                    MappedKeys produces a dictionary of all the keys to all the layers and produces a new short key (base52 of the index of the dictionary).
 *                    Savings on uncompressed data are between 45% and 49%.
 * V2 : LZ-String   - LZ compression. The clever part here is the encoding. Normally, compression results in a binary string.
 *                    This is no good for angular-cache which requires UTF-16 encoded strings. The LZ-String library produces just that!
 *                    Savings on uncompressed data are over 90%. Very happy with this!!!
 *
 * The angular-cache implements an MRU (most recently used) heap. This gets in the way when trying to deal with multiple tabs. By bypassing
 * angular-cache and using the get()/set()/remove() methods, we can get all the benefits of localStorage, along with compatibility with the
 * DigiTickets.LocalStorageAnalyser().
 */
DigiTickets.StorageImplementation = function () {
    /**
     * @type {object}
     */
    this.opts = {
        log: false,
        timing: false
    };
};

/**
 * Simple logger, controlled by options.
 *
 * @param {DigiTickets.StorageImplementation} cx
 * @param {string} message
 * @param force
 */
function logger(cx, message, force) {
    if (cx.opts.log || !!force) {
        console.log(new Date() + ' - ' + message);
    }
}

/**
 * @type {{getItem: Function, setItem: Function, removeItem: Function}}
 */
DigiTickets.StorageImplementation.prototype = {
    /**
     * For keys that are .data., expand the data back to its original form.
     *
     * @param {string} key
     * @param {{}|null}sizes
     * @returns {*}
     */
    getItem: function getItem(key, sizes) {
        // Commented out but kept in because we should be regularly checking this to improve perforamnce.
        // console.log('[StorageImplementation.getItem]', key);

        key = this.cleanKey(key);

        if (this.opts.timing) {
            var iStart = new Date().getTime();
        }

        sizes = sizes || {};
        sizes.key = key.length;

        /**
         * Build a nice string for the key. Removes :
         * 1 - angular-cache.caches.
         * 2 - .data
         *
         * @type {string}
         */
        let niceKey = key.replace(new RegExp('angular-cache\.caches\.|\.data', 'g'), '');

        /**
         * Get the value from the store.
         */
        let value = localStorage.getItem(key);
        sizes.stored = 0;

        /**
         * Do more work if we've got something.
         */
        if (!!value && value.length > 0) {
            sizes.stored = value.length;

            /**
             * Is the key a '.data.' or a '.keys' request?
             */
            if (key.indexOf('.data.') != -1 || key.indexOf('.keys') != -1) {
                /**
                 * De-JSON the value.
                 */
                let valueAsObject = angular.fromJson(value);

                /**
                 * Is this an object and does it have a .LZ property.
                 *
                 * If so, decompress the data.
                 */
                if (is.anObject(valueAsObject) && !!valueAsObject.hasOwnProperty('LZ')) {
                    let uncompressed = LZString.decompressFromUTF16(valueAsObject.LZ);
                    sizes.uncompressed = uncompressed.length;
                    valueAsObject = angular.fromJson(uncompressed);
                }

                /**
                 * Is this an object and does it have the .data and .map properties.
                 * If so, unmap the data.
                 */
                if (is.anObject(valueAsObject) && Object.keys(valueAsObject).sort().join(',') == 'data,map') {
                    valueAsObject = DigiTickets.KeyMapper.unmap(valueAsObject);
                }

                /**
                 * Having done all the work, send the object back as a string.
                 */
                value = angular.toJson(valueAsObject);
                sizes.original = value ? value.length : 0;
            }

            if (this.opts.timing) {
                let iDuration = new Date().getTime() - iStart;
                logger(this, 'Getting ' + niceKey + ' took ' + iDuration, this.opts.timing);
            }
        }

        return value;
    },

    /**
     * Shrink the data.
     *
     * @param {string} key
     * @param {string} value
     */
    setItem: function setItem(key, value) {
        // Commented out but kept in because we should be regularly checking this to improve perforamnce.
        // console.log('[StorageImplementation.setItem]', key);

        key = this.cleanKey(key);

        /**
         * Build a nice string for the key. Removes :
         * 1 - angular-cache.caches.
         * 2 - .data
         *
         * @type {string}
         */
        let niceKey = key.replace(new RegExp('angular-cache\.caches\.|\.data', 'g'), '');

        if (this.opts.timing) {
            var iStart = new Date().getTime();
        }

        /**
         * Only try to shrink data whose key has '.data.' in it.
         *
         * The cache uses '.keys.' and '.data.'. We are only shrinking .data. as .keys. is a simple array.
         */
        if (key.indexOf('.data.') != -1) {
            /**
             * Get the data from the JSON structure.
             */
            let valueAsObject = angular.fromJson(value);

            /**
             * Verify that the deserialized value has a 'value' property and that we have not already been mapped.
             */
            if (!!valueAsObject && valueAsObject.hasOwnProperty('value') && !valueAsObject.hasOwnProperty('map')) {
                /**
                 * Does this look like a resource response?
                 *
                 * If so, deserialize the value.
                 */
                try {
                    if (is.anArray(valueAsObject.value) && valueAsObject.value.length == 4 && valueAsObject.value[0] == 200 && is.aString(valueAsObject.value[1]) && valueAsObject.value[3] == 'OK') {
                        valueAsObject.value[1] = angular.fromJson(valueAsObject.value[1]);
                    } else {
                        valueAsObject.value = angular.fromJson(valueAsObject.value);
                    }
                } catch (err) {
                    logger(this, 'Treating ' + niceKey + ' as a string.');
                }

                var mappedValue = angular.toJson(DigiTickets.KeyMapper.map(valueAsObject));

                if (mappedValue.length < value.length) {
                    logger(this, 'Key Mapping on ' + niceKey + ' saved ' + (2 * (value.length + mappedValue.length)) + ' bytes.');
                    value = mappedValue;
                } else {
                    logger(this, 'Key Mapping on ' + niceKey + ' is not effective. Using original value.');
                }
            }
        }

        /**
         * LZ-string compress to UTF-16.
         */
        mappedValue = angular.toJson({
            LZ: LZString.compressToUTF16(value)
        });
        if (mappedValue.length < value.length) {
            logger(this, 'LZString.compressToUTF16 on ' + niceKey + ' saved ' + (2 * (value.length + mappedValue.length)) + ' bytes.');
            value = mappedValue;
        } else {
            logger(this, 'LZString.compressToUTF16 on ' + niceKey + ' is not effective. Using original value.');
        }

        /**
         * Store the value.
         */
        localStorage.setItem(key, value);

        if (this.opts.timing) {
            let iDuration = new Date().getTime() - iStart;
            logger(this, 'Setting ' + niceKey + ' took ' + iDuration, this.opts.timing);
        }
    },

    /**
     * Remove the requested key from the localStorage.
     *
     * @param {string} key
     */
    removeItem: function removeItem(key) {
        key = this.cleanKey(key);

        localStorage.removeItem(key);
    },

    /**
     * Angular-resource uses the full URL of API requests as the cache key. This includes the apiKey, meaning
     * one cache won't work for another user.
     * To fix that we remove the apiKey=abcd part of keys.
     *
     * @param {string} key
     *
     * @return {string}
     */
    cleanKey: function cleanKey(key) {
        return key.replace(/(apiKey=\w+)/, '');
    }
};
