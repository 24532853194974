const KitchenOrder = require('./KitchenOrder');
const KitchenOrderItem = require('./KitchenOrderItem');
const { cloneDeep } = require('../../../functions/clone');
const { kitchenOrderItemHash } = require('./kitchenOrderItemHash');

const OrderToKitchenOrderMapper = {
    /**
     * @param {DigiTickets.Order} order
     *
     * @return {KitchenOrder}
     */
    map(order) {
        const kitchenOrder = new KitchenOrder();

        kitchenOrder.fulfillmentCreatedAt = order.fulfillmentCreatedAt || null;
        kitchenOrder.fulfilledAt = order.fulfilledAt || null;
        kitchenOrder.fulfillments = cloneDeep(order.fulfillments);

        kitchenOrder.modifiedAt = order.date;
        kitchenOrder.staffRef = order.staffRef ? order.staffRef : order.bookingRef;
        kitchenOrder.thirdPartyID = order.thirdPartyID;
        kitchenOrder.thirdPartyRef = order.thirdPartyRef;
        kitchenOrder.notes = order.notes;

        // TODO: Remove (PRO-412)
        kitchenOrder.orderID = order.ID;
        kitchenOrder.stashedCartGuid = order.stashedCartGuid;

        // Get a flattened array of already fulfilled lines
        let fulfilledItems = order.fulfillments.reduce(
            (acc, fulfillment) => acc.concat(fulfillment.fulfilledItems), []
        );

        for (let i = 0; i < order.items.length; i++) {
            const orderLine = order.items[i];

            if (!orderLine.item || !orderLine.item.showOnKitchenScreen) {
                // Skip missing or non-kitchen items.
                continue;
            }

            if (orderLine.qty <= 0) {
                // Skip negative lines.
                continue;
            }

            let kitchenItem = new KitchenOrderItem();
            kitchenItem.itemName = orderLine.getName();
            kitchenItem.itemID = orderLine.getItemID();

            if (order.fields) {
                let lineFieldGroups = order.fields.filterFieldGroups({
                    lineNumber: orderLine.lineNumber,
                    hideNoValue: true
                });

                for (let j = 0; j < lineFieldGroups.length; j++) {
                    kitchenItem.addFieldData(lineFieldGroups[j].getDisplayValues());
                }
            }

            kitchenItem.hash = kitchenOrderItemHash(kitchenItem);

            // reduce the quantity for any items already fulfilled
            const matchingFulfilledItems = fulfilledItems.filter(
                (fulfilledItem) => fulfilledItem.hash === kitchenItem.hash
            );
            const qtyAlreadyFulfilled = matchingFulfilledItems.reduce(
                (carry, fulfilledItem) => carry + parseInt(fulfilledItem.qty, 10),
                0
            );
            const qtyRequested = orderLine.getQuantity();
            kitchenItem.qty = qtyRequested - qtyAlreadyFulfilled;

            // remove any lines that have already been fully fulfilled
            if (kitchenItem.qty <= 0) {
                continue;
            }

            let existingItem = this.containsItem(kitchenOrder, kitchenItem);
            if (existingItem) {
                existingItem.qty += kitchenItem.qty;
            } else {
                kitchenOrder.addItem(kitchenItem);
            }
        }

        return kitchenOrder;
    },

    /**
     * @param {DigiTickets.Order[]} orders
     *
     * @return {KitchenOrder[]}
     */
    mapMany(orders) {
        return orders.map(this.map.bind(this));
    },

    /**
     * Check if the given KitchenOrder already contains an equivalent KitchenItem. Returns that item if it does.
     * Equivalent means its the same itemID and the same answers to field data (anything the kitchen needs to
     * be unique).
     *
     * The kitchen is not concerned with offers etc. so lines split up due to offers get merged back together
     * for the kitchen.
     *
     * @param {KitchenOrder} kitchenOrder
     * @param {KitchenOrderItem} findItem
     *
     * @return {KitchenOrderItem|undefined}
     */
    containsItem(kitchenOrder, findItem) {
        return kitchenOrder.items.find((item) => item.hash && item.hash === findItem.hash && item.isFulfilled === false);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderToKitchenOrderMapper;
}
