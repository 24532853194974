const ItemInstance = require('../Orders/ItemInstance');
const OrderLine = require('../Orders/OrderLine');
const { cloneDeep } = require('../../../functions/clone');
const { createFieldGroupCollectionFromOrder } = require('../CustomFields/fieldGroupCollectionFactory');
const { shouldGiftAidCart } = require('../GiftAid/giftAid');

/**
 * @param {CartLineSplitter} cartLineSplitter
 * @param {Hydrator} hydrator
 * @param {PaymentService} paymentService
 * @param {UserService} UserService
 */
const CartToOrderMapper = function (
    cartLineSplitter,
    hydrator,
    paymentService,
    UserService
) {
    /**
     * @type {CartLineSplitter}
     */
    this.cartLineSplitter = cartLineSplitter;

    /**
     * @type {Hydrator}
     */
    this.hydrator = hydrator;

    /**
     * @type {PaymentService}
     */
    this.paymentService = paymentService;

    /**
     * @type {UserService}
     */
    this.userService = UserService;
};

CartToOrderMapper.prototype = {
    /**
     * @param {Cart} cart
     */
    map(cart) {
        let lines = cart.itemList.map((line) => line.clone());

        // Split up the lines where discounts are applied
        const splitResult = this.cartLineSplitter.splitDiscounts(lines);
        lines = splitResult.items;

        //
        // // Get the fields that should exist for the items in the order
        // var fields = this.fieldManager.createFieldGroupCollection(lines, true);
        //
        // // Flatten the field data (which has now been adjusted for the line splitting) back into the string keys
        // var fieldData = flatten(splitResult.fieldData, null, false);
        //
        // // Assign the field data to the appropriate instances in the FieldGroupCollection for the order
        // this.fieldManager.setValuesOfFieldGroupCollection(
        //     fields,
        //     fieldData,
        //     DigiTickets.FieldGroupingType.ALL_FIELDS
        // );
        //

        // When printing an order after making a partial payment, we need to include the original booking ref.
        let originalOrderHavingBalancePaid = cart.getOriginalOrderHavingBalancePaid();
        let bookingRef = originalOrderHavingBalancePaid ? originalOrderHavingBalancePaid.bookingRef : null;

        const order = new DigiTickets.Order();
        order.company = this.userService.getCompany();
        order.customer = cloneDeep(cart.customer);
        order.date = new Date();
        order.orderLevelFieldGroup = cloneDeep(cart.fields);
        order.giftAid = shouldGiftAidCart(cart);
        order.notes = cart.notes;
        order.paidAt = new Date();
        order.bookingRef = bookingRef;
        order.payments = this.paymentService.cleanPayments(cloneDeep(cart.payments));
        order.seller = this.createSeller();
        order.staffRef = cart.staffRef;
        order.stashedCartGuid = cart.stashedCartGuid;
        order.thirdPartyID = cart.thirdPartyID;
        order.thirdPartyRef = cart.thirdPartyRef;
        order.total = 0.00; // FIXME: total is not set here yet, it's still set by CartService.toOrder.

        // Add lines.
        lines.map((cartLine) => this.mapCartLineToOrderLine(cartLine))
            .forEach((orderLine) => order.items.push(orderLine));

        // Create FieldGroupCollection for backward compatibility.
        order.fields = createFieldGroupCollectionFromOrder(order);

        order.calculate();

        return order;
    },

    /**
     * @param {DigiTickets.CartItem} cartLine
     *
     * @return {OrderLine}
     */
    mapCartLineToOrderLine(cartLine) {
        const orderLine = new OrderLine();

        orderLine.baseTotal = cartLine.baseTotal;
        orderLine.branch = cloneDeep(this.userService.currentBranch);
        orderLine.category = cloneDeep(cartLine.item.category);
        orderLine.discount = cartLine.discounts.length > 0 ? cloneDeep(cartLine.discounts[0]) : null;
        orderLine.giftAid = cartLine.giftAid;
        orderLine.item = cloneDeep(cartLine.item);
        orderLine.itemID = cartLine.item.ID;
        orderLine.itemType = cartLine.item.itemType;
        orderLine.lineTotal = cartLine.lineTotal;
        orderLine.name = cartLine.getName();
        orderLine.subtitle = cartLine.subtitle;
        orderLine.people = cartLine.item.people;
        orderLine.price = cartLine.price;
        orderLine.qty = cartLine.quantity;
        orderLine.iteminstances = cloneDeep(cartLine.itemInstances);
        orderLine.absQty = Math.abs(cartLine.quantity);
        orderLine.donation = cartLine.donation;
        orderLine.lineNumber = cartLine.lineNumber; // FIXME OrderLine shouldn't have the concept of line numbers.
        orderLine.validityPeriod = cartLine.item.hasOwnProperty('validityPeriod') ? cartLine.item.validityPeriod : null;

        if (cartLine.session) {
            orderLine.event = cloneDeep(cartLine.item.event);
            orderLine.session = cloneDeep(cartLine.session);
        }

        return orderLine;
    },

    /**
     * @return {DigiTickets.Seller}
     */
    createSeller() {
        const seller = new DigiTickets.Seller();
        seller.ID = this.userService.getUserID();
        seller.usergroupID = this.userService.getUsergroupID();
        seller.username = this.userService.getUsername();

        return seller;
    },

    /**
     * Convert the current cart to an order, adding anything in 'additionalData' to the new Order.
     *
     * @param {CartService} cartService
     * @param {object} [apiResponse] The data returned by the API for the order store request. Used to augment
     *      the new Order model with additional properties.
     * @param {object} [additionalData] Any additional properties to be added to the new Order model.
     *
     * @return {DigiTickets.Order}
     */
    buildOrderFromApiConfirmation(
        cartService,
        apiResponse,
        additionalData
    ) {
        // TODO: Move this toOrder method to here instead of CartService.
        const newOrder = cartService.toOrder();

        // Add bookingRef to the order.
        if (apiResponse && apiResponse.order) {
            if (apiResponse.order.bookingRef) {
                newOrder.bookingRef = apiResponse.order.bookingRef;
            }

            // Add ID to the order.
            if (apiResponse.order.orderID) {
                newOrder.ID = apiResponse.order.orderID;
            }

            // Add transactionNumber to the order.
            if (apiResponse.order.transactionNumber) {
                newOrder.transactionNumber = apiResponse.order.transactionNumber;
            }

            // Add itemInstances to order lines.
            if (apiResponse.order.orderitems) {
                apiResponse.order.orderitems.forEach((partialOrderItem, lineIndex) => {
                    // FIXME: this is going to lose the field data!
                    newOrder.items[lineIndex].iteminstances = this.hydrator.hydrateArray(
                        partialOrderItem.iteminstances,
                        () => new ItemInstance()
                    );
                });
            }
        }

        if (typeof additionalData === 'object') {
            Object.assign(newOrder, additionalData);
        }

        return newOrder;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CartToOrderMapper;
}
