/**
 * Displays a {FieldGroupCollection} (supplied in the "fieldGroupCollection" attribute).
 */
const fieldsDirective = function () {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            fieldGroupCollection: '=',
            hideNoValue: '@', // Literal - true or false
            level: '@', // Literal - 'order' or 'item'
            lineNumber: '=',
            itemInstance: '='
        },
        link: function link($scope, element, attrs, ctrl, transclude) {
            let cloneElement = null;
            let cloneScope = null;

            $scope.$watchCollection('[fieldGroupCollection, hideNoValue, itemInstance, level, lineNumber]',
                function handleWatchChange(newValues, oldValues) {
                    if (cloneElement) {
                        cloneScope.$destroy();
                        cloneScope = null;
                        cloneElement.remove();
                        cloneElement = null;
                    }

                    if ($scope.fieldGroupCollection) {
                        if (newValues) {
                            cloneScope = $scope.$new();

                            $scope.fieldGroups = $scope.fieldGroupCollection.filterFieldGroups(
                                {
                                    hideNoValue: $scope.hideNoValue === 'true',
                                    level: $scope.level,
                                    lineNumber: $scope.lineNumber,
                                    itemInstance: $scope.itemInstance
                                }
                            );

                            // Allows child <dt-field-instance> elements to use the scope of this directive
                            // (so that $scope.instance exists for them)
                            cloneElement = transclude(
                                cloneScope,
                                function injectClonedElement(clone) {
                                    element.html('');
                                    element.append(clone);
                                }
                            );
                        }
                    }
                });
        },
        template: '<div ng-transclude></div>'
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = fieldsDirective;
}
