const { toInt } = require('../functions/transform');

DigiTickets.Event = function () {
    this.ID = null;
    this.name = '';
    this.navOrder = 10000;
    this.sessionType = '';
    this.staffSalesCutoffPointAfterSessionStart = 'session_start'; // Session Start is the default.
    this.staffSalesCutoffTime = null;
};

DigiTickets.Event.prototype = {
    getHydrationMap() {
        return {
            generatePerPersonBarCode: {},
            ID: {
                field: ['ID', 'eventCatID'],
                transform: toInt
            },
            name: {},
            navOrder: toInt,
            sessionType: {},
            staffSalesCutoffPointAfterSessionStart: {},
            staffSalesCutoffTime: {}
        };
    }
};
