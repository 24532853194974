const angular = require('angular');
const CustomerDetailsCtrl = require('../../../js/controllers/modal/CustomerDetailsCtrl');
const ItemEditableStatus = require('../../../js/libraries/DigiTickets/Orders/ItemEditableStatus');
const mapOrderToCart = require('../../../js/libraries/DigiTickets/Mappers/mapOrderToCart');
const MembershipManagementCtrl = require('../../../js/controllers/MembershipManagementCtrl');
const OrderBalanceItem = require('../../../js/libraries/DigiTickets/Orders/OrderBalanceItem');
const OrderPaymentsCtrl = require('../../../js/controllers/modal/OrderPaymentsCtrl');
const PayOrderBalanceModalCtrl = require('../../../js/controllers/modal/PayOrderBalanceModalCtrl');
const PrivilegeNames = require('@/libraries/DigiTickets/Privileges/PrivilegeNames');
const { cloneDeep } = require('../../../js/functions/clone');

/**
 * @param $filter
 * @param $location
 * @param $modal
 * @param $routeParams
 * @param $scope
 * @param $timeout
 * @param {AutoRedemptionStrategy} autoRedemptionStrategy
 * @param {CartService} cartService
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.RedemptionManager} MembershipManagementActionsService
 * @param MembershipResource
 * @param {MembershipService} membershipService
 * @param {ModalFactory} ModalFactory
 * @param {NavigationService} navigationService
 * @param Notification
 * @param {OrderManager} OrderManagerService
 * @param {OrderReceiptPrinter} orderReceiptPrinter
 * @param {OrderSearchCache} orderSearchCache
 * @param {OrderService} orderService
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param RedemptionManagerService
 * @param RedemptionResource
 * @param {SearchContext} searchContext
 * @param {SellStateService} sellStateService
 * @param {UserService} UserService
 */
const OrderDetailCtrl = function (
    $filter,
    $location,
    $modal,
    $routeParams,
    $scope,
    $timeout,
    autoRedemptionStrategy,
    cartService,
    ConnectivityChecker,
    CurrentDevice,
    Logger,
    MembershipManagementActionsService,
    MembershipResource,
    membershipService,
    ModalFactory,
    navigationService,
    Notification,
    OrderManagerService,
    orderReceiptPrinter,
    orderSearchCache,
    orderService,
    PrivilegesService,
    ReceiptPrinterService,
    RedemptionManagerService,
    RedemptionResource,
    searchContext,
    sellStateService,
    UserService
) {
    navigationService.showNav();

    /**
     * $filter is used in the template.
     */
    $scope.$filter = $filter;

    $scope.showLoader = true;
    $scope.showNotFound = false;
    $scope.modalClosing = false;

    /**
     * @type {DigiTickets.Order}
     */
    $scope.currentOrder = null;

    /**
     * @type {boolean}
     */
    $scope.wasRecentlyAdjusted = false;

    $scope.membershipActions = MembershipManagementActionsService;

    $scope.isOnline = ConnectivityChecker.online;
    ConnectivityChecker.addObserver({
        key: 'OrderDetailCtrl',
        notifyOnline: function notifyOnline() {
            $scope.isOnline = true;
        },
        notifyOffline: function notifyOffline() {
            $scope.isOnline = false;
        }
    });

    $scope.device = CurrentDevice.device;

    $scope.uiState = {
        isPrinting: false
    };

    /**
     * @param {DigiTickets.Order} order
     * @param {boolean} [wasBarcodeScan]
     */
    const setCurrentOrder = (order, wasBarcodeScan) => {
        $scope.currentOrder = order;
        $scope.wasRecentlyAdjusted = !!$location.search().adjusted;

        $scope.fieldGroupByItemInstances();
        $scope.redirectIfInstanceScanned();

        if (wasBarcodeScan) {
            $scope.checkAutoRedeem();
        }

        $scope.selectDefaultGroup();
    };

    $scope.loadOrder = function loadOrder(ref, wasBarcodeScan) {
        $scope.currentGroup = null;
        $scope.showNotFound = false;
        $scope.showLoader = true;
        $scope.currentOrder = null;

        // If an order has been requested by id, see if it's in the search results cache.
        // If it is, just use the cached value. Otherwise get the order from the DB.
        // If they have just renewed one or more memberships, we need to force a reload, hence the "r"
        // parameter.
        let cachedOrder = orderSearchCache.getOrderFromCache(ref);
        if (!$location.search().hasOwnProperty('r') && cachedOrder) {
            setCurrentOrder(cachedOrder, wasBarcodeScan);
            $scope.showLoader = false;

            return;
        }
        // The order is not in the cache (or we didn't look!), so something unusual has
        // happened, like the user requesting the order without going through the search.
        // In this case, just clear the cache, otherwise there might be strange effects.
        orderSearchCache.clear();

        // ref can actually be an orderID or a bookingRef, as the API supports both
        OrderManagerService.get(ref,
            function (order) {
                setCurrentOrder(order, wasBarcodeScan);
                $scope.showLoader = false;
            },
            function () {
                $scope.showNotFound = true;
                $scope.showLoader = false;
            });
    };

    // Process the bar code if scanned.
    $scope.$on('barcodeScanned', function (event, response) {
        if ($scope.modalClosing === false) {
            let barCode = response.code;
            if (barCode.length > 0) {
                $scope.loadOrder(barCode, true);
            }
        }

        $scope.modalClosing = false;
    });

    /**
     * Is the edit order button visible?
     *
     * The button should only be displayed for companies who have order editing enabled in the Back Office.
     * When the button is clicked the edit_orders permission applies.
     *
     * @type {boolean}
     */
    $scope.showEditBtn = UserService.currentUser.company.enableOrderEditing;

    /**
     * @param {DigiTickets.Order} order
     */
    $scope.edit = function (order) {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.EDIT_ORDERS)
            .then(() => {
                let cart = mapOrderToCart(order);
                cart.isEditingOrder = true;

                // Setup where to send the user after it has been modified.
                cart.sellState = new DigiTickets.SellState();

                cart.sellState.returnPath = '/orders/' + order.bookingRef + '/false';

                cart.sellState.successReturnPath = '/orders/' + order.bookingRef + '/false';
                cart.sellState.successReturnPathQuery = {
                    adjusted: 1
                };

                cartService.nextCart = cart;
                navigationService.viewSell();
            });
    };

    // ============================================================================================================
    // DETAILS STATE
    // ============================================================================================================

    $scope.itemPagination = {
        pageSize: 5,
        currentPage: 1
    };

    /**
     * @type {DigiTickets.OrderItemGroup}
     */
    $scope.currentGroup = null;

    /**
     * Redeem every redeemable item in the current group.
     */
    $scope.redeemGroup = function redeemGroup() {
        $scope.currentGroup.items.forEach(function (orderLine) {
            if (orderLine.hasInstancePerTicket()) {
                orderLine.iteminstances.forEach(function (itemInstance) {
                    if (itemInstance.netRedemptions === 0) {
                        $scope.redeemItemInstance(itemInstance);
                    }
                });
            } else if (orderLine.redeemed < orderLine.qty) {
                $scope.redeemAllRemainingOnLine(orderLine);
            }
        });
    };

    /**
     * Unredeem every redeemed item in the current group. If the operator does not have permission prompt for approval.
     */
    $scope.unredeemGroupCheck = () => {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_TICKETS)
            .then(() => {
                unredeemGroup($scope.currentGroup.items);
            });
    };

    /**
     * Unredeem every redeemed item in the current group.
     *
     * @param {OrderLine[]} orderLines
     */
    const unredeemGroup = function unredeemGroup(orderLines) {
        orderLines.forEach(function (orderLine) {
            if (orderLine.hasInstancePerTicket()) {
                orderLine.iteminstances.forEach(function (itemInstance) {
                    if (itemInstance.netRedemptions > 0) {
                        unredeemItemInstance(itemInstance);
                    }
                });
            } else if (orderLine.redeemed >= orderLine.qty) {
                unredeemAllOnLine(orderLine);
            }
        });
    };

    /**
     * Redeem all the remaining possible redemptions of a single line.
     * (line qty - already redeemed qty)
     *
     * @param {OrderLine} orderLine
     * @param {string} [method] Manual or Automatic redemption?
     */
    $scope.redeemAllRemainingOnLine = function redeemAllRemainingOnLine(orderLine, method) {
        let qty = parseInt(orderLine.getQuantity(), 10) - parseInt(orderLine.getRedemptionCount(), 10);
        redeemOrderLine(orderLine, qty, method);
    };

    /**
     * Undo all the redemptions of a single line.
     * (unredeems the qty already redeemed)
     *
     * @param {OrderLine} orderLine
     */
    const unredeemAllOnLine = function unredeemAllOnLine(orderLine) {
        let qty = parseInt(orderLine.getRedemptionCount(), 10);
        redeemOrderLine(orderLine, -qty);
    };

    /**
     * Unredeem the line. If the operator does not have permission then prompt for supervisor approval.
     *
     * @param {OrderLine} orderLine
     */
    $scope.unredeemAllOnLineCheck = (orderLine) => {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_TICKETS)
            .then(() => {
                unredeemAllOnLine(orderLine);
            });
    };

    /**
     * Redeem one instance on a line.
     *
     * @param {OrderLine} orderLine
     */
    $scope.redeemOne = function redeemOne(orderLine) {
        redeemOrderLine(orderLine, +1);
    };

    /**
     * Unredeem one instance on a line.
     *
     * @param {OrderLine} orderLine
     */
    $scope.unredeemOne = (orderLine) => {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_TICKETS)
            .then(() => {
                redeemOrderLine(orderLine, -1);
            });
    };

    const redeemOrderLine = function (orderLine, delta, method) {
        let newTotal = parseInt(orderLine.getRedemptionCount(), 10) + delta;
        if (newTotal < 0 || newTotal > parseInt(orderLine.getQuantity(), 10)) {
            return;
        }

        RedemptionManagerService.adjustRedemptionCount(
            $scope.currentOrder,
            orderLine,
            delta,
            method,
            orderSearchCache.onlineResult
        );
    };

    $scope.selectDefaultGroup = function selectDefaultGroup() { // can extend this to be more clever if needed
        $scope.currentGroup = $scope.currentOrder.itemGroups[Object.keys($scope.currentOrder.itemGroups)[0]];
    };

    $scope.selectGroup = function selectGroup(index) {
        $scope.itemPagination.currentPage = 1;
        $scope.currentGroup = $scope.currentOrder.itemGroups[index];
    };

    $scope.print = function print() {
        $scope.uiState.isPrinting = true;

        // To make sure the receipt is marked as a duplicate.
        $scope.currentOrder.receiptPrinted = true;
        orderReceiptPrinter.printOrderReceipt($scope.currentOrder)
            .finally(() => {
                $timeout(() => {
                    $scope.uiState.isPrinting = false;
                });
            });
    };

    /**
     * Can we print tickets for this order?
     * This controls whether or not we show the 'Print Individual Tickets' and 'Print Group Tickets' buttons.
     *
     * @returns {boolean}
     */
    $scope.canPrintTickets = () => ReceiptPrinterService.canPrintTicketsForOrder($scope.currentOrder);

    $scope.printIndividualTickets = function printIndividualTickets() {
        $scope.uiState.isPrinting = true;
        $timeout(function () {
            ReceiptPrinterService.printIndividualTickets(
                $scope.currentOrder,
                function () {
                    $scope.uiState.isPrinting = false;
                },
                $scope.device.printTickets // Does the device have a souvenir (Boca) printer attached?
            );
        });
    };

    $scope.printGroupTickets = function printGroupTickets() {
        $scope.uiState.isPrinting = true;
        $timeout(function () {
            ReceiptPrinterService.printGroupTickets(
                $scope.currentOrder,
                function () {
                    $scope.uiState.isPrinting = false;
                },
                $scope.device.printTickets // Does the device have a souvenir (Boca) printer attached?
            );
        });
    };

    $scope.email = function email() {
        if ($scope.currentOrder.customer.hasEmail()) {
            // Already have the customer's email address
            orderService.emailReceipt($scope.currentOrder);
            // FIXME: Stop using jQuery here and just add a 'receiptEmailed' property to the Order.
            setTimeout(function () {
                $('#order-details .email-receipt-btn').removeClass('btn-info').addClass('btn-success');
            }, 200);
        } else {
            // Pop up details modal
            let errorMsg = "Please enter the customer's email address to send a receipt.";
            $scope.showCustomerDetails(errorMsg).then((data) => {
                if (data && data.customer) {
                    // Send the updated customer info back to the server
                    orderService.updateOrder($scope.currentOrder, {
                        customer: data.customer,
                        customerAccountID: data.customer.account ? data.customer.account.ID : null
                    });

                    if (data.customer.hasEmail()) {
                        $scope.email();
                    }
                }
            });
        }
    };

    $scope.showPayments = function showPayments() {
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/orderPayments.html',
            controller: OrderPaymentsCtrl,
            // static backdrop prevents modal being closed by clicking on background
            backdrop: 'static',
            resolve: {
                payments: function cart() {
                    return $scope.currentOrder.payments;
                }
            },
            windowClass: 'in order-payments-window'
        });

        modalInstance.result.then(
            function () {
                // OK
            },
            function () {
                // Cancel
            }
        );

        return modalInstance;
    };

    $scope.payBalance = function () {
        ModalFactory.open(
            'pay-balance',
            PayOrderBalanceModalCtrl,
            'partials/modals/payOrderBalance.html',
            {
                balance: () => $scope.currentOrder.remainingBalance
            }
        ).then((response) => {
            if (response.amount > 0) {
                cartService.clear();

                let balanceItem = new OrderBalanceItem();
                balanceItem.setPrice(response.amount);
                balanceItem.setOriginalOrder($scope.currentOrder);

                let cartItem = new DigiTickets.CartItem(null, balanceItem);
                cartItem.customPrice = response.amount;
                cartItem.setCanAdjustQuantity(false);

                let sellState = new DigiTickets.SellState();
                sellState.addInitialItem(cartItem, 1);
                sellState.payNow = true;
                sellState.preventAdditional = 'SELL.CANNOT_ADD_WHEN_PAYING_BALANCE';
                sellState.returnPath = '/orders/' + $scope.currentOrder.bookingRef + '/false';
                sellStateService.sellWithState(sellState);
            }
        });
    };

    /**
     * @param {string} [errorMessage]
     *
     * @return {Promise<{customer: Customer}>}
     */
    $scope.showCustomerDetails = function (errorMessage) {
        return ModalFactory.open(
            'customer-details',
            CustomerDetailsCtrl,
            'partials/modals/customerDetails.html',
            {
                customer: () => cloneDeep($scope.currentOrder.customer),
                errorMsg: () => errorMessage
            }
        ).then((result) => {
            $scope.currentOrder.customer = result.customer;

            return result;
        });
    };

    $scope.autoRedemptionState = null;

    $scope.checkAutoRedeem = function checkAutoRedeem() {
        let order = $scope.currentOrder;
        let autoRedeemProfile = UserService.currentUser.company.getAutoRedeemProfile();
        if (autoRedeemProfile && autoRedemptionStrategy.orderCanBeRedeemed(order, autoRedeemProfile)) {
            $scope.redeemAllRemainingOnLine($scope.currentOrder.items[0], 'Automatic');
            $scope.autoRedemptionState = 'redeemed';
        } else if (order.redeemed) {
            $scope.autoRedemptionState = 'already-redeemed';
        } else if (order && order.containsTickets()) {
            $scope.autoRedemptionState = 'failed';
        } else {
            $scope.autoRedemptionState = 'not applicable';
        }
    };

    $scope.itemInstancesCollection = {};

    $scope.itemInstanceSelection = function itemInstanceSelection(orderItemID) {
        $scope.itemInstancesCollection = {};
        let order = $scope.currentOrder;
        angular.forEach(order.items, function (orderItem) {
            angular.forEach(orderItem.iteminstances, function (itemInstance) {
                if (itemInstance.itemInstanceRef != null && orderItem.ID == orderItemID) {
                    $scope.itemInstancesCollection[itemInstance.ID] = itemInstance;
                }
            });
        });

        return $scope.itemInstancesCollection;
    };

    $scope.fieldGroupByItemInstancesCollection = {};

    $scope.fieldGroupByItemInstances = function fieldGroupByItemInstances() {
        // FIXME: Is this a bug? It iterates like an array but docblock says it's a FieldGroupCollection
        angular.forEach($scope.currentOrder.fields, function (fields) {
            angular.forEach(fields, function (itemFields) {
                if (itemFields.itemInstanceID != null) {
                    $scope.fieldGroupByItemInstancesCollection[itemFields.itemInstanceID] = itemFields;
                }
            });
        });
    };

    $scope.redirectIfInstanceScanned = function redirectIfInstanceScanned() {
        angular.forEach(
            $scope.currentOrder.items,
            function (item) {
                angular.forEach(
                    item.iteminstances,
                    (itemInstance) => {
                        if (itemInstance.itemInstanceRef === OrderManagerService.getSearchedBarcode()) {
                            searchContext.orderItem = item;
                            searchContext.itemInstance = itemInstance;
                            searchContext.currentOrder = $scope.currentOrder;

                            navigationService.viewInstanceRedeem(itemInstance.itemInstanceRef);
                            return true;
                        }

                        return false;
                    }
                );
            }
        );
    };

    /**
     * @param {ItemInstance} itemInstance
     * @return {boolean}
     */
    $scope.instanceHasValidFields = function instanceHasValidFiends(itemInstance) {
        if (!$scope.fieldGroupByItemInstancesCollection[itemInstance.ID]) {
            return false;
        }

        let hasValidFields = false;
        angular.forEach(
            $scope.fieldGroupByItemInstancesCollection[itemInstance.ID].instances,
            function (instance) {
                if (instance.hasValue && !instance.error) {
                    hasValidFields = true;
                }
            }
        );

        return hasValidFields;
    };

    $scope.itemHasValidFields = function itemHasValidFields(orderItem) {
        let hasValidFields = false;

        angular.forEach(orderItem.iteminstances, function (iteminstance) {
            if ($scope.fieldGroupByItemInstancesCollection[iteminstance.ID] !== undefined) {
                angular.forEach(
                    $scope.fieldGroupByItemInstancesCollection[iteminstance.ID].instances,
                    function (instance) {
                        if (instance.hasValue && !instance.error) {
                            hasValidFields = true;
                        }
                    }
                );
            }
        });

        return hasValidFields;
    };

    /**
     * Redeem/unredeem a single item instance.
     *
     * @param {ItemInstance} itemInstance
     * @param {number} qty 1 if redeeming, -1 if unredeeming.
     */
    const redeemItemInstance = function (itemInstance, qty) {
        if (qty > 0) {
            itemInstance.setRedemptionInProgress();
        } else if (qty < 0) {
            itemInstance.setUnredemptionInProgress();
        }

        RedemptionResource.redeem(
            {
                qty,
                ref: itemInstance.itemInstanceRef,
                type: 'iteminstance'
            },
            function (data) {
                if (data.success) {
                    itemInstance.setNetRedemptions(data.newRedemptionCount);
                } else {
                    Notification.error('Item can not be redeemed ' + data.message);
                }
                itemInstance.clearRedemptionInProgress();
            },
            function (response) {
                if (response.data.message) {
                    Notification.error('Item can not be redeemed ' + response.data.message);
                }
                itemInstance.clearRedemptionInProgress();
            }
        );
    };

    $scope.redeemItemInstance = function (itemInstance) {
        redeemItemInstance(itemInstance, +1);
    };

    $scope.unredeemItemInstanceCheck = function (itemInstance) {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_TICKETS)
            .then(() => {
                unredeemItemInstance(itemInstance);
            });
    };

    const unredeemItemInstance = function (itemInstance) {
        redeemItemInstance(itemInstance, -1);
    };

    $scope.isMembershipGroup = function isMembershipGroup() {
        return $scope.currentGroup && $scope.currentGroup.id === DigiTickets.ItemType.MEMBERSHIP_PLAN;
    };

    $scope.isGroupRedeemable = function isGroupRedeemable() {
        const nonRedeemableGroups = [
            DigiTickets.ItemType.GENERIC,
            DigiTickets.ItemType.MEMBERSHIP_PLAN,
            DigiTickets.ItemType.PRODUCT,
            DigiTickets.ItemType.GIFT_VOUCHER,
        ];

        return $scope.currentGroup && nonRedeemableGroups.indexOf($scope.currentGroup.id) === -1;
    };

    /**
     * @return {boolean}
     */
    $scope.isRedeemGroupInProgress = function isRedeemGroupInProgress() {
        return !!$scope.currentGroup.items.find((orderLine) => {
            return orderLine.redemptionIsInProgress()
                || !!orderLine.iteminstances.find((itemInstance) => itemInstance.redemptionIsInProgress());
        });
    };

    // Is the action a redemption or an unredemption?
    // Used to ascertain whether we show the 'Redeem All Available' or 'Unredeem All Available' buttons.
    $scope.isRedemption = function isRedemption() {
        for (let i = 0; i < $scope.currentGroup.items.length; i++) {
            let orderLine = $scope.currentGroup.items[i];
            if (orderLine.hasInstancePerTicket()) {
                if (orderLine.summaryItemInstanceNetRedemptions() < orderLine.qty) {
                    return true;
                }
            } else if (orderLine.redeemed < orderLine.qty) {
                return true;
            }
        }

        return false;
    };

    /**
     * Is this order editable?
     * Returns true if any of the lines in the order are editable.
     *
     * @return {boolean}
     */
    $scope.canEditOrder = () => !!$scope.currentOrder.items.find(
        (orderLine) => orderLine.getEditableStatus() === ItemEditableStatus.IS_EDITABLE
    );

    // This has to be after all the functions are defined because otherwise sometimes
    // they can be called before the browser has parsed them.
    let wasBarcodeScan = ($routeParams.wasBarcodeScan === 'true');
    $scope.loadOrder($routeParams.bookingRef, wasBarcodeScan);

    $scope.clearRenewingAndViewMembership = function clearRenewingAndViewMembership(membershipRef, currentGroup) {
        // Clear any plans that are renewing - avoids confusion if they start trying to
        // renew *this* one, and then come back.
        currentGroup.clearMembershipRenewing();
        navigationService.viewMembershipDetails(membershipRef);
    };

    /**
     * Include Membership management methods.
     */
    const membershipManagementCtrl = new MembershipManagementCtrl(
        $location,
        $modal,
        cartService,
        Logger,
        MembershipResource,
        Notification,
        RedemptionResource
    );

    /**
     * @param {DigiTickets.Membership} membership
     * @param {DigiTickets.MembershipPlan} membershipPlan
     */
    $scope.editMembership = function editMembership(membership, membershipPlan) {
        let dataset = membership.toMembershipDataset();
        MembershipManagementActionsService.editDatasets(
            'edit-existing',
            membershipPlan,
            [dataset],
            membership.customerAccount,
            function (modifiedDatasets) {
                if (modifiedDatasets) {
                    membershipService.updateMembership(
                        membership,
                        modifiedDatasets[0]
                    );
                }
            }
        );
    };

    $scope.logVisit = function logVisit(membership, membershipSubscription, orderItem, membershipPlan) {
        return membershipManagementCtrl.logVisit(membership, membershipSubscription, orderItem, membershipPlan);
    };

    $scope.unlogVisit = function unlogVisit(membership, membershipSubscription, orderItem, membershipPlan) {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_MEMBERSHIPS)
            .then(() => {
                return membershipManagementCtrl.unlogVisit(
                    membership,
                    membershipSubscription,
                    orderItem,
                    membershipPlan
                );
            });
    };

    $scope.postHandleSingleMemberVisit = function postHandleSingleMemberVisit(membership) {
        if (membership.members.length === 1) {
            Notification.success({
                message: 'Member visit logged',
                delay: 2000
            });
            navigationService.viewRedeem();
        }
    };

    $scope.afterMemberVisitChange = function afterMemberVisitChange(membership) {
        $scope.postHandleSingleMemberVisit(membership);
    };

    $scope.toggleMemberVisit = function toggleMemberVisit(member, membershipSubscription, orderItem, membershipPlan) {
        PrivilegesService
            .requirePrivilege(PrivilegeNames.ALLOW_UNREDEEM_MEMBERSHIPS)
            .then(() => {
                membershipManagementCtrl.updateMemberVisits(
                    member,
                    membershipSubscription,
                    orderItem,
                    membershipPlan,
                    member.hasVisitedToday() ? -1 : 1,
                    $scope.afterMemberVisitChange.bind($scope, membershipSubscription.membership)
                );
            });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderDetailCtrl;
}
