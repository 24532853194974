const ItemInstance = require('../Orders/ItemInstance');
const { toNullableBool, toDate, toNullableFloat, toInt } = require('../../../functions/transform');

const MembershipSubscription = function () {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * @type {Date|null}
     */
    this.startDate = null;

    /**
     * @type {Date|null}
     */
    this.endDate = null;

    /**
     * @type {Date|null}
     */
    this.nextPaymentDueAt = null;

    /**
     * @type {float|null}
     */
    this.installmentAmount = null;

    /**
     * Is this the current/active subscription for the associated membership?
     *
     * @type {boolean|null}
     */
    this.isCurrent = null;

    /**
     * Used when changing an installment amount.
     *
     * @type {float|null}
     */
    this.newInstallmentAmount = null;

    /**
     * @type {?ItemInstance}
     */
    this.itemInstance = null;

    /**
     * @type {number}
     */
    this.maxRedemptions = 0;

    /**
     * @type {DigiTickets.Membership|null}
     */
    this.membership = null;

    /**
     * @type {DigiTickets.MembershipPlan|null}
     */
    this.membershipPlan = null;

    /**
     * @type {number}
     */
    this.visitCount = 0;

    /**
     * @type {DigiTickets.SubscriptionPayment[]}
     */
    this.subscriptionPayments = [];
};

MembershipSubscription.prototype = {
    getHydrationMap() {
        return {
            endDate: toDate,
            ID: {
                field: ['ID', 'membershipSubscriptionID'],
                transform: toInt
            },
            installmentAmount: toNullableFloat,
            isCurrent: toNullableBool,
            itemInstance: {
                field: ['itemInstance', 'iteminstances'],
                model: ItemInstance
            },
            maxRedemptions: toInt,
            membership: {
                field: ['membership', 'memberships'],
                model: DigiTickets.Membership
            },
            membershipPlan: {
                field: ['membershipPlan', 'membershipplans'],
                model: DigiTickets.MembershipPlan
            },
            nextPaymentDueAt: toDate,
            startDate: toDate,
            subscriptionPayments: {
                modelCollection: DigiTickets.SubscriptionPayment
            },
            visitCount: toInt
        };
    },

    /**
     * @return {boolean}
     */
    hasInstallments() {
        return this.installmentAmount > 0;
    },

    /**
     * @return {number}
     */
    getVisits() {
        return parseInt(this.visitCount, 10);
    },

    /**
     * @param {number} newVisitCount
     */
    setVisits(newVisitCount) {
        this.visitCount = newVisitCount;
    },

    /**
     * Method to check whether the number of logged visits is currently at, or will
     * be at, the maximum allowed.
     * If no value is passed in, the current number of visits is assumed.
     * A max redemptions value of zero is special - it means unlimited.
     *
     * @param {?number} [newVisitCount]
     *
     * @return {boolean}
     */
    isAtMaximumVisits(newVisitCount) {
        if (this.maxRedemptions === 0) {
            return false;
        }

        if (newVisitCount === undefined) {
            // Is the current number of visits at or over the maximum allowed?
            return (this.visitCount >= this.maxRedemptions);
        }

        // Will the new number of visits exceed the maximum allowed?
        return (newVisitCount > this.maxRedemptions);
    },

    isActive() {
        let now = new Date();
        // Membership subscriptions have start and end *dates*, which don't include a time component.
        now.setHours(0, 0, 0, 0);
        return this.startDate <= now && this.endDate >= now;
    },

    /**
     * Get a list of Members on this membership.
     *
     * @returns {DigiTickets.Member[]}
     */
    getMembers() {
        return this.membership ? this.membership.getMembers() : [];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipSubscription;
}
