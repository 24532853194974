/**
 * On receipt of this integrated function, POS Client will submit all offline transactions and respond
 * with a message informing the operator of the outcome.
 * N.B. if the terminal is offline when OLS is sent, then a reconnection attempt will be made.
 * If this fails, an error message of -87 will be returned to inform the POS of this result.
 * The command can be followed by NOMSG, which will result in the terminal remaining minimised during submission,
 * e.g. ‘OLS,NOMSG’.
 */
const VerifoneSubmitOfflineTransactionsRecord = function () {
};

VerifoneSubmitOfflineTransactionsRecord.prototype = {
    toArray() {
        return [
            'OLS', // 1: Message Type
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneSubmitOfflineTransactionsRecord;
}
