DigiTickets.CategorisedItemCollection = function () {
    /**
     * @type {Category[]}
     */
    this.categories = [];

    /**
     * @type {Object<AbstractItem[]>}
     */
    this.items = {};

    /**
     * @type {boolean}
     */
    this.hasCategories = false;
};

DigiTickets.CategorisedItemCollection.prototype = {

};
