const _ = require('lodash');

/**
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 * @param privilegesManager
 * @param requestReason
 */
const PinRequestCtrl = function PinRequestCtrl(
    $modalInstance,
    $scope,
    $timeout,
    Logger,
    NumPadFactory,
    privilegesManager,
    requestReason
) {
    $scope.numPad = NumPadFactory.create(
        {
            allowNull: true,
            initialValue: null,
            showDecimalButton: false,
            showSignButton: false,
            numDecimalPlaces: 0,
            ok: function () {
                // The timeout is to allow the RFID handler to pickup the keydowns first if appropriate.
                $timeout(function () {
                    let pin = $scope.numPad.getValue();
                    if (pin) {
                        $scope.debouncedHandleSwitchUserAuth('pin', String(pin));
                    }
                }, 10);
            },
            cancel: function () {
                $scope.cancel();
            }
        }
    );

    $scope.pin = {
        focus: false,
        requestReason,
        valid: true,
        message: 'Sorry, either the PIN/Tag is invalid or the user does not have sufficient privileges.'
    };

    $scope.ok = function ok() {
        let pin = String($scope.numPad.getValue());
        $scope.debouncedHandleAuthAction('pin', pin);
    };

    // Check for a RFID tag being scanned. It attempts to authorise the action using that Tag.
    $scope.$on('RfidTagScanned', function (event, response) {
        // Similarly to above, we only look for a matching RFID tag, and not a PIN.
        $scope.numPad.setAmount(response.code);
        $scope.debouncedHandleAuthAction('rfid-tag', response.code);
    });

    /**
     * Method to attempt to authorise the action via the user identified by the given
     * method and security value (eg PIN and '1122').
     *
     * @param {string} method - 'pin', 'rfid-tag', plus any future values.
     * @param {string} enteredValue The PIN, Tag Id, etc.
     */
    $scope.handleAuthAction = function handleAuthAction(method, enteredValue) {
        let elevatedUserID = privilegesManager.grantTemporaryPrivilege(method, enteredValue);
        if (elevatedUserID) {
            $scope.pin.valid = true;
            $modalInstance.close(elevatedUserID);
        } else {
            Logger.info('Invalid authentication info given (' + method + ')');

            $scope.pin.valid = false;
            $scope.setFocus();
        }
    };

    $scope.debouncedHandleAuthAction = _.debounce($scope.handleAuthAction, 100);

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * Method to set the flag that says "focus on the PIN field in the modal"
     * for a short time. The dtFocusMe directive and the attribute on the field
     * combine to set focus. We have to set it back to false just after so that
     * we can trigger it again (eg when they click Ok and the PIN is invalid).
     */
    $scope.setFocus = function setFocus() {
        $scope.pin.focus = true;
        $timeout(function () {
            $scope.pin.focus = false;
        });
    };

    $scope.setFocus();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PinRequestCtrl;
}
