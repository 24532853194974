const clockDirective = function ($interval) {
    return {
        restrict: 'E',
        template: function template($element) {
            if ($element.hasClass('time-only')) {
                return '{{ date | date : "hh:mm a" }}';
            } else if ($element.hasClass('dev-clock')) {
                return '{{ date | date : "yyyy-MM-dd HH:mm" }}';
            }
            return '{{ date | date : "EEE dd MMM" }} {{ date | date : "hh:mm a"}}';
        },
        controller: function controller($scope) {
            $scope.date = Date.now();
            $interval(function () {
                $scope.date = Date.now();
            }, 4000);
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = clockDirective;
}
