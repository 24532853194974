const angular = require('angular');

const toArrayFilter = function (obj) {
    let result = [];
    angular.forEach(obj, function (val) {
        result.push(val);
    });
    return result;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = toArrayFilter;
}
