const _ = require('lodash');
const angular = require('angular');
const SessionSpaceCalculator = require('../../libraries/DigiTickets/Sessions/SessionSpaceCalculator');

/**
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {DigiTickets.Event} event
 * @param {Object<Session>} eventSessionPreference
 * @param {Hydrator} hydrator
 * @param {{ticket: DigiTickets.Ticket, qty: Number}[]} quantities
 * @param SessionAvailabilityResource
 * @param {SessionManager} SessionManager
 * @param SessionPriceResource
 * @param {Object<number>} sessionSpaceAdjustments Manual additions/subtractions to session availability or the
 *     the total quantity of tickets selected for an existing session when a session is being moved.
 *     See sessionSpaceAdjustments in SessionSpaceCalculator for more info.
 * @param {UserService} UserService
 */
const SessionPickerCtrl = function SessionPickerCtrl(
    $modalInstance,
    $scope,
    $timeout,
    cartService,
    event,
    eventSessionPreference,
    hydrator,
    quantities,
    SessionAvailabilityResource,
    SessionManager,
    SessionPriceResource,
    sessionSpaceAdjustments,
    UserService
) {
    $scope.event = event;
    $scope.numTickets = quantities.reduce((total, { qty }) => total + qty, 0);
    $scope.spacesRequired = quantities.reduce((total, { ticket, qty }) => total + (qty * ticket.people), 0);

    /**
     * ticketLines forms the basis of what we'll eventually return once the modal is closed.
     * It contains all the information needed to add the tickets to the cart (bar the session,
     * which is held in $scope.selectedSession).
     *   [
     *     { ticket, qty, price },
     *     { ticket, qty, price },
     *     ...etc
     *   ]
     */
    let ticketLines = quantities.map(({ ticket, qty }) => ({
        ticket,
        qty,
        price: ticket.getPrice()
    }));

    $scope.pagination = {
        currentPage: 1,
        pageSize: 5
    };

    $scope.sessionElementHeight = 57; // How tall is one session in the list
    $scope.pageHeight = $scope.sessionElementHeight * $scope.pagination.pageSize; // How tall is one page of sessions
    $scope.scrollerPadding = 0;

    /**
     * Sessions for the currently selected day.
     *
     * @type {Session[]}
     */
    $scope.daySessions = [];

    /**
     * Are there Inactive sessions for the current day? Used to toggle visibility of the key on the session picker.
     *
     * @type {boolean}
     */
    $scope.dayHasInactiveSessions = false;

    /**
     * All sessions for the event.
     *
     * @type {SessionCalendar}
     */
    $scope.sessionCalendar = {};

    /**
     * @type {?Session}
     */
    $scope.selectedSession = null;

    $scope.showLoader = true;

    $scope.datepicker = {
        /**
         * The currently selected date in the calendar.
         * Set it to today initially.
         *
         * @type {Date}
         */
        date: new Date()
    };

    $scope.dateOptions = {
        'year-format': '\'yy\'',
        'starting-day': 1 // Weeks start on Monday
    };

    /**
     * Minimum date that can be selected in the datepicker.
     * This is always set to the current date.
     *
     * @type {Date}
     */
    $scope.minDate = new Date();

    /**
     * Today's date. Used for jumping to today and displaying the 'Today' button as active when appropriate.
     *
     * @type {Date}
     */
    $scope.todaysDate = new Date();
    $scope.sessionsAvailableToday = null;

    /**
     * Tomorrow's date. Used for jumping to tomorrow and displaying the 'Tomorrow' button as active when appropriate.
     *
     * @type {Date}
     */
    $scope.tomorrowsDate = new Date();
    $scope.tomorrowsDate.setDate($scope.tomorrowsDate.getDate() + 1);
    $scope.sessionsAvailableTomorrow = null;

    /**
     * The first date that contains a session.
     *
     * @type {Date|null}
     */
    $scope.firstDate = null;

    /**
     * The next Date that has any sessions after the currently selected day.
     *
     * @type {Date|null}
     */
    $scope.nextDate = null;

    /**
     * The next Date that has any sessions after the currently selected day.
     *
     * @type {Date|null}
     */
    $scope.previousDate = null;

    $scope.sessionAvailability = null;

    $scope.priceLoading = false;

    $scope.calendarLoading = false;

    /**
     * @type {Object<DigiTickets.SessionAvailability>}
     */
    $scope.sessionAvailability = {};

    /**
     * Remember what full months session availability has already been loaded for.
     * This is an array of "YYYY-MM" strings.
     *
     * @type {string[]}
     */
    let sessionAvailabilityLoadedForMonths = [];

    /**
     * @type {SessionSpaceCalculator}
     */
    let sessionSpaceCalculator;

    $scope.datePickerObj = null;

    $scope.$on('datepicker.monthChanged', function (event, month, year, datePickerObj) {
        let date = new Date(year, month);
        $scope.calendarLoading = true;
        loadSessionAvailability(date)
            .then(
                (refreshed) => {
                    if ($scope.datepicker.date.toYM() !== date.toYM()) {
                        // Select the 1st of the month of the new date.
                        $scope.setDate(date);
                    }
                    if (refreshed) {
                        datePickerObj.refill();
                    }
                    $scope.calendarLoading = false;
                    $scope.$apply();
                }
            )
            .catch(
                () => {
                    $scope.calendarLoading = false;
                    $scope.$apply();
                }
            );
    });

    const initialize = () => {
        $scope.eventName = event.name;

        sessionSpaceCalculator = new SessionSpaceCalculator(cartService, cartService.currentCart.originalOrder);
        sessionSpaceCalculator.setSessionSpaceAdjustments(sessionSpaceAdjustments);

        $scope.showLoader = true;
        const loadStartedAt = new Date();
        SessionManager.getSessionCalendarForEvent(event.ID, sessionSalesCutoffFilter)
            .then(
                /**
                 * @param {SessionCalendar} sessionCalendar
                 */
                function (sessionCalendar) {
                    const loadDuration = (new Date()).getTime() - loadStartedAt.getTime();
                    console.log('Loaded session calendar in ' + loadDuration + 'ms.', sessionCalendar);

                    $scope.sessionCalendar = sessionCalendar;
                    $scope.showLoader = false;
                    $scope.calendarLoading = true;

                    // Load session availability for the initially displayed month.
                    const loadAvailabilityStartedAt = new Date();
                    loadSessionAvailability($scope.datepicker.date)
                        .then(
                            () => {
                                const loadAvailabilityDuration = (new Date()).getTime() - loadAvailabilityStartedAt.getTime();
                                console.debug(
                                    `Loaded session availability for ${$scope.datepicker.date.toYMD()} in ${loadAvailabilityDuration}ms.`
                                );

                                $scope.firstDate = sessionCalendar.getFirstDate();
                                $scope.sessionsAvailableToday = sessionCalendar.hasSessionsForDay($scope.todaysDate);
                                $scope.sessionsAvailableTomorrow = sessionCalendar.hasSessionsForDay($scope.tomorrowsDate);

                                // Pre-select a session.
                                // Either the previously chosen session (event session preference)
                                // or the first session for this month.
                                if (!$scope.selectPreferredSession()) {
                                    if ($scope.firstDate) {
                                        console.debug('No preferred session. Selecting first date.');
                                        $scope.setDate($scope.firstDate);
                                        // Selecting the date will select the first session on that date.
                                    }
                                }

                                $timeout(function () {
                                    // Wait until the session list becomes visible.
                                    // Then attach a scroll handler on the session list so we can update the current
                                    // page if the user scrolls it without using the pager buttons.
                                    let debouncedScrollHandler = _.debounce(
                                        function () {
                                            determineSessionPage();
                                            try {
                                                $scope.$apply();
                                            } catch (e) {
                                            }
                                        },
                                        10
                                    );
                                    angular.element(document.getElementById('session-time-list'))
                                        .on('scroll', function () {
                                            debouncedScrollHandler();
                                        });

                                    $scope.calendarLoading = false;
                                    $scope.$apply();
                                }, 10);
                            }
                        )
                        .catch(
                            () => {
                                $scope.calendarLoading = false;
                                $scope.$apply();
                            }
                        );
                }
            );
    };

    /**
     * If there are not enough sessions to fill the last "page" in the scroller, add padding to the bottom so it
     * is the same height as if there were {$scope.pagination.pageSize} sessions on the last page.
     */
    $scope.setScrollerPadding = function setScrollerPadding() {
        $scope.scrollerPadding = 0;
        if ($scope.daySessions.length % $scope.pagination.pageSize !== 0) {
            $scope.scrollerPadding = ($scope.pagination.pageSize - ($scope.daySessions.length % $scope.pagination.pageSize)) * $scope.sessionElementHeight;
        }
    };

    $scope.selectPreferredSession = function selectPreferredSession() {
        if (eventSessionPreference.hasOwnProperty(event.ID)) {
            let session = eventSessionPreference[event.ID];
            console.debug('Selecting preferred session', session.ID);
            $scope.selectSession(session);
            return true;
        }

        return false;
    };

    $scope.formatTime = function formatTime(date, showDate) {
        if (date instanceof Date) {
            if (showDate) {
                return date.toShortDate() + ' ' + date.getHoursWithZeros() + ':' + date.getMinutesWithZeros();
            }
            return date.getHoursWithZeros() + ':' + date.getMinutesWithZeros();
        }

        return '00:00';
    };

    /**
     * @param {Date} date
     * @param mode
     *
     * @return {boolean}
     */
    $scope.isDayDisabled = function isDayDisabled(date, mode) {
        switch (mode) {
            case 'year':
                if (!$scope.sessionCalendar.hasSessionsForYear(date)) {
                    // Disabled:
                    return true;
                }
                break;

            case 'month':
                if (!$scope.sessionCalendar.hasSessionsForMonth(date)) {
                    // Disabled:
                    return true;
                }
                break;

            case 'day': {
                let daySessions = $scope.sessionCalendar.getSessionsForDay(date);
                if (daySessions.length < 1) {
                    // No sessions for this day - Disabled:
                    return true;
                }

                let availabilityLoadedForAllDaysSessions = null;
                for (let i = 0; i < daySessions.length; i++) {
                    let daySession = daySessions[i];
                    if ($scope.sessionAvailability.hasOwnProperty(daySession.ID)) {
                        // The sessionAvailability has been loaded for this session.
                        availabilityLoadedForAllDaysSessions = availabilityLoadedForAllDaysSessions !== false;
                        if ($scope.sessionAvailability[daySession.ID].available && $scope.sessionAvailability[daySession.ID].suitable) {
                            // There is at least this one session on this date that can be booked.
                            // Not disabled:
                            return false;
                        }
                    } else {
                        availabilityLoadedForAllDaysSessions = false;
                    }
                }

                if (availabilityLoadedForAllDaysSessions === true) {
                    // The availability was loaded and it has not already returned false above
                    // because there was an available session.
                    // Disabled:
                    return true;
                }

                // If we get here there are some sessions for this day, but some of them have not had their availability
                // loaded so we can't say for sure there is nothing available on this day.
                // Fall through to the default behaviour.

                break;
            }
        }

        // Default - not disabled:
        return false;
    };

    /**
     * Determine if a session should be displayed, based on sales cutoffs and its start time.
     * This function is passed to SessionManager when loading session to filter out the ones to not show.
     *
     * @param {Session} session
     *
     * @return {boolean}
     */
    var sessionSalesCutoffFilter = function sessionSalesCutoffFilter(session) {
        // This code now uses the settings in the event (eventcats table).
        // There are 3 possible cutoff points for showing the session in the EPOS:
        // 1) session start; 2) session end; 3) a specific period of time after the session start.
        switch (event.staffSalesCutoffPointAfterSessionStart) {
            case 'session_end':
                // Cutoff is session end.
                if (session.endTime === null) {
                    // Session has no end, so it must be visible!
                    return true;
                }
                if (session.startTime.toYMD() > $scope.todaysDate.toYMD()) {
                    // It's tomorrow or later, so show it.
                    return true;
                }
                if (session.startTime.toYMD() === $scope.todaysDate.toYMD()) {
                    if (session.endTime.getTime() >= $scope.todaysDate.getTime()) {
                        // Session is not in the past, so show it.
                        return true;
                    }
                }
                break;
            case 'specific_time':
                // Cutoff is session start plus a specific time. It is a string of the form
                // hh:mi:ss. There doesn't seem to be an easy way to convert it to milliseconds,
                // so I'll do it myself.
                var timeParts = [0, 0, 0]; // now guards against crap data
                if (event.staffSalesCutoffTime && event.staffSalesCutoffTime.split(':').length === 3) {
                    timeParts = event.staffSalesCutoffTime.split(':');
                }
                var cutoffIntervalMs = ((((parseInt(timeParts[0]) * 60) + parseInt(timeParts[1])) * 60) + parseInt(timeParts[2])) * 1000;

                if ((session.startTime.getTime() + cutoffIntervalMs) >= $scope.todaysDate.getTime()) {
                    // Start plus offset is not in the past...
                    if (session.endTime === null) {
                        // There is no end time, so we can show.
                        return true;
                    }
                    if (session.endTime.getTime() >= $scope.todaysDate.getTime()) {
                        // Session end is also not in the past, so we're okay.
                        return true;
                    }
                }
                break;
            case 'session_start':
            default:
                // Cutoff is session start.
                if (session.startTime.getTime() >= $scope.todaysDate.getTime()) {
                    // Start is not in the past, so we can show.
                    return true;
                }
                break;
        }

        return false;
    };

    $scope.changePage = function changePage(page, animate) {
        $scope.pagination.currentPage = page;
        $scope.pageChanged(page, animate);
    };

    /**
     * Called when a page is changed on the pagination.
     *
     * @param page
     * @param animate
     */
    $scope.pageChanged = function pageChanged(page, animate) {
        try {
            animate = animate === undefined ? true : animate;
            let offset = (page - 1) * $scope.pagination.pageSize;
            let scrollTop = offset * $scope.sessionElementHeight;
            if (animate) {
                angular.element(document.getElementById('session-time-list'))
                    .scrollTo(0, scrollTop, 100);
            } else {
                document.getElementById('session-time-list').scrollTop = scrollTop;
            }
        } catch (e) {

        }
    };

    /**
     * Called when the session list is scrolled.
     */
    const determineSessionPage = () => {
        let scrollTop = document.getElementById('session-time-list').scrollTop;

        // +10 is a small amount of leeway
        $scope.pagination.currentPage = Math.ceil((scrollTop + 12) / $scope.pageHeight);
    };

    /**
     * @param {Date} date
     * @return {boolean}
     */
    const isSessionAvailabilityLoadedForMonth = (date) => sessionAvailabilityLoadedForMonths.indexOf(date.toYM()) !== -1;

    /**
     * Loads the session availability (based on bookable resources) from the API if it has not already been
     * loaded for the given month. When loaded, sets the available attribute of sessions in that month.
     *
     * @param {Date} date
     *
     * @return {Promise<boolean>} The boolean specifies if the availability was freshly loaded from the API (true)
     *                            or came from a cached copy (false).
     */
    const loadSessionAvailability = (date) => new Promise(
        (resolve, reject) => {
            if (isSessionAvailabilityLoadedForMonth(date)) {
                resolve(false);
                return;
            }

            let yearMonth = date.toYM();
            let sessions = $scope.sessionCalendar.getSessionsForMonthWithSurrounding(date);

            if (sessions.length < 1) {
                sessionAvailabilityLoadedForMonths.push(yearMonth);
                resolve(false);
                return;
            }

            let sessionIDs = sessions.map(
                /**
                 * @param {Session} session
                 */
                function (session) {
                    return session.ID;
                }
            );

            // Get all the sessions for this month.
            SessionAvailabilityResource.query(
                {
                    lines: ticketLines.map(({ ticket, qty }) => ({
                        qty,
                        itemID: ticket.ID
                    })),
                    sessionIDs: sessionIDs.join(',')
                },
                function (sessionAvailability) {
                    if (sessionAvailability) {
                        Object.assign($scope.sessionAvailability, sessionAvailability);
                        sessionAvailabilityLoadedForMonths.push(yearMonth);
                        for (let i = 0; i < sessions.length; i++) {
                            let session = sessions[i];
                            if (sessionAvailability.hasOwnProperty(session.ID) && (!sessionAvailability[session.ID].available || !sessionAvailability[session.ID].suitable)) {
                                session.available = 0;
                            } else {
                            }
                        }
                    }

                    $scope.calendarLoading = false;
                    resolve(true);
                },
                function () {
                    $scope.calendarLoading = false;
                    reject();
                }
            );
        }
    );

    const scrollToSelectedSession = () => {
        if (!$scope.selectedSession) {
            $scope.changePage(1, false);
        } else {
            let selectedIndex = $scope.daySessions.indexOf($scope.selectedSession);
            let page = Math.ceil((selectedIndex + 1) / $scope.pagination.pageSize);
            if (page !== $scope.pagination.currentPage) {
                $scope.changePage(page, true);
            }
        }
    };

    /**
     * @param {Session} session
     */
    $scope.selectSession = async function selectSession(session) {
        console.debug('Select session', session);
        $scope.selectedSession = session;

        if (session) {
            if (session.available === 0) {
                console.debug('Cancelling session selection because 0 availability.');
                $scope.selectedSession = null;
                return;
            }

            // When a session is selected, see if we need to update the price of any of the tickets.
            $scope.priceLoading = true;
            await Promise.all(
                ticketLines.map(async ({ ticket, price, qty }, i) => {
                    ticketLines[i].price = await recalculateTicketPrice(session, ticket, qty, price);
                })
            );
            $scope.priceLoading = false;
        }

        recalculateTotalSessionPrice();

        // Make sure the right day is selected.
        // This happens after the session is selected to avoid infinite loops where changing the date selects
        // the first session.
        if (session) {
            $scope.setDate(session.getStartDate());
        }

        $timeout(function () {
            scrollToSelectedSession();
        }, 10);
    };

    /**
     * @param {Date} date
     */
    $scope.setDate = function setDate(date) {
        console.debug('Set date', date);
        $scope.datepicker.date = date;
        $scope.calendarChange();
    };

    /**
     * A date was selected (either in the date picker or by using the today/tomorrow/next/prev buttons).
     * Display the sessions for that date.
     */
    $scope.calendarChange = function calendarChange() {
        console.debug('Calendar changed', $scope.datepicker.date);
        $scope.error = false;

        // When changing the date on the calendar unselect the session so
        // the user can't accidentally submit with the the wrong session selected.
        if ($scope.selectedSession && $scope.datepicker.date.toYMD() !== $scope.selectedSession.startTime.toYMD()) {
            $scope.selectSession(null);
        }

        setDisplayedSessions(
            $scope.sessionCalendar.getSessionsForDay($scope.datepicker.date)
        );

        $scope.nextDate = $scope.sessionCalendar.getNextAvailableDate($scope.datepicker.date);
        $scope.previousDate = $scope.sessionCalendar.getPreviousAvailableDate($scope.datepicker.date);
    };

    /**
     * @param {Session[]} sessions
     */
    const setDisplayedSessions = (sessions) => {
        // Sort sessions by startTime
        sessions.sort(
            /**
             * Sort sessions by start time.
             * If 2 have the same start time sort them by ID (oldest first).
             *
             * @param {Session} a
             * @param {Session} b
             * @return {number}
             */
            function (a, b) {
                if (a.startTime.getTime() === b.startTime.getTime()) {
                    return a.ID > b.ID ? 1 : -1;
                }
                return a.startTime > b.startTime ? 1 : -1;
            }
        );

        for (let i = 0; i < sessions.length; i++) {
            sessions[i].bookableSpaces = sessionSpaceCalculator.getAvailableSpacesForSession(sessions[i]);
        }

        $scope.daySessions = sessions;

        if (!$scope.selectedSession && $scope.daySessions.length > 0) {
            // Select the first session on this day by default.
            $scope.selectSession($scope.daySessions[0]);
        }

        $scope.dayHasInactiveSessions = $scope.daySessions.some((daySession) => daySession.status === 'Inactive');

        $scope.setScrollerPadding();

        $scope.changePage(1, false);
    };

    /**
     * @return {{ticket: DigiTickets.Ticket, qty: Number, price: Number, session: Session}[]}
     */
    $scope.ok = function ok() {
        if (!$scope.selectedSession) {
            return;
        }

        const result = ticketLines.map(({ ticket, qty, price }) => ({
            ticket,
            qty,
            price,
            session: $scope.selectedSession
        }));

        $modalInstance.close(result);
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    /**
     * This function works out whether it's worth asking the API about session-specific pricing. If it is, then
     * it will go away and work out an adjusted price for each ticket, and provide a total amount, stored in
     * Session.sessionPrice.
     *
     * @param {Session} session
     * @param {DigiTickets.Ticket} ticket
     * @param {Number} qty
     * @param {Number} currentPrice
     *
     * @return {Number}
     */
    async function recalculateTicketPrice(session, ticket, qty, currentPrice) {
        if (!UserService.currentBranch.pricingRulesActive) {
            // There aren't any pricing rules currently active, so the base price will always be used
            return currentPrice;
        }

        const { price } = await SessionPriceResource.query({
            ticketID: ticket.ID,
            qty,
            sessionID: session.ID
        }).$promise;

        return parseFloat(price);
    }

    /**
     * Sums up all the tickets that have been selected, and produces a total cost for booking the selected session.
     */
    function recalculateTotalSessionPrice() {
        if (!$scope.selectedSession) {
            return;
        }
        $scope.selectedSession.sessionPrice = ticketLines.reduce((total, { qty, price }) => total + (qty * price), 0);
    }

    // Initialize as soon as the modal is opened.
    initialize();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionPickerCtrl;
}
