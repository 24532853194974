/**
 * @param $q
 * @param $location
 * @param $scope
 * @param $rootScope
 * @param $timeout
 * @param {AgentService} AgentService
 * @param {CacheManager} CacheManager
 * @param {DataSyncer} DataSyncer
 * @param {DeviceManager} deviceManager
 * @param {DataStoreTester} dataStoreTester
 * @param LandingPage
 * @param {LocalStorageDataStore} LocalStorageDataStore
 * @param {DigiTickets.Logger} Logger
 * @param {NavigationService} navigationService
 * @param {CurrentDevice} CurrentDevice
 * @param {PaymentMethodService} paymentMethodService
 * @param {PrintRouter} PrintRouter
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {DigiTickets.TradingSessionManager} TradingSessionManager
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
const EposIndexCtrl = function (
    $q,
    $location,
    $scope,
    $rootScope,
    $timeout,
    AgentService,
    CacheManager,
    DataSyncer,
    deviceManager,
    dataStoreTester,
    LandingPage,
    LocalStorageDataStore,
    Logger,
    navigationService,
    CurrentDevice,
    paymentMethodService,
    PrintRouter,
    PrivilegesService,
    TradingSessionManager,
    UserService,
    UserSessionManager
) {
    navigationService.hideNav();
    $rootScope.rootProcessingMessage = null;

    /**
     * @type {DigiTickets.Branch|null}
     */
    $scope.currentBranch = null;

    /**
     * @type {DigiTickets.Device|null}
     */
    $scope.currentDevice = null;

    /**
     * @type {?User}
     */
    $scope.currentUser = null;

    /**
     * Should the loading screen say "loading" (false) or "syncing" (true)?
     *
     * @type {boolean}
     */
    $scope.displaySyncingMessage = false;

    /**
     * @type {boolean}
     */
    $scope.syncing = false;

    /**
     * @type {?{total: number, complete: number, current:string}}
     */
    $scope.syncProgress = null;

    /**
     * @type {?{failedKey: string, errorMessage: string}}
     */
    $scope.syncError = null;

    $scope.redirectToLandingPage = function redirectToLandingPage() {
        // Use the landing page code to determine which screen to show first.
        // It assumes "sell" if the code is either not set or is invalid.
        LandingPage.getTopLevelScreenPath(
            $scope.currentDevice.eposLandingPageKey,
            function (path) {
                $location.path(path);
            }
        );
    };

    $scope.init = async function init() {
        // Check if logged in. If not go to login page.
        if (!UserService.isLoggedIn()) {
            console.log('User not logged in. Redirecting to login page.');
            navigationService.viewLogin();
            return;
        }

        // Check if device selected. If not go to device page.
        if (!CurrentDevice.isSet()) {
            console.log('Device not selected. Checking for guid saved on disk.');

            try {
                $timeout(() => {
                    $rootScope.setRootProcessingMessage('EPOS_INDEX.LOADING_DEVICE');
                });
                await deviceManager.loadCurrentDeviceFromGuidOnDisk();
            } catch (e) {
            }

            $timeout(() => {
                $rootScope.setRootProcessingMessage();
            });

            if (!CurrentDevice.isSet()) {
                console.log('Device not selected. Redirecting to device page.');
                navigationService.viewDevice();
                return;
            }
        }

        $scope.currentBranch = UserService.currentBranch;
        $scope.currentDevice = CurrentDevice.device;
        $scope.currentUser = UserService.currentUser;

        // If IndexedDB is not available log this to Sentry
        // When IndexedDB enters production we should additionally halt the application
        // and display a message as we do in the browser check
        try {
            const testResult = await dataStoreTester.test();
            console.log('Testing IndexedDB support success.', `Test result: ${JSON.stringify(testResult)}`);
        } catch (e) {
            Logger.warning('IndexedDB not available: ' + e.stack);

            // Empty the entire body so that our application cannot start up properly, and replace with our message
            document.body.innerHTML = `
                    <div id="unsupported-browser-message" class="alert alert-danger" role="alert">
                        <h4><i class="glyphicon glyphicon-warning-sign"></i> Your browser settings are incompatible with ProPoint.</h4>
                        <p><br></p>
                        <p>ProPoint relies on a browser storage mechanism called <strong><em>IndexedDB</em></strong>.</p>
                        <p>Unfortunately this is not available due to your current browser settings.</p>
                        <p><br></p>
                        <p>ProPoint requires one of the following:</p>
                        <ul>
                            <li><strong>Windows</strong>: latest version of Chrome
                                 <strong><em>(Not running in Incognito Mode)</em></strong>
                            </li>
                            <li><strong>Windows</strong>: latest version of Firefox 
                                <strong><em>(Not running in Private Mode and with Remember history enabled)</em></strong>
                            </li>
                            <li>
                                <!-- Note: In October 2019, we can bump this to iOS 11 or later, as per our 5-year support policy -->
                                <strong>iPad</strong>: Safari on iOS 10.3 or later
                                <strong><em>(Not running in Private Mode)</em></strong>
                            </li>
                        </ul>
                        <p><br></p>
                        <p>If your settings are correct and you continue to see this message please contact DigiTickets support.</p>
                        
                    </div>
                `;
            alert('Your browser does not support ProPoint. Please check your settings.');
        }

        // Fire up a trading session for the device.
        TradingSessionManager.resumeOrOpenSession(
            $scope.currentDevice,
            function (tradingSession) {
                CurrentDevice.setTradingSession(tradingSession);
            }
        );

        LocalStorageDataStore.clean();

        // Check if appropriate data is already synced.
        let lastSync = LocalStorageDataStore.find('lastSync');
        console.log('Last sync', lastSync);

        /**
         * This variable is speculation on whether running the sync() method will be downloading data from the
         * API. If it is we display a message saying so, if not we display a generic loading message.
         *
         * @type {boolean}
         */
        let requiresSync = false;
        if (!lastSync || lastSync.branchID !== $scope.currentBranch.ID) {
            // If the branch has changed since the last synced data we'll clear the cached data so the subsequent call
            // to sync will fetch fresh copies from the API.
            console.log('Branch changed. Forcing sync. Last synced branch:' + (lastSync ? lastSync.branchID : 'none') + ' Current branch:' + $scope.currentBranch.ID);
            await CacheManager.changeBranchClear();
            requiresSync = true;
        }

        // We need to run a sync on every page load to prime all the 'managers' (TicketManager, ProductManager).
        // If the cache has not been cleared (which it will when changing branch or manually clicking sync)
        // this will be near instant.

        const syncStartedAt = new Date();
        $scope.loadData(requiresSync).then(
            async function () {
                console.log(`Loading data finished in ${(new Date()) - syncStartedAt}ms`);

                // If the current user does not appear in the privileges list they're probably a new user that
                // did not exist when the last sync happened. Force a sync in this case.
                // This would never cause 2 syncs to happen in a row because to get here without the current user
                // in the privileges it must have been from the cache.
                if (!PrivilegesService.findPrivilegesByUserID($scope.currentUser.ID)) {
                    console.log('No privileges found for current user. Forcing sync.');
                    await CacheManager.changeBranchClear();
                    // We can only load data once per page load, so refresh the page.
                    window.location.reload();
                } else {
                    $timeout(function () {
                        $rootScope.appReady = true;
                        $scope.afterSync();
                    }, 80);
                }
            }
        );
    };

    /**
     * @param {boolean} requiresSync
     *
     * @returns {Promise}
     */
    $scope.loadData = function loadData(requiresSync) {
        let deferred = $q.defer();
        $scope.syncing = true;
        $scope.displaySyncingMessage = requiresSync === true;

        $scope.syncError = null;
        $scope.syncProgress = null;

        const onProgress = (result) => {
            // Notified.
            $scope.syncProgress = result;
        };

        DataSyncer.loadData(UserService.currentBranch, $scope.currentDevice, onProgress, requiresSync).then(
            function () {
                // Resolved.
                if ($scope.syncProgress) {
                    $scope.syncProgress.complete = $scope.syncProgress.total;
                }
                deferred.resolve();
            },
            function (errorResult) {
                // Rejected.
                console.log('Sync failed', errorResult);
                $scope.syncError = errorResult;
            }
        );

        return deferred.promise;
    };

    $scope.afterSync = function afterSync() {
        // Establish a connection to the ProPoint Agent if needed by this client.
        if (AgentService.companyNeedsAgent(paymentMethodService.getAll())) {
            AgentService.connectToAgent(true);
        }

        // See if there was a session in progress for the current user.
        // This would happen for user A if the switch user function was used to switch from user A to B, then
        // user A logs in again.
        UserSessionManager.pullLastSessionScreen($scope.currentUser.ID)
            .then((previousScreen) => {
                if (previousScreen) {
                    console.log('Session restored. Redirecting to', previousScreen);
                    $location.path(previousScreen);
                } else {
                    // Go to device's landing page when sync finished.
                    $scope.redirectToLandingPage();
                }
            });
    };

    $scope.init();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = EposIndexCtrl;
}
