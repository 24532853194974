const FieldInstanceFilter = require('../CustomFields/FieldInstanceFilter');
const FieldLevel = require('../CustomFields/FieldLevel');
const ItemInstance = require('../Orders/ItemInstance');
const { cloneShallow } = require('../../../functions/clone');

/**
 * @param {DigiTickets.CartItem|OrderLine} line
 */
const sortInstancesOnLine = (line) => {
    let itemInstances = line.itemInstances || line.iteminstances || [];
    itemInstances.sort((a, b) => {
        if (a.instance > b.instance) {
            return 1;
        } else if (a.instance < b.instance) {
            return -1;
        }

        return 0;
    });
};

/**
 * @param {DigiTickets.CartItem|OrderLine} line
 */
const createMissingItemInstances = (line) => {
    // We could do a simple loop here but it's possible that instances have been deleted in the middle of the
    // set so we have a gap in the numbering.
    // To cater for this situation we check that each instance number that should exist does exist.
    for (let instance = 1; instance <= line.getQuantity(); ++instance) {
        let itemInstance = line.getInstance(instance);
        if (!itemInstance) {
            itemInstance = new ItemInstance();
            itemInstance.instance = instance;
            itemInstance.fields.lineNumber = line.lineNumber;
            itemInstance.fields.itemInstance = itemInstance.instanceForFieldData;
            line.getItemInstances().push(itemInstance);
        }
    }
    sortInstancesOnLine(line);
};

/**
 * @param {DigiTickets.CartItem} cartLine
 */
const removeSurplusItemInstances = (cartLine) => {
    while (cartLine.itemInstances.length > 0 && cartLine.itemInstances.length > cartLine.quantity) {
        cartLine.itemInstances.pop();
    }
    sortInstancesOnLine(cartLine);
};

/**
 * Ensure  all instances on the line use the same values for askEarly fields.
 * They are all copied from the first instance on the line.
 *
 * This will leave the fieldInstances with weird instance numbering but fixInstanceFieldDataNumbering()
 * below will solve that.
 *
 * @param {DigiTickets.CartItem} cartLine
 */
const copyAskEarlyFieldsFromFirstInstance = (cartLine) => {
    if (cartLine.quantity >= 2) {
        // Get the ask early instances from the first item instance. These are the ones we'll copy.
        let askEarlyFieldInstances = cartLine.itemInstances[0].fields.instances
            .filter(FieldInstanceFilter.ASK_EARLY_FIELDS_ONLY);

        // console.log(`Copying field instances to ${cartLine.quantity - 1} item instances.`, askEarlyFieldInstances);

        // Iterate over the instances > 1.
        for (let i = 1; i < cartLine.itemInstances.length; i++) {
            let itemInstance = cartLine.itemInstances[i];

            // Remove all its askEarly field instances (only keep non ask-early)
            itemInstance.fields.instances = itemInstance.fields.instances
                .filter(FieldInstanceFilter.NON_ASK_EARLY_FIELDS_ONLY);

            // Add the desired askEarly field instances.
            askEarlyFieldInstances.forEach((fieldInstanceToCopy) => {
                itemInstance.fields.add(cloneShallow(fieldInstanceToCopy));
            });
        }
    }
};

/**
 * For all the field data on the line ensure the lineNumber and itemInstance are correctly set (the same as the
 * item instance they are attached to).
 * This is useful if instances are moved between lines e.g. when splitting a line.
 *
 * @param {DigiTickets.CartItem|OrderLine} line
 */
const fixInstanceFieldDataNumbering = (line) => {
    line.getItemInstances().forEach((itemInstance) => {
        // Set appropriate data on the ItemInstance's FieldGroup.
        itemInstance.fields.level = FieldLevel.ITEM;
        itemInstance.fields.setLineNumber(line.lineNumber);
        itemInstance.fields.setItemInstance(itemInstance.instanceForFieldData);
        itemInstance.fields.itemInstanceID = itemInstance.ID;
    });
};

/**
 * @param {DigiTickets.CartItem} cartLine
 */
const cleanCartLine = (cartLine) => {
    createMissingItemInstances(cartLine);
    removeSurplusItemInstances(cartLine);
    copyAskEarlyFieldsFromFirstInstance(cartLine);
    fixInstanceFieldDataNumbering(cartLine);
};


/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        cleanCartLine,
        copyAskEarlyFieldsFromFirstInstance,
        createMissingItemInstances,
        fixInstanceFieldDataNumbering,
        removeSurplusItemInstances,
        sortInstancesOnLine
    };
}
