/**
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param action
 * @param {CartService} cartService
 * @param Notification
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 */
const PartialPaymentCtrl = function PartialPaymentCtrl(
    $filter,
    $modalInstance,
    $scope,
    action,
    cartService,
    Notification,
    NumPadFactory
) {
    $scope.cart = cartService;

    $scope.remainingBalance = $scope.cart.getRemainingBalance();
    $scope.maxPayment = $scope.cart.getRemainingMaxPayment();

    $scope.amountTendered = 0;
    $scope.paymentMethod = $scope.cart.getSelectedPaymentMethod();

    /**
     * 'make' = before the transaction has happened - enter the amount that you want to take.
     * 'received' = after the transaction has happened - enter the amount that was taken.
     */
    $scope.action = action;

    $scope.numPad = NumPadFactory.create(
        {
            isNegative: $scope.cart.isRefund(),
            showSignButton: false,
            ok: function () {
                $scope.ok();
            },
            cancel: function () {
                $scope.cancel();
            }
        },
        function (numPadValue) {
            $scope.amountTendered = numPadValue;
        }
    );

    $scope.cancel = function cancel() {
        $modalInstance.dismiss();
    };

    $scope.ok = function ok() {
        // Prevent making an overpayment after the fact.
        if ($scope.cart.isRefund()) {
            if ($scope.amountTendered < $scope.cart.getRemainingMaxPayment()) {
                if (action === 'received') {
                    Notification.error(
                        $filter('lang')('PAYMENT.MAX_REFUND_AMOUNT_EXCEEDED_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
                    );
                } else {
                    Notification.error(
                        $filter('lang')('PAYMENT.MAX_REFUND_AMOUNT_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
                    );
                }

                return false;
            }
        } else if ($scope.amountTendered > $scope.cart.getRemainingMaxPayment()) {
            if (action === 'received') {
                Notification.error(
                    $filter('lang')('PAYMENT.MAX_AMOUNT_EXCEEDED_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
                );
            } else {
                Notification.error(
                    $filter('lang')('PAYMENT.MAX_AMOUNT_ALERT', { max: $scope.cart.getRemainingMaxPayment() })
                );
            }

            return false;
        }

        $modalInstance.close(
            {
                amountTendered: $scope.amountTendered
            }
        );
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PartialPaymentCtrl;
}
