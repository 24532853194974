const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
/**
 * @param $filter
 * @param $interval
 * @param $modal
 * @param $scope
 * @param $timeout
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {DialogService} DialogService
 * @param {DigiTickets.OrderInProgressStasher} OrderInProgressStasher
 * @param {PaymentMethodService} paymentMethodService
 * @param {PaymentState} paymentState
 * @param {UserService} UserService
 * @param {DigiTickets.Worldpay.Config} WorldpayConfigService
 * @param {DigiTickets.Worldpay.Driver} WorldpayDriverService
 */
const WorldpayPaymentCtrl = function (
    $filter,
    $interval,
    $modal,
    $scope,
    $timeout,
    CartStashHelperService,
    DialogService,
    OrderInProgressStasher,
    paymentMethodService,
    paymentState,
    UserService,
    WorldpayConfigService,
    WorldpayDriverService
) {
    $scope.worldpayConfig = WorldpayConfigService;

    // Set standard payment controller things.
    $scope.thisPaymentMethodRef = PaymentMethodRef.CARD_WORLDPAY_CHIP_AND_PIN;
    $scope.thisPaymentMethod = paymentMethodService.getByRef($scope.thisPaymentMethodRef);
    $scope.registerSuppressPaymentReceivedButton($scope.thisPaymentMethodRef);
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    /**
     * A function to execute once we know the IPC application is ready to listen.
     *
     * @type {function|null}
     */
    $scope.onTerminalReady = null;

    /**
     * Is IPC ready to receive a transaction?
     *
     * @type {boolean|null}
     */
    $scope.ready = null;

    /**
     * To ensure the user clicks the Continue button on IPC, we don't dissmiss the payment modal and complete
     * the order until the state returns to ready.
     * When a payment is received this is set to true. If this is true and a ready status is received, the ok()
     * function will be called.
     *
     * @type {boolean}
     */
    let submitOnReady = false;

    /**
     * Has IPC said it was busy during this modal-being-open-session?
     *
     * @type {boolean}
     */
    $scope.hasBeenBusy = false;

    /**
     * When IPC says it is busy (by returning anything other than a response that says non-busy)
     * a 1 second timeout will be started, after which we'll send a record to check if it is still busy
     * then repeat if necessary.
     * This is a handle for that timeout so it can be cancelled if desired.
     *
     * @type {null}
     */
    $scope.checkStatusTimeout = null;
    $scope.clearCheckStatusTimeout = function () {
        $timeout.cancel($scope.checkStatusTimeout);
    };


    /**
     * Start a transaction for the amount given.
     *
     * @param {number} amount
     */
    $scope.beginTransaction = function beginTransaction(amount) {
        $scope.showWindow();

        $timeout(function () {
            $scope.clearCheckStatusTimeout();

            let record = $scope.createTransactionRequestRecord(amount);
            WorldpayDriverService.sendRecord(record);
            $scope.transactionRecord = record;
            submitOnReady = false;
            paymentState.setCancelable(false, $scope.thisPaymentMethodRef);

            OrderInProgressStasher.setTransactionInProgress(
                new DigiTickets.TransactionInProgress(
                    $scope.thisPaymentMethodRef,
                    record.transactionReference,
                    record.transactionAmount
                )
            );
        }, 500);
    };

    $scope.getDisplayAmount = function () {
        if ($scope.transactionRecord) {
            if ($scope.transactionRecord.transactionType === DigiTickets.Worldpay.TransactionType.RETURNS) {
                return -$scope.transactionRecord.transactionAmount;
            }
            return $scope.transactionRecord.transactionAmount;
        }

        return null;
    };

    /**
     * Start a transaction for the balance remaining in the cart.
     */
    $scope.beginFullTransaction = function beginFullTransaction() {
        $scope.beginTransaction($scope.cart.getRemainingBalance());
    };

    /**
     * Show the partial payment entry modal and start a transaction with that amount.
     */
    $scope.makePartialPayment = function makePartialPayment() {
        $scope.hideWindow();
        $scope.showMakePartialModal(function (amount) {
            if (amount) {
                $scope.beginTransaction(amount);
            }
        });
    };

    $scope.isCnp = false;
    $scope.toggleCnp = function toggleCnp() {
        if ($scope.isCnp) {
            // No permission check needed to disable.
            $scope.isCnp = false;
        } else {
            $scope.hideWindow();
            $scope.privilegesService.requirePrivilege('cnp_payments').then(
                /**
                 * @param {DigiTickets.PrivilegeCheckResult} result
                 */
                function (result) {
                    if (result.granted) {
                        $scope.isCnp = true;
                    }
                }
            );
        }
    };

    /**
     * Send a record to show the IPC window.
     */
    $scope.showWindow = function showWindow() {
        console.log('Showing IPC window.');
        WorldpayDriverService.sendRecord(
            new DigiTickets.Worldpay.TransactionRequestRecord(
                DigiTickets.Worldpay.TransactionType.SHOW_IPC_WINDOW
            )
        );
    };

    /**
     * Send a record to hide the IPC window.
     */
    $scope.hideWindow = function hideWindow() {
        console.log('Hiding IPC window.');
        WorldpayDriverService.sendRecord(
            new DigiTickets.Worldpay.TransactionRequestRecord(
                DigiTickets.Worldpay.TransactionType.HIDE_IPC_WINDOW
            )
        );
    };

    /**
     * Send a record to return the busy/not busy status of IPC.
     */
    $scope.checkStatus = function checkStatus() {
        console.log('Checking IPC status.');
        $scope.clearCheckStatusTimeout();
        $scope.setLoading(true);
        WorldpayDriverService.sendRecord(
            new DigiTickets.Worldpay.TransactionRequestRecord(
                DigiTickets.Worldpay.TransactionType.CHECK_STATUS
            )
        );
    };

    /**
     * @param {number} amount
     *
     * @return {DigiTickets.Worldpay.TransactionRequestRecord}
     */
    $scope.createTransactionRequestRecord = function createTransactionRequestRecord(amount) {
        let record = new DigiTickets.Worldpay.TransactionRequestRecord();
        record.transactionReference = $scope.cart.generatePaymentThirdPartyID();

        if (amount < 0) {
            record.transactionType = DigiTickets.Worldpay.TransactionType.RETURNS;
            record.transactionAmount = Math.abs(amount);
        } else {
            record.transactionType = DigiTickets.Worldpay.TransactionType.SALE;
            record.transactionAmount = amount;
        }

        if ($scope.isCnp) {
            record.cardholderNotPresent = true;
        }

        return record;
    };

    /**
     * Cleanup after a successful transaction ready for another.
     */
    $scope.cleanup = function cleanup() {
        // Re-enable all the other payment methods in case we've just recovered a partial payment(!)
        $scope.setRestrictToPaymentMethodUsingRef(null);

        $scope.transactionRecord = null;
    };

    $scope.dismissRecoveryModal = function dismissRecoveryModal() {
        $scope.cart.setTransactionInProgress(null);
        OrderInProgressStasher.clearTransactionInProgress();

        // Hide the modal asking the user what to do about a recovered transaction. We've been told what to do now.
        if ($scope.recoveryConfirmationModal) {
            try {
                $scope.recoveryConfirmationModal.dismiss('cancel');
            } catch (e) {}
            $scope.recoveryConfirmationModal = null;
        }
    };

    /**
     * Register listener to receive records coming from the terminal.
     */
    WorldpayDriverService.clearRecordReceivedListeners();
    WorldpayDriverService.onRecordReceived(
        function (record) {
            $scope.handleReceivedRecord(record);
        }
    );

    /**
     * @param {DigiTickets.Worldpay.TransactionResponseRecord} record
     */
    $scope.handleReceivedRecord = function handleReceivedRecord(record) {
        $scope.clearCheckStatusTimeout();

        switch (record.transactionResult) {
            case DigiTickets.Worldpay.TransactionResult.APPROVED_ONLINE:
            case DigiTickets.Worldpay.TransactionResult.APPROVED_OFFLINE:
            case DigiTickets.Worldpay.TransactionResult.APPROVED_MANUAL_REFERRAL:
                // Transaction completed.
                var isRefund = record.transactionType === DigiTickets.Worldpay.TransactionType.RETURNS;
                var payment = record.toPayment(isRefund);
                payment.setPaymentMethod($scope.thisPaymentMethod);
                $scope.addPaymentFromWorldPay(payment);

                // TODO: When every client is sending their receipts to print we can $scope.ok() as soon as we
                // receive this result as we know IPC will be finished and ready.
                // And the submitOnReady logic can be removed.
                // $scope.ok();
                break;

            case DigiTickets.Worldpay.TransactionResult.STATUS_NOT_BUSY:
                $scope.ready = true;
                $scope.cleanup();

                if ($scope.hasBeenBusy) {
                    $scope.dismissRecoveryModal();
                }
                $scope.hasBeenBusy = false;

                if (typeof $scope.onTerminalReady === 'function') {
                    $scope.onTerminalReady();
                }
                if (submitOnReady === true) {
                    submitOnReady = false;
                    $scope.ok();
                }
                break;

            case DigiTickets.Worldpay.TransactionResult.STATUS_BUSY:
                $scope.hasBeenBusy = true;
                $scope.ready = false;
                paymentState.setCancelable(false, $scope.thisPaymentMethodRef);

                console.log('IPC is busy. Checking again in 1 second.');
                $scope.checkStatusTimeout = $timeout(function () {
                    $scope.checkStatus();
                }, 1000);
                break;
        }

        if (record.transactionResult !== DigiTickets.Worldpay.TransactionResult.STATUS_BUSY) {
            paymentState.clearCancelable();
        }

        $scope.setLoading(false);

        // Check if we're allowed to send things to IPC (if this record is not already the result of that check)
        if (record.transactionResult !== DigiTickets.Worldpay.TransactionResult.STATUS_BUSY
            && record.transactionResult !== DigiTickets.Worldpay.TransactionResult.STATUS_NOT_BUSY) {
            console.log('Non-status result. Checking status in 1 second.');
            $scope.checkStatusTimeout = $timeout(function () {
                $scope.checkStatus();
            }, 1000);
        }
    };

    $scope.addPaymentFromWorldPay = function addPaymentFromWorldPay(payment) {
        $scope.dismissRecoveryModal();

        $scope.addPayment(payment);
        $scope.preventAddFullPaymentOnClose();
        $scope.cleanup();

        submitOnReady = true;
    };

    $scope.recoveryConfirmationModal = null;

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        if (newPaymentMethodRef === $scope.thisPaymentMethodRef) {
            if ($scope.cart.transactionInProgress && $scope.cart.transactionInProgress.paymentMethodRef === $scope.thisPaymentMethodRef) {
                // Started with a recovered transaction.
                // Display a prompt to ask the operator if it had already completed.

                $scope.transactionRecord = $scope.createTransactionRequestRecord(
                    $scope.cart.transactionInProgress.amount
                );

                $scope.recoveryConfirmationModal = DialogService.confirm(
                    'PAYMENT_WORLDPAY.RECOVERED_TXN_MESSAGE',
                    function (result, button) {
                        console.log('done', result, button);
                        if (result) {
                            switch (button) {
                                case 0:
                                case 1:
                                    // Did not complete or still in progress.
                                    $scope.dismissRecoveryModal();
                                    break;

                                case 2:
                                    // Completed.
                                    var payment = new DigiTickets.Payment();
                                    payment.setTendered($scope.cart.transactionInProgress.amount);
                                    payment.setPaymentMethod($scope.thisPaymentMethod);
                                    // thirdPartyID is purposely missing here as we don't know it.
                                    $scope.addPaymentFromWorldPay(payment);
                                    break;
                            }
                        }
                    },
                    {
                        customButtons: [
                            {
                                label: 'PAYMENT_WORLDPAY.RECOVERED_TXN_NO_BTN',
                                classes: 'btn-danger'
                            },
                            {
                                label: 'PAYMENT_WORLDPAY.RECOVERED_TXN_WAIT_BTN',
                                classes: 'btn-warning'
                            },
                            {
                                label: 'PAYMENT_WORLDPAY.RECOVERED_TXN_YES_BTN',
                                classes: 'btn-success'
                            },
                        ],
                        messageParams: {
                            amount: $scope.cart.transactionInProgress.amount
                        }
                    }
                );
            } else if ($scope.worldpayConfig.autoStartTransactions) {
                $scope.onTerminalReady = function () {
                    $scope.beginFullTransaction();
                    $scope.onTerminalReady = null;
                };
            }

            // Switching to this payment method.
            $scope.checkStatus();
        } else {
            // Switching away from this payment method.

            // Hide the IPC window
            $scope.hideWindow();
        }
    });

    /**
     * Hide IPC window when the payment modal is closed.
     */
    $scope.$on('payment-modal.closing', function () {
        // Hide the IPC window
        $scope.hideWindow();
    });
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = WorldpayPaymentCtrl;
}
