const { toBool, toInt, toNullableInt, toString } = require('../../../functions/transform');
const PaymentMethodRef = require('../PaymentMethods/PaymentMethodRef');

const PaymentMethod = function () {
    this.driverRef = '';
    this.ID = null;
    this.ref = null;
    this.name = '';
    this.navOrder = 0;
    this.shortName = '';
    this.showOnEpos = false;
    this.supportsNegativePaymentInProPoint = false;
    this.supportsSubscriptions = false;
    this.supportsCashback = false;
    this.supportsGratuity = false;

    // Additional fields for CompanyPaymentMethod (via companies endpoint).
    this.companiesPaymentMethodsID = null;
    this.config = '';
};

PaymentMethod.prototype = {
    getHydrationMap() {
        return {
            companiesPaymentMethodsID: toNullableInt,
            config: {},
            driverRef: toString,
            ID: {
                field: ['ID', 'paymentMethodID'],
                transform: toInt
            },
            name: toString,
            navOrder: toInt,
            ref: toString,
            shortName: toString,
            showOnEpos: toBool,
            supportsCashback: toBool,
            supportsGratuity: toBool,
            supportsNegativePaymentInProPoint: {
                // Temporarily support old and new field names to allow for users where ProPoint updates via a page
                // refresh rather than at login. Remove supportsRefund when it gets removed from the API response.
                field: ['supportsNegativePaymentInProPoint', 'supportsRefund'],
                transform: toBool
            },
            supportsSubscriptions: toBool
        };
    },

    get supportsChange() {
        return [PaymentMethodRef.CASH, PaymentMethodRef.FLEXI_VOUCHER].includes(this.ref);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentMethod;
}
