const PaymentTokenSelectionModalCtrl = function PaymentTokenSelectionModalCtrl(
    $scope,
    $modalInstance,
    tokens
) {
    /**
     * @type {DigiTickets.PaymentToken}
     */
    $scope.selectedToken = null;

    /**
     * @type {DigiTickets.PaymentToken[]}
     */
    $scope.tokens = tokens;

    $scope.selectToken = function selectToken(token) {
        if ($scope.selectedToken === token) {
            $scope.selectedToken = null;
        } else {
            $scope.selectedToken = token;
        }
    };

    $scope.ok = function () {
        $modalInstance.close({
            token: $scope.selectedToken
        });
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentTokenSelectionModalCtrl;
}
