const { toFloat, toInt } = require('../../../../../../functions/transform');

/**
 * Received from the terminal after a transaction is complete.
 * The comments on properties are from the Verifone integration guides (so may not be the clearest!)
 */
const VerifoneTransactionResponseRecord = function () {
    /**
     * Transaction result:
     * '0' - Completed
     * '7' - Declined
     * '100' - Status Message
     * '-nn' - All negative values are used to define error conditions
     * Appendix A contains a full list of error codes and messages.
     *
     * Screenless and UPT transaction results:
     * '0' - Completed
     * '2' - Referred
     * '5' - Declined
     * '6' - Authorised
     * '7' - Reversed
     * '8' - Comms Down
     * '-nn' - Negative values are used to define error conditions.
     *
     * @type {int|null}
     */
    this.result = null;

    /**
     * Field will show total that will be debited, inclusive of any DCC.
     *
     * @type {number|null}
     */
    this.totalTransactionValueProcessed = null;

    /**
     * @type {number|null}
     */
    this.cashBackValue = null;

    this.gratuityValue = null;

    /**
     * A copy of the PAN field from the .in file (if card details are keyed) or the PAN derived from Track2/ICC.
     * Please note: This value will not be returned in full due to PCI requirements.
     */
    this.pan = null;

    /**
     * Card Expiry Month and Year.
     */
    this.expiryDate = null;

    /**
     * Card Issue Number. Blank when scheme does not provide an issue number
     */
    this.issueNumber = null;

    /**
     * Card start month and year.
     */
    this.startDate = null;

    /**
     * CCYYMMDDHHMMSS
     */
    this.transactionDate = null;

    /**
     * The Merchant Number for the given card scheme and account.
     */
    this.merchantNumber = null;

    /**
     * Terminal ID used for this transaction.
     */
    this.terminalId = null;

    /**
     * Card scheme name
     * Please note: American Express ExpressPay contactless transactions are not supported
     */
    this.schemeName = null;

    /**
     * Floor limit for the card scheme/account.
     */
    this.floorLimit = null;

    /**
     * Four digits in the range 0001 – 9999. (Prefixed with "OL" when offline)
     */
    this.eftSequenceNumber = null;

    /**
     * Blank if the transaction is declined or is below the floor limit.
     */
    this.authCode = null;

    /**
     * Used to return the voice referral phone number when in Screenless/UPT modes.
     */
    this.referralTelephoneNumber = null;

    /**
     * As returned by communications process. Normally direct from acquirer.
     * Also contains status message if enabled.
     *
     * @type {string|null}
     */
    this.message = null;

    /**
     * Valid values are:
     * Contactless
     * Swipe
     * ICC
     * Keyed
     */
    this.captureMethod = null;

    /**
     * Currency Code associated with the transaction
     */
    this.transactionCurrencyCode = null;

    /**
     * Transaction value based on the original base currency
     */
    this.originalTransactionValue = null;

    /**
     * Cashback value based on the original base currency
     */
    this.originalCashBackValue = null;

    /**
     * Gratuity Value based on the original base currency
     */
    this.originalGratuityValue = null;

    /**
     * Currency code associated with the base currency
     */
    this.originalTransactionCurrencyCode = null;

    /**
     * This is the result of the Account on File registration.
     * Valid values are:
     * '0' - Not Set
     * '1' - Not Performed
     * '2' - Success
     * '3' - Failed
     *
     * @type {number|null}
     */
    this.accountOnFileRegistrationResult = null;

    /**
     * This is the token allocated to the payment details as part of the Account on File registration process
     * or the token used for the Account on File payment.
     *
     * @type {string|null}
     */
    this.tokenId = null;

    /**
     * This is the result of any AVS post code checking.
     * Valid values are:
     * '0' - Unknown
     * '1' - Not Checked
     * '2' - Matched
     * '4' - Not Matched
     * '8' - Reserved
     */
    this.avsPostCodeResult = null;

    /**
     * This is the result of any AVS house number checking.
     * Valid values are:
     * '0' - Unknown
     * '1' - Not Checked
     * '2' - Matched
     * '4' - Not Matched
     * '8' - Reserved
     */
    this.avsHouseNumberResult = null;

    /**
     * This is the result of any CSC verification.
     * Valid values are:
     * '0' - Unknown
     * '1' - Not Checked
     * '2' - Matched
     * '4' - Not Matched
     * '8' - Reserved
     */
    this.cscResult = null;

    /**
     * This is a SHA-256 hash of the PAN with a merchant specific salt.
     *
     * @type {string|null}
     */
    this.cardNumberHash = null;

    /**
     * This is the VGIS reference generated if the transaction was flagged as a VGIS transaction
     * (see Procurement Guide for further information)
     *
     * @type {string|null}
     */
    this.vgisReference = null;

    /**
     * Data captured from the Track1 Discretionary Data field, e.g. Loyalty Card Number.
     *
     * @type {string|null}
     */
    this.track1DiscretionaryData = null;

    /**
     * The donation value which has been incorporated as part of the transaction value.
     *
     * @type {number|null}
     */
    this.charityDonationValue = null;

    /**
     * The charitable donation provider allocated merchant number.
     *
     * @type {string|null}
     */
    this.charityDonationMerchantNumber = null;

    /**
     * The donation value which has been incorporated as part of the transaction value prior to currency
     * conversion being performed (if applicable).
     *
     * @type {string|null}
     */
    this.originalCharityDonationValue = null;

    /**
     * This is the ID of the transaction assigned by the hosted service.
     *
     * @type {number|null}
     */
    this.transactionId = null;

    /**
     * This is the name of the authorisation server that processed the transaction.
     *
     * @type {string|null}
     */
    this.authorisationServerName = null;

    /**
     * This is the ID of the card scheme. See below:
     * 0 - Unknown
     * 1 - Amex
     * 2 - Visa
     * 3 - MasterCard
     * 4 - Maestro
     * 5 - Diners
     * 6 - Visa Debit
     * 7 - JCB
     * 8 - BT Test Host
     * 9 - Ventura
     * 10 - Solo
     * 11 - Electron
     * 21 - Visa CPC
     * 23 - AllStar CPC
     * 24 - EDC-Maestro
     * 25 - Laser
     * 26 - LTF
     * 27 - CAF
     * 28 - Creation
     * 29 - Clydesdale
     * 30 - Style
     * 31 - BHS Gold
     * 32 - Mothercare Card
     * 33 - Burton Menswear
     * 35 - BA AirPlus
     * 36 - Amex CPC
     * 48 - Premier Inn Card
     * 49 - Debit MasterCard
     * 50 - Stax Charge Card
     * 51 - IKEA Home Card
     * 52 - Mastercard One Card
     * 53 - HFC Store Card
     * 999 - Invalid Card Range
     *
     * @type {number|null}
     */
    this.cardSchemeId = null;

    /**
     * @type {string}
     */
    this.rawResponse = null;

    /**
     * A custom HTML string to display this record's message instead of the default message text.
     *
     * @type {string|null}
     */
    this.messageHtml = null;
};

VerifoneTransactionResponseRecord.prototype = {
    getHydrationMap() {
        return {
            // Initial
            result: { field: 1, transform: toInt },
            totalTransactionValueProcessed: { field: 3, transform: toFloat },
            cashBackValue: { field: 4, transform: toFloat },
            gratuityValue: { field: 5, transform: toFloat },
            pan: { field: 6 },
            expiryDate: { field: 7 },
            issueNumber: { field: 8 },
            startDate: { field: 9 },
            transactionDate: { field: 10 },
            merchantNumber: { field: 11 },
            terminalId: { field: 12 },
            schemeName: { field: 13 },
            floorLimit: { field: 14 },
            eftSequenceNumber: { field: 15 },
            authCode: { field: 16 },
            referralTelephoneNumber: { field: 17 },
            message: { field: 18 },
            // Version 2
            captureMethod: { field: 19 },
            transactionCurrencyCode: { field: 20 },
            originalTransactionValue: { field: 21, transform: toFloat },
            originalCashBackValue: { field: 22, transform: toFloat },
            originalGratuityValue: { field: 23, transform: toFloat },
            originalTransactionCurrencyCode: { field: 24 },
            accountOnFileRegistrationResult: { field: 27 },
            tokenId: { field: 28 },
            avsPostCodeResult: { field: 29 },
            avsHouseNumberResult: { field: 30 },
            cscResult: { field: 31 },
            // Version 3
            cardNumberHash: { field: 32 },
            // Version 4
            vgisReference: { field: 33 },
            // Version 5
            track1DiscretionaryData: { field: 34 },
            // Version 6
            charityDonationValue: { field: 35 },
            charityDonationMerchantNumber: { field: 36 },
            originalCharityDonationValue: { field: 37 },
            // Version 7
            transactionId: { field: 38 },
            authorisationServerName: { field: 39 },
            // Version 8
            cardSchemeId: { field: 40 },

            rawResponse: {}
        };
    },

    /**
     * Create a Payment object from this record.
     *
     * @param {boolean} isRefund
     *
     * @returns {DigiTickets.Payment}
     */
    toPayment(isRefund) {
        let payment = new DigiTickets.Payment();

        // There seems to be no way to tell if this was a refund from the record itself.
        // So the isRefund parameter is passed in and the value is negated if true.
        if (isRefund) {
            payment.setTendered(-this.totalTransactionValueProcessed);
        } else {
            payment.setTendered(this.totalTransactionValueProcessed);
        }

        payment.setCardTypeRef(this.getCardTypeRef());
        payment.setCashback(this.cashBackValue);
        payment.setGratuity(this.gratuityValue);
        payment.setLastDigits(this.pan.substr(-4));
        payment.setStatusDetail(this.rawResponse);
        payment.setToken(this.tokenId); // Account on File token.
        payment.setAuthCode(this.authCode);

        return payment;
    },

    /**
     * Map Verifone card scheme IDs to DigiTickets card refs.
     *
     * See DigiTickets\Payment\CardTypeRef in app for the full list of our possible refs.
     *
     * @return {string|null}
     */
    getCardTypeRef() {
        let cardSchemeIdRefs = {
            1: DigiTickets.CardTypeRef.AMEX,
            2: DigiTickets.CardTypeRef.VISA_CREDIT,
            3: DigiTickets.CardTypeRef.MASTERCARD,
            4: DigiTickets.CardTypeRef.MAESTRO,
            5: DigiTickets.CardTypeRef.DINERS,
            6: DigiTickets.CardTypeRef.VISA_DEBIT,
            7: DigiTickets.CardTypeRef.JCB,
            11: DigiTickets.CardTypeRef.VISA_ELECTRON,
            25: DigiTickets.CardTypeRef.LASER
        };
        if (this.cardSchemeId && cardSchemeIdRefs.hasOwnProperty(this.cardSchemeId)) {
            return cardSchemeIdRefs[this.cardSchemeId];
        }

        return null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneTransactionResponseRecord;
}
