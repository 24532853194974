const apiErrorMessage = require('./Api/apiErrorMessage');
const PinRequestCtrl = require('../../controllers/modal/PinRequestCtrl');
const { md5Hash } = require('../../functions/hash');

/**
 * @param $q
 * @param {NavigationService} navigationService
 * @param PrivilegesResource
 * @param {UserService} UserService
 * @param $modal
 * @param Logger
 * @param {Hydrator} hydrator
 */
DigiTickets.PrivilegesManager = function PrivilegesManager(
    $q,
    navigationService,
    PrivilegesResource,
    UserService,
    $modal,
    Logger,
    hydrator
) {
    this.$q = $q;
    this.deferred = $q.defer();

    this.navigationService = navigationService;
    this.hydrator = hydrator;
    this.logger = Logger;
    this.$modal = $modal;
    this.privilegesResource = PrivilegesResource;
    this.userService = UserService;

    /**
     * @private
     *
     * @type {DigiTickets.Privileges[]}
     */
    this.privileges = [];
    this.privilegesLoaded = false;
    this.privilegesLoading = false;

    /**
     * Array of callbacks to fire once the privileges are loaded.
     *
     * @type {Function[]}
     */
    this.privilegesLoadedCallbacks = [];

    /**
     * The name and hash of the last privilege checked for by this.checkCurrentUserPrivilege()
     *
     * @type {{name: string|null, hashedName: string|null}}
     */
    this.lastRequestedPrivilege = {
        name: null,
        hashedName: null
    };
};

DigiTickets.PrivilegesManager.prototype = {

    /**
     * Populate this.privileges from the API.
     */
    loadPrivileges: function loadPrivileges() {
        let self = this;

        self.privilegesLoading = true;

        this.privilegesResource.query(
            function (privileges) {
                self.privileges = self.hydrator.hydrateArray(privileges, function () {
                    return new DigiTickets.Privileges();
                });

                self.privilegesLoaded = true;

                for (let i = 0; i < self.privilegesLoadedCallbacks.length; i++) {
                    self.privilegesLoadedCallbacks[i]();
                }
                self.privilegesLoadedCallbacks = [];

                self.privilegesLoading = false;

                self.deferred.resolve(self.privileges);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return this.deferred.promise;
    },

    getPrivileges: function getPrivileges() {
        return this.deferred.promise;
    },

    /**
     * Fire the given callback once the privileges have been loaded from the API.
     * (Fires instantly if already loaded)
     *
     * @param {Function} callback
     */
    loadPrivilegesThen: function loadPrivilegesThen(callback) {
        if (this.privilegesLoaded) {
            // The timeout prevents problems with promises resolving instantly.
            setTimeout(function () {
                callback();
            }, 1);
        } else {
            this.privilegesLoadedCallbacks.push(callback);
            if (!this.privilegesLoading) {
                this.loadPrivileges();
            }
        }
    },

    /**
     * Return the set of privileges for the given userID.
     *
     * @param {number} userID
     *
     * @returns {DigiTickets.Privileges|null}
     */
    findPrivilegesByUserID: function findPrivilegesByUserID(userID) {
        for (let i = 0; i < this.privileges.length; i++) {
            if (this.privileges[i].userID === userID) {
                return this.privileges[i];
            }
        }
        return null;
    },

    /**
     * Return the set of privileges for the given username.
     *
     * @param {string} username
     *
     * @returns {DigiTickets.Privileges|null}
     */
    findPrivilegesByUsername: function findPrivilegesByUsername(username) {
        for (let i = 0; i < this.privileges.length; i++) {
            if (this.privileges[i].username === username) {
                return this.privileges[i];
            }
        }
        return null;
    },

    /**
     * Return the set of privileges for the given pin.
     *
     * @param {string} pin
     *
     * @returns {DigiTickets.Privileges|null}
     */
    findPrivilegesByPin: function findPrivilegesByPin(pin) {
        let hash = md5Hash(pin, true);
        return this.privileges.find((p) => p.pin && p.pin.toUpperCase() === hash) || null;
    },

    /**
     * Return the set of privileges for the given RFID tag ID.
     *
     * @param {string} rfidTagId
     *
     * @returns {DigiTickets.Privileges|null}
     */
    findPrivilegesByRfidTagId: function findPrivilegesByRfidTagId(rfidTagId) {
        // User's RFID tags are loaded after being md5-hashed, so we need to convert
        // to upper case and then md5-hash the one passed in to guarantee a match.
        let hash = md5Hash(rfidTagId.toUpperCase(), true);
        return this.privileges.find((p) => p.rfidTagId && p.rfidTagId.toUpperCase() === hash) || null;
    },

    /**
     * Method to find a users privileges given an access method and the value of
     * the relevant "thing". Eg 'pin' & '1234'.
     *
     * @param {string} method One of 'pin', 'rfid-tag' or any future value.
     * @param {string} value The value of the PIN, tag, or whatever.
     *
     * @returns {DigiTickets.Privileges|null}
     */
    findPrivileges: function findPrivileges(method, value) {
        // Return the value of the specialised function, depending on the method.
        switch (method) {
            case 'userID':
                return this.findPrivilegesByUserID(value);
            case 'username':
                return this.findPrivilegesByUsername(value);
            case 'pin':
                return this.findPrivilegesByPin(value);
            case 'rfid-tag':
                return this.findPrivilegesByRfidTagId(value);
            default:
                // Didn't recognise the method - return "didn't match a user".
                return null;
        }
    },

    /**
     * Returns a promise which is resolved if the current user has the specified privilege.
     * If they do not, prompts for supervisor PIN and resolves the promise if an appropriate PIN in entered.
     * The promise will return the user ID of the user that granted the privilege if appropriate.
     * The promise will be rejected if the user does not have the privilege and has not been granted it.
     *
     * A user has a privilege if *any* of the View/Edit/Add/Delete options are checked for that privilege
     * in the Back Office (no need to check them all).
     *
     * @param {string} privilegeName
     *
     * @return {Promise<DigiTickets.PrivilegeCheckResult>}
     */
    requirePrivilege: function requirePrivilege(privilegeName) {
        let self = this;

        this.logger.info('Checking for ' + privilegeName + ' privilege.');

        let deferred = this.$q.defer();

        let successCallback = function successCallback(elevatedUserID) {
            deferred.resolve(elevatedUserID);
            self.flushTempPrivilege(privilegeName);
        };

        this.loadPrivilegesThen(function () {
            // If the logged-in user has the privilege, just return true.
            // Or if they have been temporarily granted the privilege, return true.
            let checkResult = self.checkCurrentUserPrivilegeWithGranter(privilegeName);
            if (checkResult.granted) {
                successCallback(checkResult);
                return;
            }

            // Otherwise, need to pop up the PIN request, etc.
            // Remember what callback to execute if the user manages to enter a valid a PIN.
            // Convert the privilege name into a nice sentence.

            let friendlyPrivilegeName = privilegeName.replace(/_/g, ' ');

            friendlyPrivilegeName = friendlyPrivilegeName.substring(0, 1).toUpperCase()
                + friendlyPrivilegeName.substring(1);

            friendlyPrivilegeName = friendlyPrivilegeName.ucwords();

            let result = new DigiTickets.PrivilegeCheckResult(privilegeName);

            self.showPinRequest(friendlyPrivilegeName).then(
                function (elevatedUserID) {
                    // Correct PIN entered

                    result.granted = true;
                    result.grantedBy = elevatedUserID;

                    successCallback(result);
                },
                function () {
                    // PIN cancelled
                    deferred.reject(result);
                }
            );
        });

        return deferred.promise;
    },

    /**
     * If the current user does not have the spcified privilege redirect to the 403 page.
     *
     * @param {string} privilegeName
     *
     * @return {$q} Returns a promise that is resolved if the current user has the privilege, or rejected
     *              if they do not and the user has been redirected.
     *              Both receive a DigiTickets.PrivilegeCheckResult object.
     */
    requirePrivilegeOr403: function requirePrivilegeOr403(privilegeName) {
        let self = this;
        let deferred = this.$q.defer();

        this.checkCurrentUserPrivilege(privilegeName).then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                // Has privilege
                deferred.resolve(result);
            },
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                // No privilege
                self.navigationService.view403();
                deferred.reject(result);
            }
        );

        return deferred.promise;
    },

    /**
     * Check if the logged in user has the specified privilege, or that privilege was
     * the last one granted to them by a supervisor.
     *
     * @param {string} privilegeName Plaintext privilege name
     *
     * @return {DigiTickets.PrivilegeCheckResult}
     */
    checkCurrentUserPrivilegeWithGranter: function checkCurrentUserPrivilegeWithGranter(privilegeName) {
        this.saveLastRequestedPrivilege(privilegeName);

        let result = new DigiTickets.PrivilegeCheckResult(privilegeName);

        let loggedInPrivs = this.findPrivilegesByUsername(this.userService.username);
        if (loggedInPrivs !== null) {
            let hasTemporaryPrivilegeGrantedBy = loggedInPrivs.hasTemporaryPrivilege(
                this.lastRequestedPrivilege.hashedName
            );

            if (hasTemporaryPrivilegeGrantedBy !== false) {
                // The logged in user has recently been temporarily granted this privilege by a supervisor.
                // Return the user ID that granted it.
                result.granted = true;
                result.grantedBy = hasTemporaryPrivilegeGrantedBy;
                return result;
            }

            if (loggedInPrivs.hasPrivilege(this.lastRequestedPrivilege.hashedName)) {
                // The logged-in user has the privilege anyway, so we're good to go.
                // Return boolean true.
                result.granted = true;
                return result;
            }
        }

        return result;
    },

    /**
     * Performs the same check as checkCurrentUserPrivilegeWithGranter but returns a promise instead
     * of the result directly. The promise contains just a boolean, not who granted it.
     *
     * @param {string} privilegeName Plaintext privilege name
     *
     * @returns {$q} When resolved, returns a DigiTickets.PrivilegeCheckResult to the callback.
     */
    checkCurrentUserPrivilege: function checkCurrentUserPrivilege(privilegeName) {
        let self = this;
        let deferred = this.$q.defer();

        this.loadPrivilegesThen(function () {
            let result = self.checkCurrentUserPrivilegeWithGranter(privilegeName);
            if (result.granted) {
                deferred.resolve(result);
            } else {
                deferred.reject(result);
            }
        });

        return deferred.promise;
    },

    /**
     * Check if a different user has the specified privilege.
     *
     * @param {number} userID
     * @param {string} privilegeName Plaintext privilege name
     *
     * @returns {$q} When resolved, returns a DigiTickets.PrivilegeCheckResult to the callback.
     */
    checkUserPrivilege: function checkUserPrivilege(userID, privilegeName) {
        let self = this;
        let deferred = this.$q.defer();

        this.loadPrivilegesThen(function () {
            let result = new DigiTickets.PrivilegeCheckResult(privilegeName);

            let privileges = self.findPrivilegesByUserID(userID);
            if (privileges) {
                if (privileges.hasPrivilege(self.generatePrivilegeHash(privilegeName))) {
                    result.granted = true;
                    result.grantedBy = userID;
                    deferred.resolve(result);
                } else {
                    deferred.reject(result);
                }
            } else {
                deferred.reject(result);
            }
        });

        return deferred.promise;
    },

    /**
     * Performs the same check as checkCurrentUserPrivilegeWithGranter but returns a promise instead
     * of the result directly. The promise contains just a boolean, not who granted it.
     *
     * @param {string[]} privilegeNames Array of plaintext privilege names.
     *
     * @returns {$q} When resolved, returns an object keyed by the plaintext privilege names each with a
     *               DigiTickets.PrivilegeCheckResult as a value.
     *               Note that this promise is always resolved. Check each result for if it was granted.
     */
    checkMultipleCurrentUserPrivileges: function checkCurrentUserPrivilege(privilegeNames) {
        let self = this;
        let deferred = this.$q.defer();

        this.loadPrivilegesThen(function () {
            let results = {};

            for (let i = 0; i < privilegeNames.length; i++) {
                let privilegeName = privilegeNames[i];
                results[privilegeName] = self.checkCurrentUserPrivilegeWithGranter(privilegeName);
            }

            deferred.resolve(results);
        });

        return deferred.promise;
    },

    /**
     * Clear the temporary granted privilege for the current user if it is the same as the given privilege name.
     *
     * @param {string} privilegeName Plaintext privilege name
     *
     * @return {boolean}
     */
    flushTempPrivilege: function flushTempPrivilege(privilegeName) {
        let loggedInPrivs = this.findPrivilegesByUsername(this.userService.username);
        if (loggedInPrivs !== null) {
            let hashedPrivilegeName = this.generatePrivilegeHash(privilegeName);
            if (loggedInPrivs.hasTemporaryPrivilege(hashedPrivilegeName) !== false) {
                loggedInPrivs.clearTemporaryPrivilege();
            }
            return true;
        }

        return false;
    },

    /**
     * @param popUpTitle
     *
     * @return {$q} Returns a promise that is resolved when a correct PIN is entered. Rejected when the modal is
     *     cancelled.
     */
    showPinRequest: function showPinRequest(popUpTitle) {
        let self = this;

        let deferred = this.$q.defer();

        let modalInstance = this.$modal.open({
            templateUrl: 'partials/modals/pinRequest.html',
            controller: PinRequestCtrl,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in pin-window',
            resolve: {
                privilegesManager: function privilegesManager() {
                    return self;
                },
                requestReason: function requestReason() {
                    return popUpTitle;
                }
            }
        });
        modalInstance.result.then(
            function success(elevatedUserID) {
                deferred.resolve(elevatedUserID);
            },
            function error() {
                deferred.reject();
            }
        );

        return deferred.promise;
    },

    grantTemporaryPrivilege: function grantTemporaryPrivilege(method, enteredPin) {
        // If the set of privileges for the user with the matching PIN has the
        // privilege we're asking for, then set the logged-in user to temporarily have
        // that privilege.
        let pinPrivs = this.findPrivileges(method, enteredPin);
        if (pinPrivs !== null) {
            if (pinPrivs.hasPrivilege(this.lastRequestedPrivilege.hashedName)) {
                // The user with that PIN has the privilege.
                // Temporarily grant the privilege to the logged-in user.
                let loggedInPrivs = this.findPrivilegesByUsername(this.userService.username);
                if (loggedInPrivs != null) {
                    loggedInPrivs.setTemporaryPrivilege(
                        this.lastRequestedPrivilege.hashedName,
                        pinPrivs.userID
                    );

                    // Log the fact that we did this. Could be important.
                    this.logger.info(
                        'User "' + pinPrivs.username + '" granted approval for: ' + this.lastRequestedPrivilege.name + '.'
                    );
                    return pinPrivs.userID;
                }
            }
        }
        return false;
    },

    /**
     * Remember the name of the last privilege the system is asking for (in checkCurrentUserPrivilegeWithGranter).
     * It sounds trivial, but we actually need the plain text value and the hashed value.
     *
     * @param {string} privilegeName Plaintext privilege name
     */
    saveLastRequestedPrivilege: function saveLastRequestedPrivilege(privilegeName) {
        this.lastRequestedPrivilege.name = privilegeName;
        this.lastRequestedPrivilege.hashedName = this.generatePrivilegeHash(privilegeName);
    },

    generatePrivilegeHash: function generatePrivilegeHash(privilegeName) {
        return md5Hash(privilegeName);
    }
};
