/**
 * Creates instances of DigiTickets.NumPad. This factory is useful because NumPad
 * has some dependencies. This factory class supplies the dependencies so whoever wants an instance
 * doesn't have to.
 *
 * @param $timeout
 */
DigiTickets.NumPadFactory = function (
    $timeout
) {
    this.$timeout = $timeout;
};

DigiTickets.NumPadFactory.prototype = {
    /**
     * @param {object} options
     * @param {function} [onChangeCallback]
     *
     * @return {DigiTickets.NumPad}
     */
    create: function create(options, onChangeCallback) {
        return new DigiTickets.NumPad(
            this.$timeout,
            options,
            onChangeCallback
        );
    }
};
