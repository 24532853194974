/**
 * @param $resource
 * @param ApiRequestParams
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {UserService} UserService
 * @returns {*}
 */
const StashedCartResource = function ($resource, ApiRequestParams, AppConfig, UserService) {
    return $resource(
        AppConfig.apiBaseUrl + 'stashedcarts/:guid',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                branchID: function branchID() {
                    let currentBranchID = UserService.currentBranch ? UserService.currentBranch.getId() : null;
                    let branches = [];
                    if (currentBranchID) {
                        branches.push(currentBranchID);
                    }
                    branches
                        .push
                        .apply(
                            branches,
                            UserService.branches
                                .map(function (branch) {
                                    return branch.getId();
                                })
                                .filter(function (branchID) {
                                    return !currentBranchID || branchID != currentBranchID;
                                })
                        );
                    return branches.join();
                }
            }
        ),
        {
            update: {
                method: 'PUT'
            },
            queryUnfulfilled: {
                isArray: true,
                method: 'GET',
                url: AppConfig.apiBaseUrl + 'stashedcarts/unfulfilled',
                params: {
                    resolve: '*'
                }
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = StashedCartResource;
}
