/**
 * Provides functions for dealing with custom fields and their data.
 */
DigiTickets.FieldHelper = {
    /**
     * Remove values from {fieldData} where the key no longer exists in {fields}.
     * Returns only data that still relates to fields in the given collection.
     *
     * @param {FieldGroupCollection} collection
     * @param {object} fieldData
     * @param {string} fieldGroupingType
     *
     * @return {object}
     */
    cleanFieldData: function cleanFieldData(collection, fieldData, fieldGroupingType) {
        let cleanedData = {};

        if (collection != null) {
            collection.forEachInstance(function (instance) {
                // We only want to interact with the right field grouping type.
                let process = false;
                switch (fieldGroupingType) {
                    case DigiTickets.FieldGroupingType.ALL_FIELDS:
                        process = true;
                        break;
                    case DigiTickets.FieldGroupingType.ASK_EARLY_FIELDS_ONLY:
                        process = instance.field.askEarly;
                        break;
                    case DigiTickets.FieldGroupingType.NON_ASK_EARLY_FIELDS_ONLY:
                        process = !instance.field.askEarly;
                        break;
                    default:
                        console.trace('Missing value for fieldGroupingType for cleanFieldData');
                        break;
                }

                if (process) {
                    let key = instance.getKey();
                    if (fieldData.hasOwnProperty(key)) {
                        cleanedData[key] = fieldData[key];
                    }
                }
            });
        }

        return cleanedData;
    }
};
