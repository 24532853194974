const CustomerScreenQuestion = require('../../models/CustomerScreenQuestion');
const CustomerScreenQuestionType = require('../../models/CustomerScreenQuestionType');

/**
 * @param $modalInstance
 * @param $scope
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {ToastFactory} toastFactory
 */
const DesignTestModalCtrl = function DesignTestModalCtrl(
    $modalInstance,
    $scope,
    CustomerScreenDataService,
    toastFactory
) {
    $scope.ok = function ok() {
        $modalInstance.close();
    };

    $scope.showTestToast = (style) => {
        toastFactory[style]('ABOUT.TEST', new Date().toHMS());
    };

    $scope.showTestToastTop = (style) => {
        toastFactory[style + 'Top']('ABOUT.TEST', new Date().toHMS());
    };

    $scope.showTestSpinnerToast = () => {
        let p = new Promise((resolve) => {
            setTimeout(resolve, 3000);
        });

        toastFactory.spinner(
            'PROCESSING',
            'This will disappear in 3 seconds.',
            p,
            null
        );
    };

    $scope.showTestToastWithButton = () => {
        toastFactory.info(
            'ABOUT.TEST',
            'Click me. <button class="btn btn-default btn-sm">With a Button</button>',
            {
                timeOut: 5000,
                extendedTimeOut: 5000,
                onclick: () => {
                    window.alert('You clicked it.');
                }
            }
        );
    };

    $scope.askTestBooleanQuestion = function () {
        let question = new CustomerScreenQuestion(
            CustomerScreenQuestionType.BOOLEAN,
            'Does <em>this</em> work?'
        );

        CustomerScreenDataService
            .askQuestion(question)
            .then((res) => {
                console.log('Answer was ' + (res ? 'yes' : 'no'));
            })
            .catch((res) => {
                console.log('Rejected', res);
            });
    };

    $scope.askTestOptionsQuestion = function () {
        let question = new CustomerScreenQuestion(
            CustomerScreenQuestionType.OPTIONS,
            'Which is the best at ' + ((new Date()).toHMS()) + '?',
            [
                {
                    value: 'dogs',
                    label: 'Dogs'
                },
                {
                    value: 'cats',
                    label: 'Cats'
                },
                {
                    value: 'rabbits',
                    label: 'Rabbits'
                },
            ]
        );

        CustomerScreenDataService
            .askQuestion(question)
            .then((res) => {
                console.log('Option answered ' + res);
            })
            .catch((res) => {
                console.log('Rejected', res);
            });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DesignTestModalCtrl;
}
