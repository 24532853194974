const angular = require('angular');
const moment = require('moment');

const datepickerParserDirective = function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModel) {
            // This parser overrides the default parser in the 3rd-party code, which just passes the value into
            // Date(), and of course because it's American, it recognises the format MM/DD/YYYY and not DD/MM/YYYY.
            // This was cobbled together from various answers here: https://stackoverflow.com/questions/24198669/angular-bootstrap-datepicker-date-format-does-not-format-ng-model-value
            ngModel.$parsers.unshift(function dtDatepickerParser() {
                // If it's already a date object (eg they've clicked on a date in the pop-up), then don't do
                // anything. Just confirm that it's valid.
                if (angular.isDate(ngModel.$viewValue)) {
                    ngModel.$setValidity('date', true);

                    return ngModel.$viewValue;
                }

                // If the User blanked out the field, it is deemed to be valid but the model needs to be set to null
                if (ngModel.$viewValue === '') {
                    ngModel.$setValidity('date', true);
                    return null;
                }

                // Build a date object, using moment, and using the user-defined format if possible.

                // If the user hasn't finished entering the date, assume it's not valid - the trouble is, if
                // they've only entered the day, it'll assume "this month" and "this year", which is almost
                // certainly not what they intend.
                ngModel.$setValidity('date', false); // Assume it's not valid for now.
                let dateParts = ngModel.$viewValue.split('/');
                if (dateParts.length !== 3) {
                    return null; // Doesn't have exactly 3 slashes.
                }
                if (dateParts[0].length === 0 || dateParts[1].length === 0 || dateParts[2].length < 2) {
                    return null; // day or month or year too short
                }

                // Note: the user-defined format sometimes has to have lower case letters, and we can't make them
                // upper case in the property because it screws up if you click on a date in the pop-up, hence doing
                // it here, in a transient variable.
                let dateFormat = attr.hasOwnProperty('datepickerPopup') ? attr.datepickerPopup.toUpperCase() : 'DD/MM/YYYY';
                let parsedDate = new moment(ngModel.$viewValue, dateFormat);
                // Set the data model's validity accordingly.
                ngModel.$setValidity('date', parsedDate.isValid());

                // Return the date or null, depending on whether it's valid.
                return parsedDate.isValid() ? parsedDate.toDate() : null;
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = datepickerParserDirective;
}
