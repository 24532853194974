const itemPanelDirective = function () {
    return {
        restrict: 'E',
        scope: {
            addItem: '=',
            startEventBooking: '=',
            itemCollection: '='
        },
        templateUrl: 'partials/sell/item-panel.html',
        controller: function ($scope) {
            $scope.categories = $scope.itemCollection.categories;

            $scope.items = [];

            /**
             * @type {Category}
             */
            $scope.selectedCategory = null;

            /**
             * @param {Category} newCategory
             */
            $scope.selectCategory = function selectCategory(newCategory) {
                // Highlight new category
                $scope.selectedCategory = newCategory;

                // Find all items in the newly-selected category
                const items = newCategory ? $scope.itemCollection.items[newCategory.ID] : [];

                // Get a filtered subset of items that are not linked to an event
                $scope.items = items.filter((item) => !item.event);

                // Get any events covered by tickets in this category
                const events = items.reduce((prev, item) => {
                    if (item.event) {
                        if (prev[item.event.ID]) {
                            prev[item.event.ID].numTickets++;
                        } else {
                            prev[item.event.ID] = {
                                event: item.event,
                                numTickets: 1
                            };
                        }
                    }
                    return prev;
                }, {});

                // Build a wrapped collection of events that has the meta data to show number of tickets, etc
                $scope.eventButtons = Object.values(events);
            };

            /**
             * @param {DigiTickets.Event} event
             */
            $scope.onEventButtonClicked = (event) => {
                $scope.startEventBooking(event, $scope.selectedCategory);
            };

            if ($scope.categories.length > 0) {
                // Select first category by default
                $scope.selectCategory(
                    $scope.categories[0]
                );
            }
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = itemPanelDirective;
}
