DigiTickets.OrderItemGroup = function (id, eventID, name) {
    this.id = id || null;
    /**
     * @type {OrderLine[]}
     */
    this.items = [];
    this.eventID = eventID || null;
    this.name = name || null;
};

DigiTickets.OrderItemGroup.prototype = {
    hasName: function hasName() {
        return this.name != null;
    },

    getRenewingMemberships: function getRenewingMemberships() {
        // Return an array of any membership that the user wishes to renew.
        // This method looks at all the items in this group that are memberships (and the observant among you
        // will note that either all the items are memberships, or none of them are), and return any that
        // user has chosen to renew.
        let result = [];
        for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
            let orderLine = this.items[itemIndex];
            if (orderLine.item && orderLine.item.itemType === DigiTickets.ItemType.MEMBERSHIP_PLAN) {
                for (let instanceIndex = 0; instanceIndex < orderLine.iteminstances.length; instanceIndex++) {
                    let itemInstance = orderLine.iteminstances[instanceIndex];
                    if (itemInstance.membershipSubscription && itemInstance.membershipSubscription.membership && itemInstance.membershipSubscription.membership.isRenewing()) {
                        result.push(itemInstance.membershipSubscription.membership);
                    }
                }
            }
        }

        return result;
    },

    anyRenewingMemberships: function anyRenewingMemberships() {
        // Return true if the user is renewing one or more membership; false otherwise.
        return this.getRenewingMemberships().length > 0;
    },

    clearMembershipRenewing: function clearMembershipRenewing() {
        // Go through all memberships in this group, and make sure they are not renewing.
        for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
            let orderLine = this.items[itemIndex];
            if (orderLine.item.itemType == 'Membership Plan') {
                for (let instanceIndex = 0; instanceIndex < orderLine.iteminstances.length; instanceIndex++) {
                    if (orderLine.iteminstances[instanceIndex].membershipSubscription && orderLine.iteminstances[instanceIndex].membershipSubscription.membership) {
                        orderLine.iteminstances[instanceIndex].membershipSubscription.membership.resetRenew();
                    }
                }
            }
        }
    }
};
