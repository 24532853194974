const SessionAvailabilityResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'sessionavailability',
        ApiRequestParams.defaults(),
        {
            query: {
                method: 'POST'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SessionAvailabilityResource;
}
