DigiTickets.RfidScanDetector = function (options, angular) {
    this.defaults = {
        event: 'RfidTagScanned', // the event name that will be triggered
        // Be careful if you change the min number of chars in the regexp below because it might then pick up scans of booking ref barcodes.
        regexp: '[A-Z0-9]{9,14}', // match only certain tag id patterns (9-14 alpha-numeric characters).
        prefix: '', // optional prefix to check for
        maxTimeBetweenInputs: 100, // time in milliseconds to wait between each character input from the scanner
        suppressReturnEvent: true, // stop the 'return' character from submitting forms etc, when a scan occurs
        suppressKeyEvent: true // stop characters being displayed in input elements when a scan occurs
    };
    this.options = angular.extend(this.defaults, options);
    this.lastInputAt = new Date();
    this.buffer = '';
};

DigiTickets.RfidScanDetector.prototype = {

    keyDownReceived: function keyDownReceived(event, scope) {
        let now = new Date();
        let delay = now - this.lastInputAt;

        // Ignore the "SHIFT" keydown event (before we set the last input time).
        // There is no keyup event for "SHIFT", of course, because we don't listen for it.
        if (event.which == 16) {
            return;
        }

        this.lastInputAt = now;

        if (delay < this.options.maxTimeBetweenInputs) {
            if (event.which == 13) {
                // the return key was pressed
                let scanExp = new RegExp('^' + this.options.prefix + this.options.regexp + '$');
                if (this.buffer.match(scanExp)) {
                    // suppress the 'enter' key default action if a RFID tag is detected
                    if (this.options.suppressReturnEvent) {
                        event.preventDefault();
                    }

                    scope.$broadcast(this.options.event, {
                        code: this.buffer,
                        time: new Date()
                    });
                }
                this.buffer = '';
            } else {
                // this isn't the return key, but did occur very quickly after last input
                this.buffer += String.fromCharCode(event.which);
            }
        } else {
            // there was a gap between input; clear the buffer and set it to the key that was pressed
            this.buffer = String.fromCharCode(event.which);
        }
    }
};
