/**
 * Show a gift voucher ref in a human-friendly format for reading.
 * Eg. v3fk9q249fv5kk2j becomes V3FK-9Q24-9FV5-KK2J
 *
 * @param {string} ref
 */
const presentSoldGiftVoucherRefFilter = (ref) => (
    (ref || '')
        .toUpperCase()
        .replace(/[\s-]/g, '')
        .match(/.{1,4}/g)
        || []
).join('-');

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = presentSoldGiftVoucherRefFilter;
}
