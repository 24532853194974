DigiTickets.Worldpay.Driver = (function () {
    /**
     * See the Worldpay integration guide "IPC-2 PA-DSS-2.1.9.pdf" for more background on how the Worldpay
     * integration works.
     * In short:
     * - Worldpay's IPC application runs on Windows
     * - ProPoint communicates with it via a socket
     *   (via the "socket-proxy" app: https://bitbucket.org/digitickets/socket-proxy)
     * - Messages are sent/received as a bunch of lines in the format 1=234, where 1 is the field identifier
     *   and 234 is its value. Every message ends with a line "99=0" to signify the end of that message.
     *
     * Guide is at:
     * https://drive.google.com/file/d/1dZARnCoE793lByj2l8me7XVk-Um1EDpN/view?usp=sharing
     *
     * @param $interval
     * @param {DeviceManager} deviceManager
     * @param {Hydrator} hydrator
     * @param Notification
     * @param {ReceiptPrinter} ReceiptPrinterService
     * @param {AgentService} AgentService
     * @param {DigiTickets.Worldpay.Config} WorldpayConfigService
     */
    let Driver = function Driver(
        $interval,
        deviceManager,
        hydrator,
        Notification,
        ReceiptPrinterService,
        AgentService,
        WorldpayConfigService
    ) {
        this.$interval = $interval;
        this.agentService = AgentService;
        this.config = WorldpayConfigService;
        this.deviceManager = deviceManager;
        this.hydrator = hydrator;
        this.notification = Notification;
        this.receiptPrinter = ReceiptPrinterService;

        /**
         * Callbacks to be fired when a message is received from the terminal.
         *
         * @type {function[]}
         */
        this.recordReceivedListeners = [];

        /**
         * @type {TcpSocketConnection}
         */
        this.socket = null;

        /**
         * @type {TcpSocketConnection}
         */
        this.receiptSocket = null;

        /**
         * Keep track of the state of the terminal.
         */
        this.state = {
            /**
             * @type {DigiTickets.Worldpay.TransactionResponseRecord|null}
             */
            lastRecordReceived: null
        };

        /**
         * @type {Array}
         */
        this.queuedRecords = [];

        if (this.config.validate()) {
            this.init();
        } else {
            this.notification.error('The Worldpay payment terminal config is invalid.');
        }
    };

    Driver.prototype = {
        init: function () {
            let self = this;

            this.agentService.onError = function (error) {
                self.notification.error({
                    message: 'There was a problem connecting to the Worldpay payment terminal.',
                    replaceMessage: true
                });
            };

            this.socket = this.createTcpSocket(
                'Worldpay Socket',
                this.config.terminalHost,
                this.config.terminalPort
            );
            this.socket.connect();

            if (this.config.printReceipts) {
                this.receiptSocket = this.createReceiptSocket(
                    'Worldpay Receipt Socket',
                    this.config.terminalHost,
                    this.config.receiptPort
                );
                this.receiptSocket.connect();
            }

            this.$interval(function () {
                self.sendNextQueuedRecord();
            }, 500);
        },

        /**
         * @param {DigiTickets.Worldpay.TransactionRequestRecord} record
         */
        sendRecord: function sendRecord(record) {
            if (this.queuedRecords.length > 0) {
                let lastRecord = this.queuedRecords[this.queuedRecords.length - 1];
                let lastRecordString = this.recordToString(lastRecord);
                if (lastRecordString === this.recordToString(record)) {
                    console.log('Skipping duplicate record', lastRecordString);
                    return false;
                }
            }

            this.queuedRecords.push(record);
        },

        /**
         * @param {DigiTickets.Worldpay.TransactionRequestRecord} record
         */
        sendRecordNow: function sendRecordNow(record) {
            let dataString = this.recordToString(record);
            console.log('Sending Worldpay Data', dataString.replace(/\n/g, ' // '));
            this.socket.sendData(dataString);
        },

        /**
         * To avoid sending records to IPC too quickly, instead of sending them when requested they go into a queue.
         * The next record from the queue is sent in 1 second intervals.
         */
        sendNextQueuedRecord: function sendNextQueuedRecord() {
            let nextRecord = this.queuedRecords.shift();
            if (nextRecord) {
                this.sendRecordNow(nextRecord);
            }
        },

        /**
         * Convert the record object to a string of data to send on the socket.
         *
         * Messages are sent over the socket as one value per line.
         * For example:
         * 1=abc
         * 2=def
         * 70=ghi
         * 99=0
         *
         * Every message ends with 99=0 to signify the end of the message.
         * See the Worldpay integration PDF for more info.
         *
         * @param {DigiTickets.Worldpay.TransactionRequestRecord} record
         *
         * @return {string}
         */
        recordToString: function recordToString(record) {
            let str = '';
            let data = record.toData();
            for (let key in data) {
                if (data.hasOwnProperty(key) && data[key] !== null) {
                    str += key + '=' + data[key] + '\n';
                }
            }
            str += '99=0';

            return str;
        },

        /**
         * Create a new TCP socket object via the WebSocket connection.
         * Setup listeners to handle data and errors.
         *
         * @param {string} name
         * @param {string} host
         * @param {number} port
         *
         * @return {TcpSocketConnection}
         */
        createTcpSocket: function (name, host, port) {
            let self = this;

            let tcpSocket = this.agentService.createTcpSocket(
                name,
                host,
                port
            );

            tcpSocket.onError = function (errorString) {
                self.notification.error({
                    message: 'There was a problem communicating with the payment terminal.<br/><small>' + errorString + '</small>',
                    replaceMessage: true
                });
            };

            // Add listener for data coming from the TCP socket.
            tcpSocket.onData = function (dataString, socket) {
                console.log('Received Worldpay Data', dataString.replace(/\n/g, ' // '));
                let record = self.createRecordFromSocketData(dataString, socket);
                if (record) {
                    self.handleReceivedRecord(record, socket);
                }
            };

            return tcpSocket;
        },

        createReceiptSocket: function (name, host, port) {
            const self = this;

            const tcpSocket = this.agentService.createTcpSocket(
                name,
                host,
                port
            );

            tcpSocket.onConnected = function () {
                console.log('Worldpay Receipt Socket Connected');
            };

            tcpSocket.onError = function (errorString) {
                // TODO: We are currently suppressing this error message as all clients will need to be updated to
                // make IPC not print the receipts and send them over the socket.
                // Once all client settings have been changed uncomment this.

                // self.notification.error({
                //     message: 'There was a problem communicating with the payment terminal\'s receipt port.<br/><small>' + errorString + '</small>',
                //     replaceMessage: true
                // });
            };

            // Add listener for data coming from the TCP socket.
            tcpSocket.onData = function (dataString) {
                console.log('Received Worldpay Receipt Data', dataString);

                // Receipts issued on the Receipt Socket port are prefixed by labels CUSTOMER: and MERCHANT: so
                // that their start point may be readily identified in the stream of data from the socket.
                // These label should removed from the receipt data and not printed.
                dataString = dataString.replace(/^(CUSTOMER|MERCHANT)\:/, '');

                // Replace newlines with <br/> so it can be printed as HTML.
                dataString = dataString.replace(/\n/g, '<br/>');

                self.receiptPrinter.printCardReceipt(dataString);
            };

            return tcpSocket;
        },

        /**
         * Receives the raw string data from a socket and creates a record object from it.
         *
         * @param {string} dataString
         * @param {TcpSocketConnection} fromSocket
         *
         * @return {DigiTickets.Worldpay.TransactionResponseRecord}
         */
        createRecordFromSocketData: function createRecordFromSocketData(dataString, fromSocket) {
            // Split into separate lines
            let dataLines = dataString.split('\n');

            let data = {
                rawResponse: dataString
            };

            for (let i = 0; i < dataLines.length; i++) {
                let line = dataLines[i];
                // Convert from the attribute=value format
                let equalsPos = line.indexOf('=');
                if (!equalsPos) {
                    continue;
                }
                let attribute = line.substr(0, equalsPos);
                data[attribute] = line.substr(equalsPos + 1);
            }

            return this.hydrator.hydrate(
                data,
                new DigiTickets.Worldpay.TransactionResponseRecord()
            );
        },

        /**
         * Do something with a record received from the terminal.
         * Then pass it on to any listeners.
         *
         * @param {DigiTickets.Worldpay.TransactionResponseRecord} record
         * @param {TcpSocketConnection} socket
         */
        handleReceivedRecord: function handleReceivedRecord(record, socket) {
            this.setLastReceivedRecord(record);

            // Tell other places that may be interested in this record (the payment controller).
            this._notifyResponseListeners(record, socket);
        },

        /**
         * Set the last record that was received from the terminal on that socket.
         *
         * @param {object} record
         * @param {TcpSocketConnection} socket
         */
        setLastReceivedRecord: function setLastReceivedRecord(record, socket) {
            this.state.lastRecordReceived = record;
        },

        /**
         * Register a new listener that will be fired when a record is received from the terminal.
         *
         * @param {function} listener
         */
        onRecordReceived: function onRecordReceived(listener) {
            this.recordReceivedListeners.push(listener);
        },

        /**
         * Un-register all record received listeners.
         */
        clearRecordReceivedListeners: function clearRecordReceivedListeners() {
            this.recordReceivedListeners = [];
        },

        /**
         * Notify all the registered response listeners.
         *
         * @param {object} record
         * @param {TcpSocketConnection} socket
         *
         * @private
         */
        _notifyResponseListeners: function _notifyResponseListeners(record, socket) {
            for (let r = 0; r < this.recordReceivedListeners.length; r++) {
                this.recordReceivedListeners[r](record, socket);
            }
        }
    };

    return Driver;
}());
