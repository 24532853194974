const PrintJobOptions = require('./PrintJobOptions');
const PrintType = require('./PrintType');

/**
 * Handles all business logic around what we print and when.
 * The logic of printing itself is now handled by the Printer function.
 *
 * @param {CurrentDevice} CurrentDevice
 * @param {DigiTickets.Logger} Logger
 * @param {LogoService} LogoService
 * @param {Printer} Printer
 * @param {DigiTickets.TemplateManager} TemplateService
 * @param {UserService} UserService
 */
const ReceiptPrinter = function (
    CurrentDevice,
    Logger,
    LogoService,
    Printer,
    TemplateService,
    UserService
) {
    /**
     * Returns the data that should be supplied to every print template.
     * Note: Do not store the result of this as it includes the current time. Call the function each time it is needed.
     *
     * @return {object}
     */
    const defaultPrintTemplateData = () => {
        const printableLogo = LogoService.getPrintableLogoUrl(UserService);

        return {
            branch: UserService.currentBranch,
            company: UserService.company,
            currentUser: UserService.currentUser,
            date: new Date(),
            device: CurrentDevice.device,
            displayLogo: printableLogo !== null,
            printableLogo,

            // Keep the previous 'user' property available in the template for BC because the receipt template in app
            // accesses it.
            // TODO: When receipt templates are moved to epos repo, rename this to userService and update templates.
            user: UserService
        };
    };

    /**
     * Print a no-sale receipt to trigger opening the cash drawer.
     *
     * This may be one of 3 things happening:
     * - The user is opening the drawer for an unknown reason (amount will be null).
     * - The user is adding cash to the drawer. They will have entered how much cash they intend to add and that will
     *   be the amount (although the actual amount added to the drawer may not match - all the we is the amount
     *   they entered!)
     * - The user is removing cash from the drawer. The amount will be int 0 and the receipt will print a blank line
     *   for the user to write how much cash they took.
     *
     * @param {?number} amount Will be null if no sale. int 0 if removing cash from the drawer. Otherwise the amount
     *     they said they would be be added to the drawer.
     * @param {string} reason The reason for opening the cash drawer.
     *
     * @return {Promise<any>}
     */
    const printNoSale = (amount, reason) => {
        Logger.info('Print no sale');

        let opts = new PrintJobOptions(PrintType.NO_SALE);
        return Printer.printLocalTemplate(
            'print/templates/no-sale.html',
            Object.assign(
                defaultPrintTemplateData(),
                {
                    amount,
                    date: new Date(),
                    isNoSale: amount === null,
                    reason
                }
            ),
            opts
        );
    };

    /**
     * Print a receipt for an order before the order is finished (a bill - likely for table service).
     *
     * @param {DigiTickets.Order} order
     *
     * @return {Promise<any>}
     */
    const printBill = (order) => {
        let opts = new PrintJobOptions(
            PrintType.BILL
        );

        return Printer.printLocalTemplate(
            'print/templates/bill.html',
            Object.assign(
                defaultPrintTemplateData(),
                {
                    order,
                    companyTaxRegistration: UserService.company.getTaxRegistrationCodeForCountry(
                        UserService.currentBranch.country
                    )
                }
            ),
            opts
        );
    };

    /**
     * Print the receipt for an order adjustment.
     *
     * @param {OrderAdjustmentResponse} orderAdjustmentResponse
     * @param {{isDuplicate:boolean, slLine:OrderTaxSLLine}} additionalData Data to be sent to the template.
     *
     * @return {Promise<any>}
     */
    const printOrderAdjustmentReceipt = (orderAdjustmentResponse, additionalData) => {
        Logger.info('Print order adjustment', orderAdjustmentResponse);

        let opts = new PrintJobOptions(
            additionalData.isDuplicate ? PrintType.RECEIPT_DUPLICATE : PrintType.RECEIPT
        );
        opts.openCashDrawer = !additionalData.isDuplicate;
        opts.classes = [
            additionalData.slLine ? 'via-sign-pro' : '',
        ];

        // Separate out the removed and added lines from the adjustment order.
        console.log('orderAdjustmentResponse.adjustmentOrder', orderAdjustmentResponse.adjustmentOrder);
        let removedLines = orderAdjustmentResponse.adjustmentOrder.items.filter((i) => i.qty < 0);
        let addedLines = orderAdjustmentResponse.adjustmentOrder.items.filter((i) => i.qty >= 0);

        return Printer.printLocalTemplate(
            'print/templates/adjustment-receipt.html',
            Object.assign(
                defaultPrintTemplateData(),
                {
                    addedLines,
                    adjustedOrder: orderAdjustmentResponse.adjustedOrder,
                    adjustmentOrder: orderAdjustmentResponse.adjustmentOrder,
                    // Anything that refers to 'order' wants the order that just happened, so set that.
                    order: orderAdjustmentResponse.adjustmentOrder,
                    originalOrder: orderAdjustmentResponse.originalOrder,
                    removedLines
                },
                additionalData
            ),
            opts
        );
    };

    /**
     * @param {DigiTickets.Order} order
     * @param {{isDuplicate:boolean, slLine:OrderTaxSLLine, orderAdjustmentResponse:OrderAdjustmentResponse}} additionalData Data to be sent to the template.
     */
    const printOrder = (order, additionalData) => {
        Logger.info('Print order', { orderID: order.ID, bookingRef: order.bookingRef, date: order.date });

        // Convert the SL line to a string and wrap it so it has a class.
        // This was previously done in SellCtrl but moved here because now it needs doing for reprints too.
        if (additionalData.slLine) {
            additionalData.slLine = '<div class="sl-line">' + additionalData.slLine.toString() + '</div>';
        }

        if (additionalData.orderAdjustmentResponse) {
            // This was an adjustment order. Print a special receipt for that.
            return printOrderAdjustmentReceipt(
                additionalData.orderAdjustmentResponse,
                additionalData
            );
        }

        let opts = new PrintJobOptions(
            additionalData.isDuplicate ? PrintType.RECEIPT_DUPLICATE : PrintType.RECEIPT
        );
        opts.openCashDrawer = !additionalData.isDuplicate;
        opts.classes = [
            additionalData.slLine ? 'via-sign-pro' : '',
        ];

        return TemplateService
            .loadTemplate(
                'orders.epos.receipt',
                CurrentDevice.device.printMethod.driverRef
            )
            .then(
                (template) => Printer.printTemplate(
                    template.content,
                    Object.assign(
                        defaultPrintTemplateData(),
                        {
                            order
                        },
                        additionalData
                    ),
                    opts
                )
            );
    };

    /**
     * Return the correct template name.
     * This method could be developed into the entry point for more complex custom templates as in app etc.
     *
     * @param {string} baseTemplateName
     * @param {boolean} hasSouvenirPrinter
     * @returns {string}
     */
    const getTicketTemplate = (baseTemplateName, hasSouvenirPrinter) => baseTemplateName + (hasSouvenirPrinter ? '-boca.html' : '-default.html');

    /**
     * Choose the template we need to use and deal with each orderLine in turn.
     *
     * @param {DigiTickets.Order} order
     * @param {?function} completion Method to run after all tickets are printed.
     * @param {boolean} hasSouvenirPrinter
     *
     * @return {Promise<any>}
     */
    const printIndividualTickets = (order, completion, hasSouvenirPrinter) => {
        let opts = new PrintJobOptions(
            PrintType.TICKETS_INDIVIDUAL,
            () => {
                isPrintingComplete(completion);
            }
        );
        opts.openCashDrawer = false;

        if (hasSouvenirPrinter) {
            // TODO: Remove this clause after PRO-618 when custom Boca templates have been moved from app to epos
            return printTickets(order, opts, 'orders.epos.ticket');
        }
        let template = getTicketTemplate('ticket', hasSouvenirPrinter);

        // Process each orderLine.
        return Promise.all(order.items.map((orderItem) => printIndividualTicketLine(orderItem, order, opts, template)));
    };

    /**
     * Choose the template we need to use and deal with each orderLine in turn.
     *
     * @param {DigiTickets.Order} order
     * @param {?function} completion Method to run after all tickets are printed.
     * @param {boolean} hasSouvenirPrinter
     *
     * @return {Promise<any>}
     */
    const printGroupTickets = (order, completion, hasSouvenirPrinter) => {
        let opts = new PrintJobOptions(
            PrintType.TICKETS_GROUP,
            () => {
                isPrintingComplete(completion);
            }
        );
        opts.openCashDrawer = false;

        if (hasSouvenirPrinter) {
            // TODO: Remove this clause after PRO-618 when custom Boca templates have been moved from app to epos
            return printTickets(order, opts, 'orders.epos.group-ticket');
        }
        let template = getTicketTemplate('ticket', hasSouvenirPrinter);

        // Process each orderLine.
        return Promise.all(order.items.map((orderItem) => printGroupTicketLine(orderItem, order, opts, template)));
    };

    /**
     * Don't fire the completion method until printing is complete.
     *
     * @param {function} completion
     */
    const isPrintingComplete = (completion) => {
        if (Printer.isPrintQueueEmpty() && typeof completion === 'function') {
            console.log('Printing of tickets done');
            completion();
        }
    };

    /**
     * Process each instance of each ticket.
     *
     * @param {OrderLine} orderItem
     * @param {DigiTickets.Order} order
     * @param {object} opts Printer options.
     * @param {string} template
     */
    const printIndividualTicketLine = (orderItem, order, opts, template) => Promise.all(
        orderItem.iteminstances.map(
            (itemInstance) => printIndividualTicket(
                itemInstance,
                orderItem,
                order,
                opts,
                template
            )
        )
    );

    /**
     * Generate a single group ticket for the orderLine.
     *
     * @param {OrderLine} orderItem
     * @param {DigiTickets.Order} order
     * @param {object} opts Printer options.
     * @param {string} template
     */
    const printGroupTicketLine = (orderItem, order, opts, template) => {
        let ticketData = {
            numPeople: orderItem.qty * orderItem.item.people,
            ref: order.bookingRef
        };

        return printTicket(ticketData, orderItem, order, opts, template);
    };

    /**
     * Send an individual ticket instance to the Printer ready to be compiled and added to the print queue.
     *
     * @param {object} itemInstance
     * @param {OrderLine} orderItem
     * @param {DigiTickets.Order} order
     * @param {object} opts Print options.
     * @param {string} template The name of the local template to use.
     */
    const printIndividualTicket = (itemInstance, orderItem, order, opts, template) => {
        let ticketData = {
            numPeople: orderItem.item.people,
            ref: itemInstance.itemInstanceRef
        };

        return printTicket(ticketData, orderItem, order, opts, template);
    };

    /**
     * Actually start the ticket print.
     *
     * @param {object} ticketData Qty + reference for the ticket so we can share the same template for group or individual.
     * @param {OrderLine} orderItem
     * @param {DigiTickets.Order} order
     * @param {object} opts Print options.
     * @param {string} template The name of the local template to use.
     *
     * @return {Promise<any>}
     */
    const printTicket = (ticketData, orderItem, order, opts, template) => Printer.printLocalTemplate(
        'print/templates/' + template,
        Object.assign(
            defaultPrintTemplateData(),
            {
                ticketData,
                orderItem,
                order
            }
        ),
        opts
    );

    /**
     * Old style print method using templates from the API.
     *
     * We need to keep this method in place for the Boca tickets until we have migrated all the custom ticket templates
     * in sites for arcelormittalorbit and cadburyworld)
     *
     * @TODO Delete this method after PRO-618
     *
     * @param {DigiTickets.Order} order
     * @param {PrintJobOptions} opts
     * @param {string} templateName
     *
     * @return {Promise<any>}
     */
    const printTickets = (order, opts, templateName) => {
        Logger.info('Print tickets', { orderID: order.ID, bookingRef: order.bookingRef, date: order.date });

        opts.addFooterDot = false;

        return TemplateService
            .loadTemplate(
                templateName,
                CurrentDevice.device.printMethod.driverRef
            )
            .then(
                (template) => Printer.printTemplate(
                    template.content,
                    Object.assign(
                        defaultPrintTemplateData(),
                        {
                            order
                        }
                    ),
                    opts
                )
            );
    };

    /**
     * @param {KitchenOrder} kitchenOrder
     * @param {?{added: KitchenOrderItem[], removed: KitchenOrderItem[]}} [diff]
     * @param {function} [completion]
     *
     * @return {Promise<any>}
     */
    const printKitchenOrder = (kitchenOrder, diff, completion) => {
        diff = diff === undefined ? null : diff;

        Logger.info('Print kitchen order', { order: kitchenOrder });

        let opts = new PrintJobOptions(PrintType.KITCHEN_ORDER, null, completion);

        return Printer.printLocalTemplate(
            'print/templates/kitchen-receipt.html',
            {
                order: kitchenOrder,
                diff
            },
            opts
        );
    };

    /**
     * @param {VerifoneVoucherRecord} record
     * @param {?function} [completion]
     *
     * @return {Promise<any>}
     */
    const printVerifoneVoucher = (record, completion) => {
        Logger.info('Print verifone voucher', { record });

        let opts = new PrintJobOptions(PrintType.CARD_RECEIPT, null, completion);

        return Printer.printLocalTemplate(
            'print/templates/verifone-voucher.html',
            {
                record
            },
            opts
        );
    };

    /**
     * @param {PrintJobOptions} opts
     *
     * @return {Promise<any>}
     */
    const printBlankPage = (opts) => Printer.printContent('', opts);

    /**
     * Print a card receipt.
     *
     * @param {string} receipt string The receipt is assumed to be just a string of text, with possibly only "<br />"
     *     tags to break the lines.
     * @param {PrintJobOptions} [opts]
     *
     * @return {Promise<any>}
     */
    const printCardReceipt = (receipt, opts) => {
        if (!opts) {
            opts = new PrintJobOptions();
        }
        opts.printType = PrintType.CARD_RECEIPT;

        return Printer.printTemplate('<div>' + receipt + '</div>', {}, opts);
    };

    /**
     * @param {DigiTickets.SoldGiftVoucher} soldGiftVoucher
     *
     * @return {Promise<any>}
     */
    const printGiftVoucher = (soldGiftVoucher) => {
        let opts = new PrintJobOptions(
            PrintType.GIFT_VOUCHER,
            () => {
                console.log('printing of vouchers done');
            }
        );
        opts.openCashDrawer = false;

        return Printer.printLocalTemplate(
            'print/templates/sold-gift-voucher.html',
            Object.assign(
                defaultPrintTemplateData(),
                {
                    soldGiftVoucher
                }
            ),
            opts
        );
    };

    /**
     * @param {DigiTickets.SoldGiftVoucher[]} soldGiftVouchers
     *
     * @return {Promise<any[]>}
     */
    const printGiftVouchers = (soldGiftVouchers) => Promise.all(
        soldGiftVouchers.map((soldGiftVoucher) => printGiftVoucher(soldGiftVoucher))
    );

    /**
     * @param {object} templateData
     *
     * @return {Promise<any>}
     */
    const printTestPage = (templateData) => {
        let opts = new PrintJobOptions(
            PrintType.TEST
        );

        return Printer.printLocalTemplate(
            'print/templates/printer-test.html',
            Object.assign(
                defaultPrintTemplateData(),
                templateData
            ),
            opts
        );
    };

    /**
     * Are any of the items in the order lines given printable as tickets?
     *
     * @param {OrderLine[]} orderItems
     * @returns {boolean}
     */
    this.canPrintTicketsForOrderLines = (orderItems) => !!orderItems.find(
        (line) => line.item && line.item.canPrintTicket
    );

    /**
     * @param {DigiTickets.Order} order
     * @return {boolean}
     */
    this.canPrintTicketsForOrder = (order) => order.items && this.canPrintTicketsForOrderLines(order.items);

    // Public methods
    this.printBill = printBill;
    this.printBlankPage = printBlankPage;
    this.printCardReceipt = printCardReceipt;
    this.printGiftVouchers = printGiftVouchers;
    this.printGroupTickets = printGroupTickets;
    this.printIndividualTickets = printIndividualTickets;
    this.printKitchenOrder = printKitchenOrder;
    this.printNoSale = printNoSale;
    this.printOrder = printOrder;
    this.printOrderAdjustmentReceipt = printOrderAdjustmentReceipt;
    this.printTestPage = printTestPage;
    this.printVerifoneVoucher = printVerifoneVoucher;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ReceiptPrinter;
}
