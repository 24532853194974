const apiErrorMessage = require('../Api/apiErrorMessage');
const GiftVoucher = require('@/lib/GiftVouchers/GiftVoucher');

/**
 * @param $q
 * @param {DigiTickets.CategoryManager} CategoryManager
 * @param GiftVoucherCache
 * @param GiftVoucherResource
 * @param {Hydrator} hydrator
 */
DigiTickets.GiftVoucherManager = function (
    $q,
    CategoryManager,
    GiftVoucherCache,
    GiftVoucherResource,
    hydrator
) {
    this.categoryManager = CategoryManager;
    this.giftVoucherCache = GiftVoucherCache;
    this.giftVoucherResource = GiftVoucherResource;
    this.hydrator = hydrator;

    this.deferred = $q.defer();

    this.categories = [];
    this.giftVouchers = [];
};

DigiTickets.GiftVoucherManager.prototype = {
    /**
     * @param {boolean} [clearCache] True to empty the cache before loading.
     *
     * @return {promise|{then, catch, finally}}  Returns a promise resolved when the items are loaded.
     */
    loadGiftVouchers: function loadGiftVouchers(clearCache) {
        let self = this;

        if (clearCache === true) {
            this.giftVoucherCache.removeAll();
        }

        this.giftVoucherResource.query(
            function (giftVouchers) {
                self.giftVouchers = self.hydrator.hydrateArray(giftVouchers, function () {
                    return new GiftVoucher();
                });

                self.categories = self.categoryManager.extractCategoriesFromItems(self.giftVouchers);

                self.deferred.resolve(self.giftVouchers);
            },
            function (result) {
                self.deferred.reject(apiErrorMessage(result));
            }
        );

        return self.deferred.promise;
    },

    getGiftVouchers: function getGiftVouchers() {
        return this.deferred.promise;
    },

    getCategories: function getCategories() {
        return this.categories;
    },

    /**
     * @param {number} id
     *
     * @return {GiftVoucher|null}
     */
    getGiftVoucher: function getGiftVoucher(id) {
        for (let i = 0; i < this.giftVouchers.length; i++) {
            if (this.giftVouchers[i].ID === id) {
                return this.giftVouchers[i];
            }
        }
        return null;
    }
};
