const CustomerAccountsResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'customeraccounts/',
        ApiRequestParams.defaults(),
        {
            get: {
                url: AppConfig.apiBaseUrl + 'customeraccounts/:accountID'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CustomerAccountsResource;
}
