const { toBool, toInt, toString } = require('../../../functions/transform');

DigiTickets.AbstractPaymentMethodConfig = function () {
    /**
     * IP / hostname of the verifone-proxy app.
     *
     * @type {string}
     */
    this.proxyHost = 'localhost.dtapps.co.uk';

    /**
     * Port of the verifone-proxy app.
     *
     * @type {number}
     */
    this.proxyPort = 8443;

    /**
     * true = Use https connection to proxy app.
     * false = Use http connection to proxy app.
     *
     * @type {boolean}
     */
    this.secureProxy = true;

    /**
     * IP address of the Worldpay terminal.
     * For POS Client setups this is the IP of the POS Client software - usually 127.0.0.1
     * For VX820IP setups this is the IP of the terminal itself.
     *
     * @type {string}
     */
    this.terminalHost = '127.0.0.1';

    /**
     * Port for the 'integration socket' connection to the terminal.
     *
     * @type {number}
     */
    this.terminalPort = null;

    /**
     * This value is sent to the verifone proxy server to tell it what hour is okay to automatically restart
     * to download any changes to the code.
     * It will only restart if it is during this hour, it hasn't started within the last 12 hours,
     * and if there hasn't been any data sent through it in the last 10 minutes.
     *
     * @type {number|null}
     */
    this.proxyRestartHour = 1;

    /**
     * The encoding to be used on the TCP socket between the proxy and the terminal.
     *
     * @type {string}
     */
    this.socketEncoding = 'latin1';

    /**
     * Maximum amount of cashback that can be requested.
     *
     * @type {number}
     */
    this.maxCashback = 50.00;

    /**
     * Log debug messages to the console?
     *
     * @type {boolean}
     */
    this.debug = true;
};

DigiTickets.AbstractPaymentMethodConfig.prototype = {
    getHydrationMap() {
        return {
            proxyHost: toString,
            proxyPort: toInt,
            proxyRestartHour: toInt,
            secureProxy: toBool,
            socketEncoding: toString,
            terminalHost: toString,
            terminalPort: toInt
        };
    },

    /**
     * Ensure all the required values are set.
     *
     * @return {boolean}
     */
    validate: function validate() {
        let required = [
            'proxyHost',
            'proxyPort',
            'terminalHost',
            'terminalPort',
        ];

        for (let i = 0; i < required.length; i++) {
            if (!this[required[i]]) {
                throw new Error('Worldpay.Config: The ' + required[i] + ' value must be set.');
            }
        }

        return true;
    }
};
