// Note that these are added to Object. not the Object.prototype (hence the filename).

/**
 * Polyfill for Object.values for browsers not supporting ECMAScript 2017 (ES8).
 *
 * @param obj
 */
if (typeof Object.values !== 'function') {
    Object.values = function (obj) {
        return Object.keys(obj).map(function (key) {
            return obj[key];
        });
    };
}
