const toastr = require('toastr');
const { timeoutPromise } = require('../../functions/functions');

/**
 * ToastFactory produces notifications that pop up in the corner of the screen ("toasts").
 * It uses this package: https://github.com/CodeSeven/toastr
 *
 * @param {LangService} LangService
 */
const ToastFactory = function (
    LangService
) {
    // Set some default options for the toast library...
    // @see https://github.com/CodeSeven/toastr

    // How long the toast will display without user interaction (ms)
    toastr.options.timeOut = 4000;

    // How long the toast will display after a user hovers over it (ms)
    toastr.options.extendedTimeOut = 6000;

    toastr.options.showDuration = 100; // How long the entry animation lasts (ms)
    toastr.options.showEasing = 'linear';

    toastr.options.hideDuration = 100; // How long the exit animation lasts (ms)
    toastr.options.hideEasing = 'linear';

    // Display toasts in bottom right corner
    toastr.options.positionClass = 'toast-bottom-right';

    // Add new toasts to bottom and push up
    toastr.options.newestOnTop = false;

    // Prevent multiple of the same toast showing.
    toastr.options.preventDuplicates = true;

    const getLangString = (str) => LangService.getText(str);

    /**
     * Display a toast.
     *
     * Title should be the name of a language key.
     * Text should be a raw HTML string.
     * The difference is because the title will generally be hard coded ("Deleting Address") and the text will
     * generally be variable ("123 Fake St.")
     *
     * @param {string} style
     * @return {function(string, ?string, ?object)}
     */
    const createToast = (style) => (title, text, options) => {
        if (title) {
            title = getLangString(title);
        }

        if (!options) {
            options = {};
        }

        return toastr[style](text, title, options);
    };

    /** {function(string, ?string, ?object)} */
    this.info = createToast('info');

    /** {function(string, ?string, ?object)} */
    this.success = createToast('success');

    /** {function(string, ?string, ?object)} */
    this.warning = createToast('warning');

    this.clear = toastr.clear;

    /**
     * Display an error toast.
     *
     * This will not automatically clear. The user must click on it to dismiss.
     * An OK button will be added automatically.
     *
     * @param {string} title
     * @param {string} [text]
     * @param {object} [options]
     *
     * @return {object}
     */
    this.error = (title, text, options) => {
        if (!options) {
            options = {};
        }
        // Keep it shown until clicked
        options.timeOut = 0;
        options.extendedTimeOut = 0;
        options.tapToDismiss = true;

        text = '<button class="btn btn-default btn-sm error-toast-btn">'
            + (options.buttonText || getLangString('OK'))
            + '</button>'
            + (text || '');

        return createToast('error')(title, text, options);
    };

    /**
     * Display a toast with a spinning icon.
     *
     * It will show until it is cleared. Run this.clear() with the return value of this function to clear it.
     * You can provide a promise and the toast will automatically clear after the promise resolves or rejects.
     *
     * @param {string} title
     * @param {string} [text]
     * @param {Promise} [watchPromise] Automatically clear when this promise resolves.
     * @param {object} [options]
     *
     * @return {object}
     */
    this.spinner = (title, text, watchPromise, options) => {
        if (!options) {
            options = {};
        }
        // Keep it shown
        options.timeOut = 0;
        options.extendedTimeOut = 0;
        // Allow tapping to dismiss in case it gets stuck or is in the way.
        options.tapToDismiss = true;

        options.iconClass = 'toast-spinner';

        let toast = createToast('info')(title, text, options);

        if (watchPromise) {
            // This needs to wait a short time before clearing because it might resolve/reject so fast the spinner
            // hasn't been shown yet.
            watchPromise.finally(() => {
                timeoutPromise(101).then(() => {
                    this.clear(toast);
                });
            });
        }

        return toast;
    };

    //
    // Display a toast at the top of the screen.
    // This replaces what angular-ui's notifications were previously used for.
    //
    // @param style
    //
    const createTopToast = (style) => (title, text, options) => {
        if (!options) {
            options = {};
        }
        options.positionClass = 'toast-top-center';

        let toast = createToast(style);

        return toast(title, text, options);
    };


    /** {function(string, ?string, ?object)} */
    this.infoTop = createTopToast('info');

    /** {function(string, ?string, ?object)} */
    this.successTop = createTopToast('success');

    /** {function(string, ?string, ?object)} */
    this.warningTop = createTopToast('warning');

    this.errorTop = (title, text, options) => {
        if (!options) {
            options = {};
        }
        options.positionClass = 'toast-top-center';

        return this.error(title, text, options);
    };

    /**
     * @param {jQuery} $toastElement
     * @param {string} text
     */
    this.updateText = ($toastElement, text) => {
        let $messageEl = $toastElement.find('.toast-message');
        if ($messageEl.length > 0) {
            $messageEl.text(text);
        } else {
            $toastElement.append($('<div class="toast-message" />').text(text));
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ToastFactory;
}
