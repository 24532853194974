const CustomerScreenStages = require('../../../libraries/DigiTickets/CustomerScreen/CustomerScreenStages');
const PaymentMethodRef = require('../../../libraries/DigiTickets/PaymentMethods/PaymentMethodRef');
const PaymentTokenSelectionModalCtrl = require('../PaymentTokenSelectionModalCtrl');
const VerifoneActionIds = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Constants/VerifoneActionIds');
const VerifoneContinueTransactionRecord = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Records/Request/VerifoneContinueTransactionRecord');
const VerifoneStatusIds = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Constants/VerifoneStatusIds');
const VerifoneStatusRecord = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Records/Response/VerifoneStatusRecord');
const VerifoneTransactionModifiers = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Constants/VerifoneTransactionModifiers');
const VerifoneTransactionRequestRecord = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Records/Request/VerifoneTransactionRequestRecord');
const VerifoneTransactionResponseRecord = require('../../../libraries/DigiTickets/PaymentMethods/Verifone/Records/Response/VerifoneTransactionResponseRecord');

/**
 * This is the controller for the inner part of the payment modal for the payment method of "Verifone".
 *
 * @param $filter
 * @param $modal
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param {DialogService} DialogService
 * @param {CustomerScreenDataService} CustomerScreenDataService
 * @param {PaymentState} paymentState
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param {UserService} UserService
 * @param {VerifoneConfig} VerifoneConfigService
 * @param {VerifoneDriver} VerifoneDriverService
 */
const VerifonePaymentCtrl = function VerifonePaymentCtrl(
    $filter,
    $modal,
    $scope,
    $timeout,
    cartService,
    DialogService,
    CustomerScreenDataService,
    paymentState,
    ReceiptPrinterService,
    UserService,
    VerifoneConfigService,
    VerifoneDriverService
) {
    $scope.cart = cartService;
    $scope.paymentState = paymentState;
    $scope.verifoneConfig = VerifoneConfigService;
    $scope.verifoneDriver = VerifoneDriverService;

    // Set standard payment controller things.
    $scope.thisPaymentMethodRef = PaymentMethodRef.CARD_VERIFONE_CHIP_AND_PIN;
    $scope.registerSuppressPaymentReceivedButton($scope.thisPaymentMethodRef);
    $scope.registerSuppressPartialPaymentReceivedButton($scope.thisPaymentMethodRef);

    // Make some constants available in the template.
    $scope.ActionIds = VerifoneActionIds;
    $scope.TransactionModifiers = VerifoneTransactionModifiers;

    // Action buttons to show in green.
    $scope.successActions = [
        VerifoneActionIds.CONTINUE_TRANSACTION,
        VerifoneActionIds.CONFIRM_SIGNATURE,
        VerifoneActionIds.CONFIRM_AUTH_CODE,
    ];

    // Action buttons to show in red.
    $scope.dangerActions = [
        VerifoneActionIds.CANCEL_TRANSACTION,
        VerifoneActionIds.REJECT_SIGNATURE,
        VerifoneActionIds.REJECT_AUTH_CODE,
    ];

    // Update if the payment can be cancelled when the state changes in the verifone driver.
    $scope.$watch('verifoneDriver.state.canCancel', function (value) {
        paymentState.setCancelable(
            !$scope.transactionInProgress || value,
            $scope.thisPaymentMethodRef
        );
    });

    /**
     * The last status record received (POS Client only) is remembered because it is used
     * to determine which action buttons should be available to the user.
     *
     * @type {VerifoneStatusRecord}
     */
    $scope.lastStatusRecord = null;

    /**
     * The messages received from the terminal can generally be classified into 3 types.
     * Instead of showing a big list of all the messages received on the screen (which is information overload
     * for the user), the latest one of each of the 3 types is shown.
     *
     * - The overall status of the transaction (success, failed)
     * - What the terminal is doing now (printing, waiting for card, etc..)
     * - What the user should do now (continue, voice referral, etc)
     *
     * @type {object|null}
     */
    $scope.lastTransactionStatusInfo = null;
    /**
     * @type {object|null}
     */
    $scope.lastTerminalStatusInfo = null;

    /**
     * A full history of status messages displayed, for debugging purposes.
     *
     * @type {object[]}
     */
    $scope.statusInfoHistory = [];

    /**
     * Are we doing a special type of transaction?
     * Auth only
     * CNP
     * CNP - Account On File
     *
     * @type {null}
     */
    $scope.transactionModifier = null;

    /**
     * If charging an existing saved card, this will be the tokenId of that card.
     *
     * @type {DigiTickets.PaymentToken|null}
     */
    $scope.accountOnFileToken = null;

    /**
     * Are we doing a refund transaction?
     *
     * @type {boolean}
     */
    $scope.isRefund = false;

    /**
     * This 'ready' flag is switched to true only once per the controller being loaded, when the payment
     * terminal is ready. This will be false while updates, etc. are happening so a message can be displayed.
     *
     * @type {boolean}
     */
    $scope.initialReady = false;

    $scope.transactionAmount = null;
    $scope.transactionInProgress = false;
    $scope.tryAgainIsVisible = false;

    $scope.defaultWaitingIcon = $scope.verifoneConfig.posClient ? 'refresh glyphicon-rotate' : 'info-sign';

    /**
     * Reference of last transaction sent to Verifone.
     *
     * @type {string|null}
     */
    $scope.reference = null;

    /**
     * A function to call when the ready status is received.
     * FIXME: Won't work for eVo
     *
     * @type {function|null}
     */
    $scope.onReady = null;

    /**
     * Register listener to receive records coming from the terminal.
     */
    $scope.verifoneDriver.clearRecordReceivedListeners();
    $scope.verifoneDriver.onRecordReceived(
        function (record) {
            if (record instanceof VerifoneStatusRecord) {
                $scope.handleReceivedStatusRecord(record);
            } else if (record instanceof VerifoneTransactionResponseRecord) {
                $scope.handleReceivedTransactionResponseRecord(record);
            } else {
                $scope.handleReceivedRecord(record);
            }
        }
    );

    /**
     * @param {VerifoneStatusRecord} record
     */
    $scope.handleReceivedStatusRecord = function handleReceivedStatusRecord(record) {
        if (record.statusId === VerifoneStatusIds.READY) {
            // If this is the ready notification fire the onReady listener then unset it so it does not fire again.
            $scope.transactionInProgress = false;
            if (typeof $scope.onReady === 'function') {
                $scope.onReady();
                $scope.onReady = null;
            }
        }

        // Don't display these status messages.
        let ignoreStatuses = [
            VerifoneStatusIds.CARD_DATA_RETRIEVAL,
            // VerifoneStatusIds.PROCESSING_TRANSACTION,
        ];
        if (ignoreStatuses.indexOf(record.statusId) !== -1) {
            return;
        }

        // Set the lastStatusRecord so the appropriate action buttons are shown.
        $scope.lastStatusRecord = record;

        // Update the actions but not the displayed message for these status messages.
        let hideStatuses = [
            VerifoneStatusIds.CONTINUE_REQUIRED,
        ];
        if (hideStatuses.indexOf(record.statusId) !== -1) {
            return;
        }

        // Update message displayed to user.
        let userFacingMessage = $scope.getStatusRecordDisplayMessage(record);
        let userFacingIcon = record.iconName ? record.iconName : $scope.defaultWaitingIcon;

        $scope.addStatusInfo(userFacingMessage, 'info', userFacingIcon, false, false);

        // Update message displayed on customer facing screen.
        if (record.customerFacingMessage) {
            $scope.setCustomerFacingStatus(record.customerFacingMessage);
        }
    };

    /**
     * @param {VerifoneTransactionResponseRecord} record
     */
    $scope.handleReceivedTransactionResponseRecord = function handleReceivedTransactionResponseRecord(record) {
        // Message displayed to user.
        let userFacingMessage = record.message;
        let userFacingTextType = 'info';

        /**
         * Result types:
         *
         * 0 – Completed
         * 2 – Referred
         * 5 – Declined
         * 6 – Authorised
         * 7 – Reversed
         * 8 – Comms Down
         * 100 - Status Message
         * -nn – Negative values are used to define error conditions
         */
        if (record.result === 0) {
            // Completed.
            if (record.totalTransactionValueProcessed) {
                let payment = record.toPayment($scope.isRefund);
                payment.setPaymentMethod($scope.cart.selectedPaymentMethod);
                payment.setThirdPartyID($scope.reference);

                if ($scope.accountOnFileToken) {
                    payment.setPaymentTokenID($scope.accountOnFileToken.getId());
                }

                $scope.addPayment(payment);
                $scope.preventAddFullPaymentOnClose();

                // The Verifone accreditation script suggests we need to say clearly if it is a contactless transaction.
                let successMessage = record.message.toTitleCase();
                if (record.captureMethod && record.captureMethod.indexOf('Contactless') === 0 && successMessage.indexOf('Contactless') === -1) {
                    successMessage = 'Contactless ' + successMessage;
                }
                paymentState.setSuccessMessage(successMessage);

                $scope.ok();

                // Allow another payment for the case of a partial payment.
                $scope.transactionInProgress = false;
                $scope.tryAgainIsVisible = true;
                $scope.accountOnFileToken = null;
            }
            userFacingTextType = 'success';
        } else if (record.result === 2) {
            // Voice referral.
            // The user (merchant) needs to call the telephone number provided and enter the given code.
            $scope.handleVoiceReferral(record);
        } else if (!!$scope.transactionModifier && record.result === 6) {
            // Received after a CNP transaction returns it's address / CSC validation result.
            // The user needs to decide if they wish to continue based on the result.
            $scope.displayAvsResult(record);
            return;
        } else if (record.result === 7 || record.result < 0) {
            // Declined (7) or any other error (< 0)
            $scope.transactionInProgress = false;
            $scope.tryAgainIsVisible = true;
            userFacingTextType = 'danger';
        }

        $scope.addStatusInfo(userFacingMessage, userFacingTextType, null, true, false);
    };

    /**
     * @param record
     */
    $scope.handleReceivedRecord = function handleReceivedRecord(record) {
        // Message displayed to user.
        let userFacingMessage = record.message;
        let userFacingTextType = 'info';

        if (record.result < 0) {
            // Some other type of record with an error (< 0)
            $scope.transactionInProgress = false;
            $scope.tryAgainIsVisible = true;
            userFacingTextType = 'danger';
        } else if (record.result === 0 || record.result === 6) {
            // Some other type of record with success.
            userFacingTextType = 'success';
        }

        $scope.addStatusInfo(userFacingMessage, userFacingTextType, null, false, false);
    };

    /**
     * @param {VerifoneTransactionResponseRecord} responseRecord
     */
    $scope.displayAvsResult = function displayAvsResult(responseRecord) {
        let html = $filter('lang')('PAYMENT.AVS_HOUSE_RESULT_LABEL') + ': '
            + $filter('lang')('VERIFONE.AVS_RESULT_' + responseRecord.avsHouseNumberResult)
            + '<br/>' + $filter('lang')('PAYMENT.AVS_POSTCODE_RESULT_LABEL') + ': '
            + $filter('lang')('VERIFONE.AVS_RESULT_' + responseRecord.avsPostCodeResult)
            + '<br/>' + $filter('lang')('PAYMENT.CSC_RESULT_LABEL') + ': '
            + $filter('lang')('VERIFONE.AVS_RESULT_' + responseRecord.cscResult);

        $scope.addStatusInfo(html, 'info', false, true, true);
    };

    /**
     * @param {VerifoneTransactionResponseRecord} record
     */
    $scope.handleVoiceReferral = function handleVoiceReferral(record) {
        let message = '<span class="voice-referral-info">' + $filter('lang')('PAYMENT.VOICE_REFERRAL_DESC')
            + '<span><strong>' + $filter('lang')('PAYMENT.VOICE_REFERRAL_TEL_LABEL') + ':</strong> ' + record.referralTelephoneNumber + '</span>'
            + '<span><strong>' + $filter('lang')('PAYMENT.VOICE_REFERRAL_AMOUNT_LABEL') + ':</strong> ' + $filter('currencySymbol')(record.totalTransactionValueProcessed) + '</span>'
            + '<span><strong>' + $filter('lang')('PAYMENT.VOICE_REFERRAL_MID_LABEL') + ':</strong> ' + record.merchantNumber + '</span>'
            + '<span><strong>' + $filter('lang')('PAYMENT.VOICE_REFERRAL_TID_LABEL') + ':</strong> ' + record.terminalId + '</span>';

        DialogService.prompt(
            message,
            function (value) {
                let record = new VerifoneContinueTransactionRecord();
                if (value) {
                    record.actionId = VerifoneActionIds.VOICE_REFERRAL_AUTHORISED;
                    record.parameters.AUTHCODE = value;
                } else {
                    record.actionId = VerifoneActionIds.VOICE_REFERRAL_REJECTED;
                }
                $scope.verifoneDriver.sendRecord(record);
            },
            {
                cancelLabel: 'PAYMENT.VOICE_REFERRAL_REJECT_BTN',
                continueLabel: 'PAYMENT.VOICE_REFERRAL_AUTHORISE_BTN',
                inputLabel: 'PAYMENT.VOICE_REFERRAL_INPUT_LABEL',
                maxLength: 9,
                minLength: 1,
                rawMessage: true,
                title: 'PAYMENT.VOICE_REFERRAL',
                onKeyUp: function ($dialogScope) {
                    if ($dialogScope.form.value) {
                        // Only letters and numbers in uppercase.
                        $dialogScope.form.value = $dialogScope.form.value.replace(/[^a-z0-9]/gi, '').toUpperCase().trim();
                    } else {
                        $dialogScope.form.value = '';
                    }
                }
            }
        );
    };

    $scope.makePartialPayment = function makePartialPayment() {
        $scope.cancel();
        $scope.showMakePartialModal(function (amount) {
            if (amount) {
                $scope.beginTransaction(amount);
            }
        });
    };

    $scope.cancel = function cancel() {
        if ($scope.verifoneDriver.state.canCancel === true) {
            $scope.addStatusInfo('Cancelling Transaction', 'info', $scope.defaultWaitingIcon, false);
            $scope.verifoneDriver.cancelTransaction();
            $scope.lastStatusRecord = null; // Hide the action buttons.
            $scope.transactionInProgress = false;
        }
    };

    /**
     * @param {VerifoneContinueTransactionRecord} record
     */
    $scope.sendActionRecord = function sendActionRecord(record) {
        switch (record.actionId) {
            case VerifoneActionIds.CASHBACK_REQUIRED:
                $scope.enterCashback(
                    function (value) {
                        value = parseFloat(value);
                        $scope.cashbackAmount = value;
                        if (value) {
                            record.parameters.CASHBACK = value;
                        } else {
                            record.actionId = VerifoneActionIds.CASHBACK_NOT_REQUIRED;
                        }
                        $scope.verifoneDriver.sendRecord(record);
                    }
                );
                break;

            case VerifoneActionIds.CANCEL_TRANSACTION:
                $scope.addStatusInfo('Cancelling Transaction', 'info', $scope.defaultWaitingIcon, false);
                // Fallthrough on purpose... (This comment makes PHPStorm not highlight this as a warning.)

            default:
                $scope.verifoneDriver.sendRecord(record);
        }
    };

    $scope.toggleAuthOnly = function toggleAuthOnly() {
        if ($scope.transactionModifier === VerifoneTransactionModifiers.AUTHORISATION_ONLY) {
            $scope.transactionModifier = null;
            $scope.tryAgain();
            return;
        }

        $scope.privilegesService.requirePrivilege('auth_only_payments').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    $scope.transactionModifier = VerifoneTransactionModifiers.AUTHORISATION_ONLY;
                    $scope.tryAgain();
                }
            }
        );
    };

    $scope.toggleCnpTelephone = function toggleCnpTelephone() {
        if ($scope.transactionModifier === VerifoneTransactionModifiers.CNP_TELEPHONE_ORDER) {
            $scope.transactionModifier = null;
            $scope.tryAgain();
            return;
        }

        $scope.privilegesService.requirePrivilege('cnp_payments').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    $scope.transactionModifier = VerifoneTransactionModifiers.CNP_TELEPHONE_ORDER;
                    $scope.tryAgain();
                }
            }
        );
    };

    /**
     * Display modal to start a transaction using an existing token.
     */
    $scope.showSavedCards = function showSavedCards() {
        if ($scope.transactionModifier === VerifoneTransactionModifiers.CNP_ACCOUNT_ON_FILE) {
            $scope.transactionModifier = null;
            $scope.accountOnFileToken = null;
            $scope.tryAgain();
            return;
        }

        $scope.privilegesService.requirePrivilege('cnp_payments').then(
            /**
             * @param {DigiTickets.PrivilegeCheckResult} result
             */
            function (result) {
                if (result.granted) {
                    let modalInstance = $modal.open({
                        templateUrl: 'partials/modals/paymentTokenSelectionModal.html',
                        controller: PaymentTokenSelectionModalCtrl,
                        backdrop: 'static',
                        size: 'sm',
                        resolve: {
                            tokens: function () {
                                return $scope.cart.customer.account.paymentTokens.filter(
                                    function (token) {
                                        return token.paymentMethodID === $scope.cart.selectedPaymentMethod.ID;
                                    }
                                );
                            }
                        },
                        windowClass: 'in payment-token-selection-window'
                    });

                    modalInstance.result.then(
                        function (data) {
                            if (data.token) {
                                // Card selected - switch to account on file transaction.
                                $scope.transactionModifier = VerifoneTransactionModifiers.CNP_ACCOUNT_ON_FILE;
                                $scope.accountOnFileToken = data.token;
                            } else {
                                // No card selected - cancel account on file.
                                $scope.transactionModifier = null;
                                $scope.accountOnFileToken = null;
                            }

                            $scope.tryAgain();
                        },
                        function () {
                            // Modal cancelled.
                        }
                    );
                }
            }
        );
    };

    $scope.tryAgain = function tryAgain() {
        if ($scope.verifoneDriver.state.isReady || !$scope.verifoneConfig.posClient) {
            $scope.beginTransaction($scope.cart.getRemainingBalance());
        } else {
            $scope.onReady = function () {
                $scope.beginTransaction($scope.cart.getRemainingBalance());
            };
            $scope.cancel();
        }
    };

    /**
     * @param {number} amount
     *
     * @return {VerifoneTransactionRequestRecord}
     */
    $scope.createTransactionRequestRecord = function createTransactionRequestRecord(amount) {
        $scope.transactionAmount = amount;
        $scope.clearCashbackAmount();
        $scope.cashbackAmount = null;
        let record = new VerifoneTransactionRequestRecord();

        record.reference = $scope.cart.generatePaymentThirdPartyID();
        $scope.reference = record.reference;

        if (amount < 0) {
            record.tranactionType = '02'; // Refund
            record.transactionValue = Math.abs(amount);
            $scope.isRefund = true;
        } else {
            record.transactionValue = amount;
            $scope.isRefund = false;
        }

        if ($scope.transactionModifier) {
            record.modifier = $scope.transactionModifier;

            if (record.modifier === VerifoneTransactionModifiers.CNP_ACCOUNT_ON_FILE) {
                // Add the selected token to the record.
                record.tokenId = $scope.accountOnFileToken.paymentMechanismReference;
            }
        }

        if (UserService.company.gratuityEnabled) {
            // Allow entry of gratuity if enabled by the company.
            record.suppressGratuityPrompt = 0;
        }

        // If a customer account is selected (but we are not making an account on file transaction) register for account on file.
        if ($scope.cart.customer.hasAccount() && $scope.transactionModifier !== VerifoneTransactionModifiers.CNP_ACCOUNT_ON_FILE) {
            record.registerForAccountOnFile = 2;
        } else {
            record.registerForAccountOnFile = 1;
        }

        return record;
    };

    /**
     * Begin a cardholder present transaction.
     *
     * @param {number} amount
     */
    $scope.transactionStarted = false;
    $scope.beginTransaction = function beginTransaction(amount) {
        $scope.cancel();

        $scope.transactionStarted = true;
        $scope.lastTransactionStatusInfo = null;
        $scope.transactionInProgress = true;
        $scope.tryAgainIsVisible = false;
        $scope.addStatusInfo('Sending Transaction To Terminal', 'info', $scope.defaultWaitingIcon, false);

        let record = $scope.createTransactionRequestRecord(amount);
        $scope.verifoneDriver.sendRecord(record);

        // Show the 'Try Again' button after 3 seconds
        $timeout(
            function () {
                $scope.tryAgainIsVisible = true;
            },
            3000
        );
    };

    /**
     * @param {string} text
     * @param {string} [textClass] success, danger, info
     * @param {string|boolean|null} [iconName]
     * @param {boolean} [isTransactionInfo] If true this will update the last transaction info instead of terminal info.
     * @param {boolean} [isHtml] Is the given text string HTML?
     */
    $scope.addStatusInfo = function addStatusInfo(text, textClass, iconName, isTransactionInfo, isHtml) {
        // Determine icon.
        let iconClass;
        if (iconName !== false) {
            if (iconName) {
                iconClass = 'glyphicon-' + iconName;
            } else {
                switch (textClass) {
                    case 'danger':
                        iconClass = 'glyphicon-warning-sign';
                        break;

                    case 'success':
                        iconClass = 'glyphicon-ok-circle';
                        break;

                    case 'info':
                    default:
                        iconClass = 'glyphicon-info-sign';
                        textClass = 'info'; // Set in case this was the default because an invalid class was given.
                        break;
                }
            }
        }

        let infoObject = {
            html: null,
            iconClass,
            text: null,
            textClass
        };

        if (isHtml) {
            infoObject.html = text;
        } else {
            infoObject.text = text.toTitleCase();
        }

        if (isTransactionInfo) {
            $scope.lastTransactionStatusInfo = infoObject;
            if (!$scope.verifoneConfig.posClient) {
                // If this is not POS client we clear the terminal status, as the transaction status shows
                // the latest state instead.
                $scope.lastTerminalStatusInfo = null;
            }
        } else {
            $scope.lastTerminalStatusInfo = infoObject;
        }

        // Add to statusInfoHistory.
        // Prevent duplicating the last message.
        if ($scope.statusInfoHistory.length > 0 && $scope.statusInfoHistory[$scope.statusInfoHistory.length - 1].text === text) {
            $scope.statusInfoHistory[$scope.statusInfoHistory.length - 1].iconClass = iconClass;
            $scope.statusInfoHistory[$scope.statusInfoHistory.length - 1].textClass = textClass;
            return;
        }

        // Keep only the last 20 statuses.
        while ($scope.statusInfoHistory.length > 20) {
            $scope.statusInfoHistory.shift();
        }
    };

    /**
     * Verifone recommends displaying a more informative message for some statuses.
     *
     * @param {VerifoneStatusRecord} statusRecord
     *
     * @return {string} Returns a custom string or the original message if no custom string.
     */
    $scope.getStatusRecordDisplayMessage = function getStatusRecordDisplayMessage(statusRecord) {
        switch (statusRecord.statusId) {
            case VerifoneStatusIds.CONFIRM_AUTH_CODE:
                return statusRecord.message + ' (' + statusRecord.parameters['AUTH CODE'] + ')';

            case VerifoneStatusIds.EXPIRY_DATE_REQUIRED:
                return $filter('lang')('PAYMENT_VERIFONE.EXPIRY_DATE_REQUIRED');

            case VerifoneStatusIds.AVS_HOUSE_NUMBER_REQUIRED:
                return $filter('lang')('PAYMENT_VERIFONE.AVS_HOUSE_NUMBER_REQUIRED');

            case VerifoneStatusIds.AVS_POST_CODE_REQUIRED:
                return $filter('lang')('PAYMENT_VERIFONE.AVS_POST_CODE_REQUIRED');

            case VerifoneStatusIds.CSC_REQUIRED:
                return $filter('lang')('PAYMENT_VERIFONE.CSC_REQUIRED');
        }

        return statusRecord.message;
    };

    $scope.setLastIntegrationRecord = function setLastIntegrationRecord(error, info, success) {
        $scope.errorIntegrationRecord = error || null;
        $scope.infoIntegrationRecord = info || null;
        $scope.successIntegrationRecord = success || null;
    };

    $scope.$on('payment-modal.cancel-pressed', function (event, paymentMethodRef) {
        if (paymentMethodRef === $scope.thisPaymentMethodRef) {
            // Payment was cancelled from this tab.
            // Cancel payment on the terminal.
            $scope.cancel();
        }
    });

    $scope.$on('payment-modal.payment-method-changed', function (event, newPaymentMethodRef) {
        if (newPaymentMethodRef === $scope.thisPaymentMethodRef) {
            // Switching to this tab

            if ($scope.verifoneConfig.posClient) {
                // Set the initial state when loading the payment method.
                // If the terminal is currently doing something (like downloading an update) we need to display a
                // message about that and wait for it to complete.
                $scope.initialReady = false;

                if ($scope.verifoneDriver.state.isReady) {
                    $scope.initialReady = true;
                    if (!$scope.transactionInProgress && $scope.verifoneConfig.autoStart) {
                        $scope.beginTransaction($scope.cart.getRemainingBalance());
                    }
                } else {
                    $scope.onReady = function () {
                        $scope.initialReady = true;
                        if ($scope.verifoneConfig.autoStart) {
                            $scope.beginTransaction($scope.cart.getRemainingBalance());
                        }
                    };
                }
            } else {
                $scope.initialReady = true;
                if ($scope.verifoneConfig.autoStart) {
                    $scope.beginTransaction($scope.cart.getRemainingBalance());
                }
            }
        } else {
            // Switching away from this tab
            $scope.cancel();
        }
    });

    $scope.setCustomerFacingStatus = function setCustomerFacingStatus(status) {
        CustomerScreenDataService.updateState((state) => {
            state.stage = CustomerScreenStages.PAY;
            state.paymentStatus = status;
        });
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifonePaymentCtrl;
}
