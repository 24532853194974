/**
 * Provides common methods used by both OrderDetailsCtrl and MembershipDetailsCtrl
 * for viewing/updating details about Memberships.
 *
 * @param $location
 * @param $modal
 * @param {CartService} cartService
 * @param {DigiTickets.Logger} Logger
 * @param MembershipResource
 * @param Notification
 * @param RedemptionResource
 */
const MembershipManagementCtrl = function MembershipManagementCtrl(
    $location,
    $modal,
    cartService,
    Logger,
    MembershipResource,
    Notification,
    RedemptionResource
) {
    this.modalService = $modal;
    this.logger = Logger;
    this.membershipResource = MembershipResource;
    this.redemptionResource = RedemptionResource;

    /**
     * @type {CartService}
     */
    this.cart = cartService;

    this.$location = $location;
    this.notification = Notification;

    // If we are returning from successfully renewing the memberships on screen, show a nice notice.
    let queryString = $location.search();
    if (queryString.hasOwnProperty('renewalSuccess') && queryString.renewalSuccess == true) {
        this.notification.success('Membership(s) renewed successfully!');
    }
};

MembershipManagementCtrl.prototype = {

    /**
     * @param {DigiTickets.Membership} membership
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {function} callback
     */
    logVisit: function logVisit(membership, membershipSubscription, orderItem, membershipPlan, callback) {
        this.updateMembershipVisits(membership, membershipSubscription, orderItem, membershipPlan, +1, callback);
    },

    /**
     * @param {DigiTickets.Membership} membership
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {function} [callback]
     */
    unlogVisit: function unlogVisit(
        membership,
        membershipSubscription,
        orderItem,
        membershipPlan,
        callback
    ) {
        this.updateMembershipVisits(membership, membershipSubscription, orderItem, membershipPlan, -1, callback);
    },

    /**
     * @param {DigiTickets.Membership} membership
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {number} qty
     * @param {function} [callback]
     */
    updateMembershipVisits: function updateMembershipVisits(
        membership,
        membershipSubscription,
        orderItem,
        membershipPlan,
        qty,
        callback
    ) {
        let self = this;

        if (qty > 0) {
            membership.currentlyRedeeming = true;
        }
        if (qty < 0) {
            membership.currentlyUnredeeming = true;
        }

        this.redemptionResource.redeem({
            qty,
            ref: membership.getRef(),
            type: 'membership'
        }, function success(data) {
            if (qty > 0) {
                membership.currentlyRedeeming = false;
            }
            if (qty < 0) {
                membership.currentlyUnredeeming = false;
            }

            if (data.success) {
                membershipSubscription.setVisits(data.newRedemptionCount);
                membership.setMemberVisits(data.memberRedemptionData);
                if (callback) {
                    callback(data);
                }
            } else {
                self.notification.error('Membership could not be redeemed: ' + data.message);
            }
        }, function error(response) {
            if (qty > 0) {
                membership.currentlyRedeeming = false;
            }
            if (qty < 0) {
                membership.currentlyUnredeeming = false;
            }

            if (response.data.message) {
                self.notification.error('Membership could not be redeemed: ' + response.data.message);
            }
        });
    },

    /**
     * @param {DigiTickets.Member} member
     * @param {MembershipSubscription} membershipSubscription
     * @param {OrderLine} orderItem
     * @param {DigiTickets.MembershipPlan} membershipPlan
     * @param {number} qty
     * @param {function} callback
     */
    updateMemberVisits: function updateMemberVisits(member, membershipSubscription, orderItem, membershipPlan, qty, callback) {
        if (!membershipPlan.membershipsCanBeRedeemedToday()) {
            return;
        }

        let self = this;

        if (qty > 0) {
            member.currentlyRedeeming = true;
        }
        if (qty < 0) {
            member.currentlyUnredeeming = true;
        }

        this.redemptionResource.redeem({
            qty,
            ref: member.getID(),
            type: 'member'
        }, function success(data) {
            if (qty > 0) {
                member.currentlyRedeeming = false;
            }
            if (qty < 0) {
                member.currentlyUnredeeming = false;
            }

            if (data.success) {
                membershipSubscription.setVisits(data.newRedemptionCount);
                member.setVisits(data.hasOwnProperty('newMemberRedemptionData') ? data.newMemberRedemptionData : {});
                if (callback) {
                    callback(data);
                }
            } else {
                self.notification.error('Member could not be redeemed: ' + data.message);
            }
        }, function error(response) {
            if (qty > 0) {
                member.currentlyRedeeming = false;
            }
            if (qty < 0) {
                member.currentlyUnredeeming = false;
            }

            if (response.data.message) {
                self.notification.error('Member could not be redeemed: ' + response.data.message);
            }
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipManagementCtrl;
}
