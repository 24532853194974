const { toNullableInt, toString } = require('../functions/transform');

DigiTickets.Address = function () {
    /**
     * ID of address saved in the database, if this was an existing address.
     *
     * @type {number|null}
     */
    this.ID = null;

    /**
     * @type {DigiTickets.Country|null}
     */
    this.country = null;

    this.house = '';
    this.postcode = '';
    this.region = '';
    this.state = '';
    this.street = '';
    this.town = '';
};

DigiTickets.Address.prototype = {
    getHydrationMap() {
        return {
            ID: {
                field: ['ID', 'addressID'],
                transform: toNullableInt
            },
            house: toString,
            postcode: toString,
            region: toString,
            state: toString,
            street: toString,
            town: toString
        };
    },

    /**
     * @param {CountryManager} CountryManager
     * @param {object} data
     */
    afterHydration: function afterHydration(CountryManager, data) {
        if (CountryManager) {
            this.country = CountryManager.findByData(data);
        }
    },

    getFormattedAddress: function getPrettyAddress() {
        let addressParts = [this.house, this.street, this.town, this.state, this.postcode].filter(function (n) {
            return !!n;
        });

        return addressParts.join(', ');
    },

    clear: function clear() {
        this.country = null;
        this.house = '';
        this.postcode = '';
        this.region = '';
        this.state = '';
        this.street = '';
        this.town = '';
    },

    isEmpty: function isEmpty() {
        // We'll ignore 'Country' as this is often set by default
        return !this.house
                && !this.street
                && !this.town
                && !this.county
                && !this.postcode
                && !this.state;
    },

    hasData: function () {
        return !this.isEmpty();
    },

    toServerData: function toServerData() {
        return {
            addressID: this.ID,
            ID: this.ID,

            // Provide the country in all the different forms various API endpoints require.
            country: this.country ? this.country.code : '',
            countryCode: this.country ? this.country.code : '',
            countryID: this.country ? this.country.ID : '',

            house: this.house,
            postcode: this.postcode,
            region: this.region,
            state: this.state ? this.state.substr(0, 2) : '',
            street: this.street,
            town: this.town
        };
    }
};
