const angular = require('angular');
const MembershipSubscription = require('../libraries/DigiTickets/Memberships/MembershipSubscription');
const { cloneDeep } = require('../functions/clone');
const { toNullableBool, toDate, toInt, toString } = require('../functions/transform');

DigiTickets.Membership = function () {
    /**
     * @type {number}
     */
    this.ID = null;

    /**
     * @type {string}
     */
    this.ref = '';

    /**
     * @type {DigiTickets.Address}
     */
    this.address = null;

    /**
     * @type {DigiTickets.CustomerAccount|null}
     */
    this.customerAccount = null;

    /**
     * @type {MembershipSubscription[]}
     */
    this.subscriptions = [];

    /**
     * Do any of the subscriptions covering today or starting in the future have installmentAmounts?
     *
     * @type {boolean}
     */
    this.hasInstallments = false;

    /**
     * @type {Date}
     */
    this.initialStartDate = null;

    this.status = 'Active';

    /**
     * @type {boolean}
     */
    this.currentlyProcessing = false;

    /**
     * @type {boolean}
     */
    this.currentlyRedeeming = false;

    /**
     * @type {boolean}
     */
    this.currentlyUnredeeming = false;

    /**
     * @type {DigiTickets.MembershipPlan|null}
     */
    this.currentPlan = null;

    /**
     * @type {MembershipSubscription|null}
     */
    this.currentSubscription = null;

    /**
     * @type {null|bool}
     */
    this.autoRenew = null;

    /**
     * @type {DigiTickets.MembershipPlan|null}
     */
    this.renewalPlan = null;

    /**
     * @type {Date}
     */
    this.renewToDate = null;

    /**
     * @type {Date}
     */
    this.maxSubscriptionExpiryDate = null;

    /**
     * @type {DigiTickets.MembershipRefundAmount|null}
     */
    this.refundAmount = null;

    /**
     * @type {DigiTickets.Member[]}
     */
    this.members = [];

    /**
     * Used when listing this membership on the Redeem search results.
     * If the search was for a contact's ref and that brings up multiple results, all the memberships
     * that contact is on will be listed. Clicking on one of them should take the user to the membership
     * with that contact highlighted.
     * This field is used to say which contact ref should be highlighted when it is clicked on from the
     * search results.
     *
     * @type {string|null}
     */
    this.highlightMemberRef = null;

    /**
     * @type {Date}
     */
    this.earliestAllowedRenewalDate = null;

    this.resetRenew(); // Creates the attributes used for the renewal options.
};

DigiTickets.Membership.prototype = {
    getHydrationMap() {
        return {
            address: {
                model: DigiTickets.Address
            },
            autoRenew: toNullableBool,
            currentPlan: {
                field(data) {
                    if (data.currentPlan) {
                        return data.currentPlan;
                    }

                    if (
                        data.hasOwnProperty('currentSubscription')
                            && data.currentSubscription
                            && data.currentSubscription.hasOwnProperty('membershipplans')
                    ) {
                        return data.currentSubscription.membershipplans;
                    }

                    // Returning undefined so the value does not get overwritten when re-hydrating
                    return undefined;
                },
                model: DigiTickets.MembershipPlan
            },
            currentSubscription: {
                model: MembershipSubscription
            },
            customerAccount: {
                model: DigiTickets.CustomerAccount
            },
            earliestAllowedRenewalDate: toDate,
            ID: {
                field: ['ID', 'membershipID'],
                transform: toInt
            },
            initialStartDate: toDate,
            members: {
                field(data) {
                    if (data.isHydrated) {
                        return data.members;
                    }

                    // Reduce the members->contact to just contact (but with the memberID in the contact).
                    if (data.hasOwnProperty('members')) {
                        return data.members.map(function (member) {
                            // It turns out you *can* have a member without a contact, so work around that
                            member.contacts = member.contacts || {};

                            // Keep just the contact data separate so it can be used to create a regular contact model
                            // instead of mixing up the contact and member data.
                            let contactData = {};
                            contactData = angular.extend(contactData, member.contacts);
                            member.contacts.contacts = contactData;

                            member.contacts.memberID = member.memberID;
                            // And when the member object has more fields, you have to know to manually add them here...
                            member.contacts.visitedToday = member.isRedeemedForDay || false;
                            member.contacts.visitCount = member.visitCount || 0;

                            return member.contacts;
                        });
                    }

                    return [];
                },
                modelCollection: DigiTickets.Member
            },
            ref: toString,
            refundAmount: {
                model: DigiTickets.MembershipRefundAmount
            },
            renewalPlan: {
                model: DigiTickets.MembershipPlan
            },
            renewToDate: toDate,
            status: toString,
            subscriptions: {
                modelCollection: MembershipSubscription
            }
        };
    },

    afterHydration: function afterHydration() {
        // We want the expiry date of the latest subscription.
        this.populateMaxSubscriptionExpiryDate();

        // Make sure the address is an address object, even if it's empty.
        if (!this.address || !(this.address instanceof DigiTickets.Address)) {
            this.address = new DigiTickets.Address();
        }

        this.hasInstallments = this.getFutureSubscriptionsWithInstallments().length > 0;

        for (let i = 0; i < this.members.length; i++) {
            this.members[i].setIndex(i);
        }
    },

    getRef: function getRef() {
        return this.ref;
    },

    setRef: function setRef(newRef) {
        this.ref = newRef;
    },

    getId: function getId() {
        return this.ID;
    },

    isActive: function isActive() {
        return this.status === 'Active';
    },

    getStatus: function getStatus() {
        return this.status.toUpperCase();
    },

    isDead: function isDead() {
        return this.status === 'Deleted';
    },

    setHighlightStatus: function highlightStatus(highlight) {
        this.highlightStatus = highlight;
    },

    /**
     * @return {DigiTickets.Member[]}
     */
    getMembers: function getMembers() {
        return this.members.map(function (member) {
            // Add the membership to the member to allow the member to inform if it is valid or not.
            member.membership = this;
            return member;
        }.bind(this));
    },

    /**
     * Checking if Membership is postponed
     *
     * @return {boolean} returns true if postponed otherwise false
     */
    isPostponed: function isPostponed() {
        let now = new Date();
        now.setHours(23, 59, 59, 59);
        return (this.getInitialStartDate() > now);
    },

    /**
     * Getting the initial start date of membership
     *
     * @return {Date} Initial start date or null if there is no subscription
     */
    getInitialStartDate: function getInitialStartDate() {
        return this.initialStartDate;
    },

    /**
     * Returns the first active MembershipSubscription.
     *
     * @returns {MembershipSubscription|null}
     */
    getCurrentSubscription: function getCurrentSubscription() {
        let latestSubscription = null;
        for (let i = 0; i < this.subscriptions.length; i++) {
            let subscription = this.subscriptions[i];

            if (subscription.isActive()) {
                return subscription;
            }

            if (!latestSubscription || subscription.startDate > latestSubscription.startDate) {
                latestSubscription = subscription;
            }
        }

        return latestSubscription;
    },

    /**
     * @param {number} memberID
     *
     * @return {DigiTickets.Member|null}
     */
    getMemberByID(memberID) {
        return this.members.find((m) => m.ID === memberID) || null;
    },

    /**
     * @param {number} contactID
     *
     * @return {DigiTickets.Member|null}
     */
    getMemberByContactID(contactID) {
        return this.members.find((m) => m.contactID === contactID) || null;
    },

    /**
     * @param {string} contactRef
     *
     * @return {DigiTickets.Member|null}
     */
    getMemberByRef(contactRef) {
        contactRef = contactRef.toUpperCase();
        return this.members.find((m) => m.contact && m.contact.ref && m.contact.ref.toUpperCase() === contactRef) || null;
    },

    setMemberVisits: function setMemberVisits(memberRedemptionData) {
        for (let redemptionIndex = 0; redemptionIndex < memberRedemptionData.length; redemptionIndex++) {
            let redemptionData = memberRedemptionData[redemptionIndex];
            let member = this.getMemberByID(redemptionData.memberID);
            member.setVisits({
                isRedeemedForDay: redemptionData.isRedeemedForDay,
                visitCount: redemptionData.visitCount
            });
        }
    },

    isRenewable: function isRenewable() {
        return !!this.renewalPlan;
    },

    isRenewing: function isRenewing() {
        // If user has chosen an option and it's not "don't renew", then it's renewing.
        return this.chosenRenewalOption.type && this.chosenRenewalOption.type !== DigiTickets.MembershipRenewalDateTypes.NONE;
    },

    resetRenew: function resetRenew() {
        this.chosenRenewalOption = {
            type: null,
            chosenDate: null
        };
    },

    setRenewalOption: function setRenewalOption(renewalType, renewalDate) {
        this.chosenRenewalOption.type = renewalType;
        this.chosenRenewalOption.chosenDate = renewalDate;
    },

    populateMaxSubscriptionExpiryDate: function populateMaxSubscriptionExpiryDate() {
        let maxDate = null;
        for (let i = 0; i < this.subscriptions.length; i++) {
            let subscription = this.subscriptions[i];
            if (maxDate == null || subscription.endDate > maxDate) {
                maxDate = subscription.endDate;
            }
        }
        this.maxSubscriptionExpiryDate = maxDate;
    },

    willAutoRenew: function willAutoRenew() {
        if (!this.isActive()) {
            return null;
        }

        if (this.autoRenew === false || this.autoRenew === true) {
            // Membership specifically says it will or won't.
            return this.autoRenew;
        }

        // It's up to the plan.
        if (this.currentPlan) {
            return this.currentPlan.autoRenew;
        }

        return null;
    },

    getAllPayments: function getAllPayments() {
        let subscription = this.getCurrentSubscription();

        if (!subscription) {
            return [];
        }

        return subscription.subscriptionPayments.sort(function (a, b) {
            return new Date(a.payment.takenAt) - new Date(b.payment.takenAt);
        });
    },

    getPaymentSummary: function getPaymentSummary() {
        let paymentsMade = 0.00;
        angular.forEach(this.getAllPayments(), function (subscriptionPayment) {
            if (subscriptionPayment.payment.paid) {
                paymentsMade += subscriptionPayment.apportionedAmount;
            }
        });

        let total = this.getCurrentSubscription().membershipPlan.getTotalPrice();

        return {
            total,
            paymentsMade,
            balance: (total - paymentsMade)
        };
    },

    /**
     * @return {MembershipSubscription[]}
     */
    getFutureSubscriptionsWithInstallments: function getFutureSubscriptionsWithInstallments() {
        let now = new Date();
        return this.subscriptions.filter(
            /**
             * @param {MembershipSubscription} subscription
             */
            function (subscription) {
                return subscription.hasInstallments() && subscription.endDate >= now;
            }
        );
    },

    /**
     * Return the data for this membership (including members) in a membership dataset for editing
     * in the MembershipDatasetsModal
     *
     * @return {DigiTickets.MembershipDataset}
     */
    toMembershipDataset: function toMembershipDataset() {
        let dataset = new DigiTickets.MembershipDataset(this.currentPlan);

        dataset.assignedMembershipRef = this.ref;
        dataset.address = cloneDeep(this.address);
        dataset.addressID = this.address.ID;
        dataset.membershipID = this.ID;
        dataset.membershipDateFrom = new Date(this.initialStartDate);

        dataset.members = [];
        for (let m = 0; m < this.members.length; m++) {
            let contact = this.members[m].contact ? cloneDeep(this.members[m].contact) : null;
            let member = cloneDeep(this.members[m]);
            member.contact = contact;

            // Remove the member.membership from the DigiTickets.Member as it isn't needed
            // by the dataset and causes a circular reference if the dataset needs to be converted to JSON
            // (which it will if the cart is stashed)
            member.membership = null;

            dataset.members.push(member);
        }

        return dataset;
    },

    getRenewButtonText: function getRenewButtonText() {
        switch (this.chosenRenewalOption.type) {
            case DigiTickets.MembershipRenewalDateTypes.EXPIRY:
                return 'MEMBERSHIPS.RENEWING_TO';

            case DigiTickets.MembershipRenewalDateTypes.TODAY:
            case DigiTickets.MembershipRenewalDateTypes.OTHER:
                return 'MEMBERSHIPS.RENEWING_FROM';

            case DigiTickets.MembershipRenewalDateTypes.NONE:
            default:
                return 'MEMBERSHIPS.RENEW';
        }
    },

    getChosenRenewalFromDate: function getChosenRenewalFromDate() {
        let chosenDate = this.chosenRenewalOption.chosenDate;
        return chosenDate === null ? new Date() : chosenDate;
    },

    canBeRedeemedRightNow() {
        if (this.currentlyRedeeming
                || this.currentlyUnredeeming
                || !this.isActive()) {
            return false;
        }

        return this.currentPlan ? this.currentPlan.membershipsCanBeRedeemedToday() : true;
    }
};
