const VerifoneContinueTransactionRecord = function () {
    /**
     * This is the required action to perform. These are defined below within a separate table.
     *
     * @type {Number}
     */
    this.actionId = null;

    /**
     * This field will contain any information that the POS may require that is associated with that status.
     * The following parameters are available:
     * REPRINTOPT=FILE Reprint to file rather than current printer
     * AUTHCODE= The auth code to be supplied to PAYware Ocius for VR transactions / Charge Only transactions
     * CASHBACK= The cashback to be added to the transaction
     * MGRPIN= The manager PIN to use
     * PAYPOINTACCID= The PayPoint account ID to use
     * PAYPOINTOPTIONID= The PayPoint Scheme Option to use
     */
    this.parameters = {};

    /**
     * Used internally as a description of this action to show to the user.
     *
     * @type {string}
     */
    this.description = '';
};

VerifoneContinueTransactionRecord.prototype = {
    toArray() {
        // Convert the parameters to the required format:
        // "<Name>=<Value>;<Name>=<Value>"
        let parametersStr = '';
        Object.keys(this.parameters).forEach((name) => {
            if (parametersStr.length > 0) {
                parametersStr += ';';
            }
            parametersStr += name + '=' + this.parameters[name];
        });

        return [
            'CONTTXN', // 1: Message Type
            this.actionId, // 2: Action ID
            parametersStr, // 3: Parameters
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneContinueTransactionRecord;
}
