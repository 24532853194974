const User = require('../../models/User');
const UserEvents = require('./Enums/UserEvents');

/**
 * @param $rootScope
 * @param {CacheManager} CacheManager
 * @param {Hydrator} hydrator
 * @param {LocalStorageDataStore} LocalStorageDataStore
 * @param UserResource
 */
const UserService = function (
    $rootScope,
    CacheManager,
    hydrator,
    LocalStorageDataStore,
    UserResource
) {
    this.$rootScope = $rootScope;
    this.cacheManager = CacheManager;
    this.hydrator = hydrator;
    this.localStorageDataStore = LocalStorageDataStore;
    this.userResource = UserResource;

    /**
     * @type {?DigiTickets.Branch}
     */
    this.currentBranch = null;

    /**
     * @type {?User}
     */
    this.currentUser = null;

    /**
     * This is either the API key for the current user, or the last non-empty API key we knew about.
     * Once set it should never be set back to null/empty as we often need an API key even while the user is not
     * logged in, and it's better to have an old key than none.
     * TODO: Replacing user API keys with a device API key would be a good idea.
     *
     * @type {?string}
     */
    this.apiKey = null;

    this.localStorageKey = 'user';

    /**
     * Watch localStorage to check if the user logs in or out in a different tab (previously in NavigationCtrl).
     * This works because the 'storage' even only fires when localStorage changes in *another* tab. If we change it
     * in the same tab the event does not fire.
     *
     * @param {StorageEvent} event
     */
    const onStorageChange = (event) => {
        if (event && event.key && event.key === this.localStorageKey) {
            let oldValue = event.oldValue ? JSON.parse(event.oldValue) : {};
            let newValue = event.newValue ? JSON.parse(event.newValue) : {};

            if (!oldValue.currentUser || !newValue.currentUser || oldValue.currentUser.ID !== newValue.currentUser.ID) {
                // User appears to have changed user in another tab, so logout here.
                window.location.reload();
            }
        }
    };
    if (typeof window !== 'undefined') {
        window.addEventListener('storage', onStorageChange, false);
    }
};

UserService.prototype = {

    /**
     * Write the current user and branch to storage.
     */
    saveToCache() {
        const cached = {
            apiKey: this.apiKey,
            currentBranch: this.currentBranch,
            currentUser: this.currentUser
        };

        this.localStorageDataStore.persist(this.localStorageKey, cached);
    },

    /**
     * Load the current user and branch from storage.
     */
    loadFromCache() {
        const cached = this.localStorageDataStore.find(this.localStorageKey);

        if (cached) {
            this.setApiKey(cached.apiKey);

            this.setCurrentUser(
                cached.currentUser
                    ? this.hydrator.hydrate(cached.currentUser, new User())
                    : null
            );

            // Only set the branch if there is a user.
            this.currentBranch = this.currentUser && cached.currentBranch
                ? this.hydrator.hydrate(cached.currentBranch, new DigiTickets.Branch())
                : null;

            this.onUserChange(true);
        } else {
            this.setCurrentUser(null);
        }
    },

    /**
     * Fetch an updated copy of the current user (including the company and branches) from the API.
     *
     * @return {Promise<User>}
     */
    refreshCurrentUser() {
        if (!this.apiKey) {
            return Promise.reject(new Error('There is no current API key.'));
        }

        return new Promise((resolve, reject) => {
            this.userResource.check(
                {
                    apiKey: this.apiKey
                },
                (response) => {
                    if (response.user) {
                        let user = this.hydrator.hydrate(response.user, new User());
                        console.log('Refreshed user', user);
                        this.changeUser(user);
                        resolve(user);
                    } else {
                        reject(new Error('The user was missing from the user update API response.'));
                    }
                },
                () => {
                    reject(new Error('The API request to update the current user failed.'));
                }
            );
        });
    },

    notifyLoginCallbacks(fromCache) {
        this.$rootScope.$broadcast(UserEvents.USER_LOGGED_IN, {
            user: this.currentUser,
            fromCache
        });
    },

    /**
     * Should be called before the actual logout call so the current user is still available.
     */
    notifyLogoutCallbacks() {
        this.$rootScope.$broadcast(UserEvents.USER_LOGGED_OUT, { user: this.currentUser });
    },

    notifyBranchChangeCallbacks() {
        this.$rootScope.$broadcast(UserEvents.BRANCH_CHANGED, {});
    },

    initialize() {
        this.loadFromCache();
    },

    /**
     * @param {User} user
     * @param {DigiTickets.Device} [currentDevice]
     */
    changeUser(user, currentDevice) {
        this.setCurrentUser(user);

        this.saveToCache();
        this.onUserChange(false);

        if (!this.currentUser) {
            this.setBranch(null);
            return;
        }

        if (this.currentBranch && this.currentBranch.ID) {
            // A branch was already selected.
            // See if new user has access to the current branch.
            let currentBranchFromNewUser = user.getBranchByID(this.currentBranch.ID);
            if (currentBranchFromNewUser) {
                // New user has access to the current branch.
                // Update the current branch from the new user, in case the data is newer.
                this.setBranch(currentBranchFromNewUser);
            } else {
                // New user does not have access to the current branch.
                // Just select the first available branch.
                this.setBranch(this.currentUser.getFirstBranch());
            }
        } else {
            // There is no current branch selected.
            // Try to use the device's default branch (passed in) if the user has access.
            if (currentDevice && currentDevice.defaultBranch && user.getBranchByID(currentDevice.defaultBranch.ID)) {
                this.setBranch(user.getBranchByID(currentDevice.defaultBranch.ID));
            } else {
                // Just select the first available branch.
                this.setBranch(this.currentUser.getFirstBranch());
            }
        }
    },

    /**
     * Set the currentUser property and API key only.
     *
     * @param {?User} user
     */
    setCurrentUser(user) {
        this.currentUser = user;
        this.setApiKey(user ? user.apiKey : null);
    },

    onUserChange(fromCache) {
        if (this.currentUser) {
            this.notifyLoginCallbacks(fromCache);
        }
    },

    /**
     * @param {string} [processingMessage]
     */
    logout(processingMessage) {
        this.notifyLogoutCallbacks();
        this.changeUser(null);

        // Show logging out message.
        this.$rootScope.rootProcessingMessage = processingMessage || 'NAV.LOGGING_OUT';

        // Timeout to allow things to clean up before reloading, such as logging out of payment terminals.
        setTimeout(
            () => {
                window.location.reload();
            },
            1500
        );
    },

    /**
     * @param {DigiTickets.Branch} branch
     */
    async setBranch(branch) {
        const previousBranch = this.currentBranch;
        this.currentBranch = branch;

        this.saveToCache();

        if (previousBranch && branch !== null && this.currentBranch.ID === previousBranch.ID) {
            // This is already the branch. Don't do anything.
            return false;
        }

        this.notifyBranchChangeCallbacks();

        if (previousBranch) {
            // Branch was changed after app was loaded.
            // We need to refresh for this.
            await this.cacheManager.changeBranchClear();
            if (typeof window !== 'undefined') {
                window.location.reload();
            }
        }
    },

    /**
     * @param {number} branchID
     *
     * @return {?DigiTickets.Branch}
     */
    getBranchByID(branchID) {
        return this.currentUser ? this.currentUser.getBranchByID(branchID) : null;
    },

    /**
     * @return {boolean}
     */
    isLoggedIn() {
        return this.currentUser !== null;
    },

    /**
     * @return {?string}
     */
    getApiKey() {
        if (this.apiKey) {
            return this.apiKey;
        }

        if (this.currentUser && this.currentUser.apiKey) {
            return this.currentUser.apiKey;
        }

        return null;
    },

    /**
     * Set the API key, as long as it is a non-empty value.
     *
     * @param {string} apiKey
     */
    setApiKey(apiKey) {
        if (apiKey) {
            this.apiKey = apiKey;
        }
    },

    /**
     * @return {?number}
     */
    getBranchID() {
        return this.currentBranch ? this.currentBranch.ID : null;
    },

    /**
     * @return {?number}
     */
    getUserID() {
        return this.currentUser ? this.currentUser.ID : null;
    },

    /**
     * @return {?string}
     */
    getUsername() {
        return this.currentUser ? this.currentUser.username : null;
    },

    /**
     * @return {?DigiTickets.Company}
     */
    getCompany() {
        return this.currentUser ? this.currentUser.company : null;
    },

    /**
     * @return {?number}
     */
    getUsergroupID() {
        return this.currentUser ? this.currentUser.usergroupID : null;
    },

    // Getters for backward compatibility...

    /**
     * @return {?number}
     */
    get ID() {
        return this.currentUser ? this.currentUser.ID : null;
    },

    /**
     * @type {DigiTickets.Branch[]}
     */
    get branches() {
        return this.currentUser ? this.currentUser.branches : [];
    },

    /**
     * @return {?DigiTickets.Company}
     */
    get company() {
        return this.currentUser ? this.currentUser.company : null;
    },

    /**
     * @return {?object}
     */
    get paymentTerminalConfig() {
        return this.currentUser ? this.currentUser.paymentTerminalConfig : null;
    },

    /**
     * @return {?number}
     */
    get usergroupID() {
        return this.currentUser ? this.currentUser.usergroupID : null;
    },

    /**
     * @return {?string}
     */
    get username() {
        return this.currentUser ? this.currentUser.username : null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserService;
}
