const FieldGroup = require('../CustomFields/FieldGroup');
const { populate } = require('../../../functions/objects');
const { toInt, toNullableInt, toNullableString } = require('../../../functions/transform');

const ItemInstance = function (properties = {}) {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * @type {number}
     */
    this.instance = properties.instance || 1;

    /**
     * @type {?string}
     */
    this.itemInstanceRef = properties.itemInstanceRef || null;

    /**
     * @type {?MembershipSubscription}
     */
    this.membershipSubscription = null;

    /**
     * @type {?number}
     */
    this.netRedemptions = null;

    /**
     * @type {?OrderLine}
     */
    this.orderLine = null;

    /**
     * @type {DigiTickets.Redemption[]}
     */
    this.redemptions = [];

    /**
     * @type {boolean}
     */
    this.redemptionInProgress = false;

    /**
     * @type {boolean}
     */
    this.unredemptionInProgress = false;

    /**
     * Item level field instances for this item instance.
     *
     * @type {FieldGroup}
     */

    this.fields = new FieldGroup({ level: 'item' });

    /**
     * A unique (enough) reference for this instance which is created when the instance is first created on the POS
     * and eventually is stored in the DB (TODO).
     * This is used to identify an individual instance since it has no ID or other identifying info, and the lineNumber
     * and instance number are subject to change if the line this instance is on is split.
     *
     * @type {?String}
     */
    this.thirdPartyRef = Date.now() + Math.random().toString().substr(1, 10);

    populate(this, properties);
};

ItemInstance.prototype = {
    /**
     * @return {object}
     */
    getHydrationMap() {
        return {
            fields: {
                model: FieldGroup
            },
            ID: {
                field: ['ID', 'itemInstanceID'],
                transform: toNullableInt
            },
            instance: toInt,
            itemInstanceRef: toNullableString,
            membershipSubscription: {
                field: ['membershipSubscription', 'membershipsubscriptions'],
                /**
                 * @param {any} value
                 * @param {{}} allValues
                 * @param {DigiTickets.Hydrator} hydrator
                 *
                 * @return {?MembershipSubscription}
                 */
                transform(value, allValues, hydrator) {
                    if (!value) {
                        return null;
                    }
                    // Import MembershipSubscription here to avoid a circular dependency if it were imported up the top.
                    const MembershipSubscription = require('../Memberships/MembershipSubscription');
                    return hydrator.hydrate(value, new MembershipSubscription());
                }
            },
            netRedemptions: toNullableInt,
            orderLine: {
                field: ['orderLine', 'orderitems'],
                /**
                 * @param {any} value
                 * @param {{}} allValues
                 * @param {DigiTickets.Hydrator} hydrator
                 *
                 * @return {?OrderLine}
                 */
                transform(value, allValues, hydrator) {
                    if (!value) {
                        return null;
                    }
                    // Import OrderLine here to avoid a circular dependency if it were imported up the top.
                    const OrderLine = require('./OrderLine');
                    return hydrator.hydrate(value, new OrderLine());
                }
            },
            redemptions: {
                modelCollection: DigiTickets.Redemption
            },
            thirdPartyRef: toNullableString
        };
    },

    /**
     * @param {number} instance
     *
     * @return {ItemInstance}
     */
    setInstance(instance) {
        this.instance = instance;

        return this;
    },

    /**
     * @param {number|string} newCount
     */
    setNetRedemptions(newCount) {
        this.netRedemptions = parseInt(newCount, 10);
    },

    /**
     * @return {?DigiTickets.Membership}
     */
    getMembership() {
        return this.membershipSubscription ? this.membershipSubscription.membership : null;
    },

    /**
     * Get a list of Members on this item instance.
     *
     * @return {DigiTickets.Member[]}
     */
    getMembers() {
        return this.membershipSubscription ? this.membershipSubscription.getMembers() : [];
    },

    setRedemptionInProgress() {
        this.redemptionInProgress = true;
    },

    setUnredemptionInProgress() {
        this.unredemptionInProgress = true;
    },

    clearRedemptionInProgress() {
        this.redemptionInProgress = false;
        this.unredemptionInProgress = false;
    },

    /**
     * @return {boolean}
     */
    redemptionIsInProgress() {
        return this.redemptionInProgress || this.unredemptionInProgress;
    },

    /**
     * Regular item instance numbers start at 1.
     * Instance numbers when used in field data start at 0.
     * See BAC-2714
     *
     * @return {number}
     */
    get instanceForFieldData() {
        return this.instance - 1;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ItemInstance;
}
