/**
 * @param {UserService} UserService
 */
const memberTileDirective = function (
    UserService
) {
    return {
        restrict: 'E',
        scope: {
            editMemberFunc: '=',
            index: '=',
            member: '=',
            membership: '=',
            membershipSubscription: '=',
            selectedMember: '=',
            showMembershipRef: '=',
            toggleVisitFunc: '='
        },
        templateUrl: 'partials/directives/dt-member-tile.html',
        /**
         * @param {function|null} $scope.editMemberFunc
         * @param {function|null} $scope.toggleVisitFunc
         * @param {DigiTickets.Member} $scope.member
         * @param {DigiTickets.Membership} $scope.membership
         * @param $scope
         * @param {MembershipSubscription|null} $scope.membershipSubscription
         */
        controller: function controller($scope) {
            /**
             * Determine if the contact ref should be shown on the title.
             * This is an array of card allocation types that should cause the ref to be shown.
             *
             * @type {string[]}
             */
            let showMemberRefsFor = [
                DigiTickets.MembershipCardAllocationType.ADULTS,
                DigiTickets.MembershipCardAllocationType.MEMBER,
            ];
            $scope.showMemberRef = showMemberRefsFor.indexOf(UserService.company.membershipCardAllocation) !== -1;
            if ($scope.showMembershipRef) {
                $scope.showMemberRef = true;
            }

            /**
             * When the edit button is clicked on the tile fire the passed in edit function.
             *
             * @param member
             * @param index
             */
            $scope.editTileMember = function (member, index) {
                if (typeof $scope.editMemberFunc === 'function') {
                    $scope.editMemberFunc(member, index);
                }
            };

            /**
             * When the redeem/un-redeem button is clicked on the title fire the passed in redeem function.
             *
             * @param member
             */
            $scope.toggleTileMemberVisit = function (member) {
                if (typeof $scope.toggleVisitFunc === 'function') {
                    $scope.toggleVisitFunc(
                        member,
                        $scope.membershipSubscription,
                        $scope.membershipSubscription.itemInstance.orderLine,
                        $scope.membershipSubscription.membershipPlan,
                        $scope.membership
                    );
                }
            };
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = memberTileDirective;
}
