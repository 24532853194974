/**
 * A CartItemGroup is a subset of items in a Cart that share something in common.
 * (Currently only used to group items for the same session)
 * Items in a group are visually grouped together when displaying the cart.
 *
 * This should contain no logic. It is purely for presentation / grouping items.
 *
 * @param {string} key
 * @param {string} type
 */
const CartItemGroup = function (
    key,
    type
) {
    /**
     * A unique key for this group.
     * If it's a group for the same session it should be something like session-{sessionID}
     *
     * @type {string}
     */
    this.key = key;

    /**
     * The type of group. Possible values currently are 'default' or 'session'.
     *
     * @type {string}
     */
    this.type = type;

    /**
     * @type {DigiTickets.CartItem[]}
     */
    this.items = [];

    /**
     * @type {DigiTickets.Event}
     */
    this.event = null;

    /**
     * @type {Session}
     */
    this.session = null;
};

CartItemGroup.prototype = {
    /**
     * @param {DigiTickets.CartItem} item
     */
    addItem(item) {
        this.items.push(item);
    },

    /**
     * @param {?DigiTickets.Event} event
     */
    setEvent(event) {
        this.event = event || null;
    },

    /**
     * @param {?Session} session
     */
    setSession(session) {
        this.session = session || null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined') {
    module.exports = CartItemGroup;
}
