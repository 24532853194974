const { toDate, toFloat, toNullableInt, toString, toNullableString } = require('../functions/transform');

/**
 * @param {string} action
 */
DigiTickets.TradingSessionEvent = function (action) {
    this.action = action;
    this.apiKey = null;
    this.amount = 0.00;
    /**
     * Arbitrary additional data to save with the event.
     * Should be a string, so JSON encoded.
     *
     * @type {string|null}
     */
    this.data = null;
    this.denominations = null;
    this.floatAdjustmentReason = null;
    this.floatAdjustmentReasonID = null;
    this.ID = null;
    this.managerID = null;
    this.narrative = null;
    this.operatorID = null;
    this.triggeredAt = new Date().toISOString();
};

DigiTickets.TradingSessionEvent.prototype = {
    getHydrationMap() {
        return {
            amount: toFloat,
            action: {
                field: ['action', 'auditTypeCode'],
                transform: toString
            },
            floatAdjustmentReason: toNullableString,
            floatAdjustmentReasonID: toNullableInt,
            ID: {
                field: ['ID', 'tradingSessionEventID'],
                transform: toString
            },
            narrative: {
                field: ['narrative', 'text'],
                transform: toNullableString
            },
            triggeredAt: {
                field: ['triggeredAt', 'auditEventTime'],
                transform: toDate
            }
        };
    },

    setApiKey(apiKey) {
        this.apiKey = apiKey;

        return this;
    },

    /**
     * @param {number} operatorID
     */
    setOperatorID: function setOperatorID(operatorID) {
        this.operatorID = operatorID || null;

        return this;
    },

    /**
     * @param {float} amount
     */
    setAmount: function setAmount(amount) {
        if (typeof amount !== 'undefined') {
            this.amount = amount;
        }

        return this;
    },

    setData: function setData(data) {
        this.data = data === null ? null : JSON.stringify(data);

        return this;
    },

    /**
     * @param {number} managerID
     */
    setManagerID: function setManagerID(managerID) {
        this.managerID = managerID || null;

        return this;
    },

    /**
     * @param {string} narrative
     */
    setNarrative: function setNarrative(narrative) {
        this.narrative = narrative || null;

        return this;
    },

    /**
     * @param {int=} floatAdjustmentReasonID
     */
    setFloatAdjustmentReasonID: function setFloatAdjustmentReasonID(floatAdjustmentReasonID) {
        this.floatAdjustmentReasonID = floatAdjustmentReasonID || null;

        return this;
    }
};
