const PaymentMethod = require('../libraries/DigiTickets/PaymentMethods/PaymentMethod');
const { toFloat, toInt } = require('../functions/transform');

DigiTickets.TradingSessionFinancialSummary = function () {
    /**
     * @type {PaymentMethod}
     */
    this.paymentMethod = null;

    this.qty = 0;
    this.value = 0.00;
    this.tendered = 0.00;
    this.changeGiven = 0.00;
    this.cashback = 0.00;
    this.gratuity = 0.00;

    this.ID = null;
    this.tradingSessionID = null;
};

DigiTickets.TradingSessionFinancialSummary.prototype = {
    getHydrationMap() {
        return {
            cashback: toFloat,
            changeGiven: toFloat,
            gratuity: toFloat,
            ID: {
                field: ['ID', 'tradingSessionFinancialSummaryID'],
                transform: toInt
            },
            paymentMethod: {
                model: PaymentMethod
            },
            qty: toInt,
            tendered: toFloat,
            tradingSessionID: toInt,
            value: toFloat
        };
    }
};
