const Category = require('@/lib/Categories/Category');
const GiftAidRate = require('./GiftAidRate');
const { toBool, toNullableBool, toFloat, toNullableFloat, toInt, toNullableInt } = require('../functions/transform');

DigiTickets.Ticket = function () {
    this.allowPriceEdit = false;

    /**
     * @type {Number}
     */
    this.catID = null;

    this.category = {};
    this.event = null;
    this.giftAid = false;

    /**
     * @type {?GiftAidRate}
     */
    this.giftAidRate = null;
    this.ID = 0;
    this.itemType = DigiTickets.ItemType.TICKET;
    this.name = '';
    this.navOrder = 10000;

    /**
     * @type {number}
     */
    this.people = 0;

    this.paymentPattern = null;
    this.shortName = null;
    this.timeSpecific = '';

    /**
     * Should this ticket come out of the printer when clicking the 'Print Tickets' button?
     * (Badly named field - should be something like "shouldPrintTicket".)
     * This is marked private because we want to rename this to something more sensible. Use the shouldPrintTicket
     * getter instead.
     *
     * @private
     * @type {boolean}
     */
    this.printTicketPerInstance = false;

    /**
     * @type {?Number}
     */
    this.validityPeriod = null;

    /**
     * The default price of a ticket unless modified by an adjustment such as a pricing rule.
     * If this price is not modified this will be the offlinePrice if set otherwise the (online) price.
     *
     * @type {number}
     */
    this.price = 0.00;

    /**
     * The price of a ticket sold via ProPoint if different to the online price (this.price).
     *
     * @type {?Number}
     */
    this.offlinePrice = null;

    /**
     * The initial price of a ticket in ProPoint prior to an adjustment such as a pricing rule.
     *
     * @type {?Number}
     */
    this.initialPrice = null;
};

DigiTickets.Ticket.prototype = {
    /**
     * @returns {string}
     */
    getName: function getName() {
        // use shortName in epos by default if available
        return this.shortName ? this.shortName : this.name;
    },

    /**
     * @return {number}
     */
    getPeople() {
        return parseInt(this.people ? this.people : 1);
    },

    /**
     * Return the price of a ticket after pricing rules.
     *
     * @returns {Number}
     */
    getPrice: function getPrice() {
        if (this.hasAdjustedPrice()) {
            return parseFloat(this.price);
        }
        return parseFloat(this.offlinePrice ? this.offlinePrice : this.price);
    },

    hasAdjustedPrice() {
        return this.initialPrice !== null && this.initialPrice !== this.price;
    },

    /**
     * @returns {boolean}
     * @todo Will need to be updated to handle multi-session tickets.
     */
    isSessionBased: function isSessionBased() {
        return this.timeSpecific == 'Event';
    },

    afterHydration: function afterHydration() {
        // if allowPriceEdit is null, inherit whatever is set on the category
        this.allowPriceEdit = (this.allowPriceEdit === null) ? (this.category ? this.category.allowPriceEdit : false) : this.allowPriceEdit;

        // Remember the initial price so we know if it changes.
        if (this.initialPrice === null) {
            this.initialPrice = this.price;
        }
    },

    /**
     * @returns {object}
     */
    getHydrationMap() {
        return {
            allowPriceEdit: toNullableBool,
            category: {
                field: ['category', 'categories'],
                model: Category
            },
            catID: toInt,
            event: {
                field: ['event', 'events'],
                model: DigiTickets.Event
            },
            giftAid: toBool,
            giftAidRate: {
                model: GiftAidRate
            },
            ID: {
                field: ['ID', 'itemID'],
                transform: toInt
            },
            initialPrice: toNullableFloat,
            name: {},
            navOrder: toInt,
            offlinePrice: toNullableFloat,
            paymentPattern: {},
            people: toInt,
            price: toFloat,
            printTicketPerInstance: toBool,
            shortName: {},
            timeSpecific: {},
            validityPeriod: toNullableInt
        };
    },

    /**
     * Should this ticket come out of the printer when clicking 'Print Tickets'?
     *
     * @return {boolean}
     */
    get canPrintTicket() {
        return !!this.printTicketPerInstance;
    }
};
