const Currency = require('./Currency');
const FloatAdjustmentReason = require('./FloatAdjustmentReason');
const PaymentMethod = require('../libraries/DigiTickets/PaymentMethods/PaymentMethod');
const TaxRegistration = require('./TaxRegistration');
const { toBool, toFloat, toInt, toNullableString } = require('../functions/transform');

DigiTickets.Company = function () {
    /**
     * @type {DigiTickets.AutoRedeemProfile[]}
     */
    this.autoRedeemProfiles = [];

    /**
     * @type {PaymentMethod[]}
     */
    this.companyPaymentMethods = [];

    this.contactTitlesEnabled = false;
    /**
     * @type {DigiTickets.Country}
     */
    this.country = null;
    /**
     * @type {Currency}
     */
    this.currency = null;
    this.currencyID = null;
    this.customerBackground = null;
    this.customerWaitingBackground = null;

    /**
     * @type {boolean}
     */
    this.enableOrderEditing = false;
    this.email = null;
    this.receiptEmail = null;
    this.floatAdjustmentReasons = [];
    /**
     * @type {boolean}
     */
    this.gratuityEnabled = false;
    this.ID = null;
    this.imageUrl = null;
    this.live = false;
    /**
     * If no max is specified cashback will be disabled.
     *
     * @type {float|null}
     */
    this.maxCashback = null;
    /**
     * @type {string|null}
     */
    this.membershipCardAllocation = null;
    this.name = null;
    this.subdomain = null;
    this.taxRegistrations = [];
    this.tel = null;
    this.receiptTel = null;
    this.website = null;
    this.printableLogoUrl = null;

    /**
     * @type {?string}
     */
    this.encodedPrintableLogo = null;

    this.googleCloudApiKey = null;
};

DigiTickets.Company.prototype = {
    afterHydration: function afterHydration(data, CountryManager) {
        /**
         * Restrict the payment methods to the ones that are allowed and configured appropriately.
         *
         * @type {PaymentMethod[]}
         */
        this.companyPaymentMethods = this.companyPaymentMethods.filter(function (paymentMethod) {
            return !!paymentMethod.showOnEpos;
        });

        // Sort payment methods by navOrder.
        this.companyPaymentMethods.sort(
            /**
             * @param {PaymentMethod} a
             * @param {PaymentMethod} b
             */
            function (a, b) {
                return a.navOrder > b.navOrder ? 1 : -1;
            }
        );

        if (CountryManager) {
            this.country = CountryManager.findByData(data);
        }
    },

    getHydrationMap() {
        return {
            autoRedeemProfiles: {
                modelCollection: DigiTickets.AutoRedeemProfile
            },
            companyPaymentMethods: {
                field: ['companiespaymentmethods', 'companyPaymentMethods'],
                modelCollection: PaymentMethod
            },
            contactTitlesEnabled: toBool,
            currency: {
                // Has both currencies and currency for compatibility with PRO-675.
                // Both are needed in case the Company model gets rehydrated.
                field: ['currencies', 'currency'],
                model: Currency
            },
            currencyID: toInt,
            email: {},
            enableOrderEditing: toBool,
            encodedPrintableLogo: toNullableString,
            floatAdjustmentReasons: {
                modelCollection: FloatAdjustmentReason
            },
            gratuityEnabled: toBool,
            ID: {
                field: ['ID', 'companyID'],
                transform: toInt
            },
            imageUrl: {},
            printableLogoUrl: {},
            live: {},
            maxCashback: toFloat,
            membershipCardAllocation: {},
            name: {},
            receiptTel: {},
            receiptEmail: {},
            subdomain: {},
            taxRegistrations: {
                modelCollection: TaxRegistration
            },
            tel: {},
            website: {},
            googleCloudApiKey: {}
        };
    },

    /**
     * This appears to not be used at first. But some custom receipt templates (in the sites repository) do
     * use this method.
     *
     * FIXME: Use getTaxRegistrationCodeForCountry instead which is more logical.
     * This is left in because as the comment above says it's used in custom templates in the sites repo.
     *
     * @param {UserService} userService
     *
     * @return {?TaxRegistration}
     */
    getTaxRegistrationCode: function (userService) {
        if (!userService.currentBranch.country) {
            return null;
        }

        for (let i = 0; i < this.taxRegistrations.length; ++i) {
            if (this.taxRegistrations[i].countryID === userService.currentBranch.country.ID) {
                return this.taxRegistrations[i].registrationCode;
            }
        }

        return null;
    },

    /**
     * @param {DigiTickets.Country} country
     *
     * @return {?TaxRegistration}
     */
    getTaxRegistrationCodeForCountry: function (country) {
        if (!country) {
            // Returning undefined instead of null to match the behaviour of .find().
            return undefined;
        }
        return this.taxRegistrations.find((reg) => reg.countryID === country.ID);
    },

    isLive: function isLive() {
        return !!this.live;
    },

    shouldCaptureTitles: function shouldCaptureTitles() {
        return this.contactTitlesEnabled;
    },

    /**
     * @return {DigiTickets.AutoRedeemProfile|null}
     */
    getAutoRedeemProfile: function getAutoRedeemProfile() {
        if (this.autoRedeemProfiles.length > 0) {
            for (let i = 0; i < this.autoRedeemProfiles.length; i++) {
                if (this.autoRedeemProfiles[i].enabled) {
                    return this.autoRedeemProfiles[i];
                }
            }
        }

        return null;
    }
};
