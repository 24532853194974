const { toInt } = require('../../../../../../functions/transform');

const VerifoneLoginResponseRecord = function () {
    /**
     * '0' - Success
     * '-nn' - Negative values are used to define error conditions.
     */
    this.result = null;

    /**
     * @type {string}
     */
    this.message = null;

    /**
     * @type {string}
     */
    this.rawResponse = null;
};

VerifoneLoginResponseRecord.prototype = {
    getHydrationMap() {
        return {
            result: {
                field: 1,
                transform: toInt
            },
            message: {
                field: 18
            },
            rawResponse: {}
        };
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneLoginResponseRecord;
}
