const AbstractSearchCache = require('../AbstractSearchCache');
const { cloneShallow } = require('../../../functions/clone');

/**
 * This is a singleton which holds the state of the search results from the redeem page.
 * It remembers what was entered into the search box, including advanced filters, remembers the search results,
 * and remembers what page of the results was shown.
 */
const OrderSearchCache = function () {
    AbstractSearchCache.call(this);

    this.form = {
        search: '',
        notes: '',
        startDate: null,
        endDate: null,
        dateType: 'order',
        advanced: false
    };

    // Remember the default values to put back when we reset.
    // This is done this way rather than specifying the defaults then setting the initial value to the defaults,
    // so that the IDE knows the properties of this.form.
    this.formDefaults = cloneShallow(this.form);

    this.onlineResult = null;
};

OrderSearchCache.prototype = Object.create(AbstractSearchCache.prototype);
OrderSearchCache.constructor = OrderSearchCache;

/**
 * @param {string|number} refOrID
 *
 * @return {?DigiTickets.Order}
 */
OrderSearchCache.prototype.getOrderFromCache = function (refOrID) {
    if (!this.results || !(this.results instanceof Array)) {
        return null;
    }

    let id = parseInt(refOrID, 10);

    return this.results.find(
        /**
         * @param {DigiTickets.Order} result
         */
        (result) => result.bookingRef === refOrID || result.ID === id
    ) || null;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderSearchCache;
}
