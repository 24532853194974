const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const MembershipPlanResource = function ($resource, ApiRequestParams, AppConfig, MembershipPlanCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'membershipplans/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                channelID: AppConfig.channelID
            }
        ),
        {
            query: {
                cache: MembershipPlanCache,
                isArray: true,
                params: Object.assign(
                    {
                        resolve: 'categories',
                        excludeFields: ApiRequestParams.defaultExcludeFields()
                    },
                    ApiRequestParams.defaultMappers()
                ),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'MembershipPlan')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipPlanResource;
}
