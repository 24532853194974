const ConfirmModalCtrl = function ConfirmModalCtrl(
    $modalInstance,
    $scope,
    message,
    options
) {
    if (!options) {
        options = {};
    }
    $scope.options = options;

    $scope.cancelLabel = options.hasOwnProperty('cancelLabel') ? options.cancelLabel : 'DIALOGS.NO_LABEL';
    $scope.cancelLabelParams = options.hasOwnProperty('cancelLabelParams') ? options.cancelLabelParams : {};
    $scope.cancelBtnClass = options.hasOwnProperty('cancelBtnClass') ? options.cancelBtnClass : 'btn-danger';

    $scope.okLabel = options.hasOwnProperty('okLabel') ? options.okLabel : 'DIALOGS.YES_LABEL';
    $scope.okLabelParams = options.hasOwnProperty('okLabelParams') ? options.okLabelParams : {};
    $scope.okBtnClass = options.hasOwnProperty('okBtnClass') ? options.okBtnClass : 'btn-success';

    $scope.title = options.hasOwnProperty('title') ? options.title : 'DIALOGS.CONFIRM_TITLE';
    $scope.titleParams = options.hasOwnProperty('titleParams') ? options.titleParams : {};

    $scope.customButtons = null;
    if (options.hasOwnProperty('customButtons')) {
        // Instead of an ok / cancel button, an array of buttons can be specified instead.
        $scope.customButtons = options.customButtons;
    }

    if ($scope.options.rawMessage) {
        /**
         * Display some raw HTML as the message.
         */
        $scope.rawMessage = message;
    } else {
        /**
         * Display a language file string as the message.
         */
        $scope.message = message;
        $scope.messageParams = options.hasOwnProperty('messageParams') ? options.messageParams : {};
    }

    $scope.ok = function ok() {
        $modalInstance.close();
    };

    // Go to report page
    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.customButtonClick = function (index) {
        if ($scope.customButtons[index].hasOwnProperty('click')) {
            $scope.customButtons[index].click($modalInstance);
        } else {
            $modalInstance.close(index);
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ConfirmModalCtrl;
}
