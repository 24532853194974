const Field = require('./Field');
const FieldType = require('./FieldType');
const { populate } = require('../../../functions/objects');
const { toNullableInt } = require('../../../functions/transform');

/**
 * A container to hold a Field and its data together.
 * A FieldInstance represents a single input field (or set of radios/checkboxes) on the details page.
 * i.e. There can be many FieldInstances on a page for the same Field, but for different Items / Sessions.
 *
 * @param {{field: Field, lineNumber?: number, itemInstance?: number}} [properties]
 */
const FieldInstance = function (
    properties = {}
) {
    /**
     * @type {Field}
     */
    this.field = null;

    /**
     * The user-supplied data for this instance.
     * If this is a field with options, this will be a fieldOptionID.
     * If this is a field with multiple options, this will be an array of fieldOptionIDs.
     * Otherwise it'll be whatever text the user entered.
     *
     * @type {string|int|int[]}
     */
    this.value = null;

    /**
     * The human readable input from the user.
     * The same as this.value but optionIDs are converted to the actual option value.
     *
     * @type {string|string[]}
     */
    this.displayValue = null;

    /**
     * Flag showing if data has been entered for this instance.
     * (Set automatically when adding / clearing value.)
     *
     * @type {boolean}
     */
    this.hasValue = false;

    /**
     * If this is a date of birth field the age will be calculated from the DOB and set here during validation.
     *
     * @type {Number}
     */
    this.age = null;

    /**
     * Has this field passed validation?
     * null means it has not been validated yet so is neither valid nor invalid.
     *
     * @type {Boolean}
     */
    this.isValid = null;

    /**
     * An error message from validation.
     *
     * @type {?String}
     */
    this.error = null;

    /**
     * An error message related to Date of Birth validation.
     * TODO: This is unnecessary and this.error can just be used instead.
     *
     * @type {null}
     */
    this.ageError = null;

    /**
     * If this is an item level field this is the line the data belongs to.
     *
     * @type {?number}
     */
    this.lineNumber = null;

    /**
     * If this is an item level field this is the item instance number this data belongs to.
     * Note that item instance numbers in field data start at 0 but on the item instance they start at 1,
     * so this is 1 less than the item instance you expect! (BAC-2714)
     *
     * @type {?number}
     */
    this.itemInstance = null;

    /**
     * See: generateKey()
     *
     * @type {string}
     */
    this.key = '';

    this.init(properties);
};

FieldInstance.prototype = {
    /**
     * Initialize this FieldInstance with the given variables.
     *
     * @param {object} properties Key value pairs where the keys match any member variable names above.
     * @return {FieldInstance}
     */
    init(properties) {
        if (properties) {
            // Loop though the given props and set them.
            // We do it this way (passing in an array instead of a bunch of variables) so we can keep the desired
            // defaults (above) for unspecified ones
            populate(this, properties);
        }

        this.updateKey();
        this.clearValue();

        return this;
    },

    /**
     * Returns the underlying Field.
     *
     * @return {Field}
     */
    getField() {
        return this.field;
    },

    /**
     * @param {?number} lineNumber
     */
    setLineNumber(lineNumber) {
        this.lineNumber = lineNumber;
        this.updateKey();
    },

    /**
     * @param {?number} itemInstance
     */
    setItemInstance(itemInstance) {
        this.itemInstance = itemInstance;
        this.updateKey();
    },

    /**
     * Returns the unique string for this FieldInstance.
     * Can be using as an HTML input name.
     *
     * @return {string}
     */
    getKey() {
        return this.key;
    },

    /**
     * @private
     */
    updateKey() {
        this.key = this.generateKey(
            this.field ? this.field.level : null,
            this.field ? this.field.ID : null,
            this.lineNumber,
            this.itemInstance
        );
    },

    //
    // Generates a string that can be used to uniquely identify this item instance.
    // Can be used as the name of an HTML input.
    //
    // @return {string} Order level:  order.~$fieldID
    // Item Level:   line.~$lineNumber.~$itemInstance.~$fieldID
    //
    // This format allows for unflatten(this.fieldData, null, null, '.')
    // @param level
    // @param fieldID
    // @param lineNumber
    // @param itemInstance
    //
    generateKey(level, fieldID, lineNumber, itemInstance) {
        let keys;
        switch (level) {
            case 'order':
                keys = ['order', fieldID];
                break;
            case 'item':
                keys = ['line', lineNumber, itemInstance, fieldID];
                break;
            default:
                keys = [];
        }

        return keys.join('.~');
    },

    /**
     * Clear any data entered for this field and reset to default values.
     */
    clearValue() {
        if (this.field && this.field.hasMultipleOptions) {
            this.value = [];
            this.displayValue = [];
            this.hasValue = false;
        } else {
            this.value = null;
            this.displayValue = null;
            this.hasValue = false;
        }
    },

    /**
     * Set the user-supplied data for this instance. Replaces any existing data.
     *
     * @param {string|int|int[]} value If this is a field with options, value should be a fieldOptionID.
     *                                 If this is a field with multiple options, value
     *                                 should be an array of fieldOptionIDs.
     *                                 Otherwise value should be a string containing text entered by the user.
     * @param {string|string[]} [displayValue]  If given, sets the human readable version of the value.
     *                                          Otherwise, if this is a field with options, the value for the
     *                                          appropriate FieldOptions will be used.
     *                                          Otherwise the value will be used.
     *
     * @return {FieldInstance}
     */
    setValue(value, displayValue) {
        // Multi select fields have the value as an array, so cast to an array if only a single value was given.
        if (this.field.hasMultipleOptions) {
            if (!(value instanceof Array)) {
                value = [value];
            }

            if (displayValue && !(displayValue instanceof Array)) {
                displayValue = [displayValue];
            }
        }

        if (this.field.hasMultipleOptions) {
            // Convert all optionIDs to integers.
            this.value = value.map((v) => parseInt(v, 10)).filter((v) => !!v);
        } else if (this.field.hasOptions) {
            // Convert optionID to integer.
            this.value = value ? parseInt(value, 10) : null;
        } else {
            this.value = value;
        }

        this.updateHasValue();

        // TODO: Set the displayValue from FieldOptions attached to this.field.
        if (displayValue) {
            this.displayValue = displayValue;
        } else {
            this.updateDisplayValue();
        }

        return this;
    },

    /**
     * If this is a multi-option field, appends the value to the values array.
     * Otherwise does the same as setValue()
     *
     * Note: If you call this once and provide displayValue, make sure you supply a displayValue on every
     * subsequent call or the previous given displayValue will be lost. Currently the only use of this already
     * does that, but keep that in mind for future uses.
     *
     * @param {string|int} value
     * @param displayValue
     */
    appendValue(value, displayValue) {
        if (this.hasValue && this.field.hasMultipleOptions) {
            this.value.push(value);
            this.displayValue.push(displayValue);
        } else {
            this.setValue(value, displayValue);
        }
    },

    /**
     * Get the entered value.
     *
     * @param {bool} [flatten] If true and this is a multi-option field, returns just the first value.
     *
     * @return {string|int|int[]}
     */
    getValue(flatten) {
        if (flatten && this.value instanceof Array) {
            return this.value[0];
        }

        return this.value;
    },

    /**
     * Get the human-readable entered value.
     *
     * @param {boolean} [flatten] If true and this is a multi-option field, values are joined with a comma.
     *
     * @return {string|string[]}
     */
    getDisplayValue(flatten) {
        if (flatten && this.displayValue instanceof Array) {
            return this.displayValue.join(', ');
        }

        if (this.field && this.field.type === FieldType.DOB && this.age) {
            return `${this.displayValue} (Age ${this.age})`;
        }

        return this.displayValue;
    },

    /**
     * Set the displayValue from the names of attached FieldOptions.
     * If this isn't a field with options, just sets displayValue to value.
     *
     * Note: do not call this if a displayValue was supplied in a call to setValue() or appendValue()
     * or that value will be lost.
     */
    updateDisplayValue() {
        if (this.field.hasMultipleOptions) {
            this.displayValue = this.value.map((fieldOptionID) => {
                let option = this.field.getOptionByID(fieldOptionID);
                return option ? option.name : fieldOptionID;
            });
        } else if (this.field.hasOptions) {
            let option = this.field.getOptionByID(this.value);
            this.displayValue = option ? option.name : this.value;
        } else {
            this.displayValue = this.value;
        }
    },

    /**
     * Set the hasValue property depending on if data has been entered or not.
     */
    updateHasValue() {
        // length works for both arrays and strings so this caters for checkboxes too.
        this.hasValue = (this.value !== null && ('' + this.value).length > 0);
    },

    getHydrationMap() {
        return {
            displayValue: {},
            error: {},
            field: {
                model: Field
            },
            itemInstance: toNullableInt,
            key: {},
            lineNumber: toNullableInt,
            value: {}
        };
    },

    afterHydration() {
        this.updateHasValue();
        this.updateKey();
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldInstance;
}
