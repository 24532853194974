const angular = require('angular');

/**
 * Focus and select any text already in an input field, when it loads.
 * Useful for any fields that need to be edited by default.
 *
 * @param $timeout
 * @return {{link: Function}}
 */
const focusMeDirective = function ($timeout) {
    return {
        link: function link(scope, element, attrs) {
            scope.$watch(attrs.dtFocusMe, function (val) {
                if (angular.isDefined(val) && val) {
                    $timeout(function () {
                        element[0].focus();
                    }, 20);
                }
            }, true);

            element.bind('blur', function () {
                if (angular.isDefined(attrs.dtFocusMeLost)) {
                    scope.$apply(attrs.dtFocusMeLost);
                }
            });

            element.on('focus', function () {
                $timeout(function () {
                    element[0].select();
                    element[0].setSelectionRange(0, element[0].value.length); // mobile safari
                });
            });
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = focusMeDirective;
}
