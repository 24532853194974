/**
 * Displays information about all stashed carts, with the ability to restore them.
 *
 * @param $filter
 * @param $modalInstance
 * @param $scope
 * @param $timeout
 * @param {CartService} cartService
 * @param CartStasher
 * @param CartStashHelperService
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {ToastFactory} toastFactory
 * @param {UserService} UserService
 * @param {UserSessionManager} UserSessionManager
 */
const HeldOrdersCtrl = function (
    $filter,
    $modalInstance,
    $scope,
    $timeout,
    cartService,
    CartStasher,
    CartStashHelperService,
    NotificationService,
    toastFactory,
    UserService,
    UserSessionManager
) {
    $scope.cartStashHelper = CartStashHelperService;
    $scope.showAll = false;
    $scope.showLoader = false;
    $scope.user = UserService;

    /**
     * @type {DigiTickets.StashedCart[]}
     */
    $scope.stashedCarts = [];

    $scope.ok = function ok() {
        $modalInstance.close();
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.setShowAll = (showAll) => {
        $scope.showAll = showAll;
    };

    /**
     * Method to load all the stashed orders (carts) into this pop-up. They are sorted
     * so the logged-in user's carts are first, and then by most recent stash first.
     */
    $scope.loadStashedCarts = function loadStashedCarts() {
        $scope.showLoader = true;

        CartStasher.loadStashedCarts()
            .then((stashedCarts) => $timeout(() => {
                $scope.showLoader = false;
                $scope.stashedCarts = stashedCarts;
            }));
    };

    /**
     * Remove a stashed cart from the local and remote storage.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     * @param {number} [$index]
     */
    $scope.deleteStashedCart = function deleteStashedCart(stashedCart, $index) {
        if ($index !== undefined) {
            $scope.stashedCarts.splice($index, 1);
        }

        return Promise.all(
            [
                UserSessionManager.deleteStashedCartByGuid(stashedCart.guid),
                new Promise((resolve) => CartStasher.deleteRemoteStashedCart(stashedCart, resolve)),
            ]
        );
    };

    /**
     * Populate the cart from the given stashed cart. Deletes the stashed cart.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     */
    $scope.restoreStashedCart = async (stashedCart) => {
        const populatePromise = cartService.populateFromStash(stashedCart);
        toastFactory.spinner('ON_HOLD_ORDERS.RESTORING', null, populatePromise);
        await populatePromise;
        $scope.deleteStashedCart(stashedCart);
        $scope.ok();
        toastFactory.successTop('ON_HOLD_ORDERS.RESTORED');
    };

    // Load the stashed orders into memory.
    $scope.loadStashedCarts();
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = HeldOrdersCtrl;
}
