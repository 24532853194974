const { toInt, toString, fromJson } = require('../functions/transform');

/**
 * Representation of a single user.
 * This is the beginnings of separating the User model from the UserService so there is a lot of duplication between
 * the two. Eventually the info about the user will live here and UserService will only contain a User model instead
 * of all the properties.
 */
const User = function () {
    /**
     * @type {?number}
     */
    this.ID = null;

    /**
     * @type {?string}
     */
    this.apiKey = null;

    /**
     * @type {DigiTickets.Branch[]}
     */
    this.branches = [];

    /**
     * @type {?DigiTickets.Company}
     */
    this.company = null;

    /**
     * @type {?object}
     */
    this.paymentTerminalConfig = null;

    /**
     * @type {?number}
     */
    this.usergroupID = null;

    /**
     * @type {?string}
     */
    this.username = null;
};

User.prototype = {
    getHydrationMap() {
        return {
            apiKey: toString,
            branches: {
                modelCollection: DigiTickets.Branch
            },
            company: {
                field: ['company', 'companies'],
                model: DigiTickets.Company
            },
            ID: {
                field: ['userID', 'ID'],
                transform: toInt
            },
            paymentTerminalConfig: fromJson,
            usergroupID: toInt,
            username: toString
        };
    },

    /**
     * @param {number} branchID
     *
     * @return {?DigiTickets.Branch}
     */
    getBranchByID(branchID) {
        return this.branches.find((b) => b.ID === branchID) || null;
    },

    /**
     * @return {?DigiTickets.Branch}
     */
    getFirstBranch() {
        return this.branches[0] || null;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = User;
}
