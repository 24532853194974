const CategoryResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'categories/',
        ApiRequestParams.defaults(),
        {
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = CategoryResource;
}
