DigiTickets.Worldpay.TransactionType = Object.freeze({
    SALE: 0,
    PREAUTH: 1,
    PRE_SALES_COMPLETION: 2,
    CANCEL_TRANSACTION: 3,
    TAXFREE_VOUCHER: 8,
    SALE_WITH_TOKEN: 12,
    REFUND_WITH_TOKEN: 13,
    GET_TERRITORY: 15,
    RETURNS: 20,
    PRINT_DUPLICATE_MERCHANT_RECEIPT: 22,
    PRINT_DUPLICATE_CUSTOMER_RECEIPT: 23,
    GET_TOTAL_OFFLINE_TXNS: 25,
    GET_SERIAL_NUMBER: 26,
    CHECK_CHARD: 30,
    CHECK_STATUS: 34,
    LAST_TXN_RESULT: 35,
    X_REPORT: 36,
    Z_REPORT: 37,
    HIDE_IPC_WINDOW: 90,
    SHOW_IPC_WINDOW: 91,
    CLOSE_IPC_APPLICATION: 99
});
