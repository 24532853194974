/**
 * Report command which when sent to POS Client will produce a report, where x is the report required.
 *
 * @param what
 */
const VerifoneReportRequestRecord = function (what) {
    /**
     * 1 - Z Report – report detailing all transactions since last Z report run
     * 2 - X Report – report detailing all transaction since last Z report, but without resetting the values as with Z
     * report
     * 3 - Txn Report – report of last 10 processed transactions
     * 5 - Q Report – quick report showing total processed amount since last Z report, and the date and time of the
     * last Z report
     * 10 - Last Printed Report (Re-print) – reprints the last printed report
     * 11 - Stored Offline Txn Report – report of any stored offline transactions which are yet to be submitted to the
     * Gateway server
     * 100 - Product List – prints a list of available products, e.g. till roll
     * 101 - Reprints customer receipt – reprints customer receipt from the last processed transaction
     * 102 - Reprint Merchant – reprints the merchant receipt from the last processed transaction
     * 103 - Prints System Information report – prints System Info report, which contains information about the
     * software running on the device as well as all the network settings for the terminal
     * 201 - Live Store – prints a report showing all transaction in the live store waiting to be sent for settlement
     * 202 - Session Report –prints a report showing all transactions processed during the current logon session
     * 203 - Summary Settlement Report – prints a report showing the totals, broken down by card scheme, of transaction
     * from the last time the account was settled
     * 204 - Detailed Settlement Report – prints a report showing each settled transaction included in the last
     * settlement (e.g. the night before).
     * 205 - Barclays Gift Report – prints report on all Barclays Gift transactions. Uses the Barclays Gift Transaction
     * Response as it is not internal to Verifone and can be generated via integration or manually. Possible result
     * example: 0,,,,,Report Printing Completed,,,
     */
    this.what = what;
};

VerifoneReportRequestRecord.prototype = {
    toArray() {
        return [
            'REP',
            this.what,
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneReportRequestRecord;
}
