/**
 * The possible actions that can be performed for a status ID.
 *
 * From the "Status IDs to CONTTXN Mappings" section in "POS Client - Integration Guide V2.1"
 *
 * Some actions have been commented out because they seem like obscure ones that won't be used and can cause confusion.
 *
 * The screenId, screenName, and statusName are commented out because they are not used by the code.
 * There are here for reference. The same for the actions array. This was initially an object with ID:name, but the name
 * is never used so has been commented.
 *
 * Some additional actions have been added at the bottom, because the "Action ID Messages" table in the doc specifies
 * "Please see the below table for a list of the actions available and what stage the transaction must have reached
 * before being able to perform the required action." But some are not present in the "Status IDs to CONTTXN
 * Mappings" table.
 *
 * @type {Object<Object>} statusId: { ... actions: [actionId, actionId]}
 */
const VerifoneActionsForStatuses = {
    0: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "ProcessingTxn",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    3: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "AwaitingCard",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    4: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "SwipeCard",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    7: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "CardProcessing",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    10: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "KeyInCardDetails",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    11: {
        // NOTE: The WaitingforCashback status actions are wrong in POS Client Integration Guide v2.1.
        // The below actions are confirmed correct by calling Verifone.
        // screenId: null,
        // screenName: null,
        // statusName: "WaitingForCashback",
        actions: [
            29, // CashbackRequired
            30, // CashbackNotRequired
            12, // CancelTransaction
        ]
    },
    12: {
        // screenId: 105,
        // screenName: "PEDEnterPIN",
        // statusName: "PinEntry",
        actions: [
            // 1: // BypassPIN
            // 12, // CancelTransaction
        ]
    },
    13: {
        // screenId: 42,
        // screenName: "InsertCard",
        // statusName: "RiskManagementComplete",
        actions: [
            // 13, // AlternatePayment
            12, // CancelTransaction
        ]
    },
    18: {
        // screenId: 41,
        // screenName: "CheckSignature",
        // statusName: "SignatureConfirmationRequired",
        actions: [
            3, // ConfirmSignature
            4, // RejectSignature
            5, // ReprintReceipt
        ]
    },
    19: {
        // screenId: 167,
        // screenName: "MerchantICCReceiptScreen",
        // statusName: "ContinueRequired",
        actions: [
            2, // ContinueTxn
            5, // ReprintReceipt
            // 23, // ReverseUATICCTxn
        ]
    },
    24: {
        // screenId: 40,
        // screenName: "VoiceReferral",
        // statusName: "VoiceReferral",
        actions: [
            7, // VoiceReferralAuthorised
            8, // VoiceReferralRejected
        ]
    },
    55: {
        // screenId: 29,
        // screenName: "NoPinpad",
        // statusName: "PEDUnavailable",
        actions: [
            // 24, // RetryDevice
            // 25, // ContinueWithoutDevice
            // 26, // AbortDeviceConnectivity
        ]
    },
    57: {
        // screenId: 88,
        // screenName: "RetryDownload",
        // statusName: "RetryDownload",
        actions: [
            27, // RetryDownload
            28, // CancelDownload
        ]
    },
    58: {
        // screenId: 225,
        // screenName: "RestartAfterSoftware",
        // statusName: 31,
        actions: []
    },
    60: {
        // screenId: 104,
        // screenName: "DCCCurrencyChoice",
        // statusName: "DCCCurrencyChoice",
        actions: [
            5, // ReprintReceipt
            57, // MerchantCurrencyRequired
            58, // CardholderCurrencyRequired
        ]
    },
    62: {
        // screenId: 93,
        // screenName: "UnsafeDownload",
        // statusName: "UnsafeDownload",
        actions: [
            32, // AcceptUnsafeDownload
            33, // RejectUnsafeDownload
        ]
    },
    63: {
        // screenId: 25,
        // screenName: "UnexpectedLogin",
        // statusName: "UnexpectedLogin",
        actions: [
            34, // ReplaceAccount
            35, // CancelReplaceAccount
        ]
    },
    68: {
        // screenId: 442,
        // screenName: "RegisterForAccountOnFileDecision",
        // statusName: "RegisterForAccountOnFileDecision",
        actions: [
            38, // AccountOnFileRegistrationRequired
            39, // AccountOnFileRegistrationNotRequired
            12, // CancelTransaction
        ]
    },
    75: {
        // screenId: 303,
        // screenName: "PreparingDownload",
        // statusName: "DownloadStillBeingPrepared",
        actions: [
            27, // RetryDownload
            28, // CancelDownload
        ]
    },
    76: {
        // screenId: 141,
        // screenName: "ConnectionFailed_HostedServerLogin",
        // statusName: "ServerConnectionFailed",
        actions: [
            40, // ReconnectToServer
            41, // AbortReconnectToServer
        ]
    },
    77: {
        // screenId: 301,
        // screenName: "ResumeDownload",
        // statusName: "ResumeDownload",
        actions: [
            27, // RetryDownload
            28, // CancelDownload
        ]
    },
    101: {
        // screenId: 73,
        // screenName: "PayPointConfirmError",
        // statusName: "PayPointRetryConfirmationDecision",
        actions: [
            44, // RetryPayPointConfirmation
            45, // CancelPayPointConfirmation
        ]
    },
    105: {
        // screenId: 508,
        // screenName: "SelectPayPointSchemeOption",
        // statusName: "PayPointSelectSchemeOption",
        actions: [
            43, // SelectPayPointOption
            12, // CancelTransaction
        ]
    },
    107: {
        // screenId: 495,
        // screenName: "SelectPayPointAccount",
        // statusName: "PayPointSelectAccount",
        actions: [
            42, // SelectPayPointAccount
            12, // CancelTransaction
        ]
    },
    109: {
        // screenId: 23,
        // screenName: "VerifyFoundGUID",
        // statusName: "LicenceDetailConfirmation",
        actions: [
            46, // AcceptLicenceKey
            47, // RejectLicenceKey
            48, // CancelLicenceKeyVerification
        ]
    },
    110: {
        // screenId: 439,
        // screenName: "LicenseKeyRequired",
        // statusName: "LicenceFileRequired",
        actions: [
            49, // ContinueLicenceKeyVerification
        ]
    },
    130: {
        // screenId: 111,
        // screenName: "ParkRetailGiftConfirmError",
        // statusName: "ParkRetailGiftRetryConfirmationDecision",
        actions: [
            50, // RetryParkRetailGiftConfirmation
            51, // CancelParkRetailGiftConfirmation
        ]
    },
    134: {
        // screenId: 519,
        // screenName: "ConfirmParkRetailGiftAuthorisation",
        // statusName: "ConfirmParkRetailGiftAuthorisation",
        actions: [
            3, // ConfirmSignature
            4, // RejectSignature
            52, // ConfirmParkRetailGiftTransaction
            53, // ReverseParkRetailGiftTransaction
        ]
    },
    137: {
        // screenId: 532,
        // screenName: "UpdateTransactionValueDecision",
        // statusName: "UpdateTransactionValueDecision",
        actions: [
            55, // NoTransactionValueUpdateRequired
            56, // TransactionValueUpdateRequired
            12, // CancelTransaction
        ]
    },
    146: {
        // screenId: 35,
        // screenName: "EnterReference",
        // statusName: "MerchantReferenceRequired",
        actions: [
            59, // MerchantReferenceEntered
            12, // CancelTransaction
        ]
    },
    152: {
        // screenId: 538,
        // screenName: "ConfirmTransaxSvAuthorisation",
        // statusName: "ConfirmAuthorisation",
        actions: [
            3, // ConfirmSignature
            4, // RejectSignature
            5, // ReprintReceipt
            20, // ConfirmAuthCode
            21, // RejectAuthCode
        ]
    },
    175: {
        // screenId: 572,
        // screenName: "FurtherPaymentRequired",
        // statusName: "FurtherPaymentRequired",
        actions: [
            67, // FurtherPaymentRequired
            68, // NoFurtherPaymentRequired
            12, // CancelTransaction
        ]
    },
    181: {
        // screenId: 563,
        // screenName: "SVSPartPaymentDecision",
        // statusName: "PartPayment",
        actions: [
            65, // AcceptPartPayment
            66, // RejectPartPayment
            12, // CancelTransaction
        ]
    },
    182: {
        // screenId: 564,
        // screenName: "SVSObtainSTAN",
        // statusName: "ObtainSTAN",
        actions: [
            64, // SupplySTAN
            12, // CancelTransaction
        ]
    },
    184: {
        // screenId: 566,
        // screenName: "SVSProcessIVR",
        // statusName: "IVRRequired",
        actions: [
            60, // SupplyIVRDetails
            61, // RejectIVR
            12, // CancelTransaction
        ]
    },
    185: {
        // screenId: 567,
        // screenName: "ObtainIVRAuthCode",
        // statusName: "PromptForAuthorisationCode",
        actions: [
            12, // CancelTransaction
        ]
    },
    188: {
        // screenId: 568,
        // screenName: "EnterSVSReference",
        // statusName: "EnterReference",
        actions: [
            12, // CancelTransaction
        ]
    },
    189: {
        // screenId: 569,
        // screenName: "PrintShopCopy",
        // statusName: "PrintShopCopy",
        actions: [
            62, // PrintShopCopyReceipt
            63, // CancelShopCopyReceipt
            12, // CancelTransaction
        ]
    },

    // Additional actions not in table in the doc but mentioned in "Action ID Messages" section.

    31: {
        // statusName: "Card Data Retrieval",
        actions: [
            12, // CancelTransaction
        ]
    },

    36: {
        // statusName: "Expiry Date Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    37: {
        // statusName: "Start Date Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    38: {
        // statusName: "Issue Number Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    39: {
        // statusName: "AVS House Number Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    40: {
        // statusName: "AVS Post Code Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    41: {
        // statusName: "CSC Required",
        actions: [
            12, // CancelTransaction
        ]
    },

    35: {
        // statusName: "Gratuity Selection Required", // Doc just says "Selection Required". Can only assume it's this.
        actions: [
            12, // CancelTransaction
        ]
    },

    44: {
        // statusName: "Enter Charge Auth Code",
        actions: [
            12, // CancelTransaction
        ]
    },

    8: {
        // statusName: "Change Card",
        actions: [
            12, // CancelTransaction
        ]
    },

    50: {
        // statusName: "Invalid Expiry",
        actions: [
            12, // CancelTransaction
        ]
    },

    /**
     * This "Confirm Auth Code" message is received for Cardholder not present transactions.
     * Not sure what it's for. But pressing confirm makes it work...
     */
    20: {
        // statusName: "Confirm Auth Code",
        actions: [
            20, // ConfirmAuthCode
            21, // RejectAuthCode
        ]
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneActionsForStatuses;
}
