const { cleanCartLine } = require('./cartLineCleaning');
const { cloneShallow } = require('../../../functions/clone');

/**
 * The opposite of CartLineSplitter.splitDiscounts()
 * It checks for lines in the cart that have been split up because of quantities of offers/discounts
 * on the line and merges them back together.
 *
 * This modifies the passed in cart.
 *
 * @param {Cart} cart
 */
const mergeDiscountedCartLines = (cart) => {
    /**
     * @type {Object<DigiTickets.CartItem>}
     */
    let newItemList = {};

    /**
     * @type {{originalLineNumber: number, newLineNumber: number, newLineOriginalQuantity: number, addedQuantity: number}[]}
     */
    let mergedLines = [];

    cart.itemList.forEach(
        /**
         * @param {DigiTickets.CartItem} line
         */
        (line) => {
            let lineId = line.getId();

            if (newItemList.hasOwnProperty(lineId)) {
                // Remember the merged lines so we can move the field data.
                mergedLines.push({
                    originalLineNumber: line.lineNumber,
                    newLineNumber: newItemList[lineId].lineNumber,
                    newLineOriginalQuantity: newItemList[lineId].quantity,
                    addedQuantity: line.quantity
                });

                // Add this qty to the original line.
                newItemList[lineId].quantity += line.quantity;
                newItemList[lineId].quantityDisplay += line.quantityDisplay;
                newItemList[lineId].discounts.concat(line.discounts);

                // When adding the item instances we need to fix the numbers otherwise we will have duplicate numbers.
                // Renumber the ones being added, starting from the max instance already on the line.
                let maxInstanceOnLine = Math.max(...newItemList[lineId].itemInstances.map((ii) => ii.instance));
                line.itemInstances.forEach((ii, index) => {
                    ii.instance = maxInstanceOnLine + 1 + index;
                });

                newItemList[lineId].itemInstances.push(...line.itemInstances);
            } else {
                newItemList[lineId] = line;
            }
        }
    );

    // TODO: The below field data fixing can be removed when we ditch the old field data.

    // We need to fix field data that belonged to the originally separated lines.
    // The format of the keys is "line.~[lineNumber].~[itemInstance].~[fieldId]
    // Where lineNumber starts at 1 and itemInstance starts at 0
    let newFieldData = cloneShallow(cart.fieldData);
    let originalFieldDataKeys = Object.keys(cart.fieldData);

    mergedLines.forEach((mergedLineData) => {
        // Because item instances begin at 0 the next item instance number will be the same number
        // as the current quantity (which begins at 1)
        let nextInstanceNumber = mergedLineData.newLineOriginalQuantity;
        for (let i = 0; i < mergedLineData.addedQuantity; i++) {
            // To move the field data we can do string replacement on the keys in the fieldData object.
            // Where line 2 (qty 1) has been merged with line 1 (qty 1) we need to change
            // line.~2.~0.~fieldId
            // to
            // line.~1.~1.~fieldId
            let originalKeyPrefix = `line.~${mergedLineData.originalLineNumber}.~${i}.~`;
            let newKeyPrefix = `line.~${mergedLineData.newLineNumber}.~${nextInstanceNumber}.~`;
            originalFieldDataKeys.forEach((oldKey) => {
                if (oldKey.indexOf(originalKeyPrefix) === 0) {
                    let newKey = oldKey.replace(originalKeyPrefix, newKeyPrefix);
                    newFieldData[newKey] = newFieldData[oldKey];
                    delete newFieldData[oldKey];
                }
            });
            ++nextInstanceNumber;
        }
    });
    cart.fieldData = newFieldData;

    cart.itemList = Object.values(newItemList);
    cart.itemList.forEach(cleanCartLine);
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = mergeDiscountedCartLines;
}
