const CartItemGroup = require('../../../models/CartItemGroup');

/**
 * @param {DigiTickets.CartItem[]} items
 *
 * @return {CartItemGroup[]}
 */
const createCartItemGroupsFromItems = (items = []) => {
    /**
     * @type {CartItemGroup[]}
     */
    let groups = [];

    // Create the default group now, but don't add it to the result yet so it can be at the bottom.
    let defaultGroup = new CartItemGroup('default', 'default');

    items.forEach((item) => {
        if (item.session) {
            let key = 'session-' + item.session.ID;

            let group = groups.find((g) => g.key === key);

            if (!group) {
                group = new CartItemGroup(key, 'session');
                group.setEvent(item.getEvent());
                group.setSession(item.session);
                groups.push(group);
            }

            group.addItem(item);
        } else {
            defaultGroup.addItem(item);
        }
    });

    if (defaultGroup.items.length > 0) {
        // Add default group at the end.
        groups.push(defaultGroup);
    }

    return groups;
};

/**
 * Calculate the total number of spaces of all the tickets in a session group.
 *
 * @param {CartItemGroup} cartItemGroup
 *
 * @return {Object<number>}
 */
const countSessionSpacesInGroup = (cartItemGroup) => {
    let totalSessionSpacesInGroup = {};

    cartItemGroup.items.forEach((i) => {
        if (!i.session) {
            return;
        }
        if (!totalSessionSpacesInGroup.hasOwnProperty(i.session.ID)) {
            totalSessionSpacesInGroup[i.session.ID] = 0;
        }
        totalSessionSpacesInGroup[i.session.ID] += i.item.getPeople() * i.quantity;
    });

    return totalSessionSpacesInGroup;
};

/**
 * Returns an array of objects in the format that can be passed to SessionPickerCtrl representing the quantity
 * of tickets requested in a CartItemGroup.
 *
 * @param {CartItemGroup} cartItemGroup
 *
 * @return {{ticket: DigiTickets.Ticket, qty: Number}[]}
 */
const getTicketQtysInGroup = (cartItemGroup) => cartItemGroup.items.map((i) => ({
    ticket: i.item,
    qty: i.quantity,
    lineNumber: i.lineNumber
}));

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        getTicketQtysInGroup,
        countSessionSpacesInGroup,
        createCartItemGroupsFromItems
    };
}
