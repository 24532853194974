/**
 * @param $scope
 * @param $filter
 * @param $modalInstance
 * @param {DigiTickets.Membership[]} memberships
 * @param {MembershipService} membershipService
 */
const MembershipCancellationModalCtrl = function MembershipCancellationModalCtrl(
    $scope,
    $filter,
    $modalInstance,
    memberships,
    membershipService
) {
    $scope.refundTypes = Object.values(DigiTickets.MembershipRefundType);

    /**
     * @type {DigiTickets.Membership[]}
     */
    $scope.memberships = memberships;

    $scope.loading = true;
    membershipService.calculateMembershipRefunds(
        $scope.memberships,
        function () {
            $scope.loading = false;
            $scope.calculate();
        }
    );

    $scope.totalRefund = 0.00;
    $scope.selectedRefundTypes = {};

    $scope.calculate = function calculate() {
        let total = 0.00;
        for (let i = 0; i < $scope.memberships.length; i++) {
            let membership = $scope.memberships[i];
            if (membership.refundAmount) {
                let selectedType = $scope.getSelectedRefundType(membership);
                if (selectedType) {
                    total += membership.refundAmount[selectedType + 'Total'];
                }
            }
        }
        $scope.totalRefund = total;
    };

    $scope.selectRefundType = function selectRefundType(membership, type) {
        $scope.selectedRefundTypes[membership.ID] = type;
        $scope.calculate();
    };

    $scope.getSelectedRefundType = function getSelectedRefundType(membership) {
        return $scope.selectedRefundTypes.hasOwnProperty(membership.ID) ? $scope.selectedRefundTypes[membership.ID] : null;
    };

    $scope.ok = function ok() {
        let refundCartItems = membershipService.generateMembershipRefundCartItems(
            $scope.memberships,
            $scope.selectedRefundTypes,
            null
        );

        $modalInstance.close({
            memberships,
            refundCartItems
        });
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    for (let i = 0; i < $scope.memberships.length; i++) {
        // Select 'none' refund type by default
        $scope.selectRefundType(memberships[i], DigiTickets.MembershipRefundType.NONE);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = MembershipCancellationModalCtrl;
}
