const UserEvents = Object.freeze({
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT',
    BRANCH_CHANGED: 'BRANCH_CHANGED'
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserEvents;
}
