const orderAddressFilter = function (address) {
    if (typeof address !== 'object') {
        return '';
    }

    function addLine(lineValue) {
        return lineValue ? lineValue + '\n' : '';
    }

    return addLine(address.house)
        + addLine(address.street)
        + addLine(address.town)
        + addLine(address.county)
        + addLine(address.state)
        + addLine(address.postcode)
        + addLine(address.country ? address.country.name : '');
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = orderAddressFilter;
}
