const SavedAddressResource = function ($resource, ApiRequestParams, AppConfig) {
    return $resource(
        AppConfig.apiBaseUrl + 'savedaddresses/',
        ApiRequestParams.defaults(),
        {
            create: {
                isArray: true,
                method: 'POST'
            },
            update: {
                method: 'PUT',
                url: AppConfig.apiBaseUrl + 'savedaddresses/:savedAddressID'
            },
            delete: {
                method: 'DELETE',
                params: {
                    useAddressID: true
                },
                url: AppConfig.apiBaseUrl + 'savedaddresses/:addressID'
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SavedAddressResource;
}
