const ConfirmModalCtrl = require('../../controllers/modal/Dialogs/ConfirmModalCtrl');
const PromptModalCtrl = require('../../controllers/modal/Dialogs/PromptModalCtrl');
const NumPadModalCtrl = require('../../controllers/modal/Dialogs/NumPadModalCtrl');

/**
 * Provides methods to display generic re-usable "dialogs". Some replace the browser's built in functionality
 * with more style (confirm, prompt). Some add additional abilities (numPad).
 *
 * @param $modal
 */
const DialogService = function ($modal) {
    this.$modal = $modal;
};

DialogService.prototype = {
    /**
     * Show an alert dialog, which is really a confirm dialog with only one button.
     *
     * @param {string} [message]
     * @param {function} [callback]
     * @param {objects} [options]
     */
    alert: function (
        message,
        callback,
        options
    ) {
        let opts = Object.assign(
            {
                cancelLabel: '',
                okBtnClass: 'btn-default',
                okLabel: 'OK'
            },
            options
        );
        return this.confirm(
            message,
            callback,
            opts
        );
    },

    /**
     * Method to replace native js confirm() and way to prevent (in Chrome) user to disable the confirm() popup
     * 'yes' and 'no' button text can be customized, but default to 'Yes' and 'No'.
     *
     * @param {string} message Text to display before the input in the modal.
     *                         Can be a language key, or HTML if the 'rawMessage' option is set.
     * @param {function} callback Callback receives true or false when submitted.
     * @param {object} [options] Object of options to pass to the modal.
     */
    confirm: function confirm(
        message,
        callback,
        options
    ) {
        let modal = this.$modal.open({
            templateUrl: 'partials/modals/dialogs/confirmModal.html',
            controller: ConfirmModalCtrl,
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in confirm-window',
            resolve: {
                message: function () {
                    return message !== undefined ? message : null;
                },
                options: function () {
                    return options !== undefined ? options : {};
                }
            }
        });

        if (typeof callback !== 'undefined') {
            modal.result.then(
                function (data) {
                    // Confirmed
                    callback(true, data);
                },
                function () {
                    // Not confirmed
                    callback(false);
                }
            );
        }

        return modal;
    },

    /**
     * @param {string} message Text to display before the input in the modal.
     *                         Can be a language key, or HTML if the 'rawMessage' option is set.
     * @param {function} callback Callback receives the entered value when submitted, null if cancelled.
     * @param {object} [options] Object of options to pass to the modal.
     */
    prompt: function prompt(
        message,
        callback,
        options
    ) {
        this.$modal.open({
            templateUrl: 'partials/modals/dialogs/promptModal.html',
            controller: PromptModalCtrl,
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in prompt-window',
            resolve: {
                message: function () {
                    return message !== undefined ? message : null;
                },
                options: function () {
                    return options !== undefined ? options : {};
                }
            }
        }).result.then(
            function (result) {
                // Confirmed
                callback(result.value);
            },
            function () {
                // Cancelled
                callback(null);
            }
        );
    },

    /**
     * @param {object} numPadOptions Options to be passed to the NumPad instance.
     *                               (See DigiTickets.NumPad model. For control of the display of the modal
     *                               use the options property instead.)
     * @param {string} message Text to display before the num pad in the modal.
     *                         Can be a language key, or HTML if the 'rawMessage' option is set.
     * @param {function} callback Callback receives the entered value when submitted, null if cancelled.
     * @param {object} [options] Object of options to pass to the modal.
     */
    numPad: function numPad(
        numPadOptions,
        message,
        callback,
        options
    ) {
        this.$modal.open({
            templateUrl: 'partials/modals/dialogs/numPadModal.html',
            controller: NumPadModalCtrl,
            size: 'sm',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in num-pad-window',
            resolve: {
                message: function () {
                    return message !== undefined ? message : null;
                },
                numPadOptions: function () {
                    return numPadOptions !== undefined ? numPadOptions : {};
                },
                options: function () {
                    return options !== undefined ? options : null;
                }
            }
        }).result.then(
            function (result) {
                // Confirmed
                callback(result.value);
            },
            function () {
                // Cancelled
                callback(null);
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DialogService;
}
