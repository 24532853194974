const objectHash = require('object-hash');

/**
 * Generate a hash for a KitchenOrderItem that uniquely represents this item for kitchen purposes.
 * This takes into account the itemID and field data only.
 *
 * @param {KitchenOrderItem} kitchenOrderItem
 *
 * @return {string}
 */
const kitchenOrderItemHash = (kitchenOrderItem) => objectHash(
    {
        itemID: kitchenOrderItem.itemID,
        fieldData: kitchenOrderItem.fieldData
    },
    {
        // Sort arrays so the order does not change the hash.
        unorderedArrays: true,

        // Ignore the $$hashKey key that angular may add.
        excludeKeys: (k) => k === '$$hashKey'
    }
);

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        kitchenOrderItemHash
    };
}
