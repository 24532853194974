const io = require('socket.io-client');
const logFactory = require('./Logging/logFactory');
const TcpSocketConnection = require('./TcpSocketConnection');
const { timeoutPromise } = require('../../functions/functions');

/**
 * Handles communication with the "ProPoint Agent".
 *
 * The ProPoint Agent (formerly "socket proxy") is a standalone Node server running on the client's machine
 * (accessible at 127.0.0.1:8443 via a WebSocket).
 * It allows ProPoint to access resources it otherwise would be unable to due to running in a browser.
 * The Agent currently supports:
 * - Proxying connections to TCP sockets (for connecting to payment terminals)
 * - Writing to the filesystem.
 * - Printing
 *
 * We create a single WebSocket connection to the Agent on startup if necessary for the client's configuration.
 *
 * @param {LangService} LangService
 * @param socketFactory angular-socket-io factory.
 * @param {ToastFactory} toastFactory
 */
const AgentService = function (
    LangService,
    socketFactory,
    toastFactory
) {
    /**
     * @type {LangService}
     */
    this.langService = LangService;

    /**
     * @type {ToastFactory}
     */
    this.toastFactory = toastFactory;

    /**
     * @type {ConsoleLogger}
     */
    this.log = logFactory('Agent', true);

    /**
     * The configuration options for the agent connection and their default values.
     *
     * These were previously stored as part of the payment method config (for Verifone or WorldPay).
     * They are hard coded here for the time being so they are separated from payment method configuration, as the
     * agent now does more than just connecting to payment terminals, and no clients have had a need to
     * override these default values.
     *
     * TODO: Add ability to override these settings per device in the back office. The only one that may need changing
     * is proxyRestartHour.
     */
    this.config = {
        /**
         * IP / hostname of the host the agent is running on.
         * localhost.dtapps.co.uk points to 127.0.0.1
         *
         * @type {string}
         */
        proxyHost: 'localhost.dtapps.co.uk',

        /**
         * Port the agent is listening on.
         *
         * @type {number}
         */
        proxyPort: 8443,

        /**
         * This value is sent to the agent to tell it what hour is okay to automatically restart so it can
         * update to the latest version of the code.
         * It will only restart if it is during this hour, it hasn't started within the last 12 hours,
         * and if there hasn't been any data sent through it in the last 10 minutes.
         *
         * @type {number}
         */
        proxyRestartHour: 1,

        /**
         * Use a secure connection to the ProPoint agent?
         * true = https, false = http
         *
         * @type {boolean}
         */
        secureProxy: true,

        /**
         * The encoding to be used on the TCP socket between the agent and payment terminals.
         * Using latin1 fixes encoding the £ symbol.
         *
         * @type {string}
         */
        socketEncoding: 'latin1',

        /**
         * Enable extra console logs?
         *
         * @type {boolean}
         */
        verbose: true
    };

    /**
     * angular-socket-io factory.
     */
    this.socketFactory = socketFactory;

    /**
     * @type {null}
     */
    this.webSocket = null;

    /**
     * @type {Object<TcpSocketConnection>}
     */
    this.tcpSockets = {};

    /**
     * Callback when the WebSocket begins connecting.
     *
     * @type {?Function}
     */
    this.onConnecting = null;

    /**
     * Callback when the WebSocket is connected.
     *
     * @type {?Function}
     */
    this.onConnected = null;

    /**
     * Callback when the WebSocket connection is closed.
     *
     * @type {?Function}
     */
    this.onClose = null;

    /**
     * Callback when an error message is received from the WebSocket.
     *
     * @type {?Function}
     */
    this.onError = null;

    /**
     * Info about the agent server (version etc.) received when first connecting.
     *
     * @type {?{agentVersion: string, agentStartedAt: Date}}
     */
    this.serverInfo = null;

    /**
     * Is a WebSocket connection to the agent established?
     * true = connected
     * false = not connected
     * null = in progress
     *
     * @type {?boolean}
     */
    this.connected = false;

    /**
     * If we're in the process of connecting to the agent this will be a promise.
     *
     * @type {Promise}
     */
    this.connectingPromise = null;

    /**
     * If true this will display a "failed to connect to agent" error when connection fails.
     * This should only be enabled if the agent is needed.
     *
     * @private
     * @type {boolean}
     */
    this.displayConnectionErrorToUser = false;

    /**
     * @type {number}
     */
    this.connectAttempts = 0;
};

AgentService.prototype = {
    /**
     * @param {boolean} displayConnectionErrorToUser
     * @return {Promise}
     */
    connectToAgent(displayConnectionErrorToUser = false) {
        // Enable displayConnectionErrorToUser if it was already enabled or should be enabled now.
        // It should never be disabled once enabled.
        this.displayConnectionErrorToUser = (this.displayConnectionErrorToUser || displayConnectionErrorToUser);

        if (this.connected) {
            this.log.debug('Already connected to Agent.');
            return Promise.resolve();
        }

        if (this.connectingPromise) {
            this.log.debug('Agent connection already in progress.');
            return this.connectingPromise;
        }

        this.connectingPromise = this.connectToAgentReal();
        ++this.connectAttempts;

        // Loading spinner is conditionally displayed.
        if (this.displayConnectionErrorToUser) {
            this.toastFactory.spinner(
                '',
                this.langService.getText('AGENT.TOAST_CONNECTING'),
                this.connectingPromise
            );
        }

        this.connectingPromise
            .then(() => {
                // Success is always displayed.
                this.toastFactory.success(
                    '',
                    this.langService.getText('AGENT.TOAST_CONNECTED')
                );
                this.connectAttempts = 0;
            })
            .catch((error) => {
                // This needs to wait a short while before showing because there might be a previous error message
                // that is still fading out.
                timeoutPromise(1000).then(() => {
                    console.log('this.connectAttempts', this.connectAttempts);
                    if (this.displayConnectionErrorToUser && this.connectAttempts < 5) {
                        this.toastFactory.error(
                            'AGENT.AGENT_ERROR',
                            this.langService.getText('AGENT.TOAST_CONNECTION_FAILED') + ' ' + error.toString(),
                            {
                                tapToDismiss: false,
                                buttonText: 'Retry',
                                onclick: () => {
                                    this.connectToAgent();
                                }
                            }
                        );
                    }
                });
            })
            .finally(() => {
                this.connectingPromise = null;
            });

        return this.connectingPromise;
    },

    /**
     * Establish a WebSocket connection to the proxy server.
     * This should not be called directly. Use the connectToAgent method instead, which is debounced.
     *
     * @return {Promise<any>}
     * @private
     */
    connectToAgentReal() {
        this.log.debug('Connecting to Agent');
        return new Promise((resolve, reject) => {
            const proxyScheme = this.config.secureProxy ? 'https' : 'http';
            const proxyUrl = proxyScheme + '://' + this.config.proxyHost + ':' + this.config.proxyPort;

            let ioManager = new io.Manager(
                proxyUrl,
                {
                    transports: ['websocket'],
                    reconnection: false,
                    reconnectionAttempts: 1
                }
            );

            let ioSocket = ioManager.socket('/');
            let webSocket = this.socketFactory(
                {
                    ioSocket
                }
            );
            this.connected = null;
            if (typeof this.onConnecting === 'function') {
                this.onConnecting();
            }
            this.webSocket = webSocket;

            // Failed to connect to the WebSocket.
            webSocket.on(
                'connect_error',
                (error) => {
                    this.log.error('Websocket connect_error', error);
                    reject(error);

                    if (typeof this.onError === 'function') {
                        this.onError(error.toString());
                    }
                    this.connected = false;
                    if (typeof this.onClose === 'function') {
                        this.onClose();
                    }
                }
            );

            // There was an error with the WebSocket.
            webSocket.on(
                'error',
                (error) => {
                    this.log.error('Websocket error', error);
                    if (typeof this.onError === 'function') {
                        this.onError(error.toString());
                    }
                }
            );

            // Setup listeners for data from the proxy server...

            // The agent server sent back some info about itself.
            webSocket.on(
                'serverInfo',
                (data) => {
                    this.log.debug('Server info received.', data);
                    this.serverInfo = data;

                    if (data && data.hasOwnProperty('agentStartedAt')) {
                        this.serverInfo.agentStartedAt = new Date(data.agentStartedAt);
                    }
                }
            );

            // Listen for incoming data.
            // Send it to the appropriate TCP socket object.
            webSocket.on(
                'tcpSocketData',
                (data) => {
                    this.log.debug('TCP socket data received.', data);
                    if (data && data.socketName) {
                        if (this.tcpSockets.hasOwnProperty(data.socketName)) {
                            this.tcpSockets[data.socketName]._didReceiveData(data);
                        }
                    }
                }
            );

            // Handle (the proxy server telling us about) TCP socket errors.
            // Send it to the appropriate TCP socket object.
            webSocket.on(
                'tcpSocketError',
                (data) => {
                    this.log.error('TCP socket error.', data);
                    if (data && data.socketName) {
                        if (this.tcpSockets.hasOwnProperty(data.socketName)) {
                            this.tcpSockets[data.socketName]._didError(data);
                        }
                    }
                }
            );

            // Handle (the proxy server telling us about) TCP socket disconnections.
            // Tell the appropriate TCP socket object.
            webSocket.on(
                'tcpSocketClosed',
                (data) => {
                    this.log.debug('TCP socket closed.', data);
                    if (data && data.socketName) {
                        if (this.tcpSockets.hasOwnProperty(data.socketName)) {
                            this.tcpSockets[data.socketName]._didClose();
                        }
                    }
                }
            );

            // Handle WebSocket disconnection.
            webSocket.on(
                'disconnect',
                () => {
                    this.connected = false;

                    this.toastFactory.error(
                        'AGENT.AGENT_ERROR',
                        this.langService.getText('AGENT.TOAST_DISCONNECTED')
                    );

                    // Tell all of the TCP sockets they are disconnected.
                    for (let socketName in this.tcpSockets) {
                        if (this.tcpSockets.hasOwnProperty(socketName)) {
                            this.tcpSockets[socketName]._didClose();
                        }
                    }
                    if (typeof this.onClose === 'function') {
                        this.onClose();
                    }
                }
            );

            // Handle WebSocket connection.
            webSocket.on(
                'connect',
                (response) => {
                    this.log.debug('Websocket connected.', response);
                    // Configure the proxy server.
                    webSocket.emit(
                        'configure',
                        {
                            restartHour: this.config.proxyRestartHour
                        }
                    );
                    this.connected = true;
                    if (typeof this.onConnected === 'function') {
                        this.onConnected();
                    }
                    resolve();
                }
            );
        });
    },

    /**
     * @param name
     * @param host
     * @param port
     *
     * @return {TcpSocketConnection}
     */
    createTcpSocket(name, host, port) {
        let tcpSocket = new TcpSocketConnection(
            name,
            host,
            port,
            this
        );
        this.tcpSockets[tcpSocket.name] = tcpSocket;

        return tcpSocket;
    },

    /**
     * Tell the proxy server to connect to the specified TCP socket.
     * This should not be called directly. Call connect() on a TCP Socket object instead.
     *
     * @param {TcpSocketConnection} socket
     *
     * @return {Promise<any>}
     */
    _connectTcpSocket(socket) {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.log.debug('Connecting to TCP socket', socket.host + ':' + socket.port);

                socket._isConnecting();

                // Send a message over the webSocket telling the agent to connect to a TCP socket and keep the
                // connection alive.
                // Note this can't be made to use a promise because the .emit method belongs to the angular-socket-io
                // package not our own code.
                this.webSocket.emit(
                    'connectTcpSocket',
                    {
                        socketName: socket.name,
                        host: socket.host,
                        port: socket.port,
                        encoding: this.config.socketEncoding
                    },
                    (response) => {
                        this.log.debug('TCP socket connect response.', response);
                        if (response.connected) {
                            socket._didConnect();
                            resolve(response);
                        } else {
                            socket._didError({ error: response.error });
                            reject(response);
                        }
                    }
                );
            }))
            .catch(() => {
                throw new Error('ProPoint Agent is not connected.');
            });
    },

    /**
     * Tell the proxy server to send data to the specified TCP socket.
     * This should not be called directly. Call sendData() on a TCP Socket object instead.
     *
     * @param {TcpSocketConnection} socket
     * @param {string} dataString
     * @param {function} callback
     */
    _sendData(socket, dataString, callback) {
        this.log.debug('Sending TCP socket data.', socket.host + ':' + socket.port, dataString);

        this.webSocket.emit(
            'sendTcpData',
            {
                socketName: socket.name,
                data: dataString
            },
            function (response) {
                if (typeof callback === 'function') {
                    callback(response);
                }
            }
        );
    },

    /**
     * Determine if the company needs to use the Agent.
     *
     * @param {PaymentMethod[]} paymentMethods
     *
     * @return {boolean}
     */
    companyNeedsAgent(paymentMethods) {
        const requiredForPaymentMethods = [
            'card-verifone-chip-and-pin',
            'card-worldpay-chip-and-pin',
        ];

        return paymentMethods.filter(
            /** @type {PaymentMethod} */
            (pm) => requiredForPaymentMethods.indexOf(pm.ref) !== -1
        ).length > 0;
    },

    /**
     * @param {string} html
     * @param {?string} [printerName]
     *
     * @return {Promise<any>}
     */
    print(html, printerName) {
        return this.connectToAgent()
            .then(
                () => new Promise((resolve, reject) => {
                    this.webSocket.emit(
                        'print',
                        {
                            html,
                            printerName
                        },
                        (response) => {
                            if (response.success) {
                                resolve();
                            } else {
                                reject(response.error);
                            }
                        }
                    );
                })
            );
    },

    /**
     * @return {Promise<string[]>}
     */
    getPrinterNames() {
        return this.connectToAgent()
            .then(
                () => new Promise((resolve, reject) => {
                    this.webSocket.emit(
                        'getPrinters',
                        {},
                        (response) => {
                            if (response.hasOwnProperty('printers')) {
                                resolve(response.printers);
                            } else {
                                reject(response.error);
                            }
                        }
                    );
                })
            );
    },

    /**
     * @return {Promise<string[]>}
     */
    getServerInfo() {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.webSocket.emit(
                    'getServerInfo',
                    {},
                    (response) => {
                        if (response) {
                            resolve(response);
                        } else {
                            reject();
                        }
                    }
                );
            }));
    },

    /**
     * Read the contents of an arbitrary file on disk.
     *
     * @param {string} path
     *
     * @return {Promise<string>}
     */
    readFile(path) {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.webSocket.emit(
                    'readFile',
                    {
                        path
                    },
                    (response) => {
                        if (response.data) {
                            resolve(response.data);
                        } else {
                            reject(response.error);
                        }
                    }
                );
            }));
    },

    /**
     * Read the contents of a file from the propoint data directory.
     *
     * @param {string} path
     *
     * @return {Promise<string>}
     */
    readFileFromDataDir(path) {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.webSocket.emit(
                    'readFileFromDataDir',
                    {
                        path
                    },
                    (response) => {
                        if (response.data) {
                            resolve(response.data);
                        } else {
                            reject(response.error);
                        }
                    }
                );
            }));
    },

    /**
     * Store a file in an arbitrary location on disk.
     *
     * @param {string} path
     * @param {string} data
     *
     * @return {Promise<boolean>}
     */
    writeFile(path, data) {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.webSocket.emit(
                    'writeFile',
                    {
                        path,
                        data
                    },
                    (response) => {
                        if (response.success) {
                            resolve(true);
                        } else {
                            reject(response.error);
                        }
                    }
                );
            }));
    },

    /**
     * Store a file in the propoint data directory.
     *
     * @param {string} path
     * @param {string} data
     *
     * @return {Promise<boolean>}
     */
    writeFileToDataDir(path, data) {
        return this.connectToAgent()
            .then(() => new Promise((resolve, reject) => {
                this.webSocket.emit(
                    'writeFileToDataDir',
                    {
                        path,
                        data
                    },
                    (response) => {
                        if (response.success) {
                            resolve(true);
                        } else {
                            reject(response.error);
                        }
                    }
                );
            }));
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AgentService;
}
