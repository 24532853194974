const ApiResponseUnmapper = require('../libraries/DigiTickets/Api/ApiResponseUnmapper');

const ProductResource = function ($resource, ApiRequestParams, AppConfig, ProductCache) {
    return $resource(
        AppConfig.apiBaseUrl + 'products/',
        Object.assign(
            ApiRequestParams.defaults(),
            {
                channelID: AppConfig.channelID
            }
        ), {
            query: {
                cache: ProductCache,
                isArray: true,
                params: Object.assign(
                    {
                        resolve: 'categories',
                        excludeFields: ApiRequestParams.defaultExcludeFields()
                    },
                    ApiRequestParams.defaultMappers()
                ),
                transformResponse: (json) => ApiResponseUnmapper.transformApiResponse(json, 'Product')
            }
        }
    );
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ProductResource;
}
