const AgentInfoDirective = () => ({
    restrict: 'E',
    scope: {},
    templateUrl: 'partials/directives/dt-agent-info.html',
    controller: function (
        $injector,
        $scope,
        AgentService
    ) {
        $scope.agentService = AgentService;

        try {
            $scope.verifoneDriver = $injector.get('VerifoneDriverService');
        } catch (e) {
            // Throws an exception if Verifone is not enabled. That's fine. Skip it and move on.
        }

        try {
            $scope.worldpayDriver = $injector.get('WorldpayDriverService');
        } catch (e) {
            // Throws an exception if Worldpay is not enabled. That's fine. Skip it and move on.
        }
    }
});

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AgentInfoDirective;
}
