const { cloneShallow } = require('../../functions/clone');

const AbstractSearchCache = function () {
    /**
     * @type {?Array}
     */
    this.results = null;

    this.pagination = {
        currentPage: 1,
        pageSize: 5
    };

    /**
     * Data for the search form, entered by the user.
     *
     * @type {{}}
     */
    this.form = {};

    /**
     * Default values for data in the search form.
     *
     * @type {{}}
     */
    this.formDefaults = {};
};

AbstractSearchCache.prototype = {
    /**
     * Reset the cache and clear the form.
     */
    clear() {
        this.pagination.currentPage = 1;
        this.results = null;

        // Reset the form data to default values.
        // This clones otherwise the formDefaults would be modified when typing in the form.
        this.form = cloneShallow(this.formDefaults);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AbstractSearchCache;
}
