const CartService = require('./Cart/CartService');
const moment = require('moment');
const { generateUUID } = require('../../functions/functions');

DigiTickets.CartStashHelper = function CartStashHelper(
    $injector,
    CurrentDevice,
    hydrator,
    UserService
) {
    this.$injector = $injector;

    /**
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;

    /**
     * @type {UserService}
     */
    this.userService = UserService;

    /**
     * @type {Hydrator}
     */
    this.hydrator = hydrator;
};

DigiTickets.CartStashHelper.prototype = {

    /**
     * Method to convert the cart to a format that can be stashed. It doesn't actually stash it.
     *
     * @param {CartService} cart
     *
     * @returns {DigiTickets.StashedCart}
     */
    cartToStash: function cartToStash(cart) {
        // Trying to cache the cart item list causes recursion, so we just extract
        // the bits we need as a standalone object (well, an array of objects).
        let fieldData = cart.getFieldData(false);

        let stashedCart = new DigiTickets.StashedCart();
        stashedCart.currentCart = cart.currentCart.toSerializable();
        stashedCart.deviceID = this.currentDevice.getDeviceId();
        stashedCart.fieldData = fieldData;
        stashedCart.fulfillmentCreatedAt = cart.fulfillmentCreatedAt;
        stashedCart.stashDatetime = new Date();
        stashedCart.thirdPartyID = cart.thirdPartyID; // TODO: Remove and just use thirdPartyRef
        stashedCart.thirdPartyRef = cart.thirdPartyRef;
        stashedCart.totalValue = cart.getTotalDue();
        stashedCart.userID = cart.user.ID;
        stashedCart.username = cart.user.username;

        // Generate a unique ID for the stashedCart TODO: Remove and just use thirdPartyRef
        if (cart.stashedCartGuid) {
            stashedCart.guid = cart.stashedCartGuid;
        } else {
            stashedCart.guid = generateUUID();
        }

        return stashedCart;
    },

    /**
     * @param {DigiTickets.StashedCart} stashedCart
     *
     * @return {Promise<DigiTickets.Order>}
     */
    stashToOrder: async function stashToOrder(stashedCart) {
        // We already have the ability to convert a StashedCart to a Cart, and a Cart to an Order, so do it that
        // way instead of inventing a way to do it directly.
        /** @type {CartService} */
        let cartService = this.$injector.instantiate(CartService);

        await cartService.populateFromStash(stashedCart);

        let order = cartService.toOrder();

        // Set the stash date as the order date
        order.date = stashedCart.stashDatetime;

        return order;
    },

    /**
     * Helper function to rearrange the data received for a stashed cart from the API.
     * It contains all the expected information, but some properties are extracted from the 'stashedCartData'
     * property.
     *
     * @param {object} data
     *
     * @returns {DigiTickets.StashedCart}
     */
    createStashedCartFromRemoteData: function createStashedCartFromRemoteData(data) {
        let stashedCart = new DigiTickets.StashedCart();

        // The data that comes from the API for a stashed cart includes:
        // branchID
        // createdAt
        // deletedAt
        // deviceID
        // fulfilledAt
        // fulfillmentCreatedAt
        // guid
        // stashedCartData - This is the actual payload for the cart contents.
        // stashedCartID
        // thirdPartyID
        // updatedAt
        // user
        // userID
        // username

        // First build an object with all the data at the same level.
        // FIXME: This is ugly, don't do this.
        let stashedCartData = Object.assign(data.stashedCartData, data);
        // Then apply that data to a StashedCart model.
        this.hydrator.hydrate(stashedCartData, stashedCart);

        return stashedCart;
    },

    /**
     * Method to build the string that says when the order was put on hold. The result
     * will be something like "Today at 15:17:10" or "10/11/2012 at 14:26:17".
     * It's been changed to use moment.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     *
     * @returns {string|null}
     */
    getStashedCartTime: function getStashedCartTime(stashedCart) {
        if (stashedCart.stashDatetime != undefined) {
            moment.relativeTimeThreshold('s', 60);
            moment.relativeTimeThreshold('m', 60);
            return moment(stashedCart.stashDatetime).fromNow();
        }

        return null;
    },

    /**
     * Method to return the user name for the given stashed cart for showing on screen.
     * If the cart was stashed by the logged in-user, it indicates that it was them that did it.
     *
     * @param {DigiTickets.StashedCart} stashedCart
     *
     * @returns {string}
     */
    getStashedCartUsername: function getStashedCartUsername(stashedCart) {
        let username = stashedCart.username;

        if (this.userService.getUsername() && username === this.userService.getUsername()) {
            username = 'You (' + username + ')';
        }

        return username;
    },

    /**
     * @param {DigiTickets.StashedCart} stashedCart
     */
    getStashedCartDeviceName: function getStashedCartDeviceName(stashedCart) {
        if (stashedCart.deviceID) {
            if (this.currentDevice.isSet() && stashedCart.deviceID === this.currentDevice.getDeviceId()) {
                return 'This Device';
            }
            return 'Another Device';
        }

        return '';
    },

    sortStashedCarts: function (stashedCarts) {
        let username = this.userService.getUsername();
        stashedCarts.sort(function (ele1, ele2) {
            // Sort by username first.
            if (ele1.username && ele2.username) {
                if (ele1.username === username && ele2.username !== username) {
                    return -1;
                }
                if (ele1.username !== username && ele2.username === username) {
                    return 1;
                }
            }
            // Usernames are the same, so sort by date/time stashed
            return (ele1.stashDatetime === ele2.stashDatetime ? 0
                : (ele1.stashDatetime < ele2.stashDatetime ? 1 : -1)
            );
        });
    }

};
