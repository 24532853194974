/**
 * @param $modalInstance
 * @param $scope
 * @param {ErrorPresenter} errorPresenter
 * @param {LangService} LangService
 * @param {DigiTickets.NumPadFactory} NumPadFactory
 * @param {boolean} showSignButton
 * @param {{}} templateText
 * @param {VariableDiscount} variableDiscount
 */
const VariableDiscountModalCtrl = function VariableDiscountModalCtrl(
    $modalInstance,
    $scope,
    errorPresenter,
    LangService,
    NumPadFactory,
    showSignButton,
    templateText,
    variableDiscount
) {
    templateText = templateText || {};
    $scope.templateText = {
        title: templateText.title || LangService.getText('VARIABLE_DISCOUNT.MODAL_TITLE'),
        confirm: templateText.confirm || LangService.getText('OK'),
        cancel: templateText.cancel || LangService.getText('CANCEL'),
        remove: templateText.remove || LangService.getText('VARIABLE_DISCOUNT.REMOVE_DISCOUNT'),
        amount: templateText.amount || LangService.getText('VARIABLE_DISCOUNT.AMOUNT'),
        narrative: templateText.narrative || LangService.getText('VARIABLE_DISCOUNT.NARRATIVE')
    };

    /**
     * We know this is an existing discount being edited if the amount or narrative is already set.
     *
     * @type {boolean}
     */
    $scope.isEdit = variableDiscount.amount !== 0 || variableDiscount.narrative !== '';

    /**
     * These are the properties that will be modified by the template.
     * We don't store these directly on the variableDiscount model because we want to call the setters which
     * run validation in the model.
     *
     * @type {{amount: number, narrative: string}}
     */
    $scope.data = {
        amount: variableDiscount.amount,
        narrative: variableDiscount.narrative
    };

    /**
     * Error message displayed to the user.
     *
     * @type {?string}
     */
    $scope.error = null;

    $scope.numPad = NumPadFactory.create(
        {
            allowNull: false,
            initialValue: $scope.data.amount,
            showDecimalButton: true,
            showSignButton: showSignButton || false,
            numDecimalPlaces: 2,
            showCashButtons: true,
            doNotCaptureKeysIn: ['textarea']
        },
        (value) => {
            $scope.data.amount = value.toFixed(2);
        }
    );

    $scope.ok = function ok() {
        $scope.error = null;

        try {
            variableDiscount.setAmount($scope.data.amount);
            variableDiscount.setNarrative($scope.data.narrative);
            $modalInstance.close('ok');
        } catch (error) {
            $scope.error = errorPresenter.getText(error);
            console.log(error);
        }
    };

    $scope.cancel = function cancel() {
        $modalInstance.dismiss('cancel');
    };

    $scope.remove = function remove() {
        $modalInstance.close('remove');
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VariableDiscountModalCtrl;
}
