/**
 * @param {CurrentDevice} CurrentDevice
 * @param {UserService} UserService
 */
const PaymentMethodService = function (
    CurrentDevice,
    UserService
) {
    this.currentDevice = CurrentDevice;
    this.userService = UserService;
};

PaymentMethodService.prototype = {
    /**
     * Return all available payment method for the current company / device.
     *
     * @return {PaymentMethod[]}
     */
    getAll() {
        if (!this.userService || !this.userService.company || !this.userService.company.companyPaymentMethods) {
            return [];
        }

        let paymentMethods = this.userService.company.companyPaymentMethods;

        if (this.currentDevice && this.currentDevice.device && this.currentDevice.device.disabledPaymentMethodIDs) {
            // Remove payment methods disabled for this device.
            paymentMethods = paymentMethods.filter(
                (pm) => this.currentDevice.device.disabledPaymentMethodIDs.indexOf(pm.ID) === -1
            );
        }

        return paymentMethods;
    },

    /**
     * @param {string} ref
     * @return {?PaymentMethod}
     */
    getByRef(ref) {
        return this.getAll().find((pm) => pm.ref === ref);
    },

    /**
     * @return {boolean}
     */
    refundMethodsAreAvailable() {
        return !!this.getAll().find((pm) => pm.supportsNegativePaymentInProPoint);
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PaymentMethodService;
}
