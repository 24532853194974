const angular = require('angular');
const is = require('../Is');
const { baseNEncode } = require('../../functions/functions');
const { isInt } = require('../../functions/functions');

/**
 * A KeyMapper.
 *
 * This class remaps data in the form of a result set (nested or otherwise) into a smaller set.
 *
 * e.g.
 *
 * Given a set of data : [{KeyOne:1,KeyTwo:2},{KeyOne:3,KeyTwo:4},{KeyOne:5,KeyTwo:6},{KeyOne:7,KeyTwo:8},{KeyOne:9,KeyTwo:10}]
 *
 * Mapping it will give : {map:{a:"KeyOne",b:"KeyTwo"},data:[{a:1,b:2},{a:3,b:4},{a:5,b:6},{a:7,b:8},{a:9,b:10}]}
 */
// IIFE not removed because it is wrapping some private variables.
DigiTickets.KeyMapper = (function () {
    /**
     * The characters to use for the base-n-encoding.
     *
     * @type {string}
     */
    let baseAlphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    /**
     * The key dictionary.
     *
     * @type {{}}
     */
    let dictionary = {};

    /**
     * Get the mapping for the key, or generate a new mapping if one does not already exist.
     *
     * @param key
     * @returns {*}
     * @private
     */
    function _getMappedKey(key) {
        if (!dictionary.hasOwnProperty(key)) {
            dictionary[key] = baseNEncode(1 + Object.keys(dictionary).length, baseAlphabet);
        }

        return dictionary[key];
    }

    /**
     * Recursive iterator for mapping the data.
     *
     * @param {[]} data
     * @private
     */
    function _map(data) {
        /**
         * Depending upon the type of data we are looking at, we need an array or an object to put the data into.
         */
        let mapped = is.anArray(data) ? [] : {};

        /**
         * Now we iterate each element of the data and process it accordingly.
         */
        angular.forEach(data, function (row, key) {
            /**
             * For non integer keys, get the mapped key.
             */
            if (!isInt(key)) {
                key = _getMappedKey(key);
            }

            /**
             * If the row is an object then map it, otherwise store it.
             */
            mapped[key] = (!!row && typeof row === 'object') ? _map(row) : row;
        });

        return mapped;
    }

    /**
     * Recursive iterator for unmapping the data.
     *
     * @param {[]} data
     * @private
     */
    function _unmap(data) {
        /**
         * Depending upon the type of data we are looking at, we need an array or an object to put the data into.
         */
        let unmapped = is.anArray(data) ? [] : {};

        /**
         * Now we iterate each element of the data and process it accordingly.
         */
        angular.forEach(data, function (row, key) {
            /**
             * For non integer keys, get the mapped key.
             */
            if (!isInt(key)) {
                key = dictionary[key];
            }

            /**
             * If the row is an object then unmap it, otherwise store it.
             */
            unmapped[key] = (!!row && typeof row === 'object') ? _unmap(row) : row;
        });

        return unmapped;
    }

    return {
        /**
         * Maps the data.
         *
         * @param {[]|{}} data
         * @returns {{map: (*|Function|jQuery.map|Array), data: *}}
         */
        map: function map(data) {
            /**
             * Reset the key dictionary.
             */
            dictionary = {};

            /**
             * Map the data.
             */
            let mapped = _map(data);

            /**
             * Invert the key dictionary to allow direct access to the original key.
             */
            let newDictionary = {};
            angular.forEach(dictionary, function (value, key) {
                newDictionary[value] = key;
            });

            /**
             * Return the key dictionary and the mapped data.
             *
             * This will be the requirement for the unmapping process.
             */
            return {
                map: angular.extend(newDictionary, {}),
                data: mapped
            };
        },

        /**
         * Unmaps the data.
         *
         * @param {data:{}, map:{}} data
         * @returns {*}
         */
        unmap: function unmap(data) {
            /**
             * Copy the key dictionary to the context.
             */
            Object.assign(
                dictionary,
                data.map
            );

            /**
             * Return the unmapped data.
             */
            return _unmap(data.data);
        }
    };
}());
