/**
 * Should we enable GiftAid when creating the order?
 * True if GiftAid was desired (the flag on the cart is true) and we have enough customer info.
 *
 * @param {Cart} cart
 * @return {boolean}
 */
const shouldGiftAidCart = (cart) => cart.giftAid && cart.customer.hasGiftAidData();

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        shouldGiftAidCart
    };
}
