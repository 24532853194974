const BigNumber = require('bignumber.js');

const PriceAdjustmentCalculator = function () {

};

PriceAdjustmentCalculator.prototype = {

    /**
     * Calculate the price with the adjustment amount added.
     * Returns an object with both the new price and the difference. The 'difference' returned here is the
     * real difference between the originalPrice and the newPrice, which may be slightly different than the result
     * of a call to calculateAdjustmentAmount alone due to rounding.
     *
     * @param {GiftAidRate|TestAdjustment} adjustment
     * @param {number} originalPrice
     *
     * @return {{newPrice: Number, difference: Number}}
     */
    calculateAdjustedPrice: function calculateAdjustedPrice(adjustment, originalPrice) {
        let newPrice = new BigNumber(originalPrice);

        if (adjustment) {
            const adjustBy = new BigNumber(this.calculateAdjustmentAmount(adjustment, originalPrice));

            switch (adjustment.getAdjustmentType()) {
                case 'Value':
                    newPrice = newPrice.plus(adjustBy);
                    break;

                case 'Percentage':
                    newPrice = newPrice.plus(adjustBy);
                    newPrice = new BigNumber(this.round(adjustment, newPrice.toNumber()));
                    break;
            }

            // Stop price going below zero (this is not designed to be used for discounts).
            if (newPrice.isLessThan(0.00)) {
                newPrice = new BigNumber(0.00);
            }
        }

        /**
         * Calculate the actual difference after rounding has been done.
         *
         * @type {BigNumber}
         */
        let difference = newPrice.minus(new BigNumber(originalPrice));

        return {
            newPrice: newPrice.toNumber(),
            difference: difference.toNumber()
        };
    },

    /**
     * Calculate the amount the price needs to increase or decrease by (does not apply the increase/decrease).
     *
     * @param {GiftAidRate|TestAdjustment} adjustment
     * @param {number} originalPrice
     *
     * @return {number}
     */
    calculateAdjustmentAmount: function calculateAdjustmentAmount(adjustment, originalPrice) {
        if (adjustment) {
            switch (adjustment.getAdjustmentType()) {
                case 'Value':
                    return adjustment.getAdjustmentAmount();

                case 'Percentage': {
                    let original = new BigNumber(originalPrice);
                    let amount = original.dividedBy(100).multipliedBy(adjustment.getAdjustmentAmount());

                    let max = adjustment.getMaximumAdjustmentValue();
                    if (max && amount.isGreaterThan(0)) {
                        if (amount.isGreaterThan(max)) {
                            amount = new BigNumber(max);
                        }
                    } else if (max && amount.isLessThan(0)) {
                        if (amount.isLessThan(-max)) {
                            amount = new BigNumber(-max);
                        }
                    }

                    return amount.toNumber();
                }
            }
        }

        return 0;
    },

    /**
     * Return the rounded version of the given price using the rules of
     * the given adjustment (roundTo / roundDirection).
     *
     * @param {GiftAidRate|TestAdjustment} adjustment
     * @param {number} price
     *
     * @return {number}
     */
    round: function round(adjustment, price) {
        let roundDirection = adjustment.getAdjustmentRoundDirection();
        let roundTo = parseFloat(adjustment.getAdjustmentRoundTo());

        if (roundDirection === null || !roundTo) {
            return price;
        }

        let roundMultiplier = 100 / (roundTo * 100);

        let roundedPrice;
        if (roundDirection === 'Up') {
            roundedPrice = Math.ceil(price * roundMultiplier) / roundMultiplier;
        } else if (roundDirection === 'Down') {
            roundedPrice = Math.floor(price * roundMultiplier) / roundMultiplier;
        } else {
            // Auto rounding (up or down)
            roundedPrice = Math.round(price * roundMultiplier) / roundMultiplier;
        }

        return roundedPrice;
    }
};

PriceAdjustmentCalculator.AMOUNT_TYPE_VALUE = 'Value';
PriceAdjustmentCalculator.AMOUNT_TYPE_PERCENTAGE = 'Percentage';
PriceAdjustmentCalculator.ROUND_AUTO = 'Auto';
PriceAdjustmentCalculator.ROUND_UP = 'Up';
PriceAdjustmentCalculator.ROUND_DOWN = 'Down';

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = PriceAdjustmentCalculator;
}
