/**
 * The WINSTATE messages allows the system to control the status of the [POS Client] window.
 *
 * @param state
 */
let VerifoneWinStateRequestRecord = function (state) {
    /**
     * 0 Normal
     * 1 Minimised
     * 2 Maximised
     */
    this.state = state;
};

VerifoneWinStateRequestRecord.prototype = {
    toArray() {
        return [
            'WINSTATE',
            this.state,
        ];
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = VerifoneWinStateRequestRecord;
}
