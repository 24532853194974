const AccountOrderSearchCache = require('../../../js/libraries/DigiTickets/Orders/AccountOrderSearchCache');
const AddressModalCtrl = require('./modals/AddressModalCtrl');
const MembershipDatasetsModalCtrl = require('../../../js/controllers/modal/MembershipDatasetsModalCtrl');

/**
 * @param $filter
 * @param $modal
 * @param $routeParams
 * @param $scope
 * @param $timeout
 * @param {CustomerAccountService} CustomerAccountService
 * @param {DigiTickets.MembershipCancellationRunner} MembershipCancellationRunnerService
 * @param {DigiTickets.MembershipManagementActions} MembershipManagementActionsService
 * @param {MembershipService} membershipService
 * @param {ModalFactory} ModalFactory
 * @param {NavigationService} navigationService
 * @param {OrderManager} OrderManagerService
 * @param {DigiTickets.PrivilegesManager} PrivilegesService
 * @param {SavedAddressManager} SavedAddressManager
 * @param {SellStateService} sellStateService
 * @param {ToastFactory} toastFactory
 * @param {UserService} UserService
 */
const ManageAccountCtrl = function (
    $filter,
    $modal,
    $routeParams,
    $scope,
    $timeout,
    CustomerAccountService,
    MembershipCancellationRunnerService,
    MembershipManagementActionsService,
    membershipService,
    ModalFactory,
    navigationService,
    OrderManagerService,
    PrivilegesService,
    SavedAddressManager,
    sellStateService,
    toastFactory,
    UserService
) {
    navigationService.showNav();

    // Early exit if no access permission
    PrivilegesService.requirePrivilegeOr403('access_manage');

    $scope.navigationService = navigationService;

    // Pagination for contacts/orders/memberships lists
    $scope.pagination = {
        pageSize: 5,
        currentPage: 1,
        scrollElement: document.getElementsByTagName('html')[0]
    };

    /**
     * @type {DigiTickets.CustomerAccount|null}
     */
    $scope.account = null;

    $scope.selectedTab = 'memberships';
    $scope.showLoader = false;
    $scope.showNotFound = false;

    /**
     * Enable pagination on the orders for an account.
     *
     * @type {AccountOrderSearchCache}
     */
    $scope.orderSearchCache = new AccountOrderSearchCache();

    // Order lookup results
    $scope.order = {
        results: []
    };
    $scope.showOrdersLoader = false;

    $scope.activeMembershipCount = 0;
    $scope.inactiveMembershipCount = 0;
    /**
     * @type {DigiTickets.Membership[]}
     */
    $scope.selectedMemberships = [];

    /**
     * @type {boolean}
     */
    $scope.inMembershipEditMode = false;

    $scope.selectTab = function selectTab(tabName) {
        $scope.selectedTab = tabName;
    };

    $scope.reloadAccount = function reloadAccount() {
        loadAccount($scope.account.ID);
    };

    /**
     * @param {DigiTickets.Contact} contact
     */
    $scope.editContact = function editContact(contact) {
        window.alert('Edit contact ' + contact.contactID);
    };

    /**
     * @param {DigiTickets.Membership} membership
     * @param $event
     *
     * @return {boolean}
     */
    $scope.selectMembership = function selectMembership(membership, $event) {
        $event.stopPropagation();
        let currentIndex = $scope.selectedMemberships.indexOf(membership);
        if (currentIndex !== -1) {
            $scope.selectedMemberships.splice(currentIndex, 1);
            return false;
        }
        if (membership.isActive()) {
            $scope.selectedMemberships.push(membership);
        }
        if (!membership.isActive()) {
            membership.setHighlightStatus(true);
            setTimeout(function () {
                membership.setHighlightStatus(false);
            }, 500);
        }
        return true;
    };

    $scope.selectAllMemberships = function selectAllMemberships() {
        if ($scope.allMembershipsSelected()) {
            // Clear all selections
            $scope.clearSelectedMemberships();
        } else {
            $scope.clearSelectedMemberships();
            for (let i = 0; i < $scope.account.memberships.length; i++) {
                if ($scope.account.memberships[i].isActive()) {
                    $scope.selectedMemberships.push($scope.account.memberships[i]);
                }
            }
        }
    };

    $scope.countActiveMemberships = function countActiveMemberships() {
        return $scope.account.memberships.filter(function (membership) {
            return membership.isActive();
        }).length;
    };

    /**
     * @return {boolean}
     */
    $scope.allMembershipsSelected = function allMembershipsSelected() {
        return $scope.selectedMemberships.length === $scope.activeMembershipCount;
    };

    $scope.clearSelectedMemberships = function clearSelectedMemberships() {
        $scope.selectedMemberships = [];
    };

    $scope.isInMembershipEditMode = function isInEditMode() {
        return $scope.inMembershipEditMode;
    };

    $scope.toggleEditMode = function toggleEditMode() {
        if (!$scope.inMembershipEditMode) {
            $scope.inMembershipEditMode = true;
        } else {
            $scope.clearSelectedMemberships();
            $scope.inMembershipEditMode = false;
        }
    };

    /**
     * @param {DigiTickets.Membership} membership
     *
     * @return {boolean}
     */
    $scope.isMembershipSelected = function isMembershipSelected(membership) {
        return $scope.selectedMemberships.indexOf(membership) !== -1;
    };

    /**
     * @return {boolean}
     */
    $scope.hasSelectedMemberships = function hasSelectedMemberships() {
        return $scope.selectedMemberships.length > 0;
    };

    /**
     * @param {DigiTickets.Membership} membership
     * @param $event
     */
    $scope.viewOrSelectMembership = function viewOrSelectMembership(membership, $event) {
        if ($scope.isInMembershipEditMode()) {
            $scope.selectMembership(membership, $event);
        } else {
            navigationService.viewMembershipDetails(membership.ref);
        }
    };

    $scope.changeInstallments = function changeInstallments(membership) {
        MembershipManagementActionsService.changeInstallments(membership);
    };

    /**
     * @param {DigiTickets.Membership} membership
     * @param $event
     */
    $scope.amendAccountMembership = function amendAccountMembership(membership, $event) {
        $event.stopPropagation();
        let self = this;

        let membershipDataset = membership.toMembershipDataset();

        // Show the MembershipDatasetsModal with only this dataset, and with only
        // the members tab enabled.

        // This is the user adding/editing the item, so show the pop-up.
        let modalInstance = $modal.open({
            templateUrl: 'partials/modals/membershipDatasetsModal.html',
            controller: MembershipDatasetsModalCtrl,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'in membership-datasets-window',
            resolve: {
                action: function () {
                    return 'edit-existing';
                },
                membershipPlan: function () {
                    return membership.currentPlan;
                },
                currentMemberID: function () {
                    return null;
                },
                customerAccount: function () {
                    return self.account;
                },
                increaseQuantityBy: function () {
                    return 0;
                },
                membershipDatasets: function () {
                    return [membershipDataset];
                }
            }
        });

        modalInstance.result.then(
            function success(data) {
                let dataset = data.membershipDatasets[0];

                membershipService.updateMembership(
                    membership,
                    dataset,
                    function (newMembership) {
                        $scope.replaceMembership(membership, newMembership);
                    }
                );
            },
            function error() {
            }
        );
    };

    $scope.replaceMembership = function replaceMembership(oldMembership, newMembership) {
        let index = $scope.account.memberships.indexOf(oldMembership);
        if (index !== -1) {
            $scope.account.memberships[index] = newMembership;
        }
    };

    /**
     * @param {DigiTickets.Membership} membership
     */
    $scope.extendMembership = function extendMembership(membership) {
        alert('Extend membership ' + membership.ref);
    };

    let getSelectedMemberships = function getSelectedMemberships() {
        return $scope.selectedMemberships;
    };

    let getSelectedMembershipsString = function getSelectedMembershipsString() {
        return membershipService.generateMembershipRefsString(getSelectedMemberships());
    };

    $scope.getSelectedAutoRenewingMemberships = function getSelectedAutoRenewingMemberships() {
        return membershipService.extractAutoRenewingMemberships($scope.selectedMemberships);
    };

    $scope.renewMemberships = function renewMemberships() {
        alert('Renew memberships ' + getSelectedMembershipsString());
    };

    $scope.stopRenewingInProgress = false;
    $scope.stopRenewingMemberships = function stopRenewingMemberships() {
        $scope.stopRenewingInProgress = true;
        MembershipManagementActionsService.stopRenewingMemberships(
            getSelectedMemberships(),
            function (result) {
                $scope.stopRenewingInProgress = false;

                if (result === true) {
                    $scope.clearSelectedMemberships();
                }
            }
        );
    };

    $scope.cancelMemberships = function cancelMemberships() {
        MembershipManagementActionsService.cancelMemberships(
            getSelectedMemberships(),
            $scope.getCurrentAccountUrl(),
            $scope.account
        );
    };

    $scope.changeMembershipPlans = function changeMembershipPlans() {
        let memberships = getSelectedMemberships();
        if (memberships.length < 1) {
            return false;
        }

        // Calculate refunds
        membershipService.calculateMembershipRefunds(
            memberships,
            function (memberships) {
                let refundCartItems = membershipService.generateMembershipRefundCartItems(
                    memberships,
                    {},
                    DigiTickets.MembershipRefundType.UNUSED
                );

                let sellState = new DigiTickets.SellState();
                sellState.addInitialItems(refundCartItems);
                sellState.restrictItemTypes = [
                    DigiTickets.ItemType.MEMBERSHIP_PLAN,
                ];
                sellState.customerAccount = $scope.account;
                sellState.returnPath = $scope.getCurrentAccountUrl();
                sellStateService.sellWithState(sellState);
            }
        );
    };

    $scope.newMembership = function newMembership() {
        let sellState = new DigiTickets.SellState();
        sellState.restrictItemTypes = [
            DigiTickets.ItemType.MEMBERSHIP_PLAN,
        ];
        sellState.customerAccount = $scope.account;
        sellState.returnPath = $scope.getCurrentAccountUrl();
        sellStateService.sellWithState(sellState);
    };

    $scope.getCurrentAccountUrl = function getCurrentAccountUrl() {
        if ($scope.account) {
            return '/account/' + $scope.account.ID;
        }
    };

    /**
     * Show the modal to enter a new address.
     * Saves the address to the account when submitted.
     *
     * @return {Promise<void>}
     */
    $scope.addAddress = async function () {
        let address = new DigiTickets.Address();
        address.country = UserService.currentBranch.country;
        try {
            const result = await ModalFactory.open(
                'add-address',
                AddressModalCtrl,
                'partials/modals/address.html',
                {
                    address: function () {
                        return address;
                    }
                }
            );
            await addAddressToCurrentAccount(result.address);
        } catch (error) {
            // Do nothing - they just closed the modal.
        }
    };

    /**
     * Persist a new address for the currently displayed account.
     *
     * @param {DigiTickets.Address} address
     *
     * @return {Promise<void>}
     */
    const addAddressToCurrentAccount = async (address) => {
        let addPromise = SavedAddressManager.addAddressToAccount(address, $scope.account);
        toastFactory.spinner(
            'MANAGE_ACCOUNT.ADDRESS_ADDING',
            address.getFormattedAddress(),
            addPromise
        );

        try {
            await addPromise;
            toastFactory.success(
                'MANAGE_ACCOUNT.ADDRESS_ADDED',
                address.getFormattedAddress()
            );
        } catch (error) {
            toastFactory.error(
                'MANAGE_ACCOUNT.ADDRESS_FAILED',
                error.message
            );
        }
    };

    /**
     * @param {DigiTickets.Address} address
     */
    $scope.deleteAddress = async function (address) {
        let deletePromise = SavedAddressManager.removeAddressFromAccount(address, $scope.account);
        toastFactory.spinner(
            'MANAGE_ACCOUNT.ADDRESS_DELETING',
            address.getFormattedAddress(),
            deletePromise
        );

        try {
            await deletePromise;
            toastFactory.warning(
                'MANAGE_ACCOUNT.ADDRESS_DELETED',
                address.getFormattedAddress() + '<br/><a class="btn btn-default btn-sm">Undo</a>',
                {
                    onclick: () => addAddressToCurrentAccount(address)
                }
            );
        } catch (error) {
            toastFactory.error(
                'MANAGE_ACCOUNT.ADDRESS_DELETE_FAILED',
                error.message
            );
        }
    };

    /**
     * @param {Number} id
     */
    var loadAccount = function loadAccount(id) {
        $scope.showLoader = true;
        $scope.showNotFound = false;
        CustomerAccountService.find(
            id,
            function (account) {
                $scope.account = account;
                if (account) {
                    onAccountLoaded(account);
                }
                $scope.showLoader = false;
                $scope.showNotFound = account === null;
            }
        );
    };

    var onAccountLoaded = function onAccountLoaded(account) {
        $scope.activeMembershipCount = $scope.countActiveMemberships();
        $scope.inactiveMembershipCount = $scope.account.memberships.length - $scope.activeMembershipCount;

        $scope.showOrdersLoader = true;
        OrderManagerService.search(
            {
                customerAccountID: account.ID
            },
            function (results) {
                $scope.showOrdersLoader = false;
                $scope.order.results = results;
            }
        );
    };

    let accountID = parseInt($routeParams.accountID);
    if (accountID) {
        loadAccount(accountID);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ManageAccountCtrl;
}
