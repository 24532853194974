const _ = require('lodash');

/**
 * This allows you to make multiple calls to a function, but only the last call (within
 * the given time frame) will actually execute.
 *
 * This service takes the hassle out of debouncing a function call.
 * Usually you have to create a 'debounced version' of the function, store that somewhere, then call that debounced
 * function instead. This will handle the creating and storing parts for you.
 *
 * This Adapted from Glenn's DebounceService originally at services/DebounceService.js and now uses lodash's
 * debounce method. This allows parameters to be passed to the debounced function.
 */
DigiTickets.DebounceService = function () {
    /**
     * Holds all the debounced versions of functions, created by lodash.
     *
     * @type {Object<function>}
     */
    this.debouncedFunctions = {};
};

DigiTickets.DebounceService.prototype = {
    /**
     * To debounce a function call:
     * debounceService.debounce(
     *  'name',
     *  function () { // do stuff },
     *  100)();
     * (Take note of the parenthesis at the end - this method returns a function.)
     *
     * Where 'name' is a string to uniquely identify this function e.g.
     * 'CartService.calculateOffers'
     * or
     * 'OrdersCtrl.orderSearch'
     *
     * and the function is the intensive function you wish to run.
     *
     * This returns a function you call call (immediately if you desire) that will only run once per waitTimeMs.
     * You can also give arguments to this debounced function.
     *
     * @param {string} callId
     * @param {function} whichFunction
     * @param {number} waitTimeMs
     * @param {object} [options] Additional options to pass to lodash's debounce method.
     *
     * @return {Function}
     */
    debounce: function debounce(callId, whichFunction, waitTimeMs, options) {
        // Create the debounced function.
        if (!this.debouncedFunctions.hasOwnProperty(callId)) {
            this.debouncedFunctions[callId] = _.debounce(
                whichFunction,
                waitTimeMs,
                options
            );
            console.log('DebounceService created', callId);
        }

        return this.debouncedFunctions[callId];
    },

    /**
     * This is for backward compatibility with the previous implementation of DebounceService.
     * Creates a debounced function and invokes it immediately.
     *
     * @param callId
     * @param whichFunction
     * @param waitTimeMs
     */
    call: function call(callId, whichFunction, waitTimeMs) {
        this.debounce(callId, whichFunction, waitTimeMs)();
    }
};
