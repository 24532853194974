/**
 * CategoryManager has been created to de-duplicate logic that was the same in each of the item type manager.
 */
DigiTickets.CategoryManager = function () {

};

DigiTickets.CategoryManager.prototype = {

    /**
     * Give this an array of items and it returns an object
     * of categories taken from each item's 'category' property.
     *
     * @param {AbstractItem[]} items
     *
     * @return {Object<Category>}
     */
    extractCategoriesFromItems: function (items) {
        /**
         * @type {Object<Category>}
         */
        let categories = {};

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            if (!item.hasOwnProperty('category')) {
                continue;
            }

            let category = item.category;
            if (!categories.hasOwnProperty(category.ID)) {
                category.items = [];
                category.config = {
                    loaded: false,
                    pageSize: 18,
                    currentPage: 1
                };
                categories[category.ID] = category;
            }

            if (item.isFood) {
                categories[category.ID].hasFood = true;
            } else {
                categories[category.ID].hasNonFood = true;
            }

            categories[category.ID].items.push(item);
        }

        return categories;
    }

};
