const apiErrorMessage = require('../Api/apiErrorMessage');
const EntityStatus = require('../Enums/EntityStatus');
const Field = require('./Field');
const FieldGroup = require('./FieldGroup');
const FieldGroupCollection = require('./FieldGroupCollection');
const FieldInstance = require('./FieldInstance');
const FieldLevel = require('./FieldLevel');
const { cloneShallow } = require('../../../functions/clone');

/**
 * FieldManager performs the same roles as FieldRepository and FieldInstanceFactory in PHP lib.
 * - Retrieving Fields
 * - Generating FieldInstances
 */
/**
 * FieldManager is responsible for loading custom fields from the API, and providing methods to retrieve them.
 * TODO: OE-110 split functions for building FieldGroups into separate class.
 *
 * @param $q
 * @param FieldResource
 * @param FieldCache
 * @param {Hydrator} hydrator
 */
const FieldManager = function (
    $q,
    FieldCache,
    FieldResource,
    hydrator
) {
    this.deferred = $q.defer();
    this.fieldCache = FieldCache;
    this.fieldResource = FieldResource;
    this.hydrator = hydrator;

    /**
     * @type {Field[]}
     */
    this.fields = [];

    this.clear();
};

FieldManager.prototype = {
    clear() {
        this.fields = [];
    },

    /**
     * Load all the possible fields for the company, and store them in this.fields.
     *
     * @param {boolean} [clearCache] True to empty the field cache before loading.
     *
     * @return {promise|{then, catch, finally}} Returns a promise resolved when the fields are loaded.
     */
    loadFields(clearCache) {
        if (clearCache === true) {
            this.clear();
            this.fieldCache.removeAll();
        }

        this.fieldResource.query(
            (fields) => {
                this.clear();

                this.fields = this.hydrator.hydrateArray(
                    fields,
                    () => new Field()
                );

                let supportedLevels = [
                    FieldLevel.ORDER,
                    FieldLevel.ITEM,
                ];

                this.fields = this.fields.filter((field) => {
                    // Remove inactive fields.
                    if (field.status !== EntityStatus.ACTIVE) {
                        return false;
                    }

                    // Remove unsupported levels.
                    if (supportedLevels.indexOf(field.level) === -1) {
                        console.error(`Unknown field level loaded (${field.level}).`);
                        return false;
                    }

                    return true;
                });

                this.deferred.resolve(this.fields);
            },
            (result) => {
                this.deferred.reject(apiErrorMessage(result));
            }
        );

        return this.deferred.promise;
    },

    /**
     * Returns fields matching the given criteria.
     * TODO: Convert to an async function to support storing fields in IndexedDB.
     *
     * @param {string} [level]
     * @param {number[]} [eventIDs]
     * @param {number[]} [itemIDs]
     * @param {number[]} [catIDs]
     * @param {Boolean} [isAskEarly=null]
     *
     * @return {Field[]}
     */
    getFields(level, eventIDs, itemIDs, catIDs, isAskEarly = null) {
        return this.fields.filter((field) => {
            if (level && field.level !== level) {
                return false;
            }

            if (isAskEarly !== null && field.askEarly !== isAskEarly) {
                return false;
            }

            if (field.always) {
                return true;
            }

            if (eventIDs && eventIDs.length > 0) {
                for (let e = 0; e < eventIDs.length; e++) {
                    if (field.isForEvent(eventIDs[e])) {
                        return true;
                    }
                }
            }

            if (itemIDs && itemIDs.length > 0) {
                for (let i = 0; i < itemIDs.length; i++) {
                    if (field.isForItem(itemIDs[i])) {
                        return true;
                    }
                }
            }

            if (catIDs && catIDs.length > 0) {
                for (let c = 0; c < catIDs.length; c++) {
                    if (field.isForCat(catIDs[c])) {
                        return true;
                    }
                }
            }

            return false;
        });
    },


    /**
     * Returns fields that either always apply, or apply based on what's on the given line(s).
     *
     * @param {string} level
     * @param {DigiTickets.CartItem[]} lines
     * @param {Boolean} [isAskEarly=null]
     *
     * @return {Field[]}
     */
    getFieldsForLines(level, lines, isAskEarly = null) {
        let eventIDs = [];
        let itemIDs = [];
        let catIDs = [];

        lines.forEach((line) => {
            let event = line.getEvent();
            if (event) {
                eventIDs.push(event.ID);
            }

            let item = line.getItem();
            if (item) {
                if (item.ID) {
                    itemIDs.push(item.ID);
                }
                if (item.category) {
                    catIDs.push(item.category.ID);
                }
            }
        });

        return this.getFields(level, eventIDs, itemIDs, catIDs, isAskEarly);
    },

    /**
     * Returns the necessary field instances for everything in the cart.
     *
     * @param {CartService} cart
     *
     * @return {FieldGroupCollection}
     */
    createFieldGroupCollectionForCart(cart) {
        let lines = cart.getItems();
        return this.createFieldGroupCollection(lines, true);
    },

    /**
     * Given an array of lines in the cart or an order, returns a FieldGroupCollection containing
     * every FieldInstance that should be displayed to the user
     * (order level, event level, and item level*qty), sorted into FieldGroups.
     *
     * If a Field applies to multiple items, it will appear in the array for each of those items.
     *
     * @param {array} lines  Array of cart / order lines
     * @param {boolean} includeOrderLevel Add order level fields to the group.
     * @param {boolean} matchAskEarlyTo Include or exclude askEarly field instances.
     *
     * @return {FieldGroupCollection}
     */
    createFieldGroupCollection(lines, includeOrderLevel, matchAskEarlyTo) {
        let collection = new FieldGroupCollection();

        // Order level fields
        if (includeOrderLevel === undefined || includeOrderLevel) {
            let orderGroup = this.createOrderLevelFieldGroup(lines);
            if (orderGroup) {
                collection.addFieldGroup(orderGroup);
            }
        }

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];

            // Item level fields
            let itemGroups = this.createItemLevelFieldGroups(line, matchAskEarlyTo);
            for (let x = 0; x < itemGroups.length; x++) {
                collection.addFieldGroup(itemGroups[x]);
            }
        }

        // Put groups with required fields at the top
        collection.sortByRequired();

        return collection;
    },

    /**
     * Returns a FieldGroup containing FieldInstances for order level fields for the company.
     *
     * @return {FieldGroup|false}
     * @param lines
     */
    createOrderLevelFieldGroup(lines) {
        let fields = this.getFieldsForLines('order', lines, false);

        if (fields.length < 1) {
            return false;
        }

        let fieldGroup = new FieldGroup({
            level: 'order'
        });

        $(fields).each(function (index, field) {
            fieldGroup.add(new FieldInstance({
                field
            }));
        });

        return fieldGroup;
    },

    /**
     * Returns an array of FieldGroups containing FieldInstances for item level fields for an order/cart line.
     *
     * @param {OrderLine|DigiTickets.CartItem} line
     * @param {boolean} matchAskEarlyTo
     *
     * @return {FieldGroup[]}
     */
    createItemLevelFieldGroups(line, matchAskEarlyTo) {
        let groups = [];

        let item = line.getItem();
        let session = line.getSession();

        if (!item) {
            return [];
        }
        let itemID = item.ID;

        let fields = this.getFieldsForLines('item', [line], matchAskEarlyTo);
        if (fields.length < 1) {
            return [];
        }

        // As a line is added without a quantity, treat 0 as 1 for the purposes of finding field options.
        let qty = Math.max(1, Math.abs(line.getQuantity()));
        for (let itemInstance = 0; itemInstance < qty; itemInstance++) {
            let title = item.name + (matchAskEarlyTo ? '' : ' (' + (itemInstance + 1) + ')');
            let subtitle = '';
            if (session) {
                subtitle = session.getFullName();
            }

            let fieldGroup = new FieldGroup({
                title,
                subtitle,
                level: 'item',
                itemID,
                itemInstance,
                lineNumber: line.lineNumber
            });

            // FIXME: Remove use of jQuery (write tests)
            $(fields).each(function (index, field) {
                fieldGroup.add(new FieldInstance({
                    field,
                    itemID,
                    itemInstance,
                    lineNumber: line.lineNumber
                }));
            });

            groups.push(fieldGroup);
        }

        return groups;
    },

    /**
     * Given an array of data submitted by the user, go through each FieldInstance in the collection and
     * if the key of the FieldInstance exists in {values}, set the value of the instance.
     *
     * @param {FieldGroupCollection} collection
     * @param {object} fieldData
     * @param {string} fieldGroupingType
     *
     * @return {FieldGroupCollection}
     */
    setValuesOfFieldGroupCollection(collection, fieldData, fieldGroupingType) {
        if (collection != null) {
            collection.forEachInstance(function (instance) {
                // We only want to interact with the right field grouping type.
                let process = false;
                switch (fieldGroupingType) {
                    case DigiTickets.FieldGroupingType.ALL_FIELDS:
                        process = true;
                        break;
                    case DigiTickets.FieldGroupingType.ASK_EARLY_FIELDS_ONLY:
                        process = instance.field.askEarly;
                        break;
                    case DigiTickets.FieldGroupingType.NON_ASK_EARLY_FIELDS_ONLY:
                        process = !instance.field.askEarly;
                        break;
                    default:
                        console.trace('Missing value for fieldGroupingType for setValuesOfFieldGroupCollection');
                        break;
                }
                if (process) {
                    // For ask early instance, try and copy the instance 0 value.
                    let key = (instance.field.askEarly && instance.itemInstance > 0)
                        ? cloneShallow(instance).init({ itemInstance: 0 }).getKey()
                        : instance.getKey();

                    if (fieldData.hasOwnProperty(key)) {
                        instance.setValue(fieldData[key]);
                        fieldData[instance.getKey()] = fieldData[key];
                    } else {
                        instance.clearValue();
                    }
                }
            });
        }

        return collection;
    },

    /**
     * Checks that all the required fields have been entered.
     *
     * @param  {CartService} cart
     * @param  {object} fieldData
     *
     * @return {object} Object with validation result/errors. Success property is true if the data is valid.
     */
    validateCartFieldData: function validateCartFieldData(cart, fieldData) {
        let fields = this.createFieldGroupCollectionForCart(cart);
        this.setValuesOfFieldGroupCollection(
            fields,
            fieldData,
            DigiTickets.FieldGroupingType.NON_ASK_EARLY_FIELDS_ONLY
        );

        let result = {
            valid: true,
            errors: {}
        };

        if (fields.groups.length < 1) {
            // No fields
            return result;
        }

        fields.forEachInstance(function (instance) {
            // Check required fields are entered
            if (instance.field.required && !instance.hasValue) {
                result.valid = false;
                result.errors[instance.getKey()] = 'Please fill out this field.';
            }
            if (instance.error && instance.hasValue) {
                result.valid = false;
                result.errors[instance.getKey()] = 'Please correct the values as indicted by the error message(s).';
            }
        });

        return result;
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = FieldManager;
}
