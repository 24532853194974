const { toBool, toInt } = require('../functions/transform');

DigiTickets.SessionAvailability = function () {
    this.sessionID = null;
    this.available = false;
    this.suitable = false;
};

DigiTickets.SessionAvailability.prototype = {
    getHydrationMap() {
        return {
            sessionID: toInt,
            available: toBool,
            suitability: toBool
        };
    }
};
