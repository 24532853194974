const is = require('../Is');

/**
 * @param $rootScope
 * @param {OnlineResource} OnlineResource
 */
const ConnectivityChecker = function (
    $rootScope,
    OnlineResource
) {
    this.$rootScope = $rootScope;
    this.onlineResource = OnlineResource;

    this.$rootScope.$on('requestError', function (event, response) {
        if (this.online && (response.status == 0 || response.status == 503)) {
            // Network error
            this.online = false;
            this.notifyObservers();
        }
    }.bind(this));

    this.$rootScope.$on('requestSuccess', function () {
        if (!this.online) {
            // Network success
            this.online = true;
            this.notifyObservers();
        }
    }.bind(this));

    this.debug = false;

    /**
     * Interval for checking if still connected.
     *
     * @type {?number}
     */
    this.timer = null;

    /**
     * How frequently (in seconds) to check if still connected.
     *
     * @type {number}
     */
    this.seconds = 30;

    /**
     * Connected?
     *
     * @type {boolean}
     */
    this.online = false;

    /**
     * @type {Object<{key: string, notifyOnline: function, notifyOffline: function}>}
     */
    this.observerList = {};

    this.start();
};

ConnectivityChecker.prototype = {
    start: function start() {
        if (this.timer !== null) {
            throw new Error('Cannot start interval because it is already running.');
        }

        this.logMsg('Started (' + this.seconds + 's)');
        this.timer = setInterval(this.check.bind(this), this.seconds * 1000);

        // Call it once now
        this.check().catch(
            // Catch to avoid uncaught promise rejection.
            /* istanbul ignore next */
            () => {}
        );
    },

    stop: function stop() {
        if (this.timer != null) {
            this.logMsg('Stopped');
            clearInterval(this.timer);
        }
        this.timer = null;
    },

    check: function check() {
        const self = this;

        return new Promise((resolve, reject) => {
            this.logMsg('Pinging server...');

            this.onlineResource.ping(
                function () {
                    if (self.online === false) {
                        // Previously was offline, now we are online..
                        self.online = true;
                        self.logMsg('Change - Notifying');
                        self.notifyObservers();
                    }

                    resolve(true);
                    self.logMsg('Complete - Online');
                },
                function () {
                    if (self.online === true) {
                        // Previously was online, now we are offline..
                        self.online = false;
                        self.logMsg('Change - Notifying');
                        self.notifyObservers();
                    }

                    resolve(false);
                    self.logMsg('Complete - Offline');
                }
            );
        });
    },

    notifyObservers: function notifyObservers() {
        for (let key in this.observerList) {
            /* istanbul ignore if */
            if (!this.observerList.hasOwnProperty(key)) {
                continue;
            }

            let observer = this.observerList[key];

            if (this.online) {
                observer.notifyOnline(this);
            } else {
                observer.notifyOffline(this);
            }
        }
    },

    hasObserver: function hasObserver(key) {
        return this.observerList.hasOwnProperty(key);
    },

    addObserver: function addObserver(observer, notifyNow) {
        // Check if they have the required methods
        if (
            !is.aFunction(observer.notifyOnline)
                || !is.aFunction(observer.notifyOffline)
                || !observer.hasOwnProperty('key')
        ) {
            throw new Error(
                'Observer must have a key and implement notifyOnline() and notifyOffline() methods.'
            );
        }

        if (this.hasObserver(observer.key)) {
            this.logMsg(`An observer already exists with the key '${observer.key}', replacing.`);
        }

        this.logMsg('Added observer with the key ' + observer.key);
        this.observerList[observer.key] = observer;

        // Notify that observer now.
        if (notifyNow) {
            if (this.online) {
                observer.notifyOnline(this);
            } else {
                observer.notifyOffline(this);
            }
        }
    },

    removeObserver: function removeObserver(key) {
        if (this.observerList.hasOwnProperty(key)) {
            delete this.observerList[key];
            return true;
        }

        return false;
    },

    isOnline: function isOnline() {
        return this.online;
    },

    logMsg: function logMsg(message) {
        if (this.debug) {
            console.log('ConnectivityChecker', message);
        }
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ConnectivityChecker;
}
