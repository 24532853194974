const DataStoreNames = require('./DataStores/DataStoreNames');
const Dexie = require('dexie');

const DataStore = function () {
    /**
     * @type {Dexie}
     */
    this.db = null;

    /**
     * Store names are stored here so they can be easily overridden during tests.
     * This matters when calling clearAll in a test because it will try and clear all the defined stores.
     *
     * @private
     * @type {Readonly<string>}
     */
    this.STORE_NAMES = DataStoreNames;

    /**
     * @see http://dexie.org/docs/Dexie/Dexie.transaction()
     * @see http://dexie.org/docs/Dexie/Dexie.transaction()#specify-reusage-of-parent-transaction
     */
    this.TRANSACTION_MODE = {
        READWRITE: 'rw!',
        READONLY: 'r!'
    };

    // In the browser create a real Dexie DB.
    // Unit tests set this.db to a mock DB.
    /* istanbul ignore if */
    if (typeof window !== 'undefined') {
        this.init();
    }
};

DataStore.prototype = {
    /* istanbul ignore next */
    init() {
        /**
         * Create a new IndexedDB database with the name propoint.
         *
         * @type {Dexie}
         */
        this.db = new Dexie('propoint');

        // Define the object stores and indexes.
        this.db.version(1).stores(
            {
                // Object store named 'sessions'. Primary key is 'ID'. 'eventID' is an extra index.
                sessions: 'ID,eventID',
                syncMetadata: 'name',
                test: 'name'
            }
        );

        this.db.version(2).stores(
            {
                marketingPreferences: 'ID',
                sessions: 'ID,eventID',
                syncMetadata: 'name',
                test: 'name',
                userSessions: 'ID,username'
            }
        );

        this.db.version(3).stores(
            {
                marketingPreferences: 'ID,status',
                sessions: 'ID,eventID',
                syncMetadata: 'name',
                test: 'name',
                userSessions: 'ID,username'
            }
        );

        this.db.version(4).stores(
            {
                categories: 'ID,status',
                marketingPreferences: 'ID,status',
                sessions: 'ID,eventID',
                syncMetadata: 'name',
                test: 'name',
                userSessions: 'ID,username'
            }
        );

        // If you change the structure of the above you must create a new version with an incremented version number,
        // and all the previous version definitions must remain in this file!
        // e.g.
        // this.db.version(4).stores(
        //    {
        //        newTable: 'ID,
        //        sessions: 'ID,eventID',
        //        syncMetadata: 'name',
        //        test: 'name'
        //    }
        // );
    },

    /**
     * @param {string} storeName
     *
     * @return {Dexie.Table}
     */
    getStore: function getStore(storeName) {
        return this.db.table(storeName);
    },

    /**
     * @param {string} storeName
     * @param {object} data
     *
     * @return {Dexie.Promise<Key>}
     */
    persist: function persist(storeName, data) {
        // We sometimes get an IndexedDB error when trying to store something that contains circular references
        // or other weirdness. This can be hard to debug as it doesn't tell us what caused the error!
        // To help with debugging these we can attempt to convert it to JSON here and if that fails we know saving it
        // in IndexedDB will fail.
        // Note that there may still be other reasons persisting in IndexedDB will fail, and this is helping with one
        // reason.
        try {
            JSON.stringify(data);
        } catch (e) {
            console.error(`Failed to convert to JSON to persist to store '${storeName}'`, data);
            throw e;
        }

        return this.getStore(storeName).put(data);
    },

    /**
     * @param storeName
     * @param dataArray
     *
     * @return {Dexie.Promise<Key>}
     */
    persistMany: function persistMany(storeName, dataArray) {
        return this.getStore(storeName).bulkPut(dataArray);
    },

    /**
     * @param storeName
     * @param keys
     * @return {Dexie.Promise<void>}
     */
    removeMany: function removeMany(storeName, keys) {
        return this.getStore(storeName).bulkDelete(keys);
    },

    /**
     * Find a single entity by its primary key.
     *
     * @param {string} storeName
     * @param {*} key
     *
     * @return {Dexie.Promise<Object>}
     */
    find: function find(storeName, key) {
        return this.getStore(storeName).get(key);
    },

    /**
     * Find many entities where the specified field has the specified value.
     * Note the field must be indexed.
     *
     * @param {string} storeName
     * @param {string} field
     * @param {*} value
     *
     * @return {Dexie.Promise<Array<T>>}
     */
    findWhere: function findWhere(storeName, field, value) {
        let started = new Date();
        let where = {};
        where[field] = value;
        return this.getStore(storeName)
            .where(where)
            .toArray()
            .then(function (data) {
                console.debug(`Loaded ${data.length} items from ${storeName} where ${field} = ${value} in ${(new Date()).getTime() - started.getTime()}ms`);
                return data;
            });
    },

    /**
     * Return all items from a table.
     *
     * @param {string} storeName
     *
     * @return {Dexie.Promise<array>}
     */
    findAll: function findAll(storeName) {
        let started = new Date();
        return this.getStore(storeName)
            .toArray()
            .then(function (data) {
                console.debug(`Loaded ${data.length} items from ${storeName} in ${(new Date()).getTime() - started.getTime()}ms`);
                return data;
            });
    },

    /**
     * Empty the specified store / table.
     *
     * @param {string} storeName
     *
     * @returns {Dexie.Promise<void>}
     */
    clear: function clear(storeName) {
        return this.getStore(storeName).clear();
    },

    /**
     *
     * @returns {Promise<[]>}
     */
    clearAll: function clearAll() {
        let promises = [];
        const names = Object.values(this.STORE_NAMES);
        for (let i = 0; i < names.length; i++) {
            promises.push(this.clear(names[i]));
        }

        return Promise.all(promises);
    },

    /**
     * @param {string} mode
     * @param {array} tables
     * @param {function} callback
     */
    transaction: function transaction(mode, tables, callback) {
        this.db.transaction(
            mode,
            tables,
            callback
        );
    },

    /**
     * Checks if the syncMetadata store says the data for the given store has been synced,
     * and if a branchID was given if it was for that branch.
     *
     * @param {string} storeName
     * @param {number} [branchID]
     */
    isSynced(storeName, branchID) {
        console.debug('Checking for last sync of ' + storeName, (branchID ? 'For branch ' + branchID : ''));

        return this.find(
            this.STORE_NAMES.SYNC_METADATA,
            storeName
        ).then(
            function (result) {
                console.debug('Last sync for ' + storeName, result);

                if (result) {
                    if (branchID) {
                        // Check the branch matches
                        if (result.branchID === branchID) {
                            return result.lastUpdatedAt;
                        }
                        let resultBranchStr = result.branchID ? `branch ${result.branchID}` : 'unknown branch';
                        throw new Error(
                            `Last sync for ${storeName} was for ${resultBranchStr} not ${branchID}`
                        );
                    } else {
                        return result.lastUpdatedAt;
                    }
                } else {
                    throw new Error('No last sync for ' + storeName);
                }
            }
        );
    },

    /**
     * Store in the syncMetadata store when the specified store was last updated.
     *
     * @param {string} storeName
     * @param {number} [branchID]
     * @param {Date} [lastUpdatedAt] Optionally pass in the date instead of using the current Date.
     */
    setLastSynced(storeName, branchID, lastUpdatedAt) {
        if (!lastUpdatedAt) {
            lastUpdatedAt = new Date();
        }

        return this.persist(
            this.STORE_NAMES.SYNC_METADATA,
            {
                name: storeName,
                branchID: branchID || null,
                lastUpdatedAt
            }
        );
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = DataStore;
}
