const FieldData = require('./FieldData');
const FieldInstance = require('./FieldInstance');
const FieldLevel = require('./FieldLevel');
const is = require('../../Is');

/**
 * @param {Hydrator} hydrator
 */
const OrderFieldInstancesFromApiBuilder = function (
    hydrator
) {
    this.hydrator = hydrator;
};

OrderFieldInstancesFromApiBuilder.prototype = {
    /**
     * @param {FieldData[]} fieldData
     * @param {object} lineNumbers FieldData doesn't have lineNumbers when it comes from the API but it does have
     *                             ordersItemsID, so a map of ordersItemsIDs to lineNumbers can be passed in.
     *
     * @return {FieldInstance[]} Returns an array of FieldInstances, not sorted by where they belong. Use
     *                           setFieldInstancesOnOrder to put the instances in the right places on the order.
     */
    createFieldInstancesFromApiFieldData(fieldData, lineNumbers = {}) {
        /** @type {Object<string, FieldInstance>} */
        let fieldInstances = {};

        fieldData.forEach((fieldDatum) => {
            if (!(fieldDatum instanceof FieldData)) {
                // Create FieldData object in case this is raw data from the API.
                /** @type {FieldData} */
                fieldDatum = this.hydrator.hydrate(fieldDatum, new FieldData());
            }

            // Create a FieldInstance
            let fieldInstance = new FieldInstance({
                field: fieldDatum.field,
                itemInstance: fieldDatum.itemInstance
                // lineNumber is set below
            });

            // If the FieldData already contains a line number, use it.
            // Otherwise see if we can find the lineNumber in the passed in mapping of line IDs to line numbers.
            // We only need to do this for item level data because only those have line numbers.
            if (fieldInstance.field.level === FieldLevel.ITEM) {
                if (fieldDatum.lineNumber) {
                    fieldInstance.setLineNumber(fieldDatum.lineNumber);
                } else if (!fieldInstance.lineNumber && fieldDatum.ordersItemsID) {
                    fieldInstance.setLineNumber(lineNumbers[fieldDatum.ordersItemsID] || null);
                }
            }

            let instanceKey = fieldInstance.getKey();

            // The API result has the selected FieldOption attached to the FieldData, and has the Field attached
            // to the FieldData, but the Field does not contain any FieldInstances.
            // That means we don't have a list of every option available for the field and we only have those that
            // hae been chosen. But that doesn't really matter for displaying an order.
            // So each FieldOption we come across we will add to the Field and that will suffice.
            if (fieldDatum.field.hasOptions) {
                if (fieldDatum.fieldOption && !fieldInstance.field.getOptionByID(fieldDatum.fieldOption.ID)) {
                    fieldInstance.field.options.push(fieldDatum.fieldOption);
                }
                fieldInstance.setValue(fieldDatum.fieldOptionID);
            } else {
                fieldInstance.setValue(fieldDatum.value);
            }

            // For checkbox fields or other (future) types that support multiple values the fielddata array will
            // contain one FieldData for each selected option. We need to merge those into a single FieldInstance with
            // all the options as its value.
            if (fieldInstances.hasOwnProperty(instanceKey)) {
                // Merge this value with the existing instance.
                let existingInstance = fieldInstances[instanceKey];

                if (!is.anArray(existingInstance.getValue())) {
                    console.error(existingInstance, fieldInstance);
                    throw new Error('Found multiple values for a field that should not have multiple values.');
                }

                // See the comment above about FieldOptions not being attached to the field.
                // We want to keep them all.
                existingInstance.field.options = existingInstance.field.options.concat(fieldInstance.field.options);

                existingInstance.setValue(existingInstance.getValue().concat(fieldInstance.getValue()));
            } else {
                fieldInstances[instanceKey] = fieldInstance;
            }
        });

        return Object.values(fieldInstances);
    },

    /**
     * @param {FieldInstance[]} fieldInstances
     * @param {DigiTickets.Order} order
     */
    setFieldInstancesOnOrder(fieldInstances, order) {
        order.orderLevelFieldGroup.instances = [];

        fieldInstances.forEach((fieldInstance) => {
            if (fieldInstance.field.level === FieldLevel.ORDER) {
                order.orderLevelFieldGroup.add(fieldInstance);
            } else if (fieldInstance.field.level === FieldLevel.ITEM) {
                // Find the line.
                let line = order.getLineByNumber(fieldInstance.lineNumber);
                // Find the instance.
                let instance = line ? line.getInstance(fieldInstance.itemInstance + 1) : null;
                if (instance) {
                    instance.fields.add(fieldInstance);
                }
            }
        });
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderFieldInstancesFromApiBuilder;
}
