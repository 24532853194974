DigiTickets.Worldpay.Config = (function () {
    let Config = function Config() {
        DigiTickets.AbstractPaymentMethodConfig.call(this);

        /**
         * Allow cardholder not present transactions?
         *
         * @type {boolean}
         */
        this.allowCnp = true;

        /**
         * Port for the 'integration socket' connection to the terminal.
         *
         * @type {number}
         */
        this.terminalPort = 10000;

        /**
         * Should a transaction be started automatically when switching to the WorldPay screen?
         * May be annoying if the user regularly does partial or CNP payments as they have to cancel that,
         * which also prints a receipt.
         *
         * @type {boolean}
         */
        this.autoStartTransactions = true;

        /**
         * If true ProPoint will print receipts received on the receipt socket instead of IPC printing the receipts.
         * (This flag doesn't disable IPC printing them itself too - you need to disable that using YESEFTConfig)
         *
         * @type {boolean}
         */
        this.printReceipts = true;

        /**
         * Port for the 'receipt socket' connection to the terminal.
         *
         * @type {number}
         */
        this.receiptPort = 20000;
    };

    Config.prototype = Object.create(DigiTickets.AbstractPaymentMethodConfig.prototype, {
    });

    Config.prototype.constructor = Config;

    return Config;
}());
