const AbstractSearchCache = require('../AbstractSearchCache');
const { cloneShallow } = require('../../../functions/clone');

/**
 * This is a singleton which holds the state of the search results from the orders on a account page.
 * It remembers what page of the results was shown.
 */
const AccountOrderSearchCache = function () {
    AbstractSearchCache.call(this);
};

AccountOrderSearchCache.prototype = Object.create(AbstractSearchCache.prototype);
AccountOrderSearchCache.constructor = AccountOrderSearchCache;

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = AccountOrderSearchCache;
}
