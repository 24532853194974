const PrintJobOptions = require('../../Printing/PrintJobOptions');

let paymentExpressLog = function (a, b) {
    if (b !== undefined) {
        console.log('PaymentExpressDriver', a, b);
    } else {
        console.log('PaymentExpressDriver', a);
    }
};

/**
 * @param $q
 * @param $timeout
 * @param {DigiTickets.AppConfig} AppConfig
 * @param {DigiTickets.CartStashHelper} CartStashHelperService
 * @param {DigiTickets.Logger} Logger
 * @param {DigiTickets.NotificationService} NotificationService
 * @param {PaymentMethodService} paymentMethodService
 * @param PaymentExpressResource
 * @param {CurrentDevice} CurrentDevice
 * @param {ReceiptPrinter} ReceiptPrinterService
 * @param {UserService} UserService
 */
DigiTickets.PaymentExpressDriver = function (
    $q,
    $timeout,
    AppConfig,
    CartStashHelperService,
    Logger,
    NotificationService,
    paymentMethodService,
    PaymentExpressResource,
    CurrentDevice,
    ReceiptPrinterService,
    UserService
) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.appConfig = AppConfig;
    this.cartStashHelper = CartStashHelperService;
    /**
     * @type {PaymentMethodService}
     */
    this.paymentMethodService = paymentMethodService;
    this.logger = Logger;
    this.notificationService = NotificationService;
    this.paymentExpressResource = PaymentExpressResource;
    /**
     * @type {CurrentDevice}
     */
    this.currentDevice = CurrentDevice;
    this.receiptPrinter = ReceiptPrinterService;
    this.user = UserService;

    this.paymentMethodRef = 'card-payment-express';

    /**
     * @type {DigiTickets.PaymentExpressConfig|null}
     */
    this.config = null;

    /**
     * The content of the last card receipt that was printed.
     * Used to prevent printing duplicate receipts.
     *
     * @type {string|null}
     */
    this.lastReceiptPrintedContent = null;
};

DigiTickets.PaymentExpressDriver.prototype = {

    init: function () {
        if (!this.config) {
            this.config = this.createConfig();
        }
    },

    /**
     * Create the PaymentExpressConfig object.
     *
     * @return {DigiTickets.PaymentExpressConfig}
     */
    createConfig: function createConfig() {
        let config = new DigiTickets.PaymentExpressConfig();

        // Set POS version.
        config.posVersion = this.appConfig.eposVersion.toISOString();

        let company = this.user.company;
        if (!company) {
            throw new Error('No company selected.');
        }

        // Set device ID.
        let device = this.currentDevice.device;
        if (!device) {
            throw new Error('No current device selected.');
        }
        config.deviceID = device.ID;

        // Set environment (live or test).
        config.setEnvironment(company.isLive());
        let environment = config.environment;

        // Get config from company payment method configuration.
        let paymentMethod = this.paymentMethodService.getByRef(this.paymentMethodRef);
        if (!paymentMethod) {
            throw new Error('Company does not have the Payment Express payment method.');
        }

        let paymentMethodConfig = JSON.parse(paymentMethod.config);

        if (!paymentMethodConfig) {
            throw new Error('There is no payment method paymentMethodConfiguration (Device ' + device.ID + ').');
        }

        if (!paymentMethodConfig.hasOwnProperty(environment)) {
            throw new Error('There is no "' + environment + '" entry in the paymentMethodConfiguration (Device ' + device.ID + ').');
        }

        if (!paymentMethodConfig[environment].HITuser) {
            throw new Error('HITuser missing from paymentMethodConfiguration (Device ' + device.ID + ').');
        }
        config.HITuser = paymentMethodConfig[environment].HITuser;

        if (!paymentMethodConfig[environment].HITkey) {
            throw new Error('HITkey missing from configuration (Device ' + device.ID + ').');
        }
        config.HITkey = paymentMethodConfig[environment].HITkey;

        // Set currency.
        let currencyCode = company.currency && company.currency.code ? company.currency.code : null;
        if (!currencyCode) {
            throw new Error('No currency is set on the company.');
        }
        config.setCurrencyCode(currencyCode);

        // Set station/payment terminal ID.
        if (!device.paymentTerminalRef) {
            throw new Error('The current device (' + device.ID + ') has no payment terminal ref.');
        }
        config.stationID = device.paymentTerminalRef;

        paymentExpressLog('Config', config);

        return config;
    },

    /**
     * Create a new PaymentExpressTransaction object.
     *
     * @param {number} amount
     * @param {string} txnRef
     *
     * @return {DigiTickets.PaymentExpressTransaction}
     */
    createTransaction: function createTranasction(amount, txnRef) {
        return new DigiTickets.PaymentExpressTransaction(this.config, amount, txnRef);
    },

    /**
     * Send a transaction to the terminal.
     * Returns a promise:
     * - Resolved if the transaction is complete, was successful, and has been paid.
     * - Rejected if the transaction is complete but was not successful.
     * - Notified when there is a status update.
     *
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     */
    sendTransaction: function sendTransaction(transaction) {
        let self = this;
        let deferred = this.$q.defer();

        var pollStatus = function () {
            // Start to monitor the status.
            self.status(transaction).then(
                function (result) {
                    handleResponse(result.response, result.receivedAt);
                },
                function () {
                    // Failed to check the status.
                    // This doesn't necessary mean the transaction is over! Just that there was an error
                    // while checking the status.

                    self.$timeout(function () {
                        pollStatus();
                    }, 1000);
                }
            );
        };

        /**
         * @param {DigiTickets.PaymentExpressResponse} response
         * @param {Date} responseReceivedAt
         */
        var handleResponse = function (response, responseReceivedAt) {
            transaction.isStarted = true;
            transaction.processingMessage = '';
            transaction.updateScreen(response.getScreenUpdate());

            // If the response contains a card receipt we need to print it.
            let printCardReceiptPromise = self.printCardReceipt(response);

            if (response.isComplete()) {
                transaction.isComplete = true;

                // It's complete, but was it successful?
                if (response.isApproved()) {
                    transaction.isSuccess = true;
                    // Completed and approved.

                    if (printCardReceiptPromise) {
                        // A card receipt is currently printing.

                        // Update the screen now.
                        deferred.notify({
                            response
                        });

                        // Wait until it has finished before resolving so we can log how long it took to print.
                        printCardReceiptPromise.then(function () {
                            let timeTakenToPrint = (new Date()).getTime() - responseReceivedAt.getTime();
                            console.log('Time taken to print PE receipt:', timeTakenToPrint);
                            deferred.resolve({
                                response,
                                debugInfo: {
                                    timeTakenToPrint
                                }
                            });
                        });
                    } else {
                        deferred.resolve({
                            response
                        });
                    }
                } else {
                    transaction.isSuccess = false;

                    // Completed but not approved.
                    transaction.errorMessage = 'Unsuccessful'
                            + (transaction.errorMessage ? ' - ' + transaction.errorMessage : '');

                    deferred.reject({
                        response
                    });
                }
            } else {
                transaction.isComplete = false;

                // Not complete yet.
                deferred.notify({
                    response
                });

                pollStatus();
            }
        };

        if (transaction.isStarted) {
            paymentExpressLog('Skipping initiate');
            // If recovering a transaction isStarted will already be set to true.
            // Skip initiating and go straight to polling.
            pollStatus();
        } else {
            // Send the transaction to Payment Express.
            this.initiate(transaction).then(
                function (result) {
                    // Initiated successfully.
                    handleResponse(result.response, result.receivedAt);
                },
                function (result) {
                    // Failed to initiate.
                    transaction.processingMessage = '';

                    let response = null;
                    if (result && result.response) {
                        // If there is a response that means it got to PE and they sent a bad response.
                        // We can use their error info.
                        if (result.response.getErrorMessage()) {
                            transaction.errorMessage = result.response.getErrorMessage();
                        }

                        handleResponse(result.response, result.receivedAt);
                        response = result.response;
                    } else {
                        // If there is no response it means we couldn't communicate with PE.
                        // This is bad as it means the payment may have made it to the terminal but we don't know
                        // about it.
                        // Tell the operator to cancel the payment.

                        // TODO: If customer has already paid there should be a manual entry to say it was successful.
                        // We kow the txnRef so this could be automated?

                        transaction.errorMessage = 'Failed to start transaction. Press the Cancel button on the payment terminal.';

                        // Timeout so the user has time to press Cancel before retrying.
                        self.$timeout(function () {
                            transaction.isComplete = true;
                        }, 3000);
                    }

                    deferred.reject({
                        response
                    });
                }
            );
        }

        return deferred.promise;
    },

    /**
     * Being a transaction with Payment Express.
     * Returns an object containing a boolean and a promise.
     *
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     */
    initiate: function initiate(transaction) {
        let self = this;
        let deferred = this.$q.defer();

        paymentExpressLog('Initiate', transaction);

        transaction.processingMessage = 'Starting Transaction...';

        this.paymentExpressResource.initiate(
            {
                message: transaction.getInitiateRequest()
            },
            {
                env: this.config.environment
            },
            function success(data) {
                let receivedAt = new Date();
                paymentExpressLog('Initiate response', data.responseXml);
                let response = self.transformResponse(data.responseXml);

                // If the response contains an error code
                // or if the transaction is already complete
                // something went wrong starting the transaction.
                if (response.isComplete() || response.isError()) {
                    self.logger.error('Bad Payment Express initiate response. ' + response.getErrorMessage());

                    deferred.reject({
                        response,
                        receivedAt
                    });
                } else {
                    deferred.resolve({
                        response,
                        receivedAt
                    });
                }
            },
            function failure(response) {
                self.logFailedRequest('Failed to initiate Payment Express transaction.', transaction, response);

                deferred.reject({});
            }
        );

        return deferred.promise;
    },

    /**
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     * @param {number} buttonNumber
     * @param {string} buttonLabel
     */
    buttonPress: function buttonPress(transaction, buttonNumber, buttonLabel) {
        let self = this;
        let deferred = this.$q.defer();

        paymentExpressLog('Sending Button Press', transaction, buttonNumber, buttonLabel);
        transaction.processingMessage = 'Sending Request...';

        this.paymentExpressResource.buttonPress(
            {
                message: transaction.getButtonPressRequest(buttonNumber, buttonLabel)
            },
            {
                env: this.config.environment
            },
            function success(data) {
                let receivedAt = new Date();
                paymentExpressLog('Button press response', data.responseXml);
                let response = self.transformResponse(data.responseXml);

                deferred.resolve({
                    response,
                    receivedAt
                });
            },
            function failure(response) {
                self.logFailedRequest('Failed to send Payment Express button press.', transaction, response);

                deferred.reject({});
            }
        );

        return deferred.promise;
    },

    /**
     * Returns a promise that is resolved when the status of the transaction is received.
     * It is rejected if there is an error while getting the status. If a 'bad' status' such as cancelled
     * is received, it will still resolve.
     *
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     */
    status: function status(transaction) {
        let self = this;
        let deferred = this.$q.defer();

        paymentExpressLog('Checking Status', transaction);

        this.paymentExpressResource.status(
            {
                message: transaction.getStatusRequest()
            },
            {
                env: this.config.environment
            },
            function success(data) {
                let receivedAt = new Date();
                paymentExpressLog('Status response', data.responseXml);
                let response = self.transformResponse(data.responseXml);

                deferred.resolve({
                    response,
                    receivedAt
                });
            },
            function failure(response) {
                self.logFailedRequest('Failed to check Payment Express transaction status.', transaction, response);

                // Reject the promise to show it failed.
                deferred.reject({});
            }
        );

        return deferred.promise;
    },

    /**
     * Log a failed request to Payment Express.
     * Failed means an actual failure (no response, bad HTTP status, etc.). NOT a valid response from PE
     * with an error code ore message.
     *
     * @param {string} message
     * @param {DigiTickets.PaymentExpressTransaction} transaction
     * @param response
     */
    logFailedRequest: function logFailedRequest(message, transaction, response) {
        // Log error.
        this.logger.error(
            message
                + ' (txnRef ' + transaction.txnRef + ')'
                + response.data ? JSON.stringify(response.data) : ''
        );

        // Display error.
        this.notificationService.error(message, true);
    },

    /**
     * @param {string} responseXML
     * @return {DigiTickets.PaymentExpressResponse}
     */
    transformResponse: function transformResponse(responseXML) {
        return new DigiTickets.PaymentExpressResponse(responseXML);
    },

    /**
     * Print a card receipt from the given response.
     * Returns a promise if a receipt is being printed.
     *
     * @param {DigiTickets.PaymentExpressResponse} response
     *
     * @return {object|null}
     */
    printCardReceipt: function printCardReceipt(response) {
        // If the device has its own integral printer, don't do anything.
        if (this.currentDevice.device.terminalHasPrinter) {
            return null;
        }

        // Now see if there is any kind of receipt that we could print.
        let cardReceipt = response.getCardReceipt();
        if (!cardReceipt) {
            return null;
        }

        if (cardReceipt === this.lastReceiptPrintedContent) {
            // We already printed this from the last poll. Don't print again.
            paymentExpressLog('Card receipt same as already printed.');
            return null;
        }

        paymentExpressLog('Printing card receipt.');
        this.lastReceiptPrintedContent = cardReceipt;

        let deferred = this.$q.defer();

        // There is a receipt.
        // See if there is a line width.
        let receiptWidth = parseInt(response.getContent('RcptW'));
        if (receiptWidth > 0) {
            // Split the receipt up into chunks of that length.
            let parts = cardReceipt.match(new RegExp('.{1,' + receiptWidth + '}', 'g'));
            cardReceipt = parts.join('<br />');
        }

        let printOpts = new PrintJobOptions();
        printOpts.onBeforePrintCall = function () {
            deferred.resolve();
        };

        this.receiptPrinter.printCardReceipt(cardReceipt, printOpts);

        // Just in case the onBeforePrintCall callback never happens.
        this.$timeout(function () {
            deferred.resolve();
        }, 2000);

        return deferred.promise;
    }
};
