const angular = require('angular');
const Reservation = require('./Reservations/Reservation');
const { cloneDeep } = require('../../functions/clone');

/**
 * This is the heart of the offline order processing. If an order fails (network error) then it will be
 * added to this queue and processed.
 *
 * @param $rootScope
 * @param ReservationResource
 * @param OrderResource
 * @param {ConnectivityChecker} ConnectivityChecker
 * @param OrderQueueCache
 * @param {DigiTickets.Logger} Logger
 * @param {Hydrator} hydrator
 */
const OrderQueue = function (
    $rootScope,
    ConnectivityChecker,
    hydrator,
    Logger,
    OrderQueueCache,
    OrderResource,
    ReservationResource
) {
    this.cache = OrderQueueCache;
    this.connectivityChecker = ConnectivityChecker;
    this.hydrator = hydrator;
    this.logger = Logger;
    this.orderResource = OrderResource;
    this.reservationResource = ReservationResource;
    this.$rootScope = $rootScope;

    this.orderList = [];

    this.cacheKey = 'orderList';

    this.debug = false;
    this.verbose = false;

    this.timer = null;
    this.seconds = 65;

    this.requestsTotal = 0;
    this.requestsProcessed = 0;
    this.orderSyncActive = false;

    // Load from cache (including local storage)
    let cachedOrderList = this.cache.get(this.cacheKey);
    if (cachedOrderList != undefined && cachedOrderList instanceof Array) {
        this.orderList = cachedOrderList;
    } else {
        this.syncCache();
    }

    this.requestsTotal = this.orderList.length;

    Logger.debug(
        'Order queue initialized',
        {
            queueLength: this.requestsTotal
        }
    );

    this.loop();

    let self = this;
    this.$rootScope.$on('OrderQueue.actions.process', function () {
        self.loop();
    });

    /**
     * Initialize OrderQueue
     */
    this.setOptions({
        debug: false,
        verbose: false
    }).startTimer();
};

OrderQueue.prototype = {

    startTimer: function startTimer() {
        if (this.isRunning()) {
            return false; // Already started..
        }

        this.logMsg('Started - ' + this.orderList.length);
        this.$rootScope.$broadcast('OrderQueue.start', this.orderList.length);
        this.timer = setInterval(function () {
            this.processQueue();
        }.bind(this), this.seconds * 1000);

        return true;
    },

    stopTimer: function stopTimer() {
        if (this.isRunning()) {
            this.logMsg('Stopped');
            clearInterval(this.timer);
            this.timer = null;
        }

        return true;
    },

    loop: function loop() {
        this.processQueue();

        return true;
    },

    /**
     * @param {DigiTickets.OrderRequest} request
     */
    addRequest: function addRequest(request) {
        this.logger.debug(
            'Add request to order queue',
            {
                currentLength: this.orderList.length
            }
        );
        this.logMsg('Request added.', true);

        this.orderList.push(cloneDeep(request));

        this.$rootScope.$broadcast('OrderQueue.requestAdd', this.orderList.length);

        this.syncCache();

        return this.orderList.length - 1;
    },

    /**
     * @returns {DigiTickets.OrderRequest}
     */
    getLastRequest: function getLastRequest() {
        if (this.orderList.length > 0) {
            return this.orderList[this.orderList.length - 1];
        }
        return new DigiTickets.OrderRequest({}, {});
    },

    /**
     * Processes the whole queue and then syncs the cache afterwards.
     */
    processQueue: function processQueue() {
        if (!this.connectivityChecker.isOnline()) {
            this.logMsg('Cannot process as we are offline.', true);
            return false;
        }

        if (this.orderSyncActive) {
            this.logMsg('Cannot process queue as it is already running...');
            return false;
        }

        if (this.orderList.length === 0) {
            this.logMsg('No requests to process.', true);
            return false;
        }

        this.logMsg('Processing [' + this.orderList.length + '] items.');
        this.$rootScope.$broadcast('OrderQueue.processingStarted', this.orderList.length);

        try {
            let self = this;
            let tempList = this.orderList.slice(0);
            this.orderSyncActive = true;
            this.requestsTotal = this.orderList.length;
            this.orderList = [];

            // Loop through the tempList array, and if the request fails we will add the request back onto orderList
            for (let j = 0; j < tempList.length; j++) {
                let request = tempList[j];
                (function (queue, index, request) {
                    setTimeout(function () {
                        queue.processRequest(index, request);
                    }, index * 1250);
                }(this, j, request));
            }
        } catch (error) {
            this.logger.error(
                'OrderQueue.processQueue try/catch : FAILURE',
                {
                    error
                }
            );

            this.orderSyncActive = false;
            return false;
        }


        this.syncCache();

        return true;
    },

    /**
     * Processes each individual request and broadcasts events based on the result.
     *
     * @param index
     * @param request
     */
    processRequest: function processRequest(index, request) {
        let self = this;

        if (typeof request.attempts === 'number') {
            ++request.attempts;
        } else {
            request.attempts = 1;
        }

        this.logger.debug(
            'OrderQueue.processRequest',
            {
                index,
                attempt: request.attempts
            }
        );

        /**
         * If a reservation was reserved but went offline before they complete payment,
         * this is the edge case handler - we TRY (might be expired ~ 15min) to delete the
         * reservation. And then force a new one upon it..
         */
        if (request.reservationToken != undefined && request.reservationToken != null) {
            this.reservationResource.delete(
                { id: request.reservationToken },
                function (success) {

                },
                function (response) {
                    self.logger.error(
                        'OrderQueue.processRequest : Old reservation token failed to be deleted',
                        {
                            index,
                            attempt: request.attempts,
                            reservationToken: request.reservationToken
                        }
                    );
                }
            );
        }

        this.reservationResource.reserve({}, request.reservation,
            function success(response) {
                let reservation = self.hydrator.hydrate(
                    response.reservation,
                    new Reservation()
                );

                request.order.reservationToken = reservation.token;

                self.logger.debug(
                    'OrderQueue.processRequest : Received new reservation',
                    {
                        index,
                        attempt: request.attempts,
                        reservationToken: request.order ? request.order.reservationToken : null
                    }
                );

                self.sendOrderCompletion(request);
            },
            function error(response) {
                self.logger.error(
                    'OrderQueue.processRequest : Failed to get new reservation token',
                    {
                        index,
                        attempt: request.attempts
                    }
                );

                self.handleError(self, request, response);
            });
    },

    /**
     * @param {DigiTickets.OrderRequest} request
     */
    sendOrderCompletion: function sendOrderCompletion(request) {
        let self = this;

        this.orderResource.confirm({}, request.order,
            function success(response) {
                self.logger.debug(
                    'OrderQueue.sendOrderCompletion.success : Confirmation sent',
                    {
                        attempt: request.attempts,
                        reservationToken: request.reservationToken,
                        response
                    }
                );

                self.requestsProcessed++;
                self.$rootScope.$broadcast('OrderQueue.requestComplete', self.requestsTotal - self.requestsProcessed);
                self.logAutoRedeemAfterSale(response.order.bookingRef, response.lineRedemption);
                self.logMsg(self.requestsProcessed + ' - Order request has been processed.', true);
                self.checkRequestsProcessed();
            },
            function error(response) {
                self.logger.warning(
                    'OrderQueue.sendOrderCompletion.success : Confirmation failed to be sent',
                    {
                        attempt: request.attempts,
                        reservationToken: request.reservationToken,
                        response
                    }
                );

                self.handleError(self, request, response);
            });
    },

    /**
     * Returns list of queued items
     *
     * @return array
     */
    getQueue: function getQueue() {
        return this.orderList;
    },

    /**
     * Returns an order from the queue if it's there
     *
     * @param tempOrderID Temporary order ID generated when order was added to the queue
     *
     * @return {object|null}
     */
    getOrderRequestFromQueue: function getOrderRequestFromQueue(tempOrderID) {
        for (let i in this.orderList) {
            if (this.orderList.hasOwnProperty(i)) {
                if (typeof this.orderList[i].order !== 'object') {
                    continue;
                }

                if (this.orderList[i].order.tempID == tempOrderID) {
                    return this.orderList[i];
                }
            }
        }

        return null;
    },

    //
    // Sets the 'customer' property of an OrderRequest that is waiting
    // to be sent to the server.
    //
    // @param tempOrderID Temporary order ID generated when order was added to the queue
    // @param data
    //
    updateQueuedOrder: function updateQueuedOrder(tempOrderID, data) {
        let orderRequest = this.getOrderRequestFromQueue(tempOrderID);
        if (orderRequest) {
            angular.extend(orderRequest.order, data);
            this.syncCache();
        }
    },

    /**
     * @param {OrderQueue} self
     * @param {DigiTickets.OrderRequest} request
     * @param response
     */
    handleError: function handleError(self, request, response) {
        self.logMsg(request, true);

        if (request.errors !== undefined) {
            request.errors.push(response);
        }

        if (request.attempts >= 10) {
            this.logger.warning(
                'OrderQueue.handleError - 10 or more errors, giving up'
            );

            self.logMsg('Giving up on request', true);
        } else {
            self.addRequest(request);
        }

        self.requestsProcessed++;

        // Will have to be handled next time around...
        self.logMsg(self.requestsProcessed + ' - Request failed, adding back onto queue.', true);
        self.$rootScope.$broadcast('OrderQueue.requestFailed', self.requestsTotal - self.requestsProcessed);

        self.checkRequestsProcessed();
    },

    checkRequestsProcessed: function checkRequestsProcessed() {
        if (this.requestsProcessed == this.requestsTotal) {
            this.logMsg('Processing complete.');
            this.requestsProcessed = 0;
            this.orderSyncActive = false;
            this.requestsTotal = this.orderList.length;
            this.$rootScope.$broadcast('OrderQueue.processingComplete', this.requestsTotal);
        }
    },

    /**
     * Simply replaces the current cache list with the new cache list,
     * this cache stores it in localStorage.
     */
    syncCache: function syncCache() {
        for (let i = 0; i < this.orderList.length; i++) {
            if (this.orderList[i] === null) {
                delete this.orderList[i];
            }
        }

        if (this.orderList.length > 0) {
            this.cache.put(this.cacheKey, this.orderList);
        } else {
            this.cache.put(this.cacheKey, []);
        }

        return this;
    },

    isRunning: function isRunning() {
        return this.timer !== null;
    },

    setOptions: function setOptions(options) {
        if (!options instanceof Object) {
            throw new Error('OrderQueue options needs to be an object.');
        }

        for (let key in options) {
            if (!options.hasOwnProperty(key)) {
                continue;
            }
            this[key] = options[key];
        }

        return this;
    },

    enableDebug: function enableDebug() {
        this.debug = true;

        return this;
    },

    enableVerbose: function enableVerbose() {
        this.verbose = true;

        return this;
    },

    setSeconds: function setSeconds(seconds) {
        this.seconds = seconds;

        return this;
    },

    logMsg: function logMsg(message, verbose) {
        if (this.debug && (verbose == undefined || verbose == false)) {
            console.log(new Date() + ' : [OrderQueue] ' + message);
        } else if (this.debug && this.verbose && verbose) {
            console.log(new Date() + ' : [OrderQueue] ' + message, { orderList: this.orderList });
        }
    },

    /**
     * Method just to log how many lines of an order have been auto-redeemed after the
     * order was created (& processed) in the EPOS. In the future, we may show an alert
     * on screen, but for now, we just log the details.
     *
     * @param {string} bookingRef
     * @param {object} redemptionInfo
     */
    logAutoRedeemAfterSale: function logAutoRedeemAfterSale(bookingRef, redemptionInfo) {
        if (redemptionInfo != undefined) {
            if (redemptionInfo.linesRedeemed && redemptionInfo.totalLines) {
                this.logger.info(
                    'After sale auto redeem: ' + redemptionInfo.linesRedeemed + ' of ' + redemptionInfo.totalLines + ' line(s) redeemed for order ' + bookingRef + '.'
                );
            }
        }
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = OrderQueue;
}
