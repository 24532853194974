const md5 = require('blueimp-md5');

/**
 * Wrapper md5 function so we can switch out the implementation without having to update everywhere.
 * Note: If you just want a quick hash and it doesn't have to be md5 specifically use the object-hash package instead.
 *
 * @param {string} string
 * @param {boolean} [toUpperCase]
 *
 * @return {string}
 */
const md5Hash = (string, toUpperCase) => {
    let hash = md5(string);
    return toUpperCase ? hash.toUpperCase() : hash;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        md5Hash
    };
}
