const { toBool, toInt, toNullableInt } = require('../functions/transform');

DigiTickets.AutoRedeemProfile = function () {
    /**
     * @type {boolean}
     */
    this.enabled = true;

    /**
     * @type {number|null}
     */
    this.ID = null;

    /**
     * @type {number|null}
     */
    this.maxMinutesBeforeEventStarts = null;

    /**
     * @type {boolean}
     */
    this.redeemMembersWhenScanned = false;
};

DigiTickets.AutoRedeemProfile.prototype = {
    getHydrationMap() {
        return {
            enabled: toBool,
            ID: {
                field: ['ID', 'autoRedeemProfileID'],
                transform: toInt
            },
            maxMinutesBeforeEventStarts: toNullableInt,
            redeemMembersWhenScanned: toBool
        };
    }
};
