const AgentService = require('../libraries/DigiTickets/AgentService');
const ApiRequestParams = require('../libraries/DigiTickets/Api/ApiRequestParams');
const AutoRedemptionStrategy = require('../libraries/DigiTickets/Redemption/AutoRedemptionStrategy');
const AvailablePrintersManager = require('../libraries/DigiTickets/Printing/AvailablePrintersManager');
const CacheManager = require('../libraries/DigiTickets/CacheManager');
const CartLineSplitter = require('../libraries/DigiTickets/Cart/CartLineSplitter');
const CartLineNumberFactory = require('../libraries/DigiTickets/Cart/CartLineNumberFactory');
const CartService = require('../libraries/DigiTickets/Cart/CartService');
const CartStasher = require('../libraries/DigiTickets/CartStasher');
const CartToConfirmationStructureMapper = require('../libraries/DigiTickets/Cart/CartToConfirmationStructureMapper');
const CartToOrderMapper = require('../libraries/DigiTickets/Mappers/CartToOrderMapper');
const CartToReservationStructureMapper = require('../libraries/DigiTickets/Cart/CartToReservationStructureMapper');
const CategoryRepository = require('@/lib/Categories/CategoryRepository');
const ConnectivityChecker = require('../libraries/DigiTickets/ConnectivityChecker');
const ContactPhotoService = require('../libraries/DigiTickets/ContactPhotoService');
const CountryManager = require('../libraries/DigiTickets/CountryManager');
const CurrencyService = require('../libraries/DigiTickets/CurrencyService');
const CurrentDevice = require('../libraries/DigiTickets/CurrentDevice');
const CustomerAccountSearchCache = require('../libraries/DigiTickets/Customers/CustomerAccountSearchCache');
const CustomerAccountService = require('../libraries/DigiTickets/CustomerAccountService');
const CustomerScreenDataService = require('../libraries/DigiTickets/CustomerScreenDataService');
const CustomerSearchService = require('../libraries/DigiTickets/CustomerSearchService');
const DataStore = require('../libraries/DigiTickets/DataStore');
const DataStoreTester = require('../libraries/DigiTickets/DataStoreTester');
const DataSyncer = require('../libraries/DigiTickets/DataSyncer');
const DeviceManager = require('../libraries/DigiTickets/DeviceManager');
const DialogService = require('../libraries/DigiTickets/DialogService');
const ErrorPresenter = require('../libraries/DigiTickets/Errors/ErrorPresenter');
const ExternalCartCalculator = require('../libraries/DigiTickets/Cart/ExternalCartCalculator');
const FieldInstanceFactory = require('../libraries/DigiTickets/CustomFields/FieldInstanceFactory');
const FieldManager = require('../libraries/DigiTickets/CustomFields/FieldManager');
const FieldValidator = require('../libraries/DigiTickets/CustomFields/FieldValidator');
const GiftAidPrompter = require('../libraries/DigiTickets/GiftAid/GiftAidPrompter');
const Hydrator = require('../libraries/DigiTickets/Hydration/Hydrator');
const LandingPage = require('../libraries/DigiTickets/LandingPage');
const LangService = require('../libraries/DigiTickets/LangService');
const LocalStorageDataStore = require('../libraries/DigiTickets/LocalStorageDataStore');
const LogoService = require('../libraries/DigiTickets/LogoService');
const MarketingPreferenceAsker = require('../libraries/DigiTickets/Customers/MarketingPreferences/MarketingPreferenceAsker');
const MarketingPreferenceRepository = require('@/lib/MarketingPreferences/MarketingPreferenceRepository');
const MembershipService = require('../libraries/DigiTickets/Memberships/MembershipService');
const MembershipValidator = require('../libraries/DigiTickets/Memberships/MembershipValidator');
const ModalFactory = require('../libraries/DigiTickets/ModalFactory');
const NavigationService = require('../libraries/DigiTickets/NavigationService');
const OrderFieldInstancesFromApiBuilder = require('../libraries/DigiTickets/CustomFields/OrderFieldInstancesFromApiBuilder');
const OrderAdjustmentConfirmer = require('../libraries/DigiTickets/Orders/Adjustments/OrderAdjustmentConfirmer');
const OrderFulfillmentService = require('../libraries/DigiTickets/Fulfillments/OrderFulfillmentService');
const OrderManager = require('../libraries/DigiTickets/OrderManager');
const OrderQueue = require('../libraries/DigiTickets/OrderQueue');
const OrderReceiptPrinter = require('../libraries/DigiTickets/Printing/OrderReceiptPrinter');
const OrderSearchCache = require('../libraries/DigiTickets/Orders/OrderSearchCache');
const OrderService = require('../libraries/DigiTickets/OrderService');
const PaymentService = require('../libraries/DigiTickets/Payment/PaymentService');
const PaymentMethodService = require('../libraries/DigiTickets/PaymentMethods/PaymentMethodService');
const PaymentState = require('../libraries/DigiTickets/Payment/PaymentState');
const Printer = require('../libraries/DigiTickets/Printing/Printer');
const PrinterAppPrintDriver = require('../libraries/DigiTickets/Printing/Drivers/PrinterAppPrintDriver');
const PrintRouter = require('../libraries/DigiTickets/Printing/PrintRouter');
const ReceiptPrinter = require('../libraries/DigiTickets/Printing/ReceiptPrinter');
const RedemptionScanHandler = require('../libraries/DigiTickets/RedemptionScanHandler');
const RefValidator = require('../libraries/DigiTickets/RefValidator');
const SavedAddressManager = require('../libraries/DigiTickets/SavedAddressManager');
const SearchContext = require('../libraries/DigiTickets/SearchContext');
const SellStateService = require('../libraries/DigiTickets/SellStateService');
const SessionManager = require('@/libraries/DigiTickets/SessionManager');
const SocketConnector = require('../libraries/DigiTickets/SocketConnector');
const SoldGiftVoucherManager = require('../libraries/DigiTickets/SoldGiftVoucherManager');
const SyncStateManager = require('@/lib/DataStores/SyncStateManager');
const TaxManager = require('../libraries/DigiTickets/TaxManager');
const TemplateCompiler = require('../libraries/DigiTickets/Templates/TemplateCompiler');
const ThemeManager = require('../libraries/DigiTickets/ThemeManager');
const ThirdPartyRefGenerator = require('../libraries/DigiTickets/Cart/ThirdPartyRefGenerator');
const ToastFactory = require('../libraries/DigiTickets/ToastFactory');
const UserService = require('../libraries/DigiTickets/UserService');
const UserSessionManager = require('../libraries/DigiTickets/UserSessions/UserSessionManager');
const UserSwitcher = require('../libraries/DigiTickets/UserSessions/UserSwitcher');
const WebcamService = require('../libraries/DigiTickets/WebcamService');

/**
 * This tells Angular how to resolve dependencies when doing its automatic
 * Dependency Injection (DI). In Angular these are called Providers.
 * See {@link https://docs.angularjs.org/guide/providers} for more details.
 *
 * Where a provider is more complex than just a simple list of dependencies,
 * we create a dedicated file within this same directory.
 *
 * Each call to app.service here registers a service with that name and the specified implementation.
 * The string is the name that can be used to inject the instance. it is best to keep this name and the name of the
 * implementing class the same if possible.
 * e.g. Don't call the service XService if the class is called XManager. Pick one and stick with it.
 *
 * The first time a service needs to be injected the given implementation will be new'd and that instance remembered.
 * The same instance will be re-used for all subsequent calls.
 *
 * @param {angular.Module} app
 */
const registerAngularServices = (app) => {
    app.service('AgentService', AgentService);
    app.service('ApiRequestParams', ApiRequestParams);
    app.service('AppConfig', DigiTickets.AppConfig);
    app.service('autoRedemptionStrategy', AutoRedemptionStrategy);
    app.service('availablePrintersManager', AvailablePrintersManager);
    app.service('CacheManager', CacheManager);
    app.service('cartLineSplitter', CartLineSplitter);
    app.service('cartLineNumberFactory', CartLineNumberFactory);
    app.service('cartService', CartService);
    app.service('CartStasher', CartStasher);
    app.service('CartStashHelperService', DigiTickets.CartStashHelper);
    app.service('cartToConfirmationStructureMapper', CartToConfirmationStructureMapper);
    app.service('cartToOrderMapper', CartToOrderMapper);
    app.service('cartToReservationStructureMapper', CartToReservationStructureMapper);
    app.service('CategoryManager', DigiTickets.CategoryManager);
    app.service('categoryRepository', CategoryRepository);
    app.service('ConnectivityChecker', ConnectivityChecker);
    app.service('ContactPhotoService', ContactPhotoService);
    app.service('CountryManager', CountryManager);
    app.service('CurrencyService', CurrencyService);
    app.service('CurrentDevice', CurrentDevice);
    app.service('customerAccountSearchCache', CustomerAccountSearchCache);
    app.service('CustomerAccountService', CustomerAccountService);
    app.service('CustomerScreenDataService', CustomerScreenDataService);
    app.service('CustomerSearchService', CustomerSearchService);
    app.service('dataStore', DataStore);
    app.service('dataStoreTester', DataStoreTester);
    app.service('DataSyncer', DataSyncer);
    app.service('DebounceService', DigiTickets.DebounceService);
    app.service('deviceManager', DeviceManager);
    app.service('DialogService', DialogService);
    app.service('errorPresenter', ErrorPresenter);
    app.service('externalCartCalculator', ExternalCartCalculator);
    app.service('fieldInstanceFactory', FieldInstanceFactory);
    app.service('fieldManager', FieldManager);
    app.service('fieldValidator', FieldValidator);
    app.service('giftAidPrompter', GiftAidPrompter);
    app.service('GiftVoucherManager', DigiTickets.GiftVoucherManager);
    app.service('GiftVoucherValidator', DigiTickets.GiftVoucherValidator);
    app.service('hydrator', Hydrator);
    app.service('LandingPage', LandingPage);
    app.service('LangService', LangService);
    app.service('LocalStorageDataStore', LocalStorageDataStore);
    app.service('Logger', DigiTickets.Logger);
    app.service('LogoService', LogoService);
    app.service('MarketingPreferenceAsker', MarketingPreferenceAsker);
    app.service('marketingPreferenceRepository', MarketingPreferenceRepository);
    app.service('MembershipCancellationRunnerService', DigiTickets.MembershipCancellationRunner);
    app.service('MembershipManagementActionsService', DigiTickets.MembershipManagementActions);
    app.service('MembershipPlanService', DigiTickets.MembershipPlanManager);
    app.service('MembershipRefGenerator', DigiTickets.MembershipRefGenerator);
    app.service('membershipService', MembershipService);
    app.service('membershipValidator', MembershipValidator);
    app.service('ModalFactory', ModalFactory);
    app.service('navigationService', NavigationService);
    app.service('NotificationService', DigiTickets.NotificationService);
    app.service('NumPadFactory', DigiTickets.NumPadFactory);
    app.service('OfferManager', DigiTickets.OfferManager);
    app.service('orderFieldInstancesFromApiBuilder', OrderFieldInstancesFromApiBuilder);
    app.service('OnlineQueueService', DigiTickets.OnlineQueue);
    app.service('orderAdjustmentConfirmer', OrderAdjustmentConfirmer);
    app.service('orderFulfillmentService', OrderFulfillmentService);
    app.service('OrderInProgressStasher', DigiTickets.OrderInProgressStasher);
    app.service('OrderManagerService', OrderManager);
    app.service('orderQueue', OrderQueue);
    app.service('orderReceiptPrinter', OrderReceiptPrinter);
    app.service('orderSearchCache', OrderSearchCache);
    app.service('orderService', OrderService);
    app.service('paymentService', PaymentService);
    app.service('PaymentExpressDriver', DigiTickets.PaymentExpressDriver);
    app.service('paymentMethodService', PaymentMethodService);
    app.service('paymentState', PaymentState);
    app.service('Printer', Printer);
    app.service('printerAppPrintDriver', PrinterAppPrintDriver);
    app.service('PrintRouter', PrintRouter);
    app.service('PrivilegesService', DigiTickets.PrivilegesManager);
    app.service('ProductService', DigiTickets.ProductManager);
    app.service('ReceiptPrinterService', ReceiptPrinter);
    app.service('RedemptionManagerService', DigiTickets.RedemptionManager);
    app.service('redemptionScanHandler', RedemptionScanHandler);
    app.service('refValidator', RefValidator);
    app.service('ReturnReasonService', DigiTickets.ReturnReasonManager);
    app.service('SaleableItemService', DigiTickets.SaleableItemService);
    app.service('SavedAddressManager', SavedAddressManager);
    app.service('searchContext', SearchContext);
    app.service('sellStateService', SellStateService);
    app.service('SessionManager', SessionManager);
    app.service('socketConnector', SocketConnector);
    app.service('SoldGiftVoucherManager', SoldGiftVoucherManager);
    app.service('StorageImplementationService', DigiTickets.StorageImplementation);
    app.service('syncStateManager', SyncStateManager);
    app.service('TaxManager', TaxManager);
    app.service('templateCompiler', TemplateCompiler);
    app.service('TemplateService', DigiTickets.TemplateManager);
    app.service('themeManager', ThemeManager);
    app.service('thirdPartyRefGenerator', ThirdPartyRefGenerator);
    app.service('TicketService', DigiTickets.TicketManager);
    app.service('toastFactory', ToastFactory);
    app.service('TradingSessionManager', DigiTickets.TradingSessionManager);
    app.service('UserService', UserService);
    app.service('UserSessionManager', UserSessionManager);
    app.service('userSwitcher', UserSwitcher);
    app.service('webcamService', WebcamService);
};

if (typeof module !== 'undefined' && module.exports) {
    // Running in Node or Webpack - export the functions to be used elsewhere.
    module.exports = registerAngularServices;
} else {
    // Running directly in the browser (Grunt).
    // To maintain previous behaviour call the function immediately.
    registerAngularServices(window.app);
}
