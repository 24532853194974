/**
 * The kitchen is only concerned with a few pieces of info about an Order (item names, qty, fields).
 * This is a smaller Order model that only contains that required info.
 */
class KitchenOrder {
    constructor() {
        /**
         * The time the order was first sent to the kitchen.
         *
         * @type {?Date}
         */
        this.fulfillmentCreatedAt = null;

        /**
         * The time the order was completed by the kitchen ("Order Away" pressed).
         *
         * @type {?Date}
         */
        this.fulfilledAt = null;

        /**
         * @type {OrderFulfillment[]}
         */
        this.fulfillments = [];

        /**
         * The date the order was last modified (if applicable).
         *
         * @type {?Date}
         */
        this.modifiedAt = null;

        /**
         * @type {KitchenOrderItem[]}
         */
        this.items = [];

        /**
         * @type {?string}
         */
        this.notes = null;

        /**
         * @type {?string}
         */
        this.staffRef = null;

        /**
         * TODO: Remove (PRO-412)
         *
         * @type {?string}
         */
        this.thirdPartyID = null;

        /**
         * @type {?string}
         */
        this.thirdPartyRef = null;

        // TODO: Remove (PRO-412)
        this.orderID = null;
        // TODO: Remove (PRO-412)
        this.stashedCartGuid = null;
    }

    /**
     * @param {KitchenOrderItem} item
     */
    addItem(item) {
        this.items.push(item);
    }

    isModified() {
        return !!(this.modifiedAt && this.fulfillmentCreatedAt
            && this.modifiedAt > this.fulfillmentCreatedAt
            && this.modifiedAt.toHMS() !== this.fulfillmentCreatedAt.toHMS());
    }
}

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = KitchenOrder;
}
