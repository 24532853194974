const is = require('../../Is');
const { arrayCombine } = require('../../../functions/functions');

const ApiResponseUnmapper = {

    /**
     * Unmap an API response back to the original API response we would have received had it not been mapped.
     * Only 2 mappers are currently supported:
     * mappers[0][normalise]=true
     * mappers[1][toTable]=true
     *
     * @param {object} input
     *
     * @return {object}
     */
    unmap(input) {
        if (!input) {
            return {};
        }

        if (!input.hasOwnProperty('data') || !input.hasOwnProperty('columns')) {
            throw new Error(
                'Invalid structure in mappedResponse. It should have the keys `data` and `columns`.'
            );
        }

        let columns = input.columns;

        let relationKeys;
        if (input.hasOwnProperty('relations')) {
            relationKeys = Object.keys(input.relations);
        } else {
            relationKeys = [];
        }

        const mapRow = (row, tableName) => {
            if (!columns[tableName]) {
                throw new Error(`Columns not found for table \`${tableName}\`.`);
            }
            let unmappedRow = arrayCombine(columns[tableName], row);

            relationKeys.forEach(
                (key) => {
                    if (key !== 'data' && unmappedRow.hasOwnProperty(key) && unmappedRow[key]) {
                        let value = unmappedRow[key];

                        // A relation's value may be a single number or an array of numbers.
                        if (is.aNumber(value)) {
                            unmappedRow[key] = mapRow(input.relations[key][value], key);
                        } else if (is.anArray(value)) {
                            if (is.aNumber(value[0])) {
                                unmappedRow[key] = value.map(
                                    (value) => mapRow(input.relations[key][value], key)
                                );
                            } else {
                                unmappedRow[key] = [];
                            }
                        }
                    }
                }
            );

            return unmappedRow;
        };

        return input.data.map((row) => mapRow(row, 'data'));
    },

    transformApiResponse(input, resourceType) {
        // We need to check if the parameter is a JSON string of it it is already decoded.
        // When angular-resource returns a cached response it is already decoded when given to transformResponse.
        // If it is a fresh response it is a raw JSON string.
        input = typeof input === 'string' && input.length ? JSON.parse(input) : input;

        // We will receive an unmapped response when there is no actual data for example for the session refresh
        // Otherwise we should not be receiving an unmapped response here because angular-cache uses the URL
        // (including the &mapper... part) as the cache key. Meaning the old cached versions of the responses should
        // have been replaced but check here just in case.
        if (input.length === 0) {
            console.log(resourceType + ' data is empty []');
        } else if (!input.hasOwnProperty('data') || !input.hasOwnProperty('columns')) {
            console.warn(resourceType + ' data is not mapped.', input);
        } else {
            console.log(resourceType + ' data is mapped');
            input = this.unmap(input);
        }

        return input;
    }

};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = ApiResponseUnmapper;
}
