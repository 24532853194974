const { toFloat, toInt } = require('../functions/transform');

DigiTickets.SubscriptionPayment = function () {
    /**
     * @type {number}
     */
    this.apportionedAmount = null;

    /**
     * @type {number}
     */
    this.ID = null;

    /**
     * @type {number}
     */
    this.numInstallmentsCovered = null;

    /**
     * @type {DigiTickets.Payment}
     */
    this.payment = null;
};

DigiTickets.SubscriptionPayment.prototype = {
    getHydrationMap() {
        return {
            apportionedAmount: toFloat,
            ID: {
                field: ['ID', 'subscriptionPaymentID'],
                transform: toInt
            },
            numInstallmentsCovered: toInt,
            payment: {
                field: ['payment', 'payments'],
                model: DigiTickets.Payment
            }
        };
    }
};
