const FieldType = require('./FieldType');

/**
 * Find all field instance elements in the DOM and return their values.
 * This is a replacement to the old FieldHelper.serializeFieldData which used jQuery.
 *
 * @return {Object<string, string|number|number[]>}
 */
const extractFieldValuesFromForm = () => {
    let values = {};

    const form = document.getElementById('custom-fields-form');
    if (!form) {
        return values;
    }

    let instances = form.getElementsByClassName('field-instance-input');

    for (let i = 0; i < instances.length; i++) {
        let instance = instances[i];

        let fieldType = instance.getAttribute('data-field-type');
        let instanceKey = instance.getAttribute('data-instance-key');

        let optionID;
        switch (fieldType) {
            case FieldType.CHECKBOX:
                if (!values.hasOwnProperty(instanceKey)) {
                    // For checkboxes always create an array for the values.
                    values[instanceKey] = [];
                }
                // Value is an optionID.
                optionID = parseInt(instance.getAttribute('value'), 10);
                if (optionID && instance.checked) {
                    values[instanceKey].push(optionID);
                }
                break;

            case FieldType.RADIO:
                // Value is an optionID.
                optionID = parseInt(instance.getAttribute('value'), 10);
                if (optionID && instance.checked) {
                    values[instanceKey] = optionID;
                }
                break;

            case FieldType.SELECT:
                // Value is an optionID.
                optionID = parseInt(instance.value, 10);
                if (optionID) {
                    values[instanceKey] = optionID;
                }
                break;

            default:
                // Plain text.
                values[instanceKey] = instance.value;
        }
    }

    return values;
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = {
        extractFieldValuesFromForm
    };
}
