const DataStoreNames = require('../DataStores/DataStoreNames');
const UserSession = require('./UserSession');

/**
 * @param {DataStore} dataStore
 * @param {Hydrator} hydrator
 * @param {LocalStorageDataStore} LocalStorageDataStore
 */
const UserSessionManager = function (
    dataStore,
    hydrator,
    LocalStorageDataStore
) {
    this.dataStore = dataStore;
    this.hydrator = hydrator;
    this.localStorageDataStore = LocalStorageDataStore;
};

UserSessionManager.prototype = {
    /**
     * Return all user sessions.
     *
     * @return {Promise<UserSession[]>|Dexie.Promise<UserSession[]>}
     */
    getAll() {
        return this.dataStore
            .findAll(DataStoreNames.USER_SESSIONS)
            .then(this.hydrator.createModelHydrator(UserSession));
    },

    /**
     * Get an existing user session from the DB.
     * Resolves either with the user session or with null.
     * TODO: Should reject if null?
     *
     * @param {number} userID
     *
     * @return {Promise<?UserSession>}
     */
    getSession(userID) {
        return new Promise((resolve) => {
            this.dataStore
                .find(DataStoreNames.USER_SESSIONS, userID)
                .then((result) => {
                    if (result) {
                        let userSession = this.hydrator.hydrate(
                            result,
                            new UserSession()
                        );
                        resolve(userSession);
                    } else {
                        resolve(null);
                    }
                })
                .catch(
                    /* istanbul ignore next */
                    () => resolve(null)
                );
        });
    },

    /**
     * Insert or update a session in the DB.
     * The given sessionInfo object does not need to be the complete dataset. Only the keys given will be modified.
     *
     * @param {number} userID
     * @param {object} sessionInfo
     *
     * @return {Promise}
     */
    saveSession(userID, sessionInfo) {
        return this.getSession(userID)
            .then((userSession) => {
                if (!userSession) {
                    userSession = new UserSession(userID);
                }

                userSession.updateSession(sessionInfo);

                return this.persist(userSession);
            });
    },

    /**
     * Save a session in the DB.
     *
     * @param {UserSession} userSession
     */
    persist(userSession) {
        return this.dataStore.persist(
            DataStoreNames.USER_SESSIONS,
            userSession
        );
    },

    /**
     * API keys (and formerly passwords) are stored in the user sessions so that we can
     * switch between users with just a PIN. But this should only be possible if the
     * user has logged in recently (last 24 hours).
     * This method clears stored API keys for users that logged in over 24 hours ago.
     *
     * @param {Date} [cutoff] Cutoff can be passed in for testing.
     *
     * @return {Promise<UserSession[]>}
     */
    pruneOldAuthData(cutoff) {
        if (!cutoff) {
            cutoff = new Date();
            cutoff.setTime(cutoff.getTime() - (86400 * 1000));
        }

        return this.getAll()
            .then(
                /**
                 * @param {UserSession[]} sessions
                 */
                async (sessions) => {
                    let modified = [];

                    for (let i = 0; i < sessions.length; i++) {
                        if (!sessions[i].lastLoginDatetime || sessions[i].lastLoginDatetime < cutoff) {
                            await this.saveSession(
                                sessions[i].ID,
                                {
                                    apiKey: null,
                                    password: null
                                }
                            );

                            modified.push(sessions[i]);
                        }
                    }

                    return modified;
                }
            );
    },

    /**
     * Remembers the given username for when we to pre-fill the username on the login screen.
     * It saves the whole cache afterwards.
     *
     * @param {string} username
     */
    setAutofillLoginUsername(username) {
        if (username) {
            this.localStorageDataStore.persist('autofillLoginUsername', username);
        } else {
            this.localStorageDataStore.remove('autofillLoginUsername');
        }
    },

    /**
     * @return {?string}
     */
    pullAutofillLoginUsername: function pullAutofillLoginUsername() {
        let value = this.localStorageDataStore.find('autofillLoginUsername');
        this.localStorageDataStore.remove('autofillLoginUsername');
        return value || null;
    },

    /**
     * Returns the page the user A was on before using the 'switch user' function
     * to change to user B.
     * Removes the saved value after resolving.
     *
     * @param {number} userID
     *
     * @return {Promise<?string>}
     */
    pullLastSessionScreen(userID) {
        return new Promise((resolve) => {
            this.getSession(userID)
                .then((session) => {
                    if (session && session.lastScreen) {
                        // Remove the lastScreen saved in the session.
                        this.saveSession(userID, {
                            lastScreen: null
                        })
                            .then(() => {
                                resolve(session.lastScreen);
                            });
                    } else {
                        resolve(null);
                    }
                })
                .catch(
                    /* istanbul ignore next */
                    () => resolve(null)
                );
        });
    },

    /**
     * Returns every currently stashed cart, from every EPOS user.
     *
     * @return {Dexie.Promise<DigiTickets.StashedCart[]>|Promise<DigiTickets.StashedCart[]>}
     */
    getStashedCarts() {
        return this.getAll()
            .then((sessions) => sessions.reduce(
                /**
                 * @param {[]} allCarts
                 * @param {UserSession} session
                 */
                (allCarts, session) => allCarts.concat(session.stashedCarts),
                []
            ));
    },

    /**
     * Method to go through all the stashed carts and remove any that are older than
     * our cut-off age (24 hours).
     *
     * @param {Date} [cutoff] Cutoff can be passed in for testing.
     *
     * @return {Promise<UserSession[]>}
     */
    pruneOldCarts(cutoff) {
        if (!cutoff) {
            cutoff = new Date();
            cutoff.setTime(cutoff.getTime() - (86400 * 1000));
        }

        return this.getAll()
            .then(
                /**
                 * @param {UserSession[]} sessions
                 */
                async (sessions) => {
                    let guidsToRemove = [];

                    for (let i = 0; i < sessions.length; i++) {
                        let session = sessions[i];
                        for (let c = 0; c < session.stashedCarts.length; c++) {
                            let cart = session.stashedCarts[c];
                            if (cart.stashDatetime < cutoff) {
                                guidsToRemove.push(cart.guid);
                            }
                        }
                    }

                    return guidsToRemove.length > 0 ? this.deleteStashedCartsByGuids(guidsToRemove) : false;
                }
            );
    },

    /**
     * @param {string[]} guids
     *
     * @return {Promise<boolean>}
     */
    deleteStashedCartsByGuids(guids) {
        return this.getAll()
            .then(
                /**
                 * @param {UserSession[]} sessions
                 */
                async (sessions) => {
                    let removed = false;

                    let persistPromises = [];

                    for (let i = 0; i < sessions.length; i++) {
                        let removedInThisSession = false;
                        guids.forEach((guid) => {
                            if (sessions[i].deleteStashedCartByGuid(guid)) {
                                removedInThisSession = true;
                                removed = true;
                            }
                        });

                        if (removedInThisSession) {
                            // This session contained the stashed cart and it has now been removed.
                            // Re-save this session.
                            persistPromises.push(
                                this.persist(sessions[i])
                            );
                        }
                    }

                    return Promise.all(persistPromises)
                        .then(() => removed);
                }
            );
    },

    deleteStashedCartByGuid(guid) {
        return this.deleteStashedCartsByGuids([guid]);
    }
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = UserSessionManager;
}
