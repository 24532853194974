const FieldType = require('../libraries/DigiTickets/CustomFields/FieldType');

/**
 * Displays a {FieldInstance} (supplied in the "instance" attribute).
 *
 * The mode attribute can be one of:
 * "edit" - shows the html input for that field type.
 * "hidden" - shows a hidden html input.
 * none (default) - shows the field value.
 *
 * @param $timeout
 * @param {FieldValidator} fieldValidator
 */
const fieldInstanceDirective = function (
    $timeout,
    fieldValidator
) {
    return {
        restrict: 'E',
        scope: {
            instance: '=',
            mode: '@'
        },

        /**
         * @param {{instance: FieldInstance, mode: string, validate: function}} $scope
         */
        controller: function ($scope) {
            if ($scope.mode === 'edit') {
                $scope.validate = () => {
                    // If it's date of birth, calculate the age.
                    if ($scope.instance.field.type === FieldType.DOB) {
                        // Set the value of the instance from the DOM.
                        let input = document.querySelector(`input[data-instance-key="${$scope.instance.key}"]`);
                        let value = input.value;

                        $scope.instance.setValue(value);

                        fieldValidator.calculateAge($scope.instance);
                    }
                };

                $timeout(function () {
                    $scope.validate();
                });
            } else if ($scope.instance.field.type === FieldType.DOB) {
                fieldValidator.calculateAge($scope.instance);
            }
        },

        template: function (templateElem, templateAttrs) {
            // New template.
            let tpl = '';

            // Force list mode if nothing else is supplied.
            let mode = templateAttrs.hasOwnProperty('mode') ? templateAttrs.mode : 'list';

            switch (mode) {
                case 'edit':
                    // Add an editable FieldInstance to the template.
                    tpl += '<ng-include src="\'partials/fields/\' + instance.field.type + \'.html\'"></ng-include>';
                    break;
                case 'hidden':
                    // Add a hidden FieldInstance to the template, taking into account single or multiple values.
                    tpl += '<ng-include ng-if="instance.field.hasMultipleOptions" src="\'partials/fields/hiddenMultipleValues.html\'"></ng-include>'
                        + '<ng-include ng-if="!instance.field.hasMultipleOptions" src="\'partials/fields/hiddenSingleValue.html\'"></ng-include>';
                    break;
                default:
                    // Add the FieldInstance in 'read-only' mode.
                    tpl += '<span class="field-value">{{ instance.getDisplayValue(true) }}</span>';
                    break;
            }

            return tpl;
        }
    };
};

/* istanbul ignore next */
if (typeof module !== 'undefined' && module.exports) {
    module.exports = fieldInstanceDirective;
}
